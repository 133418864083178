import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as RD from 'remotedata';

import { IconName, SelectItems } from 'components/ds';
import { SelectorSection } from '../SelectorSection';

import { fetchHierarchyMetadata } from 'actions/customerActions';
import { ReduxState } from 'reducers/rootReducer';
import { SelectProps } from '../types';

export const HierarchySelect: FC<SelectProps> = ({ selected, onUpdate }) => {
  const dispatch = useDispatch();
  const [newHierarchyId, setNewHierarchyId] = useState<number | null>(null);
  const hierarchyMetadata = useSelector((state: ReduxState) => state.customers.hierarchyMetadata);

  useEffect(() => {
    if (RD.isIdle(hierarchyMetadata)) dispatch(fetchHierarchyMetadata());
  }, [hierarchyMetadata, dispatch]);

  const hierarchyLevels = RD.isSuccess(hierarchyMetadata) ? hierarchyMetadata.data.levels : [];

  const hierarchyOptions: SelectItems<string> = hierarchyLevels
    .filter((h) => !selected.hierarchyIds.includes(h.id))
    .map((h) => ({
      value: h.id.toString(),
      label: h.name,
      icon: 'layer',
    }));

  const selectedHierarchy = hierarchyOptions.find(
    (opt) => opt.value === newHierarchyId?.toString(),
  );

  const tags = selected.hierarchyIds.map((hierarchyId) => ({
    key: String(hierarchyId),
    label: hierarchyLevels.find((h) => h.id === hierarchyId)?.name || `Hierarchy ${hierarchyId}`,
    onClose: () => {
      onUpdate({
        ...selected,
        hierarchyIds: selected.hierarchyIds.filter((hId) => hId !== hierarchyId),
      });
    },
    leftIcon: 'layer' as IconName,
  }));

  return (
    <SelectorSection
      addDisabled={!newHierarchyId}
      disabled={!RD.isSuccess(hierarchyMetadata)}
      label="Hierarchy"
      onAdd={() => {
        if (!newHierarchyId) return;
        onUpdate({
          ...selected,
          hierarchyIds: [...selected.hierarchyIds, newHierarchyId],
        });
        setNewHierarchyId(null);
      }}
      onSelect={(id) => setNewHierarchyId(parseInt(id))}
      placeholder="Select hierarchy"
      selectedValue={selectedHierarchy?.value}
      tags={tags}
      values={hierarchyOptions}
    />
  );
};
