import type {
  AndFilterDto,
  CustomerRecipientDto,
  EndUserRecipientDto,
  GroupTagRecipientDto,
  HierarchyRecipientDto,
  OrFilterDto,
} from '@explo/embeddo-api';
import { FlattenedRecipients } from './types';

const createOrFilter = (
  filters: Array<
    | CustomerRecipientDto
    | GroupTagRecipientDto
    | HierarchyRecipientDto
    | EndUserRecipientDto
    | AndFilterDto
    | OrFilterDto
  >,
): OrFilterDto => ({
  '@type': 'or',
  filters,
});

const createAndFilter = (
  filters: Array<
    | CustomerRecipientDto
    | GroupTagRecipientDto
    | HierarchyRecipientDto
    | EndUserRecipientDto
    | OrFilterDto
    | AndFilterDto
  >,
): AndFilterDto => ({
  '@type': 'and',
  filters,
});

const createHierarchyFilter = (id: number): HierarchyRecipientDto => ({
  '@type': 'hierarchy',
  id,
});

const createGroupTagFilter = (id: number): GroupTagRecipientDto => ({
  '@type': 'group-tag',
  id,
});

const createCustomerFilter = (id: number): CustomerRecipientDto => ({
  '@type': 'customer',
  id,
});

// Helper to create OR filter for multiple items of the same type
const createSubtypeBaseFilter = <
  T extends
    | CustomerRecipientDto
    | GroupTagRecipientDto
    | HierarchyRecipientDto
    | EndUserRecipientDto
    | AndFilterDto
    | OrFilterDto,
>(
  filters: T[],
): T | OrFilterDto | null => {
  if (filters.length === 0) return null;
  if (filters.length === 1) return filters[0];
  return createOrFilter(filters);
};

export const flattenRecipientFilter = (
  recipientsFilter:
    | CustomerRecipientDto
    | GroupTagRecipientDto
    | HierarchyRecipientDto
    | EndUserRecipientDto
    | OrFilterDto
    | AndFilterDto
    | null,
): FlattenedRecipients | null => {
  if (!recipientsFilter) return null;

  const result: FlattenedRecipients = {
    hierarchyIds: [],
    groupTagIds: [],
    customerIds: [],
  };

  const collectIds = (
    filter:
      | CustomerRecipientDto
      | GroupTagRecipientDto
      | HierarchyRecipientDto
      | EndUserRecipientDto
      | OrFilterDto
      | AndFilterDto,
  ) => {
    if (!filter) return;

    if (filter['@type'] === 'and' || filter['@type'] === 'or') {
      filter.filters.forEach(collectIds);
    } else if (filter['@type'] === 'customer') {
      result.customerIds.push(filter.id);
    } else if (filter['@type'] === 'group-tag') {
      result.groupTagIds.push(filter.id);
    } else if (filter['@type'] === 'hierarchy') {
      result.hierarchyIds.push(filter.id);
    }
  };

  collectIds(recipientsFilter);
  return result;
};

export const createRecipientFilter = (
  flat: FlattenedRecipients,
):
  | CustomerRecipientDto
  | GroupTagRecipientDto
  | HierarchyRecipientDto
  | EndUserRecipientDto
  | OrFilterDto
  | AndFilterDto
  | null => {
  const { hierarchyIds, groupTagIds, customerIds } = flat;

  if ([...hierarchyIds, ...groupTagIds, ...customerIds].length === 0) {
    return null;
  }

  const hierarchyFilter = createSubtypeBaseFilter(hierarchyIds.map(createHierarchyFilter));
  const groupTagFilter = createSubtypeBaseFilter(groupTagIds.map(createGroupTagFilter));
  const customerFilter = createSubtypeBaseFilter(customerIds.map(createCustomerFilter));

  const nonCustomerFilters = [hierarchyFilter, groupTagFilter].filter((filter) => filter != null);
  const nonCustomerFilter =
    nonCustomerFilters.length > 1 ? createAndFilter(nonCustomerFilters) : nonCustomerFilters[0];

  if (nonCustomerFilter && customerFilter) {
    return createOrFilter([nonCustomerFilter, customerFilter]);
  }

  return nonCustomerFilter || customerFilter;
};
