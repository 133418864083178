import { ComputedView, ListResourcesResponse } from '@explo-tech/fido-api';
import cx from 'classnames';
import { Icon, Modal, Spinner, sprinkles, Tag, Tooltip } from 'components/ds';
import SqlEditor from 'components/SqlEditor';
import { FC, useCallback, useMemo, useState } from 'react';
import * as RD from 'remotedata';
import { ReadAccessComputedView } from 'utils/fido/fidoShimmedTypes';
import * as styles from './SelectDatasetVersionModal.css';

type VersionItemProps = {
  version: ComputedView;
  versionNumber: number;
  currentDataset: ReadAccessComputedView;
  setSelectedVersionId: (versionId: string | undefined) => void;
  selectedVersionId: string | undefined;
};

const VersionItem: FC<VersionItemProps> = ({
  version,
  versionNumber,
  currentDataset,
  setSelectedVersionId,
  selectedVersionId,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div
      className={cx(
        styles.versionPanelRoot,
        selectedVersionId === version.versionId && styles.hoverSelectedVersionPanel,
      )}
      onClick={() => {
        if (version.versionId === currentDataset?.versionId) {
          setSelectedVersionId(undefined);
        } else {
          setSelectedVersionId(version.versionId ?? undefined);
        }
      }}>
      <div>
        <div className={styles.versionPanelHeader}>
          <div className={styles.versionPanelNameAndTags}>
            <div>{`Version ${versionNumber}`}</div>
            {selectedVersionId === version.versionId &&
              selectedVersionId !== currentDataset?.versionId && (
                <Icon
                  className={sprinkles({
                    marginX: 'sp1',
                    color: 'brandPrimary',
                  })}
                  name={'check'}
                />
              )}
            {currentDataset?.versionId === version.versionId && (
              <Tag
                className={sprinkles({
                  marginX: 'sp1',
                })}>
                Active
              </Tag>
            )}
          </div>
          <div
            className={isExpanded ? styles.expandedDropdown : styles.collapsedDropdown}
            onClick={(event) => {
              event.stopPropagation();
              setIsExpanded(!isExpanded);
            }}>
            <Tooltip align="center" side="bottom" text={isExpanded ? 'Hide query' : 'Show query'}>
              <Icon
                className={sprinkles({ color: 'gray11' })}
                name={isExpanded ? 'chevron-up' : 'chevron-right'}
              />
            </Tooltip>
          </div>
        </div>
      </div>
      {isExpanded ? (
        <div className={sprinkles({ paddingTop: 'sp1' })} style={{ height: '100px' }}>
          <SqlEditor columnNames={[]} query={version.query ?? ''} tableNames={[]} />
        </div>
      ) : null}
    </div>
  );
};

type Props = {
  currentDataset: ReadAccessComputedView;
  orderedComputedViewVersions: RD.ResponseData<ListResourcesResponse>;

  onSubmitVersionSelection: (newGlobalDataset: ReadAccessComputedView) => void;
  onClose: () => void;
};

export const SelectDatasetVersionModal: FC<Props> = ({
  currentDataset,
  orderedComputedViewVersions,
  onSubmitVersionSelection,
  onClose,
}) => {
  const [selectedVersionId, setSelectedVersionId] = useState<string | undefined>();

  const computedViews = useMemo(() => {
    return RD.getOrDefault(orderedComputedViewVersions, { resources: [], totalResults: 0 })
      .resources as ComputedView[];
  }, [orderedComputedViewVersions]);

  const submitVersionSelection = useCallback(
    (versionId: string | undefined) => {
      if (!versionId) {
        return;
      }

      const version = computedViews.find((computedView) => computedView.versionId === versionId);
      if (!version) {
        return;
      }

      onSubmitVersionSelection(version as ReadAccessComputedView);
      setSelectedVersionId(undefined);
      onClose();
    },
    [computedViews, onSubmitVersionSelection, onClose],
  );

  const primaryButtonProps = {
    text: 'Select',
    onClick: () => submitVersionSelection(selectedVersionId),
    disabled: !RD.isSuccess(orderedComputedViewVersions) || !selectedVersionId,
  };

  return (
    <Modal
      isOpen
      onClose={onClose}
      primaryButtonProps={primaryButtonProps}
      size="medium"
      title="Select Dataset Version">
      <div
        className={sprinkles({
          parentContainer: 'fill',
          flexItems: 'column',
        })}
        style={{ height: '216px' }}>
        {RD.isLoading(orderedComputedViewVersions) ? (
          <Spinner fillContainer />
        ) : RD.isSuccess(orderedComputedViewVersions) ? (
          computedViews.map((version: ComputedView, ordinal: number) => {
            const key = orderedComputedViewVersions.data.resources.length - ordinal;
            return (
              <VersionItem
                currentDataset={currentDataset}
                key={key}
                selectedVersionId={selectedVersionId}
                setSelectedVersionId={setSelectedVersionId}
                version={version}
                versionNumber={key}
              />
            );
          })
        ) : (
          <div className={sprinkles({ textAlign: 'center' })}>No versions found</div>
        )}
      </div>
    </Modal>
  );
};
