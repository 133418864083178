import { sprinkles } from '@explo/design-system';
import { ScheduledEmailExportDto } from '@explo/embeddo-api';
import { Button, Icon, Input } from 'components/ds';
import { FC, useState } from 'react';
import { RecipientPopover } from '../recipients/RecipientPopover';
import * as styles from './index.css';

interface Props {
  exportItem?: ScheduledEmailExportDto;
}

export const EmailExport: FC<Props> = ({ exportItem }) => {
  const [subject, setSubject] = useState('');
  const [selectedDataAsset, setSelectedDataAsset] = useState<string | null>(null);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerLine}>
          <p className={styles.headerLineLabel}>From</p>
          <p className={sprinkles({ body: 'b3', marginBottom: 'sp0', color: 'contentTertiary' })}>
            support@domain.com
          </p>
        </div>
        <div className={styles.headerLine}>
          <p className={styles.headerLineLabel}>To</p>

          <RecipientPopover recipients={exportItem?.recipients} />
        </div>
        <div className={styles.headerLine}>
          <p className={styles.headerLineLabel}>Subject</p>
          <Input
            fillWidth
            defaultValue={subject}
            onSubmit={setSubject}
            placeholder="Enter subject line here"
          />
        </div>
      </div>

      <div className={styles.body}>
        {selectedDataAsset === null ? (
          <button
            className={styles.addDataAsset}
            onClick={() => setSelectedDataAsset('Dashboard Example')}>
            <Icon name="image" />
            Add image of data asset
          </button>
        ) : (
          <div className={styles.dataAssetPreview}>
            <div
              className={sprinkles({
                display: 'flex',
                alignItems: 'center',
                gap: 'sp2',
                color: 'contentSecondary',
              })}>
              <Icon name="image" size="lg" />
              <p
                className={sprinkles({
                  body: 'b2',
                  marginBottom: 'sp0',
                })}>
                {selectedDataAsset}
              </p>
            </div>
            <div
              className={sprinkles({
                display: 'flex',
                alignItems: 'center',
                gap: 'sp2',
              })}>
              <Button onClick={() => setSelectedDataAsset(null)} variant="tertiary">
                Swap
              </Button>
              <Button icon="arrow-up-right-from-square" to="{{DASHBOARD_URL}}" variant="tertiary">
                Edit
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
