import { usePrevious } from '@radix-ui/react-use-previous';
import { FC, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { BaseCol, DatasetSchema } from '@explo/data';

import { ParentSchema } from 'actions/dataSourceActions';
import {
  Dataset,
  DatasetData,
  DrilldownColConfig,
  DrilldownColConfigOptions,
  DrilldownConfig,
} from 'actions/datasetActions';
import { sprinkles, Tabs } from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { DashboardParam } from 'types/dashboardVersionConfig';

import { DatasetFormattingTab } from './DatasetFormattingTab';
import { DatasetMetadataPanel, METADATA_VIEWS } from './DatasetMetadataPanel';
import { DatasetQueryPanels } from './DatasetQueryPanels';
import { DatasetViews } from './constants';
import * as sharedStyles from './styles.css';
import { TabIconProps, TabOption } from 'components/ds/Tabs';
import { DatasetUsagesTab } from './DatasetUsagesTab';

const DatasetViewTabs = Object.values(DatasetViews);

type Props = {
  activeQuery: string;
  activeDatasetData: DatasetData | null;
  activeDatasetConfig: Dataset | null;
  activeDatasetSchema: DatasetSchema | null;
  // This is the saved schema which should be used for drilldown configuration
  activeDatasetSavedSchema: DatasetSchema | null;
  fetchData: (query: string, pageNumber?: number) => void;
  onSave?: (query: string) => void;
  onSaveDraft: (query: string | undefined) => void;
  onSelectSchema: (schemaId: number) => void;
  parentSchemas: ParentSchema[];
  selectedDatasetId: string | null;
  variables: DashboardVariableMap;
  createNewParam: () => DashboardParam;
  renderFormattingTab: boolean;
  renderUsagesTab?: boolean;
  selectedDrilldownColumn: BaseCol | undefined;

  onRevertDraft?: () => void;
  isPreviewButtonDisabledFn?: () => boolean;
  getPreviewButtonTooltipText?: () => string | undefined;
  getMetadataPanelTabIconProps?: (tabId: METADATA_VIEWS) => TabIconProps | undefined;
  onUpdateDrilldownConfig: (datasetId: string, updates: Partial<DrilldownConfig>) => void;
  onUpdateColumnConfig: (
    datasetId: string,
    columnName: string,
    newColumnConfigs: DrilldownColConfigOptions,
  ) => void;
  onUpdateColumnConfigs: (
    datasetId: string,
    newColumnConfigs: Record<string, DrilldownColConfig>,
  ) => void;
  onSelectDrilldownColumn: (columnInfo: BaseCol | undefined) => void;
};

export const DatasetEditor: FC<Props> = ({
  activeQuery,
  activeDatasetData,
  activeDatasetConfig,
  activeDatasetSchema,
  activeDatasetSavedSchema,
  fetchData,
  onSave,
  onSaveDraft,
  onSelectSchema,
  selectedDatasetId,
  variables,
  createNewParam,
  renderFormattingTab,
  renderUsagesTab,
  selectedDrilldownColumn,
  onRevertDraft,
  isPreviewButtonDisabledFn,
  getPreviewButtonTooltipText,
  getMetadataPanelTabIconProps,
  onUpdateDrilldownConfig,
  onUpdateColumnConfig,
  onUpdateColumnConfigs,
  onSelectDrilldownColumn,
}) => {
  const { isDrilldownPreview, enableDataLibraryV2 } = useSelector(
    (state: ReduxState) => ({
      isDrilldownPreview: state.dashboardInteractions.isDrilldownPreview,
      enableDataLibraryV2: state.currentUser.team?.feature_flags.enable_data_library_v2,
    }),
    shallowEqual,
  );

  const [editorView, setEditorView] = useState(
    isDrilldownPreview ? DatasetViews.FORMATTING : DatasetViews.QUERY,
  );
  const [currentQuery, setCurrentQuery] = useState(activeQuery);

  const prevActiveQuery = usePrevious(activeQuery);
  useEffect(() => {
    if (prevActiveQuery !== activeQuery) {
      setCurrentQuery(activeQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuery]);

  const renderMetadataPanel = () => {
    if (!selectedDatasetId || !activeDatasetConfig) return;

    return (
      <DatasetMetadataPanel
        createNewParam={createNewParam}
        error={activeDatasetData?.error}
        getTabIconProps={getMetadataPanelTabIconProps}
        onSelectSchema={onSelectSchema}
        query={activeQuery}
        selectedDatasetId={activeDatasetConfig.parent_schema_id}
        variables={variables}
      />
    );
  };

  const generateTabs = () => {
    const tabs: TabOption[] = [];
    if (renderFormattingTab) {
      tabs.push({ id: DatasetViews.FORMATTING, label: 'Formatting' });
    }
    tabs.push({ id: DatasetViews.QUERY, label: 'Query' });
    if (renderUsagesTab) {
      tabs.push({ id: DatasetViews.USAGE, label: 'Usage' });
    }
    return tabs;
  };

  const renderView = () => {
    switch (editorView) {
      case DatasetViews.QUERY:
        return renderDatasetQueryEditor();
      case DatasetViews.FORMATTING:
        return renderDatasetFormattingBody();
      case DatasetViews.USAGE:
        return renderDatasetUsageBody();
      default:
        return null;
    }
  };

  const renderDatasetEditor = () => (
    <div className={datasetEditorContainerClass}>
      {renderFormattingTab ? (
        <div className={editorMenuContainerClass} style={{ minHeight: 48 }}>
          <Tabs
            className={sharedStyles.datasetEditorTabs}
            onTabSelect={(tabId) => openTab(tabId)}
            selectedTabId={editorView}
            tabs={DatasetViewTabs}
          />
        </div>
      ) : null}
      {editorView === DatasetViews.QUERY
        ? renderDatasetQueryEditor()
        : renderDatasetFormattingBody()}
    </div>
  );

  const renderDatasetEditorWithUsages = () => {
    return (
      <div className={datasetEditorContainerClass}>
        <div className={editorMenuContainerClass} style={{ minHeight: 48 }}>
          <Tabs
            className={sharedStyles.datasetEditorTabs}
            onTabSelect={(tabId) => openTab(tabId)}
            selectedTabId={editorView}
            tabs={generateTabs()}
          />
        </div>
        {renderView()}
      </div>
    );
  };

  const openTab = (tabId: string) => setEditorView(tabId as DatasetViews);

  const renderDatasetQueryEditor = () => {
    return (
      <div className={queryEditorContainerClass}>
        <DatasetQueryPanels
          activeDatasetConfig={activeDatasetConfig}
          activeDatasetData={activeDatasetData}
          activeDatasetSchema={activeDatasetSchema}
          activeQuery={activeQuery}
          currentQuery={currentQuery}
          fetchData={fetchData}
          getPreviewButtonTooltipText={getPreviewButtonTooltipText}
          isPreviewButtonDisabledFn={isPreviewButtonDisabledFn}
          onRevertDraft={onRevertDraft}
          onSave={onSave}
          onSaveDraft={onSaveDraft}
          selectedDatasetId={selectedDatasetId}
          setCurrentQuery={setCurrentQuery}
        />
        {renderMetadataPanel()}
      </div>
    );
  };

  const renderDatasetFormattingBody = () => {
    if (!selectedDatasetId) return;

    return (
      <DatasetFormattingTab
        activeDatasetConfig={activeDatasetConfig}
        activeDatasetData={activeDatasetData}
        activeDatasetSavedSchema={activeDatasetSavedSchema}
        activeQuery={activeQuery}
        fetchData={fetchData}
        onSelectDrilldownColumn={onSelectDrilldownColumn}
        onUpdateColumnConfig={onUpdateColumnConfig}
        onUpdateColumnConfigs={onUpdateColumnConfigs}
        onUpdateDrilldownConfig={onUpdateDrilldownConfig}
        openTab={openTab}
        selectedDrilldownColumn={selectedDrilldownColumn}
      />
    );
  };

  const renderDatasetUsageBody = () => {
    return (
      <div className={usagesEditorContainerClass}>
        <DatasetUsagesTab />
      </div>
    );
  };

  return (
    <div className={sprinkles({ parentContainer: 'fill', overflowY: 'auto' })}>
      {enableDataLibraryV2 ? renderDatasetEditorWithUsages() : renderDatasetEditor()}
    </div>
  );
};

const editorMenuContainerClass = sprinkles({
  backgroundColor: 'white',
  paddingRight: 'sp1.5',
  overflow: 'hidden',
  borderBottom: 1,
  borderColor: 'outline',
  width: 'fill',
});

const queryEditorContainerClass = sprinkles({
  flexItems: 'alignCenter',
  parentContainer: 'fill',
  padding: 'sp2',
  gap: 'sp2',
  overflowY: 'auto',
});

const usagesEditorContainerClass = sprinkles({
  display: 'flex',
  flexDirection: 'column',
  gap: 'sp2',
  padding: 'sp2',
  overflowY: 'auto',
});

const datasetEditorContainerClass = sprinkles({
  parentContainer: 'fill',
  flexItems: 'column',
  backgroundColor: 'white',
  flex: 1,
  overflowY: 'auto',
});
