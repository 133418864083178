import { FC, useState } from 'react';

import { sprinkles } from '@explo/design-system';
import { Breadcrumbs, Button, ButtonProps, NavigationToggle } from 'components/ds';
import * as styles from './index.css';

import { BreadcrumbsHierarchyItem } from 'components/ds/Breadcrumbs';
import { SearchBarProps as BaseSearchBarProps, SearchBar } from 'components/SearchBar/SearchBar';

export enum ViewType {
  List,
  Card,
}

type ActionProps = ButtonProps & {
  text: string;
  closeSearchOnClick?: boolean;
};

type Props = {
  title: string | JSX.Element;
  breadcrumbs?: BreadcrumbsHierarchyItem[];
  currentView?: ViewType;
  primaryActionProps?: ActionProps;
  secondaryActionProps?: ActionProps;
  searchBarProps?: SearchBarProps;
  toggleView?: (view: ViewType) => void;
  heading?: 'h2' | 'h3';
};

export type CustomSearchBarProps = {
  variant: 'custom';
  customComponent: JSX.Element;
};

export type DefaultSearchBarProps = {
  variant: 'default';
} & Omit<BaseSearchBarProps, 'onSetIsExpanded' | 'isExpanded'>;

export type SearchBarProps = DefaultSearchBarProps | CustomSearchBarProps;

export const PageHeader: FC<Props> = ({
  title,
  breadcrumbs = [],
  currentView,
  primaryActionProps,
  secondaryActionProps,
  searchBarProps,
  toggleView,
  heading = 'h2',
}) => {
  const [isSearchBarExpanded, setIsSearchBarExpanded] = useState(false);

  const renderSearchBar = () => {
    if (!searchBarProps) {
      return null;
    }

    if (searchBarProps.variant === 'custom') {
      return searchBarProps.customComponent;
    }

    return (
      <SearchBar
        isExpanded={isSearchBarExpanded}
        onSetIsExpanded={setIsSearchBarExpanded}
        onSubmit={searchBarProps.onSubmit}
        placeholderText={searchBarProps.placeholderText}
      />
    );
  };

  const closeSearch = () => {
    if (isSearchBarExpanded && searchBarProps?.variant === 'default') {
      setIsSearchBarExpanded(false);
      searchBarProps.onSubmit('');
    }
  };

  const breadcrumbHeader = () => {
    if (breadcrumbs.length > 0) {
      return (
        <div className={sprinkles({ minWidth: 0 })}>
          <Breadcrumbs items={breadcrumbs} />
          <div className={sprinkles({ truncateText: 'ellipsis' })}>{title}</div>
        </div>
      );
    }
    return <div className={sprinkles({ truncateText: 'ellipsis' })}>{title}</div>;
  };

  const handlePrimaryClick: ButtonProps['onClick'] = (event) => {
    if (primaryActionProps?.disabled || primaryActionProps?.to) {
      return;
    }
    if (primaryActionProps?.closeSearchOnClick) {
      closeSearch();
    }
    primaryActionProps?.onClick?.(event);
  };

  const handleSecondaryClick: ButtonProps['onClick'] = (event) => {
    if (secondaryActionProps?.disabled || secondaryActionProps?.to) {
      return;
    }
    if (secondaryActionProps?.closeSearchOnClick) {
      closeSearch();
    }
    secondaryActionProps?.onClick?.(event);
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.nameSearch}>
          <div
            className={sprinkles({
              heading,
              display: 'flex',
              width: 'fill',
              overflow: 'hidden',
            })}>
            {breadcrumbHeader()}
          </div>
          {renderSearchBar()}
        </div>
        <div className={styles.buttonContainer}>
          {toggleView ? (
            <NavigationToggle
              valueRequired
              defaultValue={currentView === ViewType.List ? 'list' : 'grid'}
              items={[
                { iconName: 'grid2', value: 'grid' },
                { iconName: 'list', value: 'list' },
              ]}
              onValueChange={(value) =>
                toggleView?.(value === 'grid' ? ViewType.Card : ViewType.List)
              }
              type="single"
            />
          ) : null}
          {secondaryActionProps && secondaryActionProps.onClick ? (
            <Button {...secondaryActionProps} onClick={handleSecondaryClick} variant="secondary">
              {secondaryActionProps.text}
            </Button>
          ) : secondaryActionProps ? (
            <Button {...secondaryActionProps} variant="secondary">
              {secondaryActionProps.text}
            </Button>
          ) : null}
          {primaryActionProps && primaryActionProps.onClick ? (
            <Button {...primaryActionProps} onClick={handlePrimaryClick} variant="primary">
              {primaryActionProps.text}
            </Button>
          ) : primaryActionProps ? (
            <Button {...primaryActionProps} variant="primary">
              {primaryActionProps.text}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
