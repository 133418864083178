import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ChartAggregation, AGGREGATIONS_TYPES, UNSIGNED_INTEGER, FLOAT } from '@explo/data';

import { CustomerReportAgg } from 'actions/customerReportActions';
import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';
import { EmbeddedDropdownButton } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownButton';
import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';
import * as styles from 'pages/ReportBuilder/ReportView/DataPanel/ColumnSection.css';
import { DataPanelListItem } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelListItem';
import { AGGS_SECTION_ID } from 'pages/ReportBuilder/ReportView/DataPanel/constants';
import { SCHEMA_TYPE_ICON_MAP } from 'pages/ReportBuilder/constants';
import { isCountAggregation } from 'pages/ReportBuilder/utils/viewUtils';
import { deleteAgg, updateAgg } from 'reportBuilderContent/reducers/reportEditingReducer';
import { getAggColConfig, getAggColDisplay } from 'utils/V2ColUtils';
import { getAggUniqueId, getReportAggOptions } from 'utils/customerReportUtils';
import { ReduxState } from 'reducers/rootReducer';
import { getReportBuilderUseFido } from 'reducers/selectors';

type AggregationItemProps = {
  aggregation: CustomerReportAgg;
  aggsByCol: Record<string, Set<ChartAggregation>>;
  columnConfigs?: ReportBuilderColConfigs;
  sourceType?: string;
};

export const AggItem: FC<AggregationItemProps> = ({
  aggregation,
  aggsByCol,
  columnConfigs,
  sourceType,
}) => {
  const dispatch = useDispatch();

  const { shouldUseFido, variables } = useSelector((state: ReduxState) => ({
    shouldUseFido: getReportBuilderUseFido(state),
    variables: state.embeddedReportBuilder.variables,
  }));

  const id = getAggUniqueId(aggregation);
  const aggId = aggregation.agg.id;
  const selectedAgg = AGGREGATIONS_TYPES[aggId];
  const aggOptions = getReportAggOptions(aggregation, aggsByCol, shouldUseFido, sourceType);
  const isCount = isCountAggregation(aggId);
  const isFormula = aggId === ChartAggregation.FORMULA;

  return (
    <DataPanelListItem
      actionButton={{
        variant: 'tertiary',
        icon: 'trash',
        onClick: () => dispatch(deleteAgg(id)),
        className: styles.eyeIcon,
      }}
      column={aggregation.column}
      columnConfigs={columnConfigs}
      description={getAggColConfig(aggregation, columnConfigs)?.description}
      disabled={isFormula}
      icon={SCHEMA_TYPE_ICON_MAP[isCount ? UNSIGNED_INTEGER : FLOAT]}
      id={id}
      name={getAggColDisplay(aggregation, columnConfigs, variables)}
      section={AGGS_SECTION_ID}>
      {isFormula ? null : (
        <EmbeddedDropdownMenu
          canSearch
          menuOptions={aggOptions}
          onClick={(newAgg) =>
            dispatch(
              updateAgg({ id, agg: { ...aggregation, agg: { id: newAgg as ChartAggregation } } }),
            )
          }>
          <EmbeddedDropdownButton selectedName={selectedAgg.name} />
        </EmbeddedDropdownMenu>
      )}
    </DataPanelListItem>
  );
};
