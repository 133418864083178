import { FC, useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Dataset } from 'actions/datasetActions';
import { getSelectedCustomer } from 'reducers/customersReducer';
import { addPendingResourceUpdate, revertComputedViewQuery } from 'reducers/dataLibraryReducer';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getArchetypeProperties } from 'reducers/selectors';
import { fetchGlobalDatasetPreviewThunk } from 'reducers/thunks/dashboardDataThunks/fetchDatasetPreviewThunks';
import { getCustomerVariables } from 'utils/customerUtils';
import { ComputedViewWithIds, ReadAccessComputedView } from 'utils/fido/fidoShimmedTypes';

import { DatasetEditor } from './DatasetEditor';
import { getEmbeddoSchemaIdFromView } from './utils';

import { sprinkles } from '@explo/design-system';
import { DataLibraryTopBar } from 'pages/dataLibraryPage/DataLibraryTopBar';
import { FetchOrigin } from 'reducers/thunks/dashboardDataThunks/types';
import { parseQueryThunk } from 'reducers/thunks/fidoThunks/viewThunks';
import { getDatasetConfigFromView, getEmbeddoSchemaFromFidoSchema } from 'utils/fido/fidoShims';
import { doesViewHaveInvalidParameters } from 'pages/dataLibraryPage/dataLibraryUtil';
import { METADATA_VIEWS } from './DatasetMetadataPanel';
import { IconName } from 'components/ds/Icon';

type Props = {
  selectedView: ComputedViewWithIds;
  selectedDataset: Dataset;
  baseQuery: string;
};

export const GlobalDatasetFidoDataFetcher: FC<Props> = ({
  selectedDataset,
  selectedView,
  baseQuery,
}) => {
  const dispatch = useDispatch();

  const {
    parentSchemas,
    datasetData,
    customer,
    archetypePropertiesSet,
    customVariables,
    enableDataLibraryV2,
  } = useSelector(
    (state: ReduxState) => ({
      parentSchemas: getParentSchemasList(state),
      datasetData: state.dataLibrary.datasetData,
      customer: getSelectedCustomer(state.customers),
      archetypePropertiesSet: getArchetypeProperties(state),
      customVariables: state.dataLibrary.variables,
      enableDataLibraryV2: state.currentUser.team?.feature_flags.enable_data_library_v2,
    }),
    shallowEqual,
  );

  const parentSchemaId = useMemo(
    () => getEmbeddoSchemaIdFromView(parentSchemas, selectedView),
    [parentSchemas, selectedView],
  );
  const data = datasetData[selectedView.id]?.[selectedView.versionId ?? ''];

  const customerVariables = useMemo(
    () => (customer ? getCustomerVariables(customer, archetypePropertiesSet) : {}),
    [customer, archetypePropertiesSet],
  );

  const variables = useMemo(
    () => ({ ...customerVariables, ...customVariables }),
    [customerVariables, customVariables],
  );

  const getUnderlyingData = useCallback(
    (query: string, pageNumber?: number) => {
      dispatch(
        fetchGlobalDatasetPreviewThunk(
          {
            query,
            parentSchemaId: parentSchemaId,
            selectedView,
            variables: variables,
          },
          FetchOrigin.DATA_LIBRARY,
          pageNumber,
        ),
      );
    },
    [parentSchemaId, dispatch, selectedView, variables],
  );

  const onSaveQueryDraft = useCallback(
    (query: string | undefined) => {
      dispatch(
        addPendingResourceUpdate({
          ...selectedView,
          query: query ?? '',
        }),
      );

      dispatch(parseQueryThunk({ datasetId: selectedView.id, request: { query: query ?? '' } }));
    },
    [selectedView, dispatch],
  );

  const onRevertDraft = useCallback(() => {
    if (selectedView.query === baseQuery) {
      return;
    }

    dispatch(revertComputedViewQuery(selectedView));
  }, [selectedView, baseQuery, dispatch]);

  const savedSchema = getEmbeddoSchemaFromFidoSchema(selectedView.columnDefinitions ?? []);

  const doesCustomerHaveAccessToUnderlyingDataSource = useMemo(() => {
    if (!customer) {
      return false;
    }

    const parentSchemaIdToDataSourceMap = customer.computed_parent_schema_datasource_mapping ?? {};

    // Check if the customer has access to the underlying data source for this dataset
    return !!parentSchemaIdToDataSourceMap[parentSchemaId];
  }, [customer, parentSchemaId]);

  const isPreviewButtonDisabledFn = useCallback(() => {
    return (
      !doesCustomerHaveAccessToUnderlyingDataSource ||
      doesViewHaveInvalidParameters(selectedView, archetypePropertiesSet)
    );
  }, [selectedView, archetypePropertiesSet, doesCustomerHaveAccessToUnderlyingDataSource]);

  const getPreviewButtonTooltipText = useCallback(() => {
    if (doesViewHaveInvalidParameters(selectedView, archetypePropertiesSet)) {
      return UNSET_VARIABLE_TYPES_TOOLTIP_MESSAGE;
    } else if (!doesCustomerHaveAccessToUnderlyingDataSource) {
      return CUSTOMER_LACKS_PERMISSIONS_TO_DATA_SOURCE_TOOLTIP_MESSAGE;
    }

    return undefined;
  }, [selectedView, archetypePropertiesSet, doesCustomerHaveAccessToUnderlyingDataSource]);

  const getMetadataPanelTabIconProps = useCallback(
    (tabId: METADATA_VIEWS) => {
      if (tabId !== METADATA_VIEWS.VARIABLES) {
        return undefined;
      }

      const variablesHaveUnsetType = doesViewHaveInvalidParameters(
        selectedView,
        archetypePropertiesSet,
      );
      return variablesHaveUnsetType
        ? {
            icon: 'circle-info' as IconName,
            iconTooltipText: 'There are variables with unset types',
            iconClassName: sprinkles({ color: 'error' }),
          }
        : undefined;
    },
    [selectedView, archetypePropertiesSet],
  );

  return (
    <div
      className={sprinkles({
        flexItems: 'column',
        parentContainer: 'fill',
        backgroundColor: 'white',
      })}>
      <DataLibraryTopBar selectedView={selectedView} />
      <DatasetEditor
        // @ts-ignore
        activeDatasetConfig={getDatasetConfigFromView(selectedView, selectedDataset)}
        activeDatasetData={data ?? null}
        activeDatasetSavedSchema={savedSchema}
        activeDatasetSchema={data?.schema ?? savedSchema}
        activeQuery={selectedDataset.queryDraft ?? selectedDataset.query ?? ''}
        createNewParam={() => ({ id: 'global-dataset-library', name: '', type: 'string' })}
        fetchData={getUnderlyingData}
        getMetadataPanelTabIconProps={getMetadataPanelTabIconProps}
        getPreviewButtonTooltipText={getPreviewButtonTooltipText}
        isPreviewButtonDisabledFn={isPreviewButtonDisabledFn}
        onRevertDraft={onRevertDraft}
        onSaveDraft={onSaveQueryDraft}
        onSelectDrilldownColumn={() => {
          // TODO(zifanxiang): Implement this once storage is done.
        }}
        onSelectSchema={(schemaId) => {
          const selectedSchema = parentSchemas.find((schema) => schema.id === schemaId);
          if (!selectedSchema) {
            return;
          }

          const updatedComputedView: ReadAccessComputedView = {
            ...selectedView,
            namespaceId: selectedSchema.fido_id,
            columnDefinitions: [],
          };
          dispatch(addPendingResourceUpdate(updatedComputedView));
        }}
        onUpdateColumnConfig={() => {
          // TODO(zifanxiang): Implement this once storage is done.
        }}
        onUpdateColumnConfigs={() => {
          // TODO(zifanxiang): Implement this once storage is done.
        }}
        onUpdateDrilldownConfig={() => {
          // TODO(zifanxiang): Implement this once storage is done.
        }}
        parentSchemas={parentSchemas}
        renderFormattingTab={enableDataLibraryV2 ?? false}
        renderUsagesTab={enableDataLibraryV2 ?? false}
        selectedDatasetId={selectedDataset.id}
        selectedDrilldownColumn={undefined}
        variables={variables}
      />
    </div>
  );
};

const UNSET_VARIABLE_TYPES_TOOLTIP_MESSAGE = 'There are variables with unset types';

const CUSTOMER_LACKS_PERMISSIONS_TO_DATA_SOURCE_TOOLTIP_MESSAGE =
  'The current customer does not have access to the underlying data source for this dataset';
