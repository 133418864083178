import * as RadixSelect from '@radix-ui/react-select';
import cx from 'classnames';
import { FC, forwardRef, ReactNode, useEffect, useMemo, useState } from 'react';

import { Icon, Input, Label, sprinkles, Tag } from 'components/ds';
import { IconName } from 'components/ds/Icon';
import { createDebouncedFn } from 'utils/general';
import { camelCase } from 'utils/standard';

import * as styles from './index.css';

type LabelProps = {
  text: string;
  infoText?: ReactNode;
};

export type SelectItems<T> = {
  label?: string;
  secondaryLabel?: string;
  secondaryLabelInTag?: boolean;
  subLabel?: string;
  value: T;
  icon?: IconName;
}[];

type FilterProps = {
  maxValues?: number;
  isLoading?: boolean;
  placeholder?: string;
  onFilter?: (searchString: string) => void;
  selectedLabel?: string;
  allowSearchOnSecondaryLabel?: boolean;
};

export type SelectVariant = 'primary' | 'secondary' | 'tertiary';

export type Props<T> = {
  className?: string;
  disabled?: boolean;
  label?: string | LabelProps;
  placeholder?: string;
  portalContainerId?: string;
  onChange: (value: T) => void;
  selectedValue: T | undefined;
  secondaryLabelInTag?: boolean;
  values: SelectItems<T>;
  // Parent container to create a boundary for the Radix dropdown menu
  collisionBoundary?: HTMLElement | null;
  side?: 'top' | 'bottom' | 'right' | 'left';
  // "trigger" (default) sets dropdown width to trigger width. "auto" fits dropdown width to content
  contentWidth?: 'auto' | 'trigger';
  inModal?: boolean;
  onCancel?: () => void;
  filterProps?: FilterProps;
  variant?: SelectVariant;
  useIconIndicator?: boolean;
  defaultIcon?: IconName;
  renderSelectedValue?: (selectedValue: string, selectedIcon?: IconName) => JSX.Element;
};

const debounceFn = createDebouncedFn(400);

const renderSecondaryIcon = (useIconIndicator: boolean, icon?: IconName) =>
  icon && !useIconIndicator ? (
    <Icon className={sprinkles({ color: 'contentSecondary' })} name={icon} />
  ) : null;

export const Select: FC<Props<string>> = ({
  className,
  disabled,
  label,
  onChange,
  placeholder,
  portalContainerId,
  secondaryLabelInTag = false,
  selectedValue,
  values,
  collisionBoundary,
  inModal,
  side,
  contentWidth,
  onCancel,
  filterProps,
  variant = 'primary',
  useIconIndicator = false,
  defaultIcon,
  renderSelectedValue,
}) => {
  const [inputValue, setInputValue] = useState<string>();
  const [selectedIcon, setSelectedIcon] = useState<IconName | undefined>(defaultIcon);
  // used to force rerender in on cancel and when selectedLabel becomes undefined
  const [key, setKey] = useState<number>(+new Date());

  const currLabel = typeof label === 'string' ? label : label?.text;
  const htmlFor = camelCase(currLabel);

  const handleValueChange = (newValue: string) => {
    const selectedItem = values.find((item) => item.value === newValue);
    if (useIconIndicator) {
      setSelectedIcon(selectedItem?.icon);
    }
    onChange(newValue);
  };

  const content = (
    <RadixSelect.Content
      align={variant === 'tertiary' ? 'end' : 'start'}
      className={cx(
        styles.content({ contentWidth }),
        inModal ? sprinkles({ zIndex: 'popoversInModals' }) : undefined,
      )}
      collisionBoundary={collisionBoundary}
      collisionPadding={{ top: 24, right: 16 }}
      position="popper"
      side={side}
      sideOffset={8}
      style={{ maxHeight: filterProps ? 300 : 200 }}>
      {filterProps ? (
        <FilterContent
          filterProps={filterProps}
          inputValue={inputValue}
          secondaryLabelInTag={secondaryLabelInTag}
          setInputValue={setInputValue}
          values={values}
          variant={variant}
        />
      ) : (
        <RadixSelect.Viewport>
          <div className={styles.dropdownMenuViewport({ variant })}>
            {values.map((value) => (
              <SelectItem
                icon={useIconIndicator ? value.icon : undefined}
                key={value.label || value.value}
                label={
                  useIconIndicator
                    ? value.label || String(value.value)
                    : (value.label ?? value.value)
                }
                secondaryLabel={!useIconIndicator ? value.secondaryLabel : undefined}
                secondaryLabelInTag={value.secondaryLabelInTag}
                subLabel={!useIconIndicator ? value.subLabel : undefined}
                value={value.value}
                variant={variant}
              />
            ))}
            {values.length === 0 ? <div className={styles.nonSelectItem}>No results.</div> : null}
          </div>
        </RadixSelect.Viewport>
      )}
    </RadixSelect.Content>
  );

  const renderCancelButton = () => {
    if (!onCancel || !selectedValue || disabled) return null;
    return (
      <div
        className={styles.cancelButton}
        onPointerDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onCancel();
        }}>
        <Icon name="cross" />
      </div>
    );
  };

  const selectedItem = useMemo(() => {
    if (!selectedValue) return;
    return values.find((v) => v.value === selectedValue);
  }, [selectedValue, values]);

  const selectedLabel =
    selectedItem?.label ??
    selectedItem?.value ??
    (filterProps ? (filterProps.selectedLabel ?? selectedValue) : undefined);

  const labelDisplayText = selectedLabel ?? placeholder ?? 'Select...';

  const renderHeaderSubLabel = (label?: string) => {
    return label ? (
      <div className={styles.triggerSecondaryLabelText} title={label}>
        {label}
      </div>
    ) : null;
  };

  useEffect(() => {
    if (selectedLabel === undefined) {
      setKey(+new Date());
      setSelectedIcon(defaultIcon);
    }
    if (useIconIndicator && selectedValue) {
      const item = values.find((v) => v.value === selectedValue);
      setSelectedIcon(item?.icon);
    }
  }, [selectedLabel, defaultIcon, values, useIconIndicator, selectedValue]);

  return (
    <div className={className}>
      {currLabel ? (
        <Label htmlFor={htmlFor} infoText={typeof label === 'string' ? undefined : label?.infoText}>
          {currLabel}
        </Label>
      ) : null}
      <RadixSelect.Root
        disabled={disabled}
        key={key}
        onValueChange={handleValueChange}
        value={selectedValue}>
        <RadixSelect.Trigger className={styles.trigger({ variant })} id={htmlFor}>
          <div className={styles.triggerElements}>
            {renderSecondaryIcon(useIconIndicator, selectedItem?.icon)}
            <div className={styles.triggerPrimaryLabelText({ variant })} title={labelDisplayText}>
              <RadixSelect.Value>
                {renderSelectedValue ? (
                  renderSelectedValue(labelDisplayText ?? '', selectedIcon)
                ) : useIconIndicator && selectedIcon ? (
                  <Icon name={selectedIcon} size="md" />
                ) : (
                  labelDisplayText
                )}
              </RadixSelect.Value>
            </div>
            {renderHeaderSubLabel(selectedItem?.subLabel ?? selectedItem?.secondaryLabel)}
          </div>
          <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp.5' })}>
            {renderCancelButton()}
            <RadixSelect.Icon>
              <Icon className={styles.chevron({ variant })} name="caret-down" />
            </RadixSelect.Icon>
          </div>
        </RadixSelect.Trigger>

        {portalContainerId ? (
          <RadixSelect.Portal container={document.getElementById(portalContainerId)}>
            {content}
          </RadixSelect.Portal>
        ) : (
          content
        )}
      </RadixSelect.Root>
    </div>
  );
};

type SelectItemProps<T> = {
  value: T;
  label: string;
  secondaryLabel?: string;
  secondaryLabelInTag?: boolean;
  subLabel?: string;
  icon?: IconName;
  variant: SelectVariant;
  useIconIndicator?: boolean;
};

const SelectItem: FC<SelectItemProps<string>> = forwardRef<HTMLDivElement, SelectItemProps<string>>(
  (
    {
      value,
      label,
      secondaryLabel,
      subLabel,
      secondaryLabelInTag,
      icon,
      variant,
      useIconIndicator = false,
      ...props
    },
    forwardedRef,
  ) => {
    const renderSecondaryLabel = (label?: string) => {
      return label ? (
        secondaryLabelInTag ? (
          <Tag className={styles.selectItemLabelText({ variant: 'secondaryLabelInTag' })}>
            <div className={sprinkles({ truncateText: 'ellipsis', width: 'fill' })}>{label}</div>
          </Tag>
        ) : (
          <div className={styles.selectItemLabelText({ variant: 'secondaryLabel' })}>{label}</div>
        )
      ) : null;
    };

    return (
      <RadixSelect.Item
        className={styles.item({ variant })}
        ref={forwardedRef}
        value={String(value)}
        {...props}>
        <div className={styles.selectItemLeftGroup({ variant })}>
          {renderSecondaryIcon(useIconIndicator, icon)}
          <div className={styles.labelAndSubLabelGroup({ variant })}>
            <div
              className={styles.selectItemLabelText({
                variant:
                  variant === 'tertiary'
                    ? 'primaryLabelWithFlex'
                    : subLabel
                      ? 'primaryLabelWithSubLabel'
                      : 'primaryLabel',
              })}>
              <RadixSelect.ItemText title={label}>{label}</RadixSelect.ItemText>
            </div>
            {subLabel ? (
              <div className={styles.selectItemLabelText({ variant: 'subLabel' })}>
                <RadixSelect.ItemText title={subLabel}>{subLabel}</RadixSelect.ItemText>
              </div>
            ) : null}
          </div>
          <RadixSelect.ItemIndicator>
            <Icon
              className={styles.selectItemIndicator({ variant })}
              name="check"
              size={variant === 'tertiary' ? 'md' : 'sm'}
            />
          </RadixSelect.ItemIndicator>
        </div>
        {renderSecondaryLabel(secondaryLabel)}
      </RadixSelect.Item>
    );
  },
);

SelectItem.displayName = 'SelectItem';

type FilterContentProps<T> = {
  filterProps: FilterProps;
  values: SelectItems<T>;
  inputValue: string | undefined;
  secondaryLabelInTag?: boolean;
  setInputValue: (inputValue?: string) => void;
  variant: SelectVariant;
};

const FilterContent: FC<FilterContentProps<string>> = ({
  filterProps,
  values,
  inputValue,
  secondaryLabelInTag,
  setInputValue,
  variant,
}) => {
  const [filteredValues, setFilteredValues] = useState<SelectItems<string>>(values);

  useEffect(() => setFilteredValues(values), [values]);

  const { placeholder, isLoading, allowSearchOnSecondaryLabel, onFilter } = filterProps;
  const maxValues = filterProps.maxValues ?? 50;

  const handleFiltering = (input: string) => {
    debounceFn(() => {
      const loweredInput = input.toLowerCase();
      onFilter
        ? onFilter(input)
        : setFilteredValues(
            values.filter(({ value, label, secondaryLabel }) => {
              const loweredPrimaryLabel = (label ?? value).toLowerCase();
              const labelFilter = loweredPrimaryLabel.includes(loweredInput);
              if (allowSearchOnSecondaryLabel && secondaryLabel) {
                const loweredSecondaryLabel = secondaryLabel.toLowerCase();
                return labelFilter || loweredSecondaryLabel.includes(loweredInput);
              }
              return labelFilter;
            }),
          );
      setInputValue(input);
    });
  };

  return (
    <div className={styles.dropdownMenuRoot}>
      <Input
        autoFocus
        fillWidth
        keepFocus
        className={styles.nonSelectItem}
        leftIcon="search"
        onChange={handleFiltering}
        placeholder={placeholder}
        showLoadingSpinner={isLoading}
        value={inputValue}
      />
      <RadixSelect.Viewport>
        <div className={styles.dropdownMenuViewport({ variant })}>
          {filteredValues
            .slice(0, maxValues)
            .map(({ icon, label, value, secondaryLabel, subLabel }) => (
              <SelectItem
                icon={icon}
                key={label ?? value}
                label={label ?? value}
                secondaryLabel={secondaryLabel}
                secondaryLabelInTag={secondaryLabelInTag}
                subLabel={subLabel}
                value={value}
                variant={variant}
              />
            ))}
          {filteredValues.length === 0 ? (
            <div className={styles.nonSelectItem}>No results.</div>
          ) : null}
          {filteredValues.length >= maxValues ? (
            <div className={styles.nonSelectItem}>Filter for more results.</div>
          ) : null}
        </div>
      </RadixSelect.Viewport>
    </div>
  );
};
