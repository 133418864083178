import { FilterClause } from '@explo/data';
import { FC, useMemo } from 'react';
import { filterColumnContainerClassName } from './FilterColumn.css';
import { FILTER_OPERATOR_TYPES_BY_ID } from 'types/filterOperations';
import { sprinkles } from 'components/ds';
import { getRenderedDrilldownFilterValue } from 'utils/dashboardDrilldownUtils';

interface Props {
  filterClause: FilterClause;
}

export const ImmutableFilterColumn: FC<Props> = ({ filterClause }) => {
  const filterOperatorInfo = useMemo(() => {
    const filterOperatorId = filterClause.filterOperation?.id;

    if (!filterOperatorId) {
      return;
    }

    return FILTER_OPERATOR_TYPES_BY_ID[filterOperatorId];
  }, [filterClause.filterOperation]);

  const renderedFilterValue = useMemo(() => {
    const filterValue = filterClause.filterValue;
    const filterOperation = filterClause.filterOperation;

    if (!filterOperation || !filterValue) {
      return filterValue;
    }

    return getRenderedDrilldownFilterValue(filterValue, filterOperation.id);
  }, [filterClause.filterValue, filterClause.filterOperation]);

  if (!filterOperatorInfo || !renderedFilterValue) {
    return null;
  }

  return (
    <div className={filterColumnContainerClassName}>
      <FilterSubComponent componentText={filterClause.filterColumn?.name ?? ''} />
      <FilterSubComponent componentText={filterOperatorInfo.name} />
      <span className={sprinkles({ fontWeight: 500, truncateText: 'ellipsis' })}>
        {renderedFilterValue}
      </span>
    </div>
  );
};

interface FilterSubComponentProps {
  componentText: string;
}

const FilterSubComponent: FC<FilterSubComponentProps> = ({ componentText }) => {
  return (
    <span className={filterSubComponentContainerClassName}>
      <span className={sprinkles({ marginLeft: 'sp1' })}>{componentText}</span>
    </span>
  );
};

const filterSubComponentContainerClassName = sprinkles({
  backgroundColor: 'blue3',
  borderRadius: 3,
  color: 'active',
  fontWeight: 500,
  paddingRight: 'sp1',
  paddingY: 'sp1',
  marginRight: 'sp1',
  truncateText: 'ellipsis',
});
