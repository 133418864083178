import {
  Branch,
  Commit,
  CommitResponseMetadata,
  CreateResourceDiff,
  DeleteResourceDiff,
  UpdateResourceDiff,
} from '@explo-tech/fido-api';
import { Team } from 'actions/teamActions';
import { TeamMember } from 'actions/userActions';
import { IconButton, Modal, Spinner, sprinkles, Tag } from 'components/ds';
import {
  changeComment,
  versionPanelNameAndTags,
  versionPanelRoot,
} from 'components/VersionControlModal/index.css';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCommitDetailsThunk, listCommitsThunk } from 'reducers/thunks/fidoThunks/commitThunks';

import { SettingHeader } from 'components/SettingHeader';
import { getOrDefault, isError, isIdle, isLoading, isSuccess, ResponseData } from 'remotedata';
import { sortBy } from 'utils/standard';
import { getCreatorDetailMessage } from 'utils/versionControlUtil';
import { DEFAULT_COMMIT_CHANGE_METADATA, ResourceDiffType } from './constants';
import { ResourceControl } from './ResourceControl';

interface Props {
  isOpen: boolean;
  currentBranch: Branch;
  branchCommits: ResponseData<Commit[]>;
  teamData: Team | undefined;
  commitDetails: Map<string, ResponseData<CommitResponseMetadata>>;

  onClose: () => void;
}

export const CommitHistoryModal: FC<Props> = ({
  isOpen,
  currentBranch,
  branchCommits,
  teamData,
  commitDetails,
  onClose,
}) => {
  const dispatch = useDispatch();

  const [selectedCommitId, setSelectedCommitId] = useState<string | undefined>();

  useEffect(() => {
    if (isOpen && isIdle(branchCommits)) {
      dispatch(listCommitsThunk(currentBranch.id ?? ''));
    }
  }, [branchCommits, currentBranch.id, dispatch, isOpen]);

  useEffect(() => {
    if (!selectedCommitId) {
      return;
    }

    if (!commitDetails.has(selectedCommitId) || isIdle(commitDetails.get(selectedCommitId))) {
      dispatch(
        getCommitDetailsThunk({ branchId: currentBranch.id ?? '', commitId: selectedCommitId }),
      );
    }
  }, [selectedCommitId, commitDetails, currentBranch.id, dispatch]);

  const selectedCommitDetails = useMemo(() => {
    return selectedCommitId ? commitDetails.get(selectedCommitId) : undefined;
  }, [commitDetails, selectedCommitId]);

  const selectedCommitCreations = useMemo(() => {
    return selectedCommitDetails
      ? sortBy(
          getOrDefault(selectedCommitDetails, DEFAULT_COMMIT_CHANGE_METADATA).changes.filter(
            (resourceDiff) => resourceDiff['@type'] === ResourceDiffType.CREATE,
          ) as CreateResourceDiff[],
          (resourceDiff) => resourceDiff.resource.path,
        )
      : [];
  }, [selectedCommitDetails]);

  const selectedCommitDeletions = useMemo(() => {
    return selectedCommitDetails
      ? sortBy(
          getOrDefault(selectedCommitDetails, DEFAULT_COMMIT_CHANGE_METADATA).changes.filter(
            (resourceDiff) => resourceDiff['@type'] === ResourceDiffType.DELETE,
          ) as DeleteResourceDiff[],
          (resourceDiff) => resourceDiff.originalResource.path,
        )
      : [];
  }, [selectedCommitDetails]);

  const selectedCommitUpdates = useMemo(() => {
    return selectedCommitDetails
      ? sortBy(
          getOrDefault(selectedCommitDetails, DEFAULT_COMMIT_CHANGE_METADATA).changes.filter(
            (resourceDiff) => resourceDiff['@type'] === ResourceDiffType.UPDATE,
          ) as UpdateResourceDiff[],
          (resourceDiff) => resourceDiff.updatedResource.path,
        )
      : [];
  }, [selectedCommitDetails]);

  const isCurrentlyLoading = useMemo(() => {
    return (
      isIdle(branchCommits) ||
      isLoading(branchCommits) ||
      !teamData ||
      (selectedCommitId &&
        (!selectedCommitDetails ||
          isLoading(selectedCommitDetails) ||
          isIdle(selectedCommitDetails)))
    );
  }, [branchCommits, selectedCommitDetails, selectedCommitId, teamData]);

  const onBackButtonClicked = useCallback(() => {
    setSelectedCommitId(undefined);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onBack={selectedCommitId ? onBackButtonClicked : undefined}
      onClose={onClose}
      size="xlarge"
      title={selectedCommitId ? 'Commit details' : 'Commit history'}>
      {isCurrentlyLoading ? (
        <div
          className={sprinkles({
            flexItems: 'center',
            height: 'half',
            marginY: 'sp2',
            width: 'fill',
          })}>
          <Spinner />
        </div>
      ) : null}
      {/** TODO(zifanxiang): Fully render a commit details section */}
      {isError(selectedCommitDetails) ? <div>Error loading commit details</div> : null}
      {isSuccess(selectedCommitDetails) ? (
        <div>
          {selectedCommitCreations.length > 0 ? (
            <>
              <SettingHeader className={sectionHeaderClassName} name="Additions" />
              {selectedCommitCreations.map((resourceDiff) => {
                return (
                  <ResourceControl
                    className={sectionItemClassName}
                    key={resourceDiff.resource.id}
                    resourceDisplay={resourceDiff.resource.path ?? ''}
                    resourceType={resourceDiff.resource['@type']}
                  />
                );
              })}
            </>
          ) : null}
          {selectedCommitDeletions.length > 0 ? (
            <>
              <SettingHeader className={sectionHeaderClassName} name="Deletions" />
              {selectedCommitDeletions.map((resourceDiff) => {
                return (
                  <ResourceControl
                    className={sectionItemClassName}
                    key={resourceDiff.originalResource.id}
                    resourceDisplay={resourceDiff.originalResource.path ?? ''}
                    resourceType={resourceDiff.originalResource['@type']}
                  />
                );
              })}
            </>
          ) : null}
          {selectedCommitUpdates.length > 0 ? (
            <>
              <SettingHeader className={sectionHeaderClassName} name="Updates" />
              {selectedCommitUpdates.map((resourceDiff) => {
                return (
                  <ResourceControl
                    className={sectionItemClassName}
                    key={resourceDiff.updatedResource.id}
                    resourceDisplay={resourceDiff.updatedResource.path ?? ''}
                    resourceType={resourceDiff.updatedResource['@type']}
                  />
                );
              })}
            </>
          ) : null}
        </div>
      ) : null}
      {!selectedCommitId ? (
        isSuccess(branchCommits) && branchCommits.data.length > 0 && teamData ? (
          <div>
            {branchCommits.data.map((commit, index) => (
              <CommitHistoryItem
                commit={commit}
                creationUser={teamData.team_members.find((member) => member.id === commit.userId)}
                key={commit.id}
                onDetailsButtonClicked={(commitId) => setSelectedCommitId(commitId)}
                versionNumber={branchCommits.data.length - index}
              />
            ))}
          </div>
        ) : !isCurrentlyLoading ? (
          <div className={sprinkles({ flexItems: 'center', height: 'fill' })}>
            <div>No commits found</div>
          </div>
        ) : null
      ) : null}
    </Modal>
  );
};

const sectionHeaderClassName = sprinkles({
  marginBottom: 'sp1',
});

const sectionItemClassName = sprinkles({
  marginX: 'sp1.5',
  marginY: 'sp1',
});

interface CommitHistoryItemProps {
  commit: Commit;
  versionNumber: number;
  creationUser: TeamMember | undefined;

  onDetailsButtonClicked: (commitId: string) => void;
}

const CommitHistoryItem: FC<CommitHistoryItemProps> = ({
  commit,
  versionNumber,
  creationUser,
  onDetailsButtonClicked,
}) => {
  return (
    <div className={sprinkles({ marginX: 'sp2' })}>
      <div className={versionPanelRoot}>
        <div>
          <div className={versionPanelNameAndTags}>
            <div>{`Commit ${versionNumber}`}</div>
            {commit.createdAt ? (
              <Tag
                className={sprinkles({
                  marginX: 'sp1',
                })}>
                {getCreatorDetailMessage(creationUser, commit.createdAt)}
              </Tag>
            ) : null}
          </div>
          <div className={changeComment}>{commit.commitMessage}</div>
        </div>
        <IconButton name="eye-open" onClick={() => onDetailsButtonClicked(commit.id ?? '')} />
      </div>
    </div>
  );
};
