import { IconSize } from 'components/ds/Icon';
import { SpinnerSize, SpinnerWidth } from 'components/embed/EmbedSpinner';

export * from './types/FontConfig';

export type GlobalStyleActionColorConfig = {
  default: string;
  buttonColor?: string;
  buttonTextColor?: string;
  secondaryButtonColor?: string;
  secondaryButtonTextColor?: string;
  tertiaryButtonTextColor?: string;
  interactionStateColor?: string;
};

type GlobalStyleBaseConfig = {
  numColumns: number;
  backgroundColor: string;
  actionColor: GlobalStyleActionColorConfig;
  spacing: {
    default: number;
    border?: number;
  };
};

type GlobalStyleContainerConfig = {
  fill: string;
  lineWidth: {
    default: number;
  };
  cornerRadius: {
    default: number;
    buttons?: number;
    inputFields?: number;
  };
  outline: {
    enabled: boolean;
    weight?: number;
    color?: string;
  };
  padding: {
    default: number;
  };
  shadow: {
    enabled: boolean;
    size?: number;
    color?: string;
  };
  tooltip: {
    backgroundColor: string;
  };
};

export type GlobalStyleFontConfig = {
  color?: string;
  font?: string;
  size?: number;
  weight?: number;
};

export type GlobalStyleTextConfig = {
  primaryFont?: string;
  primaryColor: string;
  primaryWeight?: number;
  secondaryFont?: string;
  secondaryColor: string;
  secondaryWeight?: number;
  textSize: number;
  overrides: {
    h1?: GlobalStyleFontConfig;
    h2?: GlobalStyleFontConfig;
    h3?: GlobalStyleFontConfig;
    kpiTitle?: GlobalStyleFontConfig;
    kpiValue?: GlobalStyleFontConfig;
    tableColumnHeader?: GlobalStyleFontConfig;
    body?: GlobalStyleFontConfig;
    smallHeading?: GlobalStyleFontConfig;
    smallBody?: GlobalStyleFontConfig;
    filterLabel?: GlobalStyleFontConfig;
  };
};

export type CategoricalPaletteKey = keyof GlobalStyleCategoricalPaletteConfig;

export const CATEGORICAL_HUE_KEYS: CategoricalPaletteKey[] = [
  'hue1',
  'hue2',
  'hue3',
  'hue4',
  'hue5',
  'hue6',
];

export const OPTIONAL_CATEGORICAL_HUE_KEYS: CategoricalPaletteKey[] = [
  'hue7',
  'hue8',
  'hue9',
  'hue10',
  'hue11',
  'hue12',
];

export type GlobalStyleCategoricalPaletteConfig = {
  hue1: string;
  hue2: string;
  hue3: string;
  hue4: string;
  hue5: string;
  hue6: string;
  hue7?: string;
  hue8?: string;
  hue9?: string;
  hue10?: string;
  hue11?: string;
  hue12?: string;
};

export type GlobalStyleDivergingPaletteConfig = {
  hue1: string;
  hue2: string;
  hue3: string;
};

export type GlobalStyleGradientPaletteConfig = {
  hue1: string;
  hue2: string;
};

type GlobalStyleVisualizationsConfig = {
  categoricalPalette: GlobalStyleCategoricalPaletteConfig;
  divergingPalette: GlobalStyleDivergingPaletteConfig;
  gradientPalette: GlobalStyleGradientPaletteConfig;
};

export type GlobalStyleSpinnerConfig = {
  size: SpinnerSize;
  width: SpinnerWidth;
  color: string;
};

export type GlobalStylePanelErrorConfig = {
  icon: GlobalStyleComponentIconConfig;
  message?: GlobalStyleComponentTextConfig;
  description?: GlobalStyleComponentTextConfig;
};

export type GlobalStyleComponentTextConfig = {
  color?: string;
  size?: number;
  weight?: string;
};

export type GlobalStyleComponentIconConfig = {
  color: string;
  size: IconSize;
};

export type GlobalStyleModalConfig = {
  overlayBackgroundColor?: string;
  overlayOpacity?: string;
};

export type GlobalStyleSingleNumberConfig = { padding?: number };

export type GlobalStyleComponentsConfig = {
  spinner: GlobalStyleSpinnerConfig;
  panelError?: GlobalStylePanelErrorConfig;
  modal?: GlobalStyleModalConfig;
  singleNumber?: GlobalStyleSingleNumberConfig;
};

export type GlobalStyleConfig = {
  base: GlobalStyleBaseConfig;
  container: GlobalStyleContainerConfig;
  text: GlobalStyleTextConfig;
  visualizations: GlobalStyleVisualizationsConfig;
  components?: GlobalStyleComponentsConfig;
};
