import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { InfoCard } from 'components/InfoCard';
import { getEditPageDataPanels } from 'reducers/dashboardEditConfigReducer';
import { ReduxState } from 'reducers/rootReducer';
import { DashboardElement, DashboardElementConfig } from 'types/dashboardTypes';

import { DatasetLink } from './DatasetLink';
import { constructLinkableDataPanelsMap } from './chartLinkUtil';
import { Dataset } from 'actions/datasetActions';
import { convertViewToDataset } from 'pages/dashboardPage/dashboardDatasetEditor/utils';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';
import { ReadAccessComputedView } from 'utils/fido/fidoShimmedTypes';

type Props = {
  datasets: Record<string, Dataset>;
  element: DashboardElement;
  referencedGlobalDatasets: Record<string, ReadAccessComputedView>;

  updateConfig: (config: DashboardElementConfig) => void;
};

export const ChartLinkConfig: FC<Props> = ({
  datasets,
  element,
  referencedGlobalDatasets,
  updateConfig,
}) => {
  const { dataPanels, parentSchemas } = useSelector((state: ReduxState) => {
    return {
      dataPanels: getEditPageDataPanels(state.dashboardEditConfig),
      parentSchemas: getParentSchemasList(state),
    };
  });

  const convertedGlobalDatasets = useMemo(() => {
    return Object.values(referencedGlobalDatasets).map((view) => {
      return convertViewToDataset(
        {
          ...view,
          id: view.id ?? '',
          namespaceId: view.namespaceId ?? '',
        },
        parentSchemas,
      );
    });
  }, [referencedGlobalDatasets, parentSchemas]);

  const allDatasets = useMemo(() => {
    return [...Object.values(datasets), ...convertedGlobalDatasets];
  }, [datasets, convertedGlobalDatasets]);

  const datasetLinkElements = useMemo(() => {
    const datasetIdToDataPanelsMap = constructLinkableDataPanelsMap(dataPanels, element);
    const datasetLinkElems: JSX.Element[] = [];
    allDatasets.forEach((dataset) => {
      const dataPanels = datasetIdToDataPanelsMap[dataset.id] ?? [];
      if (dataPanels.length === 0) return;
      datasetLinkElems.push(
        <DatasetLink
          dataPanels={dataPanels}
          dataset={dataset}
          element={element}
          key={`${element.name}-${dataset.id}`}
          updateConfig={updateConfig}
        />,
      );
    });
    return datasetLinkElems;
  }, [dataPanels, allDatasets, element, updateConfig]);

  return (
    <>
      <InfoCard
        noTopMargin
        text={
          datasetLinkElements.length > 0
            ? LINK_DATASET_TO_CHART_MESSAGE
            : NO_VALID_DATA_PANELS_MESSAGE
        }
      />
      {datasetLinkElements}
    </>
  );
};

const LINK_DATASET_TO_CHART_MESSAGE =
  'To link a chart, start by selecting a column from the dataset it uses.';

const NO_VALID_DATA_PANELS_MESSAGE = 'No valid charts to link to.';
