import axios from 'axios';
import { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ReportedAnalyticsAction } from 'actions/teamActions';
import { EmbeddedDashboardType } from 'components/EmbeddedDashboard/types';
import { ReportedAnalyticActionTypes } from 'constants/reportedAnalyticActionTypes';
import { PAGE_EVENTS } from 'constants/types';
import { AllStates } from 'reducers/rootReducer';
import getFingerprintUser from 'telemetry/fingerprint';
import { clearTelemetrySlice, setAnalyticsVars, setVisitorId } from 'telemetry/telemetryReducer';
import { AdditionalTelemetryEmbedType, TelemetryEmbedType } from 'telemetry/types';
import { OUTPUT_EVENT } from 'types/customEventTypes';
import { DashboardVariable } from 'types/dashboardTypes';
import { getEnvironment, isEnvironmentSecure } from 'utils/environmentUtils';
import { get } from 'utils/standard';

type AnalyticsPostData = {
  event_name: ReportedAnalyticActionTypes;
  user_id: string;
  properties: unknown;
  custom_properties?: unknown;
};

type ErrorPostData = {
  event_name: 'embed-error';
  error: string;
  user_id: string;
  properties: unknown;
  custom_properties?: unknown;
};

export const sendErrorEvent = (
  error: unknown,
  userId: string,
  embedType: TelemetryEmbedType,
  analyticsMetadata: AnalyticsMetadata | undefined,
  metadata: Metadata | undefined,
  analyticsProperties: Metadata | undefined,
  environment: string | undefined,
  analyticsToken: string | null | undefined,
  shouldSendAnalyticsJSEvents: boolean,
) => {
  const postData: ErrorPostData = {
    event_name: 'embed-error',
    error: String(error),
    user_id: userId,
    properties: {
      dashboard_type: embedType,
      embed_source: getEmbedSource(embedType),
      environment,
      ...analyticsMetadata,
      ...metadata,
    },
    custom_properties: analyticsProperties,
  };

  postAnalyticsEvent('error_event', postData, analyticsToken);
  if (shouldSendAnalyticsJSEvents) sendJSEvent(OUTPUT_EVENT.ERROR, postData);
};

type DashboardInfo =
  | { dashboard_template_id: number; dashboard_template_name: string }
  | { report_builder_id: number; report_builder_name: string };

export type AnalyticsMetadata = {
  team_id: number;
  team_name: string;
  customer_id: number;
  customer_name: string;
  customer_provided_id: string;
  customer_is_demo: boolean;
} & DashboardInfo;

export type Metadata = Record<
  string,
  { isStrict: boolean } | boolean | number | string | null | undefined
>;

export const sendAnalyticsEvent = (
  userId: string,
  embedType: TelemetryEmbedType,
  analyticsToken: string | null | undefined,
  eventName: ReportedAnalyticActionTypes,
  analyticsMetadata: AnalyticsMetadata | undefined,
  metadata: Metadata | undefined,
  analyticsProperties: Metadata | undefined,
  environment: string | undefined,
  shouldSendAnalyticsJSEvents?: boolean,
) => {
  if (!shouldSendAnalytics(embedType)) return;

  const isPageEvent = PAGE_EVENTS.has(eventName);
  const url = isPageEvent ? 'page_event' : 'track_event';
  const postData: AnalyticsPostData = {
    event_name: eventName,
    user_id: userId,
    properties: {
      dashboard_type: embedType,
      embed_source: getEmbedSource(embedType),
      environment,
      ...analyticsMetadata,
      ...metadata,
    },
    custom_properties: analyticsProperties,
  };

  postAnalyticsEvent(url, postData, analyticsToken);
  if (shouldSendAnalyticsJSEvents) sendJSEvent(OUTPUT_EVENT.ANALYTICS_EVENT, postData);
};

const postAnalyticsEvent = (
  url: string,
  postData: AnalyticsPostData | ErrorPostData,
  analytics_token: string | null | undefined,
) => {
  axios({
    url: `${process.env.REACT_APP_API_URL}analytics/${url}/`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Analytics-Token': analytics_token,
    },
    data: postData,
  }).catch(() => {
    // swallow error
    return;
  });
};

const sendJSEvent = (event: string, detail: { properties: unknown }) => {
  const isIframe = get(detail, 'properties.embed_source') === 'iframe';
  if (isIframe) {
    window.parent.postMessage({ event, detail }, '*');
  } else {
    window.dispatchEvent(new CustomEvent(event, { detail }));
  }
};

export const isValidHttpUrl = (s: string) => {
  let url;

  try {
    url = new URL(s);
  } catch {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const isAnalyticsEventSelected = (actions: ReportedAnalyticsAction[], actionName: string) =>
  !!actions.find(({ name }) => name === actionName);

export const getEmbedSource = (embedType: TelemetryEmbedType): TelemetryEmbedType =>
  embedType === EmbeddedDashboardType.SHARED || embedType === AdditionalTelemetryEmbedType.CHART
    ? EmbeddedDashboardType.SHARED
    : embedType;

type AnalyticsProps = {
  pageViewEvent: ReportedAnalyticActionTypes;
  environment: string | undefined;
  embedType: TelemetryEmbedType;
  isProduction?: DashboardVariable;
  isStrict?: boolean;
  analyticsProperties?: Metadata;
};

export function useSetupAnalytics({
  environment,
  embedType,
  pageViewEvent,
  isProduction,
  isStrict,
  analyticsProperties,
}: AnalyticsProps) {
  const dispatch = useDispatch();

  const {
    analyticsMetadata,
    analyticsToken,
    visitorId,
    shouldSendAnalyticsJSEvents,
    reportActions,
  } = useSelector(
    (state: AllStates) => ({
      analyticsMetadata: state.analytics.analyticsMetadata,
      analyticsToken: state.analytics.analyticsToken,
      visitorId: state.analytics.visitorId,
      shouldSendAnalyticsJSEvents: state.analytics.shouldSendAnalyticsJSEvents ?? false,
      reportActions: state.analytics.reportActions ?? [],
    }),
    shallowEqual,
  );

  const noAnalyticsNeeded = !shouldSendAnalytics(embedType);

  // use this to stop unselected JS events from firing since they never hit the backend
  const isPageEventSelected = useMemo(
    () => isAnalyticsEventSelected(reportActions, pageViewEvent),
    [reportActions, pageViewEvent],
  );

  useEffect(() => {
    return () => {
      dispatch(clearTelemetrySlice());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(setAnalyticsVars({ embedType, environment, analyticsProperties }));
  }, [dispatch, embedType, environment, analyticsProperties]);

  useEffect(() => {
    if (noAnalyticsNeeded || !analyticsMetadata || !analyticsToken) return;
    (async () => {
      const fingerprintUser = await getFingerprintUser();
      dispatch(setVisitorId(fingerprintUser.visitorId));
      sendAnalyticsEvent(
        fingerprintUser.visitorId,
        embedType,
        analyticsToken,
        pageViewEvent,
        analyticsMetadata,
        {
          is_strict: isStrict ? { isStrict: true } : undefined,
          is_production: isProduction ? isProduction === 'true' : undefined,
          environment,
        },
        analyticsProperties,
        environment,
        shouldSendAnalyticsJSEvents && isPageEventSelected,
      );
    })();
  }, [
    dispatch,
    pageViewEvent,
    analyticsMetadata,
    embedType,
    environment,
    analyticsToken,
    noAnalyticsNeeded,
    isProduction,
    isStrict,
    analyticsProperties,
    shouldSendAnalyticsJSEvents,
    isPageEventSelected,
  ]);

  return noAnalyticsNeeded || visitorId !== null;
}

const allowedAnalyticsSet: Set<TelemetryEmbedType> = new Set([
  EmbeddedDashboardType.EMBEDDED,
  EmbeddedDashboardType.IFRAME,
  EmbeddedDashboardType.SHARED,
  AdditionalTelemetryEmbedType.CHART,
  EmbeddedDashboardType.PORTAL,
]);

// Analytics should only be sent in prod
export const shouldSendAnalytics = (embedType: TelemetryEmbedType) =>
  allowedAnalyticsSet.has(embedType) && getEnvironment() === 'production' && !isEnvironmentSecure();
