import { FC, useState } from 'react';

import { Checkbox, sprinkles } from 'components/ds';
import { ChartColumnInfo } from 'constants/types';

interface Props {
  columnInfo: ChartColumnInfo;
  onColumnChecked: (checked: boolean, columnInfo: ChartColumnInfo) => void;
}

/**
 * Component that allows for configuring a particular column on a data panel to columns within
 * datasets for drilldowns.
 */
export const ColumnTargetRow: FC<Props> = ({ columnInfo, onColumnChecked }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    onColumnChecked(!isChecked, columnInfo);
  };
  return (
    <div className={sprinkles({ flexItems: 'alignCenterBetween', marginBottom: 'sp1' })}>
      <Checkbox isChecked={isChecked} onChange={handleCheckboxChange} />
      <div>{columnInfo.friendly_name}</div>
    </div>
  );
};
