import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { ResourceEnvironmentTagDto } from '@explo/embeddo-api';
import { Checkbox, Tag, sprinkles } from 'components/ds';
import { updateResourceEnvironmentTag } from 'reducers/thunks/resourceEnvironmentTagThunks';
import { ResourcePageType } from 'types/exploResource';
import { isVersionTagged } from 'utils/exploResourceUtils';

type Props = {
  pageType: ResourcePageType;
  tags: ResourceEnvironmentTagDto[];
  resourceId: number;
  versionId: number;
};

export const PublishToDropdown: FC<Props> = ({ pageType, tags, versionId, resourceId }) => {
  const dispatch = useDispatch();

  return (
    <div
      className={sprinkles({ flexItems: 'column', gap: 'sp.5', paddingX: 'sp2', width: 'fill' })}>
      {tags.map((tag) => (
        <div className={sprinkles({ flexItems: 'alignCenterBetween', height: 32 })} key={tag.id}>
          <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1', paddingY: 'sp.5' })}>
            <Checkbox
              isChecked={isVersionTagged(pageType, resourceId, versionId, tag)}
              onChange={() => {
                dispatch(
                  updateResourceEnvironmentTag({
                    id: tag.id,
                    request: {
                      resourceVersionId: versionId,
                      resourceType: pageType === ResourcePageType.EXPLORE ? 'dashboard' : 'report',
                    },
                  }),
                );
              }}
            />
            <Tag
              inverted
              backgroundColor={tag.colorHex}
              className={sprinkles({ display: 'block', truncateText: 'ellipsis' })}>
              {tag.name}
            </Tag>
          </div>
        </div>
      ))}
      <div
        className={sprinkles({
          body: 'b3',
          color: 'contentTertiary',
          textAlign: 'center',
          paddingTop: 'sp.5',
        })}>
        Edit/Add environments in <Link to={'/settings/environment-tags'}>settings page</Link>
      </div>
    </div>
  );
};
