import { FC } from 'react';

import { Checkbox } from 'components/ds';
import { CustomerSelect } from './selects/CustomerSelect';
import { GroupTagSelect } from './selects/GroupTagSelect';
import { HierarchySelect } from './selects/HierarchySelect';

import * as styles from './styles.css';
import { FlattenedRecipients } from './types';
import { RecipientFilterState } from '../ExportList/types';

type Props = {
  selected: RecipientFilterState;
  onUpdate: (state: RecipientFilterState) => void;
};

export const RecipientSelect: FC<Props> = ({ selected, onUpdate }) => {
  const isAll = selected.all === true;

  const handleSendToAllChange = () => {
    onUpdate({
      all: !isAll,
      specific: selected.specific,
    });
  };

  const handleSpecificUpdate = (recipients: FlattenedRecipients) => {
    onUpdate({
      all: selected.all,
      specific: {
        hierarchyIds: recipients.hierarchyIds,
        groupTagIds: recipients.groupTagIds,
        customerIds: recipients.customerIds,
      },
    });
  };

  return (
    <div className={styles.recipientSelectContent}>
      <div className={styles.recipientSelectCheckboxes}>
        <Checkbox isChecked={isAll} label="Send to All" onChange={handleSendToAllChange} />
      </div>
      <div className={styles.hierarchyGroupSelectContainer}>
        <HierarchySelect
          onUpdate={handleSpecificUpdate}
          selected={{
            hierarchyIds: selected.specific?.hierarchyIds ?? [],
            groupTagIds: selected.specific?.groupTagIds ?? [],
            customerIds: selected.specific?.customerIds ?? [],
          }}
        />
        <div className={styles.groupTagSelectContainer}>
          <GroupTagSelect
            onUpdate={handleSpecificUpdate}
            selected={{
              hierarchyIds: selected.specific?.hierarchyIds ?? [],
              groupTagIds: selected.specific?.groupTagIds ?? [],
              customerIds: selected.specific?.customerIds ?? [],
            }}
          />
        </div>
      </div>
      <CustomerSelect
        onUpdate={handleSpecificUpdate}
        selected={{
          hierarchyIds: selected.specific?.hierarchyIds ?? [],
          groupTagIds: selected.specific?.groupTagIds ?? [],
          customerIds: selected.specific?.customerIds ?? [],
        }}
      />
    </div>
  );
};
