import { ExportFormat } from '@explo-tech/fido-api';
import {
  ImageEmailConfiguration,
  ImageExportMetadata,
  LoggingMetadata,
  ReportBuilderEmailContentArgs,
  TabularEmailConfiguration,
} from '@explo/rover-api';
import { FetchCustomerReportsTeamData } from 'actions/customerReportActions';
import { ExportType } from 'actions/exportActions';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { Team } from 'actions/teamActions';
import { DEFAULT_NO_REPLY_EMAIL } from 'constants/emailConstants';
import { DashboardLayoutRequestInfo } from 'reducers/dashboardLayoutReducer';
import { ExportBody } from 'reportBuilderContent/exportTypes';
import { ReportType, SelectedReportType } from 'reportBuilderContent/reducers/types';
import { TelemetryReducerState } from 'telemetry/telemetryReducer';

export const getFromEmail = ({
  emailOverride,
  displayName,
}: {
  emailOverride: string | undefined;
  displayName: string | undefined;
}) => {
  const email = emailOverride || DEFAULT_NO_REPLY_EMAIL;

  return displayName ? `${displayName} <${email}>` : email;
};

const getBaseLoggingMetadata = ({
  team,
  analytics,
}: {
  team: Pick<Team, 'team_name' | 'id'>;
  analytics: Pick<TelemetryReducerState, 'visitorId' | 'analyticsProperties'>;
}): Pick<LoggingMetadata, 'team' | 'teamId' | 'userId' | 'customerId'> => ({
  teamId: team.id.toString(),
  team: team.team_name,
  userId: analytics.visitorId ?? undefined,
  customerId: analytics.analyticsProperties?.['customer_id']?.toString(),
});

export const getReportBuilderLoggingMetadata = (
  analytics: Pick<TelemetryReducerState, 'visitorId' | 'analyticsProperties'>,
  team: Pick<FetchCustomerReportsTeamData, 'id' | 'name'>,
  reportType: ReportType | undefined,
): LoggingMetadata => ({
  ...getBaseLoggingMetadata({ analytics, team: { id: team.id, team_name: team.name } }),
  emailType: reportType === ReportType.CUSTOMER_REPORT ? 'customer_report' : 'built_in',
});

export const getExploreLoggingMetadata = (
  analytics: Pick<TelemetryReducerState, 'visitorId' | 'analyticsProperties'>,
  team: Pick<DashboardLayoutRequestInfo, 'teamId' | 'teamName'>,
): LoggingMetadata => ({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  ...getBaseLoggingMetadata({ analytics, team: { id: team.teamId!, team_name: team.teamName! } }),
  emailType: 'export',
});

export const getEmailConfiguration = (
  team: FetchCustomerReportsTeamData,
  exportBody: ExportBody,
): TabularEmailConfiguration | ImageEmailConfiguration => ({
  fromEmail: getFromEmail({
    emailOverride: team.configuration?.email_from_email,
    displayName: team.configuration?.email_from_name,
  }),
  toEmails: exportBody.recipients,
  subject: exportBody.subject,
  emailContentArgs: {
    ...getTestEmailContent(exportBody.message),
    '@type': 'report-builder',
  },
});

const getTestEmailContent: (
  message: string | undefined,
) => Pick<ReportBuilderEmailContentArgs, 'htmlContent' | 'plainTextContent'> = (message) => ({
  // TODO we need to sanitize this email message likely
  htmlContent: `<div>${message ? `${message}<br><br>` : ''}This is a test email you requested to send</div>`,
  plainTextContent: 'This is a test email you requested to send',
});

export const getDataset = (
  datasets: Record<string, ReportBuilderDataset> | undefined,
  datasetId: string | undefined,
): ReportBuilderDataset => {
  if (!datasets || !datasetId) throw new Error('Missing datasets or dataset id');
  const dataset = datasets[datasetId];
  if (!dataset) throw new Error('Dataset not found');
  return dataset;
};

export const createScreenshotExportMetadata = (
  exportBody: ExportBody,
  team: FetchCustomerReportsTeamData,
): ImageExportMetadata => ({
  exportFormat: getScreenshotExportType(exportBody.export_type),
  imageScreenshotWidth: null,
  emailConfiguration: getEmailConfiguration(team, exportBody) as ImageEmailConfiguration,
});

interface ReportIdParams {
  customer_report_id?: number | undefined;
  built_in_id?: string | undefined;
  environment_tag_id: number | null;
}

export const getReportIdParams = (
  selectedReport: SelectedReportType | null,
  envTagId: number | null,
): ReportIdParams => {
  if (!selectedReport) throw new Error('Missing selected report');

  if (selectedReport.type === ReportType.CUSTOMER_REPORT) {
    return {
      customer_report_id: selectedReport.id,
      environment_tag_id: envTagId,
    };
  } else if (selectedReport.type === ReportType.BUILT_IN) {
    return {
      built_in_id: selectedReport.id,
      environment_tag_id: envTagId,
    };
  }
  throw new Error('Invalid report type');
};

export const getScreenshotExportType = (exportType: ExportType) => {
  if (exportType === ExportType.Image) return 'IMAGE';
  if (exportType === ExportType.PDF) return 'PDF';
  throw new Error('Invalid export type');
};

export const getTabularExportFormat = (exportType: ExportType) => {
  switch (exportType) {
    case ExportType.CSV:
      return ExportFormat.CSV;
    case ExportType.XLSX:
      return ExportFormat.XLSX;
    default:
      throw new Error(`Invalid file format: ${exportType}`);
  }
};
