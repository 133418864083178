import { createAction } from '@reduxjs/toolkit';

import { defineAPIAction, defineAPIPostAction } from 'actions/actionUtils';
import { ACTION } from 'actions/types';
import { PLAN_TYPES_TYPE } from 'constants/paymentPlanConstants';
import { FeatureFlags, Entitlements, Configuration } from 'types/flags';
import { FetchCustomerData } from 'reducers/thunks/customerThunks';

import { TeamMember } from './userActions';

export interface Team {
  id: number;
  team_name: string;
  team_members: TeamMember[];
  api_tokens: ApiToken[];
  analytics_reporting_actions: ReportedAnalyticsAction[];
  analytics_reporting_url: string | null;
  analytics_reporting_token: string | null;
  should_send_js_analytics_events?: boolean;
  default_currency_code: string;
  default_locale_code: string;
  use_browser_locale: boolean;
  custom_map_box_token?: string | null;
  access_groups: AccessGroup[];
  favicon_url: string | null;
  dashboard_logo_url: string | null;
  portal_site_title: string | null;
  portal_login_text: string | null;
  support_email: string | null;
  payment_plan: PLAN_TYPES_TYPE;
  internal_analytics_user_group_token: string | null;
  archetype_properties?: ArchetypeProperty[];
}

export interface EmbedTeam {
  id: number;
  team_name: string;
  default_currency_code: string;
  default_locale_code: string;
  use_browser_locale: boolean;
  support_email: string | null;
  payment_plan: PLAN_TYPES_TYPE;
  explo_analytics_token: string;
  feature_flags: FeatureFlags;
  configuration: Configuration;
  entitlements: Entitlements;
  archetype_properties?: ArchetypeProperty[];
  should_send_js_analytics_events?: boolean;
  analytics_reporting_actions?: ReportedAnalyticsAction[];
  custom_map_box_token?: string | null;
}

export interface AllowlistDomain {
  id: number;
  name: string;
}
export interface ReportedAnalyticsAction {
  id: number;
  name: string;
}

export interface ExploreEmailCadence {
  id: number;
  dashboard_template_id: number;
  minute: number;
  hour: number;
  week_of_month?: number;
  day_of_week?: number;
  subject?: string;
  from_email?: string;
  timezone?: string;
  custom_url?: string;
  disable_link?: boolean;
}

interface GroupTagSummary {
  group_tag_id: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Properties = Record<string, any>;

export interface SummaryCustomer {
  id: number;
  name: string;
  provided_id: string;
  parent_provided_id?: string;
  parent_names?: string[]; // TODO: made required after deploy
  access_group_id: number;
  computed_parent_schema_datasource_mapping: Record<string, string>;
  computed_properties: Properties;
  computed_group_tags: number[];
  hierarchy_level_id: number;
}

export interface Customer extends SummaryCustomer {
  parent_schema_datasource_mapping: Record<string, string>;
  properties: Properties;
  token: string;
  is_demo_group: boolean;
  group_tags: GroupTagSummary[];
  emails: string[];
  children_count: number;
}

export interface GroupTag {
  id: number;
  name: string;
}

export interface HierarchyLevel {
  id: number;
  name: string;
  level: number;
}

export interface HierarchyLevelCountInfo {
  hierarchy_level_id: number;
  count: number;
  is_demo_group: boolean;
}

export interface EmbedCustomer {
  id: number;
  name: string;
  provided_id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties: Record<string, any>;
  is_demo_group: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  computed_properties: Record<string, any>;
}

export interface EndUser {
  id: number;
  email: string;
  permissioned_dashboard_id: number;
  group_id: number;
}

export interface ApiToken {
  id: number;
  value: string;
  access_group_id: number;
  is_read_only: boolean;
}

export interface AccessGroup {
  id: number;
  name: string;
  api_tokens: ApiToken[];
  data_source_ids: number[];
  default_data_source_ids: number[];
  customers_count: number;
}

export interface ArchetypeProperty {
  id: number;
  name: string;
  hierarchy_level_id: number;
  deprecated: boolean;
}

export interface DashboardAttribute {
  id: number;
  name: string;
  values: DashboardAttributeValue[];
}

export interface DashboardAttributeValue {
  id: number;
  value: string;
  color_hex: string;
}

export interface InvitedUser {
  email: string;
  role_names: string[];
}

export const switchCustomer = createAction<FetchCustomerData>(ACTION.SWITCH_CUSTOMER);

// Requests

type FetchUserTeamData = {
  team: Team;
  invited_users: InvitedUser[];
};

export const {
  actionFn: fetchUserTeam,
  requestAction: fetchUserTeamRequest,
  successAction: fetchUserTeamSuccess,
} = defineAPIAction<FetchUserTeamData>(ACTION.FETCH_USER_TEAM, 'teams', '', 'GET');

type InviteTeammatesBody = {
  invites: InvitedUser[];
  team_id: number;
  inviter: string;
  resend: boolean;
};

type InviteTeammatesData = { invited_users: InvitedUser[] };

export const { actionFn: inviteTeammates, successAction: inviteTeammatesSuccess } =
  defineAPIPostAction<InviteTeammatesBody, InviteTeammatesData>(
    ACTION.INVITE_TEAMMATE,
    'teams',
    'invite_users',
    'POST',
  );

type CancelInviteBody = {
  email: string;
  team_id: number;
};

export const { actionFn: cancelTeamInvite, successAction: cancelTeamInviteSuccess } =
  defineAPIPostAction<CancelInviteBody, {}>(
    ACTION.CANCEL_TEAM_INVITE,
    'teams',
    'cancel_invite',
    'POST',
  );

export type SaveAnalyticsConfigBody = {
  analytics_reporting_actions?: string[];
  analytics_reporting_url?: string | null;
  analytics_reporting_token?: string | null;
  should_send_js_analytics_events?: boolean;
};

type SaveAnalyticsConfigData = {
  analytics_reporting_actions: ReportedAnalyticsAction[];
  analytics_reporting_url: string | null;
  analytics_reporting_token: string | null;
  should_send_js_analytics_events?: boolean;
};

export const { actionFn: saveAnalyticsConfig, successAction: saveAnalyticsConfigSuccess } =
  defineAPIPostAction<SaveAnalyticsConfigBody, SaveAnalyticsConfigData>(
    ACTION.SAVE_ANALYTICS_CONFIG,
    'teams',
    'save_analytics_config',
    'POST',
  );

type SaveCurrencyCodeBody = {
  currency_code: string;
};

type SaveCurrencyCodeData = { currency_code: string };

export const { actionFn: saveDefaultCurrencyCode, successAction: saveDefaultCurrencyCodeSuccess } =
  defineAPIPostAction<SaveCurrencyCodeBody, SaveCurrencyCodeData>(
    ACTION.SAVE_DEFAULT_CURRENCY_CODE,
    'teams',
    'save_default_currency_code',
    'POST',
  );

type SaveLocaleCodeData = { locale_code: string };

export const { actionFn: saveDefaultLocaleCode, successAction: saveDefaultLocaleCodeSuccess } =
  defineAPIPostAction<SaveLocaleCodeData, SaveLocaleCodeData>(
    ACTION.SAVE_DEFAULT_LOCALE_CODE,
    'teams',
    'save_default_locale_code',
    'POST',
  );

type SaveBooleanSettingData = { setting: boolean };

export const { actionFn: saveUseBrowserLocale, successAction: saveUseBrowserLocaleSuccess } =
  defineAPIPostAction<SaveBooleanSettingData, SaveBooleanSettingData>(
    ACTION.SAVE_USE_BROWSER_LOCALE,
    'teams',
    'save_use_browser_locale',
    'POST',
  );

type SaveCustomMapBoxTokenData = { token: string };

export const { actionFn: saveCustomMapBoxToken, successAction: saveCustomMapBoxTokenSuccess } =
  defineAPIPostAction<SaveCustomMapBoxTokenData, SaveCustomMapBoxTokenData>(
    ACTION.SAVE_CUSTOM_MAPBOX_TOKEN,
    'teams',
    'save_custom_map_box_token',
    'POST',
  );

export type SaveShareMetadataBody = {
  favicon_url?: string;
  dashboard_logo_url?: string;
  portal_site_title?: string;
  portal_login_text?: string;
};

export const { actionFn: saveShareMetadata, successAction: saveShareMetadataSuccess } =
  defineAPIPostAction<SaveShareMetadataBody, {}>(
    ACTION.SAVE_SHARE_METADATA,
    'teams',
    'save_share_metadata',
    'POST',
  );

export const { actionFn: saveFromEmailName, successAction: saveFromEmailNameSuccess } =
  defineAPIPostAction<{ name: string }, {}>(
    ACTION.SAVE_FROM_EMAIL_NAME,
    'teams',
    'save_from_email_name',
    'POST',
  );

type EditSupportEmailBody = { support_email: string | null };

export const { actionFn: uploadSupportEmail, successAction: uploadSupportEmailSuccess } =
  defineAPIPostAction<EditSupportEmailBody, {}>(
    ACTION.UPLOAD_SUPPORT_EMAIL,
    'teams',
    'upload_support_email',
    'POST',
  );

export const { actionFn: removeTeammate, successAction: removeTeammateSuccess } =
  defineAPIPostAction<{ user_id: number; team_id: number }, { user_id: number }>(
    ACTION.REMOVE_TEAMMATE,
    'teams',
    'delete_team_member',
    'POST',
  );
