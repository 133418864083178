import { Layout } from '@explo-tech/react-grid-layout';
import { createAction } from '@reduxjs/toolkit';

import { OPERATION_TYPES } from '@explo/data';

import { defineAPIAction, defineAPIPostAction } from 'actions/actionUtils';
import {
  DASHBOARD_ELEMENT_TYPES,
  DASHBOARD_LAYOUT_CONFIG,
  DashboardElement,
  DashboardElementConfig,
  VIEW_MODE,
} from 'types/dashboardTypes';
import { DashboardVersion, DashboardVersionMetadata } from 'types/dashboardVersion';
import {
  DashboardVersionConfig,
  VersionedComputedViewReference,
} from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';

import { DashboardVersionHierarchyResponse } from './dashboardActions';
import { ACTION } from './types';

export type CreateDashboardDataPanelArgs = {
  id: string;
  newLayout: Layout[];
  datasetId: string;
  vizType: OPERATION_TYPES;
  containerId?: string;
  name?: string;
  globalDatasetReference?: VersionedComputedViewReference;
};

export const createDashboardDataPanel = createAction<CreateDashboardDataPanelArgs>(
  ACTION.CREATE_DASHBOARD_DATA_PANEL,
);

type DuplicateDashboardItemArgs = {
  dashboardItem: DataPanelTemplate | DashboardElement;
  itemType: DASHBOARD_ELEMENT_TYPES;
  dashId: number;
};

export const duplicateDashboardItem = createAction<DuplicateDashboardItemArgs>(
  ACTION.DUPLICATE_DASHBOARD_ITEM,
);

export const createDashboardDataset = createAction<{
  name: string;
  dashId: number;
  parentSchemaId: number;
}>(ACTION.CREATE_DATASET);

export const deleteDataPanel = createAction<{ id: string }>(ACTION.DELETE_DATA_PANEL);

type UpdateElementConfigArgs = {
  elementId: string;
  elementLocation: DASHBOARD_LAYOUT_CONFIG;
  newElementType?: DASHBOARD_ELEMENT_TYPES;
  config?: DashboardElementConfig;
};

export const updateElementConfig = createAction<UpdateElementConfigArgs>(
  ACTION.UPDATE_ELEMENT_CONFIG,
);

export const updateElementLocation = createAction<{
  elementId: string;
  newLocation: DASHBOARD_LAYOUT_CONFIG;
}>(ACTION.UPDATE_ELEMENT_LOCATION);

type ToggleFilterLinkArgs = {
  dataPanelId: string;
  elementId: string;
};

export const toggleFilterLink = createAction<ToggleFilterLinkArgs>(ACTION.TOGGLE_FILTER_LINK);

export const deleteDashboardElement = createAction<{
  elementId: string;
  elementType: DASHBOARD_ELEMENT_TYPES;
}>(ACTION.DELETE_DASHBOARD_ELEMENT);

export const {
  actionFn: createNewDashboardVersion,
  successAction: createNewDashboardVersionSuccess,
} = defineAPIPostAction<
  { configuration?: DashboardVersionConfig; version_number: number },
  {
    dashboard_version: DashboardVersion;
    dashboard_version_hierarchy?: DashboardVersionHierarchyResponse;
  }
>(ACTION.CREATE_NEW_DASHBOARD_VERSION, 'dashboards', 'create_new_dashboard_version', 'POST');

type SwitchEditingDashboardVersionArgs = {
  dashboardVersion: DashboardVersion;
  versionHierarchy?: DashboardVersionHierarchyResponse;
};

export const switchCurrentlyEditingDashboardVersion =
  createAction<SwitchEditingDashboardVersionArgs>(
    ACTION.SWITCH_CURRENTLY_EDITING_DASHBOARD_VERSION,
  );

type PublishNewDashboardVersionBody = {
  config: DashboardVersionConfig;
  version_number: number;
  change_comments: string;
};

type PublishNewDashboardVersionResponse = {
  dashboard_version: DashboardVersion;
  dashboard_version_hierarchy?: DashboardVersionHierarchyResponse;
};

export const {
  actionFn: publishNewDashboardVersion,
  successAction: publishNewDashboardVersionSuccess,
} = defineAPIPostAction<PublishNewDashboardVersionBody, PublishNewDashboardVersionResponse>(
  ACTION.PUBLISH_NEW_DASHBOARD_VERSION,
  'dashboards',
  'publish_new_dashboard_version',
  'POST',
);

type FetchDashboardVersionsMetadataResponse = {
  versions_metadata: DashboardVersionMetadata[];
};

export const {
  actionFn: fetchDashboardVersionsMetadata,
  requestAction: fetchDashboardVersionsMetadataRequest,
  successAction: fetchDashboardVersionsMetadataSuccess,
  errorAction: fetchDashboardVersionsMetadataError,
} = defineAPIAction<FetchDashboardVersionsMetadataResponse>(
  ACTION.FETCH_DASHBOARD_VERSIONS_METADATA,
  'dashboards',
  'get_all_versions_metadata',
  'GET',
);

type DashboardVersionForPreview = Pick<DashboardVersion, 'id' | 'version_number' | 'is_draft'>;

export type FetchDashboardVersionsForPreviewResponse = {
  tags: {
    id: number;
    team_id: number;
    dashboard_version_mapping: Record<string, number>;
    report_builder_version_mapping: Record<string, number>;
    name: string;
    is_prod: boolean;
    color_hex: string;
  }[];
  versions: DashboardVersionForPreview[];
};

export const { actionFn: fetchDashboardVersionsForPreview } =
  defineAPIAction<FetchDashboardVersionsForPreviewResponse>(
    ACTION.FETCH_DASHBOARD_VERSIONS_FOR_PREVIEW,
    'dashboards',
    'list_versions_for_preview',
    'GET',
  );

export const saveDashboardElementUpdates = createAction<{
  id: string;
  config?: DashboardElementConfig;
  name?: string;
  elementLocation: DASHBOARD_LAYOUT_CONFIG;
}>(ACTION.SAVE_DASHBOARD_ELEMENT_UPDATES);

type CreateDashboardElementArgs = {
  id: string;
  elementType: DASHBOARD_ELEMENT_TYPES;
  newLayout: Layout[];
  containerId?: string;
};

export const createDashboardElement = createAction<CreateDashboardElementArgs>(
  ACTION.CREATE_DASHBOARD_ELEMENT,
);

export const clearDashboardConfigReducer = createAction(ACTION.CLEAR_DASHBOARD_CONFIG_REDUCER);

export const {
  actionFn: deleteCurrentDashboardDraft,
  successAction: deleteCurrentDashboardDraftSuccess,
} = defineAPIPostAction<
  { version_number: number; is_root: boolean },
  {
    previous_version: DashboardVersion;
    dashboard_version_hierarchy: DashboardVersionHierarchyResponse;
  }
>(ACTION.DELETE_CURRENT_DASHBOARD_DRAFT, 'dashboards', 'delete_draft', 'POST');

export const { actionFn: previewVersion } = defineAPIAction<{
  preview_version: DashboardVersion;
  dashboard_version_hierarchy?: DashboardVersionHierarchyResponse;
}>(ACTION.FETCH_PREVIEW_VERSION, 'dashboards', 'preview_version', 'GET');

type ToggleElemVisibilityData = {
  id: string;
  layoutType: VIEW_MODE;
};

export const toggleElementVisibilityForSecondaryLayout = createAction<ToggleElemVisibilityData>(
  ACTION.TOGGLE_ELEM_VISIBILITY_FOR_SECONDARY_LAYOUT,
);

// if removeElem is false, it is an addition action
export const updateElementContainerLocation = createAction<{
  elementId: string;
  containerId: string;
  removeElem?: boolean;
  isDataPanel?: boolean;
}>(ACTION.UPDATE_ELEMENT_CONTAINER_LOCATION);
