import { PivotTableV2DataInstructions } from '@explo/data';

// This regex matches any character that is not a letter, number, hyphen, underscore, period, or space
const fileNameRegex = new RegExp(/[^\p{L}\p{N}\-_. ]/gu);
export const sanitizeFileNameForExports = (fileName: string) => {
  const processed = makeAbsentOrNotBlank(fileName);

  if (processed == null) return null;

  return processed.replaceAll(fileNameRegex, '').trim();
};

export const makeAbsentOrNotBlank = (value: string | null | undefined) => {
  if (value == null) return null;

  return value.trim().length === 0 ? null : value;
};

export const canPivotTableSummarize = (
  instructions:
    | Pick<PivotTableV2DataInstructions, 'aggregations' | 'rowGroupBys' | 'colGroupBys'>
    | undefined,
): boolean => {
  if (!instructions) return false;
  const { aggregations, rowGroupBys, colGroupBys } = instructions;
  return !!aggregations.length && !!rowGroupBys.length && !!colGroupBys.length;
};
