/**
 * Parses a time input string in the format "HH:MM AM/PM" and returns an object with hour, minute, and isPm properties.
 *
 * @param value - The time input string to parse.
 * @returns An object with hour, minute, and isPm properties, or null if the input is invalid.
 */
export const parseTimeInput = (value: string) => {
  const timeRegex = /^(1[0-2]|0?[1-9]):([0-5][0-9])\s?(AM|PM)$/i;
  const match = value.match(timeRegex);

  if (match) {
    const hour = parseInt(match[1], 10);
    const minute = parseInt(match[2], 10);
    const isPm = match[3].toUpperCase() === 'PM';

    return { hour, minute, isPm };
  } else {
    return null;
  }
};
