import { Team } from 'actions/teamActions';
import { User } from 'actions/userActions';
import { PERMISSIONED_ACTIONS, PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import { getEnvironment, isEnvironmentSecure } from 'utils/environmentUtils';
import { isReportBuilderEnabled } from 'utils/paymentPlanUtils';
import { doesUserHavePermission } from 'utils/permissionUtils';

import { NAV_ITEMS } from './constants';

export const getOrderedSetupItems = (currentUser: User) => {
  const orderedSetupItems = [];

  const userHasDataSourceReadPermission = doesUserHavePermission(
    currentUser.permissions[PERMISSIONED_ENTITIES.DATA_SOURCE],
    PERMISSIONED_ACTIONS.READ,
  );
  if (userHasDataSourceReadPermission) {
    orderedSetupItems.push(NAV_ITEMS.DATA);

    if (currentUser.team?.feature_flags.enable_data_library) {
      orderedSetupItems.push(NAV_ITEMS.DATA_LIBRARY);
    }
  }

  if (
    doesUserHavePermission(
      currentUser.permissions[PERMISSIONED_ENTITIES.CUSTOMER],
      PERMISSIONED_ACTIONS.READ,
    ) ||
    doesUserHavePermission(
      currentUser.permissions[PERMISSIONED_ENTITIES.END_USER_GROUP],
      PERMISSIONED_ACTIONS.READ,
    )
  )
    orderedSetupItems.push(NAV_ITEMS.CUSTOMERS);

  if (
    doesUserHavePermission(
      currentUser.permissions[PERMISSIONED_ENTITIES.CUSTOM_STYLES],
      PERMISSIONED_ACTIONS.READ,
    )
  )
    orderedSetupItems.push(NAV_ITEMS.STYLES);

  return orderedSetupItems;
};

export const getOrderedToolItems = (teamData?: Team) => {
  const orderedToolItems = [];

  if (
    (teamData?.internal_analytics_user_group_token || getEnvironment() === 'development') &&
    !isEnvironmentSecure()
  ) {
    orderedToolItems.push(NAV_ITEMS.ANALYTICS);
  }

  return orderedToolItems;
};

export const getOrderedShareItems = (currentUser: User) => {
  const orderedShareItems = [];

  if (currentUser.team?.feature_flags.enable_schedules) {
    orderedShareItems.push(NAV_ITEMS.EXPORTS);
  }

  return orderedShareItems;
};

export const getOrderedDevItems = (currentUser: User) => {
  const orderedDevItems = [];

  if (currentUser.is_superuser) {
    orderedDevItems.push(NAV_ITEMS.SUPERUSER);
  }

  return orderedDevItems;
};

export const getOrderedProductItems = (currentUser: User) => {
  const { permissions } = currentUser;
  const orderedProductItems = [];

  if (
    doesUserHavePermission(permissions[PERMISSIONED_ENTITIES.DASHBOARD], PERMISSIONED_ACTIONS.READ)
  ) {
    orderedProductItems.push(NAV_ITEMS.DASHBOARDS);
  }

  if (
    doesUserHavePermission(
      permissions[PERMISSIONED_ENTITIES.REPORT_BUILDER],
      PERMISSIONED_ACTIONS.READ,
    )
  ) {
    const reportBuilderInfo = {
      ...NAV_ITEMS.REPORT_BUILDER,
      additionalInfo: isReportBuilderEnabled(currentUser) ? undefined : 'Upgrade',
    };
    orderedProductItems.push(reportBuilderInfo);
  }

  return orderedProductItems;
};

export const getUserInitials = (currentUser: User) => {
  const firstInitial = currentUser.first_name
    ? currentUser.first_name.charAt(0).toUpperCase()
    : 'A';
  const secondInitial = currentUser.last_name.charAt(0).toUpperCase();

  return `${firstInitial}${secondInitial}`;
};
