import { FC, useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { previewVersion, switchCurrentlyEditingDashboardVersion } from 'actions/dashboardV2Actions';
import { ReportBuilderVersionMetadata } from 'actions/reportBuilderVersionActions';
import { ACTION } from 'actions/types';
import { TeamMember } from 'actions/userActions';
import {
  AlertModal,
  APP_PORTAL_ID,
  IconButton,
  Intent,
  Menu,
  MenuActionItem,
  MenuSub,
  sprinkles,
  Tag,
} from 'components/ds';
import { PERMISSIONED_ACTIONS } from 'constants/roleConstants';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ReduxState } from 'reducers/rootReducer';
import { cloneResource, revertResourceToVersion } from 'reducers/thunks/versionManagementThunks';
import * as RD from 'remotedata';
import { DashboardVersionMetadata } from 'types/dashboardVersion';
import { ResourcePageType } from 'types/exploResource';
import { getCloneResourceSuccessUrl, isVersionTagged } from 'utils/exploResourceUtils';
import { doesUserHavePermission } from 'utils/permissionUtils';

import { ResourceEnvironmentTagDto } from '@explo/embeddo-api';
import {
  previewReportBuilderVersion,
  switchCurrentlyEditingReportBuilderVersion,
} from 'actions/reportBuilderActions';
import { ROUTE_PROVIDERS } from 'constants/routes';
import { setVersionForPreviewThunk } from 'reducers/thunks/reportBuilderEditorThunks';
import { clearSelectedReport } from 'reportBuilderContent/reducers/reportEditingReducer';
import { getCreatorDetailMessage } from 'utils/versionControlUtil';
import { PublishToDropdown } from './PublishToDropdown';
import * as styles from './index.css';

type Props = {
  canRevert: boolean;
  closeModal: () => void;
  resourceId: number;
  rootResourceId: number;
  isDraft: boolean;
  pageType: ResourcePageType;
  tags: RD.ResponseData<ResourceEnvironmentTagDto[]>;
  version: DashboardVersionMetadata | ReportBuilderVersionMetadata;
  previousVersion?: DashboardVersionMetadata | ReportBuilderVersionMetadata;
  userPermissions: string[];
  publisher: TeamMember | null;
  onSelectComparisonVersions: (currentVersionNumber: number) => void;
};

enum VersionModalStatus {
  NO_OPEN_MODAL = 'No Open Modal',
  REVERT = 'Revert',
  DUPLICATE = 'Duplicate',
}

export const VersionPanel: FC<Props> = ({
  canRevert,
  closeModal,
  resourceId,
  rootResourceId,
  isDraft,
  pageType,
  publisher,
  tags,
  version,
  previousVersion,
  userPermissions,
  onSelectComparisonVersions,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState<VersionModalStatus>(VersionModalStatus.NO_OPEN_MODAL);

  const { versionHierarchy, currentDashboardId } = useSelector(
    (state: ReduxState) => ({
      versionHierarchy: state.dashboardEditConfig.versionHierarchy,
      currentDashboardId: state.dashboardEditConfig.currentDashboardId,
    }),
    shallowEqual,
  );

  const isExploreProduct = pageType === ResourcePageType.EXPLORE;

  const revertAction = isExploreProduct
    ? ACTION.REVERT_TO_DASHBOARD_VERSION
    : ACTION.REVERT_TO_REPORT_BUILDER_VERSION;

  const revertLoading = useSelector((state: ReduxState) =>
    createLoadingSelector([revertAction], false)(state),
  );

  const isDeprecated = version.deprecated;
  const envTags = RD.isSuccess(tags) ? tags.data : undefined;

  const userCanEditResource = doesUserHavePermission(userPermissions, PERMISSIONED_ACTIONS.UPDATE);

  const userCanPublishResource = doesUserHavePermission(
    userPermissions,
    PERMISSIONED_ACTIONS.PUBLISH,
  );
  const userCanDuplicateResource = doesUserHavePermission(
    userPermissions,
    PERMISSIONED_ACTIONS.CREATE,
  );

  const handlePreview = () => {
    if (isExploreProduct) {
      dispatch(
        previewVersion(
          {
            id: rootResourceId,
            queryParams: { version_number: version.version_number },
          },
          ({ preview_version, dashboard_version_hierarchy }) =>
            dispatch(
              switchCurrentlyEditingDashboardVersion({
                dashboardVersion: preview_version,
                versionHierarchy: dashboard_version_hierarchy,
              }),
            ),
        ),
      );
    } else {
      dispatch(
        previewReportBuilderVersion(
          {
            id: resourceId,
            queryParams: { version_number: version.version_number },
          },
          ({ preview_version }) => {
            dispatch(
              switchCurrentlyEditingReportBuilderVersion({
                reportBuilderVersion: preview_version,
              }),
            );
            dispatch(setVersionForPreviewThunk());
            dispatch(clearSelectedReport());
          },
        ),
      );
    }
    closeModal();
  };

  const handleRevert = useCallback(() => {
    dispatch(
      revertResourceToVersion(
        isExploreProduct,
        {
          id: rootResourceId,
          version_number: version.version_number,
        },
        () => {
          closeModal();
          if (isExploreProduct) {
            // Redirect to the root dashboard if the current dashboard is not the hierarchy after a revert
            const currentDashboardInHierarchy =
              RD.isSuccess(versionHierarchy) && currentDashboardId
                ? versionHierarchy.data.dashboardVersions[currentDashboardId]
                : undefined;
            if (currentDashboardInHierarchy?.id !== rootResourceId) {
              history.replace(ROUTE_PROVIDERS.DASHBOARD(rootResourceId.toString()));
            }
          } else {
            dispatch(clearSelectedReport());
          }
        },
      ),
    );
  }, [
    currentDashboardId,
    dispatch,
    history,
    isExploreProduct,
    rootResourceId,
    versionHierarchy,
    closeModal,
    version.version_number,
  ]);

  const renderRevertModal = () => (
    <AlertModal
      actionButtonProps={{
        onClick: handleRevert,
        loading: revertLoading,
        text: `Yes, revert to Version ${version.version_number}`,
      }}
      cancelButtonProps={{
        onClick: () => setModalOpen(VersionModalStatus.NO_OPEN_MODAL),
        text: 'Cancel',
      }}
      isOpen={modalOpen === VersionModalStatus.REVERT}
      onClose={() => setModalOpen(VersionModalStatus.NO_OPEN_MODAL)}
      portalContainerId={APP_PORTAL_ID}
      title={`Are you sure you want to revert to Version ${version.version_number}?`}>
      <div className={sprinkles({ paddingX: 'sp3' })}>
        {isDraft
          ? `This will discard your active draft and replace it with another draft matching version ${version.version_number}. Do you want to continue?`
          : `This will add a new draft matching version ${version.version_number}. Do you want to continue?`}
      </div>
    </AlertModal>
  );

  const renderDuplicateModal = () => (
    <AlertModal
      actionButtonProps={{
        text: 'Duplicate',
        onClick: () =>
          dispatch(
            cloneResource(
              isExploreProduct,
              { id: rootResourceId, version_number: version.version_number },
              (data) =>
                history.push(getCloneResourceSuccessUrl(isExploreProduct, data.new_resource.id)),
            ),
          ),
        variant: 'primary',
      }}
      isOpen={modalOpen === VersionModalStatus.DUPLICATE}
      onClose={() => setModalOpen(VersionModalStatus.NO_OPEN_MODAL)}
      portalContainerId={APP_PORTAL_ID}
      title={`Duplicate version ${version.version_number} to a new ${
        isExploreProduct ? 'dashboard' : 'report builder'
      }?`}
    />
  );

  // Returns a message that describes when and who (if available) published and created the version.
  const getPublishDetailMessage = useCallback(() => {
    return getCreatorDetailMessage(
      publisher ?? undefined,
      version.version_saved_at || version.created,
    );
  }, [publisher, version.version_saved_at, version.created]);

  return (
    <div className={styles.versionPanelRoot}>
      <div className={sprinkles({ width: 'fill' })}>
        <div className={styles.versionPanelNameAndTags}>
          <div className={sprinkles({ whiteSpace: 'nowrap', marginRight: 'sp1' })}>
            Version {version.version_number}
          </div>
          <Tag className={sprinkles({ marginRight: 'sp1' })}>{getPublishDetailMessage()}</Tag>
          {isDeprecated ? (
            <Tag
              inverted
              className={sprinkles({ marginRight: 'sp1' })}
              intent={Intent.ERROR}
              key={`deprecated-${version.id}`}>
              Deprecated
            </Tag>
          ) : null}
          {envTags?.map((tag) =>
            isVersionTagged(pageType, rootResourceId, version.id, tag) ? (
              <Tag
                inverted
                backgroundColor={tag.colorHex}
                className={sprinkles({ marginRight: 'sp1' })}
                key={`attached-tag-${tag.id}`}>
                {tag.name}
              </Tag>
            ) : null,
          )}
        </div>
        <div className={styles.changeComment}>{version.change_comments}</div>
      </div>
      {!isDeprecated ? (
        <div className={sprinkles({ flexItems: 'alignCenter' })}>
          <Menu align="end" trigger={<IconButton name="ellipsis-vertical" />} width="small">
            <MenuActionItem onSelect={handlePreview} text="Preview" />
            {previousVersion ? (
              <MenuActionItem
                onSelect={() => onSelectComparisonVersions(version.version_number)}
                text="Compare to Previous Version"
              />
            ) : null}
            {canRevert && userCanEditResource ? (
              <MenuActionItem
                onSelect={() => setModalOpen(VersionModalStatus.REVERT)}
                text="Revert"
              />
            ) : null}
            {userCanDuplicateResource ? (
              <MenuActionItem
                onSelect={() => setModalOpen(VersionModalStatus.DUPLICATE)}
                text="Duplicate"
              />
            ) : null}
            {userCanPublishResource && envTags ? (
              <MenuSub trigger="Publish To" width="small">
                <PublishToDropdown
                  pageType={pageType}
                  resourceId={rootResourceId}
                  tags={envTags}
                  versionId={version.id}
                />
              </MenuSub>
            ) : null}
          </Menu>
          {renderRevertModal()}
          {renderDuplicateModal()}
        </div>
      ) : null}
    </div>
  );
};
