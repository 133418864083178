import { FC, useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { getTimezoneAwareDate } from '@explo/data';

import { ReduxState } from 'reducers/rootReducer';
import { LatestVersionInfo, Resource } from 'types/exploResource';
import { getPreviewInfoFromLatestVersion } from 'utils/exploResourceUtils';
import { maxBy } from 'utils/standard';

import { ResourceItemCard } from './ResourceItemCard';
import { ResourceItemListElement } from './ResourceItemListElement';
import { ResourcePreview } from './ResourcePreview';
import * as styles from './styles.css';

type Props = {
  isCard: boolean;
  resource: Resource;
  showPreview?: boolean;
  dotsMenu: JSX.Element | null;
  hasEmailState?: boolean;
  onClickUrl: string;
  isLoading?: boolean;
  onClickMigrateDatasetsTag: (resourceId: number) => void;
};

export const ResourceItem: FC<Props> = ({
  isCard,
  resource,
  showPreview,
  dotsMenu,
  hasEmailState,
  onClickUrl,
  isLoading,
  onClickMigrateDatasetsTag,
}) => {
  const { globalStyleConfig, enableDataLibrary, enableDataLibraryV3 } = useSelector(
    (state: ReduxState) => {
      return {
        globalStyleConfig: state.dashboardStyles.globalStyleConfig,
        enableDataLibrary: state.currentUser.team?.feature_flags.enable_data_library ?? false,
        enableDataLibraryV3: state.currentUser.team?.feature_flags.enable_data_library_v3 ?? false,
      };
    },
    shallowEqual,
  );

  const lastModified = maxBy(resource.latest_versions, (info) => info.version_number)?.modified;
  const modifiedText = lastModified
    ? `Last edited ${getTimezoneAwareDate(lastModified).toRelative()}`
    : '';

  const isDisabled = 'disabled' in resource ? (resource.disabled ?? false) : false;

  const latestConfig = resource.latest_versions[resource.latest_versions.length - 1];

  const browserSupportsZoom = 'zoom' in document.body.style;

  const renderPreview = useCallback(
    (latestVersion: LatestVersionInfo | undefined) => {
      const previewInfo = latestVersion
        ? getPreviewInfoFromLatestVersion(latestVersion)
        : undefined;

      if (!previewInfo) {
        return <div className={styles.noPreview}>Error</div>;
      }

      if (!previewInfo.layout.length && previewInfo.editableSectionTitle == undefined) {
        return <div className={styles.noPreview}>Empty</div>;
      }

      return (
        <ResourcePreview
          dataPanels={previewInfo.dataPanels}
          editableSectionTitle={previewInfo.editableSectionTitle}
          elements={previewInfo.elements}
          globalStyleConfig={globalStyleConfig}
          layout={previewInfo.layout}
          useZoom={browserSupportsZoom && !isCard}
        />
      );
    },
    [browserSupportsZoom, globalStyleConfig, isCard],
  );

  return isCard ? (
    <ResourceItemCard
      dotsMenu={dotsMenu}
      enableDataLibrary={enableDataLibrary}
      enableDataLibraryV3={enableDataLibraryV3}
      hasEmailState={hasEmailState || false}
      isDisabled={isDisabled}
      isLoading={isLoading || false}
      lastModifiedMessage={modifiedText}
      latestConfig={latestConfig as LatestVersionInfo}
      onClickMigrateDatasetsTag={onClickMigrateDatasetsTag}
      onClickUrl={onClickUrl}
      renderPreviewFn={renderPreview}
      resource={resource}
    />
  ) : (
    <ResourceItemListElement
      dotsMenu={dotsMenu}
      enableDataLibrary={enableDataLibrary}
      enableDataLibraryV3={enableDataLibraryV3}
      hasEmailState={hasEmailState || false}
      isDisabled={isDisabled}
      isLoading={isLoading || false}
      lastModifiedMessage={modifiedText}
      latestConfig={latestConfig as LatestVersionInfo}
      onClickMigrateDatasetsTag={onClickMigrateDatasetsTag}
      onClickUrl={onClickUrl}
      renderResourcePreviewFn={renderPreview}
      resource={resource}
      showPreview={showPreview || false}
    />
  );
};
