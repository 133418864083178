import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { Breadcrumbs, BreadcrumbsHierarchyItem } from 'components/ds/Breadcrumbs';
import { navigateToPathThunk } from './navigationUtils';
import { getOrDefault, ResponseData } from 'remotedata';
import { Folder } from '@explo-tech/fido-api';
import { ItemType } from 'reducers/dataLibraryReducer';

interface Props {
  path: string;
  folders: Map<string, ResponseData<Folder>>;
}
export const DataLibraryBreadcrumbs: FC<Props> = ({ path, folders }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const splitFolderPath = useMemo(() => {
    return path === '/' ? [''] : path.split('/');
  }, [path]);

  const breadcrumbHierarchyItems: BreadcrumbsHierarchyItem[] = useMemo(() => {
    return splitFolderPath.map((item, index) => {
      const isFirstBreadcrumb = index === 0;
      const isLastBreadcrumb = index === splitFolderPath.length - 1;
      const currentPath = isFirstBreadcrumb ? '/' : splitFolderPath.slice(0, index + 1).join('/');
      return {
        text: isFirstBreadcrumb ? 'Home' : item,
        onClick: isLastBreadcrumb // Don't allow navigation from current item
          ? undefined
          : () =>
              dispatch(
                navigateToPathThunk(
                  getOrDefault(folders.get(currentPath), null)?.id ?? '',
                  ItemType.FOLDER,
                  currentPath,
                  history,
                ),
              ),
      };
    });
  }, [dispatch, splitFolderPath, history, folders]);
  return <Breadcrumbs items={breadcrumbHierarchyItems} />;
};
