import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { v4 as uuid } from 'uuid';

import { Button, sprinkles } from 'components/ds';
import { TextFieldModal } from 'components/modals/textFieldModal';
import { CreateDatasetModal } from 'components/resource/CreateDatasetModal';
import {
  COMPUTED_VIEW_TYPE,
  FOLDER_CONTENT_FETCH_ERROR_MESSAGE,
  FOLDER_TYPE,
} from 'pages/dataLibraryPage/constants';
import { ReduxState } from 'reducers/rootReducer';
import { FolderItem } from 'shared/ExploResource/FolderItem';
import { Folder as ResourceFolder, ResourceSearchResult, ResourceType } from 'types/exploResource';
import { getFolderNameErrorMessage } from 'utils/exploResourceUtils';
import { murmurhash } from 'utils/standard';

import { Folder, Resource } from '@explo-tech/fido-api';
import { DataLibraryBreadcrumbs } from './DataLibraryBreadcrumbs';
import { DataLibraryOverflowMenu } from './DataLibraryOverflowMenu';
import { DatasetListItem } from './DatasetListItem';
import { createComputedView, createFolder } from './dataFactory';
import { getCurrentFolder, getSubFolderUniqueNames, getUpdatedFoldersForBranch } from './selectors';

import { ParentSchema } from 'actions/dataSourceActions';
import { ExploLoadingSpinner } from 'components/ExploLoadingSpinner';
import * as RD from 'remotedata';
import { FolderPageZeroStateComponent } from './FolderPageZeroStateComponent';
import { PendingChangesTooltip } from './PendingChangesTooltip';

import { sendPing } from 'actions/pingActions';
import cx from 'classnames';
import { SearchResources } from 'components/SearchResources';
import { PingTypes } from 'constants/pingTypes';
import {
  addPendingResourceCreation,
  addPendingResourceDeletion,
  addPendingResourceMove,
  addPendingResourceUpdate,
  BranchOperationType,
  ItemType,
  resetBranchOperationStatus,
  resetHasFinishedDirectPathsFetch,
  resetSearchState,
  setDirectLoadedResource,
  setFolderIsExpanded,
} from 'reducers/dataLibraryReducer';
import { listBranchContentThunk } from 'reducers/thunks/fidoThunks/branchThunks';
import { searchBranchContentThunk } from 'reducers/thunks/fidoThunks/resourceThunks';
import { showSuccessToast } from 'shared/sharedToasts';
import { EVENTS, trackEvent } from 'telemetry/exploAnalytics';
import { ReadAccessComputedView } from 'utils/fido/fidoShimmedTypes';
import { BranchMenu } from './BranchMenu';
import { CreateNewBranchModal } from './CreateNewBranchModal';
import { FetchErrorComponent } from './FetchErrorComponent';
import { searchBarExpandedContainer } from './FolderPage.css';
import { useDataLibrarySetup } from './dataFetchUtils';
import {
  convertPathToBreadcrumbs,
  copyAndRenameItem,
  createFullPath,
  getParentPath,
  getUniqueResourceName,
  isFolderFullyFetched,
  validateResourceName,
} from './dataLibraryUtil';
import { navigateToPathThunk } from './navigationUtils';
import {
  getResourceCreatedMessage,
  getResourceDeletedMessage,
  getResourceRenamedMessage,
} from './pingMessages';

export const FolderPage: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);
  const [isCreateDatasetModalOpen, setIsCreateDatasetModalOpen] = useState(false);
  const [isCreateBranchModalOpen, setIsCreateBranchModalOpen] = useState(false);
  const [isSearchBarExpanded, setIsSearchBarExpanded] = useState(false);

  const {
    allFolders,
    folder,
    currentBranch,
    allBranches,
    pendingResourceCreations,
    pendingResourceDeletions,
    pendingResourceUpdates,
    pendingCommitStatus,
    dataSources,
    parentSchemaTablesMap,
    parentSchemas,
    useFido,
    currentItemPath,
    subFolderUniqueNames,
    currentUser,
    branchOperationStatus,
    searchResults,
    enableDataLibraryV2,
    enableDataLibraryV3,
  } = useSelector((state: ReduxState) => {
    return {
      allFolders: getUpdatedFoldersForBranch(state, state.dataLibrary.currentBranch),
      folder: getCurrentFolder(state) as RD.ResponseData<Folder>,
      currentBranch: state.dataLibrary.currentBranch,
      allBranches: state.dataLibrary.branches,
      currentItemPath: state.dataLibrary.currentItemPath,
      pendingResourceCreations: state.dataLibrary.pendingResourceCreations,
      pendingResourceDeletions: state.dataLibrary.pendingResourceDeletions,
      pendingResourceUpdates: state.dataLibrary.pendingResourceUpdates,
      pendingCommitStatus: state.dataLibrary.pendingCommitStatus,
      dataSources: state.dataSource.dataSources,
      parentSchemas: state.parentSchemas.usedParentSchemas,
      parentSchemaTablesMap: state.parentSchemas.schemaTablesMap,
      useFido: state.currentUser.team?.feature_flags.use_fido,
      subFolderUniqueNames: getSubFolderUniqueNames(state),
      currentUser: state.currentUser,
      branchOperationStatus: state.dataLibrary.branchOperationStatus,
      searchResults: state.dataLibrary.searchResults,
      enableDataLibraryV2: state.currentUser.team?.feature_flags.enable_data_library_v2,
      enableDataLibraryV3: state.currentUser.team?.feature_flags.enable_data_library_v3,
    };
  });

  // Custom hook to load all the data library state
  useDataLibrarySetup(
    dispatch,
    dataSources,
    parentSchemas,
    parentSchemaTablesMap,
    useFido,
    currentBranch,
    folder,
    currentItemPath,
  );

  const unwrappedFolder =
    RD.getOrDefault(folder, undefined) ?? createFolder(uuid(), uuid(), '', []);

  const subFolders = useMemo(() => {
    const folderContent = unwrappedFolder.children;

    if (!folderContent) {
      return [];
    }
    return folderContent.filter((content) => content['@type'] === FOLDER_TYPE) as Folder[];
  }, [unwrappedFolder]);

  const computedViews = useMemo(() => {
    const folderContent = unwrappedFolder.children;

    if (!folderContent) {
      return [];
    }
    return folderContent.filter(
      (content) => content['@type'] === COMPUTED_VIEW_TYPE,
    ) as ReadAccessComputedView[];
  }, [unwrappedFolder]);

  const resourcesWithPendingChangesIdSet = useMemo(() => {
    return new Set([
      ...pendingResourceCreations.keys(),
      ...pendingResourceDeletions.keys(),
      ...pendingResourceUpdates.keys(),
    ]);
  }, [pendingResourceCreations, pendingResourceDeletions, pendingResourceUpdates]);

  const resourceSearchResults: ResourceSearchResult[] = useMemo(() => {
    if (!RD.isSuccess(searchResults)) {
      return [];
    }

    return searchResults.data.views.map((viewResponse) => {
      const view = viewResponse.view;
      return {
        id: view.id ?? '',
        name: view.name,
        type: ResourceType.DATASET,
        breadcrumbs: convertPathToBreadcrumbs(view.path ?? ''),
      };
    });
  }, [searchResults]);

  useEffect(() => {
    if (RD.isSuccess(branchOperationStatus)) {
      const toastCopyPrefixMap = {
        [BranchOperationType.CREATE]:
          'Successfully created branch: ' + branchOperationStatus.data.branch?.name,
        [BranchOperationType.DELETE]: 'Successfully deleted branch',
        [BranchOperationType.UPDATE]:
          'Successfully updated branch: ' + branchOperationStatus.data.branch?.name,
      };

      const toastCopy =
        toastCopyPrefixMap[branchOperationStatus.data.type] || 'Operation completed: ';
      showSuccessToast(toastCopy, 3, () => {
        dispatch(resetBranchOperationStatus());
      });
    }
  }, [branchOperationStatus, dispatch]);

  const hasItems = subFolderUniqueNames.size > 0;
  const folderFetchHasError = RD.isError(folder);

  const hasInFlightFetch = RD.isLoading(currentBranch) || RD.isIdle(folder) || RD.isLoading(folder);

  const hasPendingCommit = RD.isLoading(pendingCommitStatus);

  return (
    <>
      <div className={sprinkles({ marginX: 'sp8', overflowY: 'scroll', width: 'fill' })}>
        {hasInFlightFetch ? (
          <ExploLoadingSpinner />
        ) : (
          <>
            <div
              className={sprinkles({
                flexItems: 'alignCenterBetween',
                heading: 'h1',
                marginY: 'sp2',
              })}>
              {unwrappedFolder.path !== null ? (
                <DataLibraryBreadcrumbs folders={allFolders} path={unwrappedFolder.path} />
              ) : null}
              <div className={sprinkles({ flexItems: 'center' })}>
                {enableDataLibraryV2 && RD.isSuccess(currentBranch) ? (
                  <div
                    className={cx(
                      sprinkles({
                        marginX: 'sp1',
                      }),
                      isSearchBarExpanded ? searchBarExpandedContainer : undefined,
                    )}>
                    <SearchResources
                      shouldKeepFocus
                      isLoadingResults={RD.isLoading(searchResults)}
                      onClearSearch={() => {
                        dispatch(resetSearchState());
                      }}
                      onItemClicked={(id) => {
                        if (!RD.isSuccess(searchResults)) {
                          return;
                        }

                        const selectedViewResponse = searchResults.data.views.find(
                          (viewResponse) => viewResponse.view.id === id,
                        );
                        const selectedView = selectedViewResponse?.view;
                        if (!selectedView) {
                          return;
                        }

                        const selectedViewPath = selectedView.path ?? '';
                        const selectedViewParentPath = getParentPath(selectedViewPath);
                        if (isFolderFullyFetched(allFolders, selectedViewParentPath)) {
                          dispatch(
                            navigateToPathThunk(
                              id,
                              ItemType.VIEW,
                              selectedView.path ?? '',
                              history,
                            ),
                          );
                        } else {
                          dispatch(setDirectLoadedResource(selectedView));
                          dispatch(resetHasFinishedDirectPathsFetch());
                        }
                      }}
                      onSearch={(query) => {
                        dispatch(
                          searchBranchContentThunk({
                            searchQuery: query,
                            branchId: currentBranch.data.id ?? '',
                            page: 0,
                          }),
                        );
                      }}
                      onSearchBarExpanded={setIsSearchBarExpanded}
                      searchBarPlaceholderText="Search for datasets and folders"
                      searchResults={resourceSearchResults}
                    />
                  </div>
                ) : null}
                <div className={sprinkles({ flexItems: 'alignCenter', width: 'fitContent' })}>
                  {enableDataLibraryV3 &&
                  RD.isSuccess(allBranches) &&
                  RD.isSuccess(currentBranch) ? (
                    <BranchMenu
                      branchOperationStatus={branchOperationStatus}
                      branches={allBranches.data}
                      currentBranch={currentBranch.data}
                      onCreateNewBranchClicked={() => setIsCreateBranchModalOpen(true)}
                    />
                  ) : null}
                  <Button
                    className={sprinkles({ marginX: 'sp1' })}
                    disabled={hasPendingCommit}
                    onClick={() => setIsCreateFolderModalOpen(true)}
                    variant="secondary">
                    Create Folder
                  </Button>
                  <Button
                    disabled={hasPendingCommit}
                    onClick={() => setIsCreateDatasetModalOpen(true)}
                    variant="primary">
                    Create Dataset
                  </Button>
                </div>
              </div>
            </div>
            {hasItems ? (
              <div className={sprinkles({ width: 'fill', marginY: 'sp2' })}>
                {/** TODO(zifanxiang): Re-enable folder renaming. Will need a client side mutation type for this which gets translated into pending updates on create commit. */}
                {subFolders.map((subFolder) => (
                  <div className={sprinkles({ marginY: 'sp2' })} key={subFolder.path}>
                    <FolderItem
                      additionalEndGroupElement={
                        resourcesWithPendingChangesIdSet.has(subFolder.id ?? '') ? (
                          <PendingChangesTooltip />
                        ) : null
                      }
                      dotsMenu={
                        <DataLibraryOverflowMenu
                          allItemNames={subFolderUniqueNames}
                          currentBranch={currentBranch}
                          item={subFolder}
                          onItemDelete={() => {
                            dispatch(addPendingResourceDeletion(subFolder));
                            dispatch(
                              sendPing({
                                postData: {
                                  message: getResourceDeletedMessage(
                                    currentUser.first_name + ' ' + currentUser.last_name,
                                    currentUser.team?.team_name ?? '',
                                    FOLDER_TYPE,
                                    subFolder.name,
                                  ),
                                  message_type: PingTypes.PING_GLOBAL_DATASETS,
                                },
                              }),
                            );
                            trackEvent(EVENTS.DELETED_GLOBAL_DATASET_FOLDER, {
                              folderName: subFolder.name,
                            });
                          }}
                        />
                      }
                      folder={adaptFidoFolderToResourceFolder(subFolder)}
                      isCard={false}
                      openFolder={() => {
                        dispatch(
                          navigateToPathThunk(
                            subFolder.id ?? '',
                            ItemType.FOLDER,
                            subFolder.path ?? '',
                            history,
                          ),
                        );
                        dispatch(
                          setFolderIsExpanded({
                            folderPath: subFolder.path ?? '',
                            isExpanded: true,
                          }),
                        );
                      }}
                    />
                  </div>
                ))}
                {computedViews.map((computedView) => (
                  <div className={sprinkles({ marginY: 'sp2' })} key={computedView.id}>
                    <DatasetListItem
                      allItemNames={subFolderUniqueNames}
                      currentBranch={currentBranch}
                      dataset={computedView}
                      hasPendingChanges={resourcesWithPendingChangesIdSet.has(
                        computedView.id ?? '',
                      )}
                      onClick={() => {
                        dispatch(
                          navigateToPathThunk(
                            computedView.id ?? '',
                            ItemType.VIEW,
                            computedView.path ?? '',
                            history,
                          ),
                        );
                      }}
                      onItemDelete={() => {
                        dispatch(addPendingResourceDeletion(computedView));
                        dispatch(
                          sendPing({
                            postData: {
                              message: getResourceDeletedMessage(
                                currentUser.first_name + ' ' + currentUser.last_name,
                                currentUser.team?.team_name ?? '',
                                COMPUTED_VIEW_TYPE,
                                computedView.name,
                              ),
                              message_type: PingTypes.PING_GLOBAL_DATASETS,
                            },
                          }),
                        );
                        trackEvent(EVENTS.DELETED_GLOBAL_DATASET, {
                          datasetName: computedView.name,
                        });
                      }}
                      onItemDuplicate={() => {
                        const computedPathWithoutName = getParentPath(computedView.path ?? '');
                        const newComputedViewName = getUniqueResourceName(
                          subFolderUniqueNames,
                          computedView.name,
                        );
                        const newComputedViewPath = createFullPath(
                          computedPathWithoutName,
                          newComputedViewName,
                        );
                        const newComputedView = createComputedView(
                          uuid(),
                          uuid(),
                          newComputedViewName,
                          newComputedViewPath,
                          computedView.description,
                          computedView.query,
                          computedView.namespaceId ?? null,
                          computedView.columnDefinitions,
                          computedView.parameters,
                        );
                        dispatch(addPendingResourceCreation(newComputedView));
                      }}
                      onItemMove={(newParentFolder: Folder) => {
                        const newPath = createFullPath(
                          newParentFolder.path ?? '',
                          computedView.name,
                        );
                        dispatch(
                          addPendingResourceMove({
                            '@type': 'update',
                            resource: {
                              ...computedView,
                              path: newPath,
                            },
                            previousPath: computedView.path ?? '',
                          }),
                        );
                      }}
                      onItemRename={(resource: Resource, newName: string) => {
                        dispatch(
                          addPendingResourceUpdate(
                            copyAndRenameItem(resource, newName) as ReadAccessComputedView,
                          ),
                        );
                        dispatch(
                          sendPing({
                            postData: {
                              message: getResourceRenamedMessage(
                                currentUser.first_name + ' ' + currentUser.last_name,
                                currentUser.team?.team_name ?? '',
                                COMPUTED_VIEW_TYPE,
                                resource.name,
                                newName,
                              ),
                              message_type: PingTypes.PING_GLOBAL_DATASETS,
                            },
                          }),
                        );
                        trackEvent(EVENTS.RENAMED_GLOBAL_DATASET, {
                          previousName: resource.name,
                          newName,
                        });
                      }}
                    />
                  </div>
                ))}
              </div>
            ) : null}
            {!hasItems && !folderFetchHasError ? <FolderPageZeroStateComponent /> : null}
            {folderFetchHasError ? (
              <div className={sprinkles({ flexItems: 'center', parentContainer: 'fill' })}>
                <FetchErrorComponent
                  disabled={RD.isLoading(folder)}
                  errorMessage={FOLDER_CONTENT_FETCH_ERROR_MESSAGE}
                  onRetry={() => {
                    dispatch(
                      listBranchContentThunk({
                        id: RD.getOrDefault(currentBranch, null)?.id ?? '',
                        path: currentItemPath.path,
                        resourceType: ItemType.FOLDER,
                      }),
                    );
                  }}
                />
              </div>
            ) : null}
          </>
        )}
        <TextFieldModal
          buttonName="Create Folder"
          closeModal={() => setIsCreateFolderModalOpen(false)}
          getErrorMessage={(folderName, hasUserInputtedValue) => {
            const folderNameBaseErrorMessage = getFolderNameErrorMessage(
              folderName,
              subFolderUniqueNames,
              undefined,
              hasUserInputtedValue,
            );
            if (folderNameBaseErrorMessage) {
              return folderNameBaseErrorMessage;
            }

            const folderNameError = validateResourceName(folderName ?? '', FOLDER_TYPE);
            return folderNameError ?? '';
          }}
          modalOpen={isCreateFolderModalOpen}
          modalTitle="Create folder"
          onSubmit={(folderName) => {
            const currentPath = unwrappedFolder.path ?? '';
            const newFolderPath = createFullPath(currentPath, folderName);
            const newFolder: Folder = createFolder(uuid(), uuid(), newFolderPath, []);
            dispatch(addPendingResourceCreation(newFolder));

            dispatch(
              sendPing({
                postData: {
                  message: getResourceCreatedMessage(
                    currentUser.first_name + ' ' + currentUser.last_name,
                    currentUser.team?.team_name ?? '',
                    FOLDER_TYPE,
                    folderName,
                  ),
                  message_type: PingTypes.PING_GLOBAL_DATASETS,
                },
              }),
            );
            trackEvent(EVENTS.CREATED_GLOBAL_DATASET_FOLDER, { folderName: folderName });
          }}
          textFieldPlaceholder="Enter folder name"
        />
        {isCreateDatasetModalOpen ? (
          <CreateDatasetModal
            currentDatasetNames={subFolderUniqueNames}
            onClose={() => setIsCreateDatasetModalOpen(false)}
            onSubmit={(name: string, parentSchema: ParentSchema) => {
              const currentPath = unwrappedFolder.path ?? '';
              const newComputedViewPath = createFullPath(currentPath, name);
              const newComputedView = createComputedView(
                uuid(),
                uuid(),
                name,
                newComputedViewPath,
                /* description= */ null,
                /* query= */ '',
                parentSchema.fido_id ?? '',
              );
              dispatch(addPendingResourceCreation(newComputedView));
              dispatch(
                navigateToPathThunk(
                  newComputedView.id ?? '',
                  ItemType.VIEW,
                  newComputedViewPath,
                  history,
                ),
              );

              dispatch(
                sendPing({
                  postData: {
                    message: getResourceCreatedMessage(
                      currentUser.first_name + ' ' + currentUser.last_name,
                      currentUser.team?.team_name ?? '',
                      COMPUTED_VIEW_TYPE,
                      name,
                    ),
                    message_type: PingTypes.PING_GLOBAL_DATASETS,
                  },
                }),
              );
              trackEvent(EVENTS.CREATED_GLOBAL_DATASET, { datasetName: name });
            }}
            validateDatasetNameFn={(newDatasetName?: string) => {
              const nameError = validateResourceName(newDatasetName ?? '', COMPUTED_VIEW_TYPE);
              return { error: nameError };
            }}
          />
        ) : null}
        {isCreateBranchModalOpen && RD.isSuccess(currentBranch) && RD.isSuccess(allBranches) ? (
          <CreateNewBranchModal
            allBranches={allBranches.data}
            currentHeadCommitId={currentBranch.data.headId}
            onClose={() => setIsCreateBranchModalOpen(false)}
          />
        ) : null}
      </div>
    </>
  );
};

const UNSET_PARENT_ID = -1;

const adaptFidoFolderToResourceFolder = (fidoFolder: Folder): ResourceFolder => {
  const fidoFolderPath = fidoFolder.path ?? '';
  return {
    id: murmurhash(fidoFolder.id ?? ''),
    type: ResourceType.FOLDER,
    name: fidoFolderPath.split('/').pop() || '',
    parent_id: UNSET_PARENT_ID,
    num_resources: fidoFolder.children?.length || 0,
  };
};
