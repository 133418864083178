import cx from 'classnames';
import { FC, useCallback, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { addGlobalDatasetReference, Dataset } from 'actions/datasetActions';
import { ImportGlobalDatasetModal } from 'components/DataLibrary/ImportGlobalDatasetModal';
import { CardItem } from 'components/PanelComponents/CardItem';
import { SearchInput } from 'components/SearchInput/SearchInput';
import { Button, sprinkles } from 'components/ds';
import { CreateDatasetModal } from 'components/resource/CreateDatasetModal';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { DeleteDatasetModal } from 'pages/dashboardPage/dashboardDatasetEditor/modals/DeleteDatasetModal';
import { EditDatasetModal } from 'pages/dashboardPage/dashboardDatasetEditor/modals/EditDatasetModal';
import {
  getDataPanelUsageTextMap,
  getEmbeddoSchemaFromView,
} from 'pages/dashboardPage/dashboardDatasetEditor/utils';
import {
  clearVersionedComputedViewReferences,
  getEditPageDataPanels,
  getEditPageElements,
  setSelectedDatasetId,
  setSelectedGlobalDatasetId,
} from 'reducers/dashboardEditConfigReducer';
import { setHighlightedDataPanelIds } from 'reducers/dashboardInteractionsReducer';
import { ReduxState } from 'reducers/rootReducer';
import {
  getArchetypeProperties,
  getEditableDatasets,
  getRootEditConfigWithDrilldowns,
} from 'reducers/selectors';
import { createDatasetThunk } from 'reducers/thunks/dashboardDataThunks/modifyDatasetThunks';
import { getDataPanelIdsUsingDataset, getDatasetNamesToId } from 'utils/datasetUtils';
import { getDatasetName } from 'utils/naming';
import { sortBy } from 'utils/standard';
import { isQueryDependentOnVariable } from 'utils/variableUtils';

import { SettingHeader } from 'components/SettingHeader';
import { GlobalDatasetsSection } from './GlobalDatasetsSection';

import { ParentSchema } from 'actions/dataSourceActions';
import { sendPing } from 'actions/pingActions';
import { User } from 'actions/userActions';
import { AddDatasetButton } from 'components/resource/AddDatasetButton';
import { PingTypes } from 'constants/pingTypes';
import { getGlobalDatasetImportedMessage } from 'pages/dataLibraryPage/pingMessages';
import { addPendingResourceCreation } from 'reducers/dataLibraryReducer';
import * as RD from 'remotedata';
import { showSuccessToast } from 'shared/sharedToasts';
import { convertDatasetToNewComputedView } from 'utils/fido/fidoShims';
import { DataLibraryFolderActionModal } from '../../../dataLibraryPage/DataLibraryFolderActionModal';
import { ReadAccessComputedView } from 'utils/fido/fidoShimmedTypes';
import { ResourceType } from 'types/exploResource';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';

enum ModalStatus {
  CREATE_DATASET,
  DELETE_DATASET,
  EDIT_DATASET,
  IMPORT_GLOBAL_DATASET,
  CLOSED,
}

type Props = { dashboardId: number; selectedChartDatasetId?: string | number | undefined };

export const DatasetsPanel: FC<Props> = ({ dashboardId, selectedChartDatasetId }) => {
  const dispatch = useDispatch();
  const [modalStatus, setModalStatus] = useState(ModalStatus.CLOSED);
  const [editDatasetId, setEditDatasetId] = useState<string | null>(null);
  const [deleteDatasetId, setDeleteDatasetId] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedDatasetIdToExportToGlobal, setSelectedDatasetIdToExportToGlobal] = useState<
    string | null
  >(null);

  const {
    datasetConfigs,
    showUnfilteredDatasetFlag,
    archetypeProperties,
    elements,
    dataPanels,
    datasetData,
    referencedGlobalDatasets,
    latestReferencedGlobalDatasets,
    globalDatasetReferences,
    allVariableMappings,
    mainBranch,
    parentSchemas,
    enableDataLibrary,
    enableDataLibraryDebugActions,
  } = useSelector(
    (state: ReduxState) => ({
      archetypeProperties: getArchetypeProperties(state),
      variables: state.dashboardData.variables ?? {},
      datasetConfigs: getEditableDatasets(state),
      showUnfilteredDatasetFlag: state.currentUser.team?.feature_flags.show_unfiltered_dataset_flag,
      elements: getEditPageElements(state.dashboardEditConfig, false),
      dataPanels: getEditPageDataPanels(state.dashboardEditConfig, false),
      datasetData: state.dashboardEditConfig.datasetData,
      referencedGlobalDatasets: state.fido.referencedGlobalDatasets,
      latestReferencedGlobalDatasets: state.fido.latestReferencedGlobalDatasets,
      globalDatasetReferences:
        state.dashboardEditConfig.config?.versioned_computed_view_references ?? {},
      allVariableMappings: getRootEditConfigWithDrilldowns(state)?.variable_mappings ?? {},
      mainBranch: state.dataLibrary.mainBranch,
      parentSchemas: getParentSchemasList(state),
      enableDataLibrary: state.currentUser.team?.feature_flags.enable_data_library,
      enableDataLibraryDebugActions:
        state.currentUser.team?.feature_flags.enable_data_library_debug_actions,
    }),
    shallowEqual,
  );

  const usageTextMap = useMemo(() => {
    return getDataPanelUsageTextMap(datasetConfigs, dataPanels, elements);
  }, [datasetConfigs, dataPanels, elements]);

  const filteredAndOrderedDatasetItems = useMemo(() => {
    const chartSelectedDataset = selectedChartDatasetId
      ? datasetConfigs[selectedChartDatasetId]
      : undefined;
    const sortedDatasets = sortBy(
      Object.values(datasetConfigs).filter((config) =>
        config.table_name.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()),
      ),
      (config) => config.table_name.toLocaleLowerCase(),
    );
    const renderDatasetItem = (datasetConfig: Dataset) => {
      const datasetName = getDatasetName(datasetConfig);
      const query = datasetConfig.queryDraft ?? datasetConfig.query ?? '';

      const noCustomerFilter = showUnfilteredDatasetFlag
        ? !isQueryDependentOnVariable(archetypeProperties, datasetConfig)
        : false;

      const isDraft = query != null && query !== datasetConfig.query;
      const isError = !!datasetData[datasetConfig.id]?.error;

      return (
        <CardItem
          additionalIconButtons={
            enableDataLibrary
              ? [
                  {
                    name: 'globe',
                    onClick: (event) => {
                      event.stopPropagation();
                      setSelectedDatasetIdToExportToGlobal(datasetConfig.id);
                    },
                    tooltipProps: {
                      text: 'Copy to data library',
                    },
                  },
                ]
              : undefined
          }
          alert={
            isError
              ? {
                  tooltipText: 'Query has an error',
                  icon: 'circle-exclamation',
                  iconStyle: sprinkles({ color: 'error' }),
                }
              : isDraft
                ? { tooltipText: 'Query is a draft', icon: 'circle-exclamation-reg' }
                : noCustomerFilter
                  ? { tooltipText: 'No customer filter set', icon: 'users-reg' }
                  : undefined
          }
          isSelected={chartSelectedDataset?.id === datasetConfig.id}
          key={`dataset-navbar-item-${datasetConfig.id}`}
          leftIcon="table"
          name={datasetName}
          onClick={() => dispatch(setSelectedDatasetId(datasetConfig.id))}
          onDelete={() => {
            setDeleteDatasetId(datasetConfig.id);
            setModalStatus(ModalStatus.DELETE_DATASET);
          }}
          onEdit={() => {
            setEditDatasetId(datasetConfig.id);
            setModalStatus(ModalStatus.EDIT_DATASET);
          }}
          onMouseEntered={() =>
            dispatch(
              setHighlightedDataPanelIds(
                new Set(getDataPanelIdsUsingDataset(dataPanels, datasetConfig.id)),
              ),
            )
          }
          onMouseLeave={() => dispatch(setHighlightedDataPanelIds(new Set()))}
          secondaryText={usageTextMap[datasetConfig.id]}
        />
      );
    };
    return sortedDatasets.map((datasetConfig) => renderDatasetItem(datasetConfig));
  }, [
    datasetConfigs,
    dataPanels,
    archetypeProperties,
    selectedChartDatasetId,
    datasetData,
    dispatch,
    showUnfilteredDatasetFlag,
    searchQuery,
    usageTextMap,
    enableDataLibrary,
  ]);

  const renderedContent = useMemo(
    () =>
      filteredAndOrderedDatasetItems.length > 0 ? (
        filteredAndOrderedDatasetItems
      ) : !enableDataLibrary ? (
        <EmbedText
          body="b1"
          className={sprinkles({ parentContainer: 'fill', flexItems: 'centerColumn' })}
          color="contentSecondary">
          No datasets.
        </EmbedText>
      ) : null,
    [filteredAndOrderedDatasetItems, enableDataLibrary],
  );

  const currentDatasetNames = useMemo(
    () =>
      new Set(
        Object.keys(
          getDatasetNamesToId(datasetConfigs, RD.getOrDefault(referencedGlobalDatasets, {})),
        ),
      ),
    [datasetConfigs, referencedGlobalDatasets],
  );

  const onGlobalDatasetSelected = useCallback(
    (globalDataset: ReadAccessComputedView) => {
      dispatch(setSelectedGlobalDatasetId(globalDataset.id ?? ''));
    },
    [dispatch],
  );

  const handleAddDataset = useCallback(
    (name: string, parentSchema: ParentSchema) => {
      dispatch(createDatasetThunk(name, parentSchema.id, dashboardId));
    },
    [dispatch, dashboardId],
  );

  return (
    <div className={sprinkles({ height: 'fill', overflow: 'auto' })}>
      <div
        className={cx(datasetListContainerClass, {
          [sprinkles({ height: 'fill' })]: !filteredAndOrderedDatasetItems.length,
        })}>
        {enableDataLibrary ? (
          <div className={sprinkles({ flexItems: 'alignCenterBetween' })}>
            <AddDatasetButton
              className={sprinkles({ marginRight: 'sp1' })}
              datasetNames={currentDatasetNames}
              onSubmit={handleAddDataset}
              textOverride="Add local dataset"
              variantOverride="secondary"
            />
            <Button
              fillWidth
              className={sprinkles({ paddingY: 'sp1' })}
              onClick={() => setModalStatus(ModalStatus.IMPORT_GLOBAL_DATASET)}>
              <div className={sprinkles({ flexItems: 'alignCenter' })}>Import global dataset</div>
            </Button>
          </div>
        ) : (
          <AddDatasetButton
            datasetNames={currentDatasetNames}
            onSubmit={handleAddDataset}
            textOverride="Add a dataset"
          />
        )}
        {enableDataLibrary && enableDataLibraryDebugActions ? (
          <Button fillWidth onClick={() => dispatch(clearVersionedComputedViewReferences())}>
            Clear Global Datasets
          </Button>
        ) : null}
        <SearchInput
          className={sprinkles({ paddingX: 'sp1' })}
          onInputChanged={setSearchQuery}
          searchQuery={searchQuery}
        />
        {enableDataLibrary && filteredAndOrderedDatasetItems.length > 0 ? (
          <SettingHeader name="Local datasets" />
        ) : null}
        {renderedContent}
        <GlobalDatasetsSection
          chartSelectedDatasetId={selectedChartDatasetId}
          globalDatasets={referencedGlobalDatasets}
          latestGlobalDatasets={latestReferencedGlobalDatasets}
          onGlobalDatasetSelected={onGlobalDatasetSelected}
          query={searchQuery}
        />
      </div>
      {modalStatus === ModalStatus.CREATE_DATASET ? (
        <CreateDatasetModal
          currentDatasetNames={currentDatasetNames}
          onClose={() => setModalStatus(ModalStatus.CLOSED)}
          onSubmit={(name, parentSchema) => {
            dispatch(createDatasetThunk(name, parentSchema.id, dashboardId));
          }}
        />
      ) : null}
      {modalStatus === ModalStatus.DELETE_DATASET ? (
        <DeleteDatasetModal
          datasetConfigs={datasetConfigs}
          deleteDatasetId={deleteDatasetId}
          onClose={() => {
            setModalStatus(ModalStatus.CLOSED);
            setDeleteDatasetId(null);
          }}
        />
      ) : null}
      {modalStatus === ModalStatus.EDIT_DATASET ? (
        <EditDatasetModal
          datasetConfigs={datasetConfigs}
          editDatasetId={editDatasetId}
          onClose={() => {
            setModalStatus(ModalStatus.CLOSED);
            setEditDatasetId(null);
          }}
        />
      ) : null}
      {modalStatus === ModalStatus.IMPORT_GLOBAL_DATASET ? (
        <ImportGlobalDatasetModal
          allVariableMappings={allVariableMappings}
          currentDatasetNames={currentDatasetNames}
          globalDatasetReferences={globalDatasetReferences}
          onClose={() => setModalStatus(ModalStatus.CLOSED)}
          onImportGlobalDataset={(
            selectedComputedView: ReadAccessComputedView,
            currentUser: User,
          ) => {
            const parentSchema = getEmbeddoSchemaFromView(parentSchemas, selectedComputedView);

            if (!parentSchema) {
              return;
            }

            dispatch(
              addGlobalDatasetReference({
                newDataset: selectedComputedView,
                resourceType: ResourceType.DASHBOARD,
                parentSchema,
              }),
            );
            dispatch(
              sendPing({
                postData: {
                  message: getGlobalDatasetImportedMessage(
                    currentUser.first_name + ' ' + currentUser.last_name,
                    currentUser.team?.team_name ?? '',
                    selectedComputedView.name ?? '',
                  ),
                  message_type: PingTypes.PING_GLOBAL_DATASETS,
                },
              }),
            );
          }}
          shouldSkipVariableMapping={false}
        />
      ) : null}
      {selectedDatasetIdToExportToGlobal ? (
        <DataLibraryFolderActionModal
          actionButtonText="Copy"
          currentBranch={mainBranch}
          isButtonDisabledFn={(currentFolderResourceNames: Set<string>) => {
            const datasetToCopy = datasetConfigs[selectedDatasetIdToExportToGlobal];
            if (!datasetToCopy) {
              return true;
            }

            return currentFolderResourceNames.has(getDatasetName(datasetToCopy));
          }}
          onActionButtonClicked={(selectedParentFolder) => {
            const datasetToCopy = datasetConfigs[selectedDatasetIdToExportToGlobal];
            if (!datasetToCopy) {
              return;
            }

            const convertedComputedView = convertDatasetToNewComputedView(
              datasetToCopy,
              selectedParentFolder.path ?? '',
            );
            showSuccessToast(getCopySuccessMessage(getDatasetName(datasetToCopy)));
            dispatch(addPendingResourceCreation(convertedComputedView));
          }}
          onClose={() => setSelectedDatasetIdToExportToGlobal(null)}
        />
      ) : null}
    </div>
  );
};

const datasetListContainerClass = sprinkles({
  flexItems: 'column',
  padding: 'sp2',
  gap: 'sp2',
});

const getCopySuccessMessage = (datasetName: string) =>
  `Dataset "${datasetName}" copied to the data library. The change is still pending, please commit to save it.`;
