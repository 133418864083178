import {
  CreateResourceChange,
  DeleteResourceChange as DeleteResourceChangeBase,
  UpdateResourceChange,
} from '@explo-tech/fido-api';

export enum ManageDatasetModalType {
  NO_MODAL,
  EDIT_NAME_AND_DESCRIPTION_MODAL,
  DELETE_CONFIRMATION_MODAL,
  MOVE_MODAL,
  RENAME_MODAL,
}

export interface DeleteResourceChange extends DeleteResourceChangeBase {
  path: string;
  resourceType: string;
}

export interface MoveResourceChange extends UpdateResourceChange {
  previousPath: string;
}

export interface SerializedPendingChanges {
  pendingResourceCreations: CreateResourceChange[];
  pendingResourceDeletions: DeleteResourceChange[];
  pendingResourceUpdates: UpdateResourceChange[];
  headCommitId: string;
}

export type FolderPath = string;

export type BranchId = string;

export type ResourceId = string;
