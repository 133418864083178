/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateResourceEnvironmentTagRequest } from '../models/CreateResourceEnvironmentTagRequest';
import type { ListResourceEnvironmentTagResponse } from '../models/ListResourceEnvironmentTagResponse';
import type { ListResourceEnvironmentTagUsageResponse } from '../models/ListResourceEnvironmentTagUsageResponse';
import type { ResourceEnvironmentTagResponse } from '../models/ResourceEnvironmentTagResponse';
import type { UpdateResourceEnvironmentTagRequest } from '../models/UpdateResourceEnvironmentTagRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EnvironmentTagsService {
    /**
     * @returns ListResourceEnvironmentTagResponse
     * @throws ApiError
     */
    public static listEnvironmentTags(): CancelablePromise<ListResourceEnvironmentTagResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/environment_tags/',
        });
    }
    /**
     * @param requestBody
     * @returns ResourceEnvironmentTagResponse
     * @throws ApiError
     */
    public static createEnvironmentTag(
        requestBody?: CreateResourceEnvironmentTagRequest,
    ): CancelablePromise<ResourceEnvironmentTagResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/environment_tags/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns ResourceEnvironmentTagResponse
     * @throws ApiError
     */
    public static updateEnvironmentTag(
        id: number,
        requestBody?: UpdateResourceEnvironmentTagRequest,
    ): CancelablePromise<ResourceEnvironmentTagResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v2/environment_tags/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteEnvironmentTag(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v2/environment_tags/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @returns ListResourceEnvironmentTagUsageResponse
     * @throws ApiError
     */
    public static getUsage(
        id: number,
    ): CancelablePromise<ListResourceEnvironmentTagUsageResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/environment_tags/{id}/usage/',
            path: {
                'id': id,
            },
        });
    }
}
