import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { OPERATION_TYPES } from '@explo/data';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { NumberFormatToggle } from 'components/NumberFormatToggle';
import { Input } from 'components/ds';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { SunburstChartInstructions } from 'constants/types';
import { configInputClass, configRootClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

type Props = {
  instructions: SunburstChartInstructions;
};

export const SunburstChartConfig: FC<Props> = ({ instructions }) => {
  const dispatch = useDispatch();

  const selectedFormat = instructions.valueFormat?.format || V2_NUMBER_FORMATS.NUMBER;

  const updateValueFormat = (updates: Partial<SunburstChartInstructions>) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.valueFormat = {
        ...draft.valueFormat,
        ...updates.valueFormat,
      };
    });

    dispatch(updateVisualizeOperation(newInstructions, OPERATION_TYPES.VISUALIZE_SUNBURST));
  };

  return (
    <div className={configRootClass}>
      <NumberFormatToggle
        disableAbbr
        disableTime
        className={configInputClass}
        label="Value Format"
        selectedFormat={selectedFormat.id}
        updateFormat={(format) =>
          updateValueFormat({
            valueFormat: { ...instructions.valueFormat, format: { id: format } },
          })
        }
      />
      <Input
        showInputButton
        className={configInputClass}
        defaultValue={String(instructions?.valueFormat?.decimalPlaces ?? 2)}
        label="Value Decimal Places"
        onSubmit={(newValue: string) => {
          const intValue = parseInt(newValue);
          updateValueFormat({
            valueFormat: {
              ...instructions.valueFormat,
              decimalPlaces: intValue > 0 ? intValue : 0,
            },
          });
        }}
      />
    </div>
  );
};
