import { FC } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import {
  OPERATION_TYPES,
  REPORT_BUILDER_TYPES,
  VISUALIZE_TABLE_OPERATIONS_SET,
  CHART_VISUALIZATION_OPERATIONS_SET,
} from '@explo/data';

import { ConfigSection } from 'components/PanelComponents/ConfigSection';
import {
  ConfigSubSectionTitle,
  ConfigSubSectionWrapper,
} from 'components/PanelComponents/ConfigSubSection';
import {
  SELECTABLE_CHARTS,
  VIZ_OPS_WITH_CATEGORY_SELECT_DRILLDOWN,
  VIZ_OPS_WITH_CUSTOM_MENU,
  VIZ_OP_WITH_CSV_DOWNLOAD,
} from 'constants/dataConstants';
import { ReduxState } from 'reducers/rootReducer';
import { getCurrentDashboard, getDashboardIdToNameMap } from 'reducers/selectors';
import { DataPanelTemplate } from 'types/dataPanelTemplate';

import { RowLevelActionsConfig } from '../FormatConfigTab/RowLevelActionsConfig';

import { CardActionsConfig } from './sections/CardActionsConfig';
import { CategoryDrilldownConfig } from './sections/CategoryDrilldownConfig';
import { CustomMenuConfig } from './sections/CustomMenuConfig';
import { DashboardDrilldownsSection } from './sections/DashboardDrilldownsSection';
import { ExportsConfig } from './sections/ExportsConfig';
import { LinkConfig } from './sections/LinkConfig';
import { RawDataDrilldownConfig } from './sections/RawDataDrilldownConfig';
import { TableDrilldownConfig } from './sections/TableDrilldownConfig';

type Props = { dataPanel: DataPanelTemplate };

export const ActionsConfigTab: FC<Props> = ({ dataPanel }) => {
  const { instructions, operation_type: visualizationType } = dataPanel.visualize_op;
  const generalFormatOptions = dataPanel.visualize_op.generalFormatOptions ?? {};
  const drilldownEntryPoints = dataPanel.drilldownEntryPoints;

  const isTable = visualizationType === OPERATION_TYPES.VISUALIZE_TABLE;

  // Data is only needed for table right now
  const { dataPanelData, enableNewGrid, dashboardIdToNameMap, currentDashboard } = useSelector(
    (state: ReduxState) => ({
      dataPanelData: isTable ? state.dashboardData.dataPanelData[dataPanel.id] : undefined,
      enableNewGrid: state.dashboardLayout.enableNewGrid,
      dashboardIdToNameMap: getDashboardIdToNameMap(state),
      currentDashboard: getCurrentDashboard(state),
    }),
    shallowEqual,
  );

  const shouldRenderLinkConfig = () => {
    if (REPORT_BUILDER_TYPES.has(visualizationType)) return false;
    // Tables have the url in the header, so if it is hidden no need to show
    const inHeader = VISUALIZE_TABLE_OPERATIONS_SET.has(visualizationType);
    if (inHeader && generalFormatOptions.headerConfig?.isHeaderHidden) return false;
    return true;
  };

  const renderHeadingActions = () => {
    const renderExports =
      VIZ_OP_WITH_CSV_DOWNLOAD.has(visualizationType) ||
      isTable ||
      visualizationType === OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST ||
      visualizationType === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE ||
      visualizationType === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2;

    const renderUnderlyingDataConfig = CHART_VISUALIZATION_OPERATIONS_SET.has(visualizationType);

    const renderLinkConfig = shouldRenderLinkConfig();

    if ((!renderExports && !renderUnderlyingDataConfig) || !renderLinkConfig) return null;

    return (
      <ConfigSection defaultOpen icon="list" title="Heading actions" variant="header1">
        {renderExports ? (
          <ExportsConfig
            dpProvidedId={dataPanel.provided_id}
            options={generalFormatOptions}
            visualizationType={visualizationType}
          />
        ) : null}
        {renderExports ? <ConfigSubSectionTitle title="Other" /> : null}
        <ConfigSubSectionWrapper>
          {renderUnderlyingDataConfig ? (
            <RawDataDrilldownConfig
              dataPanel={dataPanel}
              datasetId={dataPanel.table_id}
              dpProvidedId={dataPanel.provided_id}
              options={generalFormatOptions}
            />
          ) : null}
          {renderLinkConfig ? <LinkConfig options={generalFormatOptions} /> : null}
        </ConfigSubSectionWrapper>
      </ConfigSection>
    );
  };

  const renderChartInteractions = () => {
    const renderCustomMenuConfig = VIZ_OPS_WITH_CUSTOM_MENU.has(visualizationType);

    const renderCategoryDrilldown = VIZ_OPS_WITH_CATEGORY_SELECT_DRILLDOWN.has(visualizationType);
    const renderDrilldown = isTable || renderCategoryDrilldown;

    if (!renderCustomMenuConfig && !renderDrilldown) return null;

    return (
      <ConfigSection icon="computer-mouse" title="Chart interactions" variant="header1">
        <ConfigSubSectionWrapper>
          {isTable ? (
            <TableDrilldownConfig
              instructions={instructions.VISUALIZE_TABLE}
              schema={dataPanelData?.schema}
            />
          ) : null}
          {isTable && enableNewGrid ? (
            <RowLevelActionsConfig
              instructions={instructions.VISUALIZE_TABLE}
              schema={dataPanelData?.schema ?? []}
            />
          ) : null}
          {renderDrilldown && !isTable ? (
            <CategoryDrilldownConfig
              instructions={instructions.V2_TWO_DIMENSION_CHART}
              visualizationType={visualizationType}
            />
          ) : null}
          {renderCustomMenuConfig ? <CustomMenuConfig options={generalFormatOptions} /> : null}
        </ConfigSubSectionWrapper>
      </ConfigSection>
    );
  };

  const renderCardInteractions = () => {
    const isSelectableKPI = SELECTABLE_CHARTS.has(visualizationType);

    if (!isSelectableKPI) return null;

    return (
      <ConfigSection defaultOpen icon="square" title="Card actions" variant="header1">
        <ConfigSubSectionWrapper>
          <CardActionsConfig instructions={instructions.V2_KPI} vizType={visualizationType} />
        </ConfigSubSectionWrapper>
      </ConfigSection>
    );
  };

  return (
    <div>
      {renderHeadingActions()}
      {renderChartInteractions()}
      {renderCardInteractions()}
      <DashboardDrilldownsSection
        currentDashboard={currentDashboard}
        dashboardIdToNameMap={dashboardIdToNameMap}
        drilldownEntryPointMap={drilldownEntryPoints}
        selectedDataPanel={dataPanel}
      />
    </div>
  );
};
