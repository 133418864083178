import { DatasetSchema } from '@explo/data';

import { SunburstChartInstructions } from 'constants/types';
import { getAxisNumericalValue } from 'pages/dashboardPage/charts/utils';

type SunburstDataPoint = Pick<Highcharts.PointOptionsObject, 'name' | 'id' | 'value' | 'parent'>;

/**
 * Transforms pivoted data into a flat list, using a parent identifier to create the
 * hierarchy between layers of the pivot table. The outputted data ends up in the form
 *
 * {
 *  name (column name)
 *  id (unique identifier for parenting)
 *  value (optional numerical value)
 *  parent (optional parent identifier)
 * }
 *
 * Highcharts handles aggregating values up to the parents, so we only have to insert values
 * at the bottom-most child of the hierarchy. For example, if you data pivots on country ->
 * state -> city, we're only aggregating the values at the city level, and highcharts handles
 * summing up values at the higher levels
 */
export const transformData = (
  rows: Record<string, string | number>[] | undefined,
  schema: DatasetSchema,
  { rowGroupBys, aggregations }: SunburstChartInstructions,
): SunburstDataPoint[] => {
  if (!rows?.length || !rowGroupBys?.length || !aggregations?.length) return [];

  const seenIds = new Set<string>();

  const data: SunburstDataPoint[] = [];

  rows.forEach((row) => {
    let identifier = '';

    schema.forEach((s, i) => {
      const columnName = s.name;

      const value = row[columnName]?.toString();

      if (value == undefined || !value) return;

      const newIdentifier =
        i === 0 ? `${columnName}_${value}` : `${identifier}_${columnName}_${value}`;

      if (i < schema.length - 1) {
        if (!seenIds.has(newIdentifier)) {
          seenIds.add(newIdentifier);
          const entry: SunburstDataPoint = {
            name: value,
            id: newIdentifier,
          };

          if (i > 0) {
            entry['parent'] = identifier;
          }

          data.push(entry);
        }

        identifier = newIdentifier;
      } else {
        data.push({
          name: columnName,
          parent: identifier,
          value: getAxisNumericalValue(value),
        });
      }
    });
  });

  return data;
};

// this is kind of arbitrarily decided, and should probably be tweaked
const colorVariationFactor = 0.25;

/**
 * Creates the levels for the sunburst chart. The first level is the center of the chart and defines
 * the unique colorset to use for children of each second. The inner levels (minus the last) define
 * the range of colors to use for their values. The last level is hidden because in our data that's
 * just the aggregation value itself
 */
export const configureLevels = (schema: DatasetSchema) => {
  const levels: Highcharts.PlotSunburstLevelsOptions[] = [];

  for (let i = 1; i <= schema.length; i++) {
    if (i === 1) {
      levels.push({
        level: i,
        // i dont know why this isn't in the api but this tells high charts
        // to individually color each point at the top level
        // @ts-ignore
        colorByPoint: true,
      });
    } else if (i === schema.length) {
      levels.push({
        level: i,
        levelSize: {
          value: 0,
        },
        dataLabels: { enabled: false },
      });
    } else {
      levels.push({
        level: i,
        colorVariation: {
          key: 'brightness',
          to: i * colorVariationFactor,
        },
      });
    }
  }

  return levels;
};
