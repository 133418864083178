import { FC, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';

import { DatasetDataObject } from 'actions/datasetActions';
import { ChartTooltip } from 'components/embed';
import {
  ColumnColorTracker,
  SunburstChartInstructions,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { GlobalStyleConfig } from 'globalStyles/types';
import { DrilldownChart } from 'pages/dashboardPage/charts/shared/drilldownChart';
import { getColorPalette, isSunburstReadyToDisplay } from 'pages/dashboardPage/charts/utils';
import {
  sharedTitleConfig,
  sharedTooltipConfigs,
} from 'pages/dashboardPage/charts/utils/sharedConfigs';
import { configureLevels, transformData } from 'pages/dashboardPage/charts/utils/sunburstUtils';
import { NeedsConfigurationPanel } from 'pages/dashboardPage/needsConfigurationPanel';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { DataPanelData, DrilldownEntryPointInfo } from 'types/dataPanelTemplate';
import { getColDisplayText } from 'utils/dataPanelColUtils';

type SeriesOptions = Highcharts.SeriesSunburstOptions;
type TransformedData = { series: SeriesOptions[] };

type Props = {
  backgroundColor: string;
  panelData: DataPanelData | undefined;
  instructions: SunburstChartInstructions | undefined;
  dataPanelId: string;
  variables: DashboardVariableMap;
  globalStyleConfig: GlobalStyleConfig;
  generalOptions: VisualizeOperationGeneralFormatOptions | undefined;
  datasetNamesToId?: Record<string, string>;
  datasetData?: DatasetDataObject;
  drilldownEntryPoints: Record<string, DrilldownEntryPointInfo>;
  colorTracker?: ColumnColorTracker;
  processString: (s: string) => string;
};

export const SunburstChart: FC<Props> = ({
  generalOptions,
  instructions,
  panelData,
  dataPanelId,
  backgroundColor,
  globalStyleConfig,
  drilldownEntryPoints,
  processString,
}) => {
  const loading = !panelData || panelData.loading;

  const instructionsNeedConfiguration = useMemo(
    () => !isSunburstReadyToDisplay(instructions),
    [instructions],
  );

  const { series } = useMemo<TransformedData>(() => {
    if (!panelData?.rows || !panelData.schema || !instructions) return { series: [] };

    return {
      series: [
        {
          data: transformData(panelData.rows, panelData.schema, instructions),
          type: 'sunburst',
          levels: configureLevels(panelData.schema),
        },
      ],
    };
  }, [instructions, panelData?.rows, panelData?.schema]);

  if (loading || instructionsNeedConfiguration) {
    return (
      <NeedsConfigurationPanel
        fullHeight
        instructionsNeedConfiguration={instructionsNeedConfiguration}
        loading={loading}
      />
    );
  }

  const aggCol = instructions?.aggregations?.[0];
  const aggColTooltipName =
    (aggCol?.column.friendly_name
      ? processString(aggCol?.column.friendly_name)
      : aggCol
        ? getColDisplayText(aggCol)
        : '') ?? '';

  const chartOptions: Highcharts.Options = {
    chart: { type: 'sunburst', backgroundColor },
    series,
    title: sharedTitleConfig,
    colors: getColorPalette(globalStyleConfig, undefined),

    tooltip: {
      ...sharedTooltipConfigs,
      formatter: function () {
        const value = this.point.value;
        if (value == null) return null;
        return ReactDOMServer.renderToStaticMarkup(
          <ChartTooltip
            globalStyleConfig={globalStyleConfig}
            header={this.point?.name}
            points={[
              {
                color: String(this.point.color),
                name: aggColTooltipName,
                value: value || 0,
                format: {
                  decimalPlaces: instructions?.valueFormat?.decimalPlaces,
                  formatId: instructions?.valueFormat?.format?.id,
                },
              },
            ]}
          />,
        );
      },
    },
  };

  return (
    <DrilldownChart
      chartOptions={chartOptions}
      customMenuOptions={
        generalOptions?.customMenu?.enabled ? generalOptions?.customMenu?.menuOptions : undefined
      }
      dataPanelId={dataPanelId}
      drilldownEntryPoints={drilldownEntryPoints}
      // @ts-ignore
      instructions={instructions}
      underlyingDataEnabled={generalOptions?.enableRawDataDrilldown}
    />
  );
};
