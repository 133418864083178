import { AnyAction, createAsyncThunk, ThunkAction } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';

import { BaseCol, getTimezone, OPERATION_TYPES } from '@explo/data';

import { generateReportBuilderComputations } from '@explo/computation-generation';
import { createApiRequestConfig } from 'actions/actionUtils';
import { CustomerReportView } from 'actions/customerReportActions';
import { JobDefinition } from 'actions/jobQueueActions';
import { ReportBuilder } from 'actions/reportBuilderActions';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { ACTION } from 'actions/types';
import { createJob } from 'components/JobQueue/createJob';
import { getScatterPlotConfigError } from 'pages/ReportBuilder/utils/visualizationUtils';
import { ReduxState } from 'reducers/rootReducer';
import { getArchetypeProperties } from 'reducers/selectors';
import {
  fetchFidoReportBuilderQueryPreview,
  fetchFidoReportBuilderView,
} from 'reducers/thunks/dashboardDataThunks/fetchFidoDataThunks';
import { saveComputedView } from 'reducers/thunks/fidoThunks';
import * as RD from 'remotedata';
import {
  getAggDataId,
  getAiDataId,
  getDatasetDataId,
  getDistinctDataId,
  getDrilldownDataId,
  getTotalDataId,
} from 'reportBuilderContent/reducers/reportEditingUtils';
import { validateColumnsThunk } from 'reportBuilderContent/thunks/dataThunks';
import { getCurrentView, getFilterableRawColumns } from 'utils/customerReportUtils';
import { getCustomerVariables } from 'utils/customerUtils';
import { getFidoSchemaFromEmbeddoSchema } from 'utils/fido/fidoShims';
import { makeThunkRequest } from 'utils/thunkUtils';

import {
  DataPreviewType,
  FetchAppDataBody,
  FetchReportBuilderDatasetBody,
  FetchReportBuilderDatasetData,
} from '../apiTypes';

import { enqueueReportBuilderJobsThunk } from './jobThunks';
import {
  getAggBody,
  getDistinctColumnBody,
  getDrilldownView,
  getTotalBody,
  getViewRequestParams,
  isPivotView,
  useFidoForReportBuilderRequest,
} from './utils';

/**
 * Thunks for fetching customer report data in the Explo App's Report Builder Editor
 */

const getFetchAppDataBody = (
  dataset: ReportBuilderDataset,
  customerId: number,
  page: number,
  reportBuilder: RD.ResponseData<ReportBuilder>,
): FetchAppDataBody => {
  const timezone = RD.getOrDefault(reportBuilder, undefined)?.default_timezone || undefined;

  return {
    page,
    customer_id: customerId,
    query: dataset.query,
    parent_schema_id: dataset.parent_schema_id,
    column_configs: dataset.columnConfigs,
    custom_aggregations: dataset.customAggregations,
    timezone: getTimezone(timezone),
  };
};

export const fetchDashboardDatasetModalData =
  (datasetId: string): Thunk =>
  (dispatch, getState) => {
    const { customers, reportBuilder, currentUser, fido, embeddedReportBuilder } = getState();

    const customerId = customers.selectedGroupId;
    const dataset = embeddedReportBuilder.reportBuilderVersion?.config.datasets[datasetId];
    if (!dataset || !customerId) return;
    const customer = customers.cachedCustomers[customerId]?.customer;

    if (!customer) return;

    const postData = getFetchAppDataBody(
      dataset,
      customerId,
      1,
      reportBuilder.currentReportBuilder,
    );
    const fetchArgs: FetchAppReportDataArgs = {
      postData,
      page: 1,
      id: getDatasetDataId(datasetId),
    };

    if (
      useFidoForReportBuilderRequest(
        customerId,
        customers.cachedCustomers,
        currentUser,
        fido,
        dataset,
      )
    ) {
      dispatch(
        fetchFidoReportBuilderView({
          id: fetchArgs.id,
          dataset: dataset,
          body: {
            dataRequestParameters: {
              includeTotalResults: fetchArgs.shouldFetchRowCount,
              pagingConfiguration: { page: 0, perPage: fetchArgs.postData.limit ?? 50 },
            },
            queryContext: getCustomerVariables(customer, getArchetypeProperties(getState())),
            computation: null,
            requestExecutionParameters: null,
          },
          customerId,
          timezone: postData.timezone,
        }),
      );
    } else if (currentUser?.team?.feature_flags.use_job_queue) {
      dispatch(fetchAppReportDataJob(fetchArgs));
    } else {
      dispatch(fetchAppReportDataApi(fetchArgs));
    }
  };

const getAppRequest = (postData: FetchAppDataBody): AxiosRequestConfig =>
  createApiRequestConfig('report_builder/get_dataset_data/', 'POST', postData);

const getRowCountRequestConfig = (postData: FetchAppDataBody) =>
  createApiRequestConfig('report_builder/get_dataset_row_count/', 'POST', postData);

export const fetchAppDataset = createAsyncThunk<
  void,
  FetchReportBuilderDatasetBody,
  { state: ReduxState }
>(
  ACTION.FETCH_REPORT_BUILDER_DATASET_PREVIEW,
  async ({ datasetId, page, save, viewParams, dataPreviewType }, { getState, dispatch }) => {
    const { reportBuilderEdit, customers, currentUser, fido, reportBuilder } = getState();

    const customerId = customers.selectedGroupId;
    const dataset = RD.isSuccess(reportBuilderEdit.config)
      ? reportBuilderEdit.config.data.datasets[datasetId]
      : undefined;
    const backingGlobalDataset = RD.getOrDefault(fido.referencedGlobalDatasets, {})[datasetId];
    if (!dataset || !customerId) return;
    const customer = customers.cachedCustomers[customerId]?.customer;

    if (!customer) return;

    const query = backingGlobalDataset
      ? backingGlobalDataset.query
      : (dataset.queryDraft ?? dataset.query);
    const useFido = currentUser?.team?.feature_flags.use_fido;
    const postData: FetchAppDataBody = {
      ...getFetchAppDataBody(dataset, customerId, page || 1, reportBuilder.currentReportBuilder),
      ...viewParams,
      query,
      // For report builders using fido, pass in the dataset to copy necessary fields to the saved computed view
      dataset: useFido ? dataset : undefined,
    };

    if (
      useFidoForReportBuilderRequest(
        customerId,
        customers.cachedCustomers,
        currentUser,
        fido,
        dataset,
      )
    ) {
      dispatch(
        fetchFidoReportBuilderQueryPreview({
          dataset: dataset,
          body: {
            query,
            dataRequestParameters: {
              includeTotalResults: page == null,
              pagingConfiguration: { page: (page ?? 1) - 1, perPage: viewParams?.limit ?? 50 },
            },
            queryContext: getCustomerVariables(customer, getArchetypeProperties(getState())),
            computation: viewParams
              ? generateReportBuilderComputations(
                  viewParams,
                  dataset.customAggregations,
                  postData.timezone,
                ).dataComputation
              : null,
          },
          customerId,
          save,
          timezone: postData.timezone,
          dataPreviewType,
        }),
      );
    } else if (currentUser?.team?.feature_flags.use_job_queue) {
      dispatch(
        fetchAppDatasetJob({
          postData,
          datasetId,
          page,
          save,
          dataPreviewType,
        }),
      );
    } else {
      dispatch(fetchReportBuilderDatasetApi({ postData, datasetId, page, save, dataPreviewType }));
    }
  },
  {
    condition: ({ switchedToDataset, datasetId }, { getState }) => {
      const { datasetData, config } = getState().reportBuilderEdit;
      const { referencedGlobalDatasets } = getState().fido;

      // If dataset has already been loaded don't need to call again
      if (switchedToDataset && datasetId in datasetData) return false;

      const dataset = RD.isSuccess(config) ? config.data.datasets[datasetId] : undefined;
      if (!dataset) return false;

      const backingGlobalDataset = RD.getOrDefault(referencedGlobalDatasets, {})[datasetId];
      // If no query then no need to make request
      const query = backingGlobalDataset
        ? backingGlobalDataset.query
        : (dataset.queryDraft ?? dataset.query);
      return query.trim() !== '';
    },
  },
);

export const fetchReportBuilderDatasetApi = createAsyncThunk<
  FetchReportBuilderDatasetData,
  FetchReportBuilderDatasetArgs,
  { state: ReduxState }
>(
  ACTION.FETCH_REPORT_BUILDER_DATASET_PREVIEW_API,
  async ({ postData, datasetId, page }, { dispatch }) => {
    const requestConfig = getAppRequest(postData);
    const onSuccess =
      page == null ? () => dispatch(fetchAppDatasetRowCount({ datasetId, postData })) : undefined;

    return makeThunkRequest(requestConfig, 'Error loading dataset preview data', { onSuccess });
  },
);

const fetchAppDatasetJob =
  ({ postData, datasetId, page, save, dataPreviewType }: FetchReportBuilderDatasetArgs): Thunk =>
  (dispatch, getState) => {
    const { fido } = getState();
    const jobs: Record<string, JobDefinition> = {};
    const dataset = postData.dataset;
    const dataJob = createJob(
      {
        job_type: ACTION.FETCH_REPORT_BUILDER_DATASET_PREVIEW_JOB,
        job_args: { ...postData, datasetId, save, dataPreviewType },
      },
      () => {
        // Only run this for fido datasets that are using the job queue
        if (!dataset || !save) return;
        const selectedView = RD.getOrDefault(fido.computedViews, []).find(
          (view) => view.id === dataset.fido_id,
        );
        if (!selectedView) return;

        dispatch(
          saveComputedView({
            ...selectedView,
            query: postData.query ?? selectedView.query,
            columnDefinitions: getFidoSchemaFromEmbeddoSchema(dataset?.schema ?? []),
            permissions: dataset.permissions,
          }),
        );
      },
    );
    Object.assign(jobs, dataJob);

    if (page === undefined) {
      const rowCountJob = createJob({
        job_type: ACTION.FETCH_REPORT_BUILDER_DATASET_ROW_COUNT_JOB,
        job_args: { ...postData, datasetId },
      });
      Object.assign(jobs, rowCountJob);
    }
    dispatch(enqueueReportBuilderJobsThunk(jobs));
  };

type FetchReportBuilderDatasetArgs = {
  postData: FetchAppDataBody;
  // used when a dataset query is executed using embeddo, but we still want a copy in fid
  datasetFidoId?: string;
  datasetId: string;
  save: boolean | undefined;
  page: number | undefined;
  dataPreviewType: DataPreviewType;
};

export const fetchAppDatasetRowCount = createAsyncThunk<
  { row_count: number },
  { datasetId: string; postData: FetchAppDataBody },
  { state: ReduxState }
>(ACTION.FETCH_REPORT_BUILDER_DATASET_ROW_COUNT, async ({ postData }) => {
  const requestConfig = getRowCountRequestConfig(postData);
  return makeThunkRequest(requestConfig, 'Error loading row count for report builder dataset');
});

/**
 * @param page - Page to fetch. If page is undefined, gets row count
 */
export const fetchAppReportData =
  (page: number | undefined): Thunk =>
  (dispatch, getState) => {
    const dataset = dispatch(validateColumnsThunk());
    if (!dataset) return;

    // Get fresh state after validating columns
    const { currentConfig, currentView } = getState().reportEditing;
    const viewConfig = getCurrentView(currentConfig?.views, currentView);
    if (!viewConfig) return;

    dispatch(fetchAppData(page, dataset, viewConfig, viewConfig.id));
  };

/**
 * @param page - Page to fetch. If page is undefined, gets row count
 */
export const fetchAppDrilldownData =
  (page: number | undefined): Thunk =>
  (dispatch, getState) => {
    const dataset = dispatch(validateColumnsThunk());
    if (!dataset) return;

    // Get fresh state after validating columns
    const { currentConfig, currentView, drilldownConfigs } = getState().reportEditing;
    const viewConfig = getCurrentView(currentConfig?.views, currentView);
    const currentConfigs = currentView ? drilldownConfigs[currentView] : null;
    if (!viewConfig || !currentConfigs?.filters) return;

    const filteredConfig: CustomerReportView = getDrilldownView(
      viewConfig,
      currentConfigs.filters,
      currentConfigs.sort,
    );
    dispatch(fetchAppData(page, dataset, filteredConfig, getDrilldownDataId(viewConfig.id)));
  };

/**
 * @param page - Page to fetch. If page is undefined, gets row count
 * @param dataset
 * @param viewConfig
 * @param dataId
 */
const fetchAppData =
  (
    page: number | undefined,
    dataset: ReportBuilderDataset,
    viewConfig: CustomerReportView,
    dataId: string,
  ): Thunk =>
  (dispatch, getState) => {
    const { customers, currentUser, fido, reportBuilder } = getState();
    const customerId = customers.selectedGroupId;
    if (!customerId) return;

    const customer = customers.cachedCustomers[customerId]?.customer;
    if (!customer) return;

    // Scatter plots will fail when any two columns are the same.
    // Don't want to run failing endpoint in this case, so we check and return
    if (
      viewConfig.visualization === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2 &&
      getScatterPlotConfigError(
        viewConfig.groupBys?.[0],
        viewConfig.columnGroupBys?.[0],
        viewConfig.scatterPlotGrouping,
      )
    )
      return;

    const fetchAppDataBody = getFetchAppDataBody(
      dataset,
      customerId,
      page || 1,
      reportBuilder.currentReportBuilder,
    );
    const viewParams = getViewRequestParams(viewConfig, dataset, fetchAppDataBody.timezone);

    const postData: FetchAppDataBody = {
      ...fetchAppDataBody,
      ...viewParams,
    };

    // If first page data is requested and not a pivot table also get row count
    const shouldFetchRowCount = page === undefined && !isPivotView(viewConfig);
    const fetchArgs: FetchAppReportDataArgs = {
      postData,
      shouldFetchRowCount,
      page,
      id: dataId,
    };

    if (
      useFidoForReportBuilderRequest(
        customerId,
        customers.cachedCustomers,
        currentUser,
        fido,
        dataset,
      )
    ) {
      const computations = generateReportBuilderComputations(
        viewParams,
        dataset.customAggregations,
        postData.timezone,
      );

      dispatch(
        fetchFidoReportBuilderView({
          id: fetchArgs.id,
          dataset: dataset,
          body: {
            dataRequestParameters: {
              pagingConfiguration: {
                page: (page ?? 1) - 1,
                perPage: postData.limit ?? 50,
              },
              includeTotalResults: page == undefined,
            },
            queryContext: getCustomerVariables(customer, getArchetypeProperties(getState())),
            computation: computations.dataComputation,
            requestExecutionParameters: null,
          },
          customerId,
          timezone: postData.timezone,
        }),
      );
    } else if (currentUser?.team?.feature_flags.use_job_queue) {
      dispatch(fetchAppReportDataJob(fetchArgs));
    } else {
      dispatch(fetchAppReportDataApi(fetchArgs));
    }
  };

export const fetchAppReportDataApi = createAsyncThunk<
  FetchReportBuilderDatasetData,
  FetchAppReportDataArgs,
  DashboardState
>(
  ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_DATA,
  async ({ postData, shouldFetchRowCount, id }, { dispatch }) => {
    const requestConfig = getAppRequest(postData);
    const onSuccess = shouldFetchRowCount
      ? () => dispatch(fetchAppReportRowCount({ postData, id }))
      : undefined;
    return makeThunkRequest(requestConfig, 'Error loading preview data', { onSuccess });
  },
);

const fetchAppReportDataJob =
  ({ postData, shouldFetchRowCount, id }: FetchAppReportDataArgs): Thunk =>
  (dispatch) => {
    const jobs: Record<string, JobDefinition> = {};
    const dataJob = createJob({
      job_type: ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_DATA_JOB,
      job_args: { ...postData, id },
    });
    Object.assign(jobs, dataJob);

    if (shouldFetchRowCount) {
      const rowCountJob = createJob({
        job_type: ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_ROW_COUNT_JOB,
        job_args: { ...postData, id },
      });
      Object.assign(jobs, rowCountJob);
    }

    dispatch(enqueueReportBuilderJobsThunk(jobs));
  };

export const fetchAppReportRowCount = createAsyncThunk<
  { row_count: number },
  FetchAppRowCountArgs,
  DashboardState
>(ACTION.FETCH_REPORT_BUILDER_PREVIEW_REPORT_ROW_COUNT, async ({ postData }) => {
  const requestConfig = getRowCountRequestConfig(postData);
  return makeThunkRequest(requestConfig, 'Error loading row count report builder preview');
});

export const fetchAppDistinctColumnData =
  (column: BaseCol): Thunk =>
  (dispatch, getState) => {
    const { customers, reportBuilder, reportEditing, currentUser, fido, embeddedReportBuilder } =
      getState();
    const { currentConfig, currentView } = reportEditing;
    const viewConfig = getCurrentView(currentConfig?.views, currentView);
    const customerId = customers.selectedGroupId;
    const dataset =
      embeddedReportBuilder.reportBuilderVersion?.config.datasets[
        currentConfig?.dataInfo?.datasetId ?? ''
      ];
    if (!dataset || !customerId || !viewConfig) return;

    const customer = customers.cachedCustomers[customerId]?.customer;
    if (!customer) return;

    const viewParams = getDistinctColumnBody(
      column,
      currentUser.team?.configuration.rb_distinct_column_limit,
    );
    const postData: FetchAppDataBody = {
      ...viewParams,
      ...getFetchAppDataBody(dataset, customerId, 1, reportBuilder.currentReportBuilder),
    };

    const fetchArgs: FetchAppReportDataArgs = {
      postData,
      page: 1,
      id: getDistinctDataId(column),
    };

    if (
      useFidoForReportBuilderRequest(
        customerId,
        customers.cachedCustomers,
        currentUser,
        fido,
        dataset,
      )
    ) {
      dispatch(
        fetchFidoReportBuilderView({
          id: fetchArgs.id,
          dataset,
          body: {
            dataRequestParameters: {
              pagingConfiguration: { page: 0, perPage: postData.limit ?? 50 },
            },
            queryContext: getCustomerVariables(customer, getArchetypeProperties(getState())),
            computation: generateReportBuilderComputations(
              viewParams,
              dataset.customAggregations,
              postData.timezone,
            ).dataComputation,
            requestExecutionParameters: null,
          },
          customerId,
          timezone: postData.timezone,
        }),
      );
    } else if (currentUser?.team?.feature_flags.use_job_queue) {
      dispatch(fetchAppReportDataJob(fetchArgs));
    } else {
      dispatch(fetchAppReportDataApi(fetchArgs));
    }
  };

export const fetchAppAggData =
  (view: CustomerReportView): Thunk =>
  (dispatch, getState) => {
    const { customers, reportBuilder, reportEditing, currentUser, fido, embeddedReportBuilder } =
      getState();
    const { reportBuilderVersion } = embeddedReportBuilder;
    const customerId = customers.selectedGroupId;
    const datasetId = reportEditing.currentConfig?.dataInfo?.datasetId;
    const dataset = datasetId && reportBuilderVersion?.config.datasets[datasetId];
    if (!dataset || !customerId) return;

    const customer = customers.cachedCustomers[customerId]?.customer;
    if (!customer) return;

    const columns = getFilterableRawColumns(view.columnOrder, dataset);
    const body = getAggBody(columns);
    if (!body.aggs.length) return;

    const postData: FetchAppDataBody = {
      ...body,
      ...getFetchAppDataBody(dataset, customerId, 1, reportBuilder.currentReportBuilder),
    };

    const aggDataId = getAggDataId(dataset.id);
    const fetchArgs: FetchAppReportDataArgs = {
      postData,
      page: 1,
      id: aggDataId,
    };

    if (
      useFidoForReportBuilderRequest(
        customerId,
        customers.cachedCustomers,
        currentUser,
        fido,
        dataset,
      )
    ) {
      dispatch(
        fetchFidoReportBuilderView({
          id: aggDataId,
          dataset,
          body: {
            dataRequestParameters: {
              pagingConfiguration: { page: 0, perPage: postData.limit ?? 50 },
            },
            requestExecutionParameters: null,
            queryContext: getCustomerVariables(customer, getArchetypeProperties(getState())),
            computation: generateReportBuilderComputations(
              body,
              dataset.customAggregations,
              postData.timezone,
            ).dataComputation,
          },
          customerId,
          timezone: postData.timezone,
        }),
      );
    } else if (currentUser?.team?.feature_flags.use_job_queue) {
      dispatch(fetchAppReportDataJob(fetchArgs));
    } else {
      dispatch(fetchAppReportDataApi(fetchArgs));
    }
  };

export const fetchAppTotalData =
  (view: CustomerReportView): Thunk =>
  (dispatch, getState) => {
    const { customers, reportBuilder, reportEditing, currentUser, fido, embeddedReportBuilder } =
      getState();
    const { reportBuilderVersion } = embeddedReportBuilder;
    const customerId = customers.selectedGroupId;
    const datasetId = reportEditing.currentConfig?.dataInfo?.datasetId;
    const dataset = datasetId && reportBuilderVersion?.config.datasets[datasetId];
    const showTotals = reportBuilderVersion?.config?.general?.showTotals;
    if (!dataset || !customerId || !showTotals || !view.totals) return;

    const customer = customers.cachedCustomers[customerId]?.customer;
    if (!customer) return;

    const columns = getFilterableRawColumns(view.columnOrder, dataset);
    const fetchAppDataBody = getFetchAppDataBody(
      dataset,
      customerId,
      1,
      reportBuilder.currentReportBuilder,
    );
    const body = getTotalBody(
      columns,
      view.filters,
      view.aggregations,
      view.totals,
      fetchAppDataBody.timezone,
    );
    if (!body.aggs.length) return;

    const postData: FetchAppDataBody = {
      ...body,
      ...fetchAppDataBody,
    };

    const totalDataId = getTotalDataId(view.id);
    const fetchArgs: FetchAppReportDataArgs = { postData, page: 1, id: totalDataId };
    if (
      useFidoForReportBuilderRequest(
        customerId,
        customers.cachedCustomers,
        currentUser,
        fido,
        dataset,
      )
    ) {
      dispatch(
        fetchFidoReportBuilderView({
          id: totalDataId,
          dataset,
          body: {
            dataRequestParameters: {},
            queryContext: getCustomerVariables(customer, getArchetypeProperties(getState())),
            computation: generateReportBuilderComputations(
              body,
              dataset.customAggregations,
              postData.timezone,
            ).dataComputation,
            requestExecutionParameters: null,
          },
          customerId,
          timezone: postData.timezone,
        }),
      );
    } else if (currentUser?.team?.feature_flags.use_job_queue) {
      dispatch(fetchAppReportDataJob(fetchArgs));
    } else {
      dispatch(fetchAppReportDataApi(fetchArgs));
    }
  };

export const fetchAppAiData =
  (viewConfig: CustomerReportView): Thunk =>
  (dispatch, getState) => {
    const { customers, reportBuilder, reportEditing, currentUser, fido, embeddedReportBuilder } =
      getState();
    const customerId = customers.selectedGroupId;
    const dataset =
      embeddedReportBuilder.reportBuilderVersion?.config.datasets[
        reportEditing.currentConfig?.dataInfo?.datasetId ?? ''
      ];
    if (!dataset || !customerId) return;

    const customer = customers.cachedCustomers[customerId]?.customer;
    if (!customer) return;

    const fetchAppDataBody = getFetchAppDataBody(
      dataset,
      customerId,
      1,
      reportBuilder.currentReportBuilder,
    );

    const viewParams = getViewRequestParams(viewConfig, dataset, fetchAppDataBody.timezone);
    const postData: FetchAppDataBody = {
      ...viewParams,
      ...fetchAppDataBody,
    };

    const fetchArgs: FetchAppReportDataArgs = {
      postData,
      shouldFetchRowCount: false,
      page: 1,
      id: getAiDataId(),
    };

    if (
      useFidoForReportBuilderRequest(
        customerId,
        customers.cachedCustomers,
        currentUser,
        fido,
        dataset,
      )
    ) {
      dispatch(
        fetchFidoReportBuilderView({
          id: getAiDataId(),
          dataset: dataset,
          body: {
            dataRequestParameters: {
              pagingConfiguration: { page: 0, perPage: postData.limit ?? 50 },
            },
            queryContext: getCustomerVariables(customer, getArchetypeProperties(getState())),
            computation: generateReportBuilderComputations(
              viewParams,
              dataset.customAggregations,
              postData.timezone,
            ).dataComputation,
            requestExecutionParameters: null,
          },
          customerId,
          timezone: postData.timezone,
        }),
      );
    } else if (currentUser?.team?.feature_flags.use_job_queue) {
      dispatch(fetchAppReportDataJob(fetchArgs));
    } else {
      dispatch(fetchAppReportDataApi(fetchArgs));
    }
  };

type FetchAppReportDataArgs = {
  postData: FetchAppDataBody;
  shouldFetchRowCount?: boolean;
  id: string; // id is used in the reducer
  page: number | undefined; // page is used in the reducer
};

type FetchAppRowCountArgs = {
  postData: FetchAppDataBody;
  id: string; // id is used in the reducer
};

type DashboardState = {
  state: ReduxState;
};

type Thunk = ThunkAction<void, ReduxState, unknown, AnyAction>;
