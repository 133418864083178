import { produce, Draft } from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { AggregationType, DatasetSchema, OPERATION_TYPES, PivotAgg } from '@explo/data';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { SettingHeader } from 'components/SettingHeader';
import { showWarningToast } from 'shared/sharedToasts';
import * as utils from 'utils/V2ColUtils';
import { findMatchingAgg, findMatchingAggColIdx } from 'utils/aggUtils';

import { SunburstChartInstructions } from 'constants/types';
import DroppableColumnSection from './droppable/DroppableColumnSection';

type Props = {
  instructions: SunburstChartInstructions;
  loading: boolean;
  schema: DatasetSchema;
};

const opType = OPERATION_TYPES.VISUALIZE_SUNBURST;

export const SunburstConfig: FC<Props> = ({ instructions, schema, loading }) => {
  const dispatch = useDispatch();

  const updateInstructions = (updateFunc: (draft: Draft<SunburstChartInstructions>) => void) => {
    const newInstructions = produce(instructions, (draft) => {
      updateFunc(draft);
    });
    dispatch(updateVisualizeOperation(newInstructions, opType));
  };

  return (
    <div>
      <SettingHeader name="Categories" />
      <DroppableColumnSection
        required
        columns={instructions.rowGroupBys ?? []}
        disableEdits={loading}
        onColAdded={(col, oldColName) => {
          if ((instructions.rowGroupBys ?? []).some((group) => group.column.name === col.name)) {
            return showWarningToast(`Column ${col.name} is already selected`);
          }

          updateInstructions((draft) => {
            const newGroupBy = utils.createGroupByCol(col);
            if (!draft.rowGroupBys) draft.rowGroupBys = [];

            if (oldColName == undefined) {
              draft.rowGroupBys.push(newGroupBy);
            } else {
              const indexToReplace = draft.rowGroupBys.findIndex(
                (col) => col.column.name === oldColName,
              );
              if (indexToReplace !== -1) draft.rowGroupBys.splice(indexToReplace, 1, newGroupBy);
            }
          });
        }}
        onColOptionChanged={(option, colName) => {
          if (!colName) return;
          updateInstructions((draft) => {
            const col = (draft.rowGroupBys ?? []).find(({ column }) => column.name === colName);
            if (!col) return;

            col.bucket = { id: option.id as PivotAgg };
          });
        }}
        onRemoveCol={({ name: colName }) => {
          if (!colName) return;
          updateInstructions((draft) => {
            const groupByIdx = (draft.rowGroupBys ?? []).findIndex(
              ({ column }) => column.name === colName,
            );
            if (groupByIdx !== -1) (draft.rowGroupBys ?? []).splice(groupByIdx, 1);
          });
        }}
        schema={schema}
      />
      <SettingHeader name="Values" />
      <DroppableColumnSection
        required
        columns={instructions.aggregations ?? []}
        disableEdits={loading}
        maxCols={1}
        onColAdded={(col, oldColName, oldColAggType) => {
          const newAgg = utils.getNewAggCol(col, instructions.aggregations);

          if (!newAgg) {
            return showWarningToast(`There are no more ways to aggregate ${col.name}`);
          }

          updateInstructions((draft) => {
            if (!draft.aggregations) draft.aggregations = [];

            if (oldColName == undefined) {
              draft.aggregations.push(newAgg);
            } else {
              const indexToReplace = findMatchingAggColIdx(
                instructions.aggregations,
                oldColName,
                oldColAggType,
              );
              if (indexToReplace === -1) return;

              draft.aggregations.splice(indexToReplace, 1, newAgg);
            }
          });
        }}
        onColOptionChanged={(option, colName, aggType) =>
          updateInstructions((draft) => {
            const newAgg = option as AggregationType;

            if (findMatchingAgg(draft.aggregations, colName, newAgg)) {
              if (newAgg.id === aggType?.id) return;
              return showWarningToast(
                'The selected aggregation is already present for this column. Duplicates are not allowed.',
              );
            }

            const colIdx = findMatchingAggColIdx(draft.aggregations, colName, aggType);
            if (colIdx === -1) return;
            (draft.aggregations ?? [])[colIdx].agg = { id: newAgg.id, formula: newAgg.formula };
          })
        }
        onRemoveCol={(col, aggType) =>
          updateInstructions((draft) => {
            const aggColIdx = findMatchingAggColIdx(draft.aggregations, col.name, aggType);
            if (aggColIdx !== -1) (draft.aggregations ?? []).splice(aggColIdx, 1);
          })
        }
        schema={schema}
      />
    </div>
  );
};
