import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
  CustomerReportFilter,
  FilterOperator,
  FilterValueType,
  isFilterClauseIncomplete,
} from '@explo/data';

import { sprinkles } from 'components/ds';
import { EmbedButton } from 'components/embed';
import { deleteFilter } from 'reportBuilderContent/reducers/reportEditingReducer';
import { FilterableColumn } from 'utils/customerReportUtils';
import { areFilterValuesEqualWithoutOrder } from 'utils/filterUtils';

type Props = {
  clause: CustomerReportFilter | undefined;
  column: FilterableColumn;
  operator: FilterOperator;
  showDelete: boolean;
  value: FilterValueType;
  onApply: () => void;
};

const buttonRowClass = sprinkles({
  flexItems: 'alignCenter',
  justifyContent: 'flex-end',
  marginTop: 'sp2',
  paddingX: 'sp2',
});

export const ApplyFilterRow: FC<Props> = ({
  clause,
  column,
  operator,
  value,
  onApply,
  showDelete,
}) => {
  const dispatch = useDispatch();

  const isDisabled = useMemo(() => {
    const col = { name: column.name, type: column.type };
    const newClause: CustomerReportFilter = {
      filterColumn: col,
      filterOperation: { id: operator },
      filterValue: value,
      id: clause?.id ?? 0,
    };
    return (
      isFilterClauseIncomplete(newClause) ||
      (operator === clause?.filterOperation.id &&
        (Array.isArray(clause.filterValue)
          ? areFilterValuesEqualWithoutOrder(clause, newClause)
          : clause?.filterValue === value))
    );
  }, [clause, operator, value, column]);

  return (
    <div className={buttonRowClass}>
      {clause && showDelete ? (
        <EmbedButton
          className={sprinkles({ marginRight: 'sp2' })}
          onClick={() => dispatch(deleteFilter(clause.id))}
          variant="tertiary">
          Delete
        </EmbedButton>
      ) : null}
      <EmbedButton disabled={isDisabled} onClick={onApply} variant="primary">
        Apply
      </EmbedButton>
    </div>
  );
};
