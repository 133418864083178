import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { FLOAT } from '@explo/data';

import { CustomerReportAgg } from 'actions/customerReportActions';
import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';
import { EmbedButton } from 'components/embed';
import { DropdownOption } from 'components/resource/EmbeddedDropdownMenu/DropdownOption';
import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';
import { SCHEMA_TYPE_ICON_MAP } from 'pages/ReportBuilder/constants';
import { addAgg } from 'reportBuilderContent/reducers/reportEditingReducer';
import { getAggColDisplay, getColDisplay, getAggColConfig } from 'utils/V2ColUtils';
import { DraggableColumnInfo, getNewAgg } from 'utils/customerReportUtils';
import { DashboardVariableMap } from 'types/dashboardTypes';
type Props = {
  disabled?: boolean;
  columns: DraggableColumnInfo[];
  aggregations: CustomerReportAgg[];
  customAggregations?: CustomerReportAgg[];
  columnConfigs?: ReportBuilderColConfigs;
  tooltipText?: string;
  variables: DashboardVariableMap;
};

export const AddAggMenu: FC<Props> = ({
  columns,
  disabled,
  aggregations,
  customAggregations,
  tooltipText,
  columnConfigs,
  variables,
}) => {
  const dispatch = useDispatch();

  const selectedSet = useMemo(
    () => new Set(aggregations.map((agg) => agg.column.name)),
    [aggregations],
  );

  const aggOptions = useMemo(() => {
    const aggOptions: DropdownOption[] = [];
    customAggregations?.forEach((agg) => {
      if (!agg.agg.formula || !agg.column.name || selectedSet.has(agg.column.name)) return;

      aggOptions.push({
        name: getAggColDisplay(agg, columnConfigs, variables),
        value: agg.column.name,
        icon: SCHEMA_TYPE_ICON_MAP[FLOAT],
        description: getAggColConfig(agg, columnConfigs)?.description,
        group: 'Custom',
        // Formula is taken from the dataset when data is being loaded rather than saved, so it's always up-to-date
        onClick: () => dispatch(addAgg({ ...agg, agg: { id: agg.agg.id, formula: '' } })),
      });
    });
    return aggOptions;
  }, [columnConfigs, customAggregations, dispatch, selectedSet, variables]);

  const hasAggOptions = aggOptions.length > 0;
  const columnOptions = useMemo(() => {
    const columnOptions: DropdownOption[] = [];
    columns?.forEach((col) => {
      const newAgg = getNewAgg(col, aggregations);
      if (!newAgg) return;
      columnOptions.push({
        name: getColDisplay(col, columnConfigs),
        value: col.name,
        icon: SCHEMA_TYPE_ICON_MAP[col.type],
        group: hasAggOptions ? 'Report Data' : '',
        onClick: () => dispatch(addAgg(newAgg)),
      });
    });
    return columnOptions;
  }, [columns, columnConfigs, hasAggOptions, aggregations, dispatch]);

  const menuOptions = useMemo(() => [...aggOptions, ...columnOptions], [aggOptions, columnOptions]);
  if (!menuOptions?.length) return null;

  return (
    <EmbeddedDropdownMenu canSearch disabled={disabled} menuOptions={menuOptions}>
      <EmbedButton
        disabled={disabled}
        icon="plus"
        tooltipProps={tooltipText ? { text: tooltipText } : undefined}
        variant="tertiary"
      />
    </EmbeddedDropdownMenu>
  );
};
