import { Icon } from '@blueprintjs/core';
import { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { FilterOperator, TIME_COLUMN_TYPES } from '@explo/data';

import { sendPing } from 'actions/pingActions';
import { Modal, Select, sprinkles } from 'components/ds';
import { SelectItems } from 'components/ds/Select';
import { SCHEMA_DATA_TYPES_BY_ID } from 'constants/dataConstants';
import { PingTypes } from 'constants/pingTypes';
import { ROUTE_PROVIDERS } from 'constants/routes';
import {
  CategoryChartColumnInfo,
  ColorColumnOption,
  DrilldownColumnIndexToTypeMap,
  DrilldownColumnType,
} from 'constants/types';
import { getDrilledDownIntoChildDashboardMessage } from 'pages/dashboardPage/LayersPanel/pingMessages';
import { setSelectedDashboardDrilldownInfo } from 'reducers/dashboardInteractionsReducer';
import { appendCurrentSourceInfo, setDrilldownFilters } from 'reducers/drilldownsReducer';
import { switchCurrentEmbedDashboard } from 'reducers/embedDashboardReducer';
import { DashboardStates } from 'reducers/rootReducer';
import { getDashboardIdToNameMap, getSelectedDashboardVersion, getTeam } from 'reducers/selectors';
import { EVENTS, trackEvent } from 'telemetry/exploAnalytics';
import { DashboardVariable } from 'types/dashboardTypes';
import { DataPanel } from 'types/exploResource';
import {
  COLUMN_TYPE_TO_SUPPORTED_FILTER_OPERATOR_IDS_MAP,
  ValuePlurality,
} from 'types/filterOperations';
import {
  getDrilldownMenuText,
  retrieveDrilldownVariablesWithParentDashboardNamesMap,
} from 'utils/dashboardDrilldownUtils';
import { parseVariableSelectIntoCorrectColType } from 'utils/drilldownUtils';
import { transformObjectToSinglePropertyObjectArray } from 'utils/objectUtils';
import { VariableNames } from 'utils/variableUtils';
import { formatDateField } from '../utils';

interface OperatorRowRenderInfo {
  columnName: string;
  variableName: string;
  columnDataType: string;
  drilldownColumnType: DrilldownColumnType;
  selectedValue: DashboardVariable;
}

export const DashboardDrilldownOptionsModal: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    selectedDashboardDrilldownInfo,
    dashboardIdToNameMap,
    drilldownDatasetFilters,
    selectedDashboardVersion,
    variablesMap,
    team,
    isEmbed,
  } = useSelector((state: DashboardStates) => {
    return {
      selectedDashboardDrilldownInfo: state.dashboardInteractions.selectedDashboardDrilldownInfo,
      selectedDashboardVersion: getSelectedDashboardVersion(state),
      dashboardIdToNameMap: getDashboardIdToNameMap(state),
      drilldownDatasetFilters: state.drilldowns.drilldownDatasetFilters,
      variablesMap: state.dashboardData.variables ?? {},
      team: getTeam(state),
      isEmbed:
        'dashboardLayout' in state ? state.dashboardLayout.requestInfo.type === 'embedded' : false,
    };
  });

  const sourceDataPanel: DataPanel | null = useMemo(() => {
    if (!selectedDashboardDrilldownInfo || !selectedDashboardVersion) {
      return null;
    }

    return selectedDashboardVersion.configuration.data_panels[
      selectedDashboardDrilldownInfo.sourceDataPanelId
    ];
  }, [selectedDashboardDrilldownInfo, selectedDashboardVersion]);

  const drilldownEntryPoint = useMemo(() => {
    if (!sourceDataPanel || !selectedDashboardDrilldownInfo) {
      return null;
    }

    return sourceDataPanel.drilldownEntryPoints[
      selectedDashboardDrilldownInfo.drilldownEntryPointId
    ];
  }, [sourceDataPanel, selectedDashboardDrilldownInfo]);

  const entryPointColumns = useMemo(() => {
    if (!drilldownEntryPoint) {
      return [];
    }

    return drilldownEntryPoint.sourceChartColumns;
  }, [drilldownEntryPoint]);

  const operatorRowRenderInfos: (OperatorRowRenderInfo | null)[] = useMemo(() => {
    if (!selectedDashboardDrilldownInfo || !sourceDataPanel) {
      return [];
    }

    let drilldownColumnIndex = 0;
    return entryPointColumns
      .flatMap((entryPointColumn) => {
        const columnType = entryPointColumn.type;
        if (!columnType) {
          return null;
        }

        const doesEntryPointColumnMatchAnySourceColumns =
          entryPointColumn.name === selectedDashboardDrilldownInfo.selectedPrimaryColumnName ||
          entryPointColumn.name === selectedDashboardDrilldownInfo.selectedSecondaryColumnName;
        if (!doesEntryPointColumnMatchAnySourceColumns) {
          return null;
        }
        const drilldownColumnType = DrilldownColumnIndexToTypeMap[drilldownColumnIndex];
        const selectedValue = getDrilldownVariableValue(
          selectedDashboardDrilldownInfo.selectedPrimaryField,
          selectedDashboardDrilldownInfo.selectedSecondaryField,
          drilldownColumnType,
          createCategoryOrColorColumn(drilldownColumnType, sourceDataPanel),
        );
        drilldownColumnIndex++;
        if (typeof selectedValue === 'object') {
          const flattenedSelectedValue = transformObjectToSinglePropertyObjectArray(
            selectedValue as Record<string, DashboardVariable>,
          );
          return flattenedSelectedValue.map(({ key, value }) => {
            return {
              columnName: entryPointColumn.name || '',
              variableName: `${key}`,
              columnDataType: columnType,
              drilldownColumnType,
              selectedValue: value as DashboardVariable,
            };
          });
        }
        return {
          columnName: entryPointColumn.name || '',
          variableName: '',
          columnDataType: columnType,
          drilldownColumnType,
          selectedValue,
        };
      })
      .filter((renderInfo) => renderInfo !== null);
  }, [selectedDashboardDrilldownInfo, sourceDataPanel, entryPointColumns]);

  const [selectedFilterOperators, setSelectedFilterOperators] = useState<
    (FilterOperator | undefined)[]
  >(new Array(operatorRowRenderInfos.length).fill(undefined));

  const isDrillInButtonDisabled = useMemo(() => {
    const selectedFilterOperatorIds = Object.values(selectedFilterOperators);
    return (
      selectedFilterOperatorIds.some((operatorId) => !operatorId) ||
      selectedFilterOperatorIds.length !== operatorRowRenderInfos.length
    );
  }, [selectedFilterOperators, operatorRowRenderInfos]);

  const dashboardNameToIdMap = useMemo(() => {
    const dashboardNameToIdMap: Map<string, number> = new Map();
    Object.entries(dashboardIdToNameMap).forEach(([dashboardId, dashboardName]) => {
      dashboardNameToIdMap.set(dashboardName, Number(dashboardId));
    });
    return dashboardNameToIdMap;
  }, [dashboardIdToNameMap]);

  if (!drilldownEntryPoint || !sourceDataPanel || !selectedDashboardDrilldownInfo) {
    return null;
  }

  const destinationDashboardName = dashboardIdToNameMap[drilldownEntryPoint.destinationDashboardId];

  return (
    <Modal
      isOpen
      onClose={() => {
        setSelectedFilterOperators([]);
        dispatch(setSelectedDashboardDrilldownInfo(null));
      }}
      primaryButtonProps={{
        disabled: isDrillInButtonDisabled,
        text: 'Apply Drilldown Filter',
        onClick: () => {
          const drilldownVariables: Record<string, DashboardVariable> = {};
          const updatedDrilldownDatasetFilters = { ...drilldownDatasetFilters };
          const sourceDashboardName = dashboardIdToNameMap[drilldownEntryPoint.sourceDashboardId];
          operatorRowRenderInfos.forEach((renderInfo, index) => {
            const operatorId = selectedFilterOperators[index];
            if (!operatorId || !renderInfo) {
              return;
            }
            const currentDrilldownDatasetFilters =
              updatedDrilldownDatasetFilters[sourceDataPanel.table_id];
            const columnName = renderInfo.columnName;
            updatedDrilldownDatasetFilters[sourceDataPanel.table_id] = {
              ...currentDrilldownDatasetFilters,
              [`${columnName}`]: [
                ...(currentDrilldownDatasetFilters?.[`${columnName}`] || []),
                {
                  operatorId,
                  filterValue: renderInfo.selectedValue,
                  drilldownEntryPointId: selectedDashboardDrilldownInfo.drilldownEntryPointId,
                },
              ],
            };
            let drilldownVariableName = `${sourceDashboardName}.${sourceDataPanel.provided_id}.${columnName}`;
            if (renderInfo.variableName) {
              drilldownVariableName += `.${renderInfo.variableName}`;
            }
            drilldownVariables[drilldownVariableName] = renderInfo.selectedValue;
          });
          const drilldownVariablesToPreserve =
            retrieveDrilldownVariablesWithParentDashboardNamesMap(
              variablesMap,
              dashboardNameToIdMap,
              () => true,
            );
          drilldownVariablesToPreserve.forEach((variable) => {
            drilldownVariables[variable.key] = variable.value;
          });

          dispatch(
            appendCurrentSourceInfo({
              sourceDashboardId: drilldownEntryPoint.sourceDashboardId,
              sourceDataPanelId: sourceDataPanel.id,
              drilldownEntryPointId: selectedDashboardDrilldownInfo.drilldownEntryPointId,
            }),
          );
          dispatch(setDrilldownFilters(updatedDrilldownDatasetFilters));
          dispatch(
            sendPing({
              postData: {
                message: getDrilledDownIntoChildDashboardMessage(
                  team?.team_name ?? '',
                  dashboardIdToNameMap[drilldownEntryPoint.sourceDashboardId],
                  destinationDashboardName,
                ),
                message_type: PingTypes.PING_DASHBOARD_DRILLDOWN,
              },
            }),
          );
          trackEvent(EVENTS.DRILLED_DOWN_INTO_CHILD_DASHBOARD, {
            source_dashboard_name: dashboardIdToNameMap[drilldownEntryPoint.sourceDashboardId],
            destination_dashboard_name: destinationDashboardName,
            source_data_panel_id: sourceDataPanel.id,
          });
          if (isEmbed) {
            dispatch(
              switchCurrentEmbedDashboard({
                currentDashboardId: drilldownEntryPoint.destinationDashboardId,
                variables: drilldownVariables,
              }),
            );
          } else {
            history.push(
              ROUTE_PROVIDERS.DASHBOARD(`${drilldownEntryPoint.destinationDashboardId}`),
              {
                drilldownVariables: drilldownVariables,
              },
            );
          }
        },
      }}
      size="small"
      title={getDrilldownMenuText(destinationDashboardName, drilldownEntryPoint)}>
      <div className={sprinkles({ paddingX: 'sp3' })}>
        {operatorRowRenderInfos.map((renderInfo, index) => {
          if (!renderInfo) {
            return null;
          }

          const supportedFilterOperators = COLUMN_TYPE_TO_SUPPORTED_FILTER_OPERATOR_IDS_MAP.get(
            renderInfo.columnDataType,
          );

          if (!supportedFilterOperators) {
            return null;
          }
          const drilldownColumnOperatorValues: SelectItems<string> = [];
          supportedFilterOperators.forEach((operator) => {
            // TODO(zifanxiang): Revisit this decision to filter out plural operators. The thinking
            // currently is that even with plural values such as the date ranges that are produced
            // from date bucket categories, we'll expand into two singular values (a start date and
            // an end date).
            if (operator.valuePlurality === ValuePlurality.PLURAL) {
              return;
            }

            drilldownColumnOperatorValues.push({
              label: operator.name,
              value: operator.id,
            });
          });
          const { columnName, variableName, columnDataType, selectedValue } = renderInfo;
          const renderedSelectedValue =
            TIME_COLUMN_TYPES.has(columnDataType) && selectedValue
              ? formatDateField(selectedValue as string, columnDataType, undefined, true)
              : selectedValue;
          const combinedId = `${columnName}.${variableName}`;

          return (
            <div
              className={sprinkles({
                display: 'flex',
                alignItems: 'center',
                gap: 'sp2',
                justifyContent: 'flex-start',
                marginBottom: index === operatorRowRenderInfos.length - 1 ? undefined : 'sp1',
              })}
              key={combinedId}>
              {operatorRowRenderInfos[index]?.variableName === VariableNames.START_DATE && (
                <div className={sprinkles({ body: 'b2' })} style={{ width: 72 }}>
                  Start Date
                </div>
              )}
              {operatorRowRenderInfos[index]?.variableName === VariableNames.END_DATE && (
                <div className={sprinkles({ body: 'b2' })} style={{ width: 72 }}>
                  End Date
                </div>
              )}
              <div
                className={sprinkles({
                  display: 'flex',
                  alignItems: 'center',
                  gap: 'sp1',
                })}>
                <Icon icon={SCHEMA_DATA_TYPES_BY_ID[columnDataType].icon} />
                <span className={sprinkles({ heading: 'h4' })}>{columnName}</span>
              </div>
              <Select
                contentWidth="auto"
                onChange={(operatorId) => {
                  const updatedSelectedFilterOperators = [...selectedFilterOperators];
                  updatedSelectedFilterOperators[index] = operatorId as FilterOperator;
                  setSelectedFilterOperators(updatedSelectedFilterOperators);
                }}
                selectedValue={selectedFilterOperators[index]}
                values={drilldownColumnOperatorValues}
              />
              <div>{renderedSelectedValue}</div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

const getDrilldownVariableValue = (
  category: string,
  subCategory: string | undefined,
  drilldownColumnType: DrilldownColumnType,
  targetColumn: CategoryChartColumnInfo | ColorColumnOption,
): DashboardVariable => {
  switch (drilldownColumnType) {
    case DrilldownColumnType.PRIMARY:
      return parseVariableSelectIntoCorrectColType(category, targetColumn);
    case DrilldownColumnType.SECONDARY:
      return parseVariableSelectIntoCorrectColType(subCategory || '', targetColumn);
  }
  throw new Error('Unsupported column type');
};

const createCategoryOrColorColumn = (
  drilldownColumnType: DrilldownColumnType,
  sourceDataPanel: DataPanel,
): CategoryChartColumnInfo | ColorColumnOption => {
  const instructions = sourceDataPanel.visualize_op.instructions.V2_TWO_DIMENSION_CHART;
  switch (drilldownColumnType) {
    case DrilldownColumnType.PRIMARY:
      if (!instructions?.categoryColumn) {
        throw new Error('Primary category column is not defined');
      }
      return instructions?.categoryColumn;
    case DrilldownColumnType.SECONDARY:
      if (!instructions?.colorColumnOptions || !instructions?.colorColumnOptions[0]) {
        throw new Error('Secondary color column is not defined');
      }
      return instructions?.colorColumnOptions[0];
  }
  throw new Error('Unsupported column type');
};
