import { ThunkAction } from '@reduxjs/toolkit';

import { ROUTE_PROVIDERS } from 'constants/routes';
import { ReduxState } from 'reducers/rootReducer';

import { History } from 'history';
import {
  ItemType,
  setCurrentItemInfo,
  setFoldersExpansionState,
} from 'reducers/dataLibraryReducer';
import { getAllPathsUpToPath, getParentPath } from './dataLibraryUtil';

export const navigateToPathThunk =
  (
    resourceId: string,
    resourceType: ItemType,
    path: string,
    history: History,
  ): ThunkAction<void, ReduxState, unknown, ReturnType<typeof setCurrentItemInfo>> =>
  (dispatch) => {
    dispatch(setCurrentItemInfo({ path, type: resourceType }));
    const url =
      resourceType === ItemType.FOLDER
        ? ROUTE_PROVIDERS.DATA_LIBRARY_FOLDER(resourceId)
        : ROUTE_PROVIDERS.DATA_LIBRARY_DATASET(resourceId);
    history.push(url);
  };

export const navigateToPathAndExpandFoldersThunk =
  (
    resourceId: string,
    resourceType: ItemType,
    path: string,
    history: History,
  ): ThunkAction<
    void,
    ReduxState,
    unknown,
    ReturnType<typeof setCurrentItemInfo | typeof setFoldersExpansionState>
  > =>
  (dispatch) => {
    dispatch(navigateToPathThunk(resourceId, resourceType, path, history));

    const parentFolderPath = getParentPath(path);
    const allPathsUpToFolderPath = getAllPathsUpToPath(parentFolderPath);
    const expandedFoldersState = new Map<string, boolean>();
    allPathsUpToFolderPath.forEach((path) => expandedFoldersState.set(path, true));
    dispatch(setFoldersExpansionState(expandedFoldersState));
  };
