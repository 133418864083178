import { getTimezoneAwareDate } from '@explo/data';
import { sprinkles } from '@explo/design-system';
import type { ScheduledEmailExportDto } from '@explo/embeddo-api';
import { FC, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { TablePager } from 'components/dataTable/tablePager';
import { Icon, Tooltip } from 'components/ds';
import { FilterPopover } from './filters/FilterPopover';

import { ROUTE_PROVIDERS } from 'constants/routes';
import { capitalize } from 'utils/standard';
import {
  EXPORT_STATUS,
  ExportSortOption,
  INITIAL_FILTER_STATE,
  SCHEDULED_EXPORTS_PER_PAGE,
} from './constants';
import { FilterState } from './types';
import { filterExports, formatCadence, formatUpcoming, sortExports } from './utils';

import cx from 'classnames';
import { useExportsList } from 'features/dataShare/hooks';
import * as styles from './styles.css';

type CellProps = {
  icon?: React.ReactNode;
  className?: string;
  showTooltip?: boolean;
  to?: string;
};

const Cell: FC<CellProps> = ({ icon, className, showTooltip, children, to }) => {
  const cellTextContainer = <div className={cx(styles.cellText, className)}>{children}</div>;
  const cellContent = to ? (
    <Link className={sprinkles({ color: 'inherit', truncateText: 'ellipsis' })} to={to}>
      {cellTextContainer}
    </Link>
  ) : (
    cellTextContainer
  );

  return (
    <div className={styles.cellContainer}>
      {icon}
      {showTooltip ? <Tooltip text={children}>{cellContent}</Tooltip> : cellContent}
    </div>
  );
};

type Props = {
  sortOption: ExportSortOption;
  searchTerm?: string;
};

export const ScheduledExports: FC<Props> = ({ sortOption, searchTerm }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [appliedFilters, setAppliedFilters] = useState<FilterState>(INITIAL_FILTER_STATE);
  const [selectedFilters, setSelectedFilters] = useState<FilterState>(INITIAL_FILTER_STATE);
  const { data } = useExportsList();

  const exports = useMemo(() => {
    const filteredExports = filterExports(data ?? [], { searchTerm, filters: appliedFilters });
    return sortExports(filteredExports, sortOption);
  }, [sortOption, searchTerm, appliedFilters, data]);

  const totalExports = useMemo(() => exports.length, [exports]);
  const maxPageNumber = useMemo(
    () => Math.max(Math.ceil(totalExports / SCHEDULED_EXPORTS_PER_PAGE), 1),
    [totalExports],
  );

  const renderListHeader = () => {
    return (
      <div className={styles.rowWrapper}>
        <div className={styles.headerCell}>UPCOMING</div>
        <div className={styles.headerCell}>CADENCE</div>
        <div className={styles.headerCell}>TYPE</div>
        <div className={styles.headerCell}>EXPORT NAME</div>
        <div className={styles.headerCell}>STATUS</div>
        <div className={styles.headerCell}>SOURCE</div>
        <div className={styles.headerCell}>RECIPIENTS</div>
        <div className={styles.headerCell}>LAST EDITED</div>
      </div>
    );
  };

  const renderStatusIcon = (status: ScheduledEmailExportDto['status']) => {
    switch (status) {
      case EXPORT_STATUS.DRAFT:
        return <Icon className={sprinkles({ color: 'contentTertiary' })} name="pencil" />;
      case EXPORT_STATUS.PAUSED:
        return <Icon className={sprinkles({ color: 'contentTertiary' })} name="pause" />;
      case EXPORT_STATUS.ACTIVE:
        return <Icon name="play" />;
      default:
        return null;
    }
  };

  const renderList = () => {
    return (
      <div className={styles.listContainer}>
        {renderListHeader()}
        {exports.map((mockedExport, index) => {
          // TODO: handle multiple source resources
          const firstDashboardSource = mockedExport.bodyAssets.find(
            (asset) => asset['@type'] === 'dashboard-image',
          );
          return (
            <div
              className={cx(
                styles.rowHasHover,
                index === 0
                  ? styles.topRowWrapper
                  : index === exports.length - 1
                    ? styles.bottomRowWrapper
                    : undefined,
              )}
              key={mockedExport.id}>
              <Cell showTooltip>{formatUpcoming(mockedExport.schedule)}</Cell>
              <Cell showTooltip>{formatCadence(mockedExport.schedule)}</Cell>
              <Cell
                icon={
                  <Icon className={sprinkles({ color: 'contentSecondary' })} name="envelopes" />
                }>
                Email
              </Cell>
              <Cell
                showTooltip
                className={sprinkles({ color: 'brandPrimary', fontWeight: 700 })}
                // TODO: Make link based on export type
                to={ROUTE_PROVIDERS.EMAIL_EXPORT(mockedExport.id)}>
                {mockedExport.name}
              </Cell>
              <Cell icon={renderStatusIcon(mockedExport.status)}>
                {capitalize(mockedExport.status)}
              </Cell>
              <Cell
                showTooltip
                className={sprinkles({ color: 'brandPrimary' })}
                to={
                  firstDashboardSource
                    ? ROUTE_PROVIDERS.DASHBOARD(firstDashboardSource.dashboardId.toString())
                    : undefined
                }>
                {firstDashboardSource?.dashboardName ?? '--'}
              </Cell>
              <Cell icon={<Icon name="users" />}>{mockedExport.recipients.totalCount}</Cell>
              <Cell>{getTimezoneAwareDate(mockedExport.lastEditedAt).toRelative()}</Cell>
            </div>
          );
        })}
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className={styles.footer}>
        <div>
          {totalExports} scheduled instance{totalExports === 1 ? '' : 's'}
        </div>
        <TablePager
          currentPage={currentPage}
          maxPageNumber={maxPageNumber}
          onNewPage={(newPage) => {
            const newPageNumber = parseInt(newPage);

            if (
              !newPageNumber ||
              isNaN(newPageNumber) ||
              newPageNumber < 1 ||
              newPageNumber > maxPageNumber ||
              currentPage === newPageNumber
            ) {
              return;
            }
            setCurrentPage(newPageNumber);
          }}
        />
        <div />
      </div>
    );
  };

  return (
    <>
      <div className={sprinkles({ marginX: 'sp3', marginY: 'sp1' })}>
        <FilterPopover
          applied={appliedFilters}
          selected={selectedFilters}
          setApplied={setAppliedFilters}
          setSelected={setSelectedFilters}
        />
      </div>
      <div className={styles.scroll}>
        <div className={styles.content}>
          <div className={sprinkles({ flexItems: 'column', gap: 'sp1', marginBottom: 'sp2' })}>
            {renderList()}
          </div>
        </div>
      </div>
      {renderFooter()}
    </>
  );
};
