import { FC, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { saveDashboardElementUpdates } from 'actions/dashboardV2Actions';
import { TextFieldModal } from 'components/modals/textFieldModal';
import { RESERVED_VARIABLES } from 'constants/variables';
import { ReduxState } from 'reducers/rootReducer';
import {
  getArchetypeProperties,
  getCurrentDashboardAllElementsWithDrilldowns,
} from 'reducers/selectors';
import { varRenameThunk } from 'reducers/thunks/dashboardDataThunks/variableUpdateThunks';
import { EVENTS, trackEvent } from 'telemetry/exploAnalytics';
import { DASHBOARD_LAYOUT_CONFIG, DashboardElement } from 'types/dashboardTypes';

type Props = { element: DashboardElement; onClose: () => void };

export const RenameElementModal: FC<Props> = ({ element, onClose }) => {
  const dispatch = useDispatch();

  const { elements, archetypeProperties } = useSelector(
    (state: ReduxState) => ({
      elements: getCurrentDashboardAllElementsWithDrilldowns(state),
      archetypeProperties: getArchetypeProperties(state),
    }),
    shallowEqual,
  );

  const allElementNames = useMemo(
    () => new Set(Object.values(elements ?? {}).map((elem) => elem.name)),
    [elements],
  );

  return (
    <TextFieldModal
      modalOpen
      buttonName="Save"
      closeModal={onClose}
      getErrorMessage={(val) => {
        if (!val) return 'An element name is required and cannot be empty.';
        if (val !== element.name && allElementNames.has(val))
          return 'An element with this name on this dashboard already exists. Please choose a different one.';
        if (archetypeProperties.has(val) || RESERVED_VARIABLES.has(val)) {
          return `The name "${val}" is reserved. Please choose a different name.`;
        }
      }}
      modalTitle="Enter Dashboard Element Name"
      onSubmit={(name) => {
        if (allElementNames.has(name) || name === element.name) return;

        onClose();
        dispatch(varRenameThunk([[element.name, name]]));
        dispatch(
          saveDashboardElementUpdates({
            id: element.id,
            name,
            elementLocation: element.elemLocation ?? DASHBOARD_LAYOUT_CONFIG.DASHBOARD_BODY,
          }),
        );

        trackEvent(EVENTS.RENAMED_DASHBOARD_ELEMENT, {
          dashboard_element_id: element.id,
          name: name,
        });
      }}
      resourceName={element.name}
      textFieldPlaceholder="Dashboard element name"
    />
  );
};
