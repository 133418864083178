import { FC, useEffect } from 'react';
import cx from 'classnames';
import * as Switch from '@radix-ui/react-switch';
import { Icon, Tooltip } from 'components/ds';

import { updateIsWidgetOpen } from 'actions/chatWidgetActions';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { togglePylonWidget } from 'utils/pylon';
import { CHAT_TEXT, TOOLTIP_SIDE, TOOLTIP_SIDE_OFFSET } from './constants';

import * as styles from './styles.css';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'constants/routes';

type Props = {
  isExpanded: boolean;
  className?:
    | string
    | {
        [x: string]: boolean;
      };
};

export const ChatWidget: FC<Props> = ({ isExpanded, className }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { widget } = useSelector((state: ReduxState) => ({
    widget: state.widget,
  }));

  const handleWidgetClick = () => {
    dispatch(updateIsWidgetOpen({ isWidgetOpen: !widget.isOpen }));
  };

  // Automatically open the chat widget when navigating to the connect or sync-tables pages
  useEffect(() => {
    if (pathname === ROUTES.CONNECT_DATA_SOURCE || pathname === ROUTES.SYNC_DATA_TABLES_NO_SCHEMA) {
      dispatch(updateIsWidgetOpen({ isWidgetOpen: true }));
    }
  }, [pathname, dispatch]);

  // Toggle the chat widget when the widget.isOpen state changes
  useEffect(() => {
    if (widget.isOpen === true) {
      togglePylonWidget(true);
    } else if (widget.isOpen === false) {
      togglePylonWidget(false);
    }
  }, [widget.isOpen, dispatch]);

  const renderChatWidget = () => (
    <div className={cx(styles.hoverableItem, className)} onClick={handleWidgetClick}>
      <div className={styles.sidebarItemIconName}>
        <Icon className={styles.leftIcon} name={widget.isOpen ? 'messages' : 'messages-reg'} />
        {isExpanded ? <p className={styles.itemText}>{CHAT_TEXT}</p> : null}
      </div>
      {isExpanded ? (
        <div className={styles.rightIcon}>
          <Switch.Root checked={!!widget.isOpen} className={styles.switchRoot}>
            <Switch.Thumb className={styles.switchThumb} />
          </Switch.Root>
        </div>
      ) : null}
    </div>
  );

  return isExpanded ? (
    renderChatWidget()
  ) : (
    <Tooltip side={TOOLTIP_SIDE} sideOffset={TOOLTIP_SIDE_OFFSET} text={CHAT_TEXT}>
      {renderChatWidget()}
    </Tooltip>
  );
};
