import axios, { AxiosResponse, AxiosError } from 'axios';
import { Jobs } from 'components/JobQueue/types';
import Redux from 'redux';
import {
  handleEnqueueError,
  getDispatchActions,
  handleBulkEnqueueResponse,
} from 'utils/jobQueueUtils';

import { ActionFnArgs, createHeaders } from './actionUtils';
import { ErrorResponse, QueryDebuggingInformation } from './responseTypes';
import { ACTION } from './types';

// function types
interface PollingFnArgs extends ActionFnArgs {
  job_ids: string[];
}

interface BulkEnqueueFnArgs extends ActionFnArgs {
  jobs: Record<string, JobDefinition>;
}

type BulkEnqueueFnWithArgs = (
  args: BulkEnqueueFnArgs,
  onSuccess?: (jobs: Record<string, Jobs>) => void,
) => void;

// object definitions
export type JobDefinition = {
  job_type: ACTION;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  job_args: any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess?: (data: any) => void | (() => void);
  onError?: ((errorMessage: string) => void) | (() => void);
};

type EnqueuedJob = {
  job_intention_key?: string;
  error?: string;
};

export type JobQueueResult = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  success?: any;
  error?: string;
  query_information?: QueryDebuggingInformation;
};

// response definitions
export type BulkJobEnqueueResponse = {
  enqueued_job_map: Record<string, EnqueuedJob>;
};

type GetJobQueueResultsResponse = {
  results: Record<string, JobQueueResult>;
};

/**
 * Polls a get endpoint at a 250ms cadence to pull down results from the job queue
 */
export const fetchJobResults = (
  args: PollingFnArgs,
  onSuccess: (response: GetJobQueueResultsResponse) => void,
  onError: (errorMsg: ErrorResponse) => void,
  pollingIntervalMs: number,
) => {
  return async () => {
    // wait to let jobs finish and to not spam the backend with requests
    await new Promise((r) => setTimeout(r, pollingIntervalMs));

    return axios({
      url: process.env.REACT_APP_API_URL + 'async_jobs/get_job_queue_results/',
      method: 'POST',
      headers: createHeaders(args.customerToken, args.jwt),
      withCredentials: !isEmbeddedRequest(args),
      data: { job_ids: args.job_ids },
    })
      .then((response: AxiosResponse) => {
        const { data } = response;
        onSuccess(data);
      })
      .catch((error: AxiosError) => {
        console.error(error.response);
        onError(
          typeof error.response?.data === 'object'
            ? {
                ...(error.response.data as ErrorResponse),
                status: error.response.status,
              }
            : {
                detail: 'Unknown error',
                status: error.response?.status ?? 500,
              },
        );
      });
  };
};

/**
 * Returns an action that sends a set of JobDefinitions to be enqueued to the
 * JobQueue by the backend. It fires off the request dispatch action for each
 * of these jobs before sending to the backend
 */
export const bulkEnqueueJobs: BulkEnqueueFnWithArgs = (
  args: BulkEnqueueFnArgs,
  onSuccess?: (jobs: Record<string, Jobs>) => void,
) => {
  return (dispatch: Redux.Dispatch) => {
    const isEmbed = isEmbeddedRequest(args);

    Object.values(args.jobs).forEach((job) => {
      const { requestFn } = getDispatchActions(job.job_type, isEmbed);

      if (requestFn) dispatch(requestFn({ postData: job.job_args }));
    });

    return axios({
      url: process.env.REACT_APP_API_URL + 'async_jobs/enqueue_jobs/',
      method: 'POST',
      headers: createHeaders(args.customerToken, args.jwt),
      withCredentials: !isEmbed,
      data: args,
    })
      .then((response: AxiosResponse) => {
        const enqueuedJobs = handleBulkEnqueueResponse(response.data, args.jobs, dispatch, isEmbed);
        onSuccess?.(enqueuedJobs);
      })
      .catch((error: AxiosError) => {
        Object.values(args.jobs).forEach((job) =>
          handleEnqueueError(
            { jobType: job.job_type, jobArgs: job.job_args, onError: job.onError },
            error.message,
            isEmbed,
            dispatch,
          ),
        );
        console.error(error.response);
      });
  };
};

const isEmbeddedRequest = (args: Pick<ActionFnArgs, 'customerToken' | 'jwt'>) =>
  args.customerToken != undefined || args.jwt != undefined;
