import { isEnvironmentSecure, getEnvironment } from './environmentUtils';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const initPylon = () => {
  if (getEnvironment() === 'development' || getEnvironment() === 'staging' || isEnvironmentSecure())
    return;

  const e = window;
  const t = document;
  const n = function (...args: any[]) {
    n.e(args);
  };
  // @ts-ignore
  n.q = [];
  n.e = function (e: any) {
    n.q.push(e);
  };
  e.Pylon = n;
  const r = function () {
    const e = t.createElement('script');
    e.setAttribute('type', 'text/javascript');
    e.setAttribute('async', 'true');
    e.setAttribute(
      'src',
      `https://widget.usepylon.com/widget/${process.env.REACT_APP_PYLON_APP_ID}`,
    );
    const n = t.getElementsByTagName('script')[0];
    if (n?.parentNode) {
      n.parentNode.insertBefore(e, n);
    }
  };
  if (t.readyState === 'complete') {
    r();
  } else if (e.addEventListener) {
    e.addEventListener('load', r, false);
  }
};

export const setPylonChatSettings = (chatSettings: any) => {
  if (getEnvironment() === 'development' || getEnvironment() === 'staging' || isEnvironmentSecure())
    return;

  window.pylon = {
    chat_settings: {
      app_id: process.env.REACT_APP_PYLON_APP_ID,
      ...chatSettings,
    },
  };
};

export const togglePylonWidget = (widgetIsOpen: boolean) => {
  if (getEnvironment() === 'development' || getEnvironment() === 'staging' || isEnvironmentSecure())
    return;

  window.Pylon(widgetIsOpen ? 'show' : 'hide');
};
