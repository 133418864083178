import { createReducer } from '@reduxjs/toolkit';

import { updateIsWidgetOpen } from 'actions/chatWidgetActions';

interface ChatWidgetReducerState {
  isOpen: boolean | null;
}

export default createReducer<ChatWidgetReducerState>({ isOpen: null }, (builder) => {
  builder
    .addCase(updateIsWidgetOpen, (state, { payload }) => {
      state.isOpen = payload.isWidgetOpen;
    })
    .addDefaultCase((state) => state);
});
