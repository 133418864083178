import { Branch, CreateResourceChange, Folder, UpdateResourceChange } from '@explo-tech/fido-api';
import { createSelector } from '@reduxjs/toolkit';
import { ItemPathInfo, ItemType } from 'reducers/dataLibraryReducer';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { ROOT_FOLDER_PATH } from './constants';
import {
  getParentPath,
  getResourceById as getResourceByIdUtil,
  getResourceFromFolder,
} from './dataLibraryUtil';
import { DeleteResourceChange, FolderPath } from './types';
import { ReadAccessComputedView } from 'utils/fido/fidoShimmedTypes';

// If the current path belongs to a view, returns the path to the subfolder the view is in. Otherwise, returns the current folder's path.
export const getPathToCurrentSubFolderHelper = ({ path, type }: ItemPathInfo) => {
  if (type === ItemType.FOLDER) return path;
  const parsedPath = path.substring(0, path.lastIndexOf('/'));
  return parsedPath === '' ? '/' : parsedPath;
};

export const getUpdatedFoldersForBranch = createSelector(
  (state: ReduxState) => state.dataLibrary.updatedFolders,
  (_state: ReduxState, branch: RD.ResponseData<Branch>) => branch,
  (updatedFolders, branch): Map<FolderPath, RD.ResponseData<Folder>> => {
    if (!RD.isSuccess(branch)) {
      return new Map();
    }

    const branchId = branch.data.id ?? '';
    return updatedFolders.get(branchId) ?? new Map();
  },
);

export const getBaseFoldersForBranch = createSelector(
  (state: ReduxState) => state.dataLibrary.folders,
  (_state: ReduxState, branch: RD.ResponseData<Branch>) => branch,
  (folders, branch): Map<FolderPath, RD.ResponseData<Folder>> => {
    if (!RD.isSuccess(branch)) {
      return new Map();
    }

    const branchId = branch.data.id ?? '';
    return folders.get(branchId) ?? new Map();
  },
);

export const getCurrentFolder = createSelector(
  (state: ReduxState) => state.dataLibrary.updatedFolders,
  (state: ReduxState) => state.dataLibrary.currentItemPath,
  (state: ReduxState) => state.dataLibrary.currentBranch,
  (folders, currentItemPath, currentBranch) => {
    if (!RD.isSuccess(currentBranch)) {
      return RD.Idle();
    }

    const currentFolderPath = getPathToCurrentSubFolderHelper(currentItemPath);
    const foldersForBranch = folders.get(currentBranch.data.id ?? '');

    if (!foldersForBranch) {
      return RD.Idle();
    }

    return foldersForBranch.get(currentFolderPath);
  },
);

export const getCurrentComputedView = createSelector(
  (state: ReduxState) => state.dataLibrary.updatedFolders,
  (state: ReduxState) => state.dataLibrary.currentItemPath,
  (state: ReduxState) => state.dataLibrary.currentBranch,
  (folders, currentItemPath, currentBranch) => {
    if (!RD.isSuccess(currentBranch)) {
      return RD.Idle();
    }

    const computedViewFolderPath = getParentPath(currentItemPath.path);
    const foldersForBranch = folders.get(currentBranch.data.id ?? '');

    if (!foldersForBranch) {
      return RD.Idle();
    }

    const folderResponse = foldersForBranch.get(computedViewFolderPath);
    if (!RD.isSuccess(folderResponse)) {
      return folderResponse;
    }

    return RD.Success(
      getResourceFromFolder(folderResponse.data, currentItemPath.path) as ReadAccessComputedView,
    );
  },
);

export const getResourceById = createSelector(
  (state: ReduxState) => state.dataLibrary.folders,
  (state: ReduxState) => state.dataLibrary.updatedFolders,
  (state: ReduxState) => state.dataLibrary.currentBranch,
  (_state: ReduxState, isBase: boolean) => isBase,
  (_state: ReduxState, _isBase: boolean, resourceId: string) => resourceId,
  (folders, updatedFolders, currentBranch, isBase, resourceId) => {
    if (!RD.isSuccess(currentBranch)) {
      return undefined;
    }

    const foldersForBranch = folders.get(currentBranch.data.id ?? '');
    const updatedFoldersForBranch = updatedFolders.get(currentBranch.data.id ?? '');

    if (!foldersForBranch || !updatedFoldersForBranch) {
      return undefined;
    }

    return getResourceByIdUtil(resourceId, isBase ? foldersForBranch : updatedFoldersForBranch);
  },
);

export const getRootFolderForBranch = createSelector(
  (state: ReduxState) => state.dataLibrary.updatedFolders,
  (_state: ReduxState, branch: RD.ResponseData<Branch>) => branch,
  (folders, branch) => {
    if (!RD.isSuccess(branch)) {
      return RD.Idle();
    }

    const foldersForBranch = folders.get(branch.data.id ?? '');

    return foldersForBranch?.get(ROOT_FOLDER_PATH) ?? RD.Idle();
  },
);

export const getBaseRootFolderForBranch = createSelector(
  (state: ReduxState) => state.dataLibrary.folders,
  (_state: ReduxState, branch: RD.ResponseData<Branch>) => branch,
  (folders, branch) => {
    if (!RD.isSuccess(branch)) {
      return RD.Idle();
    }

    const foldersForBranch = folders.get(branch.data.id ?? '');

    return foldersForBranch?.get(ROOT_FOLDER_PATH) ?? RD.Idle();
  },
);

export const getBaseRootFolder = createSelector(
  (state: ReduxState) => state.dataLibrary.folders,
  (state: ReduxState) => state.dataLibrary.currentBranch,
  (folders, currentBranch) => {
    if (!RD.isSuccess(currentBranch)) {
      return RD.Idle();
    }

    const foldersForBranch = folders.get(currentBranch.data.id ?? '');

    return foldersForBranch?.get(ROOT_FOLDER_PATH) ?? RD.Idle();
  },
);

export const getCurrentResource = createSelector(
  (state: ReduxState) => state.dataLibrary.currentItemPath,
  (state: ReduxState) => state.dataLibrary.updatedFolders,
  (state: ReduxState) => state.dataLibrary.currentBranch,
  (currentItemPath, folders, currentBranch) => {
    if (!RD.isSuccess(currentBranch)) {
      return RD.Idle();
    }

    const foldersForBranch = folders.get(currentBranch.data.id ?? '');
    if (!foldersForBranch) {
      return RD.Idle();
    }

    const resourceParentPath = getParentPath(currentItemPath.path);
    const parentFolderResponse = foldersForBranch.get(resourceParentPath);

    if (!RD.isSuccess(parentFolderResponse)) {
      return parentFolderResponse ?? RD.Idle();
    }

    return RD.Success(getResourceFromFolder(parentFolderResponse.data, currentItemPath.path));
  },
);

export const getSubFolderUniqueNames = createSelector(
  (state: ReduxState) => getCurrentFolder(state),
  (folder) => {
    if (!RD.isSuccess(folder)) return new Set<string>();
    const folderItems = folder.data?.children ?? [];
    return new Set(folderItems.map((item) => item.name));
  },
);

export const getAllPendingCreations = createSelector(
  (state: ReduxState) => state.dataLibrary.pendingResourceCreations,
  (state: ReduxState) => getUnappliedPendingCreations(state),
  (pendingResourceCreations, unappliedPendingCreations) => {
    return [
      ...pendingResourceCreations.values(),
      ...unappliedPendingCreations,
    ] as CreateResourceChange[];
  },
);

const getUnappliedPendingCreations = createSelector(
  (state: ReduxState) => state.dataLibrary.unappliedPendingChanges,
  (unappliedPendingChanges) => {
    return Array.from(unappliedPendingChanges.values()).filter(
      (change) => change['@type'] === 'create',
    ) as CreateResourceChange[];
  },
);

export const getUnappliedPendingCreationIds = createSelector(
  (state: ReduxState) => getUnappliedPendingCreations(state),
  (unappliedPendingCreations) => {
    return new Set(unappliedPendingCreations.map((change) => change.resource.id));
  },
);

export const getAllPendingDeletions = createSelector(
  (state: ReduxState) => state.dataLibrary.pendingResourceDeletions,
  (state: ReduxState) => state.dataLibrary.unappliedPendingChanges,
  (pendingResourceDeletions, unappliedPendingChanges) => {
    const unappliedPendingDeletions = Array.from(unappliedPendingChanges.values()).filter(
      (change) => change['@type'] === 'delete',
    );
    return [
      ...pendingResourceDeletions.values(),
      ...unappliedPendingDeletions,
    ] as DeleteResourceChange[];
  },
);

export const getAllPendingUpdates = createSelector(
  (state: ReduxState) => state.dataLibrary.pendingResourceUpdates,
  (state: ReduxState) => state.dataLibrary.unappliedPendingChanges,
  (pendingResourceUpdates, unappliedPendingChanges) => {
    const unappliedPendingUpdates = Array.from(unappliedPendingChanges.values()).filter(
      (change) => change['@type'] === 'update',
    );
    return [
      ...pendingResourceUpdates.values(),
      ...unappliedPendingUpdates,
    ] as UpdateResourceChange[];
  },
);

export const getAllModifiedResourceIds = createSelector(
  (state: ReduxState) => getAllPendingCreations(state),
  (state: ReduxState) => getAllPendingUpdates(state),
  (state: ReduxState) => getAllPendingDeletions(state),
  (pendingResourceCreations, pendingResourceUpdates, pendingResourceDeletions) => {
    const modifiedResourceIds = new Set<string>();
    pendingResourceCreations.forEach((change) => modifiedResourceIds.add(change.resource.id ?? ''));
    pendingResourceUpdates.forEach((change) => modifiedResourceIds.add(change.resource.id ?? ''));
    pendingResourceDeletions.forEach((change) => modifiedResourceIds.add(change.id));
    return modifiedResourceIds;
  },
);
