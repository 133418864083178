import { usePrevious } from '@radix-ui/react-use-previous';
import { FC, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { AlertModal, Button, Icon, IconButton, Tabs, Tooltip, sprinkles } from 'components/ds';
import { TabIconProps, TabOption } from 'components/ds/Tabs';
import { SchemaViewer } from 'components/resource/SchemaViewer';
import { PANEL_WIDTH } from 'constants/datasetMetadataPanelConstants';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { DashboardParam } from 'types/dashboardVersionConfig';
import { AIGoal, getHandleAIClickFn } from 'utils/aiUtils';
import { DashboardVariablesPanel } from '../EditDashboardLeftPanel/VariablesPanel/DashboardVariablesPanel';

import { FormattedQueryText } from '../DashboardDebugger/FormattedQueryText';
import { GlobalDatasetsVariablesPanel } from '../EditDashboardLeftPanel/VariablesPanel/GlobalDatasetsVariablesPanel';

type Props = {
  selectedDatasetId: number;
  query: string;
  variables: DashboardVariableMap;
  createNewParam: () => DashboardParam;
  error?: string;
  onSelectSchema: (schemaId: number) => void;
  getTabIconProps?: (tabId: METADATA_VIEWS) => TabIconProps | undefined;
};

export enum METADATA_VIEWS {
  SCHEMA = 'Schema',
  VARIABLES = 'Variables',
  ASK_AI = 'Ask AI',
}

type AskAIPanelProps = {
  query: string;
  error?: string;
};

const AskAIPanel: FC<AskAIPanelProps> = ({ query, error }) => {
  const dispatch = useDispatch();
  const { suggestion, team, customerName, enableAi } = useSelector(
    (state: ReduxState) => ({
      suggestion: state.ai.suggestion,
      team: state.teamData.data,
      customerName: state.currentUser.first_name,
      enableAi: state.currentUser.team?.entitlements.enable_ai,
    }),
    shallowEqual,
  );

  const handleClick = getHandleAIClickFn({ dispatch, team, customerName, query, error });

  const handleRequestAccess = () => {
    window.open('https://calendly.com/bookwithnav/enable-explo-ai', '_blank');
  };

  const renderAIButton = (buttonText: string, disabled?: boolean) => (
    <Button
      fillWidth
      className={sprinkles({ pointerEvents: 'none' })}
      disabled={disabled}
      icon="wand"
      variant="ai">
      {buttonText}
    </Button>
  );

  if (!enableAi)
    return (
      <div className={paywallContainerClass}>
        <div className={paywallBlurredButtonsClass} style={{ filter: 'blur(16px)' }}>
          <div className={paywallOverlay} />

          {renderAIButton('Optimize Query')}
          {renderAIButton('Explain Query')}
          {renderAIButton('Debug Query', true)}
        </div>

        <div className={sprinkles({ flexItems: 'centerColumn', gap: 'sp2', textAlign: 'center' })}>
          <Icon className={sprinkles({ color: 'violet9' })} name="wand" size="lg" />

          <span className={sprinkles({ color: 'contentPrimary', heading: 'h3' })}>
            Introducing Explo AI
          </span>

          <span className={sprinkles({ color: 'contentSecondary', paddingX: 'sp6', body: 'b2' })}>
            Use AI to help you with your SQL. Reach out to Explo support or schedule time below.
          </span>

          <Button onClick={handleRequestAccess} variant="aiTertiary">
            Request Access
          </Button>
        </div>
      </div>
    );

  return (
    <div className={rootClass}>
      <div className={buttonContainerClass}>
        <Button fillWidth icon="wand" onClick={() => handleClick(AIGoal.OPTIMIZE)} variant="ai">
          Optimize Query
        </Button>
        <Button fillWidth icon="wand" onClick={() => handleClick(AIGoal.EXPLAIN)} variant="ai">
          Explain Query
        </Button>
        <Button
          fillWidth
          disabled={!error}
          icon="wand"
          onClick={() => handleClick(AIGoal.DEBUG)}
          variant="ai">
          Debug Query
        </Button>
      </div>

      {RD.isIdle(suggestion) || RD.isLoading(suggestion) ? (
        <div className={loadingContainerClass}>
          {RD.isIdle(suggestion) ? (
            <span className={aiRecommendationsClass}>AI recommendations will show here.</span>
          ) : (
            <div className={loadingTextContainerClass}>
              <Icon
                bounce
                className={sprinkles({ color: 'black', marginRight: 'sp1' })}
                name="sparkles"
                size="sm"
              />

              <span className={loadingTextClass}>Loading</span>
            </div>
          )}
        </div>
      ) : RD.isSuccess(suggestion) ? (
        <div className={explanationContainerClass}>
          <FormattedQueryText explanation={suggestion.data.explanation} />
        </div>
      ) : null}
    </div>
  );
};

export const DatasetMetadataPanel: FC<Props> = ({
  query,
  error,
  variables,
  createNewParam,
  selectedDatasetId,
  onSelectSchema,
  getTabIconProps,
}) => {
  const [metadataView, setMetadataView] = useState(METADATA_VIEWS.SCHEMA);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [swapSchemaId, setSwapSchemaId] = useState(selectedDatasetId);
  const [isExpanded, setIsExpanded] = useState(true);

  const { suggestion, isDashboard } = useSelector(
    (state: ReduxState) => ({
      suggestion: state.ai.suggestion,
      isDashboard: state.dashboardEditConfig.currentDashboardId !== null,
    }),
    shallowEqual,
  );

  const suggestionLoading = RD.isLoading(suggestion);
  const prevSuggestionLoading = usePrevious(suggestionLoading);

  // Move tab to AI if we see that suggestion has moved from idle to something else
  useEffect(() => {
    if (suggestionLoading && !prevSuggestionLoading) {
      setMetadataView(METADATA_VIEWS.ASK_AI);
    }
  }, [suggestionLoading, prevSuggestionLoading]);

  const metadataViewTabs: TabOption[] = useMemo(() => {
    const tabIconProps: Record<METADATA_VIEWS, TabIconProps | undefined> = {
      [METADATA_VIEWS.SCHEMA]: getTabIconProps?.(METADATA_VIEWS.SCHEMA),
      [METADATA_VIEWS.VARIABLES]: getTabIconProps?.(METADATA_VIEWS.VARIABLES),
      [METADATA_VIEWS.ASK_AI]: getTabIconProps?.(METADATA_VIEWS.ASK_AI),
    };
    const metadataViewTabs: TabOption[] = [
      { id: METADATA_VIEWS.SCHEMA, label: 'Schema', ...tabIconProps[METADATA_VIEWS.SCHEMA] },
      {
        id: METADATA_VIEWS.VARIABLES,
        label: 'Variables',
        ...tabIconProps[METADATA_VIEWS.VARIABLES],
      },
      {
        id: METADATA_VIEWS.ASK_AI,
        label: 'Ask AI',
        icon: 'wand',
        ...tabIconProps[METADATA_VIEWS.ASK_AI],
      },
    ];

    return metadataViewTabs;
  }, [getTabIconProps]);

  return isExpanded ? (
    <div className={baseContainerClass} style={{ minWidth: PANEL_WIDTH, maxWidth: PANEL_WIDTH }}>
      <div className={sprinkles({ display: 'flex', flexDirection: 'row', alignItems: 'center' })}>
        <Tooltip text="Collapse panel">
          <IconButton
            className={sprinkles({ height: 'fill' })}
            name="square-chevron-right"
            onClick={() => {
              setIsExpanded(false);
            }}
          />
        </Tooltip>
        <Tabs
          className={sprinkles({ flex: 1 })}
          onTabSelect={(tabId) => setMetadataView(tabId as METADATA_VIEWS)}
          selectedTabId={metadataView}
          tabs={metadataViewTabs}
        />
      </div>
      {metadataView === METADATA_VIEWS.SCHEMA ? (
        <SchemaViewer
          onSelectSchema={(newSchemaId) => {
            setSwapSchemaId(newSchemaId);
            setIsModalOpen(true);
          }}
          selectedDatasetSchemaId={selectedDatasetId}
        />
      ) : metadataView === METADATA_VIEWS.ASK_AI ? (
        <AskAIPanel error={error} query={query} />
      ) : metadataView === METADATA_VIEWS.VARIABLES ? (
        isDashboard ? (
          <DashboardVariablesPanel readOnly createNewParam={createNewParam} variables={variables} />
        ) : (
          <GlobalDatasetsVariablesPanel variables={variables} />
        )
      ) : (
        <div className={sprinkles({ flexItems: 'alignCenter', body: 'b1' })}>
          No selected dashboard.
        </div>
      )}
      <AlertModal
        actionButtonProps={{
          onClick: () => {
            onSelectSchema(swapSchemaId);
            setIsModalOpen(false);
          },
          text: 'Save',
          variant: 'primary',
        }}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Are you sure you want to change the schema?">
        If you have created charts using this dataset and you change the schema, your charts will no
        longer load the correct data.
      </AlertModal>
    </div>
  ) : (
    <div className={baseContainerClass}>
      <Tooltip text="Open schema tab">
        <IconButton
          className={sprinkles({ marginY: 'sp2' })}
          name="square-chevron-left"
          onClick={() => {
            setIsExpanded(true);
            setMetadataView(METADATA_VIEWS.SCHEMA);
          }}
        />
      </Tooltip>
      <Tooltip text="Open variables tab">
        <IconButton
          className={sprinkles({ marginY: 'sp2' })}
          name="square-root"
          onClick={() => {
            setIsExpanded(true);
            setMetadataView(METADATA_VIEWS.VARIABLES);
          }}
        />
      </Tooltip>
      <Tooltip text="Ask AI">
        <IconButton
          className={sprinkles({ marginY: 'sp2' })}
          name="wand"
          onClick={() => {
            setIsExpanded(true);
            setMetadataView(METADATA_VIEWS.ASK_AI);
          }}
        />
      </Tooltip>
    </div>
  );
};

const baseContainerClass = sprinkles({
  flexItems: 'column',
  border: 1,
  borderColor: 'outline',
  borderRadius: 8,
  height: 'fill',
});

const aiRecommendationsClass = sprinkles({
  color: 'contentSecondary',
  maxWidth: 'half',
  textAlign: 'center',
});

const rootClass = sprinkles({
  flexItems: 'column',
  height: 'fill',
  overflow: 'auto',
});

const buttonContainerClass = sprinkles({ flexItems: 'column', padding: 'sp2', gap: 'sp1' });

const loadingContainerClass = sprinkles({
  flexItems: 'center',
  backgroundColor: 'violet3',
  padding: 'sp2',
  flex: 1,
});

const loadingTextContainerClass = sprinkles({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const loadingTextClass = sprinkles({
  color: 'contentPrimary',
  maxWidth: 'half',
  textAlign: 'center',
});

const explanationContainerClass = sprinkles({
  flexItems: 'column',
  alignItems: 'flex-start',
  height: 'fill',
  backgroundColor: 'violet3',
  padding: 'sp2',
  overflow: 'auto',
});

const paywallContainerClass = sprinkles({
  flexItems: 'column',
  alignItems: 'center',
  gap: 'sp1.5',
  parentContainer: 'fill',
  overflow: 'auto',
});

const paywallBlurredButtonsClass = sprinkles({
  position: 'relative',
  flexItems: 'column',
  paddingTop: 'sp4',
  paddingX: 'sp2',
  gap: 'sp1.5',
  width: 'fill',
});

const paywallOverlay = sprinkles({
  position: 'absolute',
  parentContainer: 'fill',
  backgroundColor: 'white',
  opacity: 0.5,
});
