import { Parameter } from '@explo-tech/fido-api';
import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useImmer } from 'use-immer';

import { Modal, Select, sprinkles } from 'components/ds';
import { dataTypeOptions } from './constants';

import { updateDatasetParameter } from 'reducers/dataLibraryReducer';
import { ComputedViewWithIds } from 'utils/fido/fidoShimmedTypes';
import {
  getEmbeddoTypeFromFidoTypeForVariableMapping,
  getFidoTypeFromEmbeddoType,
} from 'utils/fido/fidoShims';
import { isEqual } from 'utils/standard';

type Props = {
  param: Parameter;
  selectedView: ComputedViewWithIds;

  onClose: () => void;
};

export const ManageQueryParameterModal: FC<Props> = ({ param, onClose, selectedView }) => {
  const dispatch = useDispatch();

  const [newParam, setNewParam] = useImmer<Parameter>(param);

  const handleUpdateParam = useCallback(
    (updatedParameter: Parameter) => {
      dispatch(updateDatasetParameter({ datasetId: selectedView.id, parameter: updatedParameter }));
    },
    [dispatch, selectedView.id],
  );

  return (
    <Modal
      isOpen
      onClose={onClose}
      primaryButtonProps={{
        text: 'Update',
        onClick: () => {
          if (!isEqual(param, newParam)) {
            handleUpdateParam(newParam);
          }
          onClose();
        },
        disabled: !newParam.type,
      }}
      size="small"
      tertiaryButtonProps={{ text: 'Cancel', onClick: onClose }}
      title={`Set up ${param.name} query parameter`}>
      <div className={sprinkles({ paddingX: 'sp2', flexItems: 'column', gap: 'sp2' })}>
        <div className={sprinkles({ flexItems: 'column', gap: 'sp1.5' })}>
          <Select
            label="Data Type"
            onChange={(newType) =>
              setNewParam((draft) => {
                draft.type = getFidoTypeFromEmbeddoType(newType);
              })
            }
            selectedValue={
              newParam.type
                ? getEmbeddoTypeFromFidoTypeForVariableMapping(newParam.type)
                : undefined
            }
            values={dataTypeOptions}
          />
        </div>
      </div>
    </Modal>
  );
};
