import { createAsyncThunk } from '@reduxjs/toolkit';
// @ts-ignore
import JSURL from 'jsurl';

import {
  DownloadDataPanelBody,
  DownloadDataPanelSpreadsheetBody,
  ExportScreenshotType,
  ExportSpreadsheetType,
} from 'actions/exportActions';
import { JobDefinition } from 'actions/jobQueueActions';
import { ACTION } from 'actions/types';
import { ReportedAnalyticActionTypes } from 'constants/reportedAnalyticActionTypes';
import { UserTransformedSchema } from 'constants/types';
import { DashboardStates, ReduxState } from 'reducers/rootReducer';
import { getArchetypeProperties } from 'reducers/selectors';
import { exportDataPanelScreenshotThunk } from 'reducers/thunks/roverThunks';
import { sendAnalyticsEventThunk } from 'telemetry/telemetryThunks';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { AdHocOperationInstructions } from 'types/dataPanelTemplate';
import { DataPanel } from 'types/exploResource';
import { getPrimarySortInfo } from 'utils/adHocUtils';
import { getTransformedDataPanelForCsv } from 'utils/csvDownloadUtils';
import { getSanitizedDashboardVars } from 'utils/dashboardUtils';
import { getViewableSchemaForPdf } from 'utils/dataPanelConfigUtils';
import { getDatasetNamesToId } from 'utils/datasetUtils';
import { shouldDataPanelUseFidoForRequest } from 'utils/fido/fidoRequestUtils';
import {
  attachDatasetToPostData,
  createApiRequestConfigWithRequestInfo,
  makeThunkRequest,
} from 'utils/thunkUtils';
import { getDataPanelQueryContext } from 'utils/variableUtils';

import { downloadExploreComputationSpreadsheet } from '../dashboardDataThunks/fetchFidoDataThunks';

import { Dataset } from 'actions/datasetActions';
import { convertViewToDataset } from 'pages/dashboardPage/dashboardDatasetEditor/utils';
import { ReadAccessComputedView } from 'utils/fido/fidoShimmedTypes';
import { enqueueDashboardJobsThunk } from './jobsThunks';
import { DashboardLayoutThunk } from './types';
import { getDashboardConfig } from 'reducers/thunks/dashboardDataThunks/utils';

export const downloadDataPanelScreenshotThunk =
  (
    dataPanel: DataPanel,
    variables: DashboardVariableMap,
    adHocOperationInstructions: AdHocOperationInstructions,
    userTransformedSchema: UserTransformedSchema | undefined,
    exportType: ExportScreenshotType,
    email?: string,
    reportName?: string,
  ): DashboardLayoutThunk =>
  (dispatch, getState) => {
    const state = getState();
    const { dashboardLayout, dashboardStyles, dashboardData } = state;
    const requestInfo = dashboardLayout.requestInfo;
    const dashboardTheme = dashboardStyles.dashboardTheme;
    const dataPanelData = dashboardData.dataPanelData[dataPanel.id];
    const archetypeProperties = getArchetypeProperties(state);

    const downloadFileName =
      dataPanel.visualize_op.generalFormatOptions?.export?.downloadFileName ||
      dataPanel.provided_id;

    const viewableSchema = getViewableSchemaForPdf(
      dataPanel,
      dataPanelData?.schema,
      userTransformedSchema,
    );

    const sanitizedVariables = getSanitizedDashboardVars(
      {
        ...variables,
        reportName,
        userTransformedSchema: JSURL.stringify(viewableSchema),
        adHocOps: JSURL.stringify(adHocOperationInstructions),
        theme: dashboardTheme ?? undefined,
      },
      archetypeProperties,
    );
    const jobArgs: DownloadDataPanelBody = {
      data_panel_template_id: dataPanel.id,
      download_file_name: downloadFileName,
      version_number: requestInfo.versionNumber,
      variables: sanitizedVariables,
      export_type: exportType,
      email,
    };

    dispatch(sendAnalyticsEventThunk(ReportedAnalyticActionTypes.DATA_PANEL_PDF_DOWNLOADED));

    if (requestInfo.useFido && requestInfo.useRover && requestInfo.useRoverForScreenshots) {
      dispatch(
        exportDataPanelScreenshotThunk({
          data_panel_template_id: dataPanel.id,
          download_file_name: downloadFileName,
          export_type: exportType,
          email,
          variables: sanitizedVariables,
        }),
      );
    }
    // If email is passed in, we know we want to enqueue email job in backend so frontend does not have to keep track
    else if (requestInfo.useJobQueue && email === undefined) {
      const job: JobDefinition = {
        job_type: ACTION.DOWNLOAD_DATA_PANEL_SCREENSHOT,
        job_args: jobArgs,
      };
      dispatch(enqueueDashboardJobsThunk({ jobs: [job] }));
    } else {
      dispatch(downloadDataPanelScreenshot(jobArgs));
    }
  };

export const downloadDataPanelScreenshot = createAsyncThunk<
  { url?: string },
  DownloadDataPanelBody,
  { state: DashboardStates }
>(ACTION.DOWNLOAD_DATA_PANEL_SCREENSHOT, async (args, { getState }) => {
  const requestInfo = getState().dashboardLayout.requestInfo;

  const urls = {
    embedUrl: `embed/export/data_panel_template/${args.export_type}/`,
    appUrl: `export/data_panel_template/${args.export_type}/`,
  };

  const requestConfig = createApiRequestConfigWithRequestInfo(urls, 'POST', requestInfo, args);

  return makeThunkRequest(requestConfig, 'Error downloading data panel');
});

export const downloadDataPanelSpreadsheetThunk =
  (
    dataPanel: DataPanel,
    variables: DashboardVariableMap,
    datasets: Record<string, Dataset>,
    fileFormat: ExportSpreadsheetType,
    userTransformedSchema: UserTransformedSchema | undefined,
    email: string | undefined,
    isRawData: boolean,
    backingGlobalDataset: ReadAccessComputedView | null,
  ): DashboardLayoutThunk =>
  (dispatch, getState) => {
    const { dashboardLayout, dashboardData, fido, drilldowns } = getState();

    const config = getDashboardConfig(getState() as ReduxState);

    if (!config) return;

    const requestInfo = dashboardLayout.requestInfo;
    const dataPanelData = dashboardData.dataPanelData[dataPanel.id];
    if (!dataPanelData) return;

    const datasetNameToIdMap = getDatasetNamesToId(
      datasets,
      // TODO(zifanxiang): Add the name to the global dataset reference
      {},
    );
    const drilldownDatasetFilters = drilldowns.drilldownDatasetFilters;
    const transformedDataPanel = getTransformedDataPanelForCsv(
      dataPanel,
      dataPanelData.schema,
      userTransformedSchema,
      variables,
      dashboardData.datasetData,
      datasetNameToIdMap,
      requestInfo.timezone,
      drilldownDatasetFilters,
      datasets,
    );

    const dataPanelVariables = getDataPanelQueryContext(
      dataPanel,
      variables,
      config.variableMappings[dataPanel.table_id],
    );

    const fileNameForExport = getFileNameForExport(isRawData, dataPanel);
    const jobArgs: DownloadDataPanelSpreadsheetBody = {
      config: transformedDataPanel,
      id: transformedDataPanel.id,
      variables: dataPanelVariables,
      sort_info: getPrimarySortInfo(dataPanelData.adHocOperationInstructions?.sortInfo),
      filter_info: dataPanelData.adHocOperationInstructions?.filterInfo,
      file_format: fileFormat,
      email,
      ...attachDatasetToPostData(transformedDataPanel, datasets, requestInfo),
      export_file_name: fileNameForExport,
    };

    dispatch(sendAnalyticsEventThunk(ReportedAnalyticActionTypes.CSV_DOWNLOADED));

    if (
      shouldDataPanelUseFidoForRequest(
        requestInfo,
        fido,
        datasets[transformedDataPanel.table_id],
        transformedDataPanel,
      )
    ) {
      const parentSchemas = fido.embeddoDaos.usedParentSchemas ?? [];
      const backingDataset = backingGlobalDataset
        ? convertViewToDataset(
            {
              ...backingGlobalDataset,
              id: backingGlobalDataset.id ?? '',
              namespaceId: backingGlobalDataset.namespaceId ?? '',
            },
            parentSchemas,
          )
        : datasets[transformedDataPanel.table_id];
      dispatch(
        downloadExploreComputationSpreadsheet({
          dataPanel: transformedDataPanel,
          dataset: backingDataset,
          adHocInstructions: dataPanelData.adHocOperationInstructions,
          fileFormat,
          fileNameForExport,
          variables: dataPanelVariables,
          emails: email == null ? null : [email],
        }),
      );
    } else if (requestInfo.useJobQueue && email === undefined) {
      // If email is passed in, we know we want to enqueue email job in backend so frontend does not have to keep track
      const job: JobDefinition = {
        job_type: ACTION.DOWNLOAD_DATA_PANEL_SPREADSHEET,
        job_args: jobArgs,
      };
      dispatch(enqueueDashboardJobsThunk({ jobs: [job] }));
    } else {
      dispatch(downloadDataPanelSpreadsheet(jobArgs));
    }
  };

export const downloadDataPanelSpreadsheet = createAsyncThunk<
  { url?: string },
  DownloadDataPanelSpreadsheetBody,
  { state: DashboardStates }
>(ACTION.DOWNLOAD_DATA_PANEL_SPREADSHEET, async (args, { getState }) => {
  const requestInfo = getState().dashboardLayout.requestInfo;

  const urls = {
    embedUrl: `embed/download_spreadsheet/`,
    appUrl: `data_panel_templates/download_spreadsheet/`,
  };

  const requestConfig = createApiRequestConfigWithRequestInfo(urls, 'POST', requestInfo, args);

  return makeThunkRequest(requestConfig, 'Error downloading data panel');
});

function getFileNameForExport(isRawData: boolean, dataPanel: DataPanel): string {
  const exportOptions = dataPanel.visualize_op.generalFormatOptions?.export;
  return (
    (isRawData ? exportOptions?.rawDataDownloadFileName : exportOptions?.downloadFileName) ||
    dataPanel.provided_id ||
    DEFAULT_DOWNLOAD_FILE_NAME
  );
}

const DEFAULT_DOWNLOAD_FILE_NAME = 'download';
