/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListViewResourceUsagesResponse } from '../models/ListViewResourceUsagesResponse';
import type { ViewResourceUsageParams } from '../models/ViewResourceUsageParams';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ResourcesService {
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static resourcesCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/resources/',
        });
    }
    /**
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static resourcesDestroy(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/resources/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @returns any No response body
     * @throws ApiError
     */
    public static resourcesRenameUpdate(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/resources/{id}/rename/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param queryParams
     * @returns ListViewResourceUsagesResponse
     * @throws ApiError
     */
    public static getViewResourceUsages(
        queryParams: ViewResourceUsageParams,
    ): CancelablePromise<ListViewResourceUsagesResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/resources/get_view_resource_usages/',
            query: {
                'query_params': queryParams,
            },
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static resourcesListDashboardFolderContentsRetrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/resources/list_dashboard_folder_contents/',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static resourcesListFolderContentNamesRetrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/resources/list_folder_content_names/',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static resourcesListFolderHierarchyRetrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/resources/list_folder_hierarchy/',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static resourcesListReportFolderContentsRetrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/resources/list_report_folder_contents/',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static resourcesMoveUpdate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/resources/move/',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static resourcesSearchRetrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/resources/search/',
        });
    }
}
