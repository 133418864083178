import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as RD from 'remotedata';

import { IconName, SelectItems } from 'components/ds';
import { SelectorSection } from '../SelectorSection';

import { fetchGroupTags } from 'actions/customerActions';
import { ReduxState } from 'reducers/rootReducer';
import { SelectProps } from '../types';

export const GroupTagSelect: FC<SelectProps & { disabled?: boolean }> = ({
  disabled,
  selected,
  onUpdate,
}) => {
  const dispatch = useDispatch();
  const [newGroupTagId, setNewGroupTagId] = useState<number | null>(null);
  const groupTags = useSelector((state: ReduxState) => state.customers.groupTags);

  useEffect(() => {
    if (RD.isIdle(groupTags)) dispatch(fetchGroupTags());
  }, [groupTags, dispatch]);

  const groupOptions: SelectItems<string> = RD.isSuccess(groupTags)
    ? groupTags.data
        .filter((tag) => !selected.groupTagIds.includes(tag.id))
        .map((tag) => ({
          value: tag.id.toString(),
          label: tag.name,
          icon: 'users',
        }))
    : [];

  const selectedGroupTag = groupOptions.find((opt) => opt.value === newGroupTagId?.toString());

  const tags = selected.groupTagIds.map((tagId) => ({
    key: String(tagId),
    label: RD.getOrDefault(groupTags, []).find((tag) => tag.id === tagId)?.name || `Group ${tagId}`,
    onClose: () => {
      onUpdate({
        ...selected,
        groupTagIds: selected.groupTagIds.filter((gId) => gId !== tagId),
      });
    },
    leftIcon: 'users' as IconName,
  }));

  return (
    <SelectorSection
      addDisabled={!newGroupTagId || disabled}
      disabled={!RD.isSuccess(groupTags) || disabled}
      label="Group Tags"
      onAdd={() => {
        if (!newGroupTagId) return;
        onUpdate({
          ...selected,
          groupTagIds: [...selected.groupTagIds, newGroupTagId],
        });
        setNewGroupTagId(null);
      }}
      onSelect={(id) => setNewGroupTagId(parseInt(id))}
      placeholder="Select group tag"
      selectedValue={selectedGroupTag?.value}
      tags={tags}
      values={groupOptions}
    />
  );
};
