import {
  DataSourceResponse,
  OpenAPI as FidoOpenAPI,
  ListResourcesResponse,
  NamespaceResponse,
} from '@explo-tech/fido-api';
import { OpenAPI as RoverOpenAPI } from '@explo/rover-api';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { BaseCol, DatasetRow } from '@explo/data';

import { listCustomerReportsSuccess } from 'actions/customerReportActions';
import { fetchDashboardSuccess } from 'actions/dashboardActions';
import {
  DataSource as EmbeddoDataSource,
  ParentSchema,
  connectDataSourceSuccess,
  editDataSourceSuccess,
  listTeamDataSourcesActions,
} from 'actions/dataSourceActions';
import { embedFetchDashboardActions } from 'actions/embedActions';
import {
  fetchAllSchemaTablesActions,
  fetchUsedParentSchemasActions,
} from 'actions/parentSchemaActions';
import { logInUserSuccess } from 'actions/userActions';
import { FIDO_TYPE_KEY } from 'pages/ConnectDataSourceFlow/constants';
import * as RD from 'remotedata';
import { parseDataSourceFromFido } from 'utils/fido/dataSources/fidoDataSourceShims';
import {
  ComputedViewWithIds,
  ReadAccessTableView,
  ReadAccessComputedView,
} from 'utils/fido/fidoShimmedTypes';
import { FidoReadAccessTableView } from 'utils/fido/fidoShimmedTypes';
import {
  getEmbeddoResponseFromFidoResponse,
  parseFidoTableViews,
  parseListNamespacesWithMetaResponse,
  parseSchema,
} from 'utils/fido/fidoShims';
import { keyBy } from 'utils/standard';

import { SchemaTablesMap } from './parentSchemaReducer';
import {
  createDataSourceInFido,
  createEmbeddoDataSource,
  createNamespace,
  updateDataSourceInFido,
} from './thunks/connectDataSourceThunks';
import { fetchFidoTablePreview } from './thunks/dashboardDataThunks/fetchFidoDataThunks';
import {
  cloneComputedViews,
  createComputedView,
  deleteDataSourceInFido,
  deleteNamespace,
  getComputedViews,
  getNamespaces,
  saveComputedView,
  updateNamespace,
} from './thunks/fidoThunks';
import { fetchAllTables, getTableViews, syncTableViews } from './thunks/syncSchemaFlowThunks';
import {
  batchGetLatestVersionedViews,
  batchGetVersionedViews,
  ViewDeletedResponse,
} from './thunks/fidoThunks/viewThunks';
import {
  addGlobalDatasetReference,
  deleteGlobalDatasetReference,
  updateReferencedGlobalDatasetVersion,
} from 'actions/datasetActions';
import { getResourceHistoryThunk } from './thunks/fidoThunks/resourceThunks';

export type NamespaceTableViewMap = Record<string, Record<string, FidoReadAccessTableView>>;

export type FidoDaos = {
  namespaces: ParentSchema[];
  dataSources: EmbeddoDataSource[];
  tables: FidoReadAccessTableView[];
  schemaTablesMap: NamespaceTableViewMap;
};

type FidoTableViewData = {
  schema: BaseCol[];
  totalResults: number | null;
  rows: DatasetRow[];
};

export interface FidoReducerState {
  // computed views pulled directly from FIDO
  computedViews: RD.ResponseData<ComputedViewWithIds[]>;

  fidoToken: string | null;
  roverTabularExportToken: string | null;
  // fido namespaces and data sources after shimming. Also contains exclusively non fido namespaces and data sources.
  fidoDaos: RD.ResponseData<FidoDaos>;
  embeddoDaos: {
    usedParentSchemas?: ParentSchema[];
    dataSources?: EmbeddoDataSource[];
    schemaTablesMap?: SchemaTablesMap;
  };
  createNamespaceResponse: RD.ResponseData<NamespaceResponse>;
  createDataSourceResponse: RD.ResponseData<DataSourceResponse>;
  updateDataSourceResponse: RD.ResponseData<DataSourceResponse>;
  dataSourceMapping: Record<string, string>;
  // TODO(tarastentz): Remove this when we don't need to shift individual data sources back to job queue
  dataSourcePreferredExecutionMapping: Record<string, string>;
  tableView: RD.ResponseData<boolean>;
  tableViewData?: FidoTableViewData;
  allTables: RD.ResponseData<string[]>;
  syncTableViewsLoading: boolean;
  // The fetched backing global datasets for the current resource (dashboard or report builder).
  referencedGlobalDatasets: RD.ResponseData<Record<string, ReadAccessComputedView>>;
  // The latest versions of the backing global datasets for the current resource (dashboard or report builder).
  latestReferencedGlobalDatasets: RD.ResponseData<Record<string, ReadAccessComputedView>>;
  // The ids of the backing global datasets that have been deleted from the latest versions.
  deletedLatestReferencedGlobalDatasetIds: Set<string>;
  // TODO (haz): support pagination for orderedComputedViewVersions
  orderedComputedViewVersionsByDatasetId: Record<string, RD.ResponseData<ListResourcesResponse>>;
}

const initialState: FidoReducerState = {
  computedViews: RD.Idle(),
  fidoToken: null,
  roverTabularExportToken: null,
  fidoDaos: RD.Idle(),
  embeddoDaos: {},
  createNamespaceResponse: RD.Idle(),
  createDataSourceResponse: RD.Idle(),
  updateDataSourceResponse: RD.Idle(),
  dataSourceMapping: {},
  dataSourcePreferredExecutionMapping: {},
  tableView: RD.Idle(),
  tableViewData: undefined,
  syncTableViewsLoading: false,
  allTables: RD.Idle(),
  referencedGlobalDatasets: RD.Idle(),
  latestReferencedGlobalDatasets: RD.Idle(),
  deletedLatestReferencedGlobalDatasetIds: new Set(),
  orderedComputedViewVersionsByDatasetId: {},
};

const fidoReducerSlice = createSlice({
  name: 'fido',
  initialState,
  reducers: {
    clearComputedViews: (state) => {
      state.computedViews = RD.Idle();
    },
    clearTablePreview: (state) => {
      state.tableView = RD.Idle();
      state.tableViewData = undefined;
    },
    markGlobalDatasetsAsLoaded: (state) => {
      state.referencedGlobalDatasets = RD.Success({});
    },
    markLatestGlobalDatasetsAsLoaded: (state) => {
      state.latestReferencedGlobalDatasets = RD.Success({});
    },
    clearReferencedGlobalDatasets: (state) => {
      state.referencedGlobalDatasets = RD.Idle();
      state.latestReferencedGlobalDatasets = RD.Idle();
      state.orderedComputedViewVersionsByDatasetId = {};
    },
    deleteReferencedGlobalDatasets: (state, { payload }: { payload: ReadAccessComputedView[] }) => {
      if (RD.isSuccess(state.referencedGlobalDatasets)) {
        const referencedGlobalDatasetMap = state.referencedGlobalDatasets.data;
        payload.forEach((view) => {
          delete referencedGlobalDatasetMap[view.id ?? ''];
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logInUserSuccess, (state, { payload }) => {
        if (!payload.team?.fido_config) return;
        state.fidoToken = payload.tokens?.fido_token ?? null;
        state.roverTabularExportToken = payload.tokens?.rover_token ?? null;
        FidoOpenAPI.BASE = payload.team.fido_config.url;
        RoverOpenAPI.BASE = payload.team.fido_config.rover_url ?? '';
      })
      .addCase(fetchDashboardSuccess, (state, { payload }) => {
        if (!payload.fido_token) return;
        state.fidoToken = payload.fido_token;
      })
      .addCase(listTeamDataSourcesActions.successAction, (state, { payload }) => {
        state.embeddoDaos = { ...state.embeddoDaos, dataSources: payload.dataSources };
      })
      .addCase(fetchUsedParentSchemasActions.successAction, (state, { payload }) => {
        state.embeddoDaos = { ...state.embeddoDaos, usedParentSchemas: payload.parent_schemas };
      })
      .addCase(getNamespaces.pending, (state) => {
        state.fidoDaos = RD.Loading();
      })
      .addCase(getNamespaces.fulfilled, (state, { payload }) => {
        const {
          dataSources: embeddoDataSources,
          usedParentSchemas: embeddoParentSchemas,
          schemaTablesMap: embeddoSchemaTablesMap,
        } = state.embeddoDaos;

        if (embeddoDataSources && embeddoParentSchemas && embeddoSchemaTablesMap) {
          /**
           * When we useFido we also want to support non embeddo namespaces + data sources
           * TODO: in future think about keeping track of all tables here as well
           */
          const embeddoOnlySchemas = embeddoParentSchemas.filter((s) => !s.fido_id);
          const embeddoOnlyDataSources = embeddoDataSources.filter((ds) => !ds.fido_id);

          const { namespaces, dataSources, tables, schemaTablesMap } =
            parseListNamespacesWithMetaResponse(
              payload.namespaces,
              embeddoParentSchemas,
              embeddoDataSources,
              embeddoSchemaTablesMap,
            );
          state.fidoDaos = RD.Success({
            namespaces: namespaces.concat(embeddoOnlySchemas),
            dataSources: dataSources.concat(embeddoOnlyDataSources),
            tables,
            schemaTablesMap,
          });
        } else {
          state.fidoDaos = RD.Error('Could not pull embeddo data');
        }
      })
      .addCase(getNamespaces.rejected, (state, { error }) => {
        state.fidoDaos = RD.Error(error.message ?? 'Something went wrong');
      })
      .addCase(getComputedViews.pending, (state) => {
        state.computedViews = RD.Loading();
      })
      .addCase(getComputedViews.fulfilled, (state, { payload }) => {
        // these should be guaranteed to be ComputedViews
        state.computedViews = RD.Success(payload.views.map((v) => v.view as ComputedViewWithIds));
      })
      .addCase(getComputedViews.rejected, (state, { error }) => {
        state.computedViews = RD.Error(error.message ?? 'Something went wrong');
      })
      .addCase(cloneComputedViews.fulfilled, (state, { payload }) => {
        // these should be guaranteed to be ComputedViews
        state.computedViews = RD.Success(payload.views.map((v) => v.view as ComputedViewWithIds));
      })
      .addCase(fetchFidoTablePreview.pending, (state) => {
        state.tableView = RD.Loading();
      })
      .addCase(fetchFidoTablePreview.fulfilled, (state, { payload }) => {
        const data = getEmbeddoResponseFromFidoResponse(payload);
        state.tableView = RD.Success(true);
        state.tableViewData = data;
      })
      .addCase(fetchFidoTablePreview.rejected, (state, { error }) => {
        state.tableView = RD.Error(error.message ?? 'Something went wrong');
        state.tableViewData = undefined;
      })
      .addCase(createComputedView.fulfilled, (state, { payload }) => {
        if (!RD.isSuccess(state.computedViews)) {
          state.computedViews = RD.Success([]);
        }

        state.computedViews.data.push(payload.view as ComputedViewWithIds);
      })
      .addCase(saveComputedView.fulfilled, (state, { payload }) => {
        if (!RD.isSuccess(state.computedViews)) return;

        const index = state.computedViews.data.findIndex((view) => view.id === payload.view.id);

        if (index >= 0) {
          state.computedViews.data.splice(index, 1, payload.view as ComputedViewWithIds);
        }
      })
      .addCase(createNamespace.fulfilled, (state, { payload }) => {
        state.createNamespaceResponse = RD.Success(payload);
      })
      .addCase(updateNamespace.fulfilled, ({ embeddoDaos, fidoDaos }, { meta }) => {
        if (!embeddoDaos.usedParentSchemas || !RD.isSuccess(fidoDaos)) return;

        const editedNamespace = fidoDaos.data.namespaces.find(
          (n) => n.fido_id === meta.arg.namespace.id,
        );
        const editedSchema = embeddoDaos.usedParentSchemas.find(
          (s) => s.fido_id === meta.arg.namespace.id,
        );

        if (editedNamespace) editedNamespace.name = meta.arg.namespace.name;
        if (editedSchema) editedSchema.name = meta.arg.namespace.name;
      })
      .addCase(deleteNamespace.fulfilled, ({ embeddoDaos, fidoDaos }, { meta }) => {
        if (!embeddoDaos.usedParentSchemas || !RD.isSuccess(fidoDaos)) return;
        fidoDaos.data.namespaces = fidoDaos.data.namespaces.filter(
          (n) => n.fido_id !== meta.arg.namespaceId,
        );
        embeddoDaos.usedParentSchemas = embeddoDaos.usedParentSchemas.filter(
          (s) => s.fido_id === meta.arg.namespaceId,
        );
      })
      .addCase(deleteDataSourceInFido.fulfilled, ({ embeddoDaos, fidoDaos }, { meta }) => {
        if (!embeddoDaos.dataSources || !RD.isSuccess(fidoDaos)) return;

        embeddoDaos.dataSources = embeddoDaos.dataSources.filter(
          (ds) => ds.fido_id !== meta.arg.dataSourceId,
        );
        fidoDaos.data.dataSources = fidoDaos.data.dataSources.filter(
          (ds) => ds.fido_id !== meta.arg.dataSourceId,
        );
      })
      .addCase(createDataSourceInFido.fulfilled, (state, { payload }) => {
        state.createDataSourceResponse = RD.Success(payload);
      })
      .addCase(createEmbeddoDataSource.fulfilled, (state, { payload }) => {
        // This is create fido data source in embeddo
        if (!RD.isSuccess(state.createDataSourceResponse)) return;
        const parsedDataSource = parseDataSourceFromFido(
          state.createDataSourceResponse.data.dataSource,
          payload.data_source,
        );
        let parsedSchema = undefined;
        if (payload.schema && RD.isSuccess(state.createNamespaceResponse)) {
          parsedSchema = parseSchema(state.createNamespaceResponse.data.namespace, payload.schema);
        }

        if (RD.isSuccess(state.fidoDaos)) {
          if (parsedSchema) {
            state.fidoDaos.data.namespaces.push(parsedSchema);
          }
          state.fidoDaos.data.dataSources.push(parsedDataSource);
        } else if (parsedSchema) {
          state.fidoDaos = RD.Success({
            namespaces: [parsedSchema],
            dataSources: [parsedDataSource],
            tables: [],
            schemaTablesMap: {},
          });
        }
      })
      .addCase(connectDataSourceSuccess, (state, { payload }) => {
        // This is for creating non fido data source in embeddo
        if (RD.isSuccess(state.fidoDaos)) {
          state.fidoDaos.data.dataSources.push(payload.data_source);
          if (payload.new_schema) {
            state.fidoDaos.data.namespaces.push(payload.new_schema);
          }
        }
      })
      .addCase(updateDataSourceInFido.fulfilled, (state, { payload }) => {
        state.updateDataSourceResponse = RD.Success(payload);
      })
      .addCase(editDataSourceSuccess, (state, { payload }) => {
        if (!RD.isSuccess(state.fidoDaos)) return;

        const updatedDataSource = RD.isSuccess(state.updateDataSourceResponse)
          ? parseDataSourceFromFido(
              state.updateDataSourceResponse.data.dataSource,
              payload.data_source,
            )
          : payload.data_source;

        const index = state.fidoDaos.data.dataSources.findIndex(
          ({ id }) => id === payload.data_source.id,
        );
        if (index !== -1) state.fidoDaos.data.dataSources[index] = updatedDataSource;
      })
      .addCase(getTableViews.pending, (state) => {
        state.allTables = RD.Loading();
      })
      .addCase(getTableViews.fulfilled, (state, { payload }) => {
        state.allTables = RD.Success(payload.views.map((item) => item.view.name));
      })
      .addCase(getTableViews.rejected, (state, { error }) => {
        state.allTables = RD.Error(error.message ?? 'Something went wrong');
      })
      .addCase(syncTableViews.pending, (state) => {
        state.syncTableViewsLoading = true;
      })
      .addCase(syncTableViews.fulfilled, (state, { payload }) => {
        state.syncTableViewsLoading = false;

        if (RD.isSuccess(state.fidoDaos)) {
          const tableViews = payload.views.filter(
            (view) =>
              (view.view as ReadAccessTableView | ReadAccessComputedView)[FIDO_TYPE_KEY] ===
              'table-view',
          );
          const newSyncedTablesMap = parseFidoTableViews(
            tableViews.map((view) => view.view as ReadAccessTableView),
          );
          const newSyncedTables = Object.values(newSyncedTablesMap);

          if (newSyncedTables.length > 0) {
            const namespaceId = newSyncedTables[0].namespaceId;
            const removedOldTables = state.fidoDaos.data.tables.filter(
              (table) => table.namespaceId !== namespaceId,
            );
            state.fidoDaos.data.tables = removedOldTables.concat(newSyncedTables);

            const mapping = state.fidoDaos.data.schemaTablesMap;

            removedOldTables.forEach((table) => {
              delete mapping[table.namespaceId ?? ''][table.id ?? ''];
            });
            newSyncedTables.forEach((table) => {
              const namespaceId = table.namespaceId ?? '';
              const tableId = table.id ?? '';
              if (!(namespaceId in mapping)) {
                mapping[namespaceId] = {};
              }
              mapping[namespaceId][tableId] = newSyncedTablesMap[tableId];
            });
          }
        }
      })
      .addCase(syncTableViews.rejected, (state) => {
        state.syncTableViewsLoading = false;
      })
      .addCase(batchGetVersionedViews.pending, (state) => {
        state.referencedGlobalDatasets = RD.Loading();
      })
      .addCase(batchGetVersionedViews.fulfilled, (state, { payload }) => {
        if (!RD.isLoading(state.referencedGlobalDatasets)) {
          return;
        }

        const referencedGlobalDatasetMap = payload.views.reduce(
          (referencedGlobalDatasetMap, viewResponse) => {
            const fetchedComputedView = viewResponse.view as ReadAccessComputedView;
            referencedGlobalDatasetMap[fetchedComputedView.id ?? ''] = fetchedComputedView;
            return referencedGlobalDatasetMap;
          },
          RD.getOrDefault(state.referencedGlobalDatasets, {}) as Record<
            string,
            ReadAccessComputedView
          >,
        );
        state.referencedGlobalDatasets = RD.Success(referencedGlobalDatasetMap);
      })
      .addCase(batchGetVersionedViews.rejected, (state) => {
        state.referencedGlobalDatasets = RD.Error('Error fetching global datasets');
      })
      .addCase(batchGetLatestVersionedViews.pending, (state) => {
        if (RD.isIdle(state.latestReferencedGlobalDatasets)) {
          state.latestReferencedGlobalDatasets = RD.Loading();
        }
      })
      .addCase(batchGetLatestVersionedViews.fulfilled, (state, action) => {
        const referencedGlobalDatasets = action.payload;
        const referencedGlobalDatasetsMap: Record<string, ReadAccessComputedView> = {};
        for (const viewResponse of referencedGlobalDatasets) {
          if (!viewResponse) {
            continue;
          }
          if ('branchId' in viewResponse) {
            const viewDeletedResponse = viewResponse as ViewDeletedResponse;
            state.deletedLatestReferencedGlobalDatasetIds.add(viewDeletedResponse.viewId);
          } else if ('view' in viewResponse) {
            referencedGlobalDatasetsMap[viewResponse.view.id ?? ''] =
              viewResponse.view as ReadAccessComputedView;
          }
        }
        state.latestReferencedGlobalDatasets = RD.Success(referencedGlobalDatasetsMap);
      })
      .addCase(batchGetLatestVersionedViews.rejected, (state) => {
        state.latestReferencedGlobalDatasets = RD.Error('Error fetching latest global datasets');
      })
      .addCase(addGlobalDatasetReference, (state, { payload }) => {
        if (
          !RD.isSuccess(state.referencedGlobalDatasets) ||
          !RD.isSuccess(state.latestReferencedGlobalDatasets)
        ) {
          return;
        }

        const referencedGlobalDatasetMap = state.referencedGlobalDatasets.data;
        const newDatasetId = payload.newDataset.id ?? '';
        referencedGlobalDatasetMap[newDatasetId] = payload.newDataset;
        // The user currently must import the latest version of the dataset from the data library.
        // This could change.
        const latestReferencedGlobalDatasets = state.latestReferencedGlobalDatasets.data;
        latestReferencedGlobalDatasets[newDatasetId] = payload.newDataset;
      })
      .addCase(deleteGlobalDatasetReference, (state, { payload }) => {
        if (
          !RD.isSuccess(state.referencedGlobalDatasets) ||
          !RD.isSuccess(state.latestReferencedGlobalDatasets)
        ) {
          return;
        }

        const referencedGlobalDatasetMap = state.referencedGlobalDatasets.data;
        delete referencedGlobalDatasetMap[payload.datasetId];
        const latestReferencedGlobalDatasets = state.latestReferencedGlobalDatasets.data;
        delete latestReferencedGlobalDatasets[payload.datasetId];
      })
      .addCase(updateReferencedGlobalDatasetVersion, (state, { payload }) => {
        if (!RD.isSuccess(state.referencedGlobalDatasets)) {
          return;
        }

        const referencedGlobalDatasetMap: Record<string, ReadAccessComputedView> =
          state.referencedGlobalDatasets.data;
        const newGlobalDataset = payload.newDataset;
        referencedGlobalDatasetMap[newGlobalDataset.id ?? ''] = payload.newDataset;
      })
      .addCase(getResourceHistoryThunk.pending, (state, action) => {
        state.orderedComputedViewVersionsByDatasetId[action.meta.arg.resourceId] = RD.Loading();
      })
      .addCase(getResourceHistoryThunk.fulfilled, (state, action) => {
        const resourcesResponse = action.payload;
        const alreadySeenVersionIds = new Set<string>();
        let dedupedCount = 0;
        const dedupedViews = resourcesResponse.resources.filter((resource) => {
          const versionId = resource.versionId ?? '';
          const isDuplicate = alreadySeenVersionIds.has(versionId);
          alreadySeenVersionIds.add(versionId);
          dedupedCount += isDuplicate ? 1 : 0;
          return !isDuplicate;
        });

        state.orderedComputedViewVersionsByDatasetId[action.meta.arg.resourceId] = RD.Success({
          resources: dedupedViews,
          totalResults: resourcesResponse.totalResults - dedupedCount,
        });
      })
      .addCase(getResourceHistoryThunk.rejected, (state, action) => {
        state.orderedComputedViewVersionsByDatasetId[action.meta.arg.resourceId] = RD.Error(
          action.error.message || '',
        );
      })
      .addMatcher(
        isAnyOf(embedFetchDashboardActions.successAction, listCustomerReportsSuccess),
        (state, { payload }) => {
          if (!payload.fido_config) return;
          state.fidoToken = payload.fido_config.token;
          state.roverTabularExportToken = payload.fido_config.rover_token;
          state.dataSourceMapping = payload.fido_config.data_source_mapping;
          state.dataSourcePreferredExecutionMapping =
            payload.fido_config?.data_source_preferred_execution_mapping ?? {};
          FidoOpenAPI.BASE = payload.fido_config.url;
          RoverOpenAPI.BASE = payload.fido_config.rover_url ?? '';
        },
      )
      .addMatcher(
        isAnyOf(fetchAllSchemaTablesActions.requestAction, fetchAllTables.pending),
        (state) => {
          state.embeddoDaos.schemaTablesMap = undefined;
        },
      )
      .addMatcher(
        isAnyOf(fetchAllSchemaTablesActions.errorAction, fetchAllTables.rejected),
        (state) => {
          state.embeddoDaos.schemaTablesMap = undefined;
        },
      )
      .addMatcher(
        isAnyOf(fetchAllSchemaTablesActions.successAction, fetchAllTables.fulfilled),
        (state, { payload }) => {
          const schemaTablesMap: SchemaTablesMap = {};
          for (const key in payload.schema_tables) {
            schemaTablesMap[key.toString()] = keyBy(payload.schema_tables[key], 'id');
          }
          state.embeddoDaos.schemaTablesMap = schemaTablesMap;
        },
      );
  },
});

export const {
  clearComputedViews,
  clearTablePreview,
  markGlobalDatasetsAsLoaded,
  markLatestGlobalDatasetsAsLoaded,
  clearReferencedGlobalDatasets,
  deleteReferencedGlobalDatasets,
} = fidoReducerSlice.actions;

export const fidoReducer = fidoReducerSlice.reducer;
