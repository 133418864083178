import { QueryExecutionResponse } from '@explo-tech/fido-api';
import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  DrilldownColConfig,
  DrilldownColConfigOptions,
  DrilldownConfig,
  saveDatasetQuery,
  saveDraftDatasetQuery,
  setSelectedDrilldownColumn,
  updateDashboardDatasetSchema,
  updateDatasetDrilldownColConfig,
  updateDatasetDrilldownColConfigs,
} from 'actions/datasetActions';
import { FetchDashboardDatasetPreviewData } from 'actions/responseTypes';
import { Poller } from 'components/JobQueue/Poller';
import { receiveFinishedJobs } from 'reducers/dashboardLayoutReducer';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getEditableDatasets } from 'reducers/selectors';
import {
  fetchEditorDatasetPreviewThunk,
  fetchSavedDatasetThunk,
} from 'reducers/thunks/dashboardDataThunks/fetchDatasetPreviewThunks';
import * as RD from 'remotedata';
import { showDuplicateColumnNameToast } from 'shared/sharedToasts';
import { EVENTS, trackEvent } from 'telemetry/exploAnalytics';
import { getDuplicateColumnsFromSchema } from 'utils/queryUtils';
import { createNewDashboardParam } from 'utils/variableUtils';
import * as styles from './styles.css';
import { switchSelectedDataset } from './utils';

import { DatasetEditor } from './DatasetEditor';
import { DatasetSelectTopBar } from './DatasetSelectTopBar';
import { BaseCol } from '@explo/data';
import { updateDatasetDrilldownConfig } from 'reducers/dashboardEditConfigReducer';

export const DashboardEditorEmbeddoDataFetcher = () => {
  const dispatch = useDispatch();

  const {
    dashboardId,
    datasets,
    parentSchemas,
    datasetData,
    awaitedJobs,
    selectedDatasetId,
    variables,
    selectedDrilldownColumn,
  } = useSelector(
    (state: ReduxState) => ({
      dashboardId: RD.isSuccess(state.dashboard.currentDashboard)
        ? state.dashboard.currentDashboard.data.id
        : null,
      shouldUseJobQueue: !!state.currentUser.team?.feature_flags.use_job_queue,
      datasets: getEditableDatasets(state),
      parentSchemas: getParentSchemasList(state),
      datasetData: state.dashboardEditConfig.datasetData,
      awaitedJobs: state.dashboardLayout.awaitedJobs,
      selectedDatasetId: state.dashboardEditConfig.selectedDatasetId,
      variables: state.dashboardData.variables ?? {},
      selectedDrilldownColumn: state.dashboardEditConfig.selectedDrilldownColumn,
    }),
    shallowEqual,
  );

  const parentSchemaId =
    selectedDatasetId && datasets ? datasets[selectedDatasetId].parent_schema_id : undefined;

  const getUnderlyingData = useCallback(
    (
      query: string,
      pageNumber?: number,
      onSuccess?: (data: QueryExecutionResponse | FetchDashboardDatasetPreviewData) => void,
    ) => {
      if (!selectedDatasetId || !parentSchemaId) return;

      dispatch(
        fetchEditorDatasetPreviewThunk(
          {
            selectedDatasetId,
            query,
            parentSchemaId: parentSchemaId,
          },
          pageNumber,
          onSuccess,
        ),
      );
    },
    [parentSchemaId, dispatch, selectedDatasetId],
  );

  const onSaveQuery = useCallback(
    (query: string) => {
      if (!selectedDatasetId) return;

      getUnderlyingData(query, undefined, (data) => {
        const previewData = (data as FetchDashboardDatasetPreviewData).dataset_preview;
        dispatch(
          saveDatasetQuery({
            query: query,
            dataset_id: selectedDatasetId,
            schema: previewData.schema,
          }),
        );
        dispatch(fetchSavedDatasetThunk(selectedDatasetId));

        trackEvent(EVENTS.SAVED_QUERY, {
          dataset_id: selectedDatasetId,
          dataset_query: query,
        });

        showDuplicateColumnNameToast(getDuplicateColumnsFromSchema(previewData.schema));
      });
    },
    [dispatch, getUnderlyingData, selectedDatasetId],
  );

  const onSaveQueryDraft = (query: string | undefined) => {
    if (!selectedDatasetId) return;

    dispatch(saveDraftDatasetQuery({ queryDraft: query, dataset_id: selectedDatasetId }));
  };

  const onSelectSchema = (schemaId: number | string) => {
    if (!selectedDatasetId) return;

    dispatch(
      updateDashboardDatasetSchema({
        datasetId: selectedDatasetId,
        newParentSchemaId: schemaId as number,
      }),
    );
  };

  const onSelectDrilldownColumn = useCallback(
    (columnInfo: BaseCol | undefined) => {
      dispatch(setSelectedDrilldownColumn(columnInfo));
    },
    [dispatch],
  );

  const onUpdateDrilldownConfig = useCallback(
    (datasetId: string, updates: Partial<DrilldownConfig>) => {
      dispatch(updateDatasetDrilldownConfig({ id: datasetId, updates }));
    },
    [dispatch],
  );

  const onUpdateDrilldownColumnConfig = useCallback(
    (datasetId: string, columnName: string, newColumnConfigs: DrilldownColConfigOptions) => {
      dispatch(
        updateDatasetDrilldownColConfig({
          datasetId,
          colName: columnName,
          ...newColumnConfigs,
        }),
      );
    },
    [dispatch],
  );

  const onUpdateDrilldownColumnConfigs = useCallback(
    (datasetId: string, newColumnConfigs: Record<string, DrilldownColConfig>) => {
      dispatch(
        updateDatasetDrilldownColConfigs({
          datasetId,
          newConfigs: newColumnConfigs,
        }),
      );
    },
    [dispatch],
  );

  const selectedDataset = datasets ? datasets[selectedDatasetId ?? ''] : null;
  const data = selectedDatasetId ? datasetData[selectedDatasetId] : undefined;

  if (!dashboardId) {
    return null;
  }

  return (
    <div className={styles.datasetEditorContainerStyle}>
      <DatasetSelectTopBar
        datasetConfigs={datasets ?? {}}
        selectedDatasetId={selectedDatasetId}
        setSelectedDatasetId={(datasetId) => {
          const datasetName = datasetId ? datasets[datasetId]?.table_name : undefined;
          switchSelectedDataset(datasetId, datasetName, dispatch);
        }}
      />
      <DatasetEditor
        renderFormattingTab
        activeDatasetConfig={selectedDataset}
        activeDatasetData={data ?? null}
        activeDatasetSavedSchema={selectedDataset?.schema ?? []}
        activeDatasetSchema={data?.schema ?? selectedDataset?.schema ?? []}
        activeQuery={selectedDataset?.queryDraft ?? selectedDataset?.query ?? ''}
        createNewParam={() => createNewDashboardParam(dashboardId)}
        fetchData={getUnderlyingData}
        onSave={onSaveQuery}
        onSaveDraft={onSaveQueryDraft}
        onSelectDrilldownColumn={onSelectDrilldownColumn}
        onSelectSchema={onSelectSchema}
        onUpdateColumnConfig={onUpdateDrilldownColumnConfig}
        onUpdateColumnConfigs={onUpdateDrilldownColumnConfigs}
        onUpdateDrilldownConfig={onUpdateDrilldownConfig}
        parentSchemas={parentSchemas}
        selectedDatasetId={selectedDatasetId}
        selectedDrilldownColumn={selectedDrilldownColumn}
        variables={variables}
      />
      <Poller
        awaitedJobs={awaitedJobs}
        updateJobResult={(finishedJobIds, onComplete) => {
          if (finishedJobIds.length > 0) dispatch(receiveFinishedJobs(finishedJobIds));

          onComplete();
        }}
      />
    </div>
  );
};
