import { Branch, Folder } from '@explo-tech/fido-api';
import { FC, useState } from 'react';
import * as RD from 'remotedata';

import { DatasetPreviewPanel } from 'components/Dataset/DatasetPreviewPanel';
import { Button, sprinkles } from 'components/ds';
import { StepType } from './ImportGlobalDatasetModal';

import { User } from 'actions/userActions';
import { ROOT_FOLDER_PATH } from 'pages/dataLibraryPage/constants';
import { DirectoryPanelContent } from 'pages/dataLibraryPage/DirectoryPanelContent';
import { ItemPathInfo, ItemType } from 'reducers/dataLibraryReducer';
import { ReadAccessComputedView } from 'utils/fido/fidoShimmedTypes';
import { VersionedComputedViewReference } from 'types/dashboardVersionConfig';
import { panelContainer } from './ImportDatasetContent.css';

interface Props {
  mainBranch: Branch;
  rootFolder: Folder;
  selectedComputedView: ReadAccessComputedView | undefined;
  currentDatasetNames: Set<string>;
  currentUser: User;
  globalDatasetReferences: Record<string, VersionedComputedViewReference>;
  shouldSkipVariableMapping: boolean;

  onClose: () => void;
  setCurrentStep: (step: StepType) => void;
  setSelectedComputedView: (computedView: ReadAccessComputedView | undefined) => void;
  onImportGlobalDataset: (computedView: ReadAccessComputedView, currentUser: User) => void;
}

export const ImportDatasetContent: FC<Props> = ({
  onClose,
  mainBranch,
  rootFolder,
  setCurrentStep,
  setSelectedComputedView,
  selectedComputedView,
  currentDatasetNames,
  currentUser,
  onImportGlobalDataset,
  globalDatasetReferences,
  shouldSkipVariableMapping,
}) => {
  const [selectedItemPath, setSelectedItemPath] = useState<ItemPathInfo>({
    path: ROOT_FOLDER_PATH,
    type: ItemType.FOLDER,
  });
  const [folderExpansionState, setFolderExpansionState] = useState<Map<string, boolean>>(
    new Map([[ROOT_FOLDER_PATH, true]]),
  );

  const isSelectedComputedViewAlreadyReferenced =
    !!globalDatasetReferences[selectedComputedView?.id ?? ''];

  return (
    <>
      <div className={sprinkles({ alignItems: 'flex-start', display: 'flex' })}>
        <div className={panelContainer}>
          <div
            className={sprinkles({
              color: 'contentTertiary',
              heading: 'subtitle',
              marginX: 'sp2',
            })}>
            ALL DATASETS
          </div>
          <DirectoryPanelContent
            branchId={mainBranch.id ?? ''}
            commitButtonEnabled={false}
            expansionState={folderExpansionState}
            onCommitButtonClicked={() => {
              // Do nothing, the commit button is not rendered.
            }}
            onComputedViewSelection={(computedView) => {
              setSelectedComputedView(computedView);
              setSelectedItemPath({
                path: computedView.path ?? '',
                type: ItemType.VIEW,
              });
            }}
            onFolderSelection={() => {
              // Do nothing
            }}
            pendingCommitStatus={RD.Idle()}
            renderCommitButton={false}
            rootFolder={rootFolder}
            selectedItemPath={selectedItemPath}
            setIsExpanded={(folderPath: string, isExpanded: boolean) => {
              setFolderExpansionState(new Map(folderExpansionState).set(folderPath, isExpanded));
            }}
            shouldReadFromHead={false}
          />
        </div>
        <div className={sprinkles({ flexItems: 'alignCenter', width: 'fill' })}>
          {selectedComputedView ? (
            <DatasetPreviewPanel queryText={selectedComputedView.query} />
          ) : (
            <span className={sprinkles({ marginX: 'sp1' })}>Select a dataset to import</span>
          )}
        </div>
      </div>
      <div className={sprinkles({ flexItems: 'alignCenter', justifyContent: 'flex-end' })}>
        <Button
          className={sprinkles({ marginRight: 'sp2', marginTop: 'sp2' })}
          disabled={
            !selectedComputedView ||
            isSelectedComputedViewAlreadyReferenced ||
            currentDatasetNames.has(selectedComputedView?.name ?? '')
          }
          onClick={() => {
            if (!selectedComputedView) {
              // TODO(zifanxiang): Consider throwing an error here. This button should not be
              // enabled when there is no selected computed view.
              return;
            }

            // If there are global variables to map, go to the next step. Otherwise, close the modal.
            if (selectedComputedView.parameters.length && !shouldSkipVariableMapping) {
              setCurrentStep(StepType.MAP_VARIABLES);
            } else {
              onImportGlobalDataset(selectedComputedView, currentUser);
              onClose();
            }
          }}
          tooltipProps={{
            text: getDisabledTooltipText(
              selectedComputedView,
              currentDatasetNames,
              isSelectedComputedViewAlreadyReferenced,
            ),
          }}>
          Select dataset
        </Button>
      </div>
    </>
  );
};

const getDisabledTooltipText = (
  selectedComputedView: ReadAccessComputedView | undefined,
  currentDatasetNames: Set<string>,
  isSelectedComputedViewAlreadyReferenced: boolean,
): string | null => {
  if (!selectedComputedView) {
    return 'Select a dataset to import';
  } else if (isSelectedComputedViewAlreadyReferenced) {
    return 'Dataset is already imported';
  } else if (currentDatasetNames.has(selectedComputedView.name ?? '')) {
    return 'Dataset has the same name as an existing dataset. Please rename the dataset before importing.';
  }

  return null;
};
