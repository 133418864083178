import { v4 as uuidv4 } from 'uuid';

import {
  BaseCol,
  ChartAggregation,
  CustomerReportFilter,
  CustomerReportGroupBy,
  DatasetSchema,
  DATE_TYPES,
  FilterOperator,
  FilterValueType,
  isFilterClauseIncomplete,
  OPERATION_TYPES,
  PIVOT_AGG_TYPES,
  SortInfo,
  SortOrder,
} from '@explo/data';

import { CustomerReportAgg, CustomerReportView } from 'actions/customerReportActions';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { AIChartType, AIView, ColumnFilter } from 'reportBuilderContent/apiTypes';
import { compact, uniq } from 'utils/standard';
import { getSchemaColFromAgg, getSchemaColFromGroupBy } from 'utils/V2ColUtils';

/**
 * Give a column, gets the correct name and type if it were to be used as a filter or sort
 * If the column is an aggregation, the name will be the aggregation name. i.e. book_rate_max
 *
 * @param column
 * @param aggregations
 * @param groupBys
 * @param columnGroupBys
 * @param dataset
 */
const getFilterColData = (
  column: BaseCol,
  aggregations: CustomerReportAgg[],
  groupBys: CustomerReportGroupBy[],
  columnGroupBys: CustomerReportGroupBy[],
  { columnConfigs }: ReportBuilderDataset,
) => {
  const findInArray = <T extends { column: BaseCol }>(col: BaseCol, arr: T[]) =>
    arr.find((item) => item.column.name === col.name && item.column.type === col.type);

  const agg = findInArray(column, aggregations);
  if (agg) {
    const datasetAgg = getSchemaColFromAgg(columnConfigs, {}, agg, /* variables= */ {});
    return { name: datasetAgg.name, type: datasetAgg.type, isPostFilter: true };
  }

  const groupBy = findInArray(column, groupBys);
  if (groupBy) {
    const datasetGroupBy = getSchemaColFromGroupBy(columnConfigs, {}, groupBy, /* variables= */ {});
    return { name: datasetGroupBy.name, type: datasetGroupBy.type, isPostFilter: false };
  }

  const colGroupBy = findInArray(column, columnGroupBys);
  if (colGroupBy) {
    const datasetColGroupBy = getSchemaColFromGroupBy(
      columnConfigs,
      {},
      colGroupBy,
      /* variables= */ {},
    );
    return { name: datasetColGroupBy.name, type: datasetColGroupBy.type, isPostFilter: false };
  }

  return { ...column, isPostFilter: false };
};

const getArrayFromOutput = <T>(output?: T[]): T[] => (Array.isArray(output) ? output : []);

const getColumnFromSchema = (schema: DatasetSchema, name: string) =>
  schema.find((col) => col.name === name);

export const aiOutputToView = (
  name: string,
  chartType: AIChartType,
  chart: AIView,
  dataset: ReportBuilderDataset,
): CustomerReportView | undefined => {
  const schema = dataset.schema;
  if (!schema) return;

  const datasetColNames = schema.map((col) => col.name);

  const groupBys = compact(
    getArrayFromOutput(chart.groupBys).map((groupBy) => {
      const column = getColumnFromSchema(schema, groupBy.groupByColumn);
      if (column) {
        const bucket =
          DATE_TYPES.has(column.type) && PIVOT_AGG_TYPES[groupBy.dateGrouping]
            ? groupBy.dateGrouping
            : undefined; // Bucketing only allowed for dates
        return { column, bucket };
      }
    }),
  );

  // Always convert to an array
  const chartAggregations = Array.isArray(chart.aggregations)
    ? chart.aggregations
    : chart.aggregations
      ? [chart.aggregations]
      : [];
  const aggregations = compact(
    chartAggregations.map((agg) => {
      const column = getColumnFromSchema(schema, agg.aggregateColumn);
      if (column && aggregationValues.has(agg.aggregateFunction))
        return { column, agg: { id: agg.aggregateFunction } };
    }),
  );

  const filters = compact(
    getArrayFromOutput(chart.filters).map((filter, i) => {
      const column = getColumnFromSchema(schema, filter.filterColumn);
      if (!column) return;

      // Filter column names needs to have the right suffix after aggregating. i.e. book_rate_max
      const { name, type, isPostFilter } = getFilterColData(
        column,
        aggregations,
        groupBys,
        [],
        dataset,
      );

      const filterOperation = getFilterOperation(filter);
      if (!filterOperation) return;

      const clause: CustomerReportFilter = {
        id: i,
        isPostFilter,
        filterColumn: { name, type },
        filterOperation: { id: filterOperation[0] },
        filterValue: filterOperation[1],
      };
      const isIncomplete = isFilterClauseIncomplete(clause);
      if (!isIncomplete) return clause;
    }),
  );

  const isTable = chartType === 'table';

  // Sorting is only applicable to tables
  const sort: SortInfo[] | undefined =
    isTable && groupBys.length === 0 && aggregations.length === 0
      ? compact(
          getArrayFromOutput(chart.sort).map(({ sortOrder, sortColumn }) => {
            const column = getColumnFromSchema(schema, sortColumn);
            if (!column) return;
            // Sort column names needs to have the right suffix after aggregating. i.e. book_rate_max
            // (groupBys are rows and sorting is disabled when using columnGroupBys so sorting by them won't do anything)
            const { name } = getFilterColData(column, aggregations, groupBys, [], dataset);
            return {
              order: sortOrder === 'ASC' ? SortOrder.ASC : SortOrder.DESC,
              column: { name },
            };
          }),
        )
      : undefined;

  // Column order determines what columns from the original dataset to render and what order to render them in
  // Column order is only applicable to tables because all other chart types have aggregations and groupBys
  const columnOrder = isTable
    ? uniq([
        ...datasetColNames,
        ...(sort || []).map(({ column }) => column.name), // Ensure sort columns are included
        ...filters.map(({ filterColumn }) => filterColumn.name), // Filters are not needed, but nice to show
      ])
    : datasetColNames;

  const columnSet = new Set(getArrayFromOutput(chart.columns));
  const hiddenColumns = datasetColNames.filter((col) => !columnSet.has(col));

  return {
    id: uuidv4(),
    name,
    columnOrder,
    hiddenColumns: hiddenColumns,
    visualization: chartTypeToOperationType[chartType],
    filters,
    sort,
    groupBys,
    aggregations,
    columnGroupBys: [],
  };
};

export function getFilterOperation(
  input: ColumnFilter,
): [FilterOperator, FilterValueType] | undefined {
  if (input.COLUMN_TYPE_IS_BOOLEAN?.EQUALS === true) {
    return [FilterOperator.BOOLEAN_IS_TRUE, undefined];
  } else if (input.COLUMN_TYPE_IS_BOOLEAN?.EQUALS === false) {
    return [FilterOperator.BOOLEAN_IS_FALSE, undefined];
  }

  const dateNext = input.COLUMN_TYPE_IS_DATE_OR_TIME?.DATE_NEXT;
  if (dateNext) {
    const value = { relativeTimeType: { id: dateNext.unit }, number: dateNext.number };
    return [FilterOperator.DATE_NEXT, value];
  }

  const datePrev = input.COLUMN_TYPE_IS_DATE_OR_TIME?.DATE_PREVIOUS;
  if (datePrev) {
    const value = { relativeTimeType: { id: datePrev.unit }, number: datePrev.number };
    return [FilterOperator.DATE_PREVIOUS, value];
  }

  for (const [type, value] of Object.entries(input.COLUMN_TYPE_IS_STRING || {})) {
    if (stringMapping[type]) return [stringMapping[type], value];
  }

  for (const [type, value] of Object.entries(input.COLUMN_TYPE_IS_NUMERIC || {})) {
    if (numberMapping[type]) return [numberMapping[type], value];
  }

  for (const [type, value] of Object.entries(input.COLUMN_TYPE_IS_DATE_OR_TIME || {})) {
    if (dateMapping[type]) return [dateMapping[type], value];
  }

  for (const type of Object.keys(input.ANY_COLUMN_TYPE || {})) {
    if (nullMapping[type]) return [nullMapping[type], undefined];
  }
}

const stringMapping: Record<string, FilterOperator> = {
  EQUALS: FilterOperator.STRING_IS,
  NOT_EQUALS: FilterOperator.STRING_IS_NOT,
  IS_IN_LIST: FilterOperator.STRING_IS_IN,
  IS_NOT_IN_LIST: FilterOperator.STRING_IS_NOT_IN,
  CONTAINS_SUBSTR: FilterOperator.STRING_CONTAINS,
  NOT_CONTAINS_SUBSTR: FilterOperator.STRING_DOES_NOT_CONTAIN,
};

const numberMapping: Record<string, FilterOperator> = {
  EQUALS: FilterOperator.NUMBER_EQ,
  NOT_EQUALS: FilterOperator.NUMBER_NEQ,
  LESS_THAN: FilterOperator.NUMBER_LT,
  GREATER_THAN: FilterOperator.NUMBER_GT,
  LESS_THAN_OR_EQUAL: FilterOperator.NUMBER_LTE,
  GREATER_THAN_OR_EQUAL: FilterOperator.NUMBER_GTE,
  IS_IN_LIST: FilterOperator.NUMBER_IS_IN,
  IS_NOT_IN_LIST: FilterOperator.NUMBER_IS_NOT_IN,
  IS_BETWEEN: FilterOperator.NUMBER_IS_BETWEEN,
};

const dateMapping: Record<string, FilterOperator> = {
  EQUALS_UTC: FilterOperator.DATE_IS,
  NOT_EQUALS_UTC: FilterOperator.DATE_IS_NOT,
  LESS_THAN_UTC: FilterOperator.DATE_LT,
  GREATER_THAN_UTC: FilterOperator.DATE_GT,
  LESS_THAN_OR_EQUAL_UTC: FilterOperator.DATE_LTE,
  GREATER_THAN_OR_EQUAL_UTC: FilterOperator.DATE_GTE,
  IS_BETWEEN_UTC: FilterOperator.DATE_IS_BETWEEN,
  DATE_PREVIOUS: FilterOperator.DATE_PREVIOUS,
  DATE_NEXT: FilterOperator.DATE_NEXT,
};

const nullMapping: Record<string, FilterOperator> = {
  IS_NULL: FilterOperator.IS_EMPTY,
  IS_NOT_NULL: FilterOperator.IS_NOT_EMPTY,
};

const chartTypeToOperationType = {
  table: OPERATION_TYPES.VISUALIZE_TABLE,
  bar: OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  line: OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
  pie: OPERATION_TYPES.VISUALIZE_PIE_CHART_V2,
  number: OPERATION_TYPES.VISUALIZE_NUMBER_V2,
} as const;

const aggregationValues = new Set(Object.values(ChartAggregation));
