import { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import {
  Icon,
  IconButton,
  Menu,
  MenuActionItem,
  MenuSeparator,
  sprinkles,
  Tooltip,
} from 'components/ds';
import { ROUTE_PROVIDERS } from 'constants/routes';
import { DashboardSelectionControlDatum } from 'pages/dashboardPage/LayersPanel/types';
import { setHighlightedDataPanelIds } from 'reducers/dashboardInteractionsReducer';
import { setCurrentSourceInfos } from 'reducers/drilldownsReducer';

interface Props {
  itemData: DashboardSelectionControlDatum;
  currentDashboardId: number;
  dashboardIdToSourceDataPanelIdMap: Record<number, string>;
  selectedDashboardItemId: string | undefined;
  isChildOfSelectedDashboard: boolean;
  onRenameFn: (dashboardId: number, dashboardName: string) => void;
  onDeleteFn: (dashboardId: number, dashboardName: string, isRoot: boolean) => void;
}

const ROOT_HIERARCHY_LEVEL = 0;

// TODO(zifanxiang): Look into seeing how to type this more generally to all the values specified by
// the spacing object in sprinkles.
const ITEM_INDENT_MAP: Record<number, 'sp0' | 'sp1' | 'sp2'> = {
  0: 'sp0',
  1: 'sp1',
  2: 'sp2',
};

/** A control for selecting a particular dashboard within a dashboard hierarchy. */
export const DashboardSelectionControl: FC<Props> = ({
  itemData,
  currentDashboardId,
  dashboardIdToSourceDataPanelIdMap,
  selectedDashboardItemId,
  isChildOfSelectedDashboard,
  onRenameFn,
  onDeleteFn,
}) => {
  const [isOverflowMenuOpen, setIsOverflowMenuOpen] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const isSourceDataPanelSelected = useMemo(() => {
    if (!selectedDashboardItemId) {
      return false;
    }

    const sourceDataPanelId = dashboardIdToSourceDataPanelIdMap[itemData.dashboardId];
    return selectedDashboardItemId === sourceDataPanelId;
  }, [dashboardIdToSourceDataPanelIdMap, itemData, selectedDashboardItemId]);

  const isRoot = itemData.hierarchyLevel === ROOT_HIERARCHY_LEVEL;
  const isOrphaned = useMemo(
    () =>
      dashboardIdToSourceDataPanelIdMap[itemData.dashboardId] === undefined &&
      !isRoot &&
      isChildOfSelectedDashboard,
    [dashboardIdToSourceDataPanelIdMap, itemData, isRoot, isChildOfSelectedDashboard],
  );

  const iconName =
    itemData.hierarchyLevel === ROOT_HIERARCHY_LEVEL ? 'folder' : 'arrow-turn-down-right';
  const itemIndent = ITEM_INDENT_MAP[itemData.hierarchyLevel];
  const isSelectedDashboardControl = currentDashboardId === itemData.dashboardId;
  return (
    <button
      className={getSelectionControlClass(isSourceDataPanelSelected)}
      onClick={() => {
        if (!isSelectedDashboardControl && !isOverflowMenuOpen) {
          dispatch(
            setCurrentSourceInfos({
              currentSourceInfos: itemData.drilldownSourceInfos,
              shouldInvalidateDrilldownDatasetFilters: true,
            }),
          );
          history.push(ROUTE_PROVIDERS.DASHBOARD_EDIT_MODE(`${itemData.dashboardId}`));
        }
      }}
      onMouseEnter={() =>
        dispatch(
          setHighlightedDataPanelIds(
            new Set([dashboardIdToSourceDataPanelIdMap[itemData.dashboardId]]),
          ),
        )
      }
      onMouseLeave={() => dispatch(setHighlightedDataPanelIds(new Set()))}>
      <div className={sprinkles({ flexItems: 'alignCenter', marginLeft: itemIndent })}>
        <Icon name={iconName} />
        <div className={sprinkles({ marginX: 'sp1' })}>{itemData.name}</div>
      </div>
      <div className={sprinkles({ flexItems: 'alignCenter' })}>
        {isOrphaned ? (
          <Tooltip text="Dashboard has no source chart">
            <Icon
              className={sprinkles({ color: 'warning', marginRight: 'sp1' })}
              name="circle-info"
            />
          </Tooltip>
        ) : null}
        {isSelectedDashboardControl ? (
          <Icon className={sprinkles({ color: 'active', marginRight: 'sp1' })} name="check" />
        ) : null}
        <Menu
          onOpenChange={(open) => setIsOverflowMenuOpen(open)}
          trigger={
            <IconButton
              name="ellipsis-vertical"
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
          }>
          <MenuActionItem
            iconName="pencil"
            onSelect={(event) => {
              event.stopPropagation();
              onRenameFn(itemData.dashboardId, itemData.name);
            }}
            text="Rename"
          />
          <MenuSeparator />
          <MenuActionItem
            isDestructive
            iconName="trash"
            onSelect={(event) => {
              event.stopPropagation();
              onDeleteFn(itemData.dashboardId, itemData.name, isRoot);
            }}
            text="Delete"
          />
        </Menu>
      </div>
    </button>
  );
};

const getSelectionControlClass = (isSourceDataPanelSelected: boolean) =>
  sprinkles({
    backgroundColor: { hover: 'elevationLow' },
    border: isSourceDataPanelSelected ? 1 : 0,
    borderColor: isSourceDataPanelSelected ? 'active' : undefined,
    borderRadius: 4,
    flexItems: 'alignCenterBetween',
    height: 32,
    overflow: 'hidden',
    truncateText: 'ellipsis',
    paddingX: 'sp1',
    paddingY: 'sp2.5',
    marginY: 'sp1',
    width: 'fill',
  });
