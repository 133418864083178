import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { pageView } from 'telemetry/exploAnalytics';
import { useQuery } from 'utils/routerUtils';

import { Button, Tabs } from 'components/ds';
import { PageHeader } from 'components/PageHeader';
import { RecentActivity } from 'features/dataShare/ExportList/RecentActivity';
import { ScheduledExports } from 'features/dataShare/ExportList/ScheduledExports';

import { ROUTE_PROVIDERS } from 'constants/routes';
import {
  EXPORT_SORT_OPTION,
  ExportSortOption,
  TAB_ID,
  TAB_LABEL,
  TAB_LABELS,
  TabLabel,
  tabLabelToId,
} from 'features/dataShare/ExportList/constants';
import * as styles from 'features/dataShare/ExportList/styles.css';

export const DataSharePage: FC = () => {
  const history = useHistory();
  const query = useQuery();

  const [sortOption, setSortOption] = useState<ExportSortOption>(EXPORT_SORT_OPTION.LAST_EDITED);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const sortText = useMemo(() => {
    return sortOption === EXPORT_SORT_OPTION.LAST_EDITED ? 'Last Edited' : 'Upcoming';
  }, [sortOption]);

  useEffect(() => {
    document.title = 'Explo | Exports';
    pageView('Exports');
  }, []);

  const currentTab: TabLabel = useMemo(() => {
    const view = query.get('view');
    if (view === TAB_ID.RECENT) {
      return TAB_LABEL.RECENT;
    }
    if (view !== TAB_ID.SCHEDULED && !view) {
      history.replace(ROUTE_PROVIDERS.SCHEDULED_EXPORTS());
    }
    return TAB_LABEL.SCHEDULED;
  }, [query, history]);

  const renderHeader = () => (
    <PageHeader
      primaryActionProps={{
        text: 'Create Export',
        onClick: () => {
          history.push(ROUTE_PROVIDERS.EMAIL_EXPORT());
        },
      }}
      searchBarProps={
        currentTab === TAB_LABEL.SCHEDULED
          ? {
              variant: 'default',
              placeholderText: 'Search exports by export name...',
              onSubmit: setSearchTerm,
            }
          : undefined
      }
      title="All Exports"
    />
  );

  return (
    <div className={styles.root}>
      {renderHeader()}
      <div className={styles.tabsAndSortContainer}>
        <Tabs
          hideUnderline
          className={styles.tabsContainer}
          onTabSelect={(tab) => {
            history.replace(ROUTE_PROVIDERS.SCHEDULED_EXPORTS(tabLabelToId(tab as TabLabel)));
          }}
          selectedTabId={currentTab}
          tabs={TAB_LABELS}
        />
        <Button
          className={styles.tertiaryButtonText}
          onClick={() =>
            setSortOption(
              sortOption === EXPORT_SORT_OPTION.LAST_EDITED
                ? EXPORT_SORT_OPTION.UPCOMING
                : EXPORT_SORT_OPTION.LAST_EDITED,
            )
          }
          variant="tertiary">
          Sort by: {sortText}
        </Button>
      </div>
      {currentTab === TAB_LABEL.RECENT ? (
        <RecentActivity />
      ) : (
        <ScheduledExports searchTerm={searchTerm} sortOption={sortOption} />
      )}
    </div>
  );
};
