import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Intent, Tag } from 'components/ds';
import { Dashboard } from 'actions/dashboardActions';
import { ReportBuilder } from 'actions/reportBuilderActions';
import { ReduxState } from 'reducers/rootReducer';
import { PLAN_TYPES } from 'constants/paymentPlanConstants';

interface Props {
  resource: Dashboard | ReportBuilder;
}

export const EmbedTag: FC<Props> = ({ resource }) => {
  const paymentPlan = useSelector((state: ReduxState) => state.teamData.data?.payment_plan);
  if (paymentPlan === PLAN_TYPES.GROWTH_V2 && 'is_billable' in resource && resource.is_billable) {
    return <Tag intent={Intent.SUCCESS}>Embed enabled</Tag>;
  }
  return null;
};
