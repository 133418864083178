import { FC } from 'react';

import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { CustomerPermissionsSummary } from 'components/CustomerPermissions/Summary';
import { APP_PORTAL_ID, Intent, sprinkles, Tag, Tooltip } from 'components/ds';
import * as styles from 'pages/ReportBuilderEditor/DatasetEditor/DatasetItem/index.css';
import { Icon, IconName } from 'components/ds/Icon';

type Props = {
  dataset: ReportBuilderDataset;
  isSelected: boolean;
  additionalRightSideButtonContainer?: JSX.Element;
  infoIcon?: IconName;

  onClick: () => void;
};

export const DatasetItem: FC<Props> = ({
  dataset,
  isSelected,
  additionalRightSideButtonContainer,
  infoIcon,
  onClick,
}) => {
  return (
    <div className={isSelected ? styles.selectedDataset : styles.dataset} onClick={onClick}>
      <div className={styles.datasetInfoContainer}>
        <div className={styles.datasetHeading}>
          <div className={styles.datasetInfo}>
            {infoIcon ? (
              <Icon
                className={sprinkles({ marginRight: 'sp1', color: 'contentPrimary' })}
                name={infoIcon}
              />
            ) : null}
            <div className={styles.datasetName}>{dataset.name}</div>
            {dataset.queryDraft ? (
              <Tag
                className={styles.tag}
                intent={isSelected ? Intent.ACTIVE : Intent.NONE}
                inverted={isSelected}>
                Draft
              </Tag>
            ) : null}
            {dataset.schema?.length === 0 ? (
              <Tooltip
                portalContainerId={APP_PORTAL_ID}
                side="right"
                text="This dataset's schema has not been saved and will not be available in your reports">
                <Tag className={styles.tag} intent={Intent.WARNING} leftIcon="circle-exclamation" />
              </Tooltip>
            ) : null}
          </div>
        </div>
        {dataset.description ? (
          <div className={styles.datasetDescription}>{dataset.description}</div>
        ) : null}
        <CustomerPermissionsSummary permissions={dataset.permissions} />
      </div>
      {additionalRightSideButtonContainer}
    </div>
  );
};
