import { Layout } from '@explo-tech/react-grid-layout';
import { AnyAction, createAction, createAsyncThunk, Dispatch, ThunkAction } from '@reduxjs/toolkit';

import { Timezones } from '@explo/data';

import { createApiRequestConfig, defineAPIAction, defineAPIPostAction } from 'actions/actionUtils';
import { ACTION } from 'actions/types';
import { ChartColumnInfo } from 'constants/types';
import { EmbedDashboard } from 'types/dashboardTypes';
import { Breadcrumb, LatestVersionInfo, ResourcePageType } from 'types/exploResource';
import { makeThunkRequest } from 'utils/thunkUtils';

import { DashboardVersion } from '../types/dashboardVersion';

import { ReduxState } from 'reducers/rootReducer';
import { isSuccess } from 'remotedata';
import { deleteCurrentDashboardDraft } from './dashboardV2Actions';
import { Dataset } from './datasetActions';
import { INITIAL_DASHBOARD_VERSION_NUMBER } from 'constants/versionConstants';
import { deleteAllDrilldownEntryPointsWithDestination } from 'reducers/dashboardEditConfigReducer';
import { saveResourceConfig } from 'reducers/thunks/resourceSaveThunks';
import { EVENTS, trackEvent } from 'telemetry/exploAnalytics';
import { sendPing } from './pingActions';
import { getChildDashboardDeletedMessage } from 'pages/dashboardPage/LayersPanel/pingMessages';
import { PingTypes } from 'constants/pingTypes';

// TYPES
type DashboardCachingConfigOptions = {
  is_cache_enabled?: boolean;
  cache_cron?: string;
};

export interface Dashboard extends EmbedDashboard, DashboardCachingConfigOptions {
  team_id: number;
  embed_id: string;
  disabled?: boolean;
  share_link_title: string | null;
  dashboard_attributes: { attribute_id: number; value_id: number }[];
  latest_versions: LatestVersionInfo[];
  entry_id: number;
}

// This is the customers config of an editable section of a dashboard
export interface CustomerEditableSection {
  config: CustomerEditableSectionConfig;
  id: number;
  edit_version_number: number;
}

// While we only need layout right now.
// Leaving this as an interface for future expansion
export interface CustomerEditableSectionConfig {
  // Charts are created from EditableSectionConfig.charts
  layout: CustomerEditableSectionLayout[];
}

export interface CustomerEditableSectionLayout extends Layout {
  template_id?: string; // ID of the template this chart is created from
}

// REQUESTS

type DashboardHierarchyResponse = {
  root_dashboard_id: string;
  dashboards: Record<string, Dashboard>;
};

export type DashboardVersionHierarchyResponse = Record<string, DashboardVersion>;

type FetchDashboardData = {
  dashboard_version: DashboardVersion;
  dashboard: Dashboard;
  breadcrumbs: Breadcrumb[]; // inclusive of root folder to current folder context
  fido_token?: string;
  // TODO(zifanxiang/tarastentz): Remove this once we completely rollout drilldowns.
  dashboard_hierarchy?: DashboardHierarchyResponse;
  dashboard_version_hierarchy?: DashboardVersionHierarchyResponse;
};

export const {
  actionFn: fetchDashboard,
  requestAction: fetchDashboardRequest,
  successAction: fetchDashboardSuccess,
  errorAction: fetchDashboardError,
} = defineAPIAction<FetchDashboardData>(
  ACTION.FETCH_DASHBOARD,
  'dashboards',
  'get_dashboard_template',
  'GET',
);

export const { actionFn: createDashboard, successAction: createDashboardSuccess } =
  defineAPIPostAction<{ name: string; parent_id: number }, { new_dashboard_template: Dashboard }>(
    ACTION.CREATE_DASHBOARD,
    'dashboards',
    'create_dashboard_template',
    'POST',
  );

export const deleteDashboardThunk =
  (
    dashboardId: number,
    isRoot: boolean,
    onSuccess: () => void,
  ): ThunkAction<void, ReduxState, void, AnyAction> =>
  (dispatch, getState) => {
    const state = getState();

    const dashboardHierarchy = state.dashboard.dashboardHierarchy;
    const currentDashboard = state.dashboard.currentDashboard;
    const dashboardName = isSuccess(dashboardHierarchy)
      ? dashboardHierarchy.data.dashboards[dashboardId].name
      : isSuccess(currentDashboard)
        ? currentDashboard.data.name
        : '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatchAny = dispatch as Dispatch<any>;
    const onSuccessWrapper = () => {
      trackEvent(EVENTS.DELETED_DASHBOARD, {
        dashboard_template_id: dashboardId,
        is_root: isRoot,
      });
      if (!isRoot) {
        dispatchAny(
          sendPing({
            postData: {
              message: getChildDashboardDeletedMessage(state.currentUser, dashboardName),
              message_type: PingTypes.PING_DASHBOARD_DRILLDOWN,
            },
          }),
        );
      }
      onSuccess();
    };

    if (isRoot) {
      dispatchAny(deleteDashboard({ id: dashboardId }, onSuccessWrapper));
    } else {
      const versionHierarchy = state.dashboardEditConfig.versionHierarchy;
      const dashboardHierarchy = state.dashboard.dashboardHierarchy;
      if (!isSuccess(versionHierarchy) || !isSuccess(dashboardHierarchy)) {
        return;
      }

      // Delete all drilldown entry points that point to this child dashboard.
      const parentDashboardId =
        dashboardHierarchy.data.dashboards[dashboardId].parentDashboardId ?? 0;
      const parentDashboardVersionConfig =
        versionHierarchy.data.dashboardVersions[parentDashboardId]?.configuration;
      if (!parentDashboardVersionConfig) {
        return;
      }

      dispatch(
        deleteAllDrilldownEntryPointsWithDestination({
          dashboardId: parentDashboardId,
          destinationDashboardId: dashboardId,
        }),
      );
      dispatch(saveResourceConfig(ResourcePageType.EXPLORE, parentDashboardId));

      const versionNumber = versionHierarchy.data.dashboardVersions[dashboardId].version_number;
      if (versionNumber === INITIAL_DASHBOARD_VERSION_NUMBER) {
        // If the child dashboard is on the first version, completely delete the dashboard since
        // there are no versions where this child dashboard still needs to be accessible from.
        dispatchAny(deleteDashboard({ id: dashboardId }, onSuccess));
      } else {
        // Remove the child dashboard's current draft. We cannot delete the dashboard completely
        // since the child dashboard can be restored by a reversion to a previous version where the
        // child dashboard is still present.
        const args = {
          id: dashboardId,
          postData: {
            version_number: versionNumber,
            is_root: false,
          },
        };
        dispatchAny(deleteCurrentDashboardDraft(args, onSuccessWrapper));
      }
    }
  };

export const { actionFn: deleteDashboard, successAction: deleteDashboardSuccess } = defineAPIAction(
  ACTION.DELETE_DASHBOARD,
  'dashboards',
  '',
  'DELETE',
);

type ActivateDisabledDashboardPostData = { disable_dashboard_id: number };

export const {
  actionFn: activateDisabledDashboard,
  successAction: activateDisabledDashboardSuccess,
} = defineAPIPostAction<ActivateDisabledDashboardPostData, {}>(
  ACTION.ACTIVATE_DISABLED_DASHBOARD,
  'dashboards',
  'activate',
  'POST',
);

export const { actionFn: fetchDashboardList, successAction: fetchDashboardListSuccess } =
  defineAPIAction<{ dashboard_template_list: Dashboard[] }>(
    ACTION.FETCH_DASHBOARD_LIST,
    'dashboards',
    'get_dashboard_template_list',
    'GET',
  );

export const { actionFn: renameDashboard, successAction: renameDashboardSuccess } =
  defineAPIPostAction<{ name: string }, { name: string }>(
    ACTION.RENAME_DASHBOARD,
    'dashboards',
    'rename_dashboard_template',
    'POST',
  );

export type UpdateDefaultTimezoneBody = {
  default_timezone: Timezones;
};

export const {
  actionFn: updateDashboardDefaultTimezone,
  successAction: updateDashboardDefaultTimezoneSuccess,
} = defineAPIPostAction<UpdateDefaultTimezoneBody, {}>(
  ACTION.UPDATE_DASHBOARD_DEFAULT_TIMEZONE,
  'dashboards',
  'update_default_timezone',
  'POST',
);

type UpdateDashboardDisableFiltersWhileLoadingBody = {
  disable_filters_while_loading: boolean;
};

export const {
  actionFn: updateDashboardDisableFiltersWhileLoading,
  successAction: updateDisableFiltersWhileLoadingSuccess,
} = defineAPIPostAction<UpdateDashboardDisableFiltersWhileLoadingBody, {}>(
  ACTION.UPDATE_DISABLE_FILTERS_WHILE_LOADING,
  'dashboards',
  'update_dashboard_disable_filters_while_loading',
  'POST',
);

export const {
  actionFn: updateValuesShareColorsAcrossDashboard,
  successAction: updateValuesShareColorsAcrossDashboardSuccess,
} = defineAPIPostAction<{ should_values_share_colors_across_dashboard: boolean }, {}>(
  ACTION.UPDATE_SHOULD_VALUES_SHARE_COLORS_ACROSS_DASHBOARD,
  'dashboards',
  'update_should_values_share_colors_across_dashboard',
  'POST',
);

export const {
  actionFn: updateDashboardStatePersistance,
  successAction: updateDashboardStatePersistanceSuccess,
} = defineAPIPostAction<{ should_persist_customer_state: boolean }, {}>(
  ACTION.UPDATE_DASHBOARD_STATE_PERSISTANCE,
  'dashboards',
  'update_dashboard_state_persistance',
  'POST',
);

export const {
  actionFn: updateDashboardCacheConfig,
  successAction: updateDashboardCacheConfigSuccess,
} = defineAPIPostAction<DashboardCachingConfigOptions, {}>(
  ACTION.UPDATE_DASHBOARD_CACHE_CONFIG,
  'dashboards',
  'update_caching_config',
  'POST',
);

export const { actionFn: getEditableSectionUsage } = defineAPIAction<{
  count: number;
  customer_names: string[];
}>(ACTION.GET_EDITABLE_SECTION_USAGE, 'dashboards', 'get_editable_section_usage', 'GET');

// Share Dashboard Actions

type SaveShareLinkTitleBody = {
  share_link_title: string;
};

export const { actionFn: saveShareLinkTitle, requestAction: saveShareLinkTitleRequest } =
  defineAPIPostAction<SaveShareLinkTitleBody, {}>(
    ACTION.SAVE_SHARE_LINK_TITLE,
    'dashboards',
    'save_share_link_title',
    'POST',
  );

export interface CreateChildDashboardTemplateData {
  new_child_dashboard_template: Dashboard;
}

export const {
  actionFn: createChildDashboardTemplate,
  requestAction: createChildDashboardRequest,
  successAction: createChildDashboardSuccess,
  errorAction: createChildDashboardError,
} = defineAPIPostAction<
  { name: string; parent_dashboard_id: number; user_id: number },
  CreateChildDashboardTemplateData
>(ACTION.CREATE_CHILD_DASHBOARD, 'dashboards', 'create_child_dashboard_template', 'POST');

type CreateDrilldownEntryPointPayload = {
  sourceDashboardId: number;
  sourceDataPanelId: string;
  // The columns on the chart that must be selected for this drilldown to be available to be drilled
  // into. The ordering here of the  source columns matters and will start with the category columns
  // and then group by columns if any.
  // E.g. for a group bar chart, the first source column will be its category (x-axis) column and
  // the second source column will be its group by column.
  sourceColumns: ChartColumnInfo[];
  entryPointId: string;
  destinationDashboardId: number;
};

export const createDrilldownEntryPoint = createAction<CreateDrilldownEntryPointPayload>(
  ACTION.CREATE_DRILLDOWN_ENTRY_POINT,
);

type DeleteDrilldownEntryPointPayload = {
  // The dashboard that the chart with the drilldown entry point is on.
  dashboardId: number;
  sourceDataPanelId: string;
  drilldownEntryPointId: string;
};

export const deleteDrilldownEntryPoint = createAction<DeleteDrilldownEntryPointPayload>(
  ACTION.DELETE_DRILLDOWN_ENTRY_POINT,
);

export const getDashboardDatasets = createAsyncThunk<{ datasets: Record<string, Dataset> }, number>(
  ACTION.GET_DASHBOARD_DATASETS,
  async (dashboardId: number) => {
    return makeThunkRequest(
      createApiRequestConfig(`dashboards/${dashboardId}/get_datasets/`, 'GET', undefined),
      'Error fetching datasets',
    );
  },
);

export const { actionFn: updateDashboardBillable, successAction: updateDashboardBillableSuccess } =
  defineAPIPostAction<{ value: number }, { dashboard_template_list: number[] }>(
    ACTION.UPDATE_DASHBOARD_BILLABLE,
    'dashboards',
    'update_billable',
    'POST',
  );
