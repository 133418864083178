import { SELECT_ELEMENT_SET } from 'constants/dashboardConstants';
import { DashboardElement, SelectElemConfig } from 'types/dashboardTypes';
import { DataPanel } from 'types/exploResource';
import { keyBy } from 'utils/standard';

import { ReadAccessComputedView } from 'utils/fido/fidoShimmedTypes';
import { Dataset } from 'actions/datasetActions';
import { convertViewToDatasetSansParentSchema } from 'pages/dashboardPage/dashboardDatasetEditor/utils';
import { getDataPanelDatasetId } from './exploResourceUtils';
import { getSelectFilterDatasetId } from './filterUtils';
import { getDatasetName } from './naming';

export function getDataPanelsUsingDataset(dataPanels: DataPanel[], datasetId: string): string[] {
  return dataPanels
    .filter((dp) => datasetId === getDataPanelDatasetId(dp))
    .map((dp) => dp.provided_id);
}

export function getDataPanelIdsUsingDataset(dataPanels: DataPanel[], datasetId: string): string[] {
  return dataPanels
    .filter((dataPanel) => getDataPanelDatasetId(dataPanel) === datasetId)
    .map((dataPanel) => dataPanel.id);
}

export function getElementsUsingDataset(
  elements: DashboardElement[],
  datasetId: string,
  elementId?: string,
) {
  return elements
    .filter((elem) => {
      if (!SELECT_ELEMENT_SET.has(elem.element_type) || elem.id === elementId) return false;

      return getSelectFilterDatasetId(elem.config as SelectElemConfig) === datasetId;
    })
    .map((element) => element.name);
}

export const getDatasetsByName = (datasets: Record<string, Dataset>): Record<string, Dataset> =>
  keyBy(Object.values(datasets), (dataset) => getDatasetName(dataset, false));

export const getGlobalDatasetsByName = (
  referencedGlobalDatasets: Record<string, ReadAccessComputedView>,
): Record<string, ReadAccessComputedView> => {
  const globalDatasetReferencesNameMap: Record<string, ReadAccessComputedView> = {};
  Object.values(referencedGlobalDatasets || {}).forEach((globalDataset) => {
    globalDatasetReferencesNameMap[globalDataset.name] = globalDataset;
  });
  return globalDatasetReferencesNameMap;
};

export const getDatasetNamesToId = (
  datasets: Record<string, Dataset>,
  referencedGlobalDatasets: Record<string, ReadAccessComputedView>,
): Record<string, string> => {
  const datasetNamesToId: Record<string, string> = {};
  Object.values(datasets).forEach((dataset) => {
    datasetNamesToId[getDatasetName(dataset, false)] = dataset.id;
  });
  Object.values(referencedGlobalDatasets).forEach((globalDataset) => {
    if (!globalDataset.name || !globalDataset.id) {
      return;
    }
    datasetNamesToId[globalDataset.name] = globalDataset.id;
  });
  return datasetNamesToId;
};

export const convertGlobalDatasetsAndCombineWithLocal = (
  datasets: Record<string, Dataset>,
  referencedGlobalDatasets: Record<string, ReadAccessComputedView>,
): Record<string, Dataset> => {
  const convertedGlobalDatasets = Object.values(referencedGlobalDatasets).reduce(
    (convertedGlobalDatasets, globalDataset) => {
      const dataset = convertViewToDatasetSansParentSchema({
        ...globalDataset,
        id: globalDataset.id ?? '',
        namespaceId: globalDataset.namespaceId ?? '',
      });
      convertedGlobalDatasets[globalDataset.id ?? ''] = dataset;
      return convertedGlobalDatasets;
    },
    {} as Record<string, Dataset>,
  );

  return {
    ...datasets,
    ...convertedGlobalDatasets,
  };
};
