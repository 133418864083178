import { CellProps, TypeColumn } from '@trust-kaz/reactdatagrid-enterprise/types';
import cx from 'classnames';
import Color from 'color';
import { MutableRefObject, useMemo } from 'react';

import { DatasetDataObject } from 'actions/datasetActions';
import { TableProgressBar } from 'components/TableProgressBar';
import { defaultFormatCellData } from 'components/dataTable/utils';
import { Icon, sprinkles, vars } from 'components/ds';
import { EmbedCategoryTag } from 'components/embed';
import { RowLevelActionsCell } from 'components/embed/EmbedDataGrid/RowLevelActionCell';

import { mixColors } from 'utils/general';
import { getGradientColor, isGradientEnabled } from 'utils/gradientUtils';
import { resolveNumberInputWithVariables } from 'utils/variableUtils';

import {
  BaseCol,
  BOOLEAN,
  BooleanDisplayOptions,
  ColumnConfigs,
  ColumnConfigTypes,
  DatasetRow,
  DatasetSchema,
  DATE_TYPES,
  DateDisplayOptions,
  DECIMAL_TYPES,
  getCurrentStringFormat,
  ImageShapeFormat,
  MENU,
  NUMBER_TYPES,
  NumberDisplayDisplayType,
  NumberDisplayOptions,
  SchemaDisplayOption,
  STRING,
  StringDisplayFormat,
  StringDisplayOptions,
} from '@explo/data';
import { ColumnColorTracker, RowLevelAction } from 'constants/types';
import { formatDateField, formatNumberValue } from 'pages/dashboardPage/charts/utils';
import { DashboardVariableMap, MetricsByColumn, NumberColumnMetrics } from 'types/dashboardTypes';
import { getColorFromPaletteTracker } from 'utils/colorCategorySyncUtils';
import { getCurrentBooleanIcons, getLinkInfo } from 'utils/formatConfigUtils';
import { getColTypeForJoinTable } from 'utils/joinTableUtils';
import { ColumnHeader } from './ColumnHeader';
import * as styles from './index.css';
import { getCellAlignment, getFlexAlignments } from './utils';

/**
 * Util for getting cell data and type based on join config
 *
 * @param value The data to render
 * @param type The type of the column the cell is in
 * @param datasetData The dataset data to use for joining
 * @param displayOptions The join config for the column
 * @param row The row to join against
 *
 * @returns { cellData, colType } The formatted cell data and type
 */
const getCellDataAndType = ({
  value,
  type,
  datasetData,
  displayOptions,
  row,
}: {
  value: string | number;
  type: string;
  datasetData?: DatasetDataObject;
  displayOptions: SchemaDisplayOption;
  row?: DatasetRow;
}) => {
  const joinMapping: Record<string, Record<string, Record<string | number, string | number>>> = {};

  let cellData = value;
  let colType = type;

  if (datasetData && row) {
    const joinedNonsense = getColTypeForJoinTable(
      displayOptions,
      joinMapping,
      datasetData,
      [row],
      0,
    );

    if (joinedNonsense) {
      cellData = joinedNonsense.cellData;
      colType = joinedNonsense.colType;
    }
  }

  return { cellData, colType };
};

type CellStyleArgs = {
  cellData: string | number; // The data to render
  colType: string; // The type of the column the cell is in
  metrics?: NumberColumnMetrics; // The metrics for the column
  displayOptions?: SchemaDisplayOption; // The formatting config to apply
  datasetNamesToId?: Record<string, string>;
  datasetData?: DatasetDataObject;
  variables?: DashboardVariableMap; // The variables to use for gradient calculation
};

export type CellStyleOverrides = {
  backgroundColor?: string;
  color: string;
};

// Util for getting cell styles based on column configs
export const getCellStyles = (args: CellStyleArgs): CellStyleOverrides | undefined => {
  if (!args.displayOptions || args.cellData === null) return;

  if (NUMBER_TYPES.has(args.colType)) {
    const displayOptions = args.displayOptions as NumberDisplayOptions;
    return getNumberCellStyles({ ...args, displayOptions });
  } else if (args.colType === BOOLEAN) {
    const colors = (args.displayOptions as BooleanDisplayOptions).colorCell;
    if (!colors) return { color: vars.customTheme.colors.action };
    return String(args.cellData) === 'true'
      ? {
          backgroundColor: colors.trueBackgroundColor,
          color: colors.trueTextColor ?? vars.customTheme.colors.action,
        }
      : {
          backgroundColor: colors.falseBackgroundColor,
          color: colors.falseTextColor ?? vars.customTheme.colors.action,
        };
  }

  return;
};

const getNumberCellStyles = ({
  cellData,
  displayOptions,
  metrics,
  datasetData,
  datasetNamesToId,
  variables,
}: CellStyleArgs & { displayOptions: NumberDisplayOptions }): CellStyleOverrides | undefined => {
  const { gradient, gradientType, gradientOptions, displayType } = displayOptions;

  const value = Number(cellData);
  if (
    displayType === NumberDisplayDisplayType.PROGRESS_BAR ||
    !isGradientEnabled(gradientType) ||
    isNaN(value)
  )
    return;

  const backgroundColor = getGradientColor({
    value,
    gradient,
    gradientType,
    gradientOptions,
    metrics,
    datasetData,
    datasetNamesToId,
    variables,
  });
  const color = new Color(backgroundColor).isDark() ? vars.colors.white : vars.colors.black;

  return { backgroundColor, color };
};

/**
 * Util for generating number cells
 *
 * @param cellData The data to render
 * @param displayOptions The formatting config to apply
 * @param metrics The metrics for the column for progress bars
 * @param row The row the cell is in
 * @param datasets Mapping of datasets available to this table
 * @param variables The variables to use for formatting
 *
 * @returns Formatted number value to render in cell
 */
type NumberCellProps = {
  cellData: string | number;
  displayOptions: NumberDisplayOptions;
  row?: DatasetRow;
  metrics?: NumberColumnMetrics;
  datasetNamesToId?: Record<string, string>;
  datasetData?: DatasetDataObject;
  variables?: DashboardVariableMap;
  isFloatCol: boolean;
  isFooterCell?: boolean;
};
export const renderNumberCell = ({
  cellData,
  displayOptions,
  metrics,
  row,
  datasetData,
  datasetNamesToId,
  variables,
  isFloatCol,
  isFooterCell,
}: NumberCellProps): string | JSX.Element => {
  const value = Number(cellData);
  const {
    goal,
    useColumnMaxForGoal,
    gradientType,
    gradient,
    gradientOptions,
    displayType,
    displayTypeOptions,
    disableHoverTooltip,
  } = displayOptions;
  const goalOption = useColumnMaxForGoal
    ? metrics?.max
    : resolveNumberInputWithVariables(goal, variables, datasetNamesToId, datasetData);

  const formattedValue = formatNumberValue(displayOptions, value, goalOption, isFloatCol);

  if (displayType === NumberDisplayDisplayType.PROGRESS_BAR && !isFooterCell) {
    let progressBarGoal: number;
    if (displayTypeOptions?.useOtherColumnAsMax && displayTypeOptions?.goalColumnName) {
      progressBarGoal = row?.[displayTypeOptions.goalColumnName] as number;
    } else {
      progressBarGoal = (
        displayTypeOptions?.useColumnMaxForProgressBarGoal
          ? metrics?.max
          : resolveNumberInputWithVariables(
              displayTypeOptions?.progressBarGoal,
              variables,
              datasetNamesToId,
              datasetData,
            )
      ) as number;
    }

    const gradientColor = isGradientEnabled(gradientType)
      ? getGradientColor({
          value,
          gradient,
          gradientType,
          gradientOptions,
          metrics,
          datasetNamesToId,
          datasetData,
          variables,
        })
      : undefined;

    const backgroundColor = gradientColor
      ? mixColors(gradientColor, '#fff', 0.5).rgb().string()
      : vars.colors.activeSubdued;
    const color = gradientColor ?? vars.colors.active;
    return (
      <TableProgressBar
        backgroundColor={backgroundColor}
        color={color}
        disableTooltip={disableHoverTooltip}
        formattedValue={formattedValue}
        progressBarGoal={progressBarGoal}
        value={value}
        valueTextWidth={displayTypeOptions?.valueTextWidth}
      />
    );
  }

  return formattedValue;
};

interface RenderStringCellParams {
  cellData: string;
  column: BaseCol;
  row?: DatasetRow;
  displayOptions: StringDisplayOptions;
  colorTracker?: ColumnColorTracker;
}

/**
 * Util for generating string cells
 *
 * @param {string} cellData The data to render for the cell
 * @param {DatasetColumn} column The data about the column the cell is in
 * @param {DatasetRow[]} rows The rows for the entire data grid
 * @param {StringDisplayOptions} displayOptions The formatting config to apply
 *
 * @returns {ReactNode | string} The ReactNode or string to render in the cell
 */
const renderStringCell = ({
  cellData,
  column,
  row,
  displayOptions,
  colorTracker,
}: RenderStringCellParams) => {
  const { categoryColorAssignments, addedCategories, imageShape } = displayOptions;
  const stringFormat = getCurrentStringFormat(displayOptions);
  const cellDataString = String(cellData);

  switch (stringFormat) {
    case StringDisplayFormat.CATEGORY: {
      const addedColor = addedCategories?.find((c) => c.name === cellDataString);
      const assignmentColor = categoryColorAssignments?.[cellData];
      const trackedColor = getColorFromPaletteTracker({
        columnName: column.name,
        valueName: cellData,
        colorTracker,
      });
      const backgroundColor = addedColor?.color || assignmentColor || trackedColor;

      return (
        <div
          className={cx(
            sprinkles({
              display: 'flex',
              justifyContent: getFlexAlignments(displayOptions, column.type),
            }),
          )}>
          <EmbedCategoryTag categoryColor={backgroundColor}>{cellDataString}</EmbedCategoryTag>
        </div>
      );
    }
    case StringDisplayFormat.LINK: {
      if (!cellDataString) return null;
      const { urlLabel, linkColor, target } = getLinkInfo(displayOptions, row);

      return (
        <a
          href={cellDataString}
          rel="noopener noreferrer"
          style={{ color: linkColor }}
          target={target}>
          {urlLabel}
        </a>
      );
    }
    case StringDisplayFormat.IMAGE:
      return (
        <div className={styles.cellImageContainer}>
          <img
            alt="table cell view"
            className={cx(styles.cellImageDisplay, {
              [styles.circleImage]: imageShape === ImageShapeFormat.CIRCLE,
            })}
            src={cellDataString}
          />
        </div>
      );
  }
  return cellDataString;
};

/**
 * Util for generating boolean cells
 *
 * @param {string} cellData The data to render
 * @param {BooleanDisplayOptions} displayOptions The formatting config to apply
 *
 * @returns {ReactNode} The icon to render in the cell
 */
const BOOL_SET = new Set(['true', 'false']);
const renderBooleanCell = (cellData: string, displayOptions: BooleanDisplayOptions) => {
  const cellDataString = String(cellData);
  if (!BOOL_SET.has(cellDataString)) return 'N/A';

  const { trueIcon, falseIcon } = getCurrentBooleanIcons(displayOptions);

  return <Icon name={cellDataString === 'true' ? trueIcon : falseIcon} />;
};

type GenerateDataGridColumnParams = Omit<UseColumnsParams, 'columns'> & {
  columnInfo: BaseCol;
  rows: DatasetRow[];
  containerRef?: MutableRefObject<HTMLElement | null>;
  ignoreInvalidDates?: boolean;
};

export interface RenderCellParams {
  config?: ColumnConfigTypes;
  columnInfo: BaseCol;
  datasetNamesToId?: Record<string, string>;
  datasetData?: DatasetDataObject;
  row?: DatasetRow;
  metrics?: NumberColumnMetrics;
  variables?: DashboardVariableMap;
  colorTracker?: ColumnColorTracker;
  cellProps?: CellProps;
  value: string | number | undefined;
  ignoreInvalidDates?: boolean;
  isFooterCell?: boolean;
}

function renderRowLevelActionsCell(
  rowLevelActions: RowLevelAction[],
  row: DatasetRow,
  disableActiveCell?: () => void,
  containerRef?: MutableRefObject<HTMLElement | null>,
) {
  return (
    <RowLevelActionsCell
      containerRef={containerRef}
      disableActiveCell={disableActiveCell}
      row={row}
      rowLevelActions={rowLevelActions}
    />
  );
}

export function renderCell({
  config,
  columnInfo,
  datasetData,
  datasetNamesToId,
  row,
  metrics,
  variables,
  colorTracker,
  cellProps,
  value,
  ignoreInvalidDates,
  isFooterCell,
}: RenderCellParams) {
  if (value == null) return '';

  const displayOptions = config?.displayFormatting;
  if (!displayOptions)
    return defaultFormatCellData(value, columnInfo, undefined, ignoreInvalidDates);

  const { cellData, colType } = getCellDataAndType({
    value,
    type: columnInfo.type,
    displayOptions,
    datasetData,
    row,
  });

  if (colType === MENU) return '';

  if (cellProps) {
    const cellStyle = getCellStyles({
      cellData,
      colType,
      metrics,
      displayOptions,
      datasetData,
      datasetNamesToId,
      variables,
    });
    if (cellStyle) cellProps.style = { ...cellProps.style, ...cellStyle };
  }

  if (DATE_TYPES.has(colType)) {
    return formatDateField(
      String(cellData),
      colType,
      displayOptions as DateDisplayOptions,
      ignoreInvalidDates,
      true,
    );
  } else if (NUMBER_TYPES.has(colType)) {
    return renderNumberCell({
      cellData,
      displayOptions: displayOptions as NumberDisplayOptions,
      metrics,
      row,
      datasetData,
      datasetNamesToId,
      variables,
      isFloatCol: DECIMAL_TYPES.has(colType),
      isFooterCell,
    });
  } else if (colType === STRING) {
    return renderStringCell({
      cellData: String(cellData),
      column: columnInfo,
      row,
      displayOptions: displayOptions as StringDisplayOptions,
      colorTracker,
    });
  } else if (colType === BOOLEAN) {
    return renderBooleanCell(String(cellData), displayOptions as BooleanDisplayOptions);
  }

  return String(cellData);
}

/**
 * Util for calculating formatted grid columns. Iterates over schema and generates the reactdatagrid column format needed.
 * Render is determined by columnConfigs, otherwise defaults based on columnInfo
 */
export function generateDataGridColumn({
  columnConfigs,
  columnInfo,
  datasetData,
  datasetNamesToId,
  rows,
  colorTracker,
  selectedColumnId,
  metricsByColumn,
  variables,
  onColumnSelect,
  rowLevelActions,
  disableActiveCell,
  containerRef,
  ignoreInvalidDates,
}: GenerateDataGridColumnParams): TypeColumn {
  const { name, friendly_name, type } = columnInfo;
  const config = columnConfigs?.[name];
  const isRowLevelActionCell = type === MENU;

  const selected = selectedColumnId === name;
  const selectedClassName = cx({ [sprinkles({ backgroundColor: 'activeSubdued' })]: selected });

  const flexAlignment = getFlexAlignments(config?.displayFormatting, type);

  let className = undefined;
  if (type === STRING && config?.displayFormatting) {
    const format = (config.displayFormatting as StringDisplayOptions).format;
    if (format === StringDisplayFormat.IMAGE) className = styles.cellImageContent;
  }

  return {
    name,
    defaultFlex: 1,
    minWidth: 100,
    textAlign: getCellAlignment(config?.displayFormatting, type),
    headerProps: {
      className: selectedClassName,
      style: {
        paddingLeft: 0, // Remove strange left padding there by default in Data Grid
      },
    },
    style: {
      backgroundColor: selected ? vars.colors.activeSubdued : undefined,
      justifyContent: flexAlignment,
    },
    className,
    renderHeader: (cellProps) => (
      <ColumnHeader
        {...cellProps}
        alignment={flexAlignment}
        friendlyName={friendly_name}
        onClick={onColumnSelect}
      />
    ),
    render: ({
      cellProps,
      rowIndex,
      value,
    }: {
      cellProps: CellProps;
      rowIndex: number;
      value: string;
    }) =>
      isRowLevelActionCell
        ? renderRowLevelActionsCell(
            rowLevelActions ?? [],
            rows[rowIndex],
            disableActiveCell,
            containerRef,
          )
        : renderCell({
            cellProps,
            value,
            config,
            columnInfo,
            datasetData,
            datasetNamesToId,
            row: rows[rowIndex],
            metrics: metricsByColumn?.[name],
            variables,
            colorTracker,
            ignoreInvalidDates,
          }),
  };
}

export type UseColumnsParams = {
  // If provided, will override generated columns
  columns?: TypeColumn[];
  // The formatting config to apply to columns
  columnConfigs?: ColumnConfigs;
  // Datasets for joining tables
  datasetNamesToId?: Record<string, string>;
  datasetData?: DatasetDataObject;
  // The column names and types to render
  schema?: DatasetSchema;
  rows?: DatasetRow[] | null;
  // ID of the selected column, where ID is the name from schema
  selectedColumnId?: string | number;
  onColumnSelect?: (id: string | number) => void;
  colorTracker?: ColumnColorTracker;
  // Metrics from secondary data required for progress bar and gradient cells
  metricsByColumn?: MetricsByColumn;
  variables?: DashboardVariableMap;

  // Row Level Actions Params
  rowLevelActions?: RowLevelAction[];
  disableActiveCell?: () => void;
  ignoreInvalidDates?: boolean;
};

/**
 * Custom hook for calculating formatted grid columns
 * @returns Array of columns to be rendered by reactdatagrid
 */
export const useColumns = (params: UseColumnsParams) => {
  const gridColumns = useMemo<TypeColumn[]>(() => {
    const { columns, schema, rows, ...rest } = params;
    if (columns) return columns;
    if (!schema || !rows) return [];

    return schema.map((columnInfo) =>
      generateDataGridColumn({
        ...rest,
        rows,
        columnInfo,
      }),
    );
  }, [params]);

  return gridColumns;
};
