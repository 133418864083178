import { OPERATION_TYPES, TWO_DIMENSIONAL_CHART_TYPES } from '@explo/data';

// TODO(zifanxiang/tstenz): Add more operation types as we support them. Starting with just basic
// bar charts.
export const VALID_DRILLDOWN_OPERATION_TYPES: Set<OPERATION_TYPES> = new Set([
  ...TWO_DIMENSIONAL_CHART_TYPES,
]);

// These limit constants must be kept in sync with their corresponding constants in
// dashboard_template.py.
export const MAX_CHILD_DASHBOARDS_PER_PARENT = 3;

export const MAX_DASHBOARD_HIERARCHY_DEPTH = 3;

export const TITLE_CANNOT_BE_EMPTY_ERROR_MESSAGE = 'Title cannot be empty';

export const NO_DATA_PANEL_SELECTED_ERROR_MESSAGE = 'No data panel selected';

export const NO_ENTRY_COLUMNS_SELECTED_ERROR_MESSAGE = 'No entry columns selected';

export const ENTRY_POINT_ALREADY_EXISTS_ERROR_MESSAGE = 'Entry point already exists';

export const DASHBOARD_NAME_ALREADY_EXISTS_ERROR_MESSAGE =
  'Dashboard with the same name already exists in the hierarchy';
