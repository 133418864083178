import cx from 'classnames';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ResizeObserver, { DOMRect } from 'react-resize-observer';

import { BaseCol, CustomerReportFilter, FilterOperator, PivotAgg } from '@explo/data';

import { CustomerReportDataInfo, CustomerReportView } from 'actions/customerReportActions';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { ErrorFallback } from 'components/ds';
import { DASHBOARD_CLASS_NAME, DASHBOARD_LOADED_CLASS_NAME } from 'constants/exportConstants';
import { DataPanel } from 'pages/ReportBuilder/ReportView/DataPanel';
import { DrilldownPanel } from 'pages/ReportBuilder/ReportView/DrilldownPanel';
import { ReportChart } from 'pages/ReportBuilder/ReportView/ReportChart/ReportChart';
import {
  getCurrentView,
  getCurrentViewData,
  updateCurrentView,
  updateDrilldownFilters,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { createFilterThunk } from 'reportBuilderContent/thunks/reportThunks';
import { filterViewParams } from 'reportBuilderContent/thunks/utils';
import { getFilterableColumns, getSortableColumns } from 'utils/customerReportUtils';
import { constructFilterFromDrilldownColumn } from 'utils/dataPanelConfigUtils';
import { debounce } from 'utils/standard';

import {
  getIsFilterEditingEnabled,
  getIsForkingEnabled,
  getIsViewCreationEnabled,
} from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { ReportBuilderFilters } from './Filters';
import { NoDataSelected } from './NoDataSelected';
import * as styles from './ViewContent.css';

type Props = {
  dataInfo: CustomerReportDataInfo;
  view: CustomerReportView;
};

export const ViewContent: FC<Props> = ({ dataInfo, view }) => {
  const dispatch = useDispatch();

  const [height, setHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    versionConfig,
    viewData,
    showTotals,
    canShowTotals,
    totalsOnByDefault,
    isFilterEditingEnabled,
    variables,
    isForkingEnabled,
    isViewCreationEnabled,
  } = useSelector(
    (state: ReportBuilderReduxState) => ({
      versionConfig: state.embeddedReportBuilder.reportBuilderVersion?.config,
      viewData: getCurrentViewData(state.reportEditing),
      showTotals: getCurrentView(state.reportEditing)?.showTotals,
      canShowTotals: state.embeddedReportBuilder.reportBuilderVersion?.config.general?.showTotals,
      totalsOnByDefault:
        state.embeddedReportBuilder.reportBuilderVersion?.config.general?.totalsOnByDefault,
      isForkingEnabled: getIsForkingEnabled(state.embeddedReportBuilder),
      isViewCreationEnabled: getIsViewCreationEnabled(state.embeddedReportBuilder),
      isFilterEditingEnabled: getIsFilterEditingEnabled(state.embeddedReportBuilder),
      variables: state.embeddedReportBuilder.variables,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (canShowTotals && totalsOnByDefault && showTotals == null)
      dispatch(updateCurrentView({ showTotals: true }));
  }, [view.id, dispatch, canShowTotals, totalsOnByDefault, showTotals]);

  const dataset = versionConfig?.datasets[dataInfo.datasetId];
  const schemaInfo = useMemo(() => filterViewParams(view), [view]);
  const hasPivots =
    schemaInfo.columnGroupBys.length || schemaInfo.groupBys.length || schemaInfo.aggs.length;
  const columnConfigs = !dataset || hasPivots ? {} : dataset.columnConfigs;

  const filterableColumns = useMemo(
    () =>
      dataset ? getFilterableColumns(view.aggregations, view.columnOrder, dataset, variables) : [],
    [dataset, view.aggregations, view.columnOrder, variables],
  );

  const sortableColumns = useMemo(
    () => (dataset ? getSortableColumns(dataset, view, variables) : []),
    [dataset, view, variables],
  );

  const handleSelect = useCallback(
    (selection: { column: string; bucket?: PivotAgg; value: string | number | null }[]) => {
      const filters = selection.flatMap(({ column, bucket, value }) => {
        const filterableColumn = filterableColumns.find((col) => col.name === column);
        if (!filterableColumn || filterableColumn.isPostFilter) return [];

        const filterClauses =
          constructFilterFromDrilldownColumn(
            { column: filterableColumn, bucket: bucket ? { id: bucket } : undefined },
            value == null ? 'null' : value,
          ) || [];

        // Convert from Explore FilterClause to RB CustomerReportFilter
        return filterClauses?.map(
          (filter): CustomerReportFilter => ({
            ...filter,
            id: Math.round(Math.random() * 1e9), // Random ID to prevent collisions
            isPostFilter: filterableColumn.isPostFilter,
            filterColumn: filterableColumn,
            filterOperation: filter.filterOperation || { id: FilterOperator.NUMBER_EQ },
          }),
        );
      });

      if (filters.length) dispatch(updateDrilldownFilters(filters));
    },
    [dispatch, filterableColumns],
  );

  const handleFilter = useCallback(
    (column: BaseCol) => {
      const filterableColumn = filterableColumns.find((col) => col.name === column.name);
      if (filterableColumn) {
        const payload = { column, isPostFilter: filterableColumn.isPostFilter };
        dispatch(createFilterThunk(payload));
      }
    },
    [dispatch, filterableColumns],
  );

  const handleResize = useMemo(() => debounce((rect: DOMRect) => setHeight(rect.height), 300), []);

  if (!dataset) return <NoDataSelected datasetDeleted />;

  return (
    <div
      className={cx(styles.viewContainer, { [DASHBOARD_LOADED_CLASS_NAME]: viewData.rows })}
      ref={containerRef}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <div className={styles.reportContainer}>
          <ReportBuilderFilters
            columnConfigs={columnConfigs}
            columns={filterableColumns}
            filters={view.filters}
            sort={view.sort}
            sortableColumns={sortableColumns}
          />
          {/* overflow: hidden is necessary for Highcharts to resize when the layout changes */}
          <div className={cx(DASHBOARD_CLASS_NAME, styles.chartContainer)}>
            <ReportChart
              containerRef={containerRef}
              dataset={dataset}
              onFilter={isFilterEditingEnabled ? handleFilter : undefined}
              onSelect={isFilterEditingEnabled ? handleSelect : undefined}
              reportData={viewData}
              view={view}
            />
          </div>
          <DrilldownPanel
            columnConfigs={dataset.columnConfigs}
            dataInfo={dataInfo}
            height={height}
            view={view}
          />
        </div>
      </ErrorBoundary>
      {isForkingEnabled || isViewCreationEnabled ? (
        <DataPanel dataInfo={dataInfo} view={view} />
      ) : null}
      <ResizeObserver onResize={handleResize} />
    </div>
  );
};
