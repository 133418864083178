import cx from 'classnames';
import produce from 'immer';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { InfoCard } from 'components/InfoCard';
import { SettingHeader } from 'components/SettingHeader';
import { Switch, Checkbox, sprinkles } from 'components/ds';
import { FILTER_ELEMENTS } from 'constants/dashboardConstants';
import { configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';
import { ReduxState } from 'reducers/rootReducer';
import {
  ApplyFilterElemConfig,
  DashboardButtonElemConfig,
  DashboardElementConfig,
} from 'types/dashboardTypes';

import { EmbedButtonConfigPanel } from './EmbedButtonConfigPanel';
import { getCurrentDashboardAllElementsWithDrilldowns } from 'reducers/selectors';

type Props = {
  config: ApplyFilterElemConfig;
  elementId: string;

  updateConfig: (newConfig: DashboardElementConfig) => void;
};

export const ApplyFilterButtonConfigPanel: FC<Props> = ({ config, elementId, updateConfig }) => {
  const elements = useSelector((state: ReduxState) =>
    getCurrentDashboardAllElementsWithDrilldowns(state),
  );

  const updateApplyConfig = (update: (config: ApplyFilterElemConfig) => void) => {
    updateConfig(
      produce(config, (draft) => {
        update(draft);
      }),
    );
  };

  return (
    <div className={sprinkles({ marginX: 'spn1.5' })}>
      <SettingHeader name="General" />
      <div className={sectionClass}>
        <EmbedButtonConfigPanel
          config={config as DashboardButtonElemConfig}
          defaultText="Apply Filters"
          updateConfig={updateConfig}
        />
        <Switch
          className={configInputClass}
          label="Show number of filters blocked"
          onChange={() =>
            updateApplyConfig((draft) => (draft.hideNumFiltersIcon = !draft.hideNumFiltersIcon))
          }
          switchOn={!config.hideNumFiltersIcon}
        />
      </div>
      <SettingHeader name="Input Selection" />
      <div className={sectionClass}>
        <InfoCard
          className={sprinkles({ marginBottom: 'sp1.5', marginX: 'sp1.5' })}
          text="Inputs chosen here will not trigger a dashboard refresh when changed. A user will have to click this button to trigger a refresh."
        />
        <div className={cx(configInputClass, sprinkles({ flexItems: 'column', gap: 'sp1' }))}>
          {Object.values(elements ?? {}).map((element) => {
            if (element.id === elementId || !FILTER_ELEMENTS.has(element.element_type)) return null;

            return (
              <div className={checkboxContainerClass} key={`filter-btn-element-${element.id}`}>
                <Checkbox
                  isChecked={!!config.elementIds?.[element.id]}
                  label={element.name}
                  onChange={() =>
                    updateApplyConfig((draft) => {
                      if (!draft.elementIds) draft.elementIds = {};
                      draft.elementIds[element.id] = !draft.elementIds[element.id];
                    })
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const sectionClass = sprinkles({ marginY: 'sp1.5' });
const checkboxContainerClass = sprinkles({
  flexItems: 'alignCenter',
  gap: 'sp.5',
});
