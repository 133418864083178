import { Branch } from '@explo-tech/fido-api';
import { Button, Input, Modal, sprinkles } from 'components/ds';
import { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createBranchThunk } from 'reducers/thunks/fidoThunks/branchThunks';
import { validateBranchName } from './dataLibraryUtil';

interface Props {
  currentHeadCommitId: string;
  allBranches: Map<string, Branch>;

  onClose: () => void;
}

export const CreateNewBranchModal: FC<Props> = ({ currentHeadCommitId, allBranches, onClose }) => {
  const dispatch = useDispatch();

  const [newBranchName, setNewBranchName] = useState('');

  const allBranchNames = useMemo(
    () => Array.from(allBranches.values()).map((branch) => branch.name),
    [allBranches],
  );

  const errorText = useMemo(() => {
    return validateBranchName(newBranchName, allBranchNames);
  }, [allBranchNames, newBranchName]);

  return (
    <Modal isOpen onClose={onClose} size="medium" title="Create new branch">
      <Input
        className={sprinkles({ marginBottom: 'sp2', marginX: 'sp3' })}
        errorText={errorText}
        label="Branch name"
        onChange={(branchName) => setNewBranchName(branchName)}
        value={newBranchName}
      />
      <div
        className={sprinkles({
          flexItems: 'alignCenter',
          flexDirection: 'row-reverse',
          marginX: 'sp3',
        })}>
        <Button
          disabled={!newBranchName || allBranchNames.includes(newBranchName)}
          onClick={() => {
            dispatch(
              createBranchThunk({
                branch: { name: newBranchName, headId: currentHeadCommitId },
              }),
            );

            setNewBranchName('');
            onClose();
          }}>
          Create
        </Button>
      </div>
    </Modal>
  );
};
