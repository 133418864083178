import { createAsyncThunk } from '@reduxjs/toolkit';
import { createApiRequestConfig } from 'actions/actionUtils';
import { ACTION } from 'actions/types';
import { DashboardStates } from 'reducers/rootReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { createApiRequestConfigWithRequestInfo, makeThunkRequest } from 'utils/thunkUtils';
import {
  FetchAdHocDashboardScreenshotUrlBody,
  FetchAdHocScreenshotUrlResponse,
  FetchCustomerReportScreenshotUrlsBody,
  FetchDataPanelScreenshotUrlBody,
  FetchReportBuilderScreenshotPagesResponse,
  FetchTestScheduledDashboardExportMetadataBody,
  FetchTestScheduledDashboardExportMetadataResponse,
} from './types';

export const fetchAdHocDashboardScreenshotUrlThunk = createAsyncThunk<
  FetchAdHocScreenshotUrlResponse,
  FetchAdHocDashboardScreenshotUrlBody,
  { state: DashboardStates }
>(ACTION.FETCH_AD_HOC_DASHBOARD_SCREENSHOT_URL, async (args, { getState }) => {
  const requestInfo = getState().dashboardLayout.requestInfo;

  const urls = {
    embedUrl: `embed/generate_ad_hoc_dashboard_screenshot_url/`,
    appUrl: `export/generate_ad_hoc_dashboard_screenshot_url/`,
  };

  const requestConfig = createApiRequestConfigWithRequestInfo(urls, 'POST', requestInfo, args);

  return makeThunkRequest(requestConfig, 'Error fetching ad hoc dashboard screenshot url');
});

export const fetchTestScheduledDashboardExportMetadata = createAsyncThunk<
  FetchTestScheduledDashboardExportMetadataResponse,
  FetchTestScheduledDashboardExportMetadataBody,
  { state: DashboardStates }
>(
  ACTION.FETCH_TEST_SCHEDULED_DASHBOARD_EXPORT_METADATA,
  async ({ disable_link, custom_url, customer_id, resource_id }, { getState }) => {
    const requestInfo = getState().dashboardLayout.requestInfo;
    if (requestInfo.type === 'embedded') {
      throw new Error('Should only be called in app context');
    }

    return makeThunkRequest(
      createApiRequestConfig(`export/generate_test_scheduled_dashboard_export_metadata/`, 'POST', {
        disable_link,
        custom_url,
        customer_id,
        resource_id,
        user_group_id: customer_id,
        timezone: requestInfo.timezone,
      }),
      'Error fetching test scheduled dashboard export metadata',
    );
  },
);

export const fetchReportBuilderScreenshotPagesThunk = createAsyncThunk<
  FetchReportBuilderScreenshotPagesResponse,
  FetchCustomerReportScreenshotUrlsBody,
  { state: ReportBuilderReduxState }
>(ACTION.FETCH_REPORT_BUILDER_SCREENSHOT_PAGES, async (params, { getState }) => {
  const { customerToken, embedJwt } = getState().embeddedReportBuilder.requestInfo;

  const requestConfig = createApiRequestConfig(
    'customer_reports/generate_screenshot_pages/',
    'POST',
    params,
    customerToken,
    embedJwt,
  );

  return makeThunkRequest(requestConfig, 'Error fetching customer report screenshot url');
});

export const fetchDataPanelScreenshotUrlThunk = createAsyncThunk<
  FetchAdHocScreenshotUrlResponse,
  FetchDataPanelScreenshotUrlBody,
  { state: DashboardStates }
>(ACTION.FETCH_DATA_PANEL_SCREENSHOT_URL, async (args, { getState }) => {
  const requestInfo = getState().dashboardLayout.requestInfo;

  const urls = {
    embedUrl: `embed/generate_data_panel_screenshot_url/`,
    appUrl: `export/generate_data_panel_screenshot_url/`,
  };

  const requestConfig = createApiRequestConfigWithRequestInfo(urls, 'POST', requestInfo, args);

  return makeThunkRequest(requestConfig, 'Error fetching data panel screenshot url');
});
