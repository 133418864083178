import { createSlice, isAnyOf, Reducer, createSelector } from '@reduxjs/toolkit';

import { createDashboardSuccess, deleteDashboardSuccess } from 'actions/dashboardActions';
import {
  createReportBuilderSuccess,
  deleteReportBuilderSuccess,
} from 'actions/reportBuilderActions';
import {
  searchResourcesRequest,
  searchResourcesError,
  searchResourcesSuccess,
} from 'actions/resourceActions';
import { ReduxState } from 'reducers/rootReducer';
import {
  listDashboardFolderContents,
  listReportFolderContents,
  moveEntry,
  deleteFolder,
  renameFolder,
  createFolder,
  listFolderHierarchy,
} from 'reducers/thunks/resourceThunks';
import * as RD from 'remotedata';
import {
  Breadcrumb,
  Folder,
  FolderHierarchy,
  ResourceSearchResult,
  Resource,
  ResourceType,
} from 'types/exploResource';

import { cloneResourceThunk } from './thunks/versionManagementThunks';
import { ProductType } from '@explo/embeddo-api';

interface FolderReducerState {
  folders: Folder[];
  resourcesStatus: RD.ResponseData<boolean>;
  breadcrumbs: Breadcrumb[]; // inclusive of root folder to current folder context
  folderCount: number;
  resourceCount: number;
  searchResults: ResourceSearchResult[];
  searchResultsStatus: RD.ResponseData<boolean>;
  hierarchy: RD.ResponseData<FolderHierarchy & { type: ProductType }>;
  productType?: ProductType;
}

const folderReducerInitialState: FolderReducerState = {
  folders: [],
  resourcesStatus: RD.Idle(),
  breadcrumbs: [],
  folderCount: 0,
  resourceCount: 0,
  searchResults: [],
  searchResultsStatus: RD.Idle(),
  hierarchy: RD.Idle(),
};

const folderSlice = createSlice({
  name: 'folder',
  initialState: folderReducerInitialState,
  reducers: {
    clearSearch: (state) => {
      state.searchResults = [];
      state.searchResultsStatus = RD.Idle();
    },
    clearFolders: (state) => {
      state.folders = [];
      state.breadcrumbs = [];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createFolder.fulfilled, (state, { payload }) => {
        state.folders.unshift(payload.folder);
        state.hierarchy = RD.Idle();
        state.folderCount += 1;
      })
      .addCase(renameFolder.fulfilled, (state, { payload }) => {
        const folderToRename = state.folders.find((f) => f.id === payload.folder.id);
        if (folderToRename) {
          folderToRename.name = payload.folder.name;
        }
        state.hierarchy = RD.Idle();
      })
      .addCase(moveEntry.fulfilled, (state, { payload }) => {
        // if you move a folder or entry into a folder within view, update that folder's num resources, important for delete disabled on folders
        const newParentFolder = state.folders.find(({ id }) => id == payload.entry.parent_id);
        if (newParentFolder) newParentFolder.num_resources += 1;

        // Every move operation will move a resource out of the parent folder

        if (payload.entry.type !== ResourceType.FOLDER) {
          state.resourceCount = Math.max(0, state.resourceCount - 1);
          return;
        }
        state.folderCount = Math.max(0, state.folderCount - 1);

        state.folders = state.folders.filter(({ id }) => id !== payload.entry.id);
        state.hierarchy = RD.Idle();
      })
      .addCase(deleteFolder.fulfilled, (state, { payload }) => {
        state.folders = state.folders.filter((f) => f.id !== payload.id);
        state.hierarchy = RD.Idle();
        state.folderCount = Math.max(0, state.folderCount - 1);
      })
      .addCase(listFolderHierarchy.pending, (state) => {
        state.hierarchy = RD.Loading();
      })
      .addCase(listFolderHierarchy.rejected, (state) => {
        state.hierarchy = RD.Error('Error getting folder hierarchy');
      })
      .addCase(listFolderHierarchy.fulfilled, (state, { payload }) => {
        state.hierarchy = RD.Success({ ...payload.hierarchy, type: payload.type });
      })
      .addCase(searchResourcesRequest, (state) => {
        state.searchResultsStatus = RD.Loading();
      })
      .addCase(searchResourcesError, (state) => {
        state.searchResultsStatus = RD.Error('Error Loading Searched Resources');
      })
      .addCase(searchResourcesSuccess, (state, { payload }) => {
        state.searchResults = payload.results;
        state.searchResultsStatus = RD.Success(true);
      })
      .addMatcher(
        isAnyOf(createDashboardSuccess, createReportBuilderSuccess, cloneResourceThunk.fulfilled),
        (state) => {
          state.resourceCount += 1;
        },
      )
      .addMatcher(isAnyOf(deleteDashboardSuccess, deleteReportBuilderSuccess), (state) => {
        state.resourceCount = Math.max(0, state.resourceCount - 1);
      })
      .addMatcher(
        isAnyOf(listDashboardFolderContents.pending, listReportFolderContents.pending),
        (state) => {
          state.resourcesStatus = RD.Loading();
        },
      )
      .addMatcher(
        isAnyOf(listDashboardFolderContents.rejected, listReportFolderContents.rejected),
        (state) => {
          state.resourcesStatus = RD.Error('Error Loading Folders');
        },
      )
      .addMatcher(
        isAnyOf(listDashboardFolderContents.fulfilled, listReportFolderContents.fulfilled),
        (state, { payload }) => {
          state.folders = payload.folders;
          state.resourcesStatus = RD.Success(true);
          state.breadcrumbs = payload.breadcrumbs;
          state.productType = payload.type;
          state.folderCount = payload.folder_count;
          state.resourceCount = payload.entry_count;
        },
      ),
});

export const getResources = createSelector(
  (state: ReduxState) => state.dashboard.folderDashboardList,
  (state: ReduxState) => state.reportBuilder.reportBuilders,
  (_: unknown, isExploreProduct: boolean) => isExploreProduct,
  (dashboards, reportBuilders, isExploreProduct) =>
    (isExploreProduct ? dashboards || [] : reportBuilders || []) as Resource[],
);

export const getUniqueNames = createSelector(
  (state: ReduxState) => state.dashboard.currentFolderResourceNames,
  (state: ReduxState) => state.reportBuilder.currentFolderResourceNames,
  (_: unknown, isExploreProduct: boolean) => isExploreProduct,
  (dashboardNames, reportBuilderNames, isExploreProduct) => {
    const names = isExploreProduct
      ? RD.getOrDefault(dashboardNames, new Set<string>())
      : RD.getOrDefault(reportBuilderNames, new Set<string>());

    return new Set(names);
  },
);

export const getResourceParentFolderId = createSelector(
  (state: ReduxState) => state.dashboard.currentDashboard,
  (state: ReduxState) => state.reportBuilder.currentReportBuilder,
  (_: unknown, isExploreProduct: boolean) => isExploreProduct,
  (currentDashboard, currentReportBuilder, isExploreProduct) => {
    const currentResource = isExploreProduct
      ? RD.getOrDefault(currentDashboard, undefined)
      : RD.getOrDefault(currentReportBuilder, undefined);
    const breadcrumbs = currentResource?.breadcrumbs;
    // The last breadcrumb is the current resource, so the second to last
    // breadcrumb is the parent folder of the current resource
    const parentFolder =
      breadcrumbs && breadcrumbs.length >= 2 ? breadcrumbs[breadcrumbs.length - 2] : undefined;
    return parentFolder?.id;
  },
);

export const { clearSearch, clearFolders } = folderSlice.actions;

export const folderReducer = folderSlice.reducer as Reducer<FolderReducerState>;
