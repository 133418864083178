import { QueryExecutionResponse } from '@explo-tech/fido-api';
import { AnyAction, ThunkAction } from '@reduxjs/toolkit';

import { FilterOperationInstructions } from '@explo/data';

import { ReduxState } from 'reducers/rootReducer';
import { SortInfo_DEPRECATED, VisualizeOperation } from 'types/dataPanelTemplate';

// We need to use ReduxState here instead of DashboardStates because we need to
// be able to grab dataPanels, elements, and datasets from different parts of the app
export type DashboardDataThunk = ThunkAction<void, ReduxState, unknown, AnyAction>;

type PrimaryRequestInfo = {
  '@type': 'primary';
  sortInfo: SortInfo_DEPRECATED[] | undefined;
  filterInfo: FilterOperationInstructions | undefined;
  pageNumber: number | undefined;
  visualizeOp: VisualizeOperation;
};

type SecondaryRequestInfo = {
  primaryRequestId: string;
};

type RowCountRequestInfo = SecondaryRequestInfo & {
  '@type': 'row-count';
};

type ColumnTotalsRequestInfo = SecondaryRequestInfo & {
  '@type': 'column-totals';
};

type ProgressBarRequestInfo = SecondaryRequestInfo & {
  '@type': 'progress-bar';
};

type KpiTrendRequestInfo = SecondaryRequestInfo & {
  '@type': 'kpi-trend';
  secondaryDataAgg: string;
};

type RequestInfo =
  | PrimaryRequestInfo
  | RowCountRequestInfo
  | ColumnTotalsRequestInfo
  | KpiTrendRequestInfo
  | ProgressBarRequestInfo;

export type FidoReducerArgs = RequestInfo & {
  dataPanelId: string;
  timezone: string;
};

export type FidoBatchedActionPayload = Array<{
  payload: QueryExecutionResponse;
  meta: {
    arg: {
      reducerArgs: FidoReducerArgs;
    };
    requestId: string;
  };
}>;

export enum FetchOrigin {
  DASHBOARD,
  REPORT_BUILDER,
  DATA_LIBRARY,
}
