import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GlobalStylesProvider } from 'globalStyles';
import { loadFonts } from 'globalStyles/utils';
import { ReduxState } from 'reducers/rootReducer';

import { getCurrentTheme } from '../../reducers/dashboardStylesReducer';
import { getOrDefault, hasNotReturned } from '../../remotedata';

import { Location } from 'history';
import { usePreventNavigationWithUnsavedChanges } from 'utils/hookUtils';
import { DashboardPage } from './dashboardPage';
import { useHistory } from 'react-router';
import { clearDrilldownsState } from 'reducers/drilldownsReducer';
import { Dashboard } from 'actions/dashboardActions';

export const DashboardPageWrapper: FC = () => {
  const history = useHistory<{}>();
  const dispatch = useDispatch();

  const { team, globalStyleConfig, fontConfig, savingInfo, dashboardHierarchy } = useSelector(
    (state: ReduxState) => ({
      fontConfig: state.dashboardStyles.fontConfig,
      globalStyleConfig: getCurrentTheme(state.dashboardStyles),
      team: state.currentUser.team,
      savingInfo: state.resourceSaving,
      dashboardHierarchy:
        getOrDefault(state.dashboard.dashboardHierarchy, undefined)?.dashboards ?? {},
    }),
  );

  useEffect(() => {
    if (!team || hasNotReturned(fontConfig)) return;
    loadFonts(globalStyleConfig.text, getOrDefault(fontConfig, []), team.id);
  }, [fontConfig, globalStyleConfig, team]);

  useEffect(() => {
    const unlisten = history.listen((location: Location<{}>) => {
      if (!isNavigatingToChildDashboard(location, dashboardHierarchy)) {
        dispatch(clearDrilldownsState());
      }

      return undefined;
    });

    return () => {
      unlisten();
    };
  }, [dashboardHierarchy, dispatch, history]);

  usePreventNavigationWithUnsavedChanges(
    savingInfo.resourceSaveStatuses,
    (location: Location<{}>) => {
      const pathName = location.pathname;
      const dashboardId = pathName.split('/')[2];

      if (!dashboardId) {
        return false;
      }

      return isNavigatingToChildDashboard(location, dashboardHierarchy);
    },
  );

  return (
    <GlobalStylesProvider globalStyleConfig={globalStyleConfig}>
      {() => <DashboardPage />}
    </GlobalStylesProvider>
  );
};

const isNavigatingToChildDashboard = (
  location: Location<{}>,
  dashboards: Record<string, Dashboard>,
): boolean => {
  const pathName = location.pathname;
  const dashboardId = pathName.split('/')[2];

  if (!dashboardId) {
    return false;
  }

  const isNavigatingToNewChildDashboard =
    location.state && 'isNavigatingToNewChildDashboard' in location.state
      ? location.state.isNavigatingToNewChildDashboard
      : false;
  if (isNavigatingToNewChildDashboard) {
    return true;
  }

  const dashboardIdsInHierarchy = new Set(Object.keys(dashboards ?? {}));
  return dashboardIdsInHierarchy.has(dashboardId);
};
