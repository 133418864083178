import {
  EmailTypes,
  ImageEmailConfiguration,
  ImageRequestBody,
  LoggingMetadata,
} from '@explo/rover-api';
import { ExportScreenshotType, ExportType } from 'actions/exportActions';
import { DashboardLayoutRequestInfo } from 'reducers/dashboardLayoutReducer';
import { getFromEmail } from 'reducers/thunks/roverThunks/roverUtils';
import { TelemetryReducerState } from 'telemetry/telemetryReducer';

export const getLoggingMetadata = (
  analytics: Pick<TelemetryReducerState, 'visitorId' | 'analyticsProperties'>,
  requestInfo: Pick<DashboardLayoutRequestInfo, 'teamId' | 'teamName'>,
  emailType: EmailTypes,
): LoggingMetadata => ({
  teamId: requestInfo.teamId?.toString() ?? '',
  team: requestInfo.teamName ?? '',
  emailType,
  userId: analytics.visitorId ?? undefined,
  customerId: analytics.analyticsProperties?.['customer_id'] as string | undefined,
});

const createAdHocExploreEmailConfiguration = (
  downloadFileName: string,
  exportType: ExportScreenshotType,
  email: string | undefined,
  displayName: string | undefined,
  emailOverride: string | undefined,
): ImageEmailConfiguration | null => {
  return email
    ? ({
        toEmails: [email],
        subject: `Requested Data Export: ${downloadFileName} ${exportType}`,
        fromEmail: getFromEmail({ emailOverride, displayName }),
        emailContentArgs: { '@type': 'explore-ad-hoc' },
      } as ImageEmailConfiguration)
    : null;
};

export const createAdHocExploreScreenshotExportRequestBody = (
  screenshotUrl: string,
  downloadFileName: string,
  exportType: ExportScreenshotType,
  email: string | undefined,
  analytics: Pick<TelemetryReducerState, 'visitorId' | 'analyticsProperties'>,
  requestInfo: Pick<
    DashboardLayoutRequestInfo,
    'teamId' | 'teamName' | 'emailFromName' | 'emailOverride'
  >,
  emailType: EmailTypes,
): ImageRequestBody => {
  const pages = [{ pageUrl: screenshotUrl, filename: downloadFileName }];

  const emailConfiguration = createAdHocExploreEmailConfiguration(
    downloadFileName,
    exportType,
    email,
    requestInfo.emailFromName,
    requestInfo.emailOverride,
  );

  return {
    pages,
    exportMetadata: {
      exportFormat: exportType === ExportType.PDF ? 'PDF' : 'IMAGE',
      emailConfiguration,
      imageScreenshotWidth: null,
    },
    loggingMetadata: getLoggingMetadata(analytics, requestInfo, emailType),
  };
};
