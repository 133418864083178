import { FC } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { DATABASES } from 'pages/ConnectDataSourceFlow/constants';
import {
  setAuthentication,
  setDataSourceConfig,
} from 'reducers/fidoDataSourceConfigurationReducer';
import { ReduxState } from 'reducers/rootReducer';

import { CredentialsInput } from './CredentialsInput';

export const FidoAthenaCredentialsSection: FC = () => {
  const dispatch = useDispatch();

  const {
    dataSourceConfig: { type: dataSourceType, config: dataSourceConfig, auth: authConfig },
    isUpdating,
  } = useSelector(
    (state: ReduxState) => ({
      dataSourceConfig: state.fidoDataSourceConfig.dataSourceConfig,
      isUpdating: state.fidoDataSourceConfig.isUpdating,
    }),
    shallowEqual,
  );

  if (!dataSourceType || dataSourceType !== DATABASES.ATHENA) {
    return null;
  }

  return (
    <>
      <CredentialsInput
        label="AWS Region"
        optional={isUpdating} // TODO: needed?
        placeholder="Example: us-east-1"
        updateConfig={(region) => dispatch(setDataSourceConfig({ region }))}
        value={dataSourceConfig?.region}
      />
      <CredentialsInput
        helpTooltip="You can find this in the AWS console."
        label="Data Catalog (Data Source)"
        optional={isUpdating}
        placeholder="Example: AwsDataCatalog"
        updateConfig={(dataCatalog) => dispatch(setDataSourceConfig({ dataCatalog }))}
        value={dataSourceConfig?.dataCatalog}
      />
      <CredentialsInput
        helpTooltip="You can find this in the AWS console."
        label="AWS Access Key ID"
        optional={isUpdating}
        updateConfig={(accessKeyId) => dispatch(setAuthentication({ accessKeyId }))}
        value={authConfig?.accessKeyId}
      />
      <CredentialsInput
        helpTooltip="You can find this in the AWS console."
        label="AWS Secret Key"
        optional={isUpdating}
        updateConfig={(secretAccessKey) => dispatch(setAuthentication({ secretAccessKey }))}
        value={authConfig?.secretAccessKey}
      />
      <CredentialsInput
        label="S3 Output Location"
        optional={isUpdating}
        placeholder="Example: s3://query-results-bucket/example/"
        updateConfig={(s3OutputLocation) => dispatch(setDataSourceConfig({ s3OutputLocation }))}
        value={dataSourceConfig?.s3OutputLocation}
      />
      <CredentialsInput
        label="Work Group"
        optional={isUpdating}
        placeholder="Example: primary"
        updateConfig={(workGroup) => dispatch(setDataSourceConfig({ workGroup }))}
        value={dataSourceConfig?.workGroup}
      />
    </>
  );
};
