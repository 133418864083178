import { FC, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { setEditableSectionModal } from 'reducers/dashboardEditConfigReducer';
import { ReduxState } from 'reducers/rootReducer';

import { AddChartModal } from './AddChartModal';
import { DeleteChartModal } from './DeleteChartModal';
import { EditChartInfoModal } from './EditChartInfoModal';
import { EditChartPermissionsModal } from './EditChartPermissionsModal';
import { getRootEditConfigWithDrilldowns } from 'reducers/selectors';

export const EditableSectionModals: FC = () => {
  const dispatch = useDispatch();
  const { modal, charts } = useSelector(
    (state: ReduxState) => ({
      modal: state.dashboardEditConfig.editableSectionModal,
      charts: getRootEditConfigWithDrilldowns(state)?.editable_section?.charts,
    }),
    shallowEqual,
  );

  useEffect(() => {
    return () => {
      dispatch(setEditableSectionModal(null));
    };
  }, [dispatch]);

  switch (modal?.type) {
    case 'AddChart':
      return <AddChartModal />;
    case 'EditChartInfo': {
      const chart = charts?.[modal.chartId];
      if (!chart) return null;
      return <EditChartInfoModal chart={chart} />;
    }
    case 'EditChartPermissions': {
      const chart = charts?.[modal.chartId];
      if (!chart) return null;
      return <EditChartPermissionsModal chart={chart} />;
    }
    case 'DeleteChart': {
      const chart = charts?.[modal.chartId];
      return chart ? <DeleteChartModal chartId={modal.chartId} chartName={chart.name} /> : null;
    }
    default:
      return null;
  }
};
