import cx from 'classnames';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { sprinkles } from 'components/ds';
import { LatestVersionInfo, Resource } from 'types/exploResource';

import { EmailTag } from './EmailTag';
import { LatestVersionTag } from './LatestVersionTag';
import { UpdateDatasetsButton } from './UpdateDatasetsButton';
import * as styles from './styles.css';
import { EmbedTag } from './EmbedTag';

interface Props {
  resource: Resource;
  isDisabled: boolean;
  isLoading: boolean;
  showPreview: boolean;
  onClickUrl: string;
  latestConfig: LatestVersionInfo;
  lastModifiedMessage: string;
  hasEmailState: boolean;
  dotsMenu: JSX.Element | null;
  enableDataLibrary: boolean;
  enableDataLibraryV3: boolean;
  renderResourcePreviewFn: (latestVersion: LatestVersionInfo | undefined) => JSX.Element;
  onClickMigrateDatasetsTag: (resourceId: number) => void;
}

export const ResourceItemListElement: FC<Props> = ({
  resource,
  isDisabled,
  isLoading,
  showPreview,
  onClickUrl,
  latestConfig,
  lastModifiedMessage,
  hasEmailState,
  dotsMenu,
  enableDataLibrary,
  enableDataLibraryV3,
  renderResourcePreviewFn,
  onClickMigrateDatasetsTag,
}) => {
  const history = useHistory();

  return (
    <div
      className={cx(styles.resourceListItem, {
        [styles.disabledResource]: isDisabled,
      })}
      onClick={(e) => {
        if (isDisabled || isLoading) return;
        if (e.metaKey || e.ctrlKey) {
          window.open(onClickUrl, '_blank');
        } else {
          history.push(onClickUrl);
        }
      }}>
      <div className={styles.resourceListColumnGroup}>
        {showPreview ? (
          <div className={styles.listPreviewContainer}>
            <div className={styles.listPreview}>
              {renderResourcePreviewFn(latestConfig as LatestVersionInfo)}
            </div>
          </div>
        ) : null}

        <div className={styles.resourceListName}>{resource.name}</div>
      </div>
      <div className={styles.resourceListEndGroup}>
        <div className={styles.resourceListInfo}>
          {resource.latest_versions.map((latestVersion) => (
            <LatestVersionTag
              isDraft={latestVersion.is_draft}
              key={latestVersion.version_number}
              versionNumber={latestVersion.version_number}
            />
          ))}
          {lastModifiedMessage}
          <EmbedTag resource={resource} />
        </div>
        <div className={styles.emailContainer}>{hasEmailState ? <EmailTag /> : null}</div>

        {enableDataLibrary && enableDataLibraryV3 ? (
          <UpdateDatasetsButton
            className={sprinkles({ marginRight: 'sp2' })}
            onClick={() => onClickMigrateDatasetsTag(resource.id)}
          />
        ) : null}
        {dotsMenu}
      </div>
    </div>
  );
};
