import { Branch, CreateResourceChange, UpdateResourceChange } from '@explo-tech/fido-api';
import { MAIN_BRANCH_NAME } from 'pages/dataLibraryPage/constants';
import {
  getAllPendingCreations,
  getAllPendingDeletions,
  getAllPendingUpdates,
} from 'pages/dataLibraryPage/selectors';
import { DeleteResourceChange, SerializedPendingChanges } from 'pages/dataLibraryPage/types';
import {
  ALL_PENDING_CHANGES_ACTION_TYPES,
  CLEARING_PENDING_CHANGES_ACTION_TYPES,
  setUnappliedPendingChanges,
} from 'reducers/dataLibraryReducer';
import { ReduxState } from 'reducers/rootReducer';
import { listBranchesThunk } from 'reducers/thunks/fidoThunks/branchThunks';
import { Action, AnyAction, Dispatch, Middleware } from 'redux';

import * as RD from 'remotedata';

export const dataLibraryPendingChangesSerializerMiddleware: Middleware<{}, ReduxState> =
  ({ getState, dispatch }) =>
  (next: Dispatch<Action>) =>
  (action: AnyAction) => {
    const userId = getState().currentUser.id;
    const { mainBranch } = getState().dataLibrary;
    if (action.type === listBranchesThunk.fulfilled.type) {
      const typedAction = action as ReturnType<typeof listBranchesThunk.fulfilled>;
      // Only attempt to read the serialized changes for the main branch the first time branch data
      // is loaded (the first branch that is loaded must be the main branch).
      if (RD.isLoading(mainBranch)) {
        const returnedMainBranch = typedAction.payload.branches.find(
          (branch) => branch.name === MAIN_BRANCH_NAME,
        );
        const returnedMainBranchResponse = returnedMainBranch
          ? RD.Success(returnedMainBranch)
          : RD.Error('No main branch found');
        const serializedPendingChanges = readSerializedChanges(userId, returnedMainBranchResponse);
        if (serializedPendingChanges) {
          dispatch(setUnappliedPendingChanges(serializedPendingChanges));
        }
      }
    }
    next(action);
    if (ALL_PENDING_CHANGES_ACTION_TYPES.has(action.type)) {
      const { pendingResourceCreations, pendingResourceDeletions, pendingResourceUpdates } = {
        pendingResourceCreations: getAllPendingCreations(getState()),
        pendingResourceDeletions: getAllPendingDeletions(getState()),
        pendingResourceUpdates: getAllPendingUpdates(getState()),
      };

      if (!RD.isSuccess(mainBranch)) {
        return;
      }

      savePendingChangesToLocalStorage(
        Array.from(pendingResourceCreations.values()),
        Array.from(pendingResourceDeletions.values()),
        Array.from(pendingResourceUpdates.values()),
        mainBranch.data.headId,
        userId,
        mainBranch.data.id ?? '',
      );
    } else if (CLEARING_PENDING_CHANGES_ACTION_TYPES.has(action.type)) {
      if (!RD.isSuccess(mainBranch)) {
        return;
      }
      clearLocalStorage(userId, mainBranch.data.id ?? '');
    }
  };

const savePendingChangesToLocalStorage = (
  pendingResourceCreations: CreateResourceChange[],
  pendingResourceDeletions: DeleteResourceChange[],
  pendingResourceUpdates: UpdateResourceChange[],
  headCommitId: string,
  userId: number,
  branchId: string,
) => {
  const localStorage = window.localStorage;
  const serializedPendingChanges = getSerializedPendingChanges(
    pendingResourceCreations,
    pendingResourceDeletions,
    pendingResourceUpdates,
    headCommitId,
  );
  try {
    localStorage.setItem(getStorageKey(userId, branchId), serializedPendingChanges);
  } catch {
    console.warn('Could not set local storage because storage quota exceeded!');
  }
};

const getSerializedPendingChanges = (
  pendingResourceCreations: CreateResourceChange[],
  pendingResourceDeletions: DeleteResourceChange[],
  pendingResourceUpdates: UpdateResourceChange[],
  headCommitId: string,
): string => {
  const pendingChanges: SerializedPendingChanges = {
    pendingResourceCreations,
    pendingResourceDeletions,
    pendingResourceUpdates,
    headCommitId,
  };
  return JSON.stringify(pendingChanges);
};

const readSerializedChanges = (
  userId: number,
  mainBranch: RD.ResponseData<Branch>,
): SerializedPendingChanges | undefined => {
  if (!RD.isSuccess(mainBranch)) {
    return;
  }

  const localStorage = window.localStorage;
  const storedPendingChanges = localStorage.getItem(
    getStorageKey(userId, mainBranch.data.id ?? ''),
  );
  if (!storedPendingChanges) {
    return;
  }

  const parsedPendingChanges = JSON.parse(storedPendingChanges);

  const storedHeadCommitId = parsedPendingChanges.headCommitId;
  if (storedHeadCommitId !== mainBranch.data.headId) {
    // TODO(zifanxiang): Handle this case where there is an additional change atop the pending
    // changes that the user has made (e.g. someone else or the user on another computer has
    // created a commit on the same branch).
    return;
  }

  return parsedPendingChanges;
};

const clearLocalStorage = (userId: number, branchId: string) => {
  const localStorage = window.localStorage;
  localStorage.removeItem(getStorageKey(userId, branchId));
};

const getStorageKey = (userId: number, branchId: string) =>
  `explo-user-id-${userId}.branch-${branchId}`;
