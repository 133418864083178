import { useDroppable, UseDroppableArguments } from '@dnd-kit/core';
import { FC, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ChartAggregation, OPERATION_TYPES } from '@explo/data';

import { CustomerReportAgg, CustomerReportVisualization } from 'actions/customerReportActions';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { IconName } from 'components/ds/Icon';
import { AddAggMenu } from 'pages/ReportBuilder/ReportView/DataPanel/AddAggMenu';
import { AggItem } from 'pages/ReportBuilder/ReportView/DataPanel/AggItem';
import {
  NoDataAlert,
  AtLeastOneAlert,
  RemovedDataAlert,
} from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelAlert';
import { DataPanelList } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelList';
import { DataPanelSubHeader } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelSubHeader';
import { isOverSection } from 'pages/ReportBuilder/utils/listUtils';
import { getVisualizationName } from 'pages/ReportBuilder/utils/visualizationUtils';
import {
  clearPrevAggregations,
  getCurrentViewRemovedAggregations,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { DraggableColumnInfo, getAggUniqueId, getGroupByUniqueId } from 'utils/customerReportUtils';

import { AGGS_SECTION_ID, DataPanelData } from './constants';

type Props = {
  aggregations: CustomerReportAgg[];
  columns: DraggableColumnInfo[];
  disabled?: boolean;
  max?: number;
  operationType: CustomerReportVisualization;
  dataset: ReportBuilderDataset | undefined;
};

export const AggSection: FC<Props> = ({
  columns,
  aggregations,
  disabled,
  max,
  operationType,
  dataset,
}) => {
  const dispatch = useDispatch();
  const { removedAggs, sourceType, variables } = useSelector(
    (state: ReportBuilderReduxState) => ({
      removedAggs: getCurrentViewRemovedAggregations(state.reportEditing),
      sourceType: state.reportEditing.reportSourceType,
      variables: state.embeddedReportBuilder.variables,
    }),
    shallowEqual,
  );

  const { over, isOver, setNodeRef, active } = useDroppable(DROPPABLE_ARGS);

  const sortableIds = useMemo(
    () => aggregations.map((item) => `${AGGS_SECTION_ID}-${getGroupByUniqueId(item)}`),
    [aggregations],
  );

  const aggsByCol = useMemo(() => {
    const byCol: Record<string, Set<ChartAggregation>> = {};
    aggregations.forEach(({ column, agg }) => {
      if (column.name in byCol) byCol[column.name].add(agg.id);
      else byCol[column.name] = new Set([agg.id]);
    });
    return byCol;
  }, [aggregations]);

  const isOverContainer = isOverSection(AGGS_SECTION_ID, isOver, over, sortableIds);
  const isOverSelf = active?.data.current?.section === AGGS_SECTION_ID;

  const visualizationName = getVisualizationName(operationType);
  const numAggregations = aggregations.length;
  const numRemovedAggregations = removedAggs ? removedAggs.length - numAggregations : 0;
  const hasMax = max != null && aggregations.length >= max;
  const isPopoverOpen = isOverContainer && hasMax && !isOverSelf;
  const maxText = `${visualizationName} can have up to ${max} values`;

  const { title, icon } = getAggregationsTitleAndIcon(operationType);

  return (
    <DataPanelList
      disabled={isPopoverOpen}
      id={AGGS_SECTION_ID}
      isOver={isOver}
      items={sortableIds}
      over={over}
      setNodeRef={setNodeRef}>
      <DataPanelSubHeader
        icon={icon}
        popoverChildren={isPopoverOpen ? maxText : undefined}
        title={title}>
        <AddAggMenu
          aggregations={aggregations}
          columnConfigs={dataset?.columnConfigs}
          columns={columns}
          customAggregations={dataset?.customAggregations}
          disabled={disabled}
          tooltipText={hasMax ? maxText : undefined}
          variables={variables}
        />
      </DataPanelSubHeader>

      {numRemovedAggregations > 0 ? (
        <RemovedDataAlert
          name="value"
          numRemoved={numRemovedAggregations}
          onDismiss={() => dispatch(clearPrevAggregations())}
          visualizationName={visualizationName}
        />
      ) : null}

      {operationType !== OPERATION_TYPES.VISUALIZE_TABLE && aggregations.length === 0 ? (
        <AtLeastOneAlert name="value" visualizationName={visualizationName} />
      ) : null}

      {aggregations.length === 0 ? (
        <NoDataAlert name="value" />
      ) : (
        aggregations.map((aggregation) => (
          <AggItem
            aggregation={aggregation}
            aggsByCol={aggsByCol}
            columnConfigs={dataset?.columnConfigs}
            key={getAggUniqueId(aggregation)}
            sourceType={sourceType ?? undefined}
          />
        ))
      )}
    </DataPanelList>
  );
};

const DROPPABLE_ARGS: UseDroppableArguments = {
  id: AGGS_SECTION_ID,
  data: { id: AGGS_SECTION_ID, section: AGGS_SECTION_ID } as DataPanelData,
};

function getAggregationsTitleAndIcon(operationType: CustomerReportVisualization): {
  title: string;
  icon: IconName;
} {
  switch (operationType) {
    case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2:
    case OPERATION_TYPES.VISUALIZE_LINE_CHART_V2:
    case OPERATION_TYPES.VISUALIZE_AREA_CHART_V2:
      return { title: 'Y-Axis Values', icon: 'up-long' };
    case OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2:
      return { title: 'Amount', icon: 'grid4' };
    default:
      return { title: 'Values', icon: 'table-cells' };
  }
}
