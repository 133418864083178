import { useGoogleLogin, useGoogleOAuth } from '@react-oauth/google';
import { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import parse from 'url-parse';

import { googleOAuthVerification } from 'actions/authAction';
import { sendPing } from 'actions/pingActions';
import { ACTION } from 'actions/types';
import { sprinkles, Button } from 'components/ds';
import { PLAN_TYPES } from 'constants/paymentPlanConstants';
import { PingTypes } from 'constants/pingTypes';
import { isEnvironmentSecure } from 'utils/environmentUtils';
import googleLogo from 'images/app/googleLogo.png';
import * as styles from 'pages/SignInPage.css';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ReduxState } from 'reducers/rootReducer';
import {
  googleOAuthVerificationOnSuccess,
  googleOAuthVerificationOnFailure,
  submitHubspotSignUp,
} from 'utils/landingPageUtils';
import { pingCustomerOnlineMessage, pingUserWithoutTeamMessage } from 'utils/pingUtils';

export const enableSSO = !(
  process.env.REACT_APP_ENVIRONMENT === 'production-medme' || isEnvironmentSecure()
);

type Props = {
  isSignIn?: boolean;
};

export const GoogleSSOLoginButton: FC<Props> = ({ isSignIn }) => {
  const { loginLoading, ssoLoginLoading } = useSelector(
    (state: ReduxState) => ({
      loginLoading: createLoadingSelector([ACTION.LOGIN_USER], false)(state),
      ssoLoginLoading: createLoadingSelector([ACTION.GOOGLE_OAUTH_VERIFICATION], false)(state),
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();

  const { scriptLoadedSuccessfully } = useGoogleOAuth();
  const login = useGoogleLogin({
    onSuccess: (response) => {
      dispatch(
        googleOAuthVerification(
          {
            postData: {
              authorization_code: response.code,
              invite_hash: isSignIn
                ? undefined
                : parse(window.location.href, true).query.invite_hash,
            },
          },
          (response) => {
            googleOAuthVerificationOnSuccess(response.is_first_time_sso_login_for_existing_account);

            if (isSignIn) {
              dispatch(
                sendPing({
                  postData: {
                    message: pingCustomerOnlineMessage(response.user),
                    message_type:
                      response.user.team?.payment_plan === PLAN_TYPES.DEACTIVATED
                        ? PingTypes.PING_ONLINE_DEACTIVATED
                        : response.user.team?.payment_plan === PLAN_TYPES.LAUNCH
                          ? PingTypes.PING_ONLINE_LAUNCH
                          : PingTypes.PING_ONLINE,
                  },
                }),
              );
              if (!response.user.team) {
                dispatch(
                  sendPing({
                    postData: {
                      message: pingUserWithoutTeamMessage(response.user),
                      message_type: PingTypes.PING_USER_WITHOUT_TEAM,
                    },
                  }),
                );
              }
            }

            if (response.is_new_account) {
              submitHubspotSignUp(
                response.user.email,
                response.user.first_name,
                response.user.last_name,
              );
            }
          },
          (response) => googleOAuthVerificationOnFailure(response?.error_msg),
        ),
      );
    },
    onError: (response) => googleOAuthVerificationOnFailure(response.error_description),
    flow: 'auth-code',
  });

  if (!enableSSO) return null;

  return (
    <Button
      fillWidth
      className={styles.samlButton}
      disabled={loginLoading || ssoLoginLoading || !scriptLoadedSuccessfully}
      onClick={() => scriptLoadedSuccessfully && login()}>
      <div className={sprinkles({ display: 'flex', gap: 'sp1' })}>
        <img src={googleLogo} style={{ height: 16, width: 16 }} />
        {`Sign ${isSignIn ? 'in' : 'up'} with Google`}
      </div>
    </Button>
  );
};
