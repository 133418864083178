import {
  DAYS_OF_WEEK,
  EMAIL_FREQUENCY,
  EmailFrequency,
  WEEKS_OF_MONTH,
} from 'constants/emailFrequency';
import { EmailCadence } from 'reportBuilderContent/exportTypes';
import { range } from 'utils/standard';

export const cadenceOptions = Object.values(EMAIL_FREQUENCY).map((cadenceStr) => ({
  name: cadenceStr,
}));

// Options needed to populate EmailScheduler.
// These wont change so no reason to build in component.
export const timeOptions = range(0, 1440, 30).map((minutes) => ({
  value: minutes.toString(),
  name: minutesToDisplay(minutes),
}));

export const weekdayOptions = Object.values(DAYS_OF_WEEK).map((name, idx) => ({
  value: idx.toString(),
  name,
}));

export const weekOfMonthOptions = Object.values(WEEKS_OF_MONTH).map((name, idx) => ({
  value: idx.toString(),
  name,
}));

export function getEmailCadence(email: EmailCadence): EmailFrequency {
  if (email.day_of_week === null) return EMAIL_FREQUENCY.DAILY;
  if (email.week_of_month === null) return EMAIL_FREQUENCY.WEEKLY;
  return EMAIL_FREQUENCY.MONTHLY;
}

export function getEmailScheduleText(email: EmailCadence): string {
  const hour = email.hour % 12;
  const time = `at ${hour === 0 ? 12 : hour}:${email.minute === 0 ? '00' : email.minute} ${
    email.hour >= 12 ? 'PM' : 'AM'
  }`;
  if (email.day_of_week === null) return time;

  const timeWithDay = `${Object.values(DAYS_OF_WEEK)[email.day_of_week]} ${time}`;
  if (email.week_of_month === null) return timeWithDay;

  return `${Object.values(WEEKS_OF_MONTH)[email.week_of_month]} ${timeWithDay}`;
}

function minutesToDisplay(minutes: number): string {
  const hours = minutes / 60;
  const moddedHours = Math.floor(hours) % 12;
  const hourStr = moddedHours === 0 ? 12 : moddedHours;

  if (Number.isInteger(hours)) return `${hourStr}:00 ${hours >= 12 ? 'PM' : 'AM'}`;
  return `${hourStr}:30 ${hours >= 12 ? 'PM' : 'AM'}`;
}
