import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import { ErrorState } from 'components/ErrorState';
import { SearchInput } from 'components/SearchInput/SearchInput';
import { Button, DataGrid, Icon, Intent, Select, Spinner, sprinkles } from 'components/ds';
import { PaginatorProps } from 'components/ds/DataGrid/paginator';
import { ROUTES } from 'constants/routes';
import DatasetMenuItem from 'pages/manageDataTablesPage/datasetMenuItem';
import { ReduxState } from 'reducers/rootReducer';
import { fetchFidoTablePreview } from 'reducers/thunks/dashboardDataThunks/fetchFidoDataThunks';
import * as RD from 'remotedata';
import { showCustomToast } from 'shared/sharedToasts';
import { EVENTS, trackEvent } from 'telemetry/exploAnalytics';
import { FidoReadAccessTableView } from 'utils/fido/fidoShimmedTypes';
import { useFidoDataSourceData } from 'utils/hookUtils';
import { parseErrorMessage } from 'utils/queryUtils';
import { sortBy } from 'utils/standard';

import { SIDEBAR_WIDTH } from './constants';

export const ManageFidoDataTablesPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    params: { dataSourceId },
  } = useRouteMatch<{ dataSourceId: string }>();

  const [selectedDataset, setSelectedDataset] = useState<FidoReadAccessTableView>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const { fidoDaos, dataSources, parentSchemas, tableView, tableViewData } = useSelector(
    (state: ReduxState) => ({
      fidoDaos: state.fido.fidoDaos,
      dataSources: state.dataSource.dataSources,
      parentSchemas: state.parentSchemas.usedParentSchemas,
      tableView: state.fido.tableView,
      tableViewData: state.fido.tableViewData,
    }),
    shallowEqual,
  );

  useFidoDataSourceData();

  const { namespaces, fidoDataSources, tables } = useMemo(
    () =>
      RD.isSuccess(fidoDaos)
        ? {
            namespaces: fidoDaos.data.namespaces,
            fidoDataSources: fidoDaos.data.dataSources,
            tables: fidoDaos.data.tables,
          }
        : { namespaces: [], fidoDataSources: [], tables: [], schemaTablesMap: {} },
    [fidoDaos],
  );

  const dataSource = useMemo(() => {
    return fidoDataSources.find((ds) => ds.id === parseInt(dataSourceId));
  }, [fidoDataSources, dataSourceId]);

  const namespace = useMemo(() => {
    if (!dataSource) return;
    return namespaces.find((ns) => ns.id === dataSource.parent_schema_id);
  }, [dataSource, namespaces]);

  useEffect(() => {
    if (RD.isSuccess(fidoDaos) && fidoDaos.data.tables.length === 0 && namespace) {
      showCustomToast(
        <div>
          This schema has not had its tables synced to Explo.
          <br />
          <br />
          This means that you cannot view your data in this view and that autocomplete and schema
          inferring will not work in your dashboard editors.
          <br />
          <br />
          Click{' '}
          <a href={`/sync-tables/${namespace.id}`} rel="noopener noreferrer" target="_blank">
            here
          </a>{' '}
          to sync tables
        </div>,
        { timeoutInSeconds: 10, icon: 'info-sign', intent: Intent.SUCCESS },
      );
    }
  }, [fidoDaos, namespace]);

  const dataSourceOptions = useMemo(() => {
    if (!namespace) return;
    return fidoDataSources
      .filter((ds) => ds.parent_schema_id === namespace.id)
      .map((dataSource) => ({
        value: dataSource.id.toString(),
        label: dataSource.name,
      }));
  }, [namespace, fidoDataSources]);

  const baseTableOptions = useMemo(() => {
    if (RD.isSuccess(fidoDaos)) {
      return Object.values(tables.filter((t) => t.namespaceId === namespace?.fido_id));
    }
    return [];
  }, [tables, fidoDaos, namespace]);
  const searchQueryFilteredTableOptions = useMemo(() => {
    return baseTableOptions.filter((t) =>
      t.tableName.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()),
    );
  }, [baseTableOptions, searchQuery]);
  const sortedFilteredTableOptions = useMemo(() => {
    return sortBy(
      searchQueryFilteredTableOptions,
      (t) => t.schemaName,
      (t) => t.tableName,
    );
  }, [searchQueryFilteredTableOptions]);

  useEffect(() => {
    if (sortedFilteredTableOptions.length > 0) {
      setSelectedDataset(sortedFilteredTableOptions[0]);
      setCurrentPage(1);
    }
  }, [sortedFilteredTableOptions, setSelectedDataset]);

  const getTablePreview = useCallback(
    (viewId: string | null | undefined, page: number, newDataSourceId?: string) => {
      const dataSourceId = newDataSourceId ? newDataSourceId : dataSource?.fido_id;

      if (
        !dataSourceId ||
        !namespace?.fido_id ||
        sortedFilteredTableOptions.length === 0 ||
        !viewId
      )
        return;
      dispatch(
        fetchFidoTablePreview({
          viewId,
          dataSourceId,
          namespaceId: namespace.fido_id,
          body: {
            pagingConfiguration: { perPage: 50, page: page - 1 },
            includeTotalResults: true,
          },
        }),
      );
    },
    [dataSource, namespace, sortedFilteredTableOptions, dispatch],
  );

  useEffect(() => {
    if (!selectedDataset) {
      return;
    }

    getTablePreview(selectedDataset.id, currentPage);
  }, [selectedDataset, currentPage, getTablePreview]);

  useEffect(() => {
    if (RD.isIdle(tableView) && sortedFilteredTableOptions.length > 0) {
      getTablePreview(sortedFilteredTableOptions[0].id, currentPage);
    }
  }, [currentPage, getTablePreview, sortedFilteredTableOptions, tableView]);

  if (RD.isError(fidoDaos) || RD.isError(dataSources) || RD.isError(parentSchemas)) {
    return (
      <ErrorState text="An error has occurred fetching data sources and tables for this schema." />
    );
  }

  const renderDatasetName = (dataset: FidoReadAccessTableView) => {
    if (dataset.schemaName) {
      return `${dataset.schemaName}.${dataset.tableName}`;
    }

    return dataset.tableName;
  };

  const renderDatasetsSidebar = () => {
    if (
      RD.isLoadingMulti([fidoDaos, dataSources, parentSchemas]) ||
      !fidoDataSources ||
      !dataSourceOptions
    ) {
      return <Spinner fillContainer size="lg" />;
    }

    // Should never get here
    if (!namespace || !dataSource) return;

    return (
      <>
        <div className={sprinkles({ padding: 'sp1' })}>
          <span className={sprinkles({ heading: 'h4' })}>{namespace.name.toUpperCase()}</span>
          <Select
            className={sprinkles({ marginTop: 'sp1' })}
            onChange={(value) => {
              history.replace(`/datasources/${value}`);
              const newDataSource = fidoDataSources.find((ds) => ds.id === parseInt(value));
              if (!newDataSource || !selectedDataset) return;
              getTablePreview(selectedDataset.id, currentPage, newDataSource.fido_id);
            }}
            selectedValue={dataSource.id.toString()}
            values={dataSourceOptions}
          />
        </div>
        <div
          className={sprinkles({ backgroundColor: 'outline', marginY: 'sp.5' })}
          style={{ minHeight: 1 }}
        />
        <div
          className={sprinkles({
            padding: 'sp1',
            flex: 1,
            flexItems: 'column',
            overflow: 'hidden',
          })}>
          <div className={sprinkles({ heading: 'h4', marginBottom: 'sp1' })}>Source Datasets</div>
          <SearchInput
            className={sprinkles({ padding: 'sp1' })}
            onInputChanged={setSearchQuery}
            searchQuery={searchQuery}
          />
          <div className={sprinkles({ flexItems: 'column', overflowY: 'auto', flex: 1 })}>
            {sortedFilteredTableOptions.map((dataset) => (
              <DatasetMenuItem
                active={selectedDataset?.id === dataset.id}
                key={`dataset-navbar-item-${dataset.id}`}
                name={renderDatasetName(dataset)}
                onClick={() => {
                  setSelectedDataset(dataset);
                  trackEvent(EVENTS.SELECTED_DATASET, {
                    dataset_id: dataset.id,
                    dataset_name: dataset.tableName,
                  });
                }}
              />
            ))}
          </div>
        </div>
      </>
    );
  };

  const renderDatasetViewer = () => {
    if (RD.isError(tableView)) {
      const errorDescription = parseErrorMessage(tableView.error);
      return (
        <div
          className={sprinkles({ backgroundColor: 'white', flexItems: 'center', height: 'fill' })}>
          <div className={sprinkles({ flexItems: 'centerColumn', gap: 'sp2' })}>
            <Icon className={sprinkles({ color: 'error' })} name="circle-exclamation" size="xl" />
            <div className={sprinkles({ heading: 'h2' })}>
              There was an error fetching the results
            </div>
            {errorDescription ? (
              <div className={sprinkles({ body: 'b2' })}>{errorDescription}</div>
            ) : null}
            <Button
              icon="arrow-up-right-from-square"
              onClick={() => alert(tableView.error)}
              variant="tertiary">
              View full error
            </Button>
          </div>
        </div>
      );
    }

    if (tableViewData === undefined) return <DataGrid loading />;

    // Should never get here
    if (!namespace || !dataSource) return;

    if (RD.isSuccess(fidoDaos) && tables.length === 0) {
      return (
        <div className={unSyncedTablesClass}>
          <Link to={ROUTES.SYNC_DATA_TABLES_NO_SCHEMA + `/${namespace.id}`}>
            <h2>No source datasets were found, you may need to first sync your tables.</h2>
          </Link>
        </div>
      );
    }

    const paginatorProps: PaginatorProps = {
      totalRowCount: tableViewData.totalResults || undefined,
      currentPage: currentPage,
      loading: RD.isLoading(tableView),
      goToPage: ({ page }) => {
        if (!selectedDataset) return;
        setCurrentPage(page);
      },
    };

    return (
      <DataGrid
        loading={RD.isLoading(tableView)}
        paginatorProps={paginatorProps}
        rows={tableViewData.rows}
        schema={tableViewData.schema}
      />
    );
  };

  return (
    <div className={rootClass}>
      <div className={sidebarClass} style={{ width: SIDEBAR_WIDTH }}>
        {renderDatasetsSidebar()}
      </div>
      <div className={datasetViewerClass}>{renderDatasetViewer()}</div>
    </div>
  );
};

const rootClass = sprinkles({
  parentContainer: 'fill',
  flexItems: 'alignCenter',
  justifyContent: 'flex-start',
  overflow: 'auto',
});

const datasetViewerClass = sprinkles({ parentContainer: 'fill', overflow: 'auto' });

const unSyncedTablesClass = sprinkles({
  flexItems: 'center',
  textAlign: 'center',
  parentContainer: 'fill',
  heading: 'h4',
});

const sidebarClass = sprinkles({
  height: 'fill',
  backgroundColor: 'white',
  overflowY: 'auto',
  borderRight: 1,
  borderColor: 'gray3',
});
