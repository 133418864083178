export enum DatasetViews {
  QUERY = 'Query',
  FORMATTING = 'Formatting',
  USAGE = 'Explo usage',
}

export enum ModalStatus {
  DELETE_DATASET = 'delete_dataset',
  EDIT_DATASET = 'edit_dataset',
  CLOSED = 'closed',
}

export const ComputedViewEnabledProducts = {
  DASHBOARD: 'Dashboard',
  REPORT_BUILDER: 'Report Builder',
} as const;
