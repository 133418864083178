import { Layout } from '@explo-tech/react-grid-layout';

import { CustomerEditableSectionLayout } from 'actions/dashboardActions';
import { Dataset, DatasetId } from 'actions/datasetActions';
import { DashboardElement } from 'types/dashboardTypes';
import { DataPanelTemplate } from 'types/dataPanelTemplate';

import { CustomerPermissionsForObject } from './permissionTypes';
import { GlobalDatasetVariableNameMap } from 'components/DataLibrary/types';

export enum DashboardHeaderLocation {
  RIGHT_ALIGNED = 'rightAligned',
  BELOW_HEADER = 'belowHeader',
}

export interface DashboardStickyHeaderConfig {
  enabled?: boolean;
  headerDisabled?: boolean;
  headerName?: string;
  backgroundColor?: string;
  disableBottomBorder?: boolean;
  disableBottomShadow?: boolean;
  enabledExpandableFilterRow?: boolean;
  enableStretchFilters?: boolean;
  headerTextColor?: string;
  headerTextSize?: number;
  headerContentOrder?: string[];
  filterLocations?: DashboardHeaderLocation;
}

export interface DashboardPageLayoutConfig {
  stickyHeader?: DashboardStickyHeaderConfig;
}

export interface EditableSectionChart {
  name: string;
  permissions: CustomerPermissionsForObject;
  data_panel: DataPanelTemplate;
}

export interface EditableSectionSettings {
  title: string;
  tooltipText?: string;
}

/**
 * The charts inside default_layout and user layout's are instances of the chart templates below
 * Those charts keys are generated by createEditableSectionItemId which include the data_panel id as a prefix
 */
export interface EditableSectionConfig {
  enabled: boolean;
  default_layout: CustomerEditableSectionLayout[];
  // Available chart templates that can be instantiated in the editable section. Chart Key is the same as Data Panel Id
  // These are not actual charts. Those are in CustomerEditableSectionConfig.charts
  charts: Record<string, EditableSectionChart>;
  settings: EditableSectionSettings;
}

export interface VersionedComputedViewReference {
  id: string;
  versionId: string;
  namespaceId: string;
}

export interface DatasetVariableMapping {
  datasetId: string;
  // A map of global dataset variable names to dashboard variable names.
  globalDatasetVariableNameMap: GlobalDatasetVariableNameMap;
}

export interface DashboardVersionConfig {
  dashboard_layout: Layout[];
  pdf_layout?: Layout[];
  email_layout?: Layout[];
  mobile_layout?: Layout[];
  data_panels: Record<string, DataPanelTemplate>;
  elements: Record<string, DashboardElement>;
  params: Record<string, DashboardParam>;
  datasets: Record<string, Dataset>;
  dashboard_page_layout_config?: DashboardPageLayoutConfig;
  editable_section?: EditableSectionConfig;
  email_header_html?: string;
  email_footer_html?: string;
  category_colors?: Record<string, string>; // Value to color
  // A map of computed ids to their versioned reference (which contains the id and versionId)
  versioned_computed_view_references?: Record<string, VersionedComputedViewReference>;
  // A map of dataset ids to variable remappings. The variable remappings consists of a map of
  // global dataset variable names to the dashboard variable names that they should derive their
  // value from.
  variable_mappings?: Record<DatasetId, GlobalDatasetVariableNameMap>;
}

export interface DashboardParam {
  id: string;
  name: string;
  type: string;
  defaultValue?: string;
}
