import ReactQuill, { Quill } from '@explo-tech/react-quill';
import { Theme, makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
// @ts-ignore
import ImageResize from 'quill-image-resize-module-react';
import { useState, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '@explo-tech/react-quill/dist/quill.snow.css';

import { updateDashboardEmailText } from 'actions/layoutActions';
import { DEFAULT_EMAIL_WIDTH_PX } from 'constants/dashboardConstants';
import { ReduxState } from 'reducers/rootReducer';
import { createDebouncedFn } from 'utils/general';

import EmailBuilderToolbar from './EmailBuilderToolbar';
import EmailImage from './EmailImage';

Quill.register('modules/imageResize', ImageResize);
Quill.register(EmailImage);

const AlignStyle = Quill.import('attributors/style/align');
const BackgroundStyle = Quill.import('attributors/style/background');
const ColorStyle = Quill.import('attributors/style/color');
const DirectionStyle = Quill.import('attributors/style/direction');
const FontStyle = Quill.import('attributors/style/font');
const SizeStyle = Quill.import('attributors/style/size');
Quill.register(AlignStyle, true);
Quill.register(BackgroundStyle, true);
Quill.register(ColorStyle, true);
Quill.register(DirectionStyle, true);
Quill.register(FontStyle, true);
Quill.register(SizeStyle, true);

const debounceFn = createDebouncedFn(1000);

type StylesProps = {
  width?: number;
};

const useStyles = makeStyles<Theme, StylesProps>((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.ds.white,
    width: ({ width }) => width,
    margin: 'auto',
    position: 'relative',

    '& *': {
      fontFamily: "'Inter', sans-serif",
    },

    '&:focus-within > .quillTextBox': {
      border: `1px solid ${theme.palette.ds.blue} !important`,
    },

    '&:hover > .quillTextBox': {
      border: `1px solid ${theme.palette.ds.lightBlue}`,
    },

    '&:focus-within > .quillToolbar': {
      visibility: 'visible',
    },
  },
  header: {
    marginTop: theme.spacing(8),
    paddingTop: `25px`,
    paddingBottom: theme.spacing(1),
  },
  footer: {
    paddingTop: theme.spacing(1),
    paddingBottom: `25px`,
  },
  toolbarSection: {
    borderRight: `1px solid ${theme.palette.ds.grey400}`,
    '&:last-child': {
      borderRight: `0px`,
    },
    // a lot of the !importants here are overriding default styles
    marginRight: '0px !important',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  toolbar: {
    padding: '0px !important',
    border: '0 !important',
    display: 'flex',
    height: theme.spacing(10),
    borderRadius: theme.spacing(1),
    boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.12)',

    backgroundColor: theme.palette.ds.white,
  },
  textBox: {
    // fix a bug where the container gets one px smaller on focus
    border: '1px solid transparent',
    borderRadius: theme.spacing(1),
  },
  quillEditor: {
    // get rid of quill's default border
    '& > *': {
      border: 'none !important',
    },

    '& .ql-blank': {
      '&[data-placeholder]': {
        fontSize: '14px',
        fontWeight: 'normal',
      },
      '&:before': {
        fontStyle: 'normal',
      },
    },
    '& .ql-tooltip.ql-editing': {
      position: 'static',
      width: '100%',
    },
  },
  button: {
    padding: '0px !important',
    width: '24px !important',
    minWidth: '24px !important',
    minHeight: '24px !important',
  },
  // clicking the url image upload in the toolbar breaks focus, so we have to hack the outline back in
  focusHack: {
    border: `1px solid ${theme.palette.ds.blue} !important`,
  },
}));

type Props = {
  initialValue?: string;
  isHeader: boolean;
};

const EmailBuilderTextEditor = memo(
  function MemoizedEmailBuilderTextEditor({ initialValue, isHeader }: Props) {
    const [showImageVariableInputToolbar, setShowImageVariableInputToolbar] = useState(false);
    const [quill, setQuill] = useState(null as ReactQuill | null);

    const emailWidthPx = useSelector(
      (state: ReduxState) => state.currentUser.team?.configuration.email_width_px,
    );

    const dispatch = useDispatch();
    const classes = useStyles({ width: emailWidthPx ?? DEFAULT_EMAIL_WIDTH_PX });

    const toolbarId = `email-toolbar-${isHeader ? 'header' : 'footer'}`;

    const modules = {
      // disabling the default image upload behavior so that we can load our custom one in
      // via EmailBuilderToolbar
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      toolbar: { container: `#${toolbarId}`, handlers: { image: () => {} } },
      imageResize: { modules: ['Resize', 'DisplaySize'] },
    };

    useEffect(() => quill?.blur(), [quill]);

    const formats = [
      'font',
      'size',
      'header',
      'bold',
      'italic',
      'underline',
      'align',
      'list',
      'bullet',
      'link',
      'image',
      'emailImage',
    ];

    return (
      <div
        className={cx(classes.root, {
          [classes.header]: isHeader,
          [classes.footer]: !isHeader,
        })}>
        {
          // even though we only really display the toolbar on focus, we need to always have it
          // in the DOM because quill is looking for the toolbar's id to connect the text box to
          <EmailBuilderToolbar
            isHeader={isHeader}
            quill={quill}
            setShowImageVariableInputToolbar={setShowImageVariableInputToolbar}
            showImageVariableInputToolbar={showImageVariableInputToolbar}
            toolbarId={toolbarId}
          />
        }
        <div
          className={cx('quillTextBox', classes.textBox, {
            [classes.focusHack]: showImageVariableInputToolbar,
          })}>
          <ReactQuill
            className={classes.quillEditor}
            defaultValue={initialValue}
            formats={formats}
            modules={modules}
            onChange={(content: string) => {
              debounceFn(() => {
                dispatch(updateDashboardEmailText({ html: content, isHeader }));
              });
            }}
            placeholder={'Click to add text'}
            ref={(el) => {
              setQuill(el);
            }}
          />
        </div>
      </div>
    );
  },
  // Because ReactQuill manages its state internally, we don't actually need this
  // component to rerender. If it rerenders, it hijacks focus, which is annoying behavior

  () => true,
);

export default EmailBuilderTextEditor;
