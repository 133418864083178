import cx from 'classnames';
import { FC, useEffect, useState } from 'react';

import { Icon, Switch, sprinkles } from 'components/ds';

const flexContainerClass = sprinkles({ display: 'flex', alignItems: 'center', height: 'fill' });
const marginRightClass = sprinkles({ marginRight: 'sp1' });
const sectionTextClass = sprinkles({ body: 'section' });

type Props = {
  children: JSX.Element | null;
  defaultIsOpen?: boolean;
  title: string;
  toggleOn?: boolean;

  onToggle?: () => void;
};

export const ConfigSection: FC<Props> = ({
  children,
  defaultIsOpen = false,
  title,
  toggleOn,
  onToggle,
}) => {
  const hasToggle = onToggle !== undefined;
  const hasToggleOff = hasToggle && !toggleOn;

  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  useEffect(() => {
    setIsOpen(defaultIsOpen || (hasToggle && !!toggleOn));
  }, [defaultIsOpen, hasToggle, toggleOn]);

  const renderToggle = (className?: string) => {
    if (!onToggle) return null;

    return <Switch className={className} onChange={() => onToggle()} switchOn={toggleOn} />;
  };

  const renderHeader = () => {
    return (
      <>
        <div className={flexContainerClass}>
          <Icon
            className={cx(
              marginRightClass,
              sprinkles({ color: hasToggleOff ? 'gray8' : undefined }),
            )}
            name={isOpen ? 'caret-down' : 'caret-right'}
            size="sm"
          />
          <div className={sectionTextClass}>{title}</div>
        </div>
        <div>{renderToggle()}</div>
      </>
    );
  };

  return (
    <div>
      <div
        className={sprinkles({
          flexItems: 'alignCenterBetween',
          cursor: hasToggleOff ? undefined : 'pointer',
          padding: 'sp1.5',
        })}
        onClick={() => {
          if (hasToggleOff) return;
          setIsOpen(!isOpen);
        }}>
        {renderHeader()}
      </div>
      {isOpen && (onToggle === undefined || toggleOn) ? children : null}
    </div>
  );
};
