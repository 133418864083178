import { FC, useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { fetchHierarchyMetadata, fetchSelectorCustomers } from 'actions/customerActions';
import { Select, sprinkles } from 'components/ds';
import { CUSTOMER_SELECTOR_QUERY_PARAMS } from 'constants/customerConstants';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { keyBy } from 'utils/standard';

export type Props = {
  className?: string;
  disabled?: boolean;
  label?: string;
  selectedCustomerId: number | undefined;
  selectedCustomerName?: string;
  onSelect: (id: string) => void;
  fillWidth?: boolean;
};

export const CustomerSelector: FC<Props> = ({
  className,
  disabled,
  label,
  onSelect,
  selectedCustomerId,
  selectedCustomerName,
  fillWidth,
}) => {
  const dispatch = useDispatch();

  const { selectorCustomers, selectorCustomersStatus, hierarchyMetadata, accessGroups } =
    useSelector(
      (state: ReduxState) => ({
        selectorCustomers: state.customers.selectorCustomers,
        selectorCustomersStatus: state.customers.selectorCustomersStatus,
        hierarchyMetadata: state.customers.hierarchyMetadata,
        accessGroups: state.teamData.data?.access_groups,
      }),
      shallowEqual,
    );

  const accessGroupIdToName = useMemo(
    () =>
      accessGroups && accessGroups.length > 1
        ? accessGroups.reduce(
            (acc, { id, name }) => ({ ...acc, [id.toString()]: name }),
            {} as Record<string, string>,
          )
        : {},
    [accessGroups],
  );

  useEffect(() => {
    dispatch(fetchSelectorCustomers({ queryParams: CUSTOMER_SELECTOR_QUERY_PARAMS }));
  }, [dispatch]);

  useEffect(() => {
    if (RD.isIdle(hierarchyMetadata)) dispatch(fetchHierarchyMetadata());
  }, [hierarchyMetadata, dispatch]);

  const hierarchyLevelsById = useMemo(() => {
    const hierarchyLevels = RD.isSuccess(hierarchyMetadata) ? hierarchyMetadata.data.levels : [];
    return keyBy(hierarchyLevels, 'id');
  }, [hierarchyMetadata]);

  const values = useMemo(() => {
    return selectorCustomers.map((group) => {
      return {
        value: String(group.id),
        label: group.name,
        subLabel: hierarchyLevelsById[group.hierarchy_level_id]?.name,
        secondaryLabel: accessGroupIdToName[group.access_group_id],
      };
    });
  }, [hierarchyLevelsById, selectorCustomers, accessGroupIdToName]);

  const onFilter = useCallback(
    (searchString: string) =>
      dispatch(
        fetchSelectorCustomers({
          queryParams: {
            ...CUSTOMER_SELECTOR_QUERY_PARAMS,
            search_string: searchString || undefined,
          },
        }),
      ),
    [dispatch],
  );

  return (
    <div
      className={fillWidth ? sprinkles({ width: 'fill' }) : undefined}
      style={fillWidth ? undefined : { width: 280 }}>
      <Select
        secondaryLabelInTag
        className={className}
        disabled={disabled}
        filterProps={{
          isLoading: RD.isLoading(selectorCustomersStatus),
          placeholder: 'Filter for customer',
          onFilter,
          selectedLabel:
            selectedCustomerName ??
            selectorCustomers.find((c) => c.id === selectedCustomerId)?.name,
        }}
        label={label}
        onChange={onSelect}
        placeholder="Select customer"
        selectedValue={selectedCustomerId ? String(selectedCustomerId) : undefined}
        values={values}
      />
    </div>
  );
};
