import {
  Postgres,
  MySql,
  MSS,
  Redshift,
  BigQuery,
  TenantPrivateKeyAuthentication,
  VendorPrivateKeyAuthentication,
  Snowflake,
  Clickhouse,
  Athena,
} from '@explo-tech/fido-api';

import { FIDO_TYPES } from 'pages/ConnectDataSourceFlow/constants';

export enum JDBC_DATA_SOURCE_CONFIG_KEYS {
  DATABASE = 'database',
  PORT = 'port',
  HOST = 'host',
  USER = 'user',
  TUNNEL_TYPE = 'tunnelType',
}

export enum BIGQUERY_DATA_SOURCE_CONFIG_KEYS {
  PROJECT_ID = 'projectId',
}

export enum SNOWFLAKE_DATA_SOURCE_REQUIRED_CONFIG_KEYS {
  USER = 'user',
  ACCOUNT = 'account',
}

export enum SNOWFLAKE_DATA_SOURCE_CONFIG_KEYS {
  USER = 'user',
  ACCOUNT = 'account',
  SCHEMA = 'schema',
  DATABASE = 'database',
}

export enum ATHENA_DATA_SOURCE_REQUIRED_CONFIG_KEYS {
  REGION = 'region',
  DATA_CATALOG = 'dataCatalog',
}

export enum ATHENA_DATA_SOURCE_CONFIG_KEYS {
  REGION = 'region',
  DATA_CATALOG = 'dataCatalog',
  S3_OUTPUT_LOCATION = 's3OutputLocation',
  WORK_GROUP = 'workGroup',
  ACCESS_KEY_ID = 'accessKeyId',
}

export enum SSH_CONFIG_KEYS {
  SSH_AUTH_TYPE = 'sshAuthType',
  HOST = 'tunnelHost',
  PORT = 'tunnelPort',
  USERNAME = 'sshUsername',
}

export type JdbcConfig = Postgres | MySql | MSS | Redshift | Clickhouse;
export type FidoDataSourceConfig = JdbcConfig | BigQuery | Snowflake | Athena;

export type FidoSSHTunnelAuthentication =
  | TenantPrivateKeyAuthentication
  | VendorPrivateKeyAuthentication;

export const JDBC_DATABASE_TYPES = new Set([
  FIDO_TYPES.POSTGRES,
  FIDO_TYPES.REDSHIFT,
  FIDO_TYPES.MYSQL,
  FIDO_TYPES.SQLSERVER,
  FIDO_TYPES.CLICKHOUSE,
]);
