export const UPDATE_VERSION_BUTTON_TOOLTIP_TEXT = 'Newer version available, click to update';

export const LATEST_VERSION_DELETED_TOOLTIP_TEXT =
  'Global dataset deleted from data library. Referencing previous version.';

export const EDIT_IN_DATA_LIBRARY_BUTTON_TEXT = 'Edit in Data Library';

export const EDIT_IN_DATA_LIBRARY_BUTTON_TOOLTIP_TEXT = 'Edit this dataset in the Data Library';

export const EDIT_IN_DATA_LIBRARY_DATASET_DELETED_TOOLTIP_TEXT =
  'This dataset has been deleted in the Data Library';
