import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

import {
  CreateChildDashboardTemplateData,
  Dashboard,
  createChildDashboardTemplate,
  createDrilldownEntryPoint,
} from 'actions/dashboardActions';
import { Button, Icon, Input, Label, Popover, Select, sprinkles } from 'components/ds';
import { SelectItems } from 'components/ds/Select';
import { ROUTE_PROVIDERS } from 'constants/routes';
import { ChartColumnInfo } from 'constants/types';
import { ColumnTargetRow } from 'pages/dashboardPage/LayersPanel/ColumnTargetRow';
import { saveResourceConfig } from 'reducers/thunks/resourceSaveThunks';
import { DashboardHierarchy } from 'types/dashboardTypes';
import { DataPanel, ResourcePageType } from 'types/exploResource';
import { isEqual } from 'utils/standard';

import { sendPing } from 'actions/pingActions';
import { User } from 'actions/userActions';
import { PingTypes } from 'constants/pingTypes';
import { appendCurrentSourceInfo } from 'reducers/drilldownsReducer';
import { EVENTS, trackEvent } from 'telemetry/exploAnalytics';
import { DashboardVersion } from 'types/dashboardVersion';
import {
  getAncestorDashboardNames,
  getDrilldownColumnInfosForSelectedDataPanel,
} from 'utils/dashboardDrilldownUtils';
import {
  DASHBOARD_NAME_ALREADY_EXISTS_ERROR_MESSAGE,
  ENTRY_POINT_ALREADY_EXISTS_ERROR_MESSAGE,
  MAX_CHILD_DASHBOARDS_PER_PARENT,
  MAX_DASHBOARD_HIERARCHY_DEPTH,
  NO_DATA_PANEL_SELECTED_ERROR_MESSAGE,
  NO_ENTRY_COLUMNS_SELECTED_ERROR_MESSAGE,
  TITLE_CANNOT_BE_EMPTY_ERROR_MESSAGE,
  VALID_DRILLDOWN_OPERATION_TYPES,
} from './constants';
import { getChildDashboardCreatedMessage } from './pingMessages';
import { OPERATION_ICON_MAP } from 'constants/dataConstants';
import { setHighlightedDataPanelIds } from 'reducers/dashboardInteractionsReducer';

type Props = {
  parentDashboard: Dashboard;
  parentDashboardVersion: DashboardVersion;
  dashboardHierarchy: DashboardHierarchy;
  currentUser: User;
  dataPanelsById: Record<string, DataPanel>;
  parentDashboardDepth: number;
};

export const AddNewLayerPopover: FC<Props> = ({
  parentDashboard,
  parentDashboardVersion,
  dashboardHierarchy,
  currentUser,
  dataPanelsById,
  parentDashboardDepth,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [title, setTitle] = useState('');
  const [selectedDataPanelId, setSelectedDataPanelId] = useState<string>();
  const [selectedEntryColumns, setSelectedEntryColumns] = useState<ChartColumnInfo[]>([]);

  const dataPanels = useMemo(
    () =>
      Object.values(dataPanelsById).filter((dataPanel: DataPanel) =>
        VALID_DRILLDOWN_OPERATION_TYPES.has(dataPanel.visualize_op.operation_type),
      ),
    [dataPanelsById],
  );

  const ancestorDashboardNames: Set<string> = useMemo(() => {
    return getAncestorDashboardNames(dashboardHierarchy, parentDashboard.id);
  }, [parentDashboard, dashboardHierarchy]);

  const selectedDataPanelColumnInfos = useMemo(() => {
    if (selectedDataPanelId) {
      const selectedDataPanel = dataPanelsById[selectedDataPanelId];
      return getDrilldownColumnInfosForSelectedDataPanel(selectedDataPanel);
    }
    return {};
  }, [selectedDataPanelId, dataPanelsById]);

  const onSourceDatasetColumnChecked = useCallback(
    (checked: boolean, columnInfo: ChartColumnInfo) => {
      if (checked) {
        setSelectedEntryColumns([...selectedEntryColumns, columnInfo]);
      } else {
        setSelectedEntryColumns(
          selectedEntryColumns.filter((selectedColumnInfo) => selectedColumnInfo !== columnInfo),
        );
      }
    },
    [selectedEntryColumns],
  );

  // TODO(zifanxiang): Think about packaging all state into one object and using useImmer to manage
  // this state.
  const resetState = useCallback(() => {
    setTitle('');
    setSelectedDataPanelId(undefined);
    setSelectedEntryColumns([]);

    dispatch(setHighlightedDataPanelIds(new Set()));
  }, [setTitle, setSelectedDataPanelId, setSelectedEntryColumns, dispatch]);

  const addLayerButtonErrorStatus = useMemo(
    () => getAddLayerButtonErrorStatus(parentDashboardVersion, parentDashboardDepth, dataPanels),
    [parentDashboardVersion, parentDashboardDepth, dataPanels],
  );

  const createButtonErrorStatus = useMemo(
    () =>
      getCreateButtonErrorStatus(
        title,
        selectedEntryColumns,
        dataPanelsById[selectedDataPanelId ?? ''],
        ancestorDashboardNames,
      ),
    [title, selectedEntryColumns, selectedDataPanelId, dataPanelsById, ancestorDashboardNames],
  );

  return (
    <Popover
      className={sprinkles({ padding: 'sp2' })}
      onOpenChange={(open: boolean) => {
        if (!open) {
          resetState();
        }
      }}
      trigger={
        <Button
          disabled={isAddLayerButtonDisabled(addLayerButtonErrorStatus)}
          icon="plus"
          tooltipProps={{ text: getAddLayerButtonErrorMessage(addLayerButtonErrorStatus) }}
        />
      }
      width="medium">
      <div className={sprinkles({ heading: 'h3', marginY: 'sp1' })}>New View</div>
      <Label htmlFor="title-input">Title</Label>
      <Input id="title-input" onChange={setTitle} value={title} />
      <Select
        useIconIndicator
        className={sprinkles({ marginY: 'sp1' })}
        label="Chart"
        onChange={(selectedValue) => {
          setSelectedDataPanelId(selectedValue);
          dispatch(setHighlightedDataPanelIds(new Set([selectedValue])));
        }}
        renderSelectedValue={(selectedValue, selectedIcon) => {
          return (
            <div>
              {selectedIcon ? <Icon name={selectedIcon} size="md" /> : null}
              <span className={sprinkles({ marginLeft: 'sp1' })}>{selectedValue}</span>
            </div>
          );
        }}
        selectedValue={selectedDataPanelId}
        values={createDataPanelSelectOptions(dataPanels)}
      />
      {selectedDataPanelId ? (
        <>
          <Label className={sprinkles({ marginY: 'sp1' })} htmlFor="source-columns">
            Source columns
          </Label>
          <div id="source-columns">
            {Object.values(selectedDataPanelColumnInfos).map((columnInfo) => {
              return (
                <ColumnTargetRow
                  columnInfo={columnInfo}
                  key={columnInfo.name}
                  onColumnChecked={onSourceDatasetColumnChecked}
                />
              );
            })}
          </div>
        </>
      ) : null}
      <Button
        className={sprinkles({ padding: 'sp2', marginTop: 'sp1' })}
        disabled={createButtonErrorStatus !== CreateLayerButtonErrorStatus.NO_ERROR}
        onClick={() => {
          if (!selectedDataPanelId) {
            return;
          }

          const entryPointId = uuidv4();
          dispatch(
            createChildDashboardTemplate(
              {
                id: currentUser.id,
                postData: {
                  name: title.trim(),
                  parent_dashboard_id: parentDashboard.id,
                  user_id: currentUser.id,
                },
              },
              (data: CreateChildDashboardTemplateData) => {
                dispatch(
                  createDrilldownEntryPoint({
                    sourceDashboardId: parentDashboard.id,
                    sourceDataPanelId: selectedDataPanelId,
                    sourceColumns: selectedEntryColumns,
                    entryPointId,
                    destinationDashboardId: data.new_child_dashboard_template.id,
                  }),
                );
                // Dispatch a direct save after creating the drilldown entry point. Firing off a
                // save event doesn't allow the update to the data panel to persist since the
                // client side routing that follows deletes the save event listener.
                dispatch(saveResourceConfig(ResourcePageType.EXPLORE, parentDashboard.id));
                dispatch(
                  appendCurrentSourceInfo({
                    sourceDashboardId: parentDashboard.id,
                    sourceDataPanelId: selectedDataPanelId,
                    drilldownEntryPointId: entryPointId,
                  }),
                );
                dispatch(
                  sendPing({
                    postData: {
                      message: getChildDashboardCreatedMessage(currentUser, title),
                      message_type: PingTypes.PING_DASHBOARD_DRILLDOWN,
                    },
                  }),
                );
                trackEvent(EVENTS.CREATED_CHILD_DASHBOARD, {
                  child_dashboard_name: title,
                });
                history.push(
                  ROUTE_PROVIDERS.DASHBOARD_EDIT_MODE(`${data.new_child_dashboard_template.id}`),
                  { isNavigatingToNewChildDashboard: true },
                );
              },
            ),
          );
        }}
        tooltipProps={{ text: getCreateButtonErrorMessage(createButtonErrorStatus) }}>
        Create
      </Button>
    </Popover>
  );
};

const NO_ERROR_MESSAGE = '';

enum AddLayerButtonErrorStatus {
  NO_ERROR,
  MAX_CHILD_DASHBOARDS_PER_PARENT_EXCEEDED,
  MAX_DASHBOARD_HIERARCHY_DEPTH_EXCEEDED,
  NO_VALID_SOURCE_DATA_PANELS,
}

enum CreateLayerButtonErrorStatus {
  NO_ERROR,
  TITLE_EMPTY,
  NO_SELECTED_DATA_PANEL,
  NO_SELECTED_ENTRY_COLUMNS,
  ENTRY_POINT_ALREADY_EXISTS,
  ANCESTOR_DASHBOARD_NAME_EXISTS,
}

const isAddLayerButtonDisabled = (errorStatus: AddLayerButtonErrorStatus): boolean =>
  errorStatus !== AddLayerButtonErrorStatus.NO_ERROR;

const getAddLayerButtonErrorStatus = (
  parentDashboardVersion: DashboardVersion,
  parentDashboardDepth: number,
  validSourceDataPanels: DataPanel[],
): AddLayerButtonErrorStatus => {
  if (parentDashboardVersion.child_version_ids.length >= MAX_CHILD_DASHBOARDS_PER_PARENT) {
    return AddLayerButtonErrorStatus.MAX_CHILD_DASHBOARDS_PER_PARENT_EXCEEDED;
  } else if (parentDashboardDepth >= MAX_DASHBOARD_HIERARCHY_DEPTH) {
    return AddLayerButtonErrorStatus.MAX_DASHBOARD_HIERARCHY_DEPTH_EXCEEDED;
  } else if (validSourceDataPanels.length <= 0) {
    return AddLayerButtonErrorStatus.NO_VALID_SOURCE_DATA_PANELS;
  }

  return AddLayerButtonErrorStatus.NO_ERROR;
};

const getAddLayerButtonErrorMessage = (errorStatus: AddLayerButtonErrorStatus): string => {
  switch (errorStatus) {
    case AddLayerButtonErrorStatus.MAX_CHILD_DASHBOARDS_PER_PARENT_EXCEEDED:
      return `Maximum of ${MAX_CHILD_DASHBOARDS_PER_PARENT} drilldown dashboards per dashboard reached`;
    case AddLayerButtonErrorStatus.MAX_DASHBOARD_HIERARCHY_DEPTH_EXCEEDED:
      return `Maximum of ${MAX_DASHBOARD_HIERARCHY_DEPTH} nested dashboards reached`;
    case AddLayerButtonErrorStatus.NO_VALID_SOURCE_DATA_PANELS:
      return 'No valid source data panels available';
    default:
      return NO_ERROR_MESSAGE;
  }
};

const getCreateButtonErrorStatus = (
  title: string,
  selectedEntryColumns: ChartColumnInfo[],
  selectedDataPanel: DataPanel | undefined,
  ancestorDashboardNames: Set<string>,
): CreateLayerButtonErrorStatus => {
  if (title.trim().length <= 0) {
    return CreateLayerButtonErrorStatus.TITLE_EMPTY;
  } else if (!selectedDataPanel) {
    return CreateLayerButtonErrorStatus.NO_SELECTED_DATA_PANEL;
  } else if (selectedEntryColumns.length <= 0) {
    return CreateLayerButtonErrorStatus.NO_SELECTED_ENTRY_COLUMNS;
  } else if (ancestorDashboardNames.has(title)) {
    return CreateLayerButtonErrorStatus.ANCESTOR_DASHBOARD_NAME_EXISTS;
  }

  const existingDrilldownEntryPoints = selectedDataPanel.drilldownEntryPoints;
  const entryPointAlreadyExists = Object.values(existingDrilldownEntryPoints).some((entryPoint) => {
    const entryPointSourceColumns = entryPoint.sourceChartColumns;
    if (isEqual(entryPointSourceColumns, selectedEntryColumns)) {
      return true;
    }

    return false;
  });

  return entryPointAlreadyExists
    ? CreateLayerButtonErrorStatus.ENTRY_POINT_ALREADY_EXISTS
    : CreateLayerButtonErrorStatus.NO_ERROR;
};

const getCreateButtonErrorMessage = (errorStatus: CreateLayerButtonErrorStatus): string => {
  switch (errorStatus) {
    case CreateLayerButtonErrorStatus.TITLE_EMPTY:
      return TITLE_CANNOT_BE_EMPTY_ERROR_MESSAGE;
    case CreateLayerButtonErrorStatus.NO_SELECTED_DATA_PANEL:
      return NO_DATA_PANEL_SELECTED_ERROR_MESSAGE;
    case CreateLayerButtonErrorStatus.NO_SELECTED_ENTRY_COLUMNS:
      return NO_ENTRY_COLUMNS_SELECTED_ERROR_MESSAGE;
    case CreateLayerButtonErrorStatus.ENTRY_POINT_ALREADY_EXISTS:
      return ENTRY_POINT_ALREADY_EXISTS_ERROR_MESSAGE;
    case CreateLayerButtonErrorStatus.ANCESTOR_DASHBOARD_NAME_EXISTS:
      return DASHBOARD_NAME_ALREADY_EXISTS_ERROR_MESSAGE;
    default:
      return NO_ERROR_MESSAGE;
  }
};

const createDataPanelSelectOptions = (dataPanels: DataPanel[]): SelectItems<string> => {
  return dataPanels.map((dataPanel) => ({
    label:
      dataPanel.visualize_op.generalFormatOptions?.headerConfig?.title || dataPanel.provided_id,
    value: dataPanel.id,
    icon: OPERATION_ICON_MAP[dataPanel.visualize_op.operation_type] ?? 'table',
  }));
};
