import { FC } from 'react';
import { useSelector } from 'react-redux';

import { ReduxState } from 'reducers/rootReducer';
import { getEnvironment } from 'utils/environmentUtils';
import { RECENT_ACTIVITY_EMBED_ID } from './constants';

export const RecentActivity: FC = () => {
  const teamData = useSelector((state: ReduxState) => state.teamData.data);

  const customerToken =
    getEnvironment() === 'development'
      ? process.env.REACT_APP_ANALYTICS_DEFAULT_CUSTOMER_TOKEN
      : teamData?.internal_analytics_user_group_token;

  if (!customerToken) return null;

  return (
    <iframe
      src={`https://app.explo.co/iframe/${RECENT_ACTIVITY_EMBED_ID}/${customerToken}?is_production=true`}
      style={{ width: '100%', border: 'none', height: '100vh' }}
    />
  );
};
