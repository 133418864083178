import { DashboardVariable } from 'types/dashboardTypes';

export enum INTERNAL_EVENT {
  SAVE_RESOURCE_CONFIG = 'saveResourceConfigEvent',
  SAVE_MAP_VIEW_STATE = 'SaveMapViewState',
}

// Events sent from Explo's embed to our customers
export enum OUTPUT_EVENT {
  ERROR = 'error',
  UPDATE_VARIABLE = 'sendVariableUpdatedEvent',
  ANALYTICS_EVENT = 'analyticsEventFired',

  DASHBOARD_LOADED = 'dashboardLoaded',
  DASHBOARD_FAILED = 'dashboardFailed',
  DASHBOARD_READY_TO_LOAD = 'dashboardReadyToLoad',
  DASHBOARD_UPDATED = 'dashboardUpdated',

  EDITABLE_SECTION_UPDATED = 'editableSectionUpdated',
  EDITABLE_SECTION_CHART_ADDED = 'editableSectionChartAdded',
  EDITABLE_SECTION_CHART_REMOVED = 'editableSectionChartRemoved',
}

// Events sent from our customers to Explo's embed
export enum INPUT_EVENT {
  UPDATE_VARIABLE = 'updateExploDashboardVariable',
  UPDATE_VARIABLES = 'updateExploDashboardVariables',

  TOGGLE_EDITABLE_SECTION = 'toggleEditableSectionEditing',
  ADD_EDITABLE_SECTION_CHART = 'addEditableSectionChart',
  REMOVE_EDITABLE_SECTION_CHART = 'removeEditableSectionChart',
  SAVE_EDITABLE_SECTION_CHANGES = 'saveEditableSectionChanges',
  DISCARD_EDITABLE_SECTION_CHANGES = 'discardEditableSectionChanges',

  UPDATE_CUSTOMER_JWT = 'updateCustomerJwt',
}

export type UpdateVariablePayload = { varName: string; value: DashboardVariable };
export type UpdateVariablesPayload = { variables: { varName: string; value: DashboardVariable }[] };
export type AddEditableSectionChartPayload = {
  chartTemplateId: string; // Maps to chart.dataPanel.id internally
  id?: string; // Optionally sets chart.dataPanel.providedId
};
export type RemoveEditableSectionChartPayload = { chartId: string }; // Maps to chart.i
export type ToggleEditableSectionEditingPayload = { canEdit: boolean };
export type UpdateCustomerJwtPayload = { jwt: string };

declare global {
  interface WindowEventMap {
    [INPUT_EVENT.UPDATE_VARIABLE]: CustomEvent<UpdateVariablePayload>;
    [INPUT_EVENT.UPDATE_VARIABLES]: CustomEvent<UpdateVariablesPayload>;
    [INPUT_EVENT.TOGGLE_EDITABLE_SECTION]: CustomEvent<ToggleEditableSectionEditingPayload>;
    [INPUT_EVENT.ADD_EDITABLE_SECTION_CHART]: CustomEvent<AddEditableSectionChartPayload>;
    [INPUT_EVENT.REMOVE_EDITABLE_SECTION_CHART]: CustomEvent<RemoveEditableSectionChartPayload>;
    [INPUT_EVENT.SAVE_EDITABLE_SECTION_CHANGES]: CustomEvent<{}>;
    [INPUT_EVENT.DISCARD_EDITABLE_SECTION_CHANGES]: CustomEvent<{}>;
    [INPUT_EVENT.UPDATE_CUSTOMER_JWT]: CustomEvent<UpdateCustomerJwtPayload>;
  }
}
