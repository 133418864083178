import { FC, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { UNDERLYING_DATA_DATA_PANEL_ID } from '@explo/data';

import { updateUnderlyingDataDataPanel } from 'actions/dataPanelTemplateAction';
import { EmbedModal } from 'components/embed';
import * as styles from 'pages/dashboardPage/charts/ViewUnderlyingDataModal.css';
import { getIsIframe } from 'reducers/dashboardLayoutReducer';
import { DashboardStates } from 'reducers/rootReducer';
import { DashboardVariableMap, PAGE_TYPE } from 'types/dashboardTypes';
import { DataPanel } from 'types/exploResource';
import { getDatasetNamesToId } from 'utils/datasetUtils';
import { cloneDeep } from 'utils/standard';

import { DashboardDatasetView } from '../DashboardDatasetView';
import { ReadAccessComputedView } from 'utils/fido/fidoShimmedTypes';
import { Dataset } from 'actions/datasetActions';

type Props = {
  dataPanels: DataPanel[];
  datasets: Record<string, Dataset>;
  pageType: PAGE_TYPE;
  variables: DashboardVariableMap;
  referencedGlobalDatasets: Record<string, ReadAccessComputedView>;
};

/** Modal that displays the underlying data in tabular form for a particular data panel. */
export const ViewUnderlyingDataModal: FC<Props> = ({
  dataPanels,
  datasets,
  pageType,
  variables,
  referencedGlobalDatasets,
}) => {
  const dispatch = useDispatch();

  const { isOpen, isIframe } = useSelector(
    (state: DashboardStates) => ({
      isOpen: state.dashboardLayout.isUnderlyingDataModalOpen,
      isIframe: getIsIframe(state.dashboardLayout),
    }),
    shallowEqual,
  );

  const datasetNamesToId = useMemo(
    () => getDatasetNamesToId(datasets, referencedGlobalDatasets),
    [datasets, referencedGlobalDatasets],
  );

  const underlyingDataDPT = useMemo(() => {
    return dataPanels.find((dp) => dp.id === UNDERLYING_DATA_DATA_PANEL_ID);
  }, [dataPanels]);

  if (!isOpen || !underlyingDataDPT) return null;

  return (
    <EmbedModal
      isOpen
      usePortal
      isIframe={isIframe}
      onClose={() => dispatch(updateUnderlyingDataDataPanel())}
      size="xlarge">
      {/* Plain div instead of EmbedModalContent because we control our own height in styles.modalContent */}
      <div>
        <div className={styles.modalContent}>
          <DashboardDatasetView
            canDownloadDataPanel
            isDrilldownModal
            isViewOnly
            backingGlobalDataset={
              referencedGlobalDatasets[underlyingDataDPT.globalDatasetReference?.id || '']
            }
            dataPanel={cloneDeep(underlyingDataDPT)}
            datasetNamesToId={datasetNamesToId}
            datasets={datasets}
            isInContainer={false}
            isSelected={false}
            pageType={pageType}
            variables={variables}
          />
        </div>
      </div>
    </EmbedModal>
  );
};
