import { AnyAction, createAsyncThunk, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';

import { createApiRequestConfig } from 'actions/actionUtils';
import { ReportBuilderConfig } from 'actions/reportBuilderConfigActions';
import { ReportBuilderVersion } from 'actions/reportBuilderVersionActions';
import { ErrorResponse } from 'actions/responseTypes';
import { ACTION } from 'actions/types';
import { SaveStatus, setResourceSaveStatus, setSaveError } from 'reducers/resourceSavingReducer';
import { getResourceIdsRequestedToSave } from 'reducers/resourceSavingUtils';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { isSuccess } from 'remotedata';
import { DashboardVersionConfig } from 'types/dashboardVersionConfig';
import { ResourcePageType } from 'types/exploResource';
import { makeThunkRequest } from 'utils/thunkUtils';

type Thunk = ThunkAction<void, ReduxState, unknown, AnyAction>;

export const saveRequestedResourceConfigs =
  (resourceType: ResourcePageType): Thunk =>
  (dispatch, getState) => {
    const { resourceSaveStatuses } = getState().resourceSaving;
    const resourceIdsrequestedToSave = getResourceIdsRequestedToSave(resourceSaveStatuses);
    for (const resourceId of resourceIdsrequestedToSave) {
      saveResource(resourceType, resourceId, dispatch);
    }
  };

export const saveResourceConfig =
  (resourceType: ResourcePageType, resourceId: number): Thunk =>
  (dispatch) => {
    saveResource(resourceType, resourceId, dispatch);
  };

const saveResource = (
  resourceType: ResourcePageType,
  resourceId: number,
  dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>,
) => {
  dispatch(setResourceSaveStatus({ resourceId, resourceSaveStatus: SaveStatus.REQUEST_IN_FLIGHT }));
  if (resourceType === ResourcePageType.EXPLORE) dispatch(saveExploreDraft(resourceId));
  else if (resourceType === ResourcePageType.REPORT_BUILDER) dispatch(saveReportBuilderDraft());
};

type EditResponse = {
  configuration: DashboardVersionConfig;
  edit_version_number: number;
  resource_id: number;
};

export const saveExploreDraft = createAsyncThunk<EditResponse, number, { state: ReduxState }>(
  ACTION.SAVE_DASHBOARD_DRAFT,
  async (dashboardId, { getState, dispatch }) => {
    const { dashboardEditConfig } = getState();
    const { config, versionInfo, versionHierarchy } = dashboardEditConfig;
    const configToSave = RD.isSuccess(versionHierarchy)
      ? versionHierarchy.data.dashboardVersions[dashboardId].configuration
      : config;
    const versionInfoToSave = RD.isSuccess(versionHierarchy)
      ? versionHierarchy.data.dashboardVersions[dashboardId]
      : versionInfo;
    let requestConfig: AxiosRequestConfig | null = null;

    if (config && versionInfoToSave && dashboardId) {
      const postData = {
        config: configToSave,
        version_number: versionInfoToSave.version_number,
        edit_version_number: versionInfoToSave.edit_version_number,
      };
      const url = `dashboards/${dashboardId}/save_draft/`;

      requestConfig = createApiRequestConfig(url, 'POST', postData);
    }

    return makeThunkRequest<EditResponse>(requestConfig, 'Error saving dashboard draft', {
      onError: (response) => handleSaveError(dispatch, response, dashboardId),
    });
  },
);

const REPORT_BUILDER_NOT_LOADED_ERROR_MESSAGE =
  'Report builder has not be loaded yet and no versions should be attempted to be saved.';

export const saveReportBuilderDraft = createAsyncThunk<
  EditResponse | { new_version: ReportBuilderVersion; resource_id: number },
  undefined,
  { state: ReduxState }
>(ACTION.SAVE_REPORT_BUILDER_DRAFT, async (_, { getState, dispatch }) => {
  const { reportBuilderEdit, reportBuilder } = getState();
  const { config, versionInfo } = reportBuilderEdit;
  const reportBuilderId = RD.getOrDefault(reportBuilder.currentReportBuilder, undefined)?.id;
  if (!reportBuilderId) {
    throw new Error(REPORT_BUILDER_NOT_LOADED_ERROR_MESSAGE);
  }

  let requestConfig: AxiosRequestConfig | null = null;

  if (isSuccess(config) && versionInfo && reportBuilderId) {
    const sharedData = {
      config: config.data,
      version_number: versionInfo.version_number,
    };
    if (versionInfo.is_draft) {
      const postData = {
        ...sharedData,
        edit_version_number: versionInfo.edit_version_number,
      };
      const url = `report_builder/${reportBuilderId}/save_draft/`;
      requestConfig = createApiRequestConfig(url, 'POST', postData);
    } else {
      const url = `report_builder/${reportBuilderId}/create_new_version/`;
      requestConfig = createApiRequestConfig(url, 'POST', sharedData);
    }
  }

  return makeThunkRequest(requestConfig, 'Error saving report builder draft', {
    onError: (response) => handleSaveError(dispatch, response, reportBuilderId),
  });
});

export const createReportBuilderDraft = createAsyncThunk<
  { new_version: ReportBuilderVersion; resource_id: number },
  { config: ReportBuilderConfig },
  { state: ReduxState }
>(ACTION.CREATE_REPORT_BUILDER_DRAFT, async ({ config }, { getState, dispatch }) => {
  const { reportBuilder, reportBuilderEdit } = getState();
  const reportBuilderId = RD.getOrDefault(reportBuilder.currentReportBuilder, undefined)?.id;
  if (!reportBuilderId || !reportBuilderEdit.versionInfo) {
    throw new Error(REPORT_BUILDER_NOT_LOADED_ERROR_MESSAGE);
  }

  let requestConfig: AxiosRequestConfig | null = null;

  requestConfig = createApiRequestConfig(
    `report_builder/${reportBuilderId}/create_new_version/`,
    'POST',
    { config, version_number: reportBuilderEdit.versionInfo.version_number },
  );

  return makeThunkRequest(requestConfig, 'Error creating report builder draft', {
    onError: (response) => handleSaveError(dispatch, response, reportBuilderId),
  });
});

const handleSaveError = (
  dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>,
  response: ErrorResponse,
  resourceId: number,
) =>
  dispatch(
    setSaveError({
      errorMessage:
        response.error_msg ??
        'Something went wrong saving your draft. Please refresh the page and try again.',
      resourceId: resourceId,
    }),
  );
