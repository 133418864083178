import { ListViewsResponse, DataSourceResourceService } from '@explo-tech/fido-api';
import { AnyAction, ThunkAction, createAsyncThunk } from '@reduxjs/toolkit';

import { createApiRequestConfig } from 'actions/actionUtils';
import { FetchAllDataSourceTablesData } from 'actions/dataSourceActions';
import { FetchSchemaTablesMappingData } from 'actions/parentSchemaActions';
import { ACTION } from 'actions/types';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { isJobQueueEnabledForEnvironment } from 'utils/environmentUtils';
import {
  getDefaultDataSource,
  shouldUseFidoForDefaultDataSource,
} from 'utils/fido/fidoRequestUtils';
import { FidoRequestFn, makeFidoThunkRequest, makeThunkRequest } from 'utils/thunkUtils';

import { enqueueDashboardJobsThunk } from './dashboardLayoutThunks';
import { updateNamespace } from './fidoThunks';

export const getTableViews = createAsyncThunk<ListViewsResponse, string, { state: ReduxState }>(
  ACTION.FETCH_TABLES,
  async (namespaceId, { getState }) => {
    const state = getState();
    const defaultDataSourceId = getDefaultDataSource(
      namespaceId,
      RD.getOrDefault(state.parentSchemas.usedParentSchemas, []),
      RD.getOrDefault(state.dataSource.dataSources, []),
      state.teamData.data?.access_groups || [],
    )?.fido_id;

    let requestFn: FidoRequestFn<ListViewsResponse> = null;
    if (defaultDataSourceId) {
      requestFn = () => DataSourceResourceService.getTables(defaultDataSourceId, namespaceId);
    }

    return makeFidoThunkRequest(requestFn, state.fido.fidoToken ?? '', 'Error fetching tables');
  },
);

export const syncTablesThunk =
  (body: {
    schemaId: string;
    ignoreTableNames: string[];
    onSuccess: () => void;
    onError?: (error: string) => void;
  }): ThunkAction<void, ReduxState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const { fido } = getState();

    if (shouldUseFidoForDefaultDataSource(body.schemaId, getState())) {
      if (!RD.isSuccess(fido.fidoDaos)) return;

      const namespace = fido.fidoDaos.data.namespaces.find(
        (n) => n.id.toString() === body.schemaId,
      );
      const namespaceId = namespace?.fido_id;
      if (!namespace || !namespaceId) return;

      dispatch(
        updateNamespace({
          namespace: {
            id: namespaceId,
            name: namespace.name,
            ignoreTableList: body.ignoreTableNames,
          },
          onSuccess: () =>
            dispatch(
              syncTableViews({
                namespaceId: namespaceId,
                onSuccess: body.onSuccess,
                onError: body.onError,
              }),
            ),
        }),
      );
    } else {
      const onSuccess = () => {
        dispatch(fetchAllTables({}));
        body.onSuccess();
      };

      if (!isJobQueueEnabledForEnvironment()) {
        dispatch(
          syncTables({
            schemaId: body.schemaId,
            ignoreTableNames: body.ignoreTableNames,
            onSuccess,
          }),
        );
      } else {
        dispatch(
          enqueueDashboardJobsThunk({
            jobs: [
              {
                job_type: ACTION.SYNC_SOURCE_TABLES,
                job_args: { id: body.schemaId, ignore_table_names: body.ignoreTableNames },
                onSuccess,
              },
            ],
          }),
        );
      }
    }
  };

export const syncTableViews = createAsyncThunk<
  ListViewsResponse,
  { namespaceId: string; onSuccess: () => void; onError?: (error: string) => void },
  { state: ReduxState }
>(ACTION.SYNC_TABLES, async ({ namespaceId, onSuccess, onError }, { getState }) => {
  const state = getState();
  const defaultDataSourceId = getDefaultDataSource(
    namespaceId,
    RD.getOrDefault(state.parentSchemas.usedParentSchemas, []),
    RD.getOrDefault(state.dataSource.dataSources, []),
    state.teamData.data?.access_groups || [],
  )?.fido_id;

  let requestFn: FidoRequestFn<ListViewsResponse> = null;
  if (defaultDataSourceId) {
    requestFn = () => DataSourceResourceService.syncTables(defaultDataSourceId, namespaceId);
  }

  return makeFidoThunkRequest(
    requestFn,
    state.fido.fidoToken ?? '',
    'Error syncing tables',
    onSuccess,
    // the error message returned by FIDO isn't particularly useful so just show a generic error
    () =>
      onError?.(
        'Something went wrong syncing tables. Please try again, or reach out to support if the problem persists.',
      ),
  );
});

const syncTables = createAsyncThunk<
  {},
  { schemaId: string; ignoreTableNames: string[]; onSuccess: () => void },
  {}
>(ACTION.SYNC_SOURCE_TABLES, async ({ ignoreTableNames, schemaId, onSuccess }) => {
  const requestConfig = createApiRequestConfig('datasources/sync_source_tables/', 'POST', {
    ignore_table_names: ignoreTableNames,
    id: schemaId,
  });

  return makeThunkRequest(requestConfig, 'Error fetching dataset preview row count', { onSuccess });
});

export const fetchAllTables = createAsyncThunk<FetchSchemaTablesMappingData, {}, {}>(
  ACTION.FETCH_ALL_SCHEMA_TABLES_FOR_TEAM,
  async () => {
    const requestConfig = createApiRequestConfig('schema/all_tables/', 'GET', undefined);

    return makeThunkRequest(requestConfig, 'Error fetching dataset preview row count');
  },
);

export const fetchAllDataSourceTablesThunk =
  (body: { schemaId: string }): ThunkAction<void, ReduxState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const { fido } = getState();

    if (shouldUseFidoForDefaultDataSource(body.schemaId, getState())) {
      if (!RD.isSuccess(fido.fidoDaos)) return;

      const namespace = fido.fidoDaos.data.namespaces.find(
        (n) => n.id.toString() === body.schemaId,
      );
      const namespaceId = namespace?.fido_id;
      if (!namespace || !namespaceId) return;

      dispatch(getTableViews(namespaceId));
    } else {
      if (!isJobQueueEnabledForEnvironment()) {
        dispatch(
          fetchAllDataSourceTables({
            schemaId: body.schemaId,
          }),
        );
      } else {
        dispatch(
          enqueueDashboardJobsThunk({
            jobs: [
              {
                job_type: ACTION.FETCH_ALL_DATA_SOURCE_TABLES,
                job_args: { id: body.schemaId },
              },
            ],
          }),
        );
      }
    }
  };

export const fetchAllDataSourceTables = createAsyncThunk<
  FetchAllDataSourceTablesData,
  {
    schemaId: string;
  },
  {}
>(ACTION.FETCH_ALL_DATA_SOURCE_TABLES, async ({ schemaId }) => {
  const requestConfig = createApiRequestConfig(
    `datasources/${schemaId}/all_tables/`,
    'GET',
    undefined,
    undefined,
    undefined,
  );

  return makeThunkRequest(requestConfig, 'Error fetching dataset preview row count');
});
export { shouldUseFidoForDefaultDataSource };
