import cx from 'classnames';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

import { ReportBuilderConfig } from 'actions/reportBuilderConfigActions';
import { AlertModal, Button, IconButton, Intent, sprinkles, Tag, Tooltip } from 'components/ds';
import { AddDatasetButton } from 'components/resource/AddDatasetButton';
import { ColumnHeader } from 'components/resource/ColumnHeader';
import { DatasetPreviewTable } from 'components/resource/DatasetPreviewTable';
import { SearchInput } from 'components/SearchInput/SearchInput';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { DatasetItem } from 'pages/ReportBuilderEditor/DatasetEditor/DatasetItem';
import {
  clearDuplicateColumns,
  clearVersionedComputedViewReferences,
  createReportBuilderDataset,
} from 'reducers/reportBuilderEditReducer';
import { ReduxState } from 'reducers/rootReducer';
import { createComputedView } from 'reducers/thunks/fidoThunks';
import * as RD from 'remotedata';
import { fetchAppDataset } from 'reportBuilderContent/thunks/appDataThunks';
import { showDuplicateColumnNameToast } from 'shared/sharedToasts';
import { ResourcePageType, ResourceType } from 'types/exploResource';
import { filterReportBuilderDatasets } from 'utils/adHocUtils';
import { getReportBuilderSchema } from 'utils/reportBuilderConfigUtils';
import { useQuery } from 'utils/routerUtils';
import { sortBy } from 'utils/standard';

import { EditorLeftColumn } from '../EditorLeftColumn';

import {
  addGlobalDatasetReference,
  updateReferencedGlobalDatasetVersion,
} from 'actions/datasetActions';
import { ParentSchema } from 'actions/dataSourceActions';
import {
  LATEST_VERSION_DELETED_TOOLTIP_TEXT,
  UPDATE_VERSION_BUTTON_TOOLTIP_TEXT,
} from 'components/DataLibrary/constants';
import { GlobalDatasetVersionComparisonModal } from 'components/DataLibrary/GlobalDatasetVersionComparisonModal';
import { ImportGlobalDatasetModal } from 'components/DataLibrary/ImportGlobalDatasetModal';
import { SelectDatasetVersionModal } from 'components/DataLibrary/SelectDatasetVersionModal';
import { ROUTE_PROVIDERS } from 'constants/routes';
import { getEmbeddoSchemaFromView } from 'pages/dashboardPage/dashboardDatasetEditor/utils';
import { FetchOrigin } from 'reducers/thunks/dashboardDataThunks/types';
import { getResourceHistoryThunk } from 'reducers/thunks/fidoThunks/resourceThunks';
import { DATA_PREVIEW_TYPE } from 'reportBuilderContent/apiTypes';
import { isArrayASupersetOfAnother } from 'utils/arrayUtils';
import { DatasetConfig } from './DatasetConfig';
import * as styles from './DatasetEditor.css';
import { useColorCategoryTracker } from './useColorCategoryTracker';
import { DeleteDatasetModal } from './DeleteModal';
import { deleteIcon } from 'components/PanelComponents/styles.css';

type Props = {
  config: ReportBuilderConfig;
  reportBuilderId: number;
};

type SelectedDatasetInfo = { id: string; isNew: boolean };

type UpdateGlobalDatasetVersionInfo = {
  globalDatasetId: string;
  currentVersionId: string;
  newVersionId: string;
};

export const DatasetEditor: FC<Props> = ({ config, reportBuilderId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const [searchQuery, setSearchQuery] = useState('');

  const [selectedDatasetInfo, setSelectedDatasetInfo] = useState<SelectedDatasetInfo | null>(null);
  const [isImportGlobalDatasetModalOpen, setIsImportGlobalDatasetModalOpen] = useState(false);
  const [globalDatasetVersionUpdateInfo, setUpdateGlobalDatasetVersionInfo] =
    useState<UpdateGlobalDatasetVersionInfo | null>(null);
  const [
    updateGlobalDatasetVersionWarningModalOpen,
    setUpdateGlobalDatasetVersionWarningModalOpen,
  ] = useState(false);
  const [globalDatasetToVersionId, setGlobalDatasetToVersionId] = useState<string | undefined>();
  const [datasetIdToDelete, setDatasetIdToDelete] = useState<string | undefined>();

  const {
    datasetData,
    globalStyleConfig,
    shouldUseFido,
    schemas,
    referencedGlobalDatasets,
    latestReferencedGlobalDatasets,
    deletedLatestReferencedGlobalDatasetIds,
    orderedComputedViewVersionsByDatasetId,
    mainBranch,
    enableDataLibraryV2,
    enableDataLibraryDebugActions,
  } = useSelector(
    (state: ReduxState) => ({
      datasetData: selectedDatasetInfo
        ? state.reportBuilderEdit.datasetData[selectedDatasetInfo.id]
        : undefined,
      globalStyleConfig: state.embeddedReportBuilder.styleConfig,
      shouldUseFido: state.currentUser.team?.feature_flags.use_fido,
      schemas: state.parentSchemas,
      referencedGlobalDatasets: RD.getOrDefault(state.fido.referencedGlobalDatasets, {}),
      latestReferencedGlobalDatasets: RD.getOrDefault(
        state.fido.latestReferencedGlobalDatasets,
        {},
      ),
      deletedLatestReferencedGlobalDatasetIds: state.fido.deletedLatestReferencedGlobalDatasetIds,
      orderedComputedViewVersionsByDatasetId: state.fido.orderedComputedViewVersionsByDatasetId,
      mainBranch: state.dataLibrary.mainBranch,
      enableDataLibraryV2: state.currentUser.team?.feature_flags.enable_data_library_v2,
      enableDataLibraryDebugActions:
        state.currentUser.team?.feature_flags.enable_data_library_debug_actions,
    }),
    shallowEqual,
  );

  const selectedDataset = selectedDatasetInfo ? config.datasets[selectedDatasetInfo.id] : undefined;

  const colorCategoryTracker = useColorCategoryTracker(
    globalStyleConfig,
    selectedDataset?.columnConfigs,
    datasetData?.rows,
  );

  const filteredAndOrderedDatasets = useMemo(
    () =>
      sortBy(
        filterReportBuilderDatasets(searchQuery, Object.values(config.datasets)),
        (dataset) => dataset.name,
      ),
    [searchQuery, config],
  );

  const currentDatasetNames = useMemo(() => {
    return new Set(Object.values(config.datasets).map((dataset) => dataset.name));
  }, [config.datasets]);

  const switchSelectedDataset = useCallback(
    (datasetId: string, isNew?: boolean) => {
      dispatch(
        fetchAppDataset({
          datasetId,
          switchedToDataset: true,
          dataPreviewType: DATA_PREVIEW_TYPE.FULL_PREVIEW,
        }),
      );
      history.replace(
        ROUTE_PROVIDERS.REPORT_BUILDER_DATASET_EDIT(String(reportBuilderId), datasetId),
      );
      setSelectedDatasetInfo({ id: datasetId, isNew: isNew ?? false });
    },
    [dispatch, history, reportBuilderId],
  );

  useEffect(() => {
    if (selectedDatasetInfo || filteredAndOrderedDatasets.length === 0) return;

    const id = query.get('id');
    const dataset = config.datasets[id ?? ''] ?? filteredAndOrderedDatasets[0];
    switchSelectedDataset(dataset.id);
  }, [
    query,
    config.datasets,
    switchSelectedDataset,
    selectedDatasetInfo,
    filteredAndOrderedDatasets,
  ]);

  useEffect(() => {
    if (!selectedDatasetInfo || !datasetData?.duplicateColumns) return;
    dispatch(clearDuplicateColumns(selectedDatasetInfo.id));
    showDuplicateColumnNameToast(datasetData.duplicateColumns, true);
  }, [dispatch, datasetData?.duplicateColumns, selectedDatasetInfo]);

  const schema = useMemo(
    () => selectedDataset && getReportBuilderSchema(datasetData?.schema, selectedDataset, true),
    [datasetData?.schema, selectedDataset],
  );

  const handleAddDataset = useCallback(
    (name: string, parentSchema: ParentSchema) => {
      const newId = uuidv4();

      if (shouldUseFido) {
        dispatch(
          createComputedView({
            name,
            namespace: RD.getOrDefault(schemas.usedParentSchemas, []).find(
              (s) => s.id === parentSchema.id,
            ),
            resourceType: ResourcePageType.REPORT_BUILDER,
            datasetId: newId,
            onSuccess: () => switchSelectedDataset(newId, true),
          }),
        );
      } else {
        dispatch(createReportBuilderDataset({ id: newId, name, parentSchemaId: parentSchema.id }));
        switchSelectedDataset(newId, true);
      }
    },
    [dispatch, schemas.usedParentSchemas, shouldUseFido, switchSelectedDataset],
  );

  const handlePageChange = useCallback(
    (page: number) => {
      return (
        selectedDataset &&
        dispatch(
          fetchAppDataset({
            datasetId: selectedDataset.id,
            page,
            dataPreviewType: DATA_PREVIEW_TYPE.FULL_PREVIEW,
          }),
        )
      );
    },
    [dispatch, selectedDataset],
  );

  const noDatasetSelectedDiv = () => {
    return (
      <div className={styles.emptyContainer} style={{ fontSize: 20 }}>
        Select a Dataset
      </div>
    );
  };

  const handleHistoryButtonClicked = useCallback(
    (globalDatasetId: string) => {
      setGlobalDatasetToVersionId(globalDatasetId);
      const orderedComputedViewVersionsForDataset =
        orderedComputedViewVersionsByDatasetId[globalDatasetId];

      if (
        (orderedComputedViewVersionsForDataset &&
          !RD.isIdle(orderedComputedViewVersionsForDataset)) ||
        !RD.isSuccess(mainBranch)
      ) {
        return;
      }

      dispatch(
        getResourceHistoryThunk({
          branchId: mainBranch.data.id ?? '',
          resourceId: globalDatasetId,
          page: 0,
          resourceType: ResourceType.REPORT,
        }),
      );
    },
    [orderedComputedViewVersionsByDatasetId, mainBranch, dispatch, setGlobalDatasetToVersionId],
  );

  const renderedContent = useMemo(
    () =>
      filteredAndOrderedDatasets.map((dataset) => {
        const isSelected = dataset.id === selectedDatasetInfo?.id;
        const referencedGlobalDataset = referencedGlobalDatasets[dataset.id];

        if (referencedGlobalDataset) {
          const globalDatasetId = referencedGlobalDataset.id ?? '';
          const latestReferencedGlobalDataset = latestReferencedGlobalDatasets[globalDatasetId];
          const hasVersionMismatch =
            latestReferencedGlobalDataset &&
            referencedGlobalDataset.versionId !== latestReferencedGlobalDataset?.versionId;
          const isLatestVersionDeleted =
            deletedLatestReferencedGlobalDatasetIds.has(globalDatasetId);
          return (
            <DatasetItem
              additionalRightSideButtonContainer={
                <div className={sprinkles({ flexItems: 'alignCenter' })}>
                  <IconButton
                    className={deleteIcon}
                    name="trash-can"
                    onClick={() => setDatasetIdToDelete(dataset.id)}
                    tooltipProps={{ text: `Delete Dataset ${dataset.name}` }}
                  />
                  {hasVersionMismatch ? (
                    <IconButton
                      className={sprinkles({ color: 'warningBold' })}
                      name="circle-up"
                      onClick={() => {
                        setUpdateGlobalDatasetVersionInfo({
                          globalDatasetId: globalDatasetId,
                          currentVersionId: referencedGlobalDataset.versionId ?? '',
                          newVersionId: latestReferencedGlobalDataset?.versionId ?? '',
                        });
                      }}
                      tooltipProps={{ text: UPDATE_VERSION_BUTTON_TOOLTIP_TEXT }}
                    />
                  ) : null}
                  <IconButton
                    name="rectangle-history"
                    onClick={() => {
                      handleHistoryButtonClicked(globalDatasetId);
                    }}
                    tooltipProps={{
                      text: 'Select a different version',
                    }}
                  />
                  {isLatestVersionDeleted ? (
                    <Tooltip text={LATEST_VERSION_DELETED_TOOLTIP_TEXT}>
                      <Tag intent={Intent.WARNING} leftIcon="circle-exclamation" />
                    </Tooltip>
                  ) : null}
                </div>
              }
              dataset={dataset}
              infoIcon="globe"
              isSelected={globalDatasetId === selectedDatasetInfo?.id}
              key={globalDatasetId}
              onClick={() => {
                if (globalDatasetId === selectedDatasetInfo?.id) return;
                switchSelectedDataset(globalDatasetId);
              }}
            />
          );
        } else {
          return (
            <DatasetItem
              additionalRightSideButtonContainer={
                <div className={sprinkles({ flexItems: 'alignCenter' })}>
                  <IconButton
                    className={deleteIcon}
                    name="trash-can"
                    onClick={() => setDatasetIdToDelete(dataset.id)}
                    tooltipProps={{ text: `Delete Dataset ${dataset.name}` }}
                  />
                </div>
              }
              dataset={dataset}
              infoIcon="table"
              isSelected={isSelected}
              key={dataset.id}
              onClick={() => {
                if (isSelected) return;
                switchSelectedDataset(dataset.id);
              }}
            />
          );
        }
      }),
    [
      filteredAndOrderedDatasets,
      selectedDatasetInfo?.id,
      referencedGlobalDatasets,
      latestReferencedGlobalDatasets,
      deletedLatestReferencedGlobalDatasetIds,
      switchSelectedDataset,
      setUpdateGlobalDatasetVersionInfo,
      handleHistoryButtonClicked,
    ],
  );

  const hasDatasetsToShow = useMemo(() => {
    return filteredAndOrderedDatasets.length > 0;
  }, [filteredAndOrderedDatasets]);

  // TODO(zifanxiang): Investigate what is triggering the re-render when the preview data request
  // from the comparison modal is being made. This memoization of the variable mappings and variable
  // maps prevents additional re-renders of the GlobalDatasetVersionComparisonModal.
  const allVariableMappings = useMemo(() => {
    return {};
  }, []);

  const variablesMap = useMemo(() => {
    return {};
  }, []);

  const selectedGlobalDataset = referencedGlobalDatasets[selectedDatasetInfo?.id ?? ''];

  return (
    <div className={styles.root}>
      <EditorLeftColumn>
        <div
          className={cx(styles.datasetListContainer, {
            [sprinkles({ height: 'fill' })]: !filteredAndOrderedDatasets.length,
          })}>
          {enableDataLibraryV2 ? (
            <div className={sprinkles({ flexItems: 'alignCenter', margin: 'sp1' })}>
              <AddDatasetButton
                datasetNames={currentDatasetNames}
                onSubmit={handleAddDataset}
                textOverride="Add local dataset"
                variantOverride="secondary"
              />
              <Button
                fillWidth
                className={sprinkles({ marginLeft: 'sp1' })}
                onClick={() => setIsImportGlobalDatasetModalOpen(true)}>
                Import global dataset
              </Button>
            </div>
          ) : (
            <AddDatasetButton
              className={sprinkles({ marginBottom: 'sp1' })}
              datasetNames={currentDatasetNames}
              onSubmit={handleAddDataset}
            />
          )}
          {enableDataLibraryDebugActions ? (
            <div className={sprinkles({ marginX: 'sp1', marginBottom: 'sp1' })}>
              <Button
                fillWidth
                onClick={() => dispatch(clearVersionedComputedViewReferences())}
                variant="destructive">
                Clear Global Datasets
              </Button>
            </div>
          ) : null}
          <SearchInput
            className={sprinkles({ paddingX: 'sp1' })}
            onInputChanged={setSearchQuery}
            searchQuery={searchQuery}
          />
          {renderedContent}
          {hasDatasetsToShow ? null : (
            <EmbedText
              body="b1"
              className={sprinkles({ parentContainer: 'fill', flexItems: 'centerColumn' })}
              color="contentSecondary">
              No datasets.
            </EmbedText>
          )}
        </div>
      </EditorLeftColumn>
      <div className={styles.configMenu} style={{ width: 545, minWidth: 545 }}>
        {selectedDataset ? (
          <DatasetConfig
            backingGlobalDataset={selectedGlobalDataset}
            dataset={selectedDataset}
            isNew={selectedDatasetInfo?.isNew ?? false}
            latestBackingGlobalDataset={latestReferencedGlobalDatasets[selectedDataset.id ?? '']}
          />
        ) : (
          noDatasetSelectedDiv()
        )}
      </div>
      <div className={styles.tableView}>
        {selectedDataset ? (
          <>
            <ColumnHeader title="Preview" />
            <DatasetPreviewTable
              colorTracker={colorCategoryTracker}
              currentQuery={selectedDataset.queryDraft ?? selectedDataset.query}
              dataset={selectedDataset}
              error={datasetData?.error}
              handlePageChange={handlePageChange}
              isLoading={!!datasetData?.loading}
              rowCount={datasetData?.rowCount}
              rows={datasetData?.rows}
              schema={schema}
              unsupportedOperations={datasetData?.unsupportedOperations}
            />
          </>
        ) : (
          noDatasetSelectedDiv()
        )}
      </div>
      {isImportGlobalDatasetModalOpen ? (
        <ImportGlobalDatasetModal
          shouldSkipVariableMapping
          allVariableMappings={{}}
          currentDatasetNames={currentDatasetNames}
          globalDatasetReferences={config.versionedComputedViewReferences}
          onClose={() => setIsImportGlobalDatasetModalOpen(false)}
          onImportGlobalDataset={(computedView) => {
            const parentSchema = getEmbeddoSchemaFromView(
              RD.getOrDefault(schemas.usedParentSchemas, []),
              computedView,
            );

            if (!parentSchema) {
              return;
            }

            dispatch(
              addGlobalDatasetReference({
                newDataset: computedView,
                resourceType: ResourceType.REPORT,
                parentSchema,
              }),
            );
          }}
        />
      ) : null}
      {globalDatasetVersionUpdateInfo ? (
        <GlobalDatasetVersionComparisonModal
          allVariableMappings={allVariableMappings}
          currentGlobalDataset={
            referencedGlobalDatasets[globalDatasetVersionUpdateInfo.globalDatasetId]
          }
          fetchOrigin={FetchOrigin.REPORT_BUILDER}
          newestGlobalDataset={
            latestReferencedGlobalDatasets[globalDatasetVersionUpdateInfo.globalDatasetId]
          }
          onClose={() => setUpdateGlobalDatasetVersionInfo(null)}
          onUpdateVersion={(globalDataset, previousGlobalDataset) => {
            const newPropertySchema = globalDataset.columnDefinitions;
            const previousPropertySchema = previousGlobalDataset.columnDefinitions;
            const doesNewSchemaContainAllPreviousColumns = isArrayASupersetOfAnother(
              newPropertySchema,
              previousPropertySchema,
              (firstColumn, secondColumn) => {
                return (
                  firstColumn.name === secondColumn.name && firstColumn.type === secondColumn.type
                );
              },
            );

            if (!doesNewSchemaContainAllPreviousColumns) {
              setUpdateGlobalDatasetVersionWarningModalOpen(true);
            } else {
              dispatch(
                updateReferencedGlobalDatasetVersion({
                  newDataset: globalDataset,
                  newVariableMappings: allVariableMappings,
                  resourceType: ResourceType.REPORT,
                }),
              );
              setUpdateGlobalDatasetVersionInfo(null);
              // Make a request to fetch the preview of the new version of the dataset.
              dispatch(
                fetchAppDataset({
                  datasetId: globalDataset.id ?? '',
                  dataPreviewType: DATA_PREVIEW_TYPE.FULL_PREVIEW,
                }),
              );
            }
          }}
          variablesMap={variablesMap}
        />
      ) : null}
      {updateGlobalDatasetVersionWarningModalOpen ? (
        <AlertModal
          isOpen
          actionButtonProps={{
            text: 'Update',
            variant: 'primary',
            onClick: () => {
              if (!globalDatasetVersionUpdateInfo) {
                return;
              }

              const newGlobalDataset =
                latestReferencedGlobalDatasets[globalDatasetVersionUpdateInfo.globalDatasetId];
              dispatch(
                updateReferencedGlobalDatasetVersion({
                  newDataset: newGlobalDataset,
                  newVariableMappings: allVariableMappings,
                  resourceType: ResourceType.REPORT,
                }),
              );
              // Make a request to fetch the preview of the new version of the dataset.
              dispatch(
                fetchAppDataset({
                  datasetId: newGlobalDataset.id ?? '',
                  dataPreviewType: DATA_PREVIEW_TYPE.FULL_PREVIEW,
                }),
              );

              setUpdateGlobalDatasetVersionWarningModalOpen(false);
              setUpdateGlobalDatasetVersionInfo(null);
            },
          }}
          onClose={() => {
            setUpdateGlobalDatasetVersionWarningModalOpen(false);
            setUpdateGlobalDatasetVersionInfo(null);
          }}
          title="Are you sure you want to update this global dataset?">
          The new query schema is missing columns that were present in the previous schema. Customer
          reports that are using this dataset will not be able to load the correct data anymore.
        </AlertModal>
      ) : null}
      {globalDatasetToVersionId ? (
        <SelectDatasetVersionModal
          currentDataset={referencedGlobalDatasets[globalDatasetToVersionId ?? '']}
          onClose={() => setGlobalDatasetToVersionId(undefined)}
          onSubmitVersionSelection={(newGlobalDataset) => {
            dispatch(
              updateReferencedGlobalDatasetVersion({
                newDataset: newGlobalDataset,
                newVariableMappings: allVariableMappings,
                resourceType: ResourceType.REPORT,
              }),
            );
            dispatch(
              fetchAppDataset({
                datasetId: newGlobalDataset.id ?? '',
                dataPreviewType: DATA_PREVIEW_TYPE.FULL_PREVIEW,
              }),
            );
          }}
          orderedComputedViewVersions={
            orderedComputedViewVersionsByDatasetId[globalDatasetToVersionId]
          }
        />
      ) : null}
      {datasetIdToDelete ? (
        <DeleteDatasetModal
          datasetId={datasetIdToDelete}
          isGlobalDatasetBacked={referencedGlobalDatasets[datasetIdToDelete] !== undefined}
          onClose={() => setDatasetIdToDelete(undefined)}
        />
      ) : null}
    </div>
  );
};
