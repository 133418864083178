import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleElementVisibilityForSecondaryLayout } from 'actions/dashboardV2Actions';
import { DashboardElementVisibilityCard } from 'components/DashboardElementVisibilityCard';
import { SettingHeader } from 'components/SettingHeader';
import { sprinkles } from 'components/ds';
import { getChartIcon } from 'constants/chartIcons';
import { VIZ_TO_NAME } from 'constants/dataConstants';
import { ReduxState } from 'reducers/rootReducer';
import { VIEW_MODE } from 'types/dashboardTypes';
import { getLayoutFromDashboardVersionConfig } from 'utils/dashboardUtils';
import { groupBy } from 'utils/standard';
import { getDashboardEditConfigWithDrilldowns } from 'reducers/selectors';

type Props = {
  viewMode: VIEW_MODE;
};

export const ElementShowHidePanel: FC<Props> = ({ viewMode }) => {
  const dispatch = useDispatch();

  const config = useSelector((state: ReduxState) => getDashboardEditConfigWithDrilldowns(state));

  const idsInLayout = useMemo(() => {
    if (!config) return new Set([]);
    const layout = getLayoutFromDashboardVersionConfig(config, viewMode);
    return new Set(layout.map((item) => item.i));
  }, [config, viewMode]);

  const groupedDataPanels = useMemo(
    () =>
      groupBy(
        Object.values(config?.data_panels ?? {}),
        (panel) => panel.visualize_op.operation_type,
      ),
    [config?.data_panels],
  );

  if (config === undefined) return <div>Error</div>;

  return (
    <>
      <SettingHeader name="Elements" />
      <div className={sprinkles({ padding: 'sp1' })}>
        {Object.values(config.elements ?? {}).map((element) => (
          <DashboardElementVisibilityCard
            isSelected={idsInLayout.has(element.id)}
            key={element.id}
            name={element.name}
            onClick={() =>
              dispatch(
                toggleElementVisibilityForSecondaryLayout({
                  id: element.id,
                  layoutType: viewMode,
                }),
              )
            }
          />
        ))}
      </div>
      {Object.values(groupedDataPanels).map((group) => {
        const opType = group[0].visualize_op.operation_type;
        const opName = VIZ_TO_NAME[opType];

        return (
          <div key={opType}>
            <SettingHeader name={opName} />
            <div className={sprinkles({ padding: 'sp1' })}>
              {group.map((dataPanel) => {
                // filter out drilldowns and other future throw away panels
                if (dataPanel.id.startsWith('_')) return;

                return (
                  <DashboardElementVisibilityCard
                    icon={getChartIcon(dataPanel.visualize_op.operation_type)}
                    isSelected={idsInLayout.has(dataPanel.id)}
                    key={dataPanel.id}
                    name={
                      dataPanel.visualize_op.generalFormatOptions?.headerConfig?.title || opName
                    }
                    onClick={() =>
                      dispatch(
                        toggleElementVisibilityForSecondaryLayout({
                          id: dataPanel.id,
                          layoutType: viewMode,
                        }),
                      )
                    }
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};
