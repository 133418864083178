import cx from 'classnames';
import { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { sprinkles } from 'components/ds';

import * as styles from './index.css';

export type BreadcrumbsHierarchyItem = {
  /**
   * Text to show in breadcrumb item.
   */
  text: string;
  /**
   * Link for internal path to navigate to when breadcrumb item is clicked.
   */
  link?: string | LinkProps['to'];
  onClick?: () => void;
};

export type Props = {
  /**
   * A list of breadcrumb items to display linking to other places in the navigation items.
   * Each item can optionally have a `link` property.
   */
  items: BreadcrumbsHierarchyItem[];
  /**
   * Optional class name for style overrides
   */
  className?: string;
  /**
   * Whether to truncate the middle of the breadcrumb items early at 80px.
   */
  truncateMiddle?: boolean;
};

export const Breadcrumbs: FC<Props> = ({ items, className, truncateMiddle = true }) => {
  return (
    <div
      className={cx(sprinkles({ display: 'flex', overflow: 'hidden', width: 'fill' }), className)}>
      {items.map((item, index) => {
        const isLastItem = index === items.length - 1;
        const hasMaxWidth = truncateMiddle && index !== 0 && !isLastItem;
        return (
          <span className={styles.item} key={index}>
            {item.link ? (
              <Link
                className={cx(styles.linkItem, hasMaxWidth && styles.itemMaxWidth)}
                style={{ textDecoration: 'none' }}
                to={item.link}>
                {item.text}
              </Link>
            ) : (
              <span
                className={cx(
                  item?.onClick ? styles.linkItem : styles.displayItem,
                  hasMaxWidth && styles.itemMaxWidth,
                )}
                onClick={item?.onClick}>
                {item.text}
              </span>
            )}
            {!isLastItem && <span className={styles.divider}> / </span>}
          </span>
        );
      })}
    </div>
  );
};
