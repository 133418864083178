import { FC, useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as RD from 'remotedata';

import { Modal } from 'components/ds';
import { ExploLoadingSpinner } from 'components/ExploLoadingSpinner';
import { ImportDatasetContent } from './ImportDatasetContent';
import { MapVariablesContent } from '../../pages/dashboardPage/dashboardDatasetEditor/modals/components/MapVariablesContent';

import { User } from 'actions/userActions';
import { useLoadRootFolderContents } from 'pages/dataLibraryPage/hooks';
import { getBaseRootFolderForBranch } from 'pages/dataLibraryPage/selectors';
import { updateVariableMappings } from 'reducers/dashboardEditConfigReducer';
import { ReduxState } from 'reducers/rootReducer';
import { GlobalDatasetVariableNameMap } from 'components/DataLibrary/types';
import { ReadAccessComputedView } from 'utils/fido/fidoShimmedTypes';
import { VersionedComputedViewReference } from 'types/dashboardVersionConfig';

export enum StepType {
  SELECT_DATASET,
  MAP_VARIABLES,
}

interface Props {
  currentDatasetNames: Set<string>;
  allVariableMappings: Record<string, GlobalDatasetVariableNameMap>;
  globalDatasetReferences: Record<string, VersionedComputedViewReference>;
  shouldSkipVariableMapping: boolean;

  onImportGlobalDataset: (computedView: ReadAccessComputedView, currentUser: User) => void;
  onClose: () => void;
}

export const ImportGlobalDatasetModal: FC<Props> = ({
  currentDatasetNames,
  allVariableMappings,
  globalDatasetReferences,
  shouldSkipVariableMapping,
  onImportGlobalDataset,
  onClose,
}) => {
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(StepType.SELECT_DATASET);
  const [selectedComputedView, setSelectedComputedView] = useState<
    ReadAccessComputedView | undefined
  >();
  const [currentVariableMappings, setCurrentVariableMappings] =
    useState<GlobalDatasetVariableNameMap>({});

  const { mainBranch, rootFolder, currentUser } = useSelector((state: ReduxState) => {
    return {
      // Only allow importing from the main branch contents for now.
      // TODO(zifanxiang): Have UI to select which branch to import from.
      mainBranch: state.dataLibrary.mainBranch,
      rootFolder: getBaseRootFolderForBranch(state, state.dataLibrary.mainBranch),
      currentUser: state.currentUser,
    };
  }, shallowEqual);

  useLoadRootFolderContents(mainBranch, rootFolder ?? RD.Idle());

  const onSetVariableMappings = useCallback(
    (
      selectedComputedView: ReadAccessComputedView,
      variableMappings: GlobalDatasetVariableNameMap,
    ) => {
      dispatch(
        updateVariableMappings({
          datasetId: selectedComputedView.id ?? '',
          globalDatasetVariableNameMap: variableMappings ?? {},
        }),
      );
      onImportGlobalDataset(selectedComputedView, currentUser);
    },
    [dispatch, currentUser, onImportGlobalDataset],
  );

  const onNewVariableMappingSelected = useCallback(
    (parameterName: string, variableName: string) => {
      const newGlobalToDashboardVariableMappings = {
        ...currentVariableMappings,
        [parameterName]: variableName,
      };
      setCurrentVariableMappings(newGlobalToDashboardVariableMappings);
    },
    [currentVariableMappings, setCurrentVariableMappings],
  );

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      size="xlarge"
      title={currentStep === StepType.SELECT_DATASET ? 'Select data' : 'Variables'}>
      {RD.isSuccess(rootFolder) && RD.isSuccess(mainBranch) ? (
        <>
          {currentStep === StepType.SELECT_DATASET ? (
            <ImportDatasetContent
              currentDatasetNames={currentDatasetNames}
              currentUser={currentUser}
              globalDatasetReferences={globalDatasetReferences}
              mainBranch={mainBranch.data}
              onClose={onClose}
              onImportGlobalDataset={onImportGlobalDataset}
              rootFolder={rootFolder.data}
              selectedComputedView={selectedComputedView}
              setCurrentStep={setCurrentStep}
              setSelectedComputedView={(selectedComputedView) => {
                setSelectedComputedView(selectedComputedView);
                setCurrentVariableMappings(
                  allVariableMappings[selectedComputedView?.id ?? ''] ?? {},
                );
              }}
              shouldSkipVariableMapping={shouldSkipVariableMapping}
            />
          ) : selectedComputedView ? (
            <MapVariablesContent
              globalDatasetVariableMappings={currentVariableMappings}
              onClose={onClose}
              onNewMappingSelected={onNewVariableMappingSelected}
              onSetVariableMappings={onSetVariableMappings}
              selectedComputedView={selectedComputedView}
              setVariableMappingsButtonText="Use dataset"
            />
          ) : null}
        </>
      ) : (
        <ExploLoadingSpinner />
      )}
    </Modal>
  );
};
