import { Button, Checkbox, Popover } from 'components/ds';
import { FC, useState } from 'react';

import { capitalize } from 'utils/standard';
import { RecipientSelect } from 'features/dataShare/recipients/RecipientSelect';
import {
  ALL_CADENCES,
  ALL_STATUSES,
  ExportStatus,
  INITIAL_FILTER_STATE,
  ScheduleFrequency,
} from '../constants';
import { FilterState, RecipientFilterState } from '../types';
import { getAppliedFiltersCount, toggleSetValue } from '../utils';

import * as styles from './styles.css';

type Props = {
  selected: FilterState;
  applied: FilterState;
  setSelected: (state: FilterState) => void;
  setApplied: (state: FilterState) => void;
};

export const FilterPopover: FC<Props> = ({ selected, applied, setSelected, setApplied }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleFilterChange = (
    type: 'cadences' | 'statuses',
    value: ScheduleFrequency | ExportStatus,
  ) => {
    setSelected({
      ...selected,
      [type]: toggleSetValue(selected[type], value),
    });
  };

  const handleRecipientFilterUpdate = (recipientFilters: RecipientFilterState) => {
    setSelected({
      ...selected,
      recipients: recipientFilters,
    });
  };

  const filterContent = (
    <div className={styles.filterContent}>
      <div className={styles.filterSection}>
        <div className={styles.filterSectionLabel}>Cadence</div>
        <div className={styles.filterSectionCheckbox}>
          {ALL_CADENCES.map((cadence) => (
            <Checkbox
              isChecked={selected.cadences.has(cadence)}
              key={cadence}
              label={capitalize(cadence)}
              onChange={() => handleFilterChange('cadences', cadence)}
            />
          ))}
        </div>
      </div>

      <div className={styles.filterSection}>
        <div className={styles.filterSectionLabel}>Status</div>
        <div className={styles.filterSectionCheckbox}>
          {ALL_STATUSES.map((status) => (
            <Checkbox
              isChecked={selected.statuses.has(status)}
              key={status}
              label={capitalize(status)}
              onChange={() => handleFilterChange('statuses', status)}
            />
          ))}
        </div>
      </div>

      <div className={styles.recipientFilterSection}>
        <div className={styles.filterSectionLabel}>Recipients</div>
        <RecipientSelect onUpdate={handleRecipientFilterUpdate} selected={selected.recipients} />
      </div>
    </div>
  );

  const appliedFiltersCount = getAppliedFiltersCount(applied);

  return (
    <Popover
      align="start"
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      primaryButtonProps={{
        text: 'Apply',
        icon: 'filter',
        onClick: () => {
          setApplied(selected);
          setIsOpen(false);
        },
      }}
      secondaryButtonProps={{
        text: 'Reset',
        onClick: () => {
          setSelected(INITIAL_FILTER_STATE);
        },
      }}
      sideOffset={8}
      trigger={
        <Button icon="filter" onClick={() => setIsOpen(!isOpen)} variant="secondary">
          Filters {appliedFiltersCount > 0 ? `(${appliedFiltersCount})` : null}
        </Button>
      }
      width="large">
      {filterContent}
    </Popover>
  );
};
