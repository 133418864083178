import cx from 'classnames';
import { FC, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { deleteReportBuilderDataset, getReportsUsingDataset } from 'actions/reportBuilderActions';
import { AlertModal, Icon, Spinner, sprinkles } from 'components/ds';
import { IconName } from 'components/ds/Icon';
import { Sprinkles } from 'components/ds/sprinkles.css';
import { ReduxState } from 'reducers/rootReducer';
import { getCurrentReportBuilder } from 'reducers/selectors';
import * as RD from 'remotedata';

import * as styles from './DeleteModal.css';
import { deleteGlobalDatasetReference } from 'actions/datasetActions';
import { ResourceType } from 'types/exploResource';

type Props = {
  datasetId: string;
  isGlobalDatasetBacked: boolean;

  onClose: () => void;
};

export const DeleteDatasetModal: FC<Props> = ({ datasetId, isGlobalDatasetBacked, onClose }) => {
  const dispatch = useDispatch();

  const { config, currentReportBuilderId } = useSelector(
    (state: ReduxState) => ({
      config: state.reportBuilderEdit.config,
      currentReportBuilderId: getCurrentReportBuilder(state)?.id,
    }),
    shallowEqual,
  );

  const [reportCount, setReportCount] = useState<RD.ResponseData<number>>(RD.Idle());

  useEffect(() => {
    if (!RD.isIdle(reportCount) || !currentReportBuilderId) return;
    setReportCount(RD.Loading());
    dispatch(
      getReportsUsingDataset(
        { id: currentReportBuilderId, postData: { dataset_id: datasetId } },
        (data) => setReportCount(RD.Success(data.report_count)),
        () => setReportCount(RD.Error('Error loading report usage')),
      ),
    );
  }, [dispatch, reportCount, datasetId, currentReportBuilderId]);

  const builtInsUsingDataset = useMemo(() => {
    if (!RD.isSuccess(config)) return 0;

    return Object.values(config.data.builtInReports ?? {}).filter(
      (builtIn) => builtIn.config.dataInfo?.datasetId === datasetId,
    ).length;
  }, [config, datasetId]);

  const showHeaderWithIcon = (props: HeaderWithIconProps) => {
    return (
      <div
        className={cx(
          styles.header,
          props.noBottomMargin ? undefined : sprinkles({ marginBottom: 'sp2' }),
        )}>
        <Icon className={sprinkles({ color: props.iconColor })} name={props.iconName} />
        <div className={styles.warningTitle}>{props.title}</div>
      </div>
    );
  };

  const renderBuiltInUsage = () => {
    if (builtInsUsingDataset === 0)
      return showHeaderWithIcon({
        title: 'Built ins will be unaffected',
        iconColor: 'success',
        iconName: 'circle-check',
      });

    const isOne = builtInsUsingDataset === 1;
    const title = `${builtInsUsingDataset} built in${isOne ? '' : 's'} use${
      isOne ? 's' : ''
    } this dataset.`;
    return (
      <>
        {showHeaderWithIcon({
          title,
          iconColor: 'warning',
          iconName: 'circle-exclamation',
          noBottomMargin: true,
        })}
        <div className={styles.warningInfoText}>
          Built ins that use this dataset will break. You will have to update them to make them
          useable
        </div>
      </>
    );
  };

  const renderReportUsage = () => {
    return (
      <RD.RemoteComponent
        Error={() =>
          showHeaderWithIcon({
            title: 'Error getting report usage',
            iconColor: 'error',
            iconName: 'circle-exclamation',
          })
        }
        Loading={() => (
          <div className={styles.header}>
            <Spinner size="md" />
            <div className={styles.warningTitle}>Loading report usage</div>
          </div>
        )}
        Success={(count) => {
          if (count === 0)
            return showHeaderWithIcon({
              title: 'User reports will be unaffected',
              iconColor: 'success',
              iconName: 'circle-check',
            });

          const title = `${count} user report${count === 1 ? '' : 's'} use${
            count === 1 ? 's' : ''
          } this dataset.`;
          return (
            <>
              {showHeaderWithIcon({
                title,
                iconColor: 'warning',
                iconName: 'circle-exclamation',
                noBottomMargin: true,
              })}
              <div className={cx(styles.warningInfoText, sprinkles({ marginBottom: 'sp2' }))}>
                User reports that use this dataset will break, your user will be forced to reset
                each report with a new dataset.
              </div>
            </>
          );
        }}
        data={reportCount}
      />
    );
  };

  const renderBody = () => {
    return (
      <>
        <div className={styles.bodyHeader}>
          Once you&rsquo;ve deleted it you won&rsquo;t be able to retrieve it.
        </div>
        {renderReportUsage()}
        {renderBuiltInUsage()}
      </>
    );
  };

  return (
    <AlertModal
      isOpen
      actionButtonProps={{
        disabled: !RD.isSuccess(reportCount),
        onClick: () => {
          dispatch(deleteReportBuilderDataset(datasetId));
          if (isGlobalDatasetBacked) {
            dispatch(
              deleteGlobalDatasetReference({ datasetId, resourceType: ResourceType.REPORT }),
            );
          }
        },
      }}
      onClose={onClose}
      title="Are you sure you want to delete this dataset?">
      <div className={sprinkles({ paddingX: 'sp3' })}>{renderBody()}</div>
    </AlertModal>
  );
};

type HeaderWithIconProps = {
  iconName: IconName;
  iconColor: Sprinkles['color'];
  title: string;
  noBottomMargin?: boolean;
};
