import { Role } from 'features/auth/types';
import { InvitedUser } from 'actions/teamActions';
import { TeamMember, User, UserPermissions } from 'actions/userActions';
import {
  DEFAULT_ROLES,
  PERMISSIONED_ACTIONS,
  PERMISSIONED_ENTITIES,
} from 'constants/roleConstants';

export const doesUserHavePermission = (
  permissions: string[],
  action: PERMISSIONED_ACTIONS,
  isEditingDisabled = false,
) => {
  if (isEditingDisabled) {
    return false;
  }
  return (permissions ?? []).includes(action);
};

export const doesUserHaveSomePermission = (
  permissions: string[],
  actions: PERMISSIONED_ACTIONS[],
) => actions.some((action) => doesUserHavePermission(permissions, action));

export const getUserPermissions = (roles: Role[]) => {
  const userPermissions: UserPermissions = {};

  Object.values(PERMISSIONED_ENTITIES).forEach((entity) => {
    // set to filter duplicates
    const rolesSet = new Set(roles.flatMap((role) => role.permissions[entity] ?? []));
    userPermissions[entity] = Array.from(rolesSet);
  });

  return userPermissions;
};

// for now we only support users having one role, even though the infrastructure supports multiple
export const getTeamMemberRole = (member: TeamMember | InvitedUser | undefined) =>
  (member?.role_names ?? [])[0] ?? DEFAULT_ROLES.VIEWER;

// less generic helpers
export const canUserViewResourceConfigurationMenu = (user: User, entity: PERMISSIONED_ENTITIES) => {
  const userPermissions = user.permissions[entity];
  const userCanConfigureResource = doesUserHaveSomePermission(userPermissions, [
    PERMISSIONED_ACTIONS.UPDATE,
    PERMISSIONED_ACTIONS.CREATE,
    PERMISSIONED_ACTIONS.DELETE,
    PERMISSIONED_ACTIONS.PUBLISH,
    PERMISSIONED_ACTIONS.VIEW_EMBED_SNIPPET,
  ]);

  if (entity === PERMISSIONED_ENTITIES.REPORT_BUILDER || !userCanConfigureResource) {
    return userCanConfigureResource;
  }

  return doesUserHaveSomePermission(userPermissions, [
    PERMISSIONED_ACTIONS.UPDATE,
    PERMISSIONED_ACTIONS.CREATE,
    PERMISSIONED_ACTIONS.DELETE,
  ]);
};

export const canUserModifyResource = (
  resourcePermissions: string[],
  isEditingDisabled = false,
): boolean => {
  if (isEditingDisabled) {
    return false;
  }
  return doesUserHaveSomePermission(resourcePermissions, [
    PERMISSIONED_ACTIONS.CREATE,
    PERMISSIONED_ACTIONS.PUBLISH,
    PERMISSIONED_ACTIONS.DELETE,
    PERMISSIONED_ACTIONS.UPDATE,
  ]);
};
