import { FC, useMemo, useState } from 'react';
import { Modal, Button, Tag, Select, Icon, sprinkles } from 'components/ds';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  currentVersion: number;
  availableVersions: number[];
  selectedResourceIds: string[];
};

const UpdateResourceViewUsageModal: FC<Props> = ({
  isOpen,
  onClose,
  currentVersion,
  availableVersions,
  selectedResourceIds,
}) => {
  const [step, setStep] = useState(1);
  const [selectedVersion, setSelectedVersion] = useState<number>(currentVersion);
  const [currentResourceIndex, setCurrentResourceIndex] = useState(0);

  const handleNext = () => {
    if (step === 1) {
      setStep(2);
    } else {
      if (currentResourceIndex < selectedResourceIds.length - 1) {
        setCurrentResourceIndex(currentResourceIndex + 1);
        setStep(1);
      } else {
        onClose(); // Close the modal after processing all resources
      }
    }
  };

  const dynamicTitle = useMemo(() => {
    return `Promote version (${currentResourceIndex + 1} of ${selectedResourceIds.length})`;
  }, [currentResourceIndex, selectedResourceIds]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="medium" title={dynamicTitle}>
      <div
        className={sprinkles({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        })}>
        <div className={sprinkles({ display: 'flex', alignItems: 'center' })}>
          <Tag intent="active">Current: {currentVersion}</Tag>
          <Icon name="arrow-right" />
          <Select
            onChange={(value) => setSelectedVersion(Number(value))}
            selectedValue={selectedVersion.toString()}
            values={availableVersions.map((version) => ({
              value: version.toString(),
              label: `Version ${version}`,
            }))}
          />
          <Icon name="cross" onClick={onClose} />
        </div>
      </div>
      <div className={sprinkles({ display: 'flex', gap: 'sp2' })}>
        <div className={sprinkles({ flex: 1 })}>
          <Button>Query</Button>
          <Button>Formatting</Button>
        </div>
        <div className={sprinkles({ flex: 3 })}>
          {step === 1 ? (
            <div>SQL Summary for Resource {selectedResourceIds[currentResourceIndex]}</div>
          ) : (
            <div>Placeholder for next step</div>
          )}
        </div>
      </div>
      <div
        className={sprinkles({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        })}>
        <div>
          Promotion may introduce breaking changes. Surfaces will be promoted into draft mode.
        </div>
        <div>
          <span>Step {step} of 2</span>
          <Button onClick={handleNext}>Next</Button>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateResourceViewUsageModal;
