import { Menu, MenuItem } from '@blueprintjs/core';
import * as Popover from '@radix-ui/react-popover';
import cx from 'classnames';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { sprinkles } from 'components/ds';
import { CustomMenuOptionConfig, V2TwoDimensionChartInstructions } from 'constants/types';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { setSelectedDashboardDrilldownInfo } from 'reducers/dashboardInteractionsReducer';
import { ChartMenuInfo, getIsIframe, setChartMenu } from 'reducers/dashboardLayoutReducer';
import { DashboardStates, ReportBuilderStates } from 'reducers/rootReducer';
import { getAllDashboardIdsInHierarchy } from 'reducers/selectors';
import { fetchDrilldownDataThunk } from 'reducers/thunks/dashboardDataThunks/fetchDataPanelThunks';
import { DrilldownVariable } from 'types/dashboardTypes';
import { DrilldownEntryPointInfo } from 'types/dataPanelTemplate';
import { getColorColumn } from 'utils/colorColUtils';
import {
  getDrilldownMenuText,
  getMatchingDashboardDrilldownEntryPoints,
} from 'utils/dashboardDrilldownUtils';
import { isCategorySelected } from 'utils/drilldownUtils';

type Props = {
  canViewUnderlyingData: boolean;
  chartMenuInfo: ChartMenuInfo;
  dataPanelId: string;
  instructions: V2TwoDimensionChartInstructions | undefined;
  customMenuOptions?: CustomMenuOptionConfig[];
  drilldownVar?: DrilldownVariable;
  selectedColorColName?: string;
  excludedCategories?: (string | number)[];
  drilldownEntryPoints: Record<string, DrilldownEntryPointInfo>;
  dashboardIdToNameMap: Record<number, string>;

  setCategorySelect?: (category: string, colorColumn?: string) => void;
};

export const DrilldownChartMenu: FC<Props> = ({
  canViewUnderlyingData,
  chartMenuInfo,
  dataPanelId,
  customMenuOptions,
  instructions,
  selectedColorColName,
  excludedCategories,
  setCategorySelect,
  drilldownVar,
  drilldownEntryPoints,
  dashboardIdToNameMap,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation('DrilldownChartMenu');

  const { layoutId, isIframe, dashboardIds } = useSelector(
    (state: DashboardStates | ReportBuilderStates) => ({
      layoutId: 'dashboardLayout' in state ? state.dashboardLayout.layoutId : null,
      isIframe: 'dashboardLayout' in state ? getIsIframe(state.dashboardLayout) : false,
      dashboardIds: getAllDashboardIdsInHierarchy(state),
    }),
    shallowEqual,
  );

  const onCustomMenuOptionClicked = useCallback(
    (jsEvent: string, category: string, subCategory?: string) => {
      const detail = { category, color: subCategory };
      if (isIframe) {
        window.parent.postMessage({ event: jsEvent, detail }, '*');
      } else {
        window.dispatchEvent(new CustomEvent(jsEvent, { detail }));
      }
    },
    [isIframe],
  );

  const { category, subCategory, chartX, chartY, ignoreCategory } = chartMenuInfo;
  const hasColorOptions = !!instructions?.colorColumnOptions?.length;

  const matchingDrilldownEntryPoints = useMemo(
    () =>
      getMatchingDashboardDrilldownEntryPoints(
        drilldownEntryPoints,
        dashboardIds,
        instructions,
        subCategory,
      ),
    [drilldownEntryPoints, instructions, subCategory, dashboardIds],
  );

  const onUnderlyingDataClick = (category: string, subCategory?: string) => {
    const categoryColumn = instructions?.categoryColumn;
    if (!categoryColumn) return;

    const colorColumn = getColorColumn(instructions, selectedColorColName);

    dispatch(
      fetchDrilldownDataThunk({
        dataPanelId,
        categoryColumn,
        category: ignoreCategory ? undefined : category,
        subCategoryColumn: colorColumn,
        subCategory: hasColorOptions ? subCategory : undefined,
        excludedCategories: category === 'Other' ? excludedCategories : undefined,
      }),
    );
  };

  if (
    !canViewUnderlyingData &&
    !setCategorySelect &&
    !customMenuOptions?.length &&
    !matchingDrilldownEntryPoints.length
  )
    return null;

  const isCurrentCategorySelected = isCategorySelected(drilldownVar, category, subCategory);
  return (
    <Popover.Root onOpenChange={(open) => !open && dispatch(setChartMenu(null))} open={true}>
      <Popover.Anchor asChild>
        <div
          className={sprinkles({ position: 'absolute' })}
          style={{ left: chartX, top: chartY }}
        />
      </Popover.Anchor>
      <Popover.Portal container={layoutId ? document.getElementById(layoutId) : undefined}>
        {/* Added key so it repaints if another part of the chart is clicked */}
        <Popover.Content
          align="start"
          key={`${chartX}-${chartY}`}
          onClick={() => dispatch(setChartMenu(null))}
          side="right"
          style={{ zIndex: 10 }}>
          <Menu
            className={cx(
              sprinkles({ boxShadow: 'md' }),
              embedSprinkles({ body: 'primaryWithoutColor' }),
            )}>
            {canViewUnderlyingData ? (
              <MenuItem
                onClick={() => onUnderlyingDataClick(category, subCategory)}
                text={t('view_underlying_data')}
              />
            ) : null}
            {setCategorySelect ? (
              <MenuItem
                onClick={() =>
                  setCategorySelect(category, hasColorOptions ? subCategory : undefined)
                }
                text={isCurrentCategorySelected ? 'Remove Filter' : 'Filter Category'}
              />
            ) : null}
            {matchingDrilldownEntryPoints.map(([entryPointId, entryPointInfo]) => {
              const destinationDashboardName =
                dashboardIdToNameMap[entryPointInfo.destinationDashboardId];
              return (
                <MenuItem
                  key={entryPointId}
                  onClick={() => {
                    dispatch(
                      setSelectedDashboardDrilldownInfo({
                        drilldownEntryPointId: entryPointId,
                        sourceDataPanelId: dataPanelId,
                        selectedPrimaryField: category,
                        selectedSecondaryField: subCategory,
                        selectedPrimaryColumnName: instructions?.categoryColumn?.column?.name ?? '',
                        selectedSecondaryColumnName: selectedColorColName,
                      }),
                    );
                  }}
                  text={getDrilldownMenuText(destinationDashboardName, entryPointInfo)}
                />
              );
            })}
            {customMenuOptions?.map((menuOption) =>
              menuOption.name && menuOption.customJSEventName ? (
                <MenuItem
                  key={menuOption.name}
                  onClick={() =>
                    onCustomMenuOptionClicked(
                      menuOption.customJSEventName as string,
                      category,
                      subCategory,
                    )
                  }
                  text={menuOption.name}
                />
              ) : null,
            )}
          </Menu>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
