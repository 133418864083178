import { FetchProfileData } from 'features/auth/types';

export function verifyEmail(key: string, successCallback: () => void, errorCallback?: () => void) {
  fetch(process.env.REACT_APP_API_URL + 'rest-auth/registration/verify-email/', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      key,
    }),
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data.detail !== 'ok') {
        errorCallback && errorCallback();
      } else {
        successCallback();
      }
    });
}

export function fetchProfile(
  successCallback: (data: FetchProfileData) => void,
  failureCallback: () => void,
) {
  try {
    fetch(process.env.REACT_APP_API_URL + 'fetch-profile/', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.detail) {
          failureCallback();
        } else {
          successCallback(data.user);
        }
      });
  } catch {
    failureCallback();
  }
}
