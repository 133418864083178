import { FC, useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { DatasetDataObject } from 'actions/datasetActions';
import MultiSelect from 'components/multiSelect';
import { ReportedAnalyticActionTypes } from 'constants/reportedAnalyticActionTypes';
import { sendAnalyticsEventThunk } from 'telemetry/telemetryThunks';
import { DashboardVariable, DashboardVariableMap, SelectElemConfig } from 'types/dashboardTypes';
import { OnNewValueSelectFunc } from 'types/functionPropTypes';
import { useStringWithVariablesReplaced } from 'utils/dataPanelConfigUtils';
import { constructOptions } from 'utils/dropdownUtils';
import { resolveTooltipVariables } from 'utils/variableUtils';
import { SelectedDropdownInputItem } from 'constants/types';

type Props = {
  config: SelectElemConfig;
  datasetData: DatasetDataObject;
  datasetNamesToId: Record<string, string>;
  onNewValueSelect: OnNewValueSelectFunc;
  value: DashboardVariable;
  disabled?: boolean;
  openElementToLeft: boolean;
  variables: DashboardVariableMap;
  isInContainer?: boolean;
};

const getSelectedOptions = (options: SelectedDropdownInputItem[], value: DashboardVariable) => {
  if (options == null || value == null) return [];

  if (!Array.isArray(value) || !value.length) {
    // Lists of lists need to be parsed from smart quoted strings
    const sanitizedValue = (value as string).replace(/“|”/g, '"');
    const maybeListOfLists = JSON.parse(sanitizedValue);
    if (Array.isArray(maybeListOfLists)) {
      const selectedSet = new Set<string | number | undefined>();
      maybeListOfLists.forEach((val) => selectedSet.add(val as string | number));
      return options.filter((option) => selectedSet.has(option.value));
    }
    return [];
  }
  const selectedSet = new Set<string | number | undefined>();
  value.forEach((val) => selectedSet.add(val as string | number));
  return options.filter((option) => selectedSet.has(option.value));
};

export const DashboardMultiSelectElement: FC<Props> = ({
  config,
  value,
  datasetData,
  datasetNamesToId,
  disabled,
  openElementToLeft,
  onNewValueSelect,
  variables,
  isInContainer,
}) => {
  const dispatch = useDispatch();
  const options = useMemo(
    () => constructOptions(config.valuesConfig, datasetData),
    [config.valuesConfig, datasetData],
  );

  const [selectedOptions, setSelectedOptions] = useState<SelectedDropdownInputItem[]>(
    getSelectedOptions(options ?? [], value),
  );

  useEffect(() => {
    setSelectedOptions(getSelectedOptions(options ?? [], value));
  }, [options, value]);

  const label = useStringWithVariablesReplaced(config.label, datasetNamesToId);

  return (
    <div>
      <MultiSelect
        isEmbed
        disableOnNoItems={config.disableOnNoItems}
        disabled={disabled}
        infoTooltipText={resolveTooltipVariables(config, variables, datasetNamesToId, datasetData)}
        label={label}
        openElementToLeft={openElementToLeft}
        options={options ?? []}
        placeholder={config.placeholderText}
        selectedItems={selectedOptions}
        updateSelectedValues={(newValues) => {
          setSelectedOptions(getSelectedOptions(options ?? [], newValues));
          onNewValueSelect(newValues, { length: newValues?.length ?? 0 });
          dispatch(sendAnalyticsEventThunk(ReportedAnalyticActionTypes.MULTISELECT_SELECTED));
        }}
        usePortal={isInContainer}
      />
    </div>
  );
};
