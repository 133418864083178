export const isArrayASupersetOfAnother = <T>(
  potentialSupersetArray: T[],
  potentialSubsetArray: T[],
  isEqualFn: (firstItem: T, secondItem: T) => boolean,
): boolean => {
  return potentialSubsetArray.every((subsetArrayItem) => {
    return potentialSupersetArray.some((supersetArrayItem) => {
      return isEqualFn(supersetArrayItem, subsetArrayItem);
    });
  });
};
