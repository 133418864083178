import {
  CHART_VISUALIZATION_OPERATIONS_SET,
  KPI_NUMBER_TREND_OPERATION_TYPES,
  OPERATION_TYPES,
} from '@explo/data';

import { DatasetDataObject } from 'actions/datasetActions';
import { V2GeneralInstructions, VisualizeOperationInstructions } from 'constants/types';
import { DashboardVariableMap } from 'types/dashboardTypes';

import { replaceVariablesInString } from './variableUtils';

export const convertHexToRGBA = (hexString: string, alpha: number) => {
  const hex = hexString.substr(1, 10);
  let c;
  let rgbaString = 'rgba(';
  for (let i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    rgbaString += `${c},`;
  }
  rgbaString += `${alpha})`;
  return rgbaString;
};

export const titleCase = (s: string | number) => {
  if (!s) return '';

  s = String(s);

  s = s.replace(/_/g, ' ');

  // WIP - camel case to title case
  // if (s.indexOf(' ') === -1) {
  //   s = s.replace(/([A-Z])/g, ' $1');
  // }
  return capitalizeEachWord(s);
};

const capitalizeEachWord = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getChartTooltipText = (
  opType: OPERATION_TYPES,
  vizInstructions: VisualizeOperationInstructions,
  variables: DashboardVariableMap,
  datasetNameToIds: Record<string, string> = {},
  datasetData: DatasetDataObject,
): string | undefined => {
  const generalFormat: V2GeneralInstructions | undefined =
    getGeneralFormatFromVisualizationInstructions(opType, vizInstructions);
  if (!generalFormat?.showTooltip) {
    return;
  }

  return replaceVariablesInString(
    generalFormat.tooltipText || '',
    variables,
    datasetNameToIds,
    datasetData,
  );
};

// TODO(SHIBA-6002): Write a migration to bring the general format instructions to the visualization
// operation top level.
export const getGeneralFormatFromVisualizationInstructions = (
  operationType: OPERATION_TYPES,
  visualizationInstructions: VisualizeOperationInstructions,
): V2GeneralInstructions | undefined => {
  if (CHART_VISUALIZATION_OPERATIONS_SET.has(operationType)) {
    if (
      operationType === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ||
      operationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
    ) {
      return visualizationInstructions.V2_KPI?.generalFormat;
    } else if (KPI_NUMBER_TREND_OPERATION_TYPES.has(operationType)) {
      return visualizationInstructions.V2_KPI_TREND?.generalFormat;
    } else if (operationType === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2) {
      return visualizationInstructions.V2_SCATTER_PLOT?.generalFormat;
    } else if (operationType === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2) {
      return visualizationInstructions.V2_BOX_PLOT?.generalFormat;
    } else if (
      operationType === OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP ||
      operationType === OPERATION_TYPES.VISUALIZE_DENSITY_MAP
    ) {
      return visualizationInstructions.VISUALIZE_GEOSPATIAL_CHART?.generalFormat;
    } else if (operationType === OPERATION_TYPES.VISUALIZE_SUNBURST) {
      return visualizationInstructions.VISUALIZE_SUNBURST?.generalFormat;
    } else {
      return visualizationInstructions.V2_TWO_DIMENSION_CHART?.generalFormat;
    }
  } else if (operationType === OPERATION_TYPES.VISUALIZE_TABLE) {
    return visualizationInstructions.VISUALIZE_TABLE.generalFormat;
  } else if (operationType === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE) {
    return visualizationInstructions.VISUALIZE_PIVOT_TABLE?.generalFormat;
  } else if (operationType === OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST) {
    return visualizationInstructions.VISUALIZE_COLLAPSIBLE_LIST?.generalFormat;
  } else if (operationType === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2) {
    return visualizationInstructions.VISUALIZE_PIVOT_TABLE_V2?.generalFormat;
  }
};
