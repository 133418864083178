import produce from 'immer';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { OPERATION_TYPES } from '@explo/data';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { SharedValueFormatConfig } from 'components/ChartConfigs/SharedValueFormatConfig';
import { KPIValueFormat, V2KPIChartInstructions } from 'constants/types';
import { configRootClass, configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';

type Props = {
  visualizationType:
    | OPERATION_TYPES.VISUALIZE_NUMBER_V2
    | OPERATION_TYPES.VISUALIZE_PROGRESS_V2
    | OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2
    | OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL;
  instructions: V2KPIChartInstructions;
};

export const KPIValueConfig: FC<Props> = ({ visualizationType, instructions }) => {
  const dispatch = useDispatch();

  const updateFormat = (valueFormat: KPIValueFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      if (valueFormat.numberFormat?.id === V2_NUMBER_FORMATS.ABBREVIATED.id) {
        draft.valueFormat = { ...draft.valueFormat, ...valueFormat, smartAbbreviate: false };
      } else {
        draft.valueFormat = { ...draft.valueFormat, ...valueFormat };
      }
    });
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  return (
    <div className={configRootClass}>
      <SharedValueFormatConfig
        configInputClass={configInputClass}
        operationType={visualizationType}
        updateValueFormat={updateFormat}
        valueFormat={instructions?.valueFormat ?? {}}
      />
    </div>
  );
};
