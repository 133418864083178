import {
  Branch,
  CreateResourceChange,
  Folder,
  Resource,
  UpdateResourceChange,
} from '@explo-tech/fido-api';
import { IconButton, Input, Modal, sprinkles, Tooltip } from 'components/ds';
import { SettingHeader } from 'components/SettingHeader';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { clearPendingChanges, ItemPathInfo, ItemType } from 'reducers/dataLibraryReducer';
import { createCommitThunk } from 'reducers/thunks/fidoThunks/commitThunks';
import { sortBy } from 'utils/standard';
import { COMPUTED_VIEW_TYPE, ROOT_FOLDER_PATH } from './constants';
import { getParentPath, getResourceById } from './dataLibraryUtil';
import { stripIdsFromPendingCreations } from './dataSanitizer';
import { navigateToPathThunk } from './navigationUtils';
import { ResourceControl } from './ResourceControl';
import { DeleteResourceChange, FolderPath, MoveResourceChange } from './types';

import { User } from 'actions/userActions';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { revertPendingDeletion } from 'reducers/dataLibraryReducer';
import { isSuccess, ResponseData } from 'remotedata';
import { ReadAccessComputedView } from 'utils/fido/fidoShimmedTypes';
import { doResourcesHaveContentDifference } from './resourceComparator';

interface Props {
  currentBranch: Branch;
  pendingResourceCreations: CreateResourceChange[];
  pendingResourceUpdates: UpdateResourceChange[];
  pendingResourceDeletions: DeleteResourceChange[];
  isOpen: boolean;
  currentUser: User;
  currentItemPath: ItemPathInfo;
  baseFolders: Map<FolderPath, ResponseData<Folder>>;

  onClose: () => void;
}

export const CreateCommitModal: FC<Props> = ({
  currentBranch,
  pendingResourceCreations,
  pendingResourceDeletions,
  pendingResourceUpdates,
  isOpen,
  currentUser,
  currentItemPath,
  baseFolders,
  onClose,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [commitMessage, setCommitMessage] = useState('');
  const [comparisonResourceId, setComparisonResourceId] = useState('');

  const closeAndResetStateFn = useCallback(() => {
    setCommitMessage('');
    setComparisonResourceId('');
    onClose();
  }, [onClose]);

  const originalResourcesForUpdates = useMemo(() => {
    return pendingResourceUpdates.reduce(
      (resourceByIdMap, pendingUpdate) => {
        const originalResource = getResourceById(pendingUpdate.resource.id ?? '', baseFolders);
        if (!originalResource) {
          return resourceByIdMap;
        }
        resourceByIdMap.set(originalResource.id ?? '', originalResource);
        return resourceByIdMap;
      },
      new Map() as Map<string, Resource>,
    );
  }, [pendingResourceUpdates, baseFolders]);

  const updatedComparisonResource = useMemo(() => {
    if (!comparisonResourceId) {
      return null;
    }

    return pendingResourceUpdates.find((change) => change.resource.id === comparisonResourceId)
      ?.resource;
  }, [comparisonResourceId, pendingResourceUpdates]);

  const baseComparisonResource = useMemo(() => {
    if (!comparisonResourceId) {
      return null;
    }

    return originalResourcesForUpdates.get(comparisonResourceId);
  }, [comparisonResourceId, originalResourcesForUpdates]);

  const handleBackFn = useMemo(() => {
    return comparisonResourceId ? () => setComparisonResourceId('') : undefined;
  }, [comparisonResourceId]);

  const isCurrentItemAPendingCreation = useMemo(() => {
    return pendingResourceCreations.some((change) => change.resource.path === currentItemPath.path);
  }, [currentItemPath.path, pendingResourceCreations]);

  const allLoadedFolderPaths = useMemo(() => {
    const allLoadedFolderPaths = new Set<string>();
    baseFolders.forEach((folder) => {
      if (!isSuccess(folder)) {
        return;
      }

      allLoadedFolderPaths.add(folder.data.path ?? '');
    });
    return allLoadedFolderPaths;
  }, [baseFolders]);

  const createdComputedViewIdsWithoutSetSchemas = useMemo(() => {
    return new Set(
      pendingResourceCreations
        .filter(
          (pendingCreation) =>
            pendingCreation.resource['@type'] === COMPUTED_VIEW_TYPE &&
            (pendingCreation.resource as ReadAccessComputedView).columnDefinitions.length === 0,
        )
        .map((pendingComputedViewCreation) => pendingComputedViewCreation.resource.id ?? ''),
    );
  }, [pendingResourceCreations]);

  return (
    <Modal
      isOpen={isOpen}
      onBack={handleBackFn}
      onClose={closeAndResetStateFn}
      primaryButtonProps={{
        disabled: !commitMessage || createdComputedViewIdsWithoutSetSchemas.size > 0,
        tooltipProps: {
          text: getCommitButtonTooltipText(commitMessage, createdComputedViewIdsWithoutSetSchemas),
        },
        onClick: () => {
          dispatch(
            createCommitThunk({
              branchId: currentBranch?.id ?? '',
              branchName: currentBranch.name,
              body: {
                commitMessage: commitMessage,
                parentCommitId: currentBranch?.headId ?? '',
                changes: [
                  ...Array.from(
                    stripIdsFromPendingCreations(Array.from(pendingResourceCreations.values())),
                  ),
                  ...Array.from(pendingResourceDeletions.values()),
                  ...Array.from(pendingResourceUpdates.values()),
                ],
                userId: currentUser.id,
              },
            }),
          );
          closeAndResetStateFn();
        },
        text: 'Create',
      }}
      secondaryButtonProps={{
        text: 'Discard',
        variant: 'destructive',
        onClick: () => {
          dispatch(clearPendingChanges());
          closeAndResetStateFn();

          const rootFolder = baseFolders.get(ROOT_FOLDER_PATH);

          if (isCurrentItemAPendingCreation && isSuccess(rootFolder)) {
            dispatch(
              navigateToPathThunk(
                rootFolder.data.id ?? '',
                ItemType.FOLDER,
                ROOT_FOLDER_PATH,
                history,
              ),
            );
          }
        },
      }}
      size="large"
      title="Create Commit">
      {comparisonResourceId &&
      updatedComparisonResource &&
      updatedComparisonResource['@type'] === COMPUTED_VIEW_TYPE &&
      baseComparisonResource &&
      baseComparisonResource['@type'] === COMPUTED_VIEW_TYPE ? (
        <ComputedViewComparisonSection
          originalComputedView={baseComparisonResource as ReadAccessComputedView}
          updatedComputedView={updatedComparisonResource as ReadAccessComputedView}
        />
      ) : (
        <CommitDetailsSection
          allLoadedFolderPaths={allLoadedFolderPaths}
          commitMessage={commitMessage}
          createdComputedViewIdsWithoutSetSchemas={createdComputedViewIdsWithoutSetSchemas}
          originalResourcesForUpdates={originalResourcesForUpdates}
          pendingResourceCreations={pendingResourceCreations}
          pendingResourceDeletions={pendingResourceDeletions}
          pendingResourceUpdates={pendingResourceUpdates}
          setCommitMessage={setCommitMessage}
          setComparisonResourceId={setComparisonResourceId}
        />
      )}
    </Modal>
  );
};

interface CommitDetailsSectionProps {
  pendingResourceCreations: CreateResourceChange[];
  pendingResourceUpdates: UpdateResourceChange[];
  pendingResourceDeletions: DeleteResourceChange[];
  originalResourcesForUpdates: Map<string, Resource>;
  commitMessage: string;
  allLoadedFolderPaths: Set<string>;
  createdComputedViewIdsWithoutSetSchemas: Set<string>;

  setCommitMessage: (message: string) => void;
  setComparisonResourceId: (resourceId: string) => void;
}

const CommitDetailsSection: FC<CommitDetailsSectionProps> = ({
  pendingResourceCreations,
  pendingResourceUpdates,
  pendingResourceDeletions,
  originalResourcesForUpdates,
  commitMessage,
  allLoadedFolderPaths,
  createdComputedViewIdsWithoutSetSchemas,
  setCommitMessage,
  setComparisonResourceId,
}) => {
  const sortedPendingCreations = useMemo(() => {
    return sortBy(pendingResourceCreations, (change) => change.resource.path);
  }, [pendingResourceCreations]);

  const sortedPendingUpdates = useMemo(() => {
    return sortBy(pendingResourceUpdates, (change) => change.resource.path);
  }, [pendingResourceUpdates]);

  const pendingResourceMoves = useMemo(() => {
    return pendingResourceUpdates
      .filter(
        (change) =>
          change.resource.path !== originalResourcesForUpdates.get(change.resource.id ?? '')?.path,
      )
      .map((change) => {
        const originalResource = originalResourcesForUpdates.get(change.resource.id ?? '');
        return {
          ...change,
          previousPath: originalResource?.path ?? '',
        };
      });
  }, [originalResourcesForUpdates, pendingResourceUpdates]);

  const sortedPendingDeletions = useMemo(() => {
    return sortBy(pendingResourceDeletions, (change) => change.path);
  }, [pendingResourceDeletions]);

  const hasPendingCreations = pendingResourceCreations.length > 0;
  const hasPendingUpdates = pendingResourceUpdates.length > 0;
  const hasPendingDeletions = pendingResourceDeletions.length > 0;
  const hasPendingMoves = pendingResourceMoves.length > 0;
  return (
    <>
      {hasPendingCreations ? (
        <>
          <SettingHeader className={sprinkles({ marginBottom: 'sp1' })} name="Additions" />
          <div className={sprinkles({ marginX: 'sp1.5', marginY: 'sp1' })}>
            {sortedPendingCreations.map((change) => {
              return (
                <div
                  className={sprinkles({ flexItems: 'alignCenterBetween', marginY: 'sp1' })}
                  key={change.resource.id ?? ''}>
                  <ResourceControl
                    resourceDisplay={change.resource.path ?? ''}
                    resourceType={change.resource['@type']}
                  />
                  {createdComputedViewIdsWithoutSetSchemas.has(change.resource.id ?? '') ? (
                    <Tooltip text="Dataset has no set schema. Please preview the query to set the schema. ">
                      <IconButton
                        className={sprinkles({ color: 'error' })}
                        name="circle-exclamation-reg"
                      />
                    </Tooltip>
                  ) : null}
                </div>
              );
            })}
          </div>
        </>
      ) : null}
      {hasPendingUpdates ? (
        <>
          <SettingHeader className={sprinkles({ marginY: 'sp1' })} name="Updates" />
          <div className={sprinkles({ marginX: 'sp1.5', marginY: 'sp1' })}>
            {sortedPendingUpdates.map((change) => {
              const originalResource = originalResourcesForUpdates.get(change.resource.id ?? '');
              const maybeViewComparisonButton =
                originalResource &&
                doResourcesHaveContentDifference(originalResource, change.resource) ? (
                  <Tooltip text="Compare changes">
                    <IconButton
                      name="eye-open"
                      onClick={() => setComparisonResourceId(change.resource.id ?? '')}
                    />
                  </Tooltip>
                ) : undefined;
              return (
                <ResourceControl
                  additionalRightSideElement={maybeViewComparisonButton}
                  className={sprinkles({ marginY: 'sp1' })}
                  key={change.resource.id ?? ''}
                  resourceDisplay={change.resource.path ?? ''}
                  resourceType={change.resource['@type']}
                />
              );
            })}
          </div>
        </>
      ) : null}
      {hasPendingDeletions ? (
        <>
          <SettingHeader className={sprinkles({ marginY: 'sp1' })} name="Deletions" />
          <div className={sprinkles({ marginX: 'sp1.5', marginY: 'sp1' })}>
            {sortedPendingDeletions.map((change) => {
              return (
                <DeleteResourceControl
                  change={change}
                  key={change.id ?? ''}
                  parentPathIsLoaded={allLoadedFolderPaths.has(getParentPath(change.path))}
                />
              );
            })}
          </div>
        </>
      ) : null}
      {hasPendingMoves ? (
        <>
          <SettingHeader className={sprinkles({ marginY: 'sp1' })} name="Moves" />
          <div className={sprinkles({ marginX: 'sp1.5', marginY: 'sp1' })}>
            {pendingResourceMoves.map((change) => {
              const newResource = change.resource;
              return (
                <ResourceControl
                  className={sprinkles({ marginY: 'sp1' })}
                  key={newResource.id ?? ''}
                  resourceDisplay={getMoveDisplayMessage(change)}
                  resourceType={newResource['@type']}
                />
              );
            })}
          </div>
        </>
      ) : null}
      {!hasPendingCreations && !hasPendingUpdates && !hasPendingDeletions && !hasPendingMoves ? (
        <div className={sprinkles({ flexItems: 'center', height: 80, marginX: 'sp1.5' })}>
          There are no pending changes
        </div>
      ) : null}
      <div className={sprinkles({ marginX: 'sp1.5', marginY: 'sp2' })}>
        <div className={sprinkles({ heading: 'h3' })}>Commit message</div>
        <Input
          autoFocus
          className={sprinkles({ marginY: 'sp1' })}
          onChange={setCommitMessage}
          placeholder="Enter commit message"
          value={commitMessage}
        />
      </div>
    </>
  );
};

interface ComputedViewComparisonSectionProps {
  originalComputedView: ReadAccessComputedView;
  updatedComputedView: ReadAccessComputedView;
}

const ComputedViewComparisonSection: FC<ComputedViewComparisonSectionProps> = ({
  originalComputedView,
  updatedComputedView,
}) => {
  return (
    <ReactDiffViewer
      compareMethod={DiffMethod.LINES}
      newValue={updatedComputedView.query}
      oldValue={originalComputedView.query}
    />
  );
};

interface DeleteResourceControlProps {
  change: DeleteResourceChange;
  parentPathIsLoaded: boolean;
}

const DeleteResourceControl: FC<DeleteResourceControlProps> = ({ change, parentPathIsLoaded }) => {
  const dispatch = useDispatch();

  return (
    <div className={sprinkles({ flexItems: 'alignCenterBetween', marginY: 'sp1' })}>
      <ResourceControl
        key={change.id ?? ''}
        resourceDisplay={change.path}
        resourceType={change.resourceType}
      />
      {change.resourceType === COMPUTED_VIEW_TYPE && parentPathIsLoaded ? (
        <Tooltip text="Revert deletion">
          <IconButton
            name="arrows-rotate"
            onClick={() => {
              dispatch(revertPendingDeletion(change));
            }}
          />
        </Tooltip>
      ) : null}
    </div>
  );
};

const getMoveDisplayMessage = (change: MoveResourceChange) => {
  return `${change.previousPath} -> ${change.resource.path}`;
};

const getCommitButtonTooltipText = (
  commitMessage: string,
  createdComputedViewIdsWithoutSetSchemas: Set<string>,
) => {
  if (commitMessage.length === 0) {
    return 'Commit message is required';
  } else if (createdComputedViewIdsWithoutSetSchemas.size > 0) {
    return 'All computed views must have schemas set';
  }

  return '';
};
