import { FC, useMemo } from 'react';

import { BaseCol, DatasetSchema } from '@explo/data';

import { IconButton } from 'components/ds';

import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';
import { SCHEMA_TYPE_ICON_MAP } from 'pages/ReportBuilder/constants';

import * as styles from './styles.css';

import cx from 'classnames';

type Props = {
  schema: DatasetSchema;
  onColChange: (newCol: BaseCol) => void;
  displayName: string | undefined;

  className?: string;
  // If popover has a right divider, then this is a single row dropped column. Otherwise, it is a double row dropped column.
  rightDivider?: boolean;
  transparentIcon?: boolean;
};

export const ColumnOptionsPopover: FC<Props> = ({
  rightDivider,
  schema,
  onColChange,
  displayName,
  className,
  transparentIcon,
}) => {
  const caretButton = (
    <IconButton
      className={
        transparentIcon
          ? styles.transparentIconBtn
          : rightDivider
            ? styles.iconBtnWithDivider
            : styles.iconBtn
      }
      name="caret-down"
    />
  );

  const includeDisplayName = displayName !== undefined;

  const menuOptions = useMemo(
    () =>
      schema.map((col) => ({
        value: col.name,
        name: col.friendly_name || col.name,
        icon: SCHEMA_TYPE_ICON_MAP[col.type],
        onClick: () => onColChange(col),
      })),
    [schema, onColChange],
  );

  return (
    <EmbeddedDropdownMenu fillContainer menuOptions={menuOptions}>
      {includeDisplayName ? (
        <div className={cx(className ?? styles.popoverTargetContainer)}>
          <div className={styles.colName}>{displayName}</div>
          {caretButton}
        </div>
      ) : (
        caretButton
      )}
    </EmbeddedDropdownMenu>
  );
};
