import { createSlice } from '@reduxjs/toolkit';

import { FilterOperator } from '@explo/data';

import { DashboardVariable } from 'types/dashboardTypes';

import type { PayloadAction } from '@reduxjs/toolkit';
import { DatasetId } from 'actions/datasetActions';

export type DrilldownEntryPointId = string;

export interface DrilldownSourceInfo {
  sourceDashboardId: number;
  // The id of the data panel in the source dashboard that the user drilled down from. This is
  // undefined if the drilldown was triggered outside of a data panel (e.g. through the layers
  // panel in the editor).
  sourceDataPanelId: string | undefined;
  // The id of the drilldown entry point in the source dashboard that the user drilled down from.
  // This is undefined if the drilldown was triggered outside of a drilldown entry point (e.g.
  // through the layers panel in the editor).
  drilldownEntryPointId: DrilldownEntryPointId | undefined;
}

export interface DrilldownFilterInfo {
  operatorId: FilterOperator;
  filterValue: DashboardVariable;
  // The id of the drilldown entry point that is the source of this filter.
  drilldownEntryPointId: DrilldownEntryPointId;
}

type DatasetColumn = string;

// A drilldown dataset filter is a map of the dataset column to an array of drilldown filter info.
// An array of drilldown filter infos is used as there can be multiple filters for a single column
// in the case that the filter value is an expanded value such as a date range.
export type DrilldownDatasetFilter = Record<DatasetColumn, DrilldownFilterInfo[]>;

interface DrilldownsState {
  currentSourceInfos: DrilldownSourceInfo[];
  // A map of dataset id to dataset column to drilldown filter info (which contains an array of
  // drilldown filter operators and values).
  // The drilldown dataset filters will be used to automatically generated filter clauses for all
  // data against the specified dataset.
  drilldownDatasetFilters: Record<DatasetId, DrilldownDatasetFilter>;
}

const initialState: DrilldownsState = {
  currentSourceInfos: [],
  drilldownDatasetFilters: {},
};

export type DeleteDrilldownFilterActionPayload = {
  datasetId: string;
  datasetColumnName: string;
  operatorId: FilterOperator;
};

export type SetCurrentSourceInfosActionPayload = {
  currentSourceInfos: DrilldownSourceInfo[];
  shouldInvalidateDrilldownDatasetFilters: boolean;
};

const drilldownsReducer = createSlice({
  name: 'drilldowns',
  initialState,
  reducers: {
    clearDrilldownsState: (state) => {
      state.currentSourceInfos = [];
      state.drilldownDatasetFilters = {};
    },
    setCurrentSourceInfos: (state, action: PayloadAction<SetCurrentSourceInfosActionPayload>) => {
      const { currentSourceInfos, shouldInvalidateDrilldownDatasetFilters } = action.payload;
      state.currentSourceInfos = currentSourceInfos;
      if (shouldInvalidateDrilldownDatasetFilters) {
        const allValidDrilldownEntryPointIds: Set<string> = new Set();
        currentSourceInfos.forEach((sourceInfo) => {
          if (sourceInfo.drilldownEntryPointId) {
            allValidDrilldownEntryPointIds.add(sourceInfo.drilldownEntryPointId);
          }
        });
        Object.keys(state.drilldownDatasetFilters).forEach((datasetId) => {
          Object.keys(state.drilldownDatasetFilters[datasetId]).forEach((datasetColumnName) => {
            const datasetColumnFilters =
              state.drilldownDatasetFilters[datasetId][datasetColumnName];
            const filteredDatasetColumnFilters = datasetColumnFilters.filter(
              (datasetColumnFilter) =>
                allValidDrilldownEntryPointIds.has(datasetColumnFilter.drilldownEntryPointId),
            );
            if (filteredDatasetColumnFilters.length === 0) {
              delete state.drilldownDatasetFilters[datasetId][datasetColumnName];
            }
            state.drilldownDatasetFilters[datasetId][datasetColumnName] =
              filteredDatasetColumnFilters;
          });
        });
      }
    },
    appendCurrentSourceInfo: (state, action: PayloadAction<DrilldownSourceInfo>) => {
      state.currentSourceInfos = [...state.currentSourceInfos, action.payload];
    },
    setDrilldownFilters: (
      state,
      action: PayloadAction<Record<string, DrilldownDatasetFilter> | undefined>,
    ) => {
      state.drilldownDatasetFilters = action.payload ?? {};
    },
    deleteDrilldownFilter: (state, action: PayloadAction<DeleteDrilldownFilterActionPayload>) => {
      const { datasetId, datasetColumnName, operatorId } = action.payload;
      const datasetColumnFilter = state.drilldownDatasetFilters[datasetId]?.[datasetColumnName];
      if (datasetColumnFilter) {
        const filterInfoIndex = datasetColumnFilter.findIndex(
          (filterInfo) => filterInfo.operatorId === operatorId,
        );
        if (filterInfoIndex !== -1) {
          datasetColumnFilter.splice(filterInfoIndex, 1);
        }

        if (datasetColumnFilter.length === 0) {
          delete state.drilldownDatasetFilters[datasetId][datasetColumnName];
        }
      }
    },
  },
});

export const {
  clearDrilldownsState,
  setCurrentSourceInfos,
  appendCurrentSourceInfo,
  setDrilldownFilters,
  deleteDrilldownFilter,
} = drilldownsReducer.actions;
export default drilldownsReducer.reducer;
