import { DateTime } from 'luxon';
import { FC, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { OPERATION_TYPES } from '@explo/data';

import { ConfigSection } from 'components/PanelComponents/ConfigSection';
import { PanelListItem } from 'components/PanelComponents/PanelListItem';
import { COLOR_CATEGORY_FILTER_SUFFIX } from 'constants/dashboardConstants';
import { ReduxState } from 'reducers/rootReducer';
import { DashboardVariable, DashboardVariableMap } from 'types/dashboardTypes';
import { isChartUsingMultipleColorCategories } from 'utils/colorColUtils';
import { CATEGORY_SUFFIX, COLOR_SUFFIX, filterDpsWithDrilldowns } from 'utils/drilldownUtils';
import { getSelectableKPIs } from 'utils/selectableKpiUtils';
import { getVariableIcon } from 'utils/variableUtils';

import { TABLE_COLUMN_CLICKED_VARIABLE_SUFFIX } from './constants';
import {
  getDashboardEditConfigWithDrilldowns,
  getRootEditConfigWithDrilldowns,
} from 'reducers/selectors';

type Props = {
  variables: DashboardVariableMap;
  searchQuery: string;
};

export const ChartVariablesSection: FC<Props> = ({ variables, searchQuery }) => {
  const { dataPanels, editableSection } = useSelector(
    (state: ReduxState) => ({
      dataPanels: getDashboardEditConfigWithDrilldowns(state)?.data_panels ?? {},
      editableSection: getRootEditConfigWithDrilldowns(state)?.editable_section,
    }),
    shallowEqual,
  );

  const dpsWithDrilldowns = useMemo(
    () => filterDpsWithDrilldowns(Object.values(dataPanels)),
    [dataPanels],
  );
  const editableSectionDpsWithDrilldowns = useMemo(
    () =>
      editableSection?.enabled
        ? filterDpsWithDrilldowns(
            Object.values(editableSection.charts).map((chart) => chart.data_panel),
          )
        : [],
    [editableSection],
  );

  const selectableKPIs = useMemo(() => getSelectableKPIs(dataPanels), [dataPanels]);

  const dpsWithColorCategories = useMemo(
    () =>
      Object.values(dataPanels).filter((dp) =>
        isChartUsingMultipleColorCategories(dp.visualize_op),
      ),
    [dataPanels],
  );
  const editableSectionDpsWithColorCategories = useMemo(
    () =>
      editableSection?.enabled
        ? Object.values(editableSection.charts)
            .filter((chart) => isChartUsingMultipleColorCategories(chart.data_panel.visualize_op))
            .map((chart) => chart.data_panel)
        : [],
    [editableSection],
  );

  const isEmpty =
    dpsWithColorCategories.length === 0 &&
    dpsWithDrilldowns.length === 0 &&
    selectableKPIs.length === 0;

  const viewVariable = (varName: string, value: DashboardVariable, suffix?: string) => {
    if (!varName.toLowerCase().includes(searchQuery)) {
      return null;
    }

    return (
      <PanelListItem
        copiable
        key={varName}
        leftIcon={getVariableIcon(value)}
        name={varName}
        rightElement={JSON.stringify(value)}
        suffix={suffix}
      />
    );
  };

  // Recursively expand nested variables with . notation, but stop if we hit DateTime
  // Important for drilldown variables with have category and color potentially mapping to DateTimeRangeDashboardVariable
  const viewNestedVariables = (
    dptId: string,
    valueObj: Record<string, DashboardVariable>,
  ): (JSX.Element | null)[] => {
    return Object.entries(valueObj).flatMap(([key, value]) => {
      if (typeof value === 'object' && value !== null && !DateTime.isDateTime(value)) {
        return viewNestedVariables(`${dptId}.${key}`, value as Record<string, DashboardVariable>);
      }
      return viewVariable(`${dptId}.${key}`, value);
    });
  };

  return (
    <ConfigSection
      defaultOpen={!isEmpty}
      icon="chart-line"
      title="Chart variables"
      variant="header2">
      {selectableKPIs.map((dp) => viewVariable(dp.provided_id, variables[dp.provided_id]))}
      {dpsWithColorCategories.concat(editableSectionDpsWithColorCategories).map((dpt) => {
        const varName = dpt.provided_id + COLOR_CATEGORY_FILTER_SUFFIX;
        const value = variables[varName];
        return viewVariable(varName, value);
      })}
      {dpsWithDrilldowns.concat(editableSectionDpsWithDrilldowns).map((dp) => {
        const isTable = dp.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_TABLE;
        const dptId = dp.provided_id;
        const value = variables[dptId];

        if (!value || (isTable && typeof value !== 'object')) {
          const instructions = dp.visualize_op.instructions;

          // Handle table case
          if (isTable) {
            const columnClickedVariableName = `${dptId}.${TABLE_COLUMN_CLICKED_VARIABLE_SUFFIX}`;
            return (
              <>
                {viewVariable(
                  dptId,
                  value,
                  instructions.VISUALIZE_TABLE.drilldownConfig?.allColumns
                    ? '.column_name'
                    : undefined,
                )}
                {viewVariable(columnClickedVariableName, variables[columnClickedVariableName])}
              </>
            );
          }

          // Handle 2D case
          const vars: (JSX.Element | null)[] = [];
          const twoDimInstructions = instructions.V2_TWO_DIMENSION_CHART;
          if (twoDimInstructions?.colorColumnOptions?.length)
            vars.push(viewVariable(`${dptId}${COLOR_SUFFIX}`, value));
          if (twoDimInstructions?.categoryColumn?.column.type)
            vars.push(viewVariable(`${dptId}${CATEGORY_SUFFIX}`, value));
          return vars;
        }

        const foldedOtherDataPanelValues = foldOtherDataPanelVariablesIntoObjectValue(
          variables,
          dptId,
        );
        return viewNestedVariables(dptId, {
          ...(value as Record<string, DashboardVariable>),
          ...foldedOtherDataPanelValues,
        });
      })}
    </ConfigSection>
  );
};

// TODO(zifanxiang): Add tests for this method even though its a private method (potentially migrate
// to a common util).
/**
 * Creates a new object that contains the other variables on the data panel that are not explicitly
 * set as part of the object variable. For example, a table defines the following value variable
 * that is an object that represents all values in its selected row, table = { column1: 'value1',
 * column2: 'value2' }. The table also defines another variable through dot notation,
 * table.hiddenColumn = 'value3'. This function will fold the hiddenColumn variable into the object
 * value creating an object that looks like this: table = { column1: 'value1', column2: 'value2',
 * hiddenColumn: 'value3' }
 */
const foldOtherDataPanelVariablesIntoObjectValue = (
  variables: DashboardVariableMap,
  dataPanelId: string,
): Record<string, DashboardVariable> => {
  const foldedOtherDataPanelValues: Record<string, DashboardVariable> = {};
  Object.entries(variables).forEach(([variableName, variableValue]) => {
    if (variableName.startsWith(dataPanelId)) {
      const variableNameParts = variableName.split('.');
      if (variableNameParts.length > 1) {
        const nestedVariableName = variableNameParts.slice(1).join('.');
        if (variableValue !== undefined) {
          foldedOtherDataPanelValues[nestedVariableName] = variableValue;
        }
      }
    }
  });

  return foldedOtherDataPanelValues;
};
