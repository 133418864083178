import {
  CreateCommitRequest,
  CreateCommitResponse,
  CommitResourceService,
  ListCommitResponse,
  CommitResponse,
} from '@explo-tech/fido-api';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ACTION } from 'actions/types';
import { ReduxState } from 'reducers/rootReducer';
import { makeFidoThunkRequest } from 'utils/thunkUtils';

export const createCommitThunk = createAsyncThunk<
  CreateCommitResponse,
  { branchId: string; branchName: string; body: CreateCommitRequest },
  { state: ReduxState }
>(ACTION.FIDO_CREATE_COMMIT, async ({ branchId, body }, { getState }) => {
  const fido = getState().fido;

  return makeFidoThunkRequest(
    () => CommitResourceService.createCommit(branchId, body),
    fido.fidoToken ?? '',
    'Error saving your view',
  );
});

export const listCommitsThunk = createAsyncThunk<ListCommitResponse, string, { state: ReduxState }>(
  ACTION.FIDO_LIST_COMMITS,
  async (branchId, { getState }) => {
    const fido = getState().fido;

    return makeFidoThunkRequest(
      () => CommitResourceService.listCommits(branchId),
      fido.fidoToken ?? '',
      'Error fetching commits',
    );
  },
);

export const getCommitDetailsThunk = createAsyncThunk<
  CommitResponse,
  { branchId: string; commitId: string },
  { state: ReduxState }
>(ACTION.FIDO_GET_COMMIT_DETAILS, async ({ branchId, commitId }, { getState }) => {
  const fido = getState().fido;

  return makeFidoThunkRequest(
    () => CommitResourceService.getCommit(branchId, commitId, /* includeMeta= */ true),
    fido.fidoToken ?? '',
    'Error fetching commit details',
  );
});
