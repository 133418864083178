import cx from 'classnames';
import { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { OPERATION_TYPES } from '@explo/data';

import { toggleFilterLink } from 'actions/dashboardV2Actions';
import { Icon, sprinkles } from 'components/ds';
import { DashboardStates } from 'reducers/rootReducer';
import { getDatasetLinks } from 'reducers/selectors';
import { isDataPanelLinked } from 'utils/editableSectionUtils';
import { getDatasetName } from 'utils/naming';
import { Dataset } from 'actions/datasetActions';
import { ReadAccessComputedView } from 'utils/fido/fidoShimmedTypes';
import { convertGlobalDatasetsAndCombineWithLocal } from 'utils/datasetUtils';

type Props = {
  dataPanelId: string;
  dataPanelDatasetId: string;
  datasets: Record<string, Dataset>;
  referencedGlobalDatasets: Record<string, ReadAccessComputedView>;
  operationType: OPERATION_TYPES;
};

// includes hyphens
const NUM_CHAR_IN_UUID = 36;

export const DataPanelLinkOverlay: FC<Props> = ({
  datasets,
  referencedGlobalDatasets,
  dataPanelDatasetId,
  dataPanelId,
  operationType,
}) => {
  const dispatch = useDispatch();

  const allDatasets = convertGlobalDatasetsAndCombineWithLocal(datasets, referencedGlobalDatasets);
  const dataset = allDatasets[dataPanelDatasetId];
  const { elementId, datasetLinks } = useSelector(
    (state: DashboardStates) => ({
      elementId: state.dashboardInteractions.selectedItem?.id,
      datasetLinks: getDatasetLinks(state, dataset, operationType),
    }),
    shallowEqual,
  );

  if (!elementId || datasetLinks === undefined) return null;

  const isLinked = isDataPanelLinked(datasetLinks, dataPanelId);

  let backgroundColor = 'rgba(238, 238, 238, 0.6)';
  if (!datasetLinks) backgroundColor = 'rgba(158, 158, 158, 0.6)';
  else if (isLinked) backgroundColor = 'rgba(0, 105, 237, 0.2)';

  return (
    <div
      className={cx(
        sprinkles({
          positionAbsolute: 'fill',
          flexItems: 'center',
        }),
      )}
      style={{ backgroundColor }}>
      <div
        className={sprinkles({
          flexItems: 'center',
          paddingX: 'sp2',
          backgroundColor: isLinked ? 'blue11' : 'gray11',
          color: 'white',
          borderRadius: 8,
          overflow: 'hidden',
          cursor: datasetLinks ? 'pointer' : undefined,
        })}
        onClick={(e) => {
          if (datasetLinks)
            dispatch(
              toggleFilterLink({
                elementId,
                dataPanelId: dataPanelId.slice(0, NUM_CHAR_IN_UUID), // slice because editable section might have random uuid at the end
              }),
            );
          e.stopPropagation();
        }}
        style={{ height: 48 }}>
        <Icon name={isLinked ? 'tick' : 'cross'} />
        <div className={sprinkles({ marginLeft: 'sp2', heading: 'h4', truncateText: 'ellipsis' })}>
          {isLinked ? (
            'Linked to filter'
          ) : !datasetLinks ? (
            <>
              Select a field for{' '}
              <span className={sprinkles({ fontStyle: 'italic', paddingRight: 'sp.25' })}>
                {getDatasetName(dataset)}
              </span>
            </>
          ) : (
            'Not Linked'
          )}
        </div>
      </div>
    </div>
  );
};
