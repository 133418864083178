import { FC, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Breadcrumbs, sprinkles, Tag } from 'components/ds';
import { BreadcrumbsHierarchyItem } from 'components/ds/Breadcrumbs';
import { ROUTE_PROVIDERS } from 'constants/routes';
import { GlobalStyleConfig } from 'globalStyles/types';
import { useHistory } from 'react-router';
import { deleteDrilldownFilter, setCurrentSourceInfos } from 'reducers/drilldownsReducer';
import { switchCurrentEmbedDashboard } from 'reducers/embedDashboardReducer';
import { DashboardStates } from 'reducers/rootReducer';
import {
  getCurrentDashboardWithDrilldowns,
  getDashboardHierarchy,
  isEmbedDashboard,
} from 'reducers/selectors';
import * as RD from 'remotedata';
import { DashboardVariable } from 'types/dashboardTypes';
import { FILTER_OPERATOR_TYPES_BY_ID } from 'types/filterOperations';
import {
  createDashboardNameToIdMapForSourceInfos,
  getRenderedDrilldownFilterValue,
  retrieveDrilldownVariablesWithParentDashboardNamesMap,
} from 'utils/dashboardDrilldownUtils';
import { FilterValueType } from '@explo/data';
import { DateTime } from 'luxon';

interface Props {
  globalStyleConfig: GlobalStyleConfig;
}

export const DashboardDrilldownsBanner: FC<Props> = ({ globalStyleConfig }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    currentSourceInfos,
    drilldownDatasetFilters,
    dashboardHierarchy,
    currentDashboard,
    isEmbed,
    isEditing,
    variablesMap,
  } = useSelector((state: DashboardStates) => {
    return {
      currentSourceInfos: state.drilldowns.currentSourceInfos,
      drilldownDatasetFilters: state.drilldowns.drilldownDatasetFilters,
      dashboardHierarchy: getDashboardHierarchy(state),
      currentDashboard: getCurrentDashboardWithDrilldowns(state),
      isEmbed: isEmbedDashboard(state),
      isEditing: state.dashboardInteractions.interactionsInfo.isEditing,
      variablesMap: state.dashboardData.variables ?? {},
    };
  }, shallowEqual);

  const dashboardBreadcrumbInfo = useMemo(() => {
    if (!RD.isSuccess(dashboardHierarchy)) {
      return [];
    }

    return currentSourceInfos.map((info) => {
      const dashboard = dashboardHierarchy.data.dashboards[info.sourceDashboardId];

      if (!dashboard) {
        return {};
      }

      return {
        dashboardId: dashboard.id,
        dashboardName: dashboard.name,
        drilldownEntryPointId: info.drilldownEntryPointId,
      };
    });
  }, [currentSourceInfos, dashboardHierarchy]);

  const breadcrumbHierarchyItems = useMemo(() => {
    const breadcrumbs: BreadcrumbsHierarchyItem[] = [];
    dashboardBreadcrumbInfo.forEach((breadcrumbInfo, index) => {
      const previousDashboardId = breadcrumbInfo.dashboardId;
      if (!previousDashboardId) {
        return;
      }
      const previousSourceInfos = currentSourceInfos.slice(0, index);
      breadcrumbs.push({
        text: breadcrumbInfo.dashboardName,
        onClick: () => {
          if (!RD.isSuccess(dashboardHierarchy)) {
            return;
          }

          dispatch(
            setCurrentSourceInfos({
              currentSourceInfos: previousSourceInfos,
              shouldInvalidateDrilldownDatasetFilters: true,
            }),
          );
          const drilldownVariablesToPreserve =
            retrieveDrilldownVariablesWithParentDashboardNamesMap(
              variablesMap,
              createDashboardNameToIdMapForSourceInfos(
                previousSourceInfos,
                dashboardHierarchy.data,
              ),
              () => true,
            ).reduce((drilldownVariableMap: Record<string, DashboardVariable>, variable) => {
              drilldownVariableMap[variable.key] = variable.value;
              return drilldownVariableMap;
            }, {});
          if (isEmbed) {
            dispatch(
              switchCurrentEmbedDashboard({
                currentDashboardId: previousDashboardId,
                variables: drilldownVariablesToPreserve,
              }),
            );
          } else {
            const previousDashboardUrl = isEditing
              ? ROUTE_PROVIDERS.DASHBOARD_EDIT_MODE(`${previousDashboardId}`)
              : ROUTE_PROVIDERS.DASHBOARD(`${previousDashboardId}`);
            history.push(previousDashboardUrl, {
              drilldownVariables: drilldownVariablesToPreserve,
            });
          }
        },
      });
    });
    // Add current dashboard info, but no linking behavior
    if (RD.isSuccess(currentDashboard)) breadcrumbs.push({ text: currentDashboard.data.name });
    return breadcrumbs;
  }, [
    dashboardBreadcrumbInfo,
    currentSourceInfos,
    isEmbed,
    currentDashboard,
    history,
    isEditing,
    dashboardHierarchy,
    variablesMap,
    dispatch,
  ]);

  if (dashboardBreadcrumbInfo.length === 0 || !RD.isSuccess(currentDashboard)) {
    return null;
  }

  return (
    <div
      className={containerClass}
      style={{ backgroundColor: globalStyleConfig.base.backgroundColor }}>
      <div className={sprinkles({ heading: 'h3', paddingRight: 'sp8' })}>
        <Breadcrumbs items={breadcrumbHierarchyItems} />
      </div>
      <div className={sprinkles({ flexItems: 'alignCenter', marginX: 'sp8' })}>
        {Object.entries(drilldownDatasetFilters).map(([datasetId, datasetFilter]) => {
          return Object.entries(datasetFilter).map(([columnName, filterInfos]) => {
            return filterInfos.map((filterInfo) => {
              const { filterValue, operatorId } = filterInfo;
              const value =
                filterValue instanceof DateTime ? { startDate: filterValue.toISO() } : filterValue;
              const renderedFilterValue = getRenderedDrilldownFilterValue(
                value as FilterValueType,
                operatorId,
              );
              return (
                <div
                  className={sprinkles({ flexItems: 'alignCenter' })}
                  key={`${columnName}-${filterInfo.filterValue}`}>
                  <Tag
                    className={sprinkles({ marginX: 'sp1' })}
                    closeIconTooltip="Remove filter"
                    leftIcon="filter"
                    onClose={() => {
                      dispatch(
                        deleteDrilldownFilter({
                          datasetId,
                          datasetColumnName: columnName,
                          operatorId,
                        }),
                      );
                    }}>
                    <span>
                      {columnName} {FILTER_OPERATOR_TYPES_BY_ID[operatorId].name}{' '}
                      {renderedFilterValue}
                    </span>
                  </Tag>
                </div>
              );
            });
          });
        })}
      </div>
    </div>
  );
};

const containerClass = sprinkles({
  flexItems: 'alignCenter',
  paddingX: 'sp3',
  paddingTop: 'sp2',
  gap: 'sp8',
});
