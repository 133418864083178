import { Parameter } from '@explo-tech/fido-api';
import { Input, Select, sprinkles } from 'components/ds';
import { FC, useMemo } from 'react';
import { getEmbeddoTypeFromFidoTypeForVariableMapping } from 'utils/fido/fidoShims';
import { VariableOption } from './MapVariablesContent';

const COLUMN_WIDTH = 240;
interface Props {
  parameter: Parameter;
  possibleVariables: VariableOption[];
  setVariableMapping: (parameterName: string, variableName: string) => void;
  selectedVariable: string;
  isFirstListItem: boolean;
  isMappingInvalid: boolean;
}

export const VariableMappingListItem: FC<Props> = ({
  parameter,
  possibleVariables,
  setVariableMapping,
  selectedVariable,
  isFirstListItem,
  isMappingInvalid,
}) => {
  const embeddoType = getEmbeddoTypeFromFidoTypeForVariableMapping(parameter.type);
  const filteredVariables = useMemo(() => {
    return possibleVariables.filter(
      // Don't filter out variables that have no type, as they can be mapped to any parameter
      (variable) => variable.validTypes === undefined || variable.validTypes.includes(embeddoType),
    );
  }, [possibleVariables, embeddoType]);

  const filteredAppendedVariablesValues = useMemo(() => {
    if (
      isMappingInvalid &&
      !filteredVariables.find((filteredVariable) => filteredVariable.name === selectedVariable)
    ) {
      // If the selected variable is no longer in the available variables, append it to the list of
      // filtered variables so that it can still be displayed in the dropdown.
      filteredVariables.push({ name: selectedVariable, validTypes: [embeddoType] });
    }

    return filteredVariables.map(({ name }) => ({ label: name, value: name }));
  }, [filteredVariables, embeddoType, isMappingInvalid, selectedVariable]);

  const paramName = parameter.name;

  return (
    <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp5' })}>
      <div style={{ width: COLUMN_WIDTH }}>
        <Input
          disabled
          defaultValue={paramName}
          label={isFirstListItem ? 'Variable' : undefined}
          onSubmit={() => null}
        />
      </div>
      <div style={{ width: COLUMN_WIDTH }}>
        <Select
          label={isFirstListItem ? 'Linked dashboard variable' : undefined}
          onChange={(newDashboardValue) => setVariableMapping(paramName, newDashboardValue)}
          selectedValue={selectedVariable}
          values={filteredAppendedVariablesValues}
        />
      </div>
    </div>
  );
};
