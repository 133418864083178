import { ResponseBody, RoverResourceService } from '@explo/rover-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ExportScreenshotType } from 'actions/exportActions';
import { ErrorResponse } from 'actions/responseTypes';
import { ACTION } from 'actions/types';
import { DEFAULT_EMAIL_WIDTH_PX } from 'constants/dashboardConstants';
import { DashboardStates } from 'reducers/rootReducer';
import { getSelectedDashboardVersionInfoWithDrilldowns } from 'reducers/selectors';
import { getFromEmail } from 'reducers/thunks/roverThunks/roverUtils';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { makeRoverThunkRequest } from 'utils/thunkUtils';
import { createAdHocExploreScreenshotExportRequestBody, getLoggingMetadata } from './exploreUtils';
import {
  fetchAdHocDashboardScreenshotUrlThunk,
  fetchDataPanelScreenshotUrlThunk,
  fetchTestScheduledDashboardExportMetadata,
} from './fetchScreenshotUrlThunks';
import {
  FetchAdHocScreenshotUrlResponse,
  FetchTestScheduledDashboardExportMetadataResponse,
} from './types';

export const exportDataPanelScreenshotThunk = createAsyncThunk<
  ResponseBody,
  {
    data_panel_template_id: string;
    download_file_name: string;
    variables: DashboardVariableMap;
    export_type: ExportScreenshotType;
    email: string | undefined;
  },
  { state: DashboardStates }
>(ACTION.EXPORT_DATA_PANEL_SCREENSHOT, async (params, { dispatch, getState, rejectWithValue }) => {
  const state = getState();
  const { analytics, dashboardLayout } = state;

  const versionNumber = getSelectedDashboardVersionInfoWithDrilldowns(state)?.version_number;

  if (!versionNumber) return rejectWithValue('No valid version found for current dashboard');

  let response: FetchAdHocScreenshotUrlResponse | undefined;
  try {
    response = await dispatch(
      fetchDataPanelScreenshotUrlThunk({
        data_panel_template_id: params.data_panel_template_id,
        version_number: versionNumber,
        export_type: params.export_type,
        is_email: !!params.email,
        variables: params.variables,
      }),
    ).unwrap();
  } catch (error) {
    return rejectWithValue(error ?? 'Error fetching data panel export metadata');
  }

  const { url, rover_screenshot_token } = response;

  return makeRoverThunkRequest(
    () =>
      RoverResourceService.screenshotExport(
        createAdHocExploreScreenshotExportRequestBody(
          url,
          params.download_file_name,
          params.export_type,
          params.email,
          analytics,
          dashboardLayout.requestInfo,
          'export',
        ),
      ),
    rover_screenshot_token,
    'Error downloading data panel screenshot',
  ).catch((e) => rejectWithValue(e));
});

export const exportAdHocDashboardThunk = createAsyncThunk<
  ResponseBody,
  {
    download_file_name: string;
    variables: DashboardVariableMap;
    export_type: ExportScreenshotType;
    email: string | undefined;
  },
  { state: DashboardStates }
>(ACTION.EXPORT_AD_HOC_DASHBOARD, async (params, { dispatch, getState, rejectWithValue }) => {
  const state = getState();
  const { analytics, dashboardLayout } = state;

  const versionNumber = getSelectedDashboardVersionInfoWithDrilldowns(state)?.version_number;

  if (!versionNumber) return rejectWithValue('No valid version found for current dashboard');

  let response: FetchAdHocScreenshotUrlResponse | undefined;
  try {
    response = await dispatch(
      fetchAdHocDashboardScreenshotUrlThunk({
        version_number: versionNumber,
        export_type: params.export_type,
        is_email: !!params.email,
        variables: params.variables,
      }),
    ).unwrap();
  } catch (error) {
    return rejectWithValue(error ?? 'Error fetching dashboard export metadata');
  }

  const { url, rover_screenshot_token } = response;

  return makeRoverThunkRequest(
    () =>
      RoverResourceService.screenshotExport(
        createAdHocExploreScreenshotExportRequestBody(
          url,
          params.download_file_name,
          params.export_type,
          params.email,
          analytics,
          dashboardLayout.requestInfo,
          'export',
        ),
      ),
    rover_screenshot_token,
    'Error downloading dashboard screenshot',
  ).catch((e) => rejectWithValue(e));
});

export const sendScheduledDashboardTestEmailThunk = createAsyncThunk<
  ResponseBody,
  {
    email: string;
    subject: string;
    customerId: number;
    disableLink: boolean;
    dashboardTemplateId: number;
    customUrl: string | undefined;
    onSuccess: () => void;
    onError: (error: ErrorResponse) => void;
  },
  { state: DashboardStates }
>(
  ACTION.SEND_SCHEDULED_DASHBOARD_TEST_EMAIL,
  async (params, { dispatch, getState, rejectWithValue }) => {
    let scheduledDashboardExportMetadata:
      | FetchTestScheduledDashboardExportMetadataResponse
      | undefined;
    try {
      scheduledDashboardExportMetadata = await dispatch(
        fetchTestScheduledDashboardExportMetadata({
          disable_link: params.disableLink,
          custom_url: params.customUrl,
          customer_id: params.customerId,
          resource_id: params.dashboardTemplateId,
        }),
      ).unwrap();
    } catch (error) {
      return rejectWithValue(error ?? 'Error fetching scheduled dashboard export metadata');
    }

    const state = getState();
    const { analytics, dashboardLayout } = state;
    const { emailFromName, emailWidthPx, emailOverride } = dashboardLayout.requestInfo;
    const { url, link, attributes, html_header, html_footer } = scheduledDashboardExportMetadata;

    return makeRoverThunkRequest(
      () =>
        RoverResourceService.screenshotExport({
          pages: [
            {
              pageUrl: url,
              link: link ?? undefined,
            },
          ],
          exportMetadata: {
            exportFormat: 'IMAGE',
            imageScreenshotWidth: emailWidthPx ?? DEFAULT_EMAIL_WIDTH_PX,
            emailConfiguration: {
              toEmails: [params.email],
              subject: params.subject,
              fromEmail: getFromEmail({ emailOverride, displayName: emailFromName }),
              emailContentArgs: {
                '@type': 'explore-scheduled-dashboard',
                attributes,
                htmlHeader: html_header ?? undefined,
                htmlFooter: html_footer ?? undefined,
              },
            },
          },
          loggingMetadata: getLoggingMetadata(analytics, dashboardLayout.requestInfo, 'export'),
        }),
      scheduledDashboardExportMetadata.rover_screenshot_token,
      'Error sending test email',
      params.onSuccess,
      params.onError,
    ).catch((e) => rejectWithValue(e));
  },
);
