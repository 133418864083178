import { FC } from 'react';

import { APP_PORTAL_ID, Spinner, sprinkles } from 'components/ds';
import { TextFieldModal } from 'components/modals/textFieldModal';
import { ResourceType } from 'types/exploResource';
import { getResourceNameErrorMessage } from 'utils/exploResourceUtils';

import { capitalizeFirstLetter } from './utils';

type Props = {
  isOpen: boolean;
  resourceType: ResourceType;
  resourceName: string;
  uniqueNames: Set<string>;
  isLoadingUniqueNames: boolean;
  uniqueNameErrorText?: string;
  onClose: () => void;
  onSubmit: (newName: string) => void;
  validateNewNameFn?: (newName?: string) => string | undefined;
};

export const RenameModal: FC<Props> = ({
  isOpen,
  resourceType,
  uniqueNames,
  isLoadingUniqueNames,
  resourceName,
  uniqueNameErrorText,
  onClose,
  onSubmit,
  validateNewNameFn,
}) => {
  return (
    <TextFieldModal
      buttonName="Save"
      closeModal={onClose}
      getErrorMessage={(input) => {
        const baseResourceErrorMessage = getResourceNameErrorMessage(
          input,
          uniqueNames,
          resourceName,
          uniqueNameErrorText,
        );

        if (baseResourceErrorMessage) {
          return baseResourceErrorMessage;
        }

        return validateNewNameFn?.(input);
      }}
      modalOpen={isOpen}
      modalTitle={`Rename ${capitalizeFirstLetter(resourceType)}`}
      onSubmit={(name) => {
        onClose();

        onSubmit(name);
      }}
      portalContainerId={APP_PORTAL_ID}
      resourceName={resourceName}
      textFieldPlaceholder={`${capitalizeFirstLetter(resourceType)} name`}>
      <div>
        {isLoadingUniqueNames ? (
          <div className={sprinkles({ parentContainer: 'fill', flexItems: 'centerColumn' })}>
            <Spinner />
          </div>
        ) : undefined}
      </div>
    </TextFieldModal>
  );
};
