import { usePrevious } from '@radix-ui/react-use-previous';
import { Settings } from 'luxon';
import { FC, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Timezones, setDisplayTimezone } from '@explo/data';

import { fetchReportBuilder } from 'actions/reportBuilderActions';
import { ReportBuilderConfig } from 'actions/reportBuilderConfigActions';
import { fetchReportBuilderVersionsMetadata } from 'actions/reportBuilderVersionActions';
import { ExploLoadingSpinner } from 'components/ExploLoadingSpinner';
import { sprinkles } from 'components/ds';
import { PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import { loadFonts } from 'globalStyles/utils';
import { ReportBuilderBuiltIns } from 'pages/ReportBuilderEditor/BuiltIns';
import { getCurrentTheme } from 'reducers/dashboardStylesReducer';
import { clearFolders } from 'reducers/folderReducer';
import { clearReportBuilders } from 'reducers/reportBuilderReducer';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { getOrDefault, hasNotReturned, isSuccess } from 'remotedata';
import { useLoadEditMetadata, usePreventNavigationWithUnsavedChanges } from 'utils/hookUtils';
import { canUserModifyResource } from 'utils/permissionUtils';

import { ReportBuilderPoller } from '../ReportBuilder/ReportBuilderPoller';

import { setIsEditing } from 'reducers/reportBuilderEditReducer';
import { EDIT_MODE_HASH } from 'types/dashboardTypes';
import { getReportBuilderTimezone } from 'utils/customerReportUtils';
import { DatasetEditor } from './DatasetEditor';
import { Header } from './Header';
import { ReportBuilderPreview } from './Preview';
import { VIEW_MODE } from './types';
import { REPORT_BUILDER_BASE_PATH } from 'constants/routes';

Settings.defaultZone = Timezones.UTC;

export const ReportBuilderEditorPage: FC = () => {
  const dispatch = useDispatch();
  const [initialViewIds, setInitialViewIds] = useState<string[]>();
  const { reportBuilderId, view } = useParams<{ reportBuilderId: string; view: VIEW_MODE }>();

  const {
    reportBuilder,
    config,
    fontConfig,
    team,
    globalStyleConfig,
    portalId,
    permissions,
    isEditing,
    currentUser,
    globalDatasetReferences,
    referencedGlobalDatasets,
    latestReferencedGlobalDatasets,
    deletedLatestReferencedGlobalDatasetIds,
    savingInfo,
  } = useSelector(
    (state: ReduxState) => ({
      reportBuilder: state.reportBuilder.currentReportBuilder,
      config: state.reportBuilderEdit.config,
      team: state.currentUser.team,
      permissions: state.currentUser.permissions,
      globalStyleConfig: getCurrentTheme(state.dashboardStyles),
      fontConfig: state.dashboardStyles.fontConfig,
      portalId: state.embeddedReportBuilder.portalId,
      shouldUseFido: state.currentUser.team?.feature_flags.use_fido,
      isEditing: state.reportBuilderEdit.isEditing,
      currentUser: state.currentUser,
      globalDatasetReferences: RD.isSuccess(state.reportBuilderEdit.config)
        ? state.reportBuilderEdit.config.data.versionedComputedViewReferences
        : undefined,
      referencedGlobalDatasets: state.fido.referencedGlobalDatasets,
      latestReferencedGlobalDatasets: state.fido.latestReferencedGlobalDatasets,
      deletedLatestReferencedGlobalDatasetIds: state.fido.deletedLatestReferencedGlobalDatasetIds,
      savingInfo: state.resourceSaving,
    }),
    shallowEqual,
  );

  const metadataLoading = useLoadEditMetadata(
    initialViewIds,
    globalDatasetReferences,
    referencedGlobalDatasets,
    latestReferencedGlobalDatasets,
    deletedLatestReferencedGlobalDatasetIds,
  );

  useEffect(() => {
    if (!team || hasNotReturned(fontConfig)) return;
    loadFonts(globalStyleConfig.text, getOrDefault(fontConfig, []), team.id);
  }, [globalStyleConfig, fontConfig, team]);

  const userCanModifyResource = useMemo(
    () => canUserModifyResource(currentUser.permissions[PERMISSIONED_ENTITIES.REPORT_BUILDER]),
    [currentUser.permissions],
  );

  useEffect(() => {
    const id = parseInt(reportBuilderId);
    if (isNaN(id)) return;

    dispatch(fetchReportBuilderVersionsMetadata({ id }));
    dispatch(
      fetchReportBuilder({ id }, (data) => {
        if (data.report_builder_version.is_draft && window.location.hash === EDIT_MODE_HASH.EDIT) {
          dispatch(setIsEditing(userCanModifyResource));
        } else {
          history.pushState('', document.title, window.location.pathname + window.location.search);
        }
        const globalDatasetIds = new Set(
          Object.keys(data.report_builder_version.config.versionedComputedViewReferences),
        );
        setInitialViewIds(
          Object.values(data.report_builder_version.config.datasets)
            .map((v) => v.fido_id ?? '')
            .filter((datasetId) => !globalDatasetIds.has(datasetId)),
        );
      }),
    );

    // clear folders so if we click back to a folder via the breadcrumbs,
    // we don't show a flicker of the stale stored folder
    dispatch(clearFolders());
    dispatch(clearReportBuilders());
  }, [dispatch, reportBuilderId, userCanModifyResource]);

  const prevInEditMode = usePrevious(isEditing);

  useEffect(() => {
    if (isEditing && !prevInEditMode) {
      window.location.hash = EDIT_MODE_HASH.EDIT;
    } else if (!isEditing && prevInEditMode) {
      history.pushState('', document.title, window.location.pathname + window.location.search);
    }
  }, [isEditing, prevInEditMode]);

  usePreventNavigationWithUnsavedChanges(savingInfo.resourceSaveStatuses, (location) => {
    const pathNameComponents = new Set(location.pathname.split('/'));

    return pathNameComponents.has(REPORT_BUILDER_BASE_PATH);
  });

  const renderPage = (reportBuilderId: number, config: ReportBuilderConfig) => {
    if (!canUserModifyResource(permissions[PERMISSIONED_ENTITIES.REPORT_BUILDER]) || !isEditing) {
      return <ReportBuilderPreview />;
    }

    switch (view) {
      case VIEW_MODE.BUILT_INS:
        return <ReportBuilderBuiltIns />;
      case VIEW_MODE.SETTINGS:
        return <ReportBuilderPreview />;
      default:
        return <DatasetEditor config={config} reportBuilderId={reportBuilderId} />;
    }
  };

  const renderReportBuilderEditor = () => {
    if (!RD.isSuccess(reportBuilder) || !isSuccess(config) || metadataLoading) {
      return <ExploLoadingSpinner />;
    }

    setDisplayTimezone(getReportBuilderTimezone({ reportBuilder, timezone: null }));

    return (
      <div className={containerClass}>
        <Header breadcrumbs={reportBuilder.data.breadcrumbs} reportBuilder={reportBuilder.data} />
        <div className={sprinkles({ display: 'flex', flex: 1, width: 'fill', overflow: 'hidden' })}>
          {renderPage(reportBuilder.data.id, config.data)}
        </div>
      </div>
    );
  };

  return (
    <div
      className={sprinkles({ display: 'flex', parentContainer: 'fill', overflowX: 'auto' })}
      id={portalId}>
      {renderReportBuilderEditor()}
      <ReportBuilderPoller />
    </div>
  );
};

const containerClass = sprinkles({
  flexItems: 'column',
  height: 'fill',
  position: 'relative',
  overflow: 'hidden',
  widthConstraints: 'minOnly',
});
