import { Icon } from '@blueprintjs/core';
import cx from 'classnames';
import { FC, Fragment, useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { updateIsWidgetOpen } from 'actions/chatWidgetActions';
import { fetchUsedParentSchemas } from 'actions/parentSchemaActions';
import { ACTION } from 'actions/types';
import { ExploLoadingSpinner } from 'components/ExploLoadingSpinner';
import { Poller } from 'components/JobQueue/Poller';
import { Button, Switch, CalloutLink, sprinkles } from 'components/ds';
import FullPagePanel from 'components/pages/fullPagePanel';
import { NON_SUCCESS_ICON } from 'constants/iconConstants';
import { createLoadingSelector } from 'reducers/api/selectors';
import { receiveFinishedJobs } from 'reducers/dashboardLayoutReducer';
import { ReduxState } from 'reducers/rootReducer';
import {
  fetchAllDataSourceTablesThunk,
  shouldUseFidoForDefaultDataSource,
  syncTablesThunk,
} from 'reducers/thunks/syncSchemaFlowThunks';
import * as RD from 'remotedata';
import { sortBy } from 'utils/standard';

import * as styles from './index.css';
import { useFidoDataSourceData } from 'utils/hookUtils';
import { showErrorToast } from 'shared/sharedToasts';

type MatchParams = {
  passedSchemaId: string;
};

export const SyncDataTablesPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    params: { passedSchemaId },
  } = useRouteMatch<MatchParams>();

  const { allTables, allTableViews, syncSourceTablesLoading, widget, awaitedJobs } = useSelector(
    (state: ReduxState) => ({
      allTables: state.dataSource.allTables,
      allTableViews: state.fido.allTables,
      awaitedJobs: state.dashboardLayout.awaitedJobs,
      syncSourceTablesLoading: state.currentUser.team?.feature_flags.use_fido
        ? state.fido.syncTableViewsLoading
        : createLoadingSelector([ACTION.SYNC_SOURCE_TABLES], false)(state),
      widget: state.widget,
    }),
    shallowEqual,
  );

  const isFidoLoading = useFidoDataSourceData();

  // the useEffect here should only run once
  const [effectHasRun, setEffectHasRun] = useState(false);
  const [ignoreTableList, setIgnoreTableList] = useState<string[]>([]);
  const [schemaId, setSchemaId] = useState(passedSchemaId);

  const shouldUseFido = useSelector((state: ReduxState) =>
    shouldUseFidoForDefaultDataSource(schemaId, state),
  );

  // this functions as componentDidMount
  useEffect(() => {
    if (effectHasRun || isFidoLoading) return;

    // if the chat widget is not open, open it
    if (!widget.isOpen) {
      dispatch(updateIsWidgetOpen({ isWidgetOpen: true }));
    }

    if (!passedSchemaId) {
      dispatch(
        fetchUsedParentSchemas({}, (result) => {
          const schemaId = result.parent_schemas[0].id;
          dispatch(fetchAllDataSourceTablesThunk({ schemaId: schemaId.toString() }));
          setSchemaId(String(schemaId));
        }),
      );
    } else {
      dispatch(fetchAllDataSourceTablesThunk({ schemaId: passedSchemaId }));
    }

    setEffectHasRun(true);
  }, [dispatch, passedSchemaId, effectHasRun, widget, schemaId, isFidoLoading]);

  const areAllSelected = ignoreTableList.length === 0;

  const renderTableList = (tables: string[]) => (
    <>
      <div className={cx(styles.tableList, sprinkles({ marginBottom: 'sp2.5' }))}>
        <div className={sprinkles({ heading: 'h4' })}>Table Name</div>
        <div
          className={styles.tableListSyncCol}
          onClick={() => setIgnoreTableList(areAllSelected ? tables : [])}>
          {areAllSelected ? 'Unselect all' : 'Select all'}
        </div>
      </div>
      <div className={cx(styles.tableList, styles.tableListTables)}>
        {sortBy(tables).map((name) => {
          const isChecked = !ignoreTableList.includes(name);
          return (
            <Fragment key={`table-name-${name}`}>
              <div className={sprinkles({ fontWeight: 400, color: 'gray11' })}>{name} </div>
              <div className={sprinkles({ display: 'flex', justifyContent: 'flex-end' })}>
                <Switch
                  onChange={() => {
                    if (isChecked) {
                      setIgnoreTableList([...ignoreTableList, name]);
                    } else {
                      setIgnoreTableList(ignoreTableList.filter((tableName) => tableName !== name));
                    }
                  }}
                  switchOn={isChecked}
                />
              </div>
            </Fragment>
          );
        })}
      </div>
    </>
  );

  const renderNonSuccessState = (tableError?: string) => {
    const isError = tableError !== undefined;
    // just sanity checking that the error isn't empty and displaying a generic error
    // message if it is
    const errorMessage = tableError?.includes('Timed out')
      ? 'Timed out while trying to pull tables from your database. Likely, this was because there were too many tables for us to pull. Narrow the scope of the read permissions your Explo user has on your database to only the tables you want to see in Explo, or reach out to support for help.'
      : tableError || 'Something went wrong and we were unable to connect to the data source.';

    return (
      <>
        <div className={cardContainerClass(isError)}>
          <Icon icon={NON_SUCCESS_ICON(isError)} />
          <div className={sprinkles({ color: isError ? 'error' : 'gray11' })}>
            {isError
              ? errorMessage
              : 'We connected to your data source, but 0 tables were returned.'}
          </div>
        </div>
        <CalloutLink
          className={sprinkles({ marginTop: 'sp1' })}
          text="Troubleshoot database connection issues"
          url="https://docs.explo.co/troubleshooting/database-connection-errors"
        />
      </>
    );
  };

  const renderSuccessActions = (tableLen: number) => (
    <div className={styles.actionsContainer}>
      <Button
        fillWidth
        disabled={ignoreTableList.length === tableLen}
        loading={syncSourceTablesLoading}
        onClick={() =>
          dispatch(
            syncTablesThunk({
              schemaId: schemaId,
              ignoreTableNames: ignoreTableList,
              onSuccess: () => history.goBack(),
              onError: (error) => showErrorToast(error),
            }),
          )
        }
        variant="primary">
        Continue
      </Button>
    </div>
  );

  const renderNonSuccessActions = (isError: boolean) => (
    <div className={styles.actionsContainer}>
      <Button
        fillWidth
        onClick={() => dispatch(fetchAllDataSourceTablesThunk({ schemaId: schemaId.toString() }))}
        variant="primary">
        Retry
      </Button>
      <Button
        fillWidth
        onClick={
          isError
            ? history.goBack
            : () =>
                dispatch(
                  syncTablesThunk({
                    schemaId: schemaId,
                    ignoreTableNames: ignoreTableList,
                    onSuccess: () => history.goBack(),
                    onError: (error) => showErrorToast(error),
                  }),
                )
        }
        variant="secondary">
        {isError ? 'Skip for now' : 'Continue'}
      </Button>
    </div>
  );

  const renderSyncPanel = () => {
    return (
      <div className={styles.root}>
        <FullPagePanel
          description="The tables selected will have their schemas (column names and types) saved to enhance the query writing experience. It is not required to sync tables to use Explo, but it is recommended."
          title="Select Tables to Sync">
          {renderPanelContent()}
        </FullPagePanel>
      </div>
    );
  };

  const renderPanelContent = () => {
    const tables = shouldUseFido
      ? RD.getOrDefault(allTableViews, [])
      : RD.getOrDefault(allTables, []).map((item) => item.name);

    const error =
      shouldUseFido && RD.isError(allTableViews)
        ? allTableViews.error
        : !shouldUseFido && RD.isError(allTables)
          ? allTables.error
          : undefined;

    if (error) {
      return (
        <>
          {renderNonSuccessState(error)}
          {renderNonSuccessActions(true)}
        </>
      );
    } else if (tables.length > 0)
      return (
        <>
          {renderTableList(tables)}
          {renderSuccessActions(tables.length)}
        </>
      );
    else {
      return (
        <>
          {renderNonSuccessState()}
          {renderNonSuccessActions(false)}
        </>
      );
    }
  };

  const isLoading =
    (shouldUseFido && RD.isLoading(allTableViews, true)) ||
    (!shouldUseFido && RD.isLoading(allTables, true)) ||
    isFidoLoading;

  return (
    <>
      <Poller
        awaitedJobs={awaitedJobs}
        updateJobResult={(finishedJobIds, onComplete) => {
          if (finishedJobIds.length > 0) dispatch(receiveFinishedJobs(finishedJobIds));

          onComplete();
        }}
      />
      {isLoading ? <ExploLoadingSpinner /> : renderSyncPanel()}
    </>
  );
};

const cardContainerClass = (isError: boolean) =>
  sprinkles({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 4,
    padding: 'sp2',
    gap: 'sp1.5',
    backgroundColor: isError ? 'lightRed' : 'elevationMid',
  });
