import { FilterState } from './types';

export const EXPORT_TYPE = {
  EMAIL: 'email',
} as const;
export type ExportType = (typeof EXPORT_TYPE)[keyof typeof EXPORT_TYPE];

export const EXPORT_STATUS = {
  DRAFT: 'draft',
  PAUSED: 'paused',
  ACTIVE: 'active',
} as const;
export type ExportStatus = (typeof EXPORT_STATUS)[keyof typeof EXPORT_STATUS];

export const SCHEDULE_TYPE = {
  RECURRING: 'recurring',
  ONE_TIME: 'one-time',
} as const;
export type ScheduleType = (typeof SCHEDULE_TYPE)[keyof typeof SCHEDULE_TYPE];

export const SCHEDULE_FREQUENCY = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  CUSTOM: 'custom',
  ONE_TIME: 'one-time',
} as const;
export type ScheduleFrequency = (typeof SCHEDULE_FREQUENCY)[keyof typeof SCHEDULE_FREQUENCY];

export const SCHEDULED_EXPORTS_PER_PAGE = 20;

export const RECENT_ACTIVITY_EMBED_ID = 'placeholderEmbedId';

export const TAB_LABEL = {
  SCHEDULED: 'All Scheduled Exports',
  RECENT: 'Recent Activity',
} as const;
export type TabLabel = (typeof TAB_LABEL)[keyof typeof TAB_LABEL];

export const TAB_ID = {
  SCHEDULED: 'scheduled',
  RECENT: 'recent',
} as const;
export type TabId = (typeof TAB_ID)[keyof typeof TAB_ID];

export const TAB_LABELS = Object.values(TAB_LABEL);

export const tabLabelToId = (tab: TabLabel): TabId => {
  return tab === TAB_LABEL.RECENT ? TAB_ID.RECENT : TAB_ID.SCHEDULED;
};

export const EXPORT_SORT_OPTION = {
  LAST_EDITED: 'last edited',
  UPCOMING: 'upcoming',
} as const;
export type ExportSortOption = (typeof EXPORT_SORT_OPTION)[keyof typeof EXPORT_SORT_OPTION];

export const ALL_CADENCES = Object.values(SCHEDULE_FREQUENCY);
export const ALL_STATUSES = Object.values(EXPORT_STATUS);

export const INITIAL_FILTER_STATE: FilterState = {
  cadences: new Set<ScheduleFrequency>(),
  statuses: new Set<ExportStatus>(),
  recipients: {
    all: null,
    specific: null,
  },
};
