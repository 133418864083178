import {
  BranchContentResourceService,
  ListResourcesResponse,
  ListViewsResponse,
  ResourceResponse,
  UUID,
} from '@explo-tech/fido-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ACTION } from 'actions/types';
import { ReduxState } from 'reducers/rootReducer';
import { ResourceType } from 'types/exploResource';
import { makeFidoThunkRequest } from 'utils/thunkUtils';

export const getResourceOnBranchThunk = createAsyncThunk<
  ResourceResponse,
  { branchId: string; resourceId: string },
  { state: ReduxState }
>(ACTION.FIDO_GET_RESOURCE_ON_BRANCH, async ({ branchId, resourceId }, { getState }) => {
  const fido = getState().fido;

  return makeFidoThunkRequest(
    () => BranchContentResourceService.getResourceOnBranch(branchId, resourceId),
    fido.fidoToken ?? '',
    'Error fetching resource on branch',
  );
});

export const getResourceHistoryThunk = createAsyncThunk<
  ListResourcesResponse,
  { branchId: UUID; resourceId: UUID; page: number; resourceType: ResourceType },
  { state: ReduxState }
>(ACTION.FIDO_GET_RESOURCE_HISTORY, async ({ branchId, resourceId, page }, { getState }) => {
  const fido = getState().fido;

  return makeFidoThunkRequest(
    () => BranchContentResourceService.getResourceHistory(branchId, resourceId, page),
    fido.fidoToken ?? '',
    'Error fetching resource history',
  );
});

export const searchBranchContentThunk = createAsyncThunk<
  ListViewsResponse,
  { branchId: UUID; searchQuery: string; page: number },
  { state: ReduxState }
>(ACTION.FIDO_SEARCH_BRANCH_CONTENT, async ({ branchId, searchQuery, page }, { getState }) => {
  const fido = getState().fido;

  return makeFidoThunkRequest(
    () =>
      BranchContentResourceService.searchBranchContent(
        branchId,
        page,
        /* perPage= */ undefined,
        searchQuery,
      ),
    fido.fidoToken ?? '',
    'Error searching branch content',
  );
});
