import { FC } from 'react';

import {
  EDIT_IN_DATA_LIBRARY_BUTTON_TEXT,
  EDIT_IN_DATA_LIBRARY_BUTTON_TOOLTIP_TEXT,
  EDIT_IN_DATA_LIBRARY_DATASET_DELETED_TOOLTIP_TEXT,
} from 'components/DataLibrary/constants';
import { Button, Input, sprinkles } from 'components/ds';
import { ConfigSection } from 'components/resource/ConfigSection';
import * as sharedStyles from 'components/resource/index.css';
import { showErrorToast } from 'shared/sharedToasts';
import { ReadAccessComputedView } from 'utils/fido/fidoShimmedTypes';

type ValueParams = {
  name?: string;
  description?: string;
  provided_id?: string;
};

type Props = {
  defaultIsOpen: boolean;
  initialDescription: string;
  initialName: string;
  isDatasetBackedByGlobalDataset: boolean;
  latestBackingGlobalDataset: ReadAccessComputedView | undefined;
  initialOverrideName: string;
  initialOverrideDescription: string;

  handleNewValueSubmitted: (value: ValueParams) => void;
  onEditInDataLibrary?: () => void;
};

const NAME_INFO_TEXT = 'This is the name your customers will see in the template selection modal';

const DESCRIPTION_INFO_TEXT = 'This is the description your customers will see';

const GLOBAL_DATASET_NAME_INFO_TEXT =
  'This is the name of the backing global dataset. It cannot be changed here.';

const GLOBAL_DATASET_DESCRIPTION_INFO_TEXT =
  'This is the description of the backing global dataset. It cannot be changed here.';

const GLOBAL_DATASET_NAME_OVERRIDE_INFO_TEXT = 'Override the name of the global dataset.';

const GLOBAL_DATASET_DESCRIPTION_OVERRIDE_INFO_TEXT =
  'Override the description of the global dataset.';

export const MetadataInputs: FC<Props> = ({
  defaultIsOpen,
  initialDescription,
  initialName,
  isDatasetBackedByGlobalDataset,
  latestBackingGlobalDataset,
  initialOverrideName,
  initialOverrideDescription,
  handleNewValueSubmitted,
  onEditInDataLibrary,
}) => {
  return (
    <ConfigSection defaultIsOpen={defaultIsOpen} title="General">
      <div className={sharedStyles.configSection}>
        <InputSection
          descriptionInfoText={
            isDatasetBackedByGlobalDataset
              ? GLOBAL_DATASET_DESCRIPTION_INFO_TEXT
              : DESCRIPTION_INFO_TEXT
          }
          disabled={isDatasetBackedByGlobalDataset}
          handleNewValueSubmitted={handleNewValueSubmitted}
          initialDescription={initialDescription}
          initialName={initialName}
          nameInfoText={
            isDatasetBackedByGlobalDataset ? GLOBAL_DATASET_NAME_INFO_TEXT : NAME_INFO_TEXT
          }
        />
        {isDatasetBackedByGlobalDataset ? (
          <ConfigSection title="Overrides">
            <div className={sharedStyles.configSection}>
              <InputSection
                descriptionInfoText={GLOBAL_DATASET_DESCRIPTION_OVERRIDE_INFO_TEXT}
                disabled={false}
                handleNewValueSubmitted={handleNewValueSubmitted}
                initialDescription={initialOverrideDescription ?? ''}
                initialName={initialOverrideName ?? ''}
                nameInfoText={GLOBAL_DATASET_NAME_OVERRIDE_INFO_TEXT}
              />
            </div>
          </ConfigSection>
        ) : null}
        {isDatasetBackedByGlobalDataset ? (
          <Button
            className={sprinkles({ margin: 'sp1' })}
            disabled={!latestBackingGlobalDataset}
            onClick={onEditInDataLibrary}
            tooltipProps={{
              text: !latestBackingGlobalDataset
                ? EDIT_IN_DATA_LIBRARY_DATASET_DELETED_TOOLTIP_TEXT
                : EDIT_IN_DATA_LIBRARY_BUTTON_TOOLTIP_TEXT,
            }}
            variant="secondary">
            {EDIT_IN_DATA_LIBRARY_BUTTON_TEXT}
          </Button>
        ) : null}
      </div>
    </ConfigSection>
  );
};

type InputSectionProps = {
  initialName: string;
  initialDescription: string;
  nameInfoText: string;
  descriptionInfoText: string;
  disabled: boolean;
  handleNewValueSubmitted: (newValue: ValueParams) => void;
};

const InputSection: FC<InputSectionProps> = ({
  initialName,
  initialDescription,
  nameInfoText,
  descriptionInfoText,
  disabled,
  handleNewValueSubmitted,
}) => {
  return (
    <>
      <div className={sprinkles({ display: 'flex', gap: 'sp1.5' })}>
        <Input
          fillWidth
          defaultValue={initialName}
          disabled={disabled}
          label={{
            text: 'Name',
            infoText: nameInfoText,
          }}
          onSubmit={(newName) => {
            const trimmedName = newName.trim();
            if (trimmedName === '') return showErrorToast('Name cannot be empty.');
            handleNewValueSubmitted({ name: trimmedName });
          }}
        />
      </div>

      <Input
        fillWidth
        defaultValue={initialDescription}
        disabled={disabled}
        label={{
          text: 'Description',
          infoText: descriptionInfoText,
        }}
        onSubmit={(newDescription) => handleNewValueSubmitted({ description: newDescription })}
      />
    </>
  );
};
