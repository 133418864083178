import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  ReportBuilderColConfig,
  ReportBuilderDataset,
  UpdateReportBuilderDataset,
} from 'actions/reportBuilderConfigActions';
import { sprinkles } from 'components/ds';
import { ColumnHeader } from 'components/resource/ColumnHeader';
import {
  DatasetColumnSelection,
  DatasetColumnSelectionItem,
} from 'components/resource/DatasetColumnSelection';
import { QuerySection } from 'components/resource/QuerySection';
import { DatasetColumnFormat } from 'pages/ReportBuilderEditor/DatasetEditor/DatasetColumnFormat';
import {
  DatasetModal,
  DatasetModalEnum,
  DatasetModalType,
} from 'pages/ReportBuilderEditor/DatasetEditor/DatasetModal';
import { MetadataInputs } from 'pages/ReportBuilderEditor/DatasetEditor/MetadataInputs';
import { PermissionConfig } from 'pages/ReportBuilderEditor/DatasetEditor/PermissionConfig';
import {
  updateReportBuilderColConfig,
  updateReportBuilderDataset,
} from 'reducers/reportBuilderEditReducer';
import { ReduxState } from 'reducers/rootReducer';
import { saveComputedView } from 'reducers/thunks/fidoThunks';
import * as RD from 'remotedata';
import { fetchAppDataset } from 'reportBuilderContent/thunks/appDataThunks';

import { navigateToPathAndExpandFoldersThunk } from 'pages/dataLibraryPage/navigationUtils';
import { useHistory } from 'react-router';
import { ItemType } from 'reducers/dataLibraryReducer';
import { DATA_PREVIEW_TYPE } from 'reportBuilderContent/apiTypes';
import { ReadAccessComputedView } from 'utils/fido/fidoShimmedTypes';
import { DatasetCustomAggSection } from './DatasetCustomAggSection';

type Props = {
  dataset: ReportBuilderDataset;
  isNew: boolean;
  backingGlobalDataset?: ReadAccessComputedView;
  latestBackingGlobalDataset?: ReadAccessComputedView;
};

export const DatasetConfig: FC<Props> = ({
  dataset,
  isNew,
  backingGlobalDataset,
  latestBackingGlobalDataset,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [openModal, setOpenModal] = useState<DatasetModalType>();
  const [hasShownSaveModal, setHasShownSaveModal] = useState(false);
  const { shouldUseFido, schemas, datasetData } = useSelector(
    (state: ReduxState) => ({
      shouldUseFido: state.currentUser.team?.feature_flags.use_fido,
      schemas: state.parentSchemas,
      datasetData: state.reportBuilderEdit.datasetData,
    }),
    shallowEqual,
  );

  useEffect(() => {
    setOpenModal(undefined);
  }, [dataset.id]);

  const currentSchemaId = shouldUseFido
    ? (dataset.namespace_id ?? dataset.parent_schema_id)
    : dataset.parent_schema_id;

  const [prevDatasetId, setPrevDatasetId] = useState(dataset.id);
  const [prevSchemaId, setPrevSchemaId] = useState(currentSchemaId);

  useEffect(() => {
    if (dataset.id !== prevDatasetId) {
      setPrevDatasetId(dataset.id);
      setPrevSchemaId(currentSchemaId);
      // If we have changed datasets, we don't want to accidentally save a draft query
      // The user should be allowed to toggle between datasets and preserve the draft state
      dispatch(
        fetchAppDataset({
          datasetId: dataset.id,
          save: !dataset.queryDraft,
          dataPreviewType: DATA_PREVIEW_TYPE.FULL_PREVIEW,
        }),
      );
    } else if (currentSchemaId !== prevSchemaId) {
      setPrevDatasetId(dataset.id);
      setPrevSchemaId(currentSchemaId);
      // If we are within the same dataset, but the underlying schema has changed,
      // rerun and save the results to reflect the update in underlying schema
      dispatch(
        fetchAppDataset({
          datasetId: dataset.id,
          save: true,
          dataPreviewType: DATA_PREVIEW_TYPE.FULL_PREVIEW,
        }),
      );
    }
  }, [currentSchemaId, dataset.id, dataset.queryDraft, prevDatasetId, prevSchemaId, dispatch]);

  const fetchDataset = (save: boolean) =>
    dispatch(
      fetchAppDataset({
        datasetId: dataset.id,
        save,
        dataPreviewType: DATA_PREVIEW_TYPE.FULL_PREVIEW,
      }),
    );

  const updateConfig = (colName: string, config: Partial<ReportBuilderColConfig>) => {
    dispatch(
      updateReportBuilderColConfig({
        datasetId: dataset.id,
        colName,
        config,
      }),
    );
  };

  const updateDataset = useCallback(
    (payload: UpdateReportBuilderDataset) => {
      const descriptionPayload = payload.description ?? dataset.description;
      // Don't attempt to save to FIDO if this UI is for a global dataset since the global dataset
      // should be immutable and only the metadata that is associated specifically for a report
      // builder should be saved to FIDO.
      const shouldSaveToFido = shouldUseFido && !backingGlobalDataset;
      shouldSaveToFido
        ? dispatch(
            saveComputedView({
              '@type': 'computed-view',
              id: dataset.fido_id ?? '',
              namespaceId:
                RD.getOrDefault(schemas.usedParentSchemas, []).find(
                  (s) => s.id === (payload.schemaId ?? dataset.parent_schema_id),
                )?.fido_id ?? '',
              name: payload.name ?? dataset.name,
              description: descriptionPayload.length === 0 ? null : descriptionPayload,
              permissions: payload.permissions ?? dataset.permissions,
              columnDefinitions: [],
              query: dataset.query,
              cacheEvictionPolicy: null,
              path: null,
              parameters: [],
            }),
          )
        : dispatch(updateReportBuilderDataset(payload));
    },
    [dataset, dispatch, schemas.usedParentSchemas, shouldUseFido, backingGlobalDataset],
  );

  const handleNewValueSubmitted = (params: Partial<UpdateReportBuilderDataset>) =>
    updateDataset({ datasetId: dataset.id, ...params });

  const isUnsavedSchema = !dataset.schema?.length;
  const showSaveModal = !isUnsavedSchema && !hasShownSaveModal;
  const datasetDatum = datasetData?.[dataset.id];
  // Previewing a custom aggregation does not change the dataset's schema. The column config options
  // are dependent on the dataset schema and therefore can be shown regardless of custom aggregation
  // preview state.
  const showColumnConfigOptions =
    (!datasetDatum?.error && !datasetDatum?.loading) ||
    datasetDatum.dataPreviewType === DATA_PREVIEW_TYPE.CUSTOM_AGGREGATION;

  const onSelectSchema = useMemo(() => {
    if (backingGlobalDataset) {
      return undefined;
    }

    return (schemaId: number) =>
      showSaveModal
        ? setOpenModal({
            type: DatasetModalEnum.SELECT_SCHEMA,
            schemaIdToSelect: schemaId,
            isUnsavedSchema,
          })
        : updateDataset({ datasetId: dataset.id, schemaId });
  }, [updateDataset, dataset.id, backingGlobalDataset, showSaveModal, isUnsavedSchema]);

  const onEditInDataLibrary = useCallback(() => {
    if (!backingGlobalDataset || !latestBackingGlobalDataset) {
      return;
    }

    // Use the latest global dataset's path since the global dataset might have moved.
    dispatch(
      navigateToPathAndExpandFoldersThunk(
        backingGlobalDataset.id ?? '',
        ItemType.VIEW,
        latestBackingGlobalDataset.path ?? '',
        history,
      ),
    );
  }, [backingGlobalDataset, latestBackingGlobalDataset, history, dispatch]);

  return (
    <>
      <ColumnHeader title="Configuration" />
      <div className={sprinkles({ flex: 1, width: 'fill', overflowY: 'auto' })}>
        <MetadataInputs
          defaultIsOpen={isNew}
          handleNewValueSubmitted={handleNewValueSubmitted}
          initialDescription={
            backingGlobalDataset ? (backingGlobalDataset.description ?? '') : dataset.description
          }
          initialName={backingGlobalDataset ? backingGlobalDataset.name : dataset.name}
          initialOverrideDescription={dataset.description}
          initialOverrideName={dataset.name}
          isDatasetBackedByGlobalDataset={!!backingGlobalDataset}
          latestBackingGlobalDataset={latestBackingGlobalDataset}
          onEditInDataLibrary={onEditInDataLibrary}
        />
        <QuerySection
          backingGlobalDataset={backingGlobalDataset}
          dataset={dataset}
          getPreview={() => fetchDataset(false)}
          latestBackingGlobalDataset={latestBackingGlobalDataset}
          onEditInDataLibrary={onEditInDataLibrary}
          onSelectSchema={onSelectSchema}
          saveQuery={() =>
            showSaveModal
              ? setOpenModal({
                  type: DatasetModalEnum.SAVE,
                  isUnsavedSchema: !dataset.schema?.length,
                })
              : fetchDataset(true)
          }
        />
        <PermissionConfig
          handleNewValueSubmitted={handleNewValueSubmitted}
          initialPermissions={dataset.permissions}
          key={dataset.id}
        />
        {showColumnConfigOptions && dataset.schema?.length ? (
          <>
            <DatasetColumnSelection
              isNewDataset={isNew}
              sectionText="Select which columns will be visible to your end users. Default filters will be filters exposed to the customer, regardless of if the columns are visible to them or not."
              toggleName="Default filter">
              {dataset.schema.map((col) => {
                const columnConfig = dataset.columnConfigs[col.name];
                if (!columnConfig) return null;

                return (
                  <DatasetColumnSelectionItem
                    alwaysShowToggle
                    column={col}
                    isVisible={columnConfig.isVisible}
                    key={col.name}
                    onToggle={(newVal) => updateConfig(col.name, { showDefaultFilter: newVal })}
                    onVisibilityToggle={(newVal) => updateConfig(col.name, { isVisible: newVal })}
                    toggleOn={!!columnConfig.showDefaultFilter}
                  />
                );
              })}
            </DatasetColumnSelection>
            <DatasetColumnFormat dataset={dataset} updateConfig={updateConfig} />
            <DatasetCustomAggSection
              dataset={dataset}
              onDelete={(aggId) =>
                setOpenModal({ type: DatasetModalEnum.DELETE_CUSTOM_AGG, aggId })
              }
            />
          </>
        ) : null}
      </div>
      <DatasetModal
        datasetId={dataset.id}
        openModal={openModal}
        setHasShownSaveModal={setHasShownSaveModal}
        setOpenModal={setOpenModal}
        updateDataset={updateDataset}
      />
    </>
  );
};
