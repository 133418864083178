import { Team } from 'actions/teamActions';
import { fetchAiSuggestion } from 'reducers/thunks/aiThunks';
import { EVENTS, trackEvent } from 'telemetry/exploAnalytics';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { ReduxState } from 'reducers/rootReducer';

export enum AIGoal {
  OPTIMIZE = 'optimize',
  EXPLAIN = 'explain',
  DEBUG = 'debug',
}

export const getHandleAIClickFn =
  (arg: {
    dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>;
    team?: Team;
    customerName?: string;
    query?: string;
    error?: string;
  }) =>
  (goal: AIGoal) => {
    const { dispatch, team, query, error } = arg;

    if (!query) return;

    const event =
      goal === AIGoal.OPTIMIZE
        ? EVENTS.AI_OPTIMIZED_QUERY
        : goal === AIGoal.EXPLAIN
          ? EVENTS.AI_EXPLAINED_QUERY
          : EVENTS.AI_DEBUGGED_QUERY;

    trackEvent(event, {
      team_id: team?.id,
      team_name: team?.team_name,
    });

    dispatch(fetchAiSuggestion({ query, error, goal }));
  };
