import * as RadixPopover from '@radix-ui/react-popover';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { createDebouncedFn } from 'utils/general';

import { SearchBar } from 'components/SearchBar/SearchBar';
import * as styles from './index.css';
import { ResourceSearchResult, ResourceType } from 'types/exploResource';
import { Breadcrumbs, Icon, sprinkles } from 'components/ds';

type Props = {
  searchResults: ResourceSearchResult[];
  isLoadingResults: boolean;
  searchBarPlaceholderText: string;
  shouldKeepFocus: boolean;

  onItemClicked: (id: string, name: string, type: ResourceType) => void;
  onSearch: (searchQuery: string) => void;
  onClearSearch: () => void;
  onSearchBarExpanded?: (isExpanded: boolean) => void;
};

const debounceFn = createDebouncedFn(400);

// backend search resources always returns at most 20 items
const MAX_VALUES = 20;

export const SearchResources: FC<Props> = ({
  searchResults,
  isLoadingResults,
  searchBarPlaceholderText,
  shouldKeepFocus,
  onItemClicked,
  onSearch,
  onClearSearch,
  onSearchBarExpanded,
}) => {
  const inputRef = useRef<HTMLDivElement>(null);
  const searchResultsRef = useRef<HTMLDivElement>(null);

  const [inputValue, setInputValue] = useState<string>();
  const [isSearchBarExpanded, setIsSearchBarExpanded] = useState(false);

  const setIsSearchBarExpandedWrapper = useCallback(
    (isExpanded: boolean) => {
      setIsSearchBarExpanded(isExpanded);
      onSearchBarExpanded?.(isExpanded);
    },
    [onSearchBarExpanded],
  );

  const onReset = useCallback(() => {
    onClearSearch();
    setIsSearchBarExpandedWrapper(false);
    setInputValue(undefined);
  }, [onClearSearch, setIsSearchBarExpandedWrapper]);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
        if (searchResultsRef.current && searchResultsRef.current.contains(e.target as Node)) {
          return;
        }
        onReset();
      }
    };

    document.body.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.body.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onReset]);

  const handleFiltering = useCallback(
    (name: string) => {
      debounceFn(() => {
        if (name) {
          onSearch(name);
        } else {
          onClearSearch();
        }
        setInputValue(name);
      });
    },
    [onSearch, onClearSearch],
  );

  return (
    <div
      style={{
        flex: isSearchBarExpanded ? '0 0 450px' : undefined,
      }}>
      <RadixPopover.Root
        onOpenChange={(isOpen) => setIsSearchBarExpandedWrapper(!isOpen)}
        open={!!inputValue && isSearchBarExpanded}>
        <RadixPopover.Anchor className={styles.anchor}>
          <SearchBar
            isExpanded={isSearchBarExpanded}
            onSetIsExpanded={setIsSearchBarExpandedWrapper}
            onSubmit={(searchText) => {
              handleFiltering(searchText);
            }}
            placeholderText={searchBarPlaceholderText}
            ref={inputRef}
            shouldKeepFocus={shouldKeepFocus}
          />
        </RadixPopover.Anchor>
        {searchResults.length === 0 && isLoadingResults ? null : (
          <RadixPopover.Content
            className={styles.content}
            collisionPadding={{ top: 24, right: 16 }}
            ref={searchResultsRef}>
            <div
              onClick={() => {
                onReset();
              }}>
              {searchResults.map(({ id, name, type, breadcrumbs }) => (
                <div
                  className={styles.resource}
                  key={`${name}-${id.toString()}`}
                  onClick={() => {
                    onItemClicked(id.toString(), name, type);
                    onReset();
                  }}>
                  <Icon
                    className={sprinkles({ color: 'contentTertiary' })}
                    name={type === ResourceType.FOLDER ? ResourceType.FOLDER : 'window-maximize'}
                    size="md"
                  />
                  <div className={styles.itemInfo}>
                    <div className={sprinkles({ truncateText: 'ellipsis' })}>{name}</div>
                    <Breadcrumbs
                      items={breadcrumbs.map(({ name }) => ({ text: name, onClick: undefined }))}
                    />
                  </div>
                </div>
              ))}
            </div>
            {searchResults.length === 0 ? (
              <div className={styles.resultsInfo}>No results</div>
            ) : null}
            {searchResults.length >= MAX_VALUES ? (
              <div className={styles.resultsInfo}>Filter for more results</div>
            ) : null}
          </RadixPopover.Content>
        )}
      </RadixPopover.Root>
    </div>
  );
};
