import { FC, useCallback, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Dashboard, deleteDashboardThunk, renameDashboard } from 'actions/dashboardActions';
import { sprinkles } from 'components/ds';
import { DashboardSelectionControl } from 'pages/dashboardPage/LayersPanel/DashboardSelectionControl';
import { DashboardSelectionControlDatum } from 'pages/dashboardPage/LayersPanel/types';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { DashboardHierarchy, DashboardVersionHierarchy } from 'types/dashboardTypes';
import { getDashboardDepth } from 'utils/dashboardHierarchyUtils';

import { RenameModal } from 'components/RenameModal';
import { ROUTE_PROVIDERS, ROUTES } from 'constants/routes';
import { useHistory } from 'react-router';
import { DrilldownSourceInfo } from 'reducers/drilldownsReducer';
import { DeleteResourceConfirmationModal } from 'shared/ExploResource/DeleteResourceConfirmationModal';
import { ResourceType } from 'types/exploResource';
import {
  getAllAncestorDashboardIds,
  getAncestorDashboardNames,
} from 'utils/dashboardDrilldownUtils';
import { cloneDeep } from 'utils/standard';
import { AddNewLayerPopover } from './AddNewLayerPopover';
import { DASHBOARD_NAME_ALREADY_EXISTS_ERROR_MESSAGE } from './constants';

interface DeleteDashboardInfo {
  dashboardId: number;
  dashboardName: string;
  isRoot: boolean;
}

interface RenameDashboardInfo {
  dashboardId: number;
  dashboardName: string;
}

export const LayersPanel: FC = () => {
  const [deleteDashboardInfo, setDeleteDashboardInfo] = useState<DeleteDashboardInfo | undefined>();
  const [renameDashboardInfo, setRenameDashboardInfo] = useState<RenameDashboardInfo | undefined>();

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    dashboard,
    dashboardHierarchy,
    dashboardVersionHierarchy,
    currentUser,
    selectedDashboardItemId,
  } = useSelector((state: ReduxState) => {
    return {
      dashboard: RD.getOrDefault(state.dashboard.currentDashboard, undefined),
      dashboardHierarchy: RD.getOrDefault(state.dashboard.dashboardHierarchy, undefined),
      dashboardVersionHierarchy: RD.getOrDefault(
        state.dashboardEditConfig.versionHierarchy,
        undefined,
      ),
      currentUser: state.currentUser,
      selectedDashboardItemId: state.dashboardInteractions.selectedItem?.id,
    };
  }, shallowEqual);

  const itemControlDatas = useMemo(() => {
    if (!dashboardHierarchy || !dashboardVersionHierarchy) {
      return [];
    }
    return createDashboardControlData(dashboardHierarchy, dashboardVersionHierarchy);
  }, [dashboardHierarchy, dashboardVersionHierarchy]);

  const selectedDashboardDepth = useMemo(() => {
    if (dashboard && dashboardHierarchy) {
      return getDashboardDepth(dashboardHierarchy.dashboards, dashboard.id);
    }
    return 0;
  }, [dashboard, dashboardHierarchy]);

  const currentDashboardConfig = useMemo(() => {
    if (!dashboard || !dashboardVersionHierarchy) {
      return null;
    }
    return dashboardVersionHierarchy.dashboardVersions[dashboard.id]?.configuration;
  }, [dashboard, dashboardVersionHierarchy]);

  const dashboardIdToSourceDataPanelIdMap = useMemo(() => {
    const dashboardIdToSourceDataPanelIdMap: Record<number, string> = {};
    if (!currentDashboardConfig) {
      return dashboardIdToSourceDataPanelIdMap;
    }

    Object.values(currentDashboardConfig.data_panels).forEach((dataPanel) => {
      const drilldownEntryPoints = dataPanel.drilldownEntryPoints;
      Object.values(drilldownEntryPoints).forEach((drilldownEntryPoint) => {
        dashboardIdToSourceDataPanelIdMap[drilldownEntryPoint.destinationDashboardId] =
          dataPanel.id;
      });
    });

    return dashboardIdToSourceDataPanelIdMap;
  }, [currentDashboardConfig]);

  const onDeleteDashboard = useCallback(() => {
    if (!deleteDashboardInfo) {
      return;
    }

    dispatch(
      deleteDashboardThunk(deleteDashboardInfo.dashboardId, deleteDashboardInfo.isRoot, () => {
        if (!dashboardHierarchy || !dashboard) {
          return;
        }

        if (deleteDashboardInfo.isRoot) {
          history.push(ROUTES.HOME);
        }

        const currentDashboardAncestorIds = new Set(
          getAllAncestorDashboardIds(dashboardHierarchy, dashboard.id),
        );
        currentDashboardAncestorIds.add(dashboard.id);
        const isCurrentDashboardDescendantOfDeletedDashboard = currentDashboardAncestorIds.has(
          deleteDashboardInfo.dashboardId,
        );

        if (!isCurrentDashboardDescendantOfDeletedDashboard) {
          return;
        }

        // If the current dashboard is a descendant of the deleted dashboard, then the current
        // dashboard has been deleted as well and we need to navigate to the parent dashboard of the
        // deleted dashboard.
        const parentDashboardId =
          dashboardHierarchy?.dashboards[deleteDashboardInfo.dashboardId].parentDashboardId;
        if (parentDashboardId) {
          history.push(ROUTE_PROVIDERS.DASHBOARD_EDIT_MODE(`${parentDashboardId}`));
        }
      }),
    );
  }, [deleteDashboardInfo, history, dashboardHierarchy, dashboard, dispatch]);

  const ancestorDashboardNamesForDashboardToRename: Set<string> = useMemo(() => {
    if (!renameDashboardInfo || !dashboardHierarchy) {
      return new Set();
    }

    const renameDashboardParentId =
      dashboardHierarchy.dashboards[renameDashboardInfo.dashboardId].parentDashboardId;

    if (!renameDashboardParentId) {
      return new Set();
    }

    return getAncestorDashboardNames(dashboardHierarchy, renameDashboardParentId);
  }, [renameDashboardInfo, dashboardHierarchy]);

  if (!dashboard || !dashboardHierarchy || !dashboardVersionHierarchy || !currentDashboardConfig) {
    // Consider rendering a loading spinner here but we shouldn't ever be in this state. The
    // dashboard page blocks any other rendering until the dashboard is finished fetching.
    return null;
  }

  return (
    <div className={sprinkles({ paddingX: 'sp2' })}>
      <div className={PANEL_HEADER_CLASS}>
        <div className={sprinkles({ fontWeight: 600 })}>Views</div>
        <AddNewLayerPopover
          currentUser={currentUser}
          dashboardHierarchy={dashboardHierarchy}
          dataPanelsById={currentDashboardConfig.data_panels}
          parentDashboard={dashboard}
          parentDashboardDepth={selectedDashboardDepth}
          parentDashboardVersion={dashboardVersionHierarchy.dashboardVersions[dashboard.id]}
        />
      </div>
      <div className={sprinkles({ paddingTop: 'sp1' })}>
        {itemControlDatas.map((itemData) => (
          <DashboardSelectionControl
            currentDashboardId={dashboard.id}
            dashboardIdToSourceDataPanelIdMap={dashboardIdToSourceDataPanelIdMap}
            isChildOfSelectedDashboard={
              // The hierarchy level is 0 indexed while the dashboard depth is 1 indexed.
              itemData.hierarchyLevel + 1 === selectedDashboardDepth + 1
            }
            itemData={itemData}
            key={`layers-panel-control-${itemData.name}`}
            onDeleteFn={(dashboardId, dashboardName, isRoot) => {
              setDeleteDashboardInfo({ dashboardId, dashboardName, isRoot });
            }}
            onRenameFn={(dashboardId, dashboardName) => {
              setRenameDashboardInfo({ dashboardId, dashboardName });
            }}
            selectedDashboardItemId={selectedDashboardItemId}
          />
        ))}
      </div>
      {deleteDashboardInfo ? (
        <DeleteResourceConfirmationModal
          onClose={() => setDeleteDashboardInfo(undefined)}
          onDelete={onDeleteDashboard}
          resourceName={deleteDashboardInfo.dashboardName}
        />
      ) : null}
      {renameDashboardInfo ? (
        <RenameModal
          isOpen
          isLoadingUniqueNames={false}
          onClose={() => setRenameDashboardInfo(undefined)}
          onSubmit={(newName) => {
            dispatch(
              renameDashboard({ postData: { name: newName }, id: renameDashboardInfo.dashboardId }),
            );
          }}
          resourceName={renameDashboardInfo.dashboardName}
          resourceType={ResourceType.DASHBOARD}
          uniqueNameErrorText={DASHBOARD_NAME_ALREADY_EXISTS_ERROR_MESSAGE}
          uniqueNames={ancestorDashboardNamesForDashboardToRename}
        />
      ) : null}
    </div>
  );
};

const PANEL_HEADER_CLASS = sprinkles({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: 'sp2',
});

const createDashboardControlData = (
  dashboardHierarchy: DashboardHierarchy,
  dashboardVersionHierarchy: DashboardVersionHierarchy,
): DashboardSelectionControlDatum[] => {
  const dashboardMetadataItems: DashboardSelectionControlDatum[] = [];
  createDashboardControlDataHelper(
    dashboardHierarchy.dashboards[dashboardHierarchy.rootDashboardId],
    0,
    dashboardMetadataItems,
    dashboardHierarchy,
    dashboardVersionHierarchy,
    [],
  );
  return dashboardMetadataItems;
};

const createDashboardControlDataHelper = (
  currentDashboard: Dashboard,
  hierarchyLevel: number,
  itemDatas: DashboardSelectionControlDatum[],
  dashboardHierarchy: DashboardHierarchy,
  dashboardVersionHierarchy: DashboardVersionHierarchy,
  currentDrilldownSourceInfos: DrilldownSourceInfo[],
) => {
  if (!dashboardVersionHierarchy.dashboardVersions[currentDashboard.id]) {
    // Do not include dashboards that are not present in the current version. If a dashboard is not
    // present in the current version, none of its children should be either so we can return early.
    return;
  }
  itemDatas.push({
    name: currentDashboard.name,
    hierarchyLevel,
    dashboardId: currentDashboard.id,
    drilldownSourceInfos: cloneDeep(currentDrilldownSourceInfos),
  });
  currentDrilldownSourceInfos.push({
    sourceDashboardId: currentDashboard.id,
    sourceDataPanelId: undefined,
    drilldownEntryPointId: undefined,
  });
  const childDashboardIds = currentDashboard.childDashboardIds;
  childDashboardIds.forEach((childDashboardId) => {
    createDashboardControlDataHelper(
      dashboardHierarchy.dashboards[childDashboardId],
      hierarchyLevel + 1,
      itemDatas,
      dashboardHierarchy,
      dashboardVersionHierarchy,
      cloneDeep(currentDrilldownSourceInfos),
    );
  });
};
