import { QueryExecutionResponse } from '@explo-tech/fido-api';
import { FC, useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { BaseCol, DatasetSchema } from '@explo/data';

import {
  Dataset,
  DrilldownColConfig,
  DrilldownColConfigOptions,
  DrilldownConfig,
  saveDraftComputedViewQuery,
  setSelectedDrilldownColumn,
  updateDatasetDrilldownColConfig,
  updateDatasetDrilldownColConfigs,
} from 'actions/datasetActions';
import { FetchDashboardDatasetPreviewData } from 'actions/responseTypes';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getDatasetsByFidoId } from 'reducers/selectors';
import {
  fetchEditorDatasetPreviewThunk,
  fetchSavedDatasetThunk,
} from 'reducers/thunks/dashboardDataThunks/fetchDatasetPreviewThunks';
import { saveComputedView } from 'reducers/thunks/fidoThunks';
import * as RD from 'remotedata';
import { showDuplicateColumnNameToast } from 'shared/sharedToasts';
import { EVENTS, trackEvent } from 'telemetry/exploAnalytics';
import { ComputedViewWithIds } from 'utils/fido/fidoShimmedTypes';
import {
  getEmbeddoSchemaFromFidoSchema,
  getFidoSchemaFromEmbeddoSchema,
} from 'utils/fido/fidoShims';
import { getDuplicateColumnsFromSchema } from 'utils/queryUtils';
import * as styles from './styles.css';

import { getEmbeddoSchemaIdFromView, switchSelectedDataset } from './utils';

import { getDatasetConfigFromView } from 'utils/fido/fidoShims';

import { createNewDashboardParam } from 'utils/variableUtils';
import { DatasetEditor } from './DatasetEditor';
import { DatasetSelectTopBar } from './DatasetSelectTopBar';
import { updateDatasetDrilldownConfig } from 'reducers/dashboardEditConfigReducer';

type Props = {
  selectedView?: ComputedViewWithIds;
  selectedDataset?: Dataset;
  isGlobalDatasetEditor?: boolean;
};

export const DashboardEditorFidoDataFetcher: FC<Props> = ({ selectedDataset, selectedView }) => {
  const dispatch = useDispatch();

  const {
    parentSchemas,
    dashboardId,
    variables,
    computedViews,
    datasetData,
    datasetsByFidoId,
    selectedDrilldownColumn,
  } = useSelector(
    (state: ReduxState) => ({
      dashboardId: RD.isSuccess(state.dashboard.currentDashboard)
        ? state.dashboard.currentDashboard.data.id
        : null,
      computedViews: RD.isSuccess(state.fido.computedViews) ? state.fido.computedViews.data : [],
      parentSchemas: getParentSchemasList(state),
      datasetData: state.dashboardEditConfig.datasetData,
      datasetsByFidoId: getDatasetsByFidoId(state),
      variables: state.dashboardData.variables ?? {},
      selectedDrilldownColumn: state.dashboardEditConfig.selectedDrilldownColumn,
    }),
    shallowEqual,
  );

  const selectedDatasetId = selectedDataset?.id;

  const parentSchemaId = useMemo(
    () => (selectedView ? getEmbeddoSchemaIdFromView(parentSchemas, selectedView) : -1),
    [parentSchemas, selectedView],
  );

  const data = selectedDatasetId ? datasetData[selectedDatasetId] : null;

  const getUnderlyingData = useCallback(
    (
      query: string,
      pageNumber?: number,
      onSuccess?: (data: QueryExecutionResponse | FetchDashboardDatasetPreviewData) => void,
    ) => {
      if (!selectedDatasetId) return;

      dispatch(
        fetchEditorDatasetPreviewThunk(
          {
            selectedDatasetId,
            query,
            parentSchemaId: parentSchemaId,
          },
          pageNumber,
          onSuccess,
        ),
      );
    },
    [selectedDatasetId, parentSchemaId, dispatch],
  );

  const onSaveQuery = useCallback(
    (query: string) => {
      if (!selectedDatasetId) return;

      getUnderlyingData(query, undefined, (data) => {
        if (!selectedView) return;

        let schema: DatasetSchema;

        if ('meta' in data) {
          dispatch(
            saveComputedView({
              ...selectedView,
              query: query,
              columnDefinitions: data.meta.schema.propertySchema,
            }),
          );
          schema = getEmbeddoSchemaFromFidoSchema(data.meta.schema.propertySchema);
        } else {
          dispatch(
            saveComputedView({
              ...selectedView,
              query: query,
              columnDefinitions: getFidoSchemaFromEmbeddoSchema(data.dataset_preview.schema),
            }),
          );
          schema = data.dataset_preview.schema;
        }

        dispatch(fetchSavedDatasetThunk(selectedDatasetId));

        trackEvent(EVENTS.SAVED_QUERY, {
          dataset_id: selectedDatasetId,
          dataset_query: query,
        });

        showDuplicateColumnNameToast(getDuplicateColumnsFromSchema(schema));
      });
    },
    [dispatch, getUnderlyingData, selectedDatasetId, selectedView],
  );

  const onSaveQueryDraft = (query: string | undefined) => {
    if (!selectedDatasetId) return;

    dispatch(saveDraftComputedViewQuery({ queryDraft: query, viewId: selectedDatasetId }));
  };

  const onSelectSchema = (schemaId: number | string) => {
    const schema = parentSchemas.find((s) => s.id === schemaId);
    if (!selectedDatasetId || !schema) return;

    selectedView &&
      dispatch(
        saveComputedView({
          ...selectedView,
          namespaceId: schema.fido_id?.toString() ?? '',
        }),
      );
  };

  const onSelectDrilldownColumn = useCallback(
    (columnInfo: BaseCol | undefined) => {
      dispatch(setSelectedDrilldownColumn(columnInfo));
    },
    [dispatch],
  );

  const onUpdateDrilldownConfig = useCallback(
    (datasetId: string, updates: Partial<DrilldownConfig>) => {
      dispatch(updateDatasetDrilldownConfig({ id: datasetId, updates }));
    },
    [dispatch],
  );

  const onUpdateDrilldownColumnConfig = useCallback(
    (datasetId: string, columnName: string, newColumnConfigs: DrilldownColConfigOptions) => {
      dispatch(
        updateDatasetDrilldownColConfig({
          datasetId,
          colName: columnName,
          ...newColumnConfigs,
        }),
      );
    },
    [dispatch],
  );

  const onUpdateDrilldownColumnConfigs = useCallback(
    (datasetId: string, newColumnConfigs: Record<string, DrilldownColConfig>) => {
      dispatch(
        updateDatasetDrilldownColConfigs({
          datasetId,
          newConfigs: newColumnConfigs,
        }),
      );
    },
    [dispatch],
  );

  const savedSchema = getEmbeddoSchemaFromFidoSchema(selectedView?.columnDefinitions ?? []);

  const datasetConfigs = useMemo(
    () =>
      computedViews
        ? Object.fromEntries(
            // @ts-ignore
            computedViews
              .map((view) => {
                const dataset = datasetsByFidoId[view.id];

                if (!dataset) return null;
                return [dataset.id, getDatasetConfigFromView(view, dataset)];
              })
              .filter((e) => e != null),
          )
        : {},
    [computedViews, datasetsByFidoId],
  );

  if (!dashboardId) {
    return null;
  }

  return (
    <div className={styles.datasetEditorContainerStyle}>
      <DatasetSelectTopBar
        datasetConfigs={datasetConfigs}
        selectedDatasetId={selectedDatasetId ?? null}
        setSelectedDatasetId={(datasetId: string | null) =>
          switchSelectedDataset(datasetId, selectedView?.name, dispatch)
        }
      />
      <DatasetEditor
        renderFormattingTab
        // @ts-ignore
        activeDatasetConfig={
          selectedView && selectedDataset
            ? getDatasetConfigFromView(selectedView, selectedDataset)
            : null
        }
        activeDatasetData={data ?? null}
        activeDatasetSavedSchema={savedSchema}
        activeDatasetSchema={data?.schema ?? savedSchema}
        activeQuery={selectedDataset?.queryDraft ?? selectedDataset?.query ?? ''}
        createNewParam={() => createNewDashboardParam(dashboardId)}
        fetchData={getUnderlyingData}
        onSave={onSaveQuery}
        onSaveDraft={onSaveQueryDraft}
        onSelectDrilldownColumn={onSelectDrilldownColumn}
        onSelectSchema={onSelectSchema}
        onUpdateColumnConfig={onUpdateDrilldownColumnConfig}
        onUpdateColumnConfigs={onUpdateDrilldownColumnConfigs}
        onUpdateDrilldownConfig={onUpdateDrilldownConfig}
        parentSchemas={parentSchemas}
        selectedDatasetId={selectedDatasetId ?? null}
        selectedDrilldownColumn={selectedDrilldownColumn}
        variables={variables}
      />
    </div>
  );
};
