import cx from 'classnames';
import produce from 'immer';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { saveShareLinkTitle } from 'actions/dashboardActions';
import {
  DEFAULT_POPOVER_SUBTITLE_NO_PASS,
  DEFAULT_POPOVER_SUBTITLE_PASS,
  DEFAULT_POPOVER_TITLE,
} from 'components/ExportOptions/WebShareOption';
import { SettingHeader } from 'components/SettingHeader';
import { Input, Switch } from 'components/ds';
import { AllStates } from 'reducers/rootReducer';
import { selectEnableScreenshotExports } from 'reducers/selectors';
import { ExportElemConfig } from 'types/dashboardTypes';

import * as styles from './index.css';

type Props = {
  config: ExportElemConfig;
  dashboardId: number;
  dashboardShareLinkTitle: string | null;
  updateConfig: (newConfig: ExportElemConfig) => void;
};

export const ExportElementConfigPanel: FC<Props> = ({
  config,
  dashboardId,
  dashboardShareLinkTitle,
  updateConfig,
}) => {
  const enableScreenshotExports = useSelector((state: AllStates) =>
    selectEnableScreenshotExports(state),
  );
  const dispatch = useDispatch();

  const updateExportConfig = (update: (config: ExportElemConfig) => void) => {
    updateConfig(
      produce(config, (draft) => {
        update(draft);
      }),
    );
  };

  const allDisabled =
    config.disableImageDownload && config.disablePdfDownload && config.disableShareLink;

  return (
    <div>
      <SettingHeader className={styles.settingHeader} name="General" />
      <div className={styles.section}>
        <Input
          showInputButton
          className={styles.configInput}
          defaultValue={config.label}
          label="Button Label"
          onSubmit={(newValue) => updateExportConfig((draft) => (draft.label = newValue))}
        />
        <Input
          showInputButton
          className={styles.configInput}
          defaultValue={config.popoverTitle || DEFAULT_POPOVER_TITLE}
          label="Popover Title"
          onSubmit={(newValue) => updateExportConfig((draft) => (draft.popoverTitle = newValue))}
        />
        <Input
          showInputButton
          className={styles.configInput}
          defaultValue={
            config.popoverSubtitle ||
            (config.passwordEnabled
              ? DEFAULT_POPOVER_SUBTITLE_PASS
              : DEFAULT_POPOVER_SUBTITLE_NO_PASS)
          }
          label="Popover Subtitle"
          onSubmit={(newValue) => updateExportConfig((draft) => (draft.popoverSubtitle = newValue))}
        />
      </div>
      <SettingHeader
        className={styles.settingHeader}
        name="Share Link"
        switchProps={{
          isOn: !config.disableShareLink,
          onChange: () =>
            updateExportConfig((draft) => (draft.disableShareLink = !draft.disableShareLink)),
        }}
      />
      {!config.disableShareLink ? (
        <div className={styles.section}>
          <Input
            showInputButton
            className={styles.configInput}
            defaultValue={dashboardShareLinkTitle ?? ''}
            label="Share Page Title"
            onSubmit={(newValue) =>
              dispatch(
                saveShareLinkTitle({ id: dashboardId, postData: { share_link_title: newValue } }),
              )
            }
          />
          <div className={styles.infoCard}>
            <div className={styles.infoCardText}>
              This page title will apply to any share page linked from any share button on this
              dashboard.
            </div>
          </div>
          <Switch
            className={styles.configInput}
            label="Enable Password Protection"
            onChange={() =>
              updateExportConfig((draft) => {
                draft.passwordEnabled = !draft.passwordEnabled;
                if (!draft.passwordEnabled) draft.usernameEnabled = false;
              })
            }
            switchOn={config?.passwordEnabled}
          />
          {config.passwordEnabled ? (
            <Switch
              className={styles.configInput}
              label="Enable Username Protection"
              onChange={() =>
                updateExportConfig((draft) => (draft.usernameEnabled = !draft.usernameEnabled))
              }
              switchOn={config.usernameEnabled}
            />
          ) : null}
        </div>
      ) : null}
      {enableScreenshotExports ? (
        <>
          <SettingHeader className={styles.settingHeader} name="Other Share Methods" />
          <div className={styles.section}>
            <Switch
              className={styles.configInput}
              label="Enable Image Download"
              onChange={() =>
                updateExportConfig(
                  (draft) => (draft.disableImageDownload = !draft.disableImageDownload),
                )
              }
              switchOn={!config.disableImageDownload}
            />
            <Switch
              className={styles.configInput}
              label="Enable PDF Export"
              onChange={() =>
                updateExportConfig(
                  (draft) => (draft.disablePdfDownload = !draft.disablePdfDownload),
                )
              }
              switchOn={!config.disablePdfDownload}
            />
            <Switch
              className={styles.configInput}
              label="Enable Email Export"
              onChange={() =>
                updateExportConfig((draft) => (draft.disableEmail = !draft.disableEmail))
              }
              switchOn={!config.disableEmail}
            />
            {/* Only other share methods are Image or PDF so there is no download file to rename if no enableScreenshotExports*/}
            <Input
              showInputButton
              className={styles.configInput}
              defaultValue={config.downloadDashboardFileName || ''}
              label="Download file name"
              onSubmit={(newValue) =>
                updateExportConfig((draft) => (draft.downloadDashboardFileName = newValue))
              }
            />
          </div>
        </>
      ) : null}
      {allDisabled ? (
        <div className={cx(styles.infoCard, styles.infoCardGreyBackground)}>
          <div className={styles.infoCardWarn}>
            Warning: All export types are disabled. Please enable at least one.
          </div>
        </div>
      ) : null}
    </div>
  );
};
