import type {
  ScheduledExportStatus as APIStatus,
  DashboardImageAssetResponseDto,
  EnvironmentTagDto,
  ExternalLinkSourceDto,
  InlineHtmlDto,
  OneTimeScheduleDto,
  RecurringScheduleDto,
  ResourceLinkSourceDto,
  ScheduledEmailExportDto,
} from '@explo/embeddo-api';
import { faker } from '@faker-js/faker';
// TODO: Colocate other page with this feature
import { EXPORT_STATUS, EXPORT_TYPE, SCHEDULE_TYPE } from 'features/dataShare/ExportList/constants';
import { createRecipientFilter } from 'features/dataShare/recipients/recipientsFilterShims';
import { FlattenedRecipients } from 'features/dataShare/recipients/types';

const WEEKLY_MONDAY_9AM = '0 9 * * 1'; // Every Monday at 9:00 AM
const MONTHLY_1ST_NOON = '0 12 1 * *'; // 1st of every month at 12:00 PM
const DAILY_8AM = '0 8 * * *'; // Every day at 8:00 AM
const ONE_TIME_MARCH_25_10AM = '0 10 25 3 *'; // March 25th at 10:00 AM

const generateSchedule = (): RecurringScheduleDto | OneTimeScheduleDto | null => {
  const scheduleType = faker.helpers.arrayElement([
    SCHEDULE_TYPE.RECURRING,
    SCHEDULE_TYPE.ONE_TIME,
    null,
  ]);
  if (!scheduleType) return null;

  const cronExpression = faker.helpers.arrayElement([
    WEEKLY_MONDAY_9AM,
    MONTHLY_1ST_NOON,
    DAILY_8AM,
    ONE_TIME_MARCH_25_10AM,
  ]);

  return {
    '@type': scheduleType,
    timezone: 'UTC',
    cron: cronExpression,
  };
};

const generateExternalLink = (): ExternalLinkSourceDto => ({
  '@type': 'external',
  link: faker.internet.url(),
});

const generateResourceLink = (): ResourceLinkSourceDto => ({
  '@type': 'resource',
  shareLinkId: faker.number.int({ min: 1, max: 1000 }),
});

const generateDashboardImageAsset = (): DashboardImageAssetResponseDto => {
  const link = faker.helpers.arrayElement([generateExternalLink(), generateResourceLink(), null]);

  return {
    '@type': 'dashboard-image',
    dashboardId: faker.number.int({ min: 1, max: 1000 }),
    dashboardName: faker.helpers.arrayElement([
      faker.company.name(),
      'Sales Overview',
      'Marketing Dashboard',
      'Customer Insights',
    ]),
    link,
  };
};

const generateInlineHtml = (): InlineHtmlDto => ({
  '@type': 'html',
  html: `
    <h1>${faker.company.catchPhrase()}</h1>
    <p>${faker.lorem.paragraph()}</p>
    <ul>
      ${Array.from({ length: 3 }, () => `<li>${faker.lorem.sentence()}</li>`).join('\n')}
    </ul>
  `,
});

const generateBodyAssets = (): (DashboardImageAssetResponseDto | InlineHtmlDto)[] => {
  const numAssets = faker.number.int({ min: 0, max: 3 });
  const assets: (DashboardImageAssetResponseDto | InlineHtmlDto)[] = [];

  for (let i = 0; i < numAssets; i++) {
    if (faker.datatype.boolean()) {
      assets.push(generateDashboardImageAsset());
    } else {
      assets.push(generateInlineHtml());
    }
  }

  return assets;
};

const generateEnvironmentTag = (): EnvironmentTagDto | null => {
  return faker.helpers.arrayElement([
    {
      id: 1,
      name: 'Production',
      colorHex: '#34D399',
    },
    {
      id: 2,
      name: 'Staging',
      colorHex: '#FBBF24',
    },
    null,
  ]);
};

const generateRecipientFilter = () => {
  const hierarchyIds: number[] = [];
  const groupTagIds: number[] = [];
  const customerIds: number[] = [];

  const includeHierarchies = faker.datatype.boolean();
  const includeGroupTags = faker.datatype.boolean();
  const includeCustomers = faker.datatype.boolean();

  if (!includeHierarchies && !includeGroupTags && !includeCustomers) {
    return null;
  }

  if (includeHierarchies) {
    hierarchyIds.push(
      ...faker.helpers.uniqueArray(
        () => faker.number.int({ min: 1, max: 3 }),
        faker.number.int({ min: 1, max: 3 }),
      ),
    );
  }

  if (includeGroupTags) {
    groupTagIds.push(
      ...faker.helpers.uniqueArray(
        () => faker.number.int({ min: 1, max: 3 }),
        faker.number.int({ min: 1, max: 3 }),
      ),
    );
  }

  if (includeCustomers) {
    customerIds.push(
      ...faker.helpers.uniqueArray(
        () => faker.number.int({ min: 1, max: 3 }),
        faker.number.int({ min: 1, max: 5 }),
      ),
    );
  }

  const flattenedRecipients: FlattenedRecipients = {
    hierarchyIds,
    groupTagIds,
    customerIds,
  };

  return createRecipientFilter(flattenedRecipients);
};

const generateExport = (): ScheduledEmailExportDto => {
  const status = faker.helpers.arrayElement([
    EXPORT_STATUS.ACTIVE,
    EXPORT_STATUS.PAUSED,
    EXPORT_STATUS.DRAFT,
  ]) as APIStatus;

  return {
    '@type': EXPORT_TYPE.EMAIL,
    id: faker.string.uuid(),
    name: `${faker.company.buzzAdjective().toUpperCase()} ${faker.animal.bird()}`,
    status,
    lastEditedAt: faker.date.past().toISOString(),
    recipients: {
      recipientsFilter: generateRecipientFilter(),
      totalCount: faker.number.int({ min: 1, max: 1000 }),
    },
    bodyAssets: generateBodyAssets(),
    schedule: status === EXPORT_STATUS.DRAFT ? null : generateSchedule(),
    subject: faker.lorem.sentence(),
    locale: faker.helpers.arrayElement(['en-US', 'fr-FR', 'es-ES']),
    envTag: generateEnvironmentTag(),
  };
};

const generateScheduledExports = (count: number): ScheduledEmailExportDto[] => {
  return Array.from({ length: count }, generateExport);
};

const MOCKED_EXPORTS = generateScheduledExports(100);

export const getExportsList: () => Promise<ScheduledEmailExportDto[]> = () => {
  // TODO: Replace with API call
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(MOCKED_EXPORTS);
    }, 100);
  });
};
