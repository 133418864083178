import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as RD from 'remotedata';

import { IconName, SelectItems } from 'components/ds';
import { SelectorSection } from '../SelectorSection';

import { fetchSelectorCustomers } from 'actions/customerActions';
import { CUSTOMER_SELECTOR_QUERY_PARAMS } from 'constants/customerConstants';
import { ReduxState } from 'reducers/rootReducer';
import { SelectProps } from '../types';

export const CustomerSelect: FC<SelectProps> = ({ selected, onUpdate }) => {
  const dispatch = useDispatch();
  const [newCustomerId, setNewCustomerId] = useState<number | null>(null);
  // Need to store the selected customer so when we filter if the results don't include the selected value we can still show it
  const [selectedCustomer, setSelectedCustomer] = useState<{
    label?: string;
    value: string;
  }>();

  const { selectorCustomers, selectorCustomersStatus } = useSelector((state: ReduxState) => ({
    selectorCustomers: state.customers.selectorCustomers,
    selectorCustomersStatus: state.customers.selectorCustomersStatus,
  }));

  useEffect(() => {
    if (RD.isIdle(selectorCustomersStatus)) {
      dispatch(fetchSelectorCustomers({ queryParams: CUSTOMER_SELECTOR_QUERY_PARAMS }));
    }
  }, [dispatch, selectorCustomersStatus]);

  const customerIds = useMemo(() => selected.customerIds, [selected.customerIds]);

  const customerOptions: SelectItems<string> = useMemo(() => {
    return selectorCustomers.reduce<SelectItems<string>>((acc, customer) => {
      if (!customerIds.includes(customer.id)) {
        acc.push({
          value: String(customer.id),
          label: customer.name || String(customer.id),
        });
      }
      return acc;
    }, []);
  }, [customerIds, selectorCustomers]);

  const onFilter = useCallback(
    (searchString: string) =>
      dispatch(
        fetchSelectorCustomers({
          queryParams: {
            ...CUSTOMER_SELECTOR_QUERY_PARAMS,
            search_string: searchString || undefined,
          },
        }),
      ),
    [dispatch],
  );

  const filterProps = useMemo(
    () => ({
      placeholder: 'Filter for more customers',
      selectedLabel: selectedCustomer?.label || 'Select customer',
      onFilter,
      isLoading: RD.isLoading(selectorCustomersStatus),
    }),
    [onFilter, selectedCustomer, selectorCustomersStatus],
  );

  const handleAdd = useCallback(() => {
    if (!newCustomerId) return;
    onUpdate({
      ...selected,
      customerIds: [...selected.customerIds, newCustomerId],
    });
    setNewCustomerId(null);
  }, [newCustomerId, onUpdate, selected]);

  const handleSelect = useCallback(
    (id: string) => {
      const customer = selectorCustomers.find((c) => String(c.id) === id);
      setNewCustomerId(parseInt(id));
      setSelectedCustomer({
        value: id,
        label: customer?.name || String(id),
      });
    },
    [selectorCustomers],
  );

  const tags = selected.customerIds.map((id) => {
    const customer = selectorCustomers.find((c) => c.id === id);
    return {
      key: String(id),
      label: customer?.name ?? `Customer ${id}`,
      onClose: () => {
        onUpdate({
          ...selected,
          customerIds: selected.customerIds.filter((cId) => cId !== id),
        });
      },
      leftIcon: 'user' as IconName,
    };
  });

  return (
    <SelectorSection
      addDisabled={!newCustomerId}
      filterProps={filterProps}
      label="Individual customers"
      onAdd={handleAdd}
      onSelect={handleSelect}
      placeholder="Select customer"
      selectedValue={selectedCustomer?.value}
      tags={tags}
      values={customerOptions}
    />
  );
};
