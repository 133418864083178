import { ACTION } from 'actions/types';
import {
  ViewResourceUsageParams,
  ListViewResourceUsagesResponse,
  ResourcesService,
} from '@explo/embeddo-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReduxState } from 'reducers/rootReducer';
import { makeEmbeddoThunkRequest } from 'utils/thunkUtils';

export const fetchDataLibraryViewUsages = createAsyncThunk<
  ListViewResourceUsagesResponse,
  {
    request: ViewResourceUsageParams;
  },
  { state: ReduxState }
>(ACTION.FETCH_GLOBAL_DATASET_VIEW_USAGES, async ({ request }) => {
  return makeEmbeddoThunkRequest(
    () => ResourcesService.getViewResourceUsages(request),
    'Failed to fetch view resource usages',
  );
});
