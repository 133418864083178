import cx from 'classnames';
import { FC, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  DatasetSchema,
  DATE_TYPES,
  getTimezoneAwareUnix,
  OPERATION_TYPES,
  SortAxis,
  SortOption,
} from '@explo/data';

import { sprinkles, Tooltip } from 'components/ds';
import { ChartTooltip } from 'components/embed';
import { GlobalStyleConfig } from 'globalStyles/types';
import { NeedsConfigurationPanel } from 'pages/dashboardPage/needsConfigurationPanel';
import { setChartMenu } from 'reducers/dashboardLayoutReducer';
import { DashboardStates } from 'reducers/rootReducer';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { keyBy, orderBy, uniq } from 'utils/standard';

import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import {
  ManualSortCategory,
  V2TwoDimensionChartInstructions,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { EMPTY_STRING_PLACEHOLDER } from 'utils/sankeyChartUtils';
import { DrilldownChartMenu } from './shared/DrilldownChartMenu';
import * as styles from './styles/barFunnelStyles.css';
import {
  areRequiredVariablesSetTwoDimViz,
  formatLabel,
  formatValue,
  getAxisNumericalValue,
  getColorPalette,
  isTwoDimVizInstructionsReadyToDisplay,
} from './utils';
import {
  getValueFormat,
  getYAxisFormatById,
} from 'pages/dashboardPage/charts/utils/multiYAxisUtils';

type Props = {
  loading?: boolean;
  previewData: Record<string, string | number>[];
  globalStyleConfig: GlobalStyleConfig;
  instructions: V2TwoDimensionChartInstructions | undefined;
  dataPanelId: string;
  schema: DatasetSchema;
  variables: DashboardVariableMap;
  generalFormatOptions: VisualizeOperationGeneralFormatOptions | undefined;
};

export const BarFunnel: FC<Props> = ({
  loading,
  previewData,
  globalStyleConfig,
  instructions,
  dataPanelId,
  schema,
  variables,
  generalFormatOptions,
}) => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const chartMenuInfo = useSelector((state: DashboardStates) => state.dashboardLayout.chartMenu);

  const { xAxisFormat, chartSpecificFormat, colorFormat, aggColumns } = instructions ?? {};

  const manualStageById = useMemo(
    () =>
      xAxisFormat?.sortAxis === SortAxis.MANUAL
        ? keyBy(xAxisFormat?.sortManualCategoriesOrder || [], 'category')
        : {},
    [xAxisFormat],
  );

  const requiredVarsNotSet = !areRequiredVariablesSetTwoDimViz(variables, instructions);
  const instructionsReadyToDisplay = isTwoDimVizInstructionsReadyToDisplay(
    instructions,
    OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2,
  );
  const chartNotReadyToDisplay = loading || requiredVarsNotSet || !instructionsReadyToDisplay;

  const { categories, valuesByCategory, maxValue } = useMemo(() => {
    if (chartNotReadyToDisplay) return {};

    const { categories, rawValuesByCategory, sortValuesByCategory } = extractData(
      schema,
      previewData,
      instructions,
    );
    const sortedCategories = sortCategories(categories, sortValuesByCategory, instructions);
    const valuesByCategory = getProcessedValuesByCategory(
      rawValuesByCategory,
      sortedCategories,
      instructions,
    );

    const maxValue = getMaxValue(valuesByCategory);

    return {
      categories: sortedCategories,
      valuesByCategory,
      maxValue,
    };
  }, [chartNotReadyToDisplay, schema, previewData, instructions]);

  const colors = useMemo(
    () => getColorPalette(globalStyleConfig, colorFormat),
    [globalStyleConfig, colorFormat],
  );

  const cornerRadius = xAxisFormat?.barCornerRadius || 0;
  const barFunnelConfig = chartSpecificFormat?.barFunnelChart;
  const enableDataDrilldown = generalFormatOptions?.enableRawDataDrilldown;

  if (chartNotReadyToDisplay) {
    return (
      <NeedsConfigurationPanel
        fullHeight
        instructionsNeedConfiguration={!instructionsReadyToDisplay}
        loading={loading}
        requiredVarsNotSet={requiredVarsNotSet}
      />
    );
  }

  let previousValue = 0;

  return (
    <div className={styles.funnelChartContainer} ref={ref}>
      <div className={styles.axisContainer}>
        <div className={styles.axisLabel}>75%</div>
        <div className={styles.axisLabel}>50%</div>
        <div className={styles.axisLabel}>25%</div>
        <div className={styles.axisLabel}>0%</div>
        <div className={styles.stageInfo} />
      </div>
      <div className={styles.stagesContainer}>
        {categories?.map((category) => {
          const categoryFormatted = getFormattedCategory(category, manualStageById, instructions);
          const value = valuesByCategory[category] || 0;
          const pctHeight = (value / maxValue) * 100;

          const diffFromPrevStage = Math.max(previousValue - value, 0);
          const pctDiff = (diffFromPrevStage / maxValue) * 100;

          const convertedPctFormatted = Math.round((value / previousValue) * 1000) / 10;
          const lostPctFormatted = Math.round((1 - value / previousValue) * 1000) / 10;

          previousValue = value;

          const noDiffBar = value === maxValue || diffFromPrevStage === 0;
          const totalHeightPct = pctDiff + pctHeight;

          const { valueFormatId, decimalPlaces } = getValueFormat(
            getYAxisFormatById(instructions?.yAxisFormats, (aggColumns ?? [])[0]?.yAxisFormatId) ||
              instructions?.yAxisFormats?.[0], // fallback to the globally set yAxisFormat
          );

          let text = formatValue({
            value,
            decimalPlaces,
            formatId: valueFormatId,
            hasCommas: true,
          });

          if (!barFunnelConfig?.hideLabelParentheses) {
            text = `(${text})`;
          }

          return (
            <div
              className={styles.funnelStageContainer}
              key={`${dataPanelId}-${category}`}
              style={{ minWidth: barFunnelConfig?.minBarWidth || 50 }}>
              <div className={styles.stageBarWrapper}>
                <div
                  className={styles.stageChartContainer}
                  style={{ height: `${pctDiff + pctHeight}%`, borderRadius: cornerRadius }}>
                  <ChartTooltip
                    globalStyleConfig={globalStyleConfig}
                    header={categoryFormatted}
                    points={[
                      {
                        color: colors[0] + '33',
                        value: noDiffBar ? 1 : lostPctFormatted / 100,
                        name: barFunnelConfig?.lostLabel || 'Lost',
                        format: {
                          decimalPlaces: 2,
                          formatId: V2_NUMBER_FORMATS.PERCENT.id,
                        },
                      },
                      {
                        color: colors[0] + '33',
                        value: diffFromPrevStage,
                        name: barFunnelConfig?.entityName || 'Uniques',
                        format: {
                          decimalPlaces: decimalPlaces,
                          formatId: valueFormatId,
                        },
                      },
                    ]}>
                    <div
                      className={styles.funnelMainBar}
                      style={{
                        height: `${(pctDiff / totalHeightPct) * 100}%`,
                        backgroundColor: colors[0],
                        opacity: '0.2',
                        borderTopLeftRadius: cornerRadius,
                        borderTopRightRadius: cornerRadius,
                      }}
                    />
                  </ChartTooltip>
                  <ChartTooltip
                    globalStyleConfig={globalStyleConfig}
                    header={categoryFormatted}
                    points={[
                      {
                        color: colors[0],
                        value: noDiffBar ? 1 : convertedPctFormatted / 100,
                        name: barFunnelConfig?.wonLabel || 'Converted',
                        format: {
                          decimalPlaces: 2,
                          formatId: V2_NUMBER_FORMATS.PERCENT.id,
                        },
                      },
                      {
                        color: colors[0],
                        value: value,
                        name: barFunnelConfig?.entityName || 'Uniques',
                        format: {
                          decimalPlaces: decimalPlaces,
                          formatId: valueFormatId,
                        },
                      },
                    ]}>
                    <div
                      className={cx(styles.funnelMainBar, {
                        [sprinkles({ cursor: 'pointer' })]: enableDataDrilldown,
                      })}
                      onClick={(e) => {
                        if (!enableDataDrilldown) return;
                        const bounds = ref.current?.getBoundingClientRect();
                        if (!bounds) return;
                        dispatch(
                          setChartMenu({
                            chartX: e.clientX - bounds.left,
                            chartY: e.clientY - bounds.top,
                            chartId: dataPanelId,
                            category,
                          }),
                        );
                      }}
                      style={{
                        height: `${(pctHeight / totalHeightPct) * 100}%`,
                        backgroundColor: colors[0],
                        borderBottomLeftRadius: cornerRadius,
                        borderBottomRightRadius: cornerRadius,
                        borderTopLeftRadius: noDiffBar ? cornerRadius : undefined,
                        borderTopRightRadius: noDiffBar ? cornerRadius : undefined,
                      }}
                    />
                  </ChartTooltip>
                </div>
              </div>
              <Tooltip side="bottom" text={`${categoryFormatted} ${text}`}>
                <div className={styles.stageInfo}>
                  <div className={styles.stageName}>{categoryFormatted}</div>
                  <div className={styles.stageValue}>{text}</div>
                </div>
              </Tooltip>
            </div>
          );
        })}
      </div>
      {chartMenuInfo?.chartId === dataPanelId && enableDataDrilldown ? (
        <DrilldownChartMenu
          canViewUnderlyingData
          chartMenuInfo={chartMenuInfo}
          // Dashboard id to name map is not used since bar funnels do not currently support
          // drilldowns.
          dashboardIdToNameMap={{}}
          dataPanelId={dataPanelId}
          drilldownEntryPoints={{}}
          instructions={instructions}
        />
      ) : null}
    </div>
  );
};

const extractData = (
  schema: DatasetSchema,
  previewData: Record<string, string | number>[],
  instructions?: V2TwoDimensionChartInstructions,
) => {
  const categories: Set<string> = new Set();
  const valuesByCategory: { [category: string]: number } = {};
  const sortValuesByCategory: { [category: string]: number } = {};

  const xAxisColName = getXAxisColName(schema);
  const aggColName = getAggColName(schema);
  const isColSortActive = isColumnSortActive(instructions);
  const sortColName = isColSortActive ? getSortColumnName(schema) : undefined;

  previewData.forEach((row) => {
    const category = String(row[xAxisColName]);
    categories.add(category);
    if (valuesByCategory[category] === undefined) valuesByCategory[category] = 0;
    if (sortValuesByCategory[category] === undefined) sortValuesByCategory[category] = 0;

    const value = getAxisNumericalValue(row[aggColName]);

    if (isNaN(value)) return;

    valuesByCategory[category] += value;

    if (isColSortActive && sortColName)
      sortValuesByCategory[category] += getAxisNumericalValue(row[sortColName]);
    else sortValuesByCategory[category] += value;
  });

  return {
    categories: Array.from(categories),
    rawValuesByCategory: valuesByCategory,
    sortValuesByCategory,
  };
};

const sortCategories = (
  categories: string[],
  sortValuesByCategory: { [category: string]: number },
  instructions?: V2TwoDimensionChartInstructions,
) => {
  const sortAxis = instructions?.xAxisFormat?.sortAxis;

  if (!sortAxis || sortAxis === SortAxis.NONE) return [...categories];

  if (sortAxis === SortAxis.MANUAL && instructions.xAxisFormat?.sortManualCategoriesOrder?.length) {
    return uniq(
      instructions.xAxisFormat.sortManualCategoriesOrder
        .map((order) => order.category)
        .concat(categories),
    );
  }

  return orderBy(
    categories,
    (category) => (sortAxis === SortAxis.CAT_AXIS ? category : sortValuesByCategory[category]),
    instructions?.xAxisFormat?.sortOption === SortOption.ASC ? 'asc' : 'desc',
  );
};

const getProcessedValuesByCategory = (
  valuesByCategory: { [category: string]: number },
  sortedCategories: string[],
  instructions?: V2TwoDimensionChartInstructions,
) => {
  if (instructions?.chartSpecificFormat?.barFunnelChart?.isNotCumulative) return valuesByCategory;
  const numCategories = sortedCategories.length;

  const cumulativeValuesByCategory: Record<string, number> = {};
  for (let i = numCategories; i--; i < 0) {
    const category = sortedCategories[i];
    const prevCategory = sortedCategories[i + 1];

    const currentCategoryValue = valuesByCategory[category] ?? 0;
    if (i === numCategories - 1) {
      cumulativeValuesByCategory[category] = currentCategoryValue;
    } else {
      cumulativeValuesByCategory[category] =
        currentCategoryValue + cumulativeValuesByCategory[prevCategory];
    }
  }

  return cumulativeValuesByCategory;
};

const getXAxisColName = (schema: DatasetSchema) => schema[0].name;

const getAggColName = (schema: DatasetSchema) =>
  isColorColUsed() ? schema[2].name : schema[1].name;

const getSortColumnName = (schema: DatasetSchema) =>
  isColorColUsed() ? schema[3].name : schema[2].name;

const isColorColUsed = (instructions?: V2TwoDimensionChartInstructions) => {
  return !!instructions?.colorColumnOptions?.length;
};

const isColumnSortActive = (instructions?: V2TwoDimensionChartInstructions) => {
  const xAxisFormat = instructions?.xAxisFormat;
  return (
    xAxisFormat?.sortAxis === SortAxis.COLUMN && xAxisFormat.sortOption && xAxisFormat.sortColumns
  );
};

const getMaxValue = (valuesByCategory: { [category: string]: number }) =>
  Math.max(...Object.values(valuesByCategory));

const getFormattedCategory = (
  category: string,
  manualStageById: Record<string, ManualSortCategory>,
  instructions?: V2TwoDimensionChartInstructions,
) => {
  let categoryValue: number | string = category;
  if (DATE_TYPES.has(instructions?.categoryColumn?.column.type || '')) {
    categoryValue = getTimezoneAwareUnix(category as string);
  } else {
    if (manualStageById[category]) categoryValue = manualStageById[category].displayName;
  }

  return (
    formatLabel(
      categoryValue,
      instructions?.categoryColumn?.column.type,
      instructions?.categoryColumn?.bucket?.id,
      instructions?.categoryColumn?.bucketSize,
      instructions?.xAxisFormat?.dateFormat,
      instructions?.xAxisFormat?.stringFormat,
    ) || EMPTY_STRING_PLACEHOLDER
  );
};
