import { Button, Input, Modal, sprinkles } from 'components/ds';
import { FC, useMemo, useState } from 'react';
import { validateBranchName } from './dataLibraryUtil';
import { useDispatch } from 'react-redux';
import { updateBranchThunk } from 'reducers/thunks/fidoThunks/branchThunks';
import { Branch } from '@explo-tech/fido-api';

interface Props {
  selectedBranch: Branch;
  allBranches: Branch[];
  onClose: () => void;
  isDestructive?: boolean;
}

export const RenameBranchModal: FC<Props> = ({ selectedBranch, allBranches, onClose }) => {
  const [newBranchName, setNewBranchName] = useState(selectedBranch.name);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const dispatch = useDispatch();

  const errorText = useMemo(() => {
    return validateBranchName(
      newBranchName,
      allBranches.map((b) => b.name),
    );
  }, [allBranches, newBranchName]);

  return (
    <Modal isOpen onClose={onClose} size="medium" title={'Edit branch name'}>
      <Input
        className={sprinkles({ marginBottom: 'sp2', marginX: 'sp3' })}
        errorText={errorMessage}
        label={'New branch name'}
        onChange={(value) => {
          setNewBranchName(value);
          setErrorMessage(
            validateBranchName(
              value,
              allBranches.map((b) => b.name),
            ),
          );
        }}
        value={newBranchName}
      />
      <div
        className={sprinkles({
          flexItems: 'alignCenter',
          flexDirection: 'row-reverse',
          marginX: 'sp3',
        })}>
        <Button
          disabled={!newBranchName || errorText !== undefined}
          onClick={() => {
            dispatch(
              updateBranchThunk({
                id: selectedBranch.id || '',
                requestBody: { branch: { name: newBranchName, headId: selectedBranch.headId } },
              }),
            );
            onClose();
          }}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
};
