import { ResponseData } from 'remotedata';

import {
  DatabaseUnsupportedOperations,
  DatasetRow,
  DatasetSchema,
  FilterOperationInstructions,
  OPERATION_TYPES,
  SortOrder,
} from '@explo/data';

import { QueryDebuggingInformation } from 'actions/responseTypes';
import {
  ChartColumnInfo,
  PivotOperationInstructions,
  VisualizeOperationGeneralFormatOptions,
  VisualizeOperationInstructions,
} from 'constants/types';
import { VersionedComputedViewReference } from 'types/dashboardVersionConfig';

interface BaseOperation {
  operation_type: OPERATION_TYPES;
}

export type FilterOperation = BaseOperation & {
  instructions: FilterOperationInstructions;
};

export type PivotOperation = BaseOperation & {
  instructions: PivotOperationInstructions;
};

export type VisualizeOperation = BaseOperation & {
  instructions: VisualizeOperationInstructions;
  generalFormatOptions?: VisualizeOperationGeneralFormatOptions;
};

export interface SortInfo_DEPRECATED {
  column_name: string;
  order: SortOrder;
  // see SortInfo for a comment
  display_column_name?: string;
}

export interface AdHocOperationInstructions {
  currentPage?: number;
  sortInfo?: SortInfo_DEPRECATED | SortInfo_DEPRECATED[];
  filterInfo?: FilterOperationInstructions;
}

export interface DrilldownEntryPointInfo {
  sourceChartColumns: ChartColumnInfo[];
  sourceDashboardId: number;
  destinationDashboardId: number;
  customDrilldownMenuText?: string;
}

export interface DataPanelTemplate {
  // BE Model
  id: string;
  provided_id: string; // provided id to reference data panel as variable
  container_id?: string;
  table_id: string | number;
  filter_op: FilterOperation;
  group_by_op: PivotOperation;
  visualize_op: VisualizeOperation;
  // A map of drilldown entry points. Each entry point contains all the columns that must be
  // selected for the entry point to be activated.
  drilldownEntryPoints: Record<string, DrilldownEntryPointInfo>;
  // A reference to the global dataset that this data panel draws its data off of. This supersedes
  // the table id if this is set.
  globalDatasetReference?: VersionedComputedViewReference;
}

export interface DataPanelData {
  // Set in FE
  loading: boolean;
  outstandingSecondaryDataRequests: number;
  adHocOperationInstructions?: AdHocOperationInstructions;
  secondaryData?: DatasetRow[];
  // Attached from BE
  error?: string;
  schema?: DatasetSchema;
  rows?: DatasetRow[];
  totalRowCount?: ResponseData<number>;
  columnTotals?: DatasetRow[];
  sourceType?: string;
  unsupportedOperations?: DatabaseUnsupportedOperations[];
  queryInformation?: QueryDebuggingInformation;
  // Used to protect against race conditions
  loadingRequestId?: string;
}

export const initDataPanelData = (loading = false): DataPanelData => ({
  loading,
  outstandingSecondaryDataRequests: 0,
});

export interface DataPanelResponse {
  // Attached in BE
  _schema?: DatasetSchema;
  _rows?: DatasetRow[];
  _error?: string;
  _total_row_count?: number;
  _source_type?: string;
  _unsupported_operations?: DatabaseUnsupportedOperations[];
}
