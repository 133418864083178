import { ScheduledEmailExportDto } from '@explo/embeddo-api';
import cx from 'classnames';
import { DatePickerInput } from 'components/DatePickerInput';
import { Button, Checkbox, Input, Intent, Select, sprinkles, Switch } from 'components/ds';
import { PageHeader } from 'components/PageHeader';
import { ViewingAsCustomerSelector } from 'components/ViewingAsCustomerSelector';
import {
  DayOfWeek,
  DAYS_OF_WEEK,
  EMAIL_FREQUENCY,
  EMAIL_FREQUENCY_SELECT_OPTIONS,
  EmailCadenceTime,
  EmailFrequency,
  WeekOfMonth,
  WEEKS_OF_MONTH,
  WEEKS_OF_MONTH_SELECT_OPTIONS,
} from 'constants/emailFrequency';
import { ROUTES } from 'constants/routes';
import { BROWSER_TIMEZONE, TIMEZONE_SELECT_OPTIONS } from 'constants/timezones';
import { FC, useState } from 'react';
import { parseTimeInput } from 'utils/time';
import validator from 'validator';
import { EmailExport } from '../EmailExport';
import * as styles from './index.css';

const ScheduleControls = () => {
  const [frequency, setFrequency] = useState<EmailFrequency>(EMAIL_FREQUENCY.DAILY);
  const [weekdays, setWeekdays] = useState<DayOfWeek[]>([]);
  const [weekOfMonth, setWeekOfMonth] = useState<WeekOfMonth>(WEEKS_OF_MONTH.FIRST);
  const [emailCadenceTime, setEmailCadenceTime] = useState<EmailCadenceTime>({});
  const [timeIsInvalid, setTimeIsInvalid] = useState(false);

  const handleTimeInputChange = (value: string) => {
    const parsedTime = parseTimeInput(value);
    if (parsedTime) {
      setEmailCadenceTime({ ...emailCadenceTime, ...parsedTime });
      setTimeIsInvalid(false);
    } else {
      setTimeIsInvalid(true);
    }
  };

  const formattedTimeValue =
    emailCadenceTime.hour && emailCadenceTime.minute !== undefined
      ? `${emailCadenceTime.hour}:${emailCadenceTime.minute < 10 ? '0' : ''}${emailCadenceTime.minute} ${emailCadenceTime.isPm ? 'PM' : 'AM'}`
      : '';

  return (
    <div className={sprinkles({ flexItems: 'column', gap: 'sp2' })}>
      <DatePickerInput
        label="Start date"
        onNewValueSelect={() => {
          // TODO: Implement
        }}
      />

      <Select
        label="Frequency"
        onChange={(frequency) => setFrequency(frequency as EmailFrequency)}
        placeholder="Select Frequency"
        selectedValue={frequency}
        values={EMAIL_FREQUENCY_SELECT_OPTIONS}
      />
      {frequency === EMAIL_FREQUENCY.WEEKLY || frequency === EMAIL_FREQUENCY.MONTHLY ? (
        <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>
          {frequency === EMAIL_FREQUENCY.MONTHLY ? (
            <Select
              className={sprinkles({ flex: 1 })}
              label="Repeat on the"
              onChange={(newWeek) => setWeekOfMonth(newWeek as WeekOfMonth)}
              selectedValue={weekOfMonth}
              values={WEEKS_OF_MONTH_SELECT_OPTIONS}
            />
          ) : (
            <p className={sprinkles({ body: 'b3', marginBottom: 'sp0' })}>Repeat on</p>
          )}

          {Object.values(DAYS_OF_WEEK).map((option) => (
            <Checkbox
              isChecked={weekdays.includes(option)}
              key={option}
              label={option}
              onChange={() => {
                if (weekdays.includes(option)) {
                  setWeekdays(weekdays.filter((day) => day !== option));
                } else {
                  setWeekdays([...weekdays, option]);
                }
              }}
            />
          ))}
        </div>
      ) : null}

      <div className={sprinkles({ display: 'flex', gap: 'sp1' })}>
        <div className={sprinkles({ width: 'half' })}>
          <Input
            intent={timeIsInvalid ? Intent.ERROR : undefined}
            label="Time"
            onChange={handleTimeInputChange}
            placeholder="HH:MM AM"
            value={formattedTimeValue}
          />
        </div>
        <Select
          className={sprinkles({ width: 'half' })}
          contentWidth="auto"
          label="Timezone"
          onChange={(timezone) => setEmailCadenceTime({ ...emailCadenceTime, timezone })}
          selectedValue={emailCadenceTime.timezone ?? BROWSER_TIMEZONE}
          values={TIMEZONE_SELECT_OPTIONS}
        />
      </div>
    </div>
  );
};

interface Props {
  exportItem?: ScheduledEmailExportDto;
}

export const EditExport: FC<Props> = ({ exportItem }) => {
  const [isCron, setIsCron] = useState(false);
  const [testEmail, setTestEmail] = useState('');
  const [cronExpression, setCronExpression] = useState('');

  return (
    <>
      <PageHeader
        breadcrumbs={[{ text: 'All Exports', link: ROUTES.SCHEDULED_EXPORTS_PAGE }]}
        primaryActionProps={{
          icon: 'paper-plane',
          text: 'Create Export',
          onClick: () => {
            // TODO: Implement
          },
        }}
        secondaryActionProps={{
          text: 'Save Draft',
          onClick: () => {
            // TODO: Implement
          },
        }}
        title={exportItem?.name ?? 'Create export instance: Email'}
      />
      <div className={styles.pageContent}>
        <div className={styles.settingsContainer}>
          <div className={styles.settingsSection}>
            <Switch label="Cron" onChange={() => setIsCron((c) => !c)} switchOn={isCron} />
          </div>
          <div className={cx(styles.settingsSection, sprinkles({ flex: 1 }))}>
            {isCron ? (
              <Input label="Cron expression" onChange={setCronExpression} value={cronExpression} />
            ) : (
              <ScheduleControls />
            )}
          </div>
          <div className={styles.settingsSection}>
            <Input
              defaultValue={testEmail}
              intent={testEmail && !validator.isEmail(testEmail) ? Intent.ERROR : undefined}
              label="Send test to"
              onSubmit={setTestEmail}
              placeholder="recipient@your_email.com"
            />
            <ViewingAsCustomerSelector fillWidth label="View as customer" />
            <Button
              fillWidth
              icon="paper-plane"
              onClick={() => {
                // TODO: Implement
              }}>
              Send test
            </Button>
          </div>
        </div>
        <div className={styles.previewContainer}>
          <EmailExport exportItem={exportItem} />
        </div>
      </div>
    </>
  );
};
