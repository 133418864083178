import { FC, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';

import { IconName } from 'components/ds/Icon';
import { CardItem } from 'components/PanelComponents/CardItem';
import { SettingHeader } from 'components/SettingHeader';
import { SELECT_ELEMENT_SET } from 'constants/dashboardConstants';
import { BlockDatasetDeletionModal } from 'pages/dashboardPage/dashboardDatasetEditor/modals/BlockDatasetDeletionModal';
import { getUsageText } from 'pages/dashboardPage/dashboardDatasetEditor/utils';
import { setHighlightedDataPanelIds } from 'reducers/dashboardInteractionsReducer';
import { getBranch, getDashboardEditConfigWithDrilldowns } from 'reducers/selectors';
import * as RD from 'remotedata';
import { DeleteResourceConfirmationModal } from 'shared/ExploResource/DeleteResourceConfirmationModal';
import { DASHBOARD_LAYOUT_CONFIG, DashboardElement, SelectElemConfig } from 'types/dashboardTypes';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { ReadAccessComputedView } from 'utils/fido/fidoShimmedTypes';
import { GlobalDatasetVersionComparisonModal } from 'components/DataLibrary/GlobalDatasetVersionComparisonModal';
import { SelectDatasetVersionModal } from 'components/DataLibrary/SelectDatasetVersionModal';
import { getResourceHistoryThunk } from 'reducers/thunks/fidoThunks/resourceThunks';
import {
  LATEST_VERSION_DELETED_TOOLTIP_TEXT,
  UPDATE_VERSION_BUTTON_TOOLTIP_TEXT,
} from 'components/DataLibrary/constants';
import { FetchOrigin } from 'reducers/thunks/dashboardDataThunks/types';
import {
  deleteGlobalDatasetReference,
  updateReferencedGlobalDatasetVersion,
} from 'actions/datasetActions';
import { ResourceType } from 'types/exploResource';

interface Props {
  globalDatasets: RD.ResponseData<Record<string, ReadAccessComputedView>>;
  latestGlobalDatasets: RD.ResponseData<Record<string, ReadAccessComputedView>>;
  query: string;
  chartSelectedDatasetId: string | number | undefined;

  onGlobalDatasetSelected: (globalDataset: ReadAccessComputedView) => void;
}

type VersionComparisonModalInfo = {
  currentGlobalDataset: ReadAccessComputedView;
  newestGlobalDataset: ReadAccessComputedView;
};

export const GlobalDatasetsSection: FC<Props> = ({
  globalDatasets,
  latestGlobalDatasets,
  query,
  chartSelectedDatasetId,
  onGlobalDatasetSelected,
}) => {
  const dispatch = useDispatch();

  const [globalDatasetToDelete, setGlobalDatasetToDelete] = useState<ReadAccessComputedView | null>(
    null,
  );
  const [versionComparisonModalInfo, setVersionComparisonModalInfo] = useState<
    VersionComparisonModalInfo | undefined
  >();
  const [globalDatasetToVersion, setGlobalDatasetToVersion] = useState<
    ReadAccessComputedView | undefined
  >();

  const {
    enableDataLibrary,
    dataPanels,
    editableSectionCharts,
    elements,
    branch,
    allVariableMappings,
    variablesMap,
    orderedComputedViewVersionsByDatasetId,
  } = useSelector((state: ReduxState) => {
    return {
      enableDataLibrary: state.currentUser.team?.feature_flags.enable_data_library,
      dataPanels: getDashboardEditConfigWithDrilldowns(state)?.data_panels ?? {},
      editableSectionCharts:
        getDashboardEditConfigWithDrilldowns(state)?.editable_section?.charts ?? {},
      elements: getDashboardEditConfigWithDrilldowns(state)?.elements ?? {},
      branch: getBranch(state),
      allVariableMappings: getDashboardEditConfigWithDrilldowns(state)?.variable_mappings ?? {},
      variablesMap: state.dashboardData.variables ?? {},
      orderedComputedViewVersionsByDatasetId: state.fido.orderedComputedViewVersionsByDatasetId,
    };
  }, shallowEqual);

  const globalDatasetDataPanelUsageMap: Map<string, Set<DataPanelTemplate>> = useMemo(
    () => createDatasetDataPanelUsageMap(dataPanels),
    [dataPanels],
  );

  const globalDatasetEditableSectionChartUsageMap: Map<string, Set<DataPanelTemplate>> = useMemo(
    () =>
      createDatasetDataPanelUsageMap(
        Object.entries(editableSectionCharts).reduce(
          (dataPanelMap, [chartId, editableSectionChart]) => {
            dataPanelMap[chartId] = editableSectionChart.data_panel;
            return dataPanelMap;
          },
          {} as Record<string, DataPanelTemplate>,
        ),
      ),
    [editableSectionCharts],
  );

  const deletionDatasetDependentDataPanelNames = useMemo(() => {
    return globalDatasetToDelete
      ? Array.from(globalDatasetDataPanelUsageMap.get(globalDatasetToDelete.id ?? '') ?? []).map(
          (dataPanel) => dataPanel.provided_id,
        )
      : [];
  }, [globalDatasetToDelete, globalDatasetDataPanelUsageMap]);

  const deletionEditableSectionDependentDataPanelNames = useMemo(() => {
    return globalDatasetToDelete
      ? Array.from(
          globalDatasetEditableSectionChartUsageMap.get(globalDatasetToDelete.id ?? '') ?? [],
        ).map((dataPanel) => dataPanel.provided_id)
      : [];
  }, [globalDatasetToDelete, globalDatasetEditableSectionChartUsageMap]);

  const globalDatasetToElementUsageMap = useMemo(() => {
    const datasetToElementUsageMap: Map<string, Set<DashboardElement>> = new Map();
    Object.values(elements).forEach((element) => {
      if (!SELECT_ELEMENT_SET.has(element.element_type)) {
        return;
      }

      const selectElementConfig = element.config as SelectElemConfig;
      const valuesConfig = selectElementConfig.valuesConfig;
      if (!valuesConfig.queryGlobalDatasetReference) {
        return;
      }

      const globalDatasetId = valuesConfig.queryGlobalDatasetReference.id;
      if (!datasetToElementUsageMap.has(globalDatasetId)) {
        datasetToElementUsageMap.set(globalDatasetId, new Set());
      }
      datasetToElementUsageMap.get(globalDatasetId)?.add(element);
    });

    return datasetToElementUsageMap;
  }, [elements]);

  const deletionDependentElementNames = useMemo(() => {
    return globalDatasetToDelete
      ? Array.from(globalDatasetToElementUsageMap.get(globalDatasetToDelete.id ?? '') ?? [])
          .filter((element) => element.elemLocation === DASHBOARD_LAYOUT_CONFIG.DASHBOARD_BODY)
          .map((element) => element.name)
      : [];
  }, [globalDatasetToDelete, globalDatasetToElementUsageMap]);

  const deletionDependentStickyHeaderElementNames = useMemo(() => {
    return globalDatasetToDelete
      ? Array.from(globalDatasetToElementUsageMap.get(globalDatasetToDelete.id ?? '') ?? [])
          .filter((element) => element.elemLocation === DASHBOARD_LAYOUT_CONFIG.HEADER)
          .map((element) => element.name)
      : [];
  }, [globalDatasetToDelete, globalDatasetToElementUsageMap]);

  const filteredGlobalDatasets = useMemo(() => {
    if (!RD.isSuccess(globalDatasets)) {
      return [];
    }

    const globalDatasetsData = globalDatasets.data;
    return Object.values(globalDatasetsData).filter((globalDataset) =>
      globalDataset.name.toLowerCase().includes(query.toLowerCase()),
    );
  }, [globalDatasets, query]);

  if (!enableDataLibrary) {
    return null;
  }

  return (
    <>
      <SettingHeader name="Global datasets" />
      {filteredGlobalDatasets.map((globalDataset) => {
        const datasetName = globalDataset.name;
        const alertProps = RD.isSuccess(latestGlobalDatasets)
          ? getAlertPropsForGlobalDataset(
              globalDataset,
              latestGlobalDatasets.data[globalDataset.id ?? ''],
              (currentGlobalDataset, newestGlobalDataset) => {
                setVersionComparisonModalInfo({
                  currentGlobalDataset,
                  newestGlobalDataset,
                });
              },
            )
          : undefined;
        const additionalIconButtons = [
          getVersioningPropsForGlobalDataset(globalDataset, (globalDataset) => {
            setGlobalDatasetToVersion(globalDataset);
            const orderedComputedViewVersionsForDataset =
              orderedComputedViewVersionsByDatasetId[globalDataset.id ?? ''];
            if (
              orderedComputedViewVersionsForDataset &&
              !RD.isIdle(orderedComputedViewVersionsForDataset)
            ) {
              return;
            }
            dispatch(
              getResourceHistoryThunk({
                branchId: branch?.id ?? '',
                resourceId: globalDataset.id ?? '', // double check difference between resource and id  ??
                page: 0, // dynamically set the page based on modal selection ..
                resourceType: ResourceType.DASHBOARD,
              }),
            );
          }),
        ];

        return (
          <CardItem
            additionalIconButtons={additionalIconButtons}
            alert={alertProps}
            isSelected={globalDataset.id === chartSelectedDatasetId}
            key={`global-dataset-navbar-item-${globalDataset.id}`}
            leftIcon="globe"
            name={datasetName}
            onClick={() => {
              onGlobalDatasetSelected(globalDataset);
            }}
            onDelete={() => {
              setGlobalDatasetToDelete(globalDataset);
            }}
            onMouseEntered={() => {
              const dataPanelsDependentOnGlobalDataset =
                globalDatasetDataPanelUsageMap.get(globalDataset.id ?? '') ?? new Set();
              const dependentDataPanelIds: Set<string> = new Set();
              dataPanelsDependentOnGlobalDataset.forEach((dataPanel) => {
                dependentDataPanelIds.add(dataPanel.id);
              });
              dispatch(setHighlightedDataPanelIds(dependentDataPanelIds));
            }}
            onMouseLeave={() => {
              dispatch(setHighlightedDataPanelIds(new Set()));
            }}
            secondaryText={getGlobalDatasetsUsageText(
              globalDatasetDataPanelUsageMap,
              globalDatasetEditableSectionChartUsageMap,
              globalDatasetToElementUsageMap,
              globalDataset.id ?? '',
            )}
          />
        );
      })}
      {globalDatasetToDelete ? (
        deletionDatasetDependentDataPanelNames.length === 0 ? (
          <DeleteResourceConfirmationModal
            confirmationButtonText="Delete reference"
            onClose={() => setGlobalDatasetToDelete(null)}
            onDelete={() => {
              dispatch(
                deleteGlobalDatasetReference({
                  datasetId: globalDatasetToDelete.id ?? '',
                  resourceType: ResourceType.DASHBOARD,
                }),
              );
            }}
            resourceName={globalDatasetToDelete.name}
            titleText={`Type DELETE to remove the reference to global dataset ${globalDatasetToDelete.name}`}
          />
        ) : (
          <BlockDatasetDeletionModal
            dataPanelProvidedIds={deletionDatasetDependentDataPanelNames}
            editableSectionDataPanelProvidedIds={deletionEditableSectionDependentDataPanelNames}
            elementNames={deletionDependentElementNames}
            onClose={() => setGlobalDatasetToDelete(null)}
            stickyHeaderElementNames={deletionDependentStickyHeaderElementNames}
          />
        )
      ) : null}
      {versionComparisonModalInfo ? (
        <GlobalDatasetVersionComparisonModal
          allVariableMappings={allVariableMappings}
          currentGlobalDataset={versionComparisonModalInfo.currentGlobalDataset}
          fetchOrigin={FetchOrigin.DASHBOARD}
          newestGlobalDataset={versionComparisonModalInfo.newestGlobalDataset}
          onClose={() => setVersionComparisonModalInfo(undefined)}
          onUpdateVersion={(globalDataset, _, newVariableMappings) => {
            dispatch(
              updateReferencedGlobalDatasetVersion({
                newDataset: globalDataset,
                newVariableMappings: newVariableMappings,
                resourceType: ResourceType.DASHBOARD,
              }),
            );

            setVersionComparisonModalInfo(undefined);
          }}
          variablesMap={variablesMap}
        />
      ) : null}
      {globalDatasetToVersion ? (
        <SelectDatasetVersionModal
          currentDataset={globalDatasetToVersion}
          onClose={() => setGlobalDatasetToVersion(undefined)}
          onSubmitVersionSelection={(newGlobalDataset) => {
            dispatch(
              updateReferencedGlobalDatasetVersion({
                newDataset: newGlobalDataset,
                resourceType: ResourceType.DASHBOARD,
                newVariableMappings: {},
              }),
            );
          }}
          orderedComputedViewVersions={
            orderedComputedViewVersionsByDatasetId[globalDatasetToVersion.id ?? '']
          }
        />
      ) : null}
    </>
  );
};

const getGlobalDatasetsUsageText = (
  datasetDataPanelUsage: Map<string, Set<DataPanelTemplate>>,
  datasetEditableSectionChartUsage: Map<string, Set<DataPanelTemplate>>,
  datasetElementUsage: Map<string, Set<DashboardElement>>,
  datasetId: string,
) => {
  const dataPanelsInUse = datasetDataPanelUsage.get(datasetId);
  const editableSectionChartsInUse = datasetEditableSectionChartUsage.get(datasetId);
  const totalNumChartsInUse =
    (dataPanelsInUse?.size ?? 0) + (editableSectionChartsInUse?.size ?? 0);
  const elementsInUse = datasetElementUsage.get(datasetId);

  return getUsageText(elementsInUse?.size ?? 0, totalNumChartsInUse);
};

const getAlertPropsForGlobalDataset = (
  globalDataset: ReadAccessComputedView,
  newestGlobalDataset: ReadAccessComputedView | undefined,
  onVersionMismatchButtonClicked: (
    currentGlobalDataset: ReadAccessComputedView,
    newestGlobalDataset: ReadAccessComputedView,
  ) => void,
) => {
  const isGlobalDatasetDeleted = !newestGlobalDataset;
  if (isGlobalDatasetDeleted) {
    return {
      tooltipText: LATEST_VERSION_DELETED_TOOLTIP_TEXT,
      icon: 'circle-exclamation' as IconName,
    };
  }

  const newestGlobalDatasetVersionId = newestGlobalDataset.versionId;
  const hasVersionMismatch = globalDataset.versionId !== newestGlobalDatasetVersionId;
  return hasVersionMismatch
    ? {
        tooltipText: UPDATE_VERSION_BUTTON_TOOLTIP_TEXT,
        icon: 'circle-up' as IconName,
        onClick: (event: React.MouseEvent) => {
          event.stopPropagation();
          onVersionMismatchButtonClicked(globalDataset, newestGlobalDataset);
        },
      }
    : undefined;
};

const getVersioningPropsForGlobalDataset = (
  globalDataset: ReadAccessComputedView,
  onVersionButtonClicked: (globalDataset: ReadAccessComputedView) => void,
) => {
  return {
    tooltipText: 'Select a different version',
    name: 'rectangle-history' as IconName,
    onClick: (event: React.MouseEvent) => {
      event.stopPropagation();
      onVersionButtonClicked(globalDataset);
    },
  };
};

const createDatasetDataPanelUsageMap = (
  dataPanels: Record<string, DataPanelTemplate>,
): Map<string, Set<DataPanelTemplate>> => {
  const datasetToDataPanelUsageMap = new Map();
  Object.values(dataPanels).forEach((dataPanel) => {
    if (!dataPanel.globalDatasetReference) {
      return;
    }

    const globalDatasetId = dataPanel.globalDatasetReference.id;
    if (!datasetToDataPanelUsageMap.has(globalDatasetId)) {
      datasetToDataPanelUsageMap.set(globalDatasetId, new Set());
    }
    datasetToDataPanelUsageMap.get(globalDatasetId)?.add(dataPanel);
  });

  return datasetToDataPanelUsageMap;
};
