import {
  FILTER_OPS_DATE_PICKER,
  FilterOperator,
  FilterValueDateType,
  FilterValueType,
  TWO_DIMENSIONAL_CHART_TYPES,
} from '@explo/data';

import { Dashboard } from 'actions/dashboardActions';
import {
  ChartColumnInfo,
  DrilldownColumnType,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { isEqual } from 'utils/standard';
import { DrilldownDatasetFilter, DrilldownSourceInfo } from 'reducers/drilldownsReducer';
import {
  DashboardHierarchy,
  DashboardVariable,
  DashboardVariableMap,
  EmbedDashboardHierarchy,
} from 'types/dashboardTypes';
import { DataPanelTemplate, DrilldownEntryPointInfo } from 'types/dataPanelTemplate';
import { getDataPanelDatasetId } from './exploResourceUtils';
import { formatDateField } from 'pages/dashboardPage/charts/utils';
import { DataPanel } from 'types/exploResource';
import { VALID_DRILLDOWN_OPERATION_TYPES } from 'pages/dashboardPage/LayersPanel/constants';
import { ChartColumnInfoUtils } from './chartColumnInfoUtils';

export const getParentDashboardNames = (
  currentSourceInfos: DrilldownSourceInfo[],
  dashboardHierarchy: DashboardHierarchy,
): Set<string> => {
  const parentDashboardNames = new Set<string>();
  currentSourceInfos.forEach((sourceInfo) => {
    const dashboard = dashboardHierarchy.dashboards[sourceInfo.sourceDashboardId];
    if (!dashboard) {
      return;
    }
    parentDashboardNames.add(dashboard.name);
  });
  return parentDashboardNames;
};

export const retrieveDrilldownVariablesWithParentDashboardNamesMap = (
  variables: DashboardVariableMap,
  parentDashboardNameToIdMap: Map<string, number>,
  additionalVariableFilterFn: (
    sourceDashboardId: number,
    sourceDataPanelProvidedId: string,
    drilldownColumnType: DrilldownColumnType,
  ) => boolean,
): { key: string; value: DashboardVariable }[] => {
  const drilldownVariables: { key: string; value: DashboardVariable }[] = [];
  const parentDashboardNames: Set<string> = new Set(parentDashboardNameToIdMap.keys());
  Object.keys(variables).forEach((variableId: string) => {
    const variableIdSplitByDot = variableId.split('.');
    if (variableIdSplitByDot.length !== 3 && variableIdSplitByDot.length !== 4) {
      // The expected format for drilldown variables is
      // "dashboardName.dataPanelProvidedId.drilldownColumnType" or
      // "dashboardName.dataPanelProvidedId.drilldownColumnType.subPropertyName"
      return;
    }

    const variableDashboardName = variableIdSplitByDot[0];
    if (parentDashboardNames.has(variableDashboardName)) {
      if (
        additionalVariableFilterFn(
          parentDashboardNameToIdMap.get(variableDashboardName) || -1,
          variableIdSplitByDot[1],
          convertDrilldownColumnVariableNameToDrilldownColumnType(variableIdSplitByDot[2]),
        )
      ) {
        drilldownVariables.push({ key: variableId, value: variables[variableId] });
      }
    }
  });

  return drilldownVariables;
};

/**
 * Retrieves all the dashboard drilldown variables that are applicable for the current dashboard.
 * Dashboard drilldown variables have the format
 * "parentDashboardName.sourceDataPanelProvidedId.drilldownColumnType".
 * @param variables The current set variables
 * @param currentSourceInfos The drilldowns source info for the current dashboard. This array
 *     contains the current ancestor dashboards in order (e.g. the first index is the original
 *     dashboard that was drilled down from)
 * @param additionalVariableFilterFn An additional filter function that can be used to filter out
 *     particular variables (e.g. only variables of a particular type).
 */
export const retrieveDrilldownVariables = (
  variables: DashboardVariableMap,
  currentSourceInfos: DrilldownSourceInfo[],
  dashboardHierarchy: DashboardHierarchy,
  additionalVariableFilterFn: (
    sourceDashboardId: number,
    sourceDataPanelProvidedId: string,
    drilldownColumnType: DrilldownColumnType,
  ) => boolean,
): { key: string; value: DashboardVariable }[] => {
  const parentDashboardNameToIdMap = new Map<string, number>();
  for (const sourceInfo of currentSourceInfos) {
    const ancestorDashboard = dashboardHierarchy.dashboards[sourceInfo.sourceDashboardId];
    if (ancestorDashboard) {
      const ancestorDashboardName = ancestorDashboard.name;
      parentDashboardNameToIdMap.set(ancestorDashboardName, sourceInfo.sourceDashboardId);
    }
  }

  return retrieveDrilldownVariablesWithParentDashboardNamesMap(
    variables,
    parentDashboardNameToIdMap,
    additionalVariableFilterFn,
  );
};

const convertDrilldownColumnVariableNameToDrilldownColumnType = (
  drilldownColumnVariableName: string,
): DrilldownColumnType => {
  const columnTypeString = drilldownColumnVariableName.split('_')[1];
  if (!columnTypeString) {
    throw new Error('Incorrectly formatted column variable name');
  }

  return columnTypeString.toUpperCase() as DrilldownColumnType;
};

/**
 * @returns The breakdown columns (e.g. for two dimensional charts, the category and color
 * (grouping)) for the given data panel.
 */
export const getDataPanelBreakdownColumns = (dataPanel: DataPanelTemplate): ChartColumnInfo[] => {
  const breakdownColumns: ChartColumnInfo[] = [];
  const visualizationOperation = dataPanel.visualize_op;
  if (TWO_DIMENSIONAL_CHART_TYPES.has(visualizationOperation.operation_type)) {
    const twoDimensionalChartInstructions =
      visualizationOperation.instructions.V2_TWO_DIMENSION_CHART;
    const categoryColumn = twoDimensionalChartInstructions?.categoryColumn;
    if (categoryColumn) {
      breakdownColumns.push(categoryColumn.column);
    }
    twoDimensionalChartInstructions?.colorColumnOptions?.forEach((colorColumnOption) => {
      breakdownColumns.push(colorColumnOption.column);
    });
  }

  return breakdownColumns;
};

export const areColumnsSubsets = (
  maybeSubsetColumns: ChartColumnInfo[],
  allColumns: ChartColumnInfo[],
): boolean => {
  return maybeSubsetColumns.every((column, index) => column.name === allColumns[index].name);
};

export const getAllAffectedDataPanelIdsFromDrilldownFilterChange = (
  previousDrilldownDatasetFilters: Record<string, DrilldownDatasetFilter>,
  currentDrilldownDatasetFilters: Record<string, DrilldownDatasetFilter>,
  dataPanels: DataPanelTemplate[],
): Set<string> => {
  const affectedDataPanelIdsFromDrilldownDatasetFiltersChanges = new Set<string>();
  const drilldownDatasetFiltersChanged = !isEqual(
    previousDrilldownDatasetFilters,
    currentDrilldownDatasetFilters,
  );
  // TODO(zifanxiang): Make this more robust in handling all potential changes to drilldown
  // dataset filters. Currently we just allow for deletions but might allow changes to the filter
  // value which would also require a refetch of the data.
  if (drilldownDatasetFiltersChanged) {
    const previousDrilldownDatasetFilterDatasetIds = new Set(
      Object.keys(previousDrilldownDatasetFilters),
    );
    const currentDrilldownDatasetFilterDatasetIds = new Set(
      Object.keys(currentDrilldownDatasetFilters),
    );

    const deletedDrilldownDatasetFilterDatasetIds = new Set();
    previousDrilldownDatasetFilterDatasetIds.forEach((previousDatasetId) => {
      if (!currentDrilldownDatasetFilterDatasetIds.has(previousDatasetId)) {
        deletedDrilldownDatasetFilterDatasetIds.add(previousDatasetId);
      }
    });
    const changedDrilldownDatasetFilterDatasetIds = new Set();
    currentDrilldownDatasetFilterDatasetIds.forEach((currentDatasetId) => {
      if (
        previousDrilldownDatasetFilterDatasetIds.has(currentDatasetId) &&
        !isEqual(
          previousDrilldownDatasetFilters[currentDatasetId],
          currentDrilldownDatasetFilters[currentDatasetId],
        )
      ) {
        changedDrilldownDatasetFilterDatasetIds.add(currentDatasetId);
      }
    });
    dataPanels.forEach((dataPanel) => {
      const dataPanelDatasetId = getDataPanelDatasetId(dataPanel);
      // Get all the data panels that are affected by the deletion of drilldown dataset filters.
      if (
        deletedDrilldownDatasetFilterDatasetIds.has(dataPanelDatasetId) ||
        changedDrilldownDatasetFilterDatasetIds.has(dataPanelDatasetId)
      ) {
        affectedDataPanelIdsFromDrilldownDatasetFiltersChanges.add(dataPanel.id);
      }
    });
  }

  return affectedDataPanelIdsFromDrilldownDatasetFiltersChanges;
};

export const getAllAncestorDashboardIds = (
  dashboardHierarchy: DashboardHierarchy,
  dashboardId: number,
): number[] => {
  const ancestorDashboardIds: number[] = [];
  let currentDashboardId = dashboardId;
  do {
    const parentDashboardId = dashboardHierarchy.dashboards[currentDashboardId].parentDashboardId;
    if (!parentDashboardId) {
      break;
    }
    ancestorDashboardIds.push(parentDashboardId);
    currentDashboardId = parentDashboardId;
  } while (currentDashboardId);
  return ancestorDashboardIds;
};

export const createDashboardIdToChildIdsMap = (
  dashboardHierarchy: DashboardHierarchy,
): Record<number, number[]> => {
  const dashboardIdToChildIdsMap: Record<number, number[]> = {};
  const toProcessDashboardIds: number[] = [dashboardHierarchy.rootDashboardId];
  while (toProcessDashboardIds.length > 0) {
    const currentDashboardId = toProcessDashboardIds.shift() as number;
    const childDashboardIds = dashboardHierarchy.dashboards[currentDashboardId].childDashboardIds;
    if (childDashboardIds) {
      dashboardIdToChildIdsMap[currentDashboardId] = childDashboardIds;
      toProcessDashboardIds.push(...childDashboardIds);
    }
  }

  return dashboardIdToChildIdsMap;
};

export const getAllDescendantDashboardIds = (
  dashboardIdToChildIdsMap: Record<number, number[]>,
  dashboardId: number,
): number[] => {
  const dashboardIdsToProcess: number[] = [dashboardId];
  const descendentDashboardIds: number[] = [];
  while (dashboardIdsToProcess.length > 0) {
    const currentDashboardId = dashboardIdsToProcess.pop();
    if (!currentDashboardId) {
      break;
    }
    const currentChildDashboardIds = dashboardIdToChildIdsMap[currentDashboardId];
    if (currentChildDashboardIds) {
      dashboardIdsToProcess.push(...currentChildDashboardIds);
      descendentDashboardIds.push(...currentChildDashboardIds);
    }
  }

  return descendentDashboardIds;
};

// TODO: We need to internationalize this
export const getDefaultDrilldownMenuText = (destinationChartName: string) =>
  `Drill down to ${destinationChartName}`;

export const getDrilldownMenuText = (
  destinationDashboardName: string,
  drilldownEntryPointInfo: DrilldownEntryPointInfo,
): string => {
  return drilldownEntryPointInfo.customDrilldownMenuText
    ? `${drilldownEntryPointInfo.customDrilldownMenuText} ${destinationDashboardName}`
    : getDefaultDrilldownMenuText(destinationDashboardName);
};

export const getAncestorDashboardNames = (
  dashboardHierarchy: DashboardHierarchy,
  dashboardId: number,
): Set<string> => {
  const ancestorDashboardNames = new Set<string>();

  let currentDashboardId: number | null = dashboardId;
  while (currentDashboardId) {
    const currentDashboard: Dashboard = dashboardHierarchy.dashboards[currentDashboardId];
    if (!currentDashboard) {
      break;
    }
    ancestorDashboardNames.add(currentDashboard.name);
    currentDashboardId = currentDashboard.parentDashboardId;
  }

  return ancestorDashboardNames;
};

export const getRenderedDrilldownFilterValue = (
  filterValue: FilterValueType,
  operatorId: FilterOperator,
) => {
  if (!filterValue) return '';
  const isDateFilter = (filter: FilterValueType): filter is FilterValueDateType =>
    typeof filter === 'object' && filter !== null && 'startDate' in filter;

  return FILTER_OPS_DATE_PICKER.has(operatorId) && isDateFilter(filterValue)
    ? formatDateField(filterValue.startDate ?? '', 'DATE', undefined, true)
    : filterValue;
};

export const createDashboardNameToIdMapForSourceInfos = (
  currentSourceInfos: DrilldownSourceInfo[],
  dashboardHierarchy: EmbedDashboardHierarchy,
) => {
  const dashboardNameToIdMap = new Map<string, number>();
  currentSourceInfos.forEach((sourceInfo) => {
    const dashboard = dashboardHierarchy.dashboards[sourceInfo.sourceDashboardId];
    if (dashboard) {
      dashboardNameToIdMap.set(dashboard.name, sourceInfo.sourceDashboardId);
    }
  });
  return dashboardNameToIdMap;
};

export const getDrilldownColumnInfosForSelectedDataPanel = (
  dataPanel: DataPanel,
): Record<string, ChartColumnInfo> => {
  const visualizeOperation = dataPanel.visualize_op;
  if (VALID_DRILLDOWN_OPERATION_TYPES.has(visualizeOperation.operation_type)) {
    const twoDimensionChartInstructions = visualizeOperation.instructions.V2_TWO_DIMENSION_CHART;
    if (!twoDimensionChartInstructions) {
      return {};
    }

    const drilldownColumnInfos: Record<string, ChartColumnInfo> = {};
    const categoryColumn = twoDimensionChartInstructions.categoryColumn;
    if (categoryColumn?.column) {
      const columnName = categoryColumn.column.friendly_name ?? categoryColumn.column.name ?? '';
      drilldownColumnInfos[columnName] = categoryColumn?.column;
    }

    const colorColumns = twoDimensionChartInstructions.colorColumnOptions;
    if (colorColumns) {
      colorColumns.forEach((colorColumn) => {
        if (!colorColumn.column) {
          return;
        }
        const columnName = colorColumn.column.friendly_name ?? colorColumn.column.name ?? '';
        drilldownColumnInfos[columnName] = colorColumn.column;
      });
    }

    return drilldownColumnInfos;
  }
  return {};
};

// TODO(zifanxiang): Write tests for this method.
/**
 * @param subcategory The currently selected secondary column value. This can be undefined if no
 *     secondary column is selected.
 * @returns An array of the drilldown entry points that match the current selected columns on the
 *    chart.
 */
export const getMatchingDashboardDrilldownEntryPoints = (
  drilldownEntryPoints: Record<string, DrilldownEntryPointInfo>,
  dashboardIds: Set<string>,
  instructions: V2TwoDimensionChartInstructions | undefined,
  subCategory?: string,
): [string, DrilldownEntryPointInfo][] => {
  // There might still be some old data panels that do not have drilldown entry points defined
  // (should be an empty object in that case but since we did not do a migration, it might be
  // undefined).
  if (!drilldownEntryPoints) {
    return [];
  }

  const categoryColumn = instructions?.categoryColumn?.column;
  if (!categoryColumn) {
    return [];
  }

  return Object.entries(drilldownEntryPoints).filter(([, entryPointInfo]) => {
    if (!dashboardIds.has(entryPointInfo.destinationDashboardId.toString())) {
      return false;
    }

    const firstEntryPointSourceColumn = entryPointInfo.sourceChartColumns[0];
    if (!ChartColumnInfoUtils.equals(firstEntryPointSourceColumn, categoryColumn)) {
      return false;
    }

    if (!subCategory) {
      return true;
    }

    // Use the selected color column or default to the first color column if there is no selected
    // state on the color columns.
    const selectedColorColumn =
      instructions?.colorColumnOptions?.find((colorColumn) => colorColumn.selected) ??
      instructions?.colorColumnOptions?.[0];
    if (!selectedColorColumn) {
      return false;
    }
    const remainingDrilldownColumns = entryPointInfo.sourceChartColumns.slice(1);
    return remainingDrilldownColumns.some((drilldownColumn) =>
      ChartColumnInfoUtils.equals(drilldownColumn, selectedColorColumn.column),
    );
  });
};
