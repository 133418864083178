import { createAsyncThunk } from '@reduxjs/toolkit';

import { ACTION } from 'actions/types';
import { ReduxState } from 'reducers/rootReducer';
import { makeEmbeddoThunkRequest } from 'utils/thunkUtils';

import {
  CreateResourceEnvironmentTagRequest,
  EnvironmentTagsService,
  ListResourceEnvironmentTagResponse,
  ListResourceEnvironmentTagUsageResponse,
  ResourceEnvironmentTagResponse,
  UpdateResourceEnvironmentTagRequest,
} from '@explo/embeddo-api';

export const listResourceEnvironmentTags = createAsyncThunk<
  ListResourceEnvironmentTagResponse,
  {},
  { state: ReduxState }
>(ACTION.FETCH_ENVIRONMENT_TAGS, async () => {
  return makeEmbeddoThunkRequest(
    () => EnvironmentTagsService.listEnvironmentTags(),
    'Error fetching your environment tags',
  );
});

export const createResourceEnvironmentTag = createAsyncThunk<
  ResourceEnvironmentTagResponse,
  { request: CreateResourceEnvironmentTagRequest; onSuccess: () => void },
  { state: ReduxState }
>(ACTION.CREATE_ENVIRONMENT_TAG, async ({ request, onSuccess }) => {
  return makeEmbeddoThunkRequest(
    () => EnvironmentTagsService.createEnvironmentTag(request),
    'Error creating your environment tag',
    onSuccess,
  );
});

export const updateResourceEnvironmentTag = createAsyncThunk<
  ResourceEnvironmentTagResponse,
  { id: number; request: UpdateResourceEnvironmentTagRequest },
  { state: ReduxState }
>(ACTION.UPDATE_ENVIRONMENT_TAG, async ({ id, request }) => {
  return makeEmbeddoThunkRequest(
    () => EnvironmentTagsService.updateEnvironmentTag(id, request),
    'Error updating your environment tag',
  );
});

export const deleteResourceEnvironmentTag = createAsyncThunk<
  {},
  { id: number },
  { state: ReduxState }
>(ACTION.DELETE_ENVIRONMENT_TAG, async ({ id }) => {
  return makeEmbeddoThunkRequest(
    () => EnvironmentTagsService.deleteEnvironmentTag(id),
    'Error deleting your environment tag',
  );
});

export const getResourceEnvironmentTagUsage = createAsyncThunk<
  ListResourceEnvironmentTagUsageResponse,
  {
    id: number;
    onSuccess: (response: ListResourceEnvironmentTagUsageResponse) => void;
    onError: () => void;
  },
  { state: ReduxState }
>(ACTION.GET_ENVIRONMENT_TAG_USAGE, async ({ id }) => {
  return makeEmbeddoThunkRequest(
    () => EnvironmentTagsService.getUsage(id),
    'Error getting environment tag usage',
  );
});
