import { usePrevious } from '@radix-ui/react-use-previous';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

import { fetchDashboard } from 'actions/dashboardActions';
import { fetchDashboardVersionsMetadata } from 'actions/dashboardV2Actions';
import { fetchUserTeam } from 'actions/teamActions';
import { ErrorState } from 'components/ErrorState';
import { ExploLoadingSpinner } from 'components/ExploLoadingSpinner';
import { withWidth, WithWidthProps } from 'components/HOCs/withWidth';
import { PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import { EditDashboardPage } from 'pages/dashboardPage/editDashboardPage';
import { setExploreInteractionsInfo } from 'reducers/dashboardInteractionsReducer';
import { clearFolderDashboards } from 'reducers/dashboardReducer';
import { clearFolders } from 'reducers/folderReducer';
import { ReduxState } from 'reducers/rootReducer';
import {
  getDashboardEditConfigWithDrilldowns,
  getRootVersionInfoWithDrilldowns,
} from 'reducers/selectors';
import * as RD from 'remotedata';
import { pageView } from 'telemetry/exploAnalytics';
import { EDIT_MODE_HASH, VIEW_MODE } from 'types/dashboardTypes';
import { VersionedComputedViewReference } from 'types/dashboardVersionConfig';
import { useLoadEditMetadata } from 'utils/hookUtils';
import { canUserModifyResource } from 'utils/permissionUtils';
import { Dataset } from 'actions/datasetActions';

type Props = Pick<WithWidthProps, 'width'>;

type MatchParams = {
  dashboardId: string;
};

const VIEW_MODE_MAP: Record<string, VIEW_MODE> = {
  [EDIT_MODE_HASH.EDIT]: VIEW_MODE.DEFAULT,
  [EDIT_MODE_HASH.PDF]: VIEW_MODE.PDF,
  [EDIT_MODE_HASH.EMAIL]: VIEW_MODE.EMAIL,
  [EDIT_MODE_HASH.MOBILE]: VIEW_MODE.MOBILE,
};

const DashboardPageBase: FC<Props> = ({ width }) => {
  const dispatch = useDispatch();

  const [initialViewIds, setInitialViewIds] = useState<string[]>();
  const [globalDatasetReferences, setGlobalDatasetReferences] = useState<
    Record<string, VersionedComputedViewReference> | undefined
  >();

  const {
    currentDashboard,
    currentUser,
    dashboardConfigLoaded,
    dashboardVersionInfo,
    inEditMode,
    teamData,
    viewMode,
    isCreatingChildDashboard,
    referencedGlobalDatasets,
    latestReferencedGlobalDatasets,
    deletedLatestReferencedGlobalDatasetIds,
  } = useSelector(
    (state: ReduxState) => ({
      currentDashboard: state.dashboard.currentDashboard,
      currentUser: state.currentUser,
      dashboardConfigLoaded: !!getDashboardEditConfigWithDrilldowns(state),
      dashboardVersionInfo: getRootVersionInfoWithDrilldowns(state),
      teamData: state.teamData.data,
      inEditMode: state.dashboardInteractions.interactionsInfo.isEditing,
      viewMode: state.dashboardInteractions.interactionsInfo.viewMode,
      isCreatingChildDashboard: state.dashboardInteractions.isCreatingChildDashboard,
      referencedGlobalDatasets: state.fido.referencedGlobalDatasets,
      latestReferencedGlobalDatasets: state.fido.latestReferencedGlobalDatasets,
      deletedLatestReferencedGlobalDatasetIds: state.fido.deletedLatestReferencedGlobalDatasetIds,
    }),
    shallowEqual,
  );

  const {
    params: { dashboardId },
  } = useRouteMatch<MatchParams>();

  const updateEditModeUrlHash = useCallback(
    (newViewMode?: VIEW_MODE) => {
      switch (newViewMode ?? viewMode) {
        case VIEW_MODE.PDF:
          window.location.hash = EDIT_MODE_HASH.PDF;
          break;
        case VIEW_MODE.EMAIL:
          window.location.hash = EDIT_MODE_HASH.EMAIL;
          break;
        case VIEW_MODE.MOBILE:
          window.location.hash = EDIT_MODE_HASH.MOBILE;
          break;
        default:
          window.location.hash = EDIT_MODE_HASH.EDIT;
      }
    },
    [viewMode],
  );

  const metadataLoading = useLoadEditMetadata(
    initialViewIds,
    globalDatasetReferences,
    referencedGlobalDatasets,
    latestReferencedGlobalDatasets,
    deletedLatestReferencedGlobalDatasetIds,
  );

  const userCanModifyResource = useMemo(
    () => canUserModifyResource(currentUser.permissions[PERMISSIONED_ENTITIES.DASHBOARD]),
    [currentUser.permissions],
  );

  useEffect(() => {
    const viewMode = VIEW_MODE_MAP[window.location.hash];

    dispatch(
      fetchDashboard({ id: dashboardId }, (data) => {
        document.title = `Explo | ${data.dashboard.name}`;

        if (data.dashboard_version.is_draft && viewMode) {
          dispatch(setExploreInteractionsInfo({ isEditing: userCanModifyResource, viewMode }));
        } else {
          history.pushState('', document.title, window.location.pathname + window.location.search);
        }

        const dashboardVersionConfig = data.dashboard_version.configuration;
        setInitialViewIds(
          Object.values<Dataset>(dashboardVersionConfig.datasets ?? {})
            .map((dataset) => dataset.fido_id ?? '')
            .filter((id) => !!id),
        );
        setGlobalDatasetReferences(dashboardVersionConfig.versioned_computed_view_references ?? {});
      }),
    );

    dispatch(fetchDashboardVersionsMetadata({ id: dashboardId }));

    // clear folders so if we click back to a folder via the breadcrumbs,
    // we don't show a flicker of the stale stored folder
    dispatch(clearFolders());
    dispatch(clearFolderDashboards());

    pageView('Dashboard');
  }, [currentUser.team?.id, dashboardId, dispatch, userCanModifyResource]);

  useEffect(() => {
    if (!teamData) dispatch(fetchUserTeam());
  }, [teamData, dispatch]);

  const prevInEditMode = usePrevious(inEditMode);
  const prevViewMode = usePrevious(viewMode);

  useEffect(() => {
    if (prevInEditMode !== inEditMode) {
      if (inEditMode) updateEditModeUrlHash(viewMode);
      else history.pushState('', document.title, window.location.pathname + window.location.search);
      return;
    }
    if (prevViewMode === viewMode || !inEditMode) return;
    updateEditModeUrlHash(viewMode);
  }, [inEditMode, viewMode, updateEditModeUrlHash, prevInEditMode, prevViewMode]);

  useEffect(() => {
    dispatch(
      setExploreInteractionsInfo({
        disableFiltersWhileLoading: RD.getOrDefault(currentDashboard, undefined)
          ?.disable_filters_while_loading,
        supportEmail: teamData?.support_email ?? undefined,
      }),
    );
  }, [dispatch, currentDashboard, teamData?.support_email]);

  if (RD.isError(currentDashboard)) return <ErrorState text={currentDashboard.error} />;

  if (
    !RD.isSuccess(currentDashboard) ||
    !dashboardVersionInfo ||
    metadataLoading ||
    isCreatingChildDashboard ||
    !dashboardConfigLoaded
  )
    return <ExploLoadingSpinner />;

  return <EditDashboardPage dashboard={currentDashboard.data} width={width} />;
};

export const DashboardPage = withWidth(DashboardPageBase);
