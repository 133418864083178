import {
  ApiError,
  Computation,
  DataRequestParameters,
  ExportFormat,
  Property,
  QueryExecutionResponse,
  QueryExportResponse,
  QueryPreviewRequest,
  QueryResourceService,
  ViewRunRequest,
} from '@explo-tech/fido-api';
import { ResponseBody, RoverResourceService } from '@explo/rover-api';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  AggColInfo,
  ColumnConfigs,
  CustomerReportFilter,
  CustomerReportGroupBy,
  DefaultSortColumn,
  OPERATION_TYPES,
  SortInfo,
} from '@explo/data';

import {
  createDatasetSchemaMap,
  deriveColumnConfigs,
  generateComputations,
  generateExploreExportColumnOptions,
  generateReportBuilderComputations,
  generateReportBuilderExportColumnOptions,
  sanitizeFileNameForExports,
} from '@explo/computation-generation';
import { Dataset, FetchDashboardDatasetPreviewBody } from 'actions/datasetActions';
import { ExportSpreadsheetType } from 'actions/exportActions';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { ACTION } from 'actions/types';
import { EmbeddedDashboardType } from 'components/EmbeddedDashboard/types';
import { DashboardStates, ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { AdHocOperationInstructions } from 'types/dataPanelTemplate';
import { DataPanel } from 'types/exploResource';
import { convertSortInfoToList, getFilterInfo, getSortInfo } from 'utils/adHocUtils';
import { getReportBuilderTimezone } from 'utils/customerReportUtils';
import { getDataPanelDatasetId } from 'utils/exploResourceUtils';
import { getDataSource } from 'utils/fido/fidoRequestUtils';
import { ComputedViewWithIds } from 'utils/fido/fidoShimmedTypes';
import { getDataPanelQueryLimit, getDatasetQueryLimit } from 'utils/queryUtils';
import { FidoRequestFn, makeFidoThunkRequest, makeRoverThunkRequest } from 'utils/thunkUtils';

import { Settings } from 'luxon';
import { getSelectedCustomer } from 'reducers/customersReducer';
import { getExploreLoggingMetadata, getFromEmail } from 'reducers/thunks/roverThunks/roverUtils';
import { VersionedComputedViewReference } from 'types/dashboardVersionConfig';
import { DashboardLayoutThunk } from '../dashboardLayoutThunks/types';
import { saveComputedView } from '../fidoThunks';
import { exportReportBuilderTabular } from '../roverThunks';
import { FetchOrigin, FidoReducerArgs } from './types';
import { DataPreviewType } from 'reportBuilderContent/apiTypes';

/**
 * Fetches a preview of the provided ComputedView. This fetch uses the default data source of
 * the view's namespace to query data.
 */
export const fetchFidoViewPreview = createAsyncThunk<
  QueryExecutionResponse,
  {
    view: Pick<ComputedViewWithIds, 'id' | 'namespaceId'>;
    body: QueryPreviewRequest;
    onSuccess?: (data: QueryExecutionResponse) => void;
  },
  { state: ReduxState; rejectValue: ApiError }
>(
  ACTION.FETCH_FIDO_COMPUTED_VIEW_PREVIEW,
  async ({ body, onSuccess, view }, { getState, rejectWithValue }) => {
    const { fido, dashboardLayout } = getState();

    let requestFn: FidoRequestFn<QueryExecutionResponse> = null;

    const dataSource = getDataSource(
      { namespaceId: view.namespaceId, ...dashboardLayout.requestInfo },
      fido,
    );

    requestFn = dataSource
      ? () => QueryResourceService.getQueryPreview(dataSource, view.namespaceId, body)
      : null;

    return makeFidoThunkRequest(
      requestFn,
      fido.fidoToken ?? '',
      'Error loading preview for your query',
      onSuccess,
    ).catch(rejectWithValue);
  },
);

/**
 * Fetches a preview of the provided ComputedView. This fetch uses the default data source of
 * the view's namespace to query data.
 */
export const fetchGlobalDatasetFidoViewPreview = createAsyncThunk<
  QueryExecutionResponse,
  {
    view: ComputedViewWithIds;
    body: QueryPreviewRequest;
    origin: FetchOrigin;
  },
  { state: ReduxState }
>(ACTION.FETCH_GLOBAL_DATASET_FIDO_COMPUTED_VIEW_PREVIEW, async ({ body, view }, { getState }) => {
  const { fido, customers } = getState();

  const selectedCustomer = getSelectedCustomer(customers);

  const dataSource = getDataSource(
    {
      namespaceId: view.namespaceId,
      parentSchemaDataSourceMapping: selectedCustomer?.computed_parent_schema_datasource_mapping,
      dataSources: fido.embeddoDaos.dataSources,
      type: 'app', // Global dataset editor is only used in an app setting
      schemas: fido.embeddoDaos.usedParentSchemas,
    },
    fido,
  );

  if (!dataSource) throw new Error('No backing datasource, cannot fetch data');

  return makeFidoThunkRequest(
    () => QueryResourceService.getQueryPreview(dataSource, view.namespaceId, body),
    fido.fidoToken ?? '',
    'Error loading preview for your query',
  );
});

/**
 * Report Builder Fido Thunks
 */
export const fetchFidoReportBuilderView = createAsyncThunk<
  QueryExecutionResponse,
  {
    id: string;
    dataset: ReportBuilderDataset;
    body: ViewRunRequest;
    customerId: number;
    timezone: string | undefined;
    primaryRequestId?: string | undefined;
  },
  { state: ReduxState }
>(ACTION.FETCH_FIDO_REPORT_BUILDER_VIEW, async ({ dataset, body, customerId }, { getState }) => {
  const { fido, parentSchemas, dataSource, customers } = getState();

  let requestFn: FidoRequestFn<QueryExecutionResponse> = null;

  const { fido_id: viewId, namespace_id: namespaceId } = dataset;

  const customer = customers.cachedCustomers[customerId]?.customer;

  if (customer && viewId && namespaceId) {
    const dataSourceId = getDataSource({
      namespaceId,
      parentSchemaDataSourceMapping: customer.computed_parent_schema_datasource_mapping,
      schemas: RD.getOrDefault(parentSchemas.usedParentSchemas, []),
      dataSources: RD.getOrDefault(dataSource.dataSources, []),
      type: 'app',
    });

    const backingGlobalDataset = RD.getOrDefault(fido.referencedGlobalDatasets, {})[dataset.id];

    if (!dataSourceId) {
      return Promise.reject('No data source found');
    }

    if (backingGlobalDataset) {
      requestFn = () =>
        QueryResourceService.runVersionedView(
          dataSourceId,
          namespaceId,
          backingGlobalDataset.versionId ?? '',
          viewId,
          body,
        );
    } else {
      requestFn = () => QueryResourceService.runView(dataSourceId, namespaceId, viewId, body);
    }
  }

  return makeFidoThunkRequest(
    requestFn,
    fido.fidoToken ?? '',
    'Error loading preview for your query',
  );
});

export const fetchFidoEmbedReportBuilderView = createAsyncThunk<
  QueryExecutionResponse,
  {
    id: string;
    dataset: ReportBuilderDataset;
    body: ViewRunRequest;
    timezone: string | undefined;
  },
  { state: ReportBuilderReduxState }
>(ACTION.EMBED_FETCH_FIDO_REPORT_BUILDER_VIEW, async ({ dataset, body }, { getState }) => {
  const { fido } = getState();

  let requestFn: FidoRequestFn<QueryExecutionResponse> = null;

  const { fido_id: viewId, namespace_id: namespaceId } = dataset;

  if (viewId && namespaceId) {
    const dataSourceId = getDataSource({ namespaceId: namespaceId, type: 'embedded' }, fido);
    const backingGlobalDataset = RD.getOrDefault(fido.referencedGlobalDatasets, {})[dataset.id];

    if (!dataSourceId) {
      return Promise.reject('No data source found');
    }

    if (backingGlobalDataset) {
      requestFn = () =>
        QueryResourceService.runVersionedView(
          dataSourceId,
          namespaceId,
          backingGlobalDataset.versionId ?? '',
          viewId,
          body,
        );
    } else {
      requestFn = () => QueryResourceService.runView(dataSourceId, namespaceId, viewId, body);
    }
  }

  return makeFidoThunkRequest(
    requestFn,
    fido.fidoToken ?? '',
    'Error loading preview for your query',
  );
});

export const fetchFidoReportBuilderQueryPreview = createAsyncThunk<
  QueryExecutionResponse,
  {
    dataset: ReportBuilderDataset;
    body: QueryPreviewRequest;
    customerId: number;
    save?: boolean | undefined;
    timezone: string;
    dataPreviewType: DataPreviewType;
  },
  { state: ReduxState }
>(
  ACTION.FETCH_FIDO_REPORT_BUILDER_QUERY_PREVIEW,
  async ({ dataset, body, customerId, save }, { getState, dispatch }) => {
    const { fido, parentSchemas, dataSource, customers } = getState();

    let requestFn: FidoRequestFn<QueryExecutionResponse> = null;
    let onSuccess: ((response: QueryExecutionResponse) => void) | undefined = undefined;

    const referencedGlobalDatasets = Object.values(
      RD.getOrDefault(fido.referencedGlobalDatasets, {}),
    ).map((globalDataset) => {
      return {
        id: globalDataset.id ?? '',
        namespaceId: globalDataset.namespaceId ?? '',
        versionId: globalDataset.versionId ?? '',
        ...globalDataset,
      } as ComputedViewWithIds;
    });
    const allFidoViews = RD.getOrDefault(fido.computedViews, []).concat(referencedGlobalDatasets);
    const selectedView = allFidoViews.find((view) => view.id === dataset.fido_id);
    const customer = customers.cachedCustomers[customerId]?.customer;

    if (customer && selectedView) {
      const dataSourceId = getDataSource({
        namespaceId: selectedView.namespaceId,
        parentSchemaDataSourceMapping: customer.computed_parent_schema_datasource_mapping,
        schemas: RD.getOrDefault(parentSchemas.usedParentSchemas, []),
        dataSources: RD.getOrDefault(dataSource.dataSources, []),
        type: 'app',
      });

      if (dataSourceId) {
        requestFn = () =>
          QueryResourceService.getQueryPreview(dataSourceId, selectedView.namespaceId, body);
        onSuccess = save
          ? (response) =>
              dispatch(
                saveComputedView({
                  ...selectedView,
                  query: body.query,
                  columnDefinitions: response.meta.schema.propertySchema,
                  permissions: dataset.permissions,
                }),
              )
          : undefined;
      }
    }

    return makeFidoThunkRequest(
      requestFn,
      fido.fidoToken ?? '',
      'Error loading preview for your query',
      onSuccess,
    );
  },
);

/**
 * Dashboard FIDO thunks
 */

/**
 * Fetches the data for the provided ComputedView. This fetch uses the current user's assigned
 * data source.
 */
export const fetchFidoViewData = createAsyncThunk<
  QueryExecutionResponse,
  {
    body: Pick<FetchDashboardDatasetPreviewBody, 'variables' | 'query_limit' | 'timezone'>;
    dataset: Dataset;
  },
  { state: DashboardStates }
>(
  ACTION.FETCH_COMPUTED_VIEW_DATA,
  async ({ body: { variables, query_limit: queryLimit }, dataset }, { getState }) => {
    const { fido, dashboardLayout } = getState();

    const { namespace_id: namespaceId, fido_id: viewId } = dataset;

    let requestFn: FidoRequestFn<QueryExecutionResponse> = null;

    if (namespaceId && viewId) {
      const dataSource = getDataSource(
        { namespaceId: namespaceId, ...dashboardLayout.requestInfo },
        fido,
      );

      requestFn = dataSource
        ? () =>
            QueryResourceService.runView(dataSource, namespaceId, viewId, {
              queryContext: variables,
              dataRequestParameters: {
                pagingConfiguration: {
                  perPage: queryLimit,
                },
              },
              requestExecutionParameters: null,
              computation: null,
            })
        : null;
    }

    return makeFidoThunkRequest(
      requestFn,
      fido.fidoToken ?? '',
      'Error loading preview for your query',
    );
  },
);

export const fetchFidoVersionedViewPreviewData = createAsyncThunk<
  QueryExecutionResponse,
  {
    globalDatasetReference: VersionedComputedViewReference;
  },
  { state: DashboardStates }
>(ACTION.FETCH_VERSIONED_COMPUTED_VIEW_DATA, async (args, { getState }) => {
  const { fido, dashboardLayout, dashboardData } = getState();
  const requestInfo = dashboardLayout.requestInfo;

  const { globalDatasetReference } = args;
  const { namespaceId, versionId, id } = globalDatasetReference;

  const dataSource = getDataSource({ namespaceId, ...requestInfo }, fido);

  if (!dataSource) {
    return Promise.reject('No data source found');
  }

  const requestFn = () => {
    return QueryResourceService.runVersionedView(dataSource, namespaceId, versionId, id, {
      queryContext: dashboardData.variables ?? {},
      dataRequestParameters: {
        pagingConfiguration: {
          perPage: getDatasetQueryLimit(requestInfo.datasetMaxRows),
        },
      },
      requestExecutionParameters: null,
      computation: null,
    });
  };

  return makeFidoThunkRequest(
    requestFn,
    fido.fidoToken ?? '',
    'Error fetching the preview data for the versioned view',
  );
});

/**
 * Fetches the data for the provided data panel. This fetch uses the current users's assigned data source
 */
export const fetchFidoComputationDataThunk =
  (
    dataPanel: DataPanel,
    datasets: Record<string, Dataset>,
    variables: DashboardVariableMap,
    adHocInstructions?: AdHocOperationInstructions,
    shouldOverrideCache?: boolean,
  ): DashboardLayoutThunk =>
  (dispatch, getState) => {
    const { fido, dashboardLayout, dashboardData } = getState();

    const dataPanelData = dashboardData.dataPanelData[dataPanel.id];
    let namespaceId = '';
    let viewId = '';
    if (dataPanel.globalDatasetReference) {
      namespaceId = dataPanel.globalDatasetReference.namespaceId;
      viewId = dataPanel.globalDatasetReference.id;
    } else {
      const dataset = datasets[getDataPanelDatasetId(dataPanel)] ?? {};
      namespaceId = dataset.namespace_id ?? '';
      viewId = dataset.fido_id ?? '';
    }

    if (!namespaceId || !viewId) return;
    const dataSource = getDataSource({ namespaceId, ...dashboardLayout.requestInfo }, fido);

    if (!dataSource) return;

    const filterInfo = adHocInstructions
      ? adHocInstructions.filterInfo
      : getFilterInfo(dataPanel.visualize_op.operation_type, dataPanelData);
    const sortInfo = convertSortInfoToList(
      adHocInstructions ? adHocInstructions.sortInfo : getSortInfo(dataPanel, dataPanelData),
    );

    const pageNumber = adHocInstructions ? adHocInstructions.currentPage : undefined;

    const computations = generateComputations(
      dataPanel,
      {
        sortInfo: sortInfo?.map((s) => ({
          order: s.order,
          column: { name: s.column_name, displayColumn: s.display_column_name },
        })),
        filterInfo,
      },
      dashboardLayout.requestInfo.timezone,
      'embedType' in dashboardLayout.requestInfo &&
        dashboardLayout.requestInfo.embedType === EmbeddedDashboardType.SCREENSHOT,
    );

    const primaryComputation = computations?.find((c) => c['@type'] === 'primary')?.computation;

    if (!computations || !primaryComputation) return;

    const requestFn = () => {
      const requestBody: ViewRunRequest = {
        queryContext: variables,
        dataRequestParameters: {
          pagingConfiguration: {
            page: Math.max((pageNumber ?? 1) - 1, 0),
            perPage: getDataPanelQueryLimit(
              dataPanel.visualize_op,
              'embedType' in dashboardLayout.requestInfo
                ? dashboardLayout.requestInfo.embedType
                : undefined,
              dashboardLayout.requestInfo.dataPanelMaxDataPoints,
              dashboardLayout.requestInfo.pdfMaxRows,
            ),
          },
        },
        requestExecutionParameters: { forceRefresh: shouldOverrideCache },
        computation: primaryComputation,
      };
      if (dataPanel.globalDatasetReference) {
        return QueryResourceService.runVersionedView(
          dataSource,
          namespaceId,
          dataPanel.globalDatasetReference.versionId,
          viewId,
          requestBody,
        );
      }
      return QueryResourceService.runView(dataSource, namespaceId, viewId, requestBody);
    };

    const primaryRequestId = dispatch(
      fetchFidoComputationData({
        requestFn,
        reducerArgs: {
          dataPanelId: dataPanel.id,
          filterInfo,
          sortInfo,
          pageNumber,
          '@type': 'primary',
          visualizeOp: dataPanel.visualize_op,
          timezone: dashboardLayout.requestInfo.timezone,
        },
      }),
    ).requestId;

    computations
      ?.filter((c) => c['@type'] !== 'primary')
      .forEach(({ computation, '@type': type }) => {
        const secondaryRequestFn = () => {
          const requestBody: ViewRunRequest = {
            queryContext: variables,
            dataRequestParameters: {
              pagingConfiguration: {
                page: 0,
                perPage: getDataPanelQueryLimit(
                  dataPanel.visualize_op,
                  'embedType' in dashboardLayout.requestInfo
                    ? dashboardLayout.requestInfo.embedType
                    : undefined,
                  dashboardLayout.requestInfo.dataPanelMaxDataPoints,
                  dashboardLayout.requestInfo.pdfMaxRows,
                ),
              },
            },
            requestExecutionParameters: { forceRefresh: shouldOverrideCache },
            computation,
          };
          if (dataPanel.globalDatasetReference) {
            return QueryResourceService.runVersionedView(
              dataSource,
              namespaceId,
              dataPanel.globalDatasetReference.versionId,
              viewId,
              requestBody,
            );
          } else {
            return QueryResourceService.runView(dataSource, namespaceId, viewId, requestBody);
          }
        };

        const sharedReducerArgs = {
          dataPanelId: dataPanel.id,
          timezone: dashboardLayout.requestInfo.timezone,
          primaryRequestId,
        };

        let reducerArgs: FidoReducerArgs;

        switch (type) {
          case 'row-count':
            reducerArgs = {
              ...sharedReducerArgs,
              '@type': 'row-count',
            };
            break;
          case 'column-totals':
            reducerArgs = {
              ...sharedReducerArgs,
              '@type': 'column-totals',
            };
            break;
          case 'progress-bar':
            reducerArgs = {
              ...sharedReducerArgs,
              '@type': 'progress-bar',
            };
            break;
          case 'kpi-trend':
            reducerArgs = {
              ...sharedReducerArgs,
              '@type': 'kpi-trend',
              secondaryDataAgg: computation.properties[0].targetPropertyId ?? '',
            };
            break;
          default:
            reducerArgs = {
              ...sharedReducerArgs,
              '@type': 'row-count',
            };
        }

        dispatch(
          fetchFidoComputationData({
            requestFn: secondaryRequestFn,
            reducerArgs,
          }),
        );
      });
  };

/**
 * Fetches the data for the provided data panel. This fetch uses the current users's assigned data source
 */
export const fetchFidoComputationData = createAsyncThunk<
  QueryExecutionResponse,
  {
    requestFn: FidoRequestFn<QueryExecutionResponse>;
    reducerArgs: FidoReducerArgs;
  },
  { state: DashboardStates; rejectValue: ApiError }
>(ACTION.FETCH_SUMMARIZED_VIEW_DATA, async ({ requestFn }, { getState, rejectWithValue }) => {
  const { fidoToken } = getState().fido;

  return makeFidoThunkRequest(
    requestFn,
    fidoToken ?? '',
    'Error loading preview for your query',
  ).catch(rejectWithValue);
});

export const downloadExploreComputationSpreadsheet = createAsyncThunk<
  QueryExportResponse | ResponseBody,
  {
    dataPanel: DataPanel;
    dataset: Dataset;
    adHocInstructions?: AdHocOperationInstructions;
    fileFormat: ExportSpreadsheetType;
    fileNameForExport: string;
    variables: DashboardVariableMap;
    emails: string[] | null;
  },
  { state: DashboardStates }
>(
  ACTION.FIDO_DOWNLOAD_COMPUTATION_SPREADSHEET,
  async (
    { dataPanel, dataset, adHocInstructions, fileFormat, fileNameForExport, variables, emails },
    { getState, rejectWithValue },
  ) => {
    const { fido, dashboardLayout, dashboardData, analytics } = getState();

    const dataPanelData = dashboardData.dataPanelData[dataPanel.id];
    const { namespace_id: namespaceId, fido_id: viewId } = dataset;

    if (!namespaceId || !viewId) {
      return rejectWithValue('Unable to find namespace or view id');
    }

    const dataSource = getDataSource({ namespaceId, ...dashboardLayout.requestInfo }, fido);

    if (!dataSource) return rejectWithValue('Unable to find data source');

    const visualizeOp = dataPanel.visualize_op;
    const filterInfo = adHocInstructions
      ? adHocInstructions.filterInfo
      : getFilterInfo(visualizeOp.operation_type, dataPanelData);
    const sortInfo = convertSortInfoToList(
      adHocInstructions ? adHocInstructions.sortInfo : getSortInfo(dataPanel, dataPanelData),
    );
    const computations = generateComputations(
      dataPanel,
      {
        sortInfo: sortInfo?.map((s) => ({
          order: s.order,
          column: { name: s.column_name, displayColumn: s.display_column_name },
        })),
        filterInfo,
      },
      dashboardLayout.requestInfo.timezone,
      true,
    );

    const exportOptions = visualizeOp.generalFormatOptions?.export;
    let exportFormat: ExportFormat;
    switch (fileFormat) {
      case 'csv':
        exportFormat = exportOptions?.csvFormat?.tsvEnabled ? ExportFormat.TSV : ExportFormat.CSV;
        break;
      default:
        exportFormat = ExportFormat.XLSX;
        break;
    }

    // TODO the only chart that requires two computations is KPI trend, do we allow exports for that?
    const exportComputation = computations?.find((c) => c['@type'] === 'primary')?.computation;

    if (!computations || !exportComputation)
      return rejectWithValue('Unable to generate computation');

    // in getTransformedDataPanelForCsv, we apply some operations to changeSchemaList that cause it to
    // not include every column that could be selected, which causes csv downloads here to include extra
    // columns. This is because we use baseSchemaList to select our columns, but getTransformedDataPanelForCsv
    // doesn't transform that. It'd be a wide blast radius fix to try to fix that, so instead just doing
    // and extra filter pass here ensures that we're only selecting what the user knows about
    if (
      visualizeOp.operation_type == OPERATION_TYPES.VISUALIZE_TABLE &&
      visualizeOp.instructions.VISUALIZE_TABLE.isSchemaCustomizationEnabled
    ) {
      const changeSchemaList = visualizeOp.instructions.VISUALIZE_TABLE.changeSchemaList;
      // default sort columns might not be explicitly selected
      const sortColumnNames = visualizeOp.instructions.VISUALIZE_TABLE.defaultSortedColumn
        ? new Set(
            ('column' in visualizeOp.instructions.VISUALIZE_TABLE.defaultSortedColumn
              ? [visualizeOp.instructions.VISUALIZE_TABLE.defaultSortedColumn]
              : (visualizeOp.instructions.VISUALIZE_TABLE
                  .defaultSortedColumn as DefaultSortColumn[])
            ).map((column) => column.column),
          )
        : null;

      const exportPropertiesMap = exportComputation.properties.reduce(
        (acc, p) => {
          // This condition should always be met because data table properties always have a propertyId. The only exception is when exporting a count(*) on a chart.
          if ('propertyId' in p && p.propertyId) {
            acc[p.propertyId] = p;
          }
          return acc;
        },
        {} as { [key: string]: Property },
      );

      const orderedProperties: Property[] = [];
      changeSchemaList.forEach(({ col, keepCol }) => {
        const property = exportPropertiesMap[col];
        if (property && (keepCol || sortColumnNames?.has(property.targetPropertyId ?? ''))) {
          orderedProperties.push(property);
        }
      });

      exportComputation.properties = orderedProperties;
    }

    const fileName = sanitizeFileNameForExports(fileNameForExport);

    const datasetSchemaMap = createDatasetSchemaMap(dataset.schema);

    const exportConfiguration = {
      fileName,
      exportFormat,
      columnDisplayOptions: generateExploreExportColumnOptions(
        exportComputation,
        deriveColumnConfigs(
          visualizeOp.operation_type,
          dataPanel.visualize_op.instructions,
          exportComputation,
        ),
        datasetSchemaMap,
        exportFormat,
        {
          timezone: dashboardLayout.requestInfo.timezone,
          locale: Settings.defaultLocale,
          currencyCode: dashboardLayout.requestInfo.dashboardCurrencyCode,
        },
      ),
    };
    const exportRequestBody = {
      queryContext: variables,
      emailConfiguration: emails
        ? {
            recipientEmails: emails,
            subject: `Requested Data Export: ${fileName} ${fileFormat}`,
            body: 'Attached below is the download you requested.',
          }
        : null,
      exportConfiguration,
      computation: exportComputation,
    };

    if (dashboardLayout.requestInfo.useRover) {
      return makeRoverThunkRequest(
        () =>
          RoverResourceService.tabularExport({
            loggingMetadata: getExploreLoggingMetadata(analytics, dashboardLayout.requestInfo),
            exportMetadata: {
              emailConfiguration: exportRequestBody.emailConfiguration
                ? {
                    emailContentArgs: {
                      '@type': 'explore-ad-hoc',
                    },
                    toEmails: exportRequestBody.emailConfiguration.recipientEmails,
                    subject: exportRequestBody.emailConfiguration.subject,
                    fromEmail: getFromEmail({
                      emailOverride: dashboardLayout.requestInfo.emailOverride,
                      displayName: dashboardLayout.requestInfo.emailFromName,
                    }),
                  }
                : null,
              queryContext: variables,
            },
            computationConfigs: [
              {
                ...exportComputation,
                ...exportConfiguration,
                viewId: dataPanel.globalDatasetReference
                  ? (dataPanel.globalDatasetReference?.id ?? '')
                  : viewId,
                namespaceId: namespaceId,
                dataSourceId: dataSource,
                versionId: dataPanel.globalDatasetReference
                  ? (dataPanel.globalDatasetReference?.versionId ?? '')
                  : undefined,
              },
            ],
            jwt: fido.fidoToken ?? '',
          }),
        fido.roverTabularExportToken ?? '',
        'Error downloading tabular report',
      );
    } else {
      const requestFn = dataPanel.globalDatasetReference
        ? () => {
            return QueryResourceService.exportVersionedView(
              dataSource,
              namespaceId,
              dataPanel.globalDatasetReference?.versionId ?? '',
              dataPanel.globalDatasetReference?.id ?? '',
              exportRequestBody,
            );
          }
        : () => {
            return QueryResourceService.exportView(
              dataSource,
              namespaceId,
              viewId,
              exportRequestBody,
            );
          };

      return makeFidoThunkRequest(
        requestFn,
        fido.fidoToken ?? '',
        'Error downloading your data panel',
      );
    }
  },
);

export const FIDO_SUPPORTED_EXPORT_FORMATS = new Set<string>([
  ExportFormat.CSV,
  ExportFormat.XLSX,
  ExportFormat.TSV,
]);

export const downloadReportBuilderComputationSpreadsheet = createAsyncThunk<
  QueryExportResponse | ResponseBody,
  {
    computationBody: {
      aggs: AggColInfo[];
      sort: SortInfo[];
      filters: CustomerReportFilter[];
      group_bys: CustomerReportGroupBy[] | undefined;
      col_group_bys: CustomerReportGroupBy[] | undefined;
      columns: string[];
      hidden_columns: string[];
      column_configs: ColumnConfigs;
    };
    dataset: ReportBuilderDataset;
    fileFormat: ExportSpreadsheetType;
    fileName: string;
    emails: null;
  },
  { state: ReportBuilderReduxState }
>(
  ACTION.FIDO_DOWNLOAD_COMPUTATION_SPREADSHEET_REPORT_BUILDER,
  async ({ computationBody, dataset, fileFormat, fileName }, { getState, rejectWithValue }) => {
    const { fido, embeddedReportBuilder } = getState();
    const {
      aggs,
      sort,
      filters,
      group_bys: groupBys,
      col_group_bys: colGroupBys,
      columns,
      hidden_columns: hiddenColumns,
      column_configs: columnConfigs,
    } = computationBody;
    const { team } = embeddedReportBuilder;

    const groupings = (groupBys ?? []).concat(colGroupBys ?? []).map((g) => ({
      column: g.column,
      bucket: g.bucket ? { id: g.bucket } : undefined,
    }));

    const computation = generateReportBuilderComputations(
      {
        aggs,
        sort,
        filters,
        group_bys: groupings,
        columns,
        hidden_columns: hiddenColumns,
      },
      dataset.customAggregations,
      getReportBuilderTimezone(embeddedReportBuilder),
    );

    const { namespace_id: namespaceId, fido_id: viewId } = dataset;

    if (!namespaceId || !viewId) return rejectWithValue('Unable to find namespace or view id');

    const dataSourceId = getDataSource({ namespaceId, type: 'embedded' }, fido);

    if (!dataSourceId) return rejectWithValue('Unable to find data source');

    let exportFormat: ExportFormat;
    switch (fileFormat) {
      case 'csv':
        exportFormat = ExportFormat.CSV;
        break;
      case 'xlsx':
        exportFormat = ExportFormat.XLSX;
        break;
      default:
        throw new Error(`Invalid file format: ${fileFormat}`);
    }

    const queryContext = embeddedReportBuilder.variables;
    const datasetSchemaMap = createDatasetSchemaMap(dataset.schema);

    const exportConfiguration = {
      fileName: sanitizeFileNameForExports(fileName),
      exportFormat: exportFormat,
      columnDisplayOptions: generateReportBuilderExportColumnOptions(
        computation.dataComputation,
        columnConfigs,
        datasetSchemaMap,
        exportFormat,
        {
          timezone: getReportBuilderTimezone(embeddedReportBuilder),
          locale: Settings.defaultLocale,
          currencyCode: embeddedReportBuilder.teamCurrencyCode,
        },
      ),
    };

    if (team?.feature_flags.use_rover) {
      return exportReportBuilderTabular({
        computationConfig: {
          dataSourceId,
          namespaceId,
          viewId,
          ...(computation.dataComputation as Computation),
          ...exportConfiguration,
        },
        state: getState(),
      });
    }

    return makeFidoThunkRequest(
      () =>
        QueryResourceService.exportView(dataSourceId, namespaceId, viewId, {
          queryContext,
          emailConfiguration: null,
          exportConfiguration,
          computation: computation.dataComputation,
        }),
      fido.fidoToken ?? '',
      'Error downloading your data panel',
    );
  },
);

/**
 * Fetches a table preview for a data source under a namespace.
 */
export const fetchFidoTablePreview = createAsyncThunk<
  QueryExecutionResponse,
  {
    viewId: string;
    dataSourceId: string;
    namespaceId: string;
    body: DataRequestParameters;
    onSuccess?: (data: QueryExecutionResponse) => void;
  },
  { state: ReduxState }
>(
  ACTION.FETCH_FIDO_TABLE_VIEW_PREVIEW,
  async ({ viewId, dataSourceId, namespaceId, body, onSuccess }, { getState }) => {
    const state = getState();

    return makeFidoThunkRequest(
      () =>
        QueryResourceService.runView(dataSourceId, namespaceId, viewId, {
          dataRequestParameters: body,
          computation: null,
          queryContext: {},
          requestExecutionParameters: null,
        }),
      state.fido.fidoToken ?? '',
      'Error loading table preview',
      onSuccess,
    );
  },
);
