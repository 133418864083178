import {
  ComputationConfig,
  ResponseBody,
  RoverResourceService,
  TabularEmailConfiguration,
} from '@explo/rover-api';
import { AnyAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import { ExportScreenshotType, ExportType } from 'actions/exportActions';
import { ACTION } from 'actions/types';
import { EmailCadence, ExportBody } from 'reportBuilderContent/exportTypes';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';

import { Computation } from '@explo-tech/fido-api';
import { ExportTargetConfiguration } from '@explo/rover-api';
import { getDataSource } from 'utils/fido/fidoRequestUtils';
import { makeRoverThunkRequest } from 'utils/thunkUtils';
import { fetchReportBuilderScreenshotPagesThunk } from './fetchScreenshotUrlThunks';
import {
  createScreenshotExportMetadata,
  getDataset,
  getEmailConfiguration,
  getReportBuilderLoggingMetadata,
  getReportIdParams,
  getScreenshotExportType,
  getTabularExportFormat,
} from './roverUtils';

import { CustomerReportView } from 'actions/customerReportActions';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';

import {
  createDatasetSchemaMap,
  generateReportBuilderComputations,
  generateReportBuilderExportColumnOptions,
  sanitizeFileNameForExports,
} from '@explo/computation-generation';
import { Settings } from 'luxon';
import { getViewDiffs } from 'reportBuilderContent/thunks/exportThunks';
import { mapCustomerReportRelativeFilters } from 'reportBuilderContent/thunks/utils';
import { FetchReportBuilderScreenshotPagesResponse } from './types';

export const sendReportBuilderTestDraftExportThunk = createAsyncThunk<
  ResponseBody,
  {
    exportBody: ExportBody;
  },
  { state: ReportBuilderReduxState }
>(
  ACTION.SEND_ROVER_REPORT_BUILDER_DRAFT_TEST_EMAIL,
  async ({ exportBody }, { getState, rejectWithValue, dispatch }) =>
    sendReportBuilderExport({ exportBody, state: getState(), dispatch }).catch((e) =>
      rejectWithValue(e),
    ),
);

export const sendReportBuilderTestExportThunk = createAsyncThunk<
  ResponseBody,
  {
    exportBody: EmailCadence;
  },
  { state: ReportBuilderReduxState }
>(
  ACTION.SEND_ROVER_REPORT_BUILDER_TEST_EMAIL,
  async ({ exportBody }, { getState, rejectWithValue, dispatch }) =>
    sendReportBuilderExport({ exportBody, state: getState(), dispatch }).catch((e) =>
      rejectWithValue(e),
    ),
);

const sendReportBuilderExport = async ({
  exportBody,
  state,
  dispatch,
}: {
  exportBody: ExportBody;
  state: ReportBuilderReduxState;
  dispatch: ThunkDispatch<ReportBuilderReduxState, unknown, AnyAction>;
}) => {
  const { embeddedReportBuilder, reportEditing, fido, analytics } = state;
  const { currentConfig, selectedReport } = reportEditing;
  const { team, teamCurrencyCode, requestInfo, variables, reports } = embeddedReportBuilder;

  const dataset = getDataset(
    embeddedReportBuilder.reportBuilderVersion?.config.datasets,
    currentConfig?.dataInfo?.datasetId,
  );
  const builtIns = embeddedReportBuilder.reportBuilderVersion?.config.builtInReports;

  if (!team || !currentConfig?.views || !dataset) throw new Error('No valid report');

  if (exportBody.export_type === ExportType.PDF || exportBody.export_type === ExportType.Image) {
    if (!requestInfo.embedId) throw new Error('No embed id found');

    const reportIdParam = getReportIdParams(selectedReport, requestInfo.envTagId);

    const { pages, rover_screenshot_token } = await dispatch(
      fetchReportBuilderScreenshotPagesThunk({
        embed_id: requestInfo.embedId,
        version_number: requestInfo.versionNumber,
        export_type: exportBody.export_type,
        variables,
        view_overrides: getViewDiffs(currentConfig, selectedReport, builtIns, reports),
        is_email: true,
        timezone: exportBody.timezone,
        dataset_id: dataset.id,
        built_in_id: reportIdParam?.built_in_id,
        customer_report_id: reportIdParam?.customer_report_id,
      }),
    ).unwrap();

    return makeRoverThunkRequest(
      () =>
        RoverResourceService.screenshotExport({
          pages,
          exportMetadata: createScreenshotExportMetadata(exportBody, team),
          loggingMetadata: getReportBuilderLoggingMetadata(analytics, team, selectedReport?.type),
        }),
      rover_screenshot_token,
      'Error downloading report builder screenshot',
    );
  } else {
    const { namespace_id: namespaceId, fido_id: viewId } = dataset;

    if (!namespaceId || !viewId) throw new Error('Unable to find namespace or view id');

    const dataSourceId = getDataSource({ namespaceId, type: 'embedded' }, fido);

    if (!dataSourceId) throw new Error('Unable to find data source');

    const computationConfigs = currentConfig.views.map((view) =>
      createComputationConfig({
        dataSourceId,
        namespaceId,
        viewId,
        view,
        dataset,
        exportBody,
        currencyCode: teamCurrencyCode,
      }),
    );

    return makeRoverThunkRequest(
      () =>
        RoverResourceService.tabularExport({
          loggingMetadata: getReportBuilderLoggingMetadata(
            analytics,
            team,
            reportEditing.selectedReport?.type,
          ),
          exportMetadata: {
            emailConfiguration: getEmailConfiguration(
              team,
              exportBody,
            ) as TabularEmailConfiguration,
            queryContext: embeddedReportBuilder.variables,
          },
          computationConfigs,
          jwt: fido.fidoToken ?? '',
        }),
      fido.roverTabularExportToken ?? '',
      'Error downloading your tabular report',
    );
  }
};

const createComputationConfig = ({
  dataSourceId,
  namespaceId,
  viewId,
  view,
  dataset,
  exportBody,
  currencyCode,
}: {
  dataSourceId: string;
  namespaceId: string;
  viewId: string;
  view: CustomerReportView;
  dataset: ReportBuilderDataset;
  exportBody: ExportBody;
  currencyCode: string | undefined;
}): ComputationConfig => {
  const groupings = (view.groupBys ?? []).concat(view.columnGroupBys ?? []).map((g) => ({
    column: g.column,
    bucket: g.bucket ? { id: g.bucket } : undefined,
  }));

  const computation = generateReportBuilderComputations(
    {
      aggs: view.aggregations || [],
      columns: view.columnOrder,
      filters: mapCustomerReportRelativeFilters(view.filters, exportBody.timezone),
      sort: view.sort || [],
      group_bys: groupings,
      hidden_columns: view.hiddenColumns,
    },
    dataset.customAggregations,
    exportBody.timezone,
  ).dataComputation;

  const exportFormat = getTabularExportFormat(exportBody.export_type);

  const exportConfiguration: ExportTargetConfiguration = {
    fileName: sanitizeFileNameForExports(view.name),
    exportFormat,
    columnDisplayOptions: generateReportBuilderExportColumnOptions(
      computation,
      dataset.columnConfigs,
      createDatasetSchemaMap(dataset.schema),
      exportFormat,
      { timezone: exportBody.timezone, currencyCode: currencyCode, locale: Settings.defaultLocale },
    ),
  };

  return {
    dataSourceId,
    namespaceId,
    viewId,
    ...(computation as Computation),
    ...exportConfiguration,
  };
};

export const exportReportBuilderTabular = ({
  computationConfig,
  state,
}: {
  computationConfig: ComputationConfig;
  state: ReportBuilderReduxState;
}) => {
  const { fido, embeddedReportBuilder, reportEditing, analytics } = state;
  const { team } = embeddedReportBuilder;

  if (!team) throw new Error('No team found');

  return makeRoverThunkRequest(
    () =>
      RoverResourceService.tabularExport({
        loggingMetadata: getReportBuilderLoggingMetadata(
          analytics,
          team,
          reportEditing.selectedReport?.type,
        ),
        exportMetadata: {
          emailConfiguration: null,
          queryContext: embeddedReportBuilder.variables,
        },
        computationConfigs: [computationConfig],
        jwt: fido.fidoToken ?? '',
      }),
    fido.roverTabularExportToken ?? '',
    'Error downloading tabular report',
  );
};

export const exportRoverReportBuilderScreenshotThunk = createAsyncThunk<
  ResponseBody,
  {
    export_type: ExportScreenshotType;
    timezone: string;
  },
  { state: ReportBuilderReduxState }
>(
  ACTION.EXPORT_ROVER_REPORT_BUILDER_SCREENSHOT,
  async (params, { dispatch, getState, rejectWithValue }) => {
    const { embeddedReportBuilder, reportEditing, analytics } = getState();
    const { currentConfig, selectedReport, currentView } = reportEditing;
    const { requestInfo, variables, team } = embeddedReportBuilder;

    if (!team) throw new Error('No team found');
    if (!requestInfo.embedId) throw new Error('No embed id found');

    const dataset = getDataset(
      embeddedReportBuilder.reportBuilderVersion?.config.datasets,
      currentConfig?.dataInfo?.datasetId,
    );
    const reportIdParam = getReportIdParams(selectedReport, requestInfo.envTagId);

    const exportView = currentConfig?.views?.find((v) => v.id === currentView);
    if (!exportView) throw new Error('Export report missing view');

    let response: FetchReportBuilderScreenshotPagesResponse | undefined;
    try {
      response = await dispatch(
        fetchReportBuilderScreenshotPagesThunk({
          embed_id: requestInfo.embedId,
          version_number: requestInfo.versionNumber,
          export_type: params.export_type,
          variables,
          view_overrides: [
            {
              ...exportView,
              filters: mapCustomerReportRelativeFilters(exportView.filters, params.timezone),
            },
          ],
          is_email: false,
          timezone: params.timezone,
          dataset_id: dataset.id,
          built_in_id: reportIdParam?.built_in_id,
          customer_report_id: reportIdParam?.customer_report_id,
        }),
      ).unwrap();
    } catch (error) {
      return rejectWithValue(error ?? 'Error fetching report builder export metadata');
    }

    const { pages, rover_screenshot_token } = response;

    return makeRoverThunkRequest(
      () =>
        RoverResourceService.screenshotExport({
          pages,
          exportMetadata: {
            exportFormat: getScreenshotExportType(params.export_type),
            imageScreenshotWidth: null,
            emailConfiguration: null,
          },
          loggingMetadata: getReportBuilderLoggingMetadata(
            analytics,
            team,
            reportEditing.selectedReport?.type,
          ),
        }),
      rover_screenshot_token,
      'Error downloading report builder screenshot',
    ).catch((e) => rejectWithValue(e));
  },
);
