import { useState, useMemo, useEffect } from 'react';
import { Tag, Button, Intent, sprinkles } from 'components/ds';
import BaseDataTable from 'components/dataTable/baseDataTable';
import { ComputedViewEnabledProducts } from './constants';
import { SelectedDropdownInputItem } from 'constants/types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataLibraryViewUsages } from 'reducers/thunks/dataLibraryThunks';
import { getCurrentResource } from 'pages/dataLibraryPage/selectors';
import { ReduxState } from 'reducers/rootReducer';
import { ComputedView } from '@explo-tech/fido-api';
import * as RD from 'remotedata';
import { VersionedResourceUsageInfo } from '@explo/embeddo-api';
import { DatasetDataObject } from 'actions/datasetActions';
import { BaseCol } from '@explo/rover-api';
import DropdownSelect from 'shared/DropdownSelect';
import UpdateResourceViewUsageModal from './UpdateResourceViewUsageModal';
import { v4 as uuidv4 } from 'uuid';

export const DatasetUsagesTab = () => {
  const dispatch = useDispatch();

  const { dashboardUsages, reportBuilderUsages } = useSelector((state: ReduxState) => {
    const viewUsages = state.dataLibrary.computedViewUsage;
    if (!RD.isSuccess(viewUsages)) {
      return { dashboardUsages: [], reportBuilderUsages: [] };
    }
    return {
      dashboardUsages: viewUsages.data.dashboards,
      reportBuilderUsages: viewUsages.data.reportBuilders,
    };
  });

  const [isModalOpen, setIsModalOpen] = useState(true);
  const [productSelection, setProductSelection] = useState<SelectedDropdownInputItem | undefined>();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const { viewId, viewVersionId } = useSelector((state: ReduxState) => {
    const resource = getCurrentResource(state);
    if (!RD.isSuccess(resource)) {
      return { viewId: '', viewVersionId: '' };
    }
    const computedView = resource.data as ComputedView;

    return { viewId: computedView.id, viewVersionId: computedView.versionId };
  });

  const productOptions = useMemo((): SelectedDropdownInputItem[] => {
    return Object.entries(ComputedViewEnabledProducts).map(([key, value]) => ({
      id: key,
      value: value,
      name: value,
    }));
  }, []);

  const tableSchema: BaseCol[] = useMemo(() => {
    const cols = [];
    cols.push({
      id: 'resourceId',
      name: 'Resource ID',
      type: 'string',
    });
    cols.push({
      id: 'resourceName',
      name: 'Resource Name',
      type: 'string',
    });
    return cols;
  }, []);

  const mostRecentViewVersionNumber = useMemo(() => {
    let maxDashboardVersionNumber = 0;
    if (dashboardUsages) {
      maxDashboardVersionNumber = dashboardUsages.reduce((acc, usage) => {
        return Math.max(acc, usage.versionNumber);
      }, 0);
    }
    let maxReportBuilderVersionNumber = 0;
    if (reportBuilderUsages) {
      maxReportBuilderVersionNumber = reportBuilderUsages.reduce((acc, usage) => {
        return Math.max(acc, usage.versionNumber);
      }, 0);
    }
    return Math.max(maxDashboardVersionNumber, maxReportBuilderVersionNumber);
  }, [dashboardUsages, reportBuilderUsages]);

  const { currentVersionDatasetData, outdatedVersionDatasetData } = useMemo(() => {
    const currentUsages: VersionedResourceUsageInfo[] = [];
    const outdatedUsages: VersionedResourceUsageInfo[] = [];

    if (dashboardUsages) {
      dashboardUsages.forEach((usage) => {
        if (usage.versionNumber === mostRecentViewVersionNumber) {
          currentUsages.push(usage);
        } else {
          outdatedUsages.push(usage);
        }
      });
    }
    if (reportBuilderUsages) {
      reportBuilderUsages.forEach((usage) => {
        if (usage.versionNumber === mostRecentViewVersionNumber) {
          currentUsages.push(usage);
        } else {
          outdatedUsages.push(usage);
        }
      });
    }

    const currentVersionDatasetData: DatasetDataObject = {
      data: {
        rows: currentUsages,
        schema: tableSchema,
        loading: false,
      },
    };
    const outdatedVersionDatasetData: DatasetDataObject = {
      data: {
        rows: outdatedUsages,
        schema: [],
        loading: false,
      },
    };
    return { currentVersionDatasetData, outdatedVersionDatasetData };
  }, [dashboardUsages, reportBuilderUsages, tableSchema, mostRecentViewVersionNumber]);

  useEffect(() => {
    if (viewId && viewVersionId) {
      dispatch(
        fetchDataLibraryViewUsages({
          request: {
            viewId,
            viewVersionId,
          },
        }),
      );
    }
  }, [viewId, viewVersionId, dispatch]);

  const versionOptions = useMemo(() => {
    return [
      { value: 'Version 1', id: uuidv4(), number: 1 },
      { value: 'Version 2', id: uuidv4(), number: 2 },
      { value: 'Version 3', id: uuidv4(), number: 3 },
    ];
  }, []);

  return (
    <>
      <div className={sprinkles({ flexItems: 'column', gap: 'sp2', marginBottom: 'sp2' })}>
        <div className={sprinkles({ display: 'flex', gap: 'sp1' })}>
          <div className={sprinkles({ heading: 'h2' })}>Surfaces using current version</div>
          <Tag intent={Intent.ACTIVE}>Version {mostRecentViewVersionNumber}</Tag>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            disabled={false /* replace with actual condition */}
            onClick={() => setIsModalOpen(true)}>
            Demote to previous version
          </Button>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <DropdownSelect
              containerClassName={sprinkles({ width: 'fill', borderRadius: 8 })}
              onChange={(value) => {
                setProductSelection(value);
              }}
              options={productOptions}
              selectedItem={productSelection}
            />
          </div>
        </div>
        <BaseDataTable
          className={sprinkles({ borderRadius: 8 })}
          datasetData={currentVersionDatasetData} /* replace with actual data */
          datasetNamesToId={{}}
          isSortable={false} /* replace with actual value */
          maxRows={10} /* replace with actual value */
          onRowSelection={(row) => {
            if (row) {
              selectedRows.push(row.id as string);
              setSelectedRows(selectedRows);
            }
          }} /* replace with actual data */
          rows={[]} /* replace with actual data */
          schema={tableSchema} /* replace with actual schema */
          variables={{}} /* replace with actual data */
        />
      </div>
      <div className={sprinkles({ flexItems: 'column', gap: 'sp2' })}>
        <div className={sprinkles({ heading: 'h2', display: 'flex', gap: 'spn1' })}>
          Surfaces using past version
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            disabled={false /* replace with actual condition */}
            onClick={() => setIsModalOpen(true)}>
            Promote to current version
          </Button>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <DropdownSelect
              usePortal
              containerClassName={sprinkles({ width: 'fill' })}
              onChange={(value) => {
                setProductSelection(value);
              }}
              options={productOptions}
              selectedItem={productSelection}
            />
          </div>
        </div>
        <BaseDataTable
          datasetData={outdatedVersionDatasetData} /* replace with actual data */
          datasetNamesToId={{}}
          isSortable={false} /* replace with actual value */
          maxRows={10} /* replace with actual value */
          onRowSelection={(row) => {
            if (row) {
              selectedRows.push(row.id as string);
              setSelectedRows(selectedRows);
            }
          }} /* replace with actual data */
          rows={[]} /* replace with actual data */
          schema={tableSchema} /* replace with actual schema */
          variables={{}} /* replace with actual data */
        />
      </div>
      {isModalOpen ? (
        <UpdateResourceViewUsageModal
          availableVersions={versionOptions.map((option) => option.number)}
          currentVersion={mostRecentViewVersionNumber}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          selectedResourceIds={['fake', 'fake-again']}
        />
      ) : null}
    </>
  );
};
