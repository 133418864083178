import { Theme, makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { DatasetSchema, FILTER_OPS_NO_VALUE, FilterClause } from '@explo/data';

import {
  deleteFilterClause,
  selectFilterColumn,
  selectFilterOperator,
} from 'actions/dataPanelConfigActions';
import { Dataset } from 'actions/datasetActions';
import { IconButton, sprinkles, vars } from 'components/ds';
import { OperatorDropdown } from 'pages/dashboardPage/DashboardDatasetView/Header/OperatorDropdown';
import { DashboardElement } from 'types/dashboardTypes';
import { DashboardParam } from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';

import { ColumnOptionsPopover } from '../vizConfigs/droppable/ColumnOptionsPopover';
import * as styles from '../vizConfigs/droppable/styles.css';

import { ConditionalFilterConfigMenu } from './ConditionalFilterConfigMenu';
import { ConditionalFilterMenu } from './ConditionalFilterMenu';
import { FilterConfigMenu } from './FilterConfigMenu';
import { filterColumnContainerClassName } from './FilterColumn.css';

const useStyles = makeStyles((theme: Theme) => ({
  operatorDropdown: {
    '& .bp3-button': {
      width: '100%',
      backgroundColor: `${vars.colors.blue3} !important`,
      borderRadius: 3,
      border: 'none !important',
      padding: `0px !important`,
      color: vars.colors.blue9,
      '&:disabled': {
        backgroundColor: `${vars.colors.gray7} !important`,
        color: vars.colors.contentSecondary,
      },
    },
    '& .bp3-icon': {
      color: vars.colors.blue9,
      padding: `0px 8px !important`,
    },
    '& .bp3-button-text': {
      fontWeight: 500,
      marginLeft: theme.spacing(2),
      width: '100%',
    },
  },
}));

type Props = {
  clause: FilterClause;
  clauseIdx: number;
  dashboardElements: DashboardElement[];
  dashboardParams: Record<string, DashboardParam>;
  datasets: Record<string, Dataset>;
  dpsWithDrilldown: DataPanelTemplate[];
  schema: DatasetSchema;
  selectableKPIs: DataPanelTemplate[];
};

export const DroppedFilterColumn: FC<Props> = ({
  clause,
  clauseIdx,
  dashboardElements,
  dashboardParams,
  dpsWithDrilldown,
  schema,
  selectableKPIs,
  datasets,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const showFilterConfigMenu =
    !clause.filterOperation || !FILTER_OPS_NO_VALUE.has(clause.filterOperation.id);

  const showConditionalMenu = clause.filterOperation && !!selectableKPIs.length;
  const isConditionalFilter =
    showConditionalMenu && !!clause.conditionalFilterConfig?.isConditional;

  return (
    <div className={filterColumnContainerClassName}>
      <div className={sprinkles({ flexItems: 'column', flex: 1, overflow: 'hidden', gap: 'sp.5' })}>
        <div className={sprinkles({ display: 'flex', flex: 1, gap: 'sp.5' })}>
          <div className={sprinkles({ overflow: 'hidden', width: 'fill' })}>
            <ColumnOptionsPopover
              transparentIcon
              className={columnsPopoverTargetContainerClass}
              displayName={clause.filterColumn?.name}
              onColChange={(column) => dispatch(selectFilterColumn({ index: clauseIdx, column }))}
              schema={schema}
            />
          </div>
          <OperatorDropdown
            usePortal
            className={classes.operatorDropdown}
            onOperatorSelect={(operator) =>
              dispatch(selectFilterOperator({ index: clauseIdx, operator }))
            }
            selectedColumn={clause.filterColumn}
            selectedOperator={clause.filterOperation?.id}
          />
        </div>
        {showFilterConfigMenu ? (
          <FilterConfigMenu
            clause={clause}
            clauseIdx={clauseIdx}
            dashboardElements={dashboardElements}
            dashboardParams={dashboardParams}
            dataPanels={dpsWithDrilldown}
            datasets={datasets}
            operatorDropdownClass={classes.operatorDropdown}
          />
        ) : null}
        {showConditionalMenu ? (
          <ConditionalFilterMenu
            clause={clause}
            clauseIdx={clauseIdx}
            operatorDropdownClass={classes.operatorDropdown}
          />
        ) : null}
        {isConditionalFilter ? (
          <ConditionalFilterConfigMenu
            clause={clause}
            clauseIdx={clauseIdx}
            panels={selectableKPIs}
          />
        ) : null}
      </div>
      <IconButton
        className={cx(styles.iconBtn, sprinkles({ marginLeft: 'sp.5' }))}
        name="cross"
        onClick={() => dispatch(deleteFilterClause(clauseIdx))}
      />
    </div>
  );
};

const columnsPopoverTargetContainerClass = sprinkles({
  backgroundColor: 'blue3',
  flex: 1,
  flexItems: 'alignCenterBetween',
  overflow: 'hidden',
});
