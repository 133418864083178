import { getTimeZones } from '@vvo/tzdb';
import { groupBy } from 'utils/standard';

/**
 * Retrieves a formatted list of timezone options for use in select components.
 *
 * @returns An array of timezone options with formatted labels and values.
 * Each option contains:
 *   - label: A formatted string with GMT offset, timezone name, and region (if needed for disambiguation)
 *   - value: The canonical timezone name (e.g., "America/New_York")
 */

const getTimezoneOptions = () => {
  const raw_timezones = getTimeZones({ includeUtc: true });
  const timezones_by_alternative_name = groupBy(
    raw_timezones,
    (timezone) => timezone.alternativeName,
  );
  return raw_timezones
    .sort((a, b) => a.rawOffsetInMinutes - b.rawOffsetInMinutes)
    .map((timezone) => {
      // timezone.name is always something like Country/More Specific/(optional) More Specific.
      // We just want the last, most specific part
      const region = timezone.name.split('/').pop()?.replace('_', ' ');
      // if there are multiple timezones with this alternative name (like Eastern Time),
      // then we want to also show the more specific region for this option
      const label =
        `(GMT${timezone.rawFormat.split(' ')[0]}) ${timezone.alternativeName}` +
        (timezones_by_alternative_name[timezone.alternativeName].length > 1 ? ` - ${region}` : '');
      return {
        label,
        value: timezone.name,
      };
    });
};

export const TIMEZONE_SELECT_OPTIONS = getTimezoneOptions();

export const BROWSER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
