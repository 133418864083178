import { FC } from 'react';

import { Select, sprinkles } from 'components/ds';
import { EmailCadenceTime } from 'constants/emailFrequency';
import { TIMEZONE_SELECT_OPTIONS, BROWSER_TIMEZONE } from 'constants/timezones';

const HOUR_OPTIONS = Array.from(Array(12).keys(), (time) => time + 1).map((hour) => ({
  value: String(hour),
}));
const MINUTE_OPTIONS = Array.from(Array(60).keys()).map((minute) => ({
  value: String(minute),
  label: `0${minute}`.slice(-2),
}));

type Props = {
  emailCadenceTime: EmailCadenceTime;
  onSetTime: (time: EmailCadenceTime) => void;
};

export const EmailTimeSelection: FC<Props> = ({ emailCadenceTime, onSetTime }) => {
  return (
    <div className={sprinkles({ display: 'flex', alignItems: 'flex-end' })}>
      <Select
        label="Time"
        onChange={(newHour) => onSetTime({ ...emailCadenceTime, hour: Number(newHour) })}
        placeholder="HH"
        selectedValue={emailCadenceTime?.hour?.toString()}
        values={HOUR_OPTIONS}
      />
      <Select
        onChange={(newMin) => onSetTime({ ...emailCadenceTime, minute: Number(newMin) })}
        placeholder="MM"
        selectedValue={emailCadenceTime?.minute?.toString()}
        values={MINUTE_OPTIONS}
      />
      <Select
        onChange={(val) => onSetTime({ ...emailCadenceTime, isPm: val === 'PM' })}
        selectedValue={emailCadenceTime?.isPm ? 'PM' : 'AM'}
        values={[{ value: 'AM' }, { value: 'PM' }]}
      />
      <Select
        className={sprinkles({ flex: 1, marginLeft: 'sp1' })}
        onChange={(newTz) => onSetTime({ ...emailCadenceTime, timezone: newTz })}
        selectedValue={emailCadenceTime.timezone ?? BROWSER_TIMEZONE}
        values={TIMEZONE_SELECT_OPTIONS}
      />
    </div>
  );
};
