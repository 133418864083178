import type {
  OneTimeScheduleDto,
  RecurringScheduleDto,
  ScheduledEmailExportDto,
} from '@explo/embeddo-api';
import { CronExpressionParser } from 'cron-parser';
import { isValidCron } from 'cron-validator';
import cronstrue from 'cronstrue';
import { flattenRecipientFilter } from 'features/dataShare/recipients/recipientsFilterShims';
import { RecipientFilterState } from './types';
import {
  ALL_CADENCES,
  ALL_STATUSES,
  EXPORT_SORT_OPTION,
  ExportSortOption,
  SCHEDULE_FREQUENCY,
  SCHEDULE_TYPE,
  ScheduleFrequency,
} from './constants';
import { FilterState } from './types';

export const formatCadence = (
  schedule: RecurringScheduleDto | OneTimeScheduleDto | null,
): string => {
  if (!schedule) return '--';

  if (!isValidCron(schedule.cron)) return '--';

  if (schedule['@type'] === SCHEDULE_TYPE.RECURRING) {
    return cronstrue.toString(schedule.cron, { use24HourTimeFormat: false });
  }

  return 'One time';
};

export const formatUpcoming = (
  schedule: RecurringScheduleDto | OneTimeScheduleDto | null,
): string => {
  if (!schedule) return '--';

  if (!isValidCron(schedule.cron)) {
    // TODO: handle errors around !isValidCron here and in other places
    return '--';
  }
  const nextExecution = CronExpressionParser.parse(schedule.cron).next().toDate();
  return nextExecution.toLocaleString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};

export const getNextExecutionTime = (
  schedule: RecurringScheduleDto | OneTimeScheduleDto | null,
): Date | null => {
  if (!schedule) return null;

  if (!isValidCron(schedule.cron)) return null;

  const interval = CronExpressionParser.parse(schedule.cron);
  return interval.next().toDate();
};

export const sortExports = (
  exports: ScheduledEmailExportDto[],
  sortOption: ExportSortOption,
): ScheduledEmailExportDto[] => {
  return [...exports].sort((a, b) => {
    if (sortOption === EXPORT_SORT_OPTION.LAST_EDITED) {
      return new Date(b.lastEditedAt).getTime() - new Date(a.lastEditedAt).getTime();
    }

    const nextA = getNextExecutionTime(a.schedule);
    const nextB = getNextExecutionTime(b.schedule);

    if (!nextA) return 1;
    if (!nextB) return -1;
    return nextA.getTime() - nextB.getTime();
  });
};

export const categorizeCronExpression = (cronExpression: string): ScheduleFrequency => {
  // Daily: runs at the same time every day
  const dailyPattern = /^0 \d{1,2} \* \* \*$/;

  // Weekly: runs at the same time on the same day of the week
  const weeklyPattern = /^0 \d{1,2} \* \* [0-6]$/;

  // Monthly: runs at the same time on the same day of the month
  const monthlyPattern = /^0 \d{1,2} \d{1,2} \* \*$/;

  if (dailyPattern.test(cronExpression)) {
    return SCHEDULE_FREQUENCY.DAILY;
  }

  if (weeklyPattern.test(cronExpression)) {
    return SCHEDULE_FREQUENCY.WEEKLY;
  }

  if (monthlyPattern.test(cronExpression)) {
    return SCHEDULE_FREQUENCY.MONTHLY;
  }

  return SCHEDULE_FREQUENCY.CUSTOM;
};

type FilterOptions = {
  searchTerm?: string;
  filters?: FilterState;
};

export const filterExports = (
  exports: ScheduledEmailExportDto[],
  options: FilterOptions,
): ScheduledEmailExportDto[] => {
  const { searchTerm, filters } = options;

  return exports.filter((exp) => {
    // Search term filter
    const matchesSearchTerm =
      !searchTerm || exp.name.toLowerCase().includes(searchTerm.toLowerCase());

    const appliesAllRecipientsFilter = filters
      ? getAppliesAllRecipientsFilter(filters.recipients)
      : false;
    const appliesSpecificRecipientsFilter = filters
      ? getAppliesSpecificRecipientsFilter(filters.recipients)
      : false;

    // If no filters are applied or filters are empty
    if (
      !filters ||
      (filters.cadences.size === 0 &&
        filters.statuses.size === 0 &&
        !appliesAllRecipientsFilter &&
        !appliesSpecificRecipientsFilter)
    ) {
      return matchesSearchTerm;
    }

    // Cadence filter
    const matchesCadence =
      filters.cadences.size === 0 ||
      (exp.schedule?.['@type'] === SCHEDULE_TYPE.ONE_TIME &&
        filters.cadences.has(SCHEDULE_FREQUENCY.ONE_TIME)) ||
      (exp.schedule?.['@type'] === SCHEDULE_TYPE.RECURRING &&
        filters.cadences.has(categorizeCronExpression(exp.schedule.cron)));

    // Status filter - convert the API status to our enum value for comparison
    const matchesStatus = filters.statuses.size === 0 || filters.statuses.has(exp.status);

    // Recipients filter

    // We include all recipients in the case of no filters applies, or we explicitly include all recipients
    // If we only apply specific recipients filter, we exclude all recipients
    const matchesAllRecipients = appliesAllRecipientsFilter
      ? exp.recipients.recipientsFilter === null
      : appliesSpecificRecipientsFilter
        ? exp.recipients.recipientsFilter !== null
        : true;

    let matchesSpecificRecipients = true;

    if (appliesSpecificRecipientsFilter) {
      const specificFilters = filters.recipients.specific;

      const exportRecipients = flattenRecipientFilter(exp.recipients.recipientsFilter);

      if (exportRecipients) {
        const hierarchyMatch =
          specificFilters?.hierarchyIds?.length && specificFilters.hierarchyIds.length > 0
            ? specificFilters.hierarchyIds.every((id) => exportRecipients.hierarchyIds.includes(id))
            : true;

        const groupTagMatch =
          specificFilters?.groupTagIds?.length && specificFilters.groupTagIds.length > 0
            ? specificFilters.groupTagIds.every((id) => exportRecipients.groupTagIds.includes(id))
            : true;

        const customerMatch =
          specificFilters?.customerIds?.length && specificFilters.customerIds.length > 0
            ? specificFilters.customerIds.every((id) => exportRecipients.customerIds.includes(id))
            : true;

        matchesSpecificRecipients = hierarchyMatch && groupTagMatch && customerMatch;
      }
    }

    return (
      matchesSearchTerm &&
      matchesCadence &&
      matchesStatus &&
      matchesAllRecipients &&
      matchesSpecificRecipients
    );
  });
};

const getAppliesAllRecipientsFilter = (recipientsFilter: RecipientFilterState): boolean => {
  return recipientsFilter.all === true;
};

const getAppliesSpecificRecipientsFilter = (recipientsFilter: RecipientFilterState): boolean => {
  return (
    recipientsFilter.specific !== null &&
    (!!recipientsFilter.specific.hierarchyIds?.length ||
      !!recipientsFilter.specific.groupTagIds?.length ||
      !!recipientsFilter.specific.customerIds?.length)
  );
};

export const toggleSetValue = <T>(set: Set<T>, value: T): Set<T> => {
  const newSet = new Set(set);
  if (newSet.has(value)) {
    newSet.delete(value);
  } else {
    newSet.add(value);
  }
  return newSet;
};

export const getAppliedFiltersCount = (filters: FilterState): number => {
  let count = 0;
  if (filters.cadences.size > 0 && filters.cadences.size < ALL_CADENCES.length) {
    count += 1;
  }
  if (filters.statuses.size > 0 && filters.statuses.size < ALL_STATUSES.length) {
    count += 1;
  }

  if (
    getAppliesAllRecipientsFilter(filters.recipients) ||
    getAppliesSpecificRecipientsFilter(filters.recipients)
  ) {
    count += 1;
  }
  return count;
};
