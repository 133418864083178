import { FC } from 'react';

import { Button, IconName, Select, SelectItems, Tag } from 'components/ds';

import * as styles from './styles.css';

type FilterProps = {
  isLoading: boolean;
  placeholder: string;
  onFilter: (searchString: string) => void;
  selectedLabel: string;
};

type TagProps = {
  key: string;
  label: string;
  onClose: () => void;
  leftIcon: IconName;
};

type Props = {
  label: string;
  placeholder: string;
  values: SelectItems<string>;
  selectedValue?: string;
  onSelect: (value: string) => void;
  onAdd: () => void;
  addDisabled?: boolean;
  disabled?: boolean;
  tags: TagProps[];
  filterProps?: FilterProps;
};

export const SelectorSection: FC<Props> = ({
  label,
  placeholder,
  values,
  selectedValue,
  onSelect,
  onAdd,
  addDisabled,
  disabled,
  tags,
  filterProps,
}) => (
  <div className={styles.selectionSection}>
    <div className={styles.selectControls}>
      <Select
        className={styles.select}
        disabled={disabled}
        filterProps={filterProps}
        label={{ text: label }}
        onChange={onSelect}
        placeholder={placeholder}
        selectedValue={selectedValue}
        values={values}
      />
      <Button disabled={addDisabled} onClick={onAdd}>
        Add
      </Button>
    </div>
    {tags.length > 0 ? (
      <div className={styles.selectTags}>
        {tags.map(({ key, label, onClose, leftIcon }) => (
          <Tag intent="active" key={key} leftIcon={leftIcon} onClose={onClose}>
            {label}
          </Tag>
        ))}
      </div>
    ) : null}
  </div>
);
