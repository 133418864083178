import { sendPing } from 'actions/pingActions';
import { PingTypes } from 'constants/pingTypes';
import { UNKNOWN_TEAM_NAME } from 'constants/teamConstants';
import {
  getCommitCreatedMessage,
  getCommitFailedMessage,
  getCommitSucceededMessage,
} from 'pages/dataLibraryPage/pingMessages';
import { ReduxState } from 'reducers/rootReducer';
import { createCommitThunk } from 'reducers/thunks/fidoThunks/commitThunks';
import { Action, AnyAction, Dispatch, Middleware } from 'redux';

export const dataLibraryLoggingMiddleware: Middleware<{}, ReduxState> =
  ({ getState, dispatch }) =>
  (next: Dispatch<Action>) =>
  (action: AnyAction) => {
    next(action);

    const currentUser = getState().currentUser;
    const teamName = currentUser.team?.team_name ?? UNKNOWN_TEAM_NAME;

    if (LOGGED_ACTION_TYPES.has(action.type)) {
      const typedAction = action as ReturnType<typeof createCommitThunk.fulfilled>;
      const branchName = typedAction.meta.arg.branchName;
      const userName = currentUser.first_name + ' ' + currentUser.last_name;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dispatchAny = dispatch as Dispatch<any>;
      dispatchAny(
        sendPing({
          postData: {
            message_type: PingTypes.PING_GLOBAL_DATASETS,
            message: getCommitMessage(action.type, userName, teamName, branchName),
          },
        }),
      );
    }
  };

const LOGGED_ACTION_TYPES: Set<string> = new Set([
  createCommitThunk.fulfilled.type,
  createCommitThunk.rejected.type,
  createCommitThunk.pending.type,
]);

const getCommitMessage = (
  actionType: string,
  userName: string,
  teamName: string,
  branchName: string,
): string => {
  if (actionType === createCommitThunk.fulfilled.type) {
    return getCommitSucceededMessage(userName, teamName, branchName);
  } else if (actionType === createCommitThunk.rejected.type) {
    return getCommitFailedMessage(userName, teamName, branchName);
  } else if (actionType === createCommitThunk.pending.type) {
    return getCommitCreatedMessage(userName, teamName, branchName);
  }

  throw new Error('Invalid action type');
};
