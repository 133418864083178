import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import cx from 'classnames';
import { FC, MutableRefObject, useState, useMemo, useCallback, MouseEvent, Fragment } from 'react';

import { sprinkles } from 'components/ds';
import { EmbedInput } from 'components/embed';
import { DropdownOption } from 'components/resource/EmbeddedDropdownMenu/DropdownOption';
import { EmbeddedDropdownMenuItem } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenuItem';
import { EmbeddedDropdownMenuLabel } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenuLabel';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { groupBy } from 'utils/standard';
import { fullTextSearch } from 'utils/stringUtils';

import * as styles from './styles.css';
import { replaceVariablesInString } from 'utils/variableUtils';
import { useSelector } from 'react-redux';
import { AllStates } from 'reducers/rootReducer';

interface Props {
  align?: 'start' | 'end';
  side?: 'top' | 'right' | 'left' | 'bottom';
  disabled?: boolean;
  menuOptions: DropdownOption[];
  open?: boolean;
  onClick?: (value: string) => void; // Can be overwritten at option level
  onOpenChange?: (open: boolean) => void; // https://github.com/radix-ui/primitives/issues/1241
  fillContainer?: boolean; // If true, resizes to the width of the trigger
  canSearch?: boolean; // If true, renders a search bar to filter options
  className?: string; // If supplied, overwrites styles applied to menu itself

  // Ref for the parent container to create a boundary for the Radix dropdown menu
  containerRef?: MutableRefObject<HTMLElement | null>;
  portalRef?: MutableRefObject<HTMLElement | null>;
}

export const EmbeddedDropdownMenu: FC<Props> = ({
  open,
  children,
  disabled,
  menuOptions,
  onOpenChange,
  onClick,
  containerRef,
  fillContainer,
  canSearch,
  align,
  portalRef,
  side,
  className,
}) => {
  const [openInternal, setOpenInternal] = useState(false);
  const [search, setSearch] = useState('');

  const variables = useSelector((state: AllStates) =>
    'embeddedReportBuilder' in state
      ? state.embeddedReportBuilder.variables
      : 'dashboardData' in state
        ? state.dashboardData.variables
        : {},
  );
  const filteredOptions: [string, DropdownOption[]][] = useMemo(() => {
    const options = fullTextSearch(search, menuOptions, (option) => option.name).map((option) => {
      return {
        ...option,
        name: replaceVariablesInString(option.name, variables),
      };
    });
    const groupedOptions = groupBy(options, (option) => option.group || '');
    return Object.entries(groupedOptions);
  }, [menuOptions, search, variables]);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      onOpenChange?.(open);
      setOpenInternal(open);
    },
    [onOpenChange],
  );

  const handleClickItem = useCallback(
    (option: DropdownOption, e: MouseEvent<HTMLDivElement>) => {
      if (option.disabled) return;
      if (option.onClick) option.onClick(e);
      else onClick?.(option.value ?? option.name);
      handleOpenChange(false); // Close the popover when a value is selected
    },
    [handleOpenChange, onClick],
  );

  const content = (
    <DropdownMenu.Content
      align={(align ?? fillContainer) ? 'start' : 'end'}
      className={cx(styles.dropdownContainer, fillContainer && styles.fillContainer, className)}
      collisionBoundary={containerRef?.current}
      onInteractOutside={() => setOpenInternal(false)}
      side={side}
      sideOffset={8}
      updatePositionStrategy="always">
      {canSearch ? (
        <div className={styles.searchContainer}>
          <EmbedInput
            autoFocus
            fillWidth
            leftIcon="search"
            onChange={setSearch}
            // Use this so that menu items don't take focus when typing
            onKeyDown={(e) => e.stopPropagation()}
            placeholder="Search"
            type="text"
            value={search}
          />
        </div>
      ) : null}
      {filteredOptions.map(([group, options]) => (
        <Fragment key={group || '_group'}>
          {group ? (
            <EmbeddedDropdownMenuLabel key={group}>{group}</EmbeddedDropdownMenuLabel>
          ) : null}
          {options.map((option) => (
            <EmbeddedDropdownMenuItem key={option.name} onClick={handleClickItem} option={option} />
          ))}
        </Fragment>
      ))}
      {canSearch && filteredOptions.length === 0 ? (
        <div className={sprinkles({ paddingX: 'sp2', paddingY: 'sp1' })}>
          <EmbedText body="b1" color="contentSecondary">
            No results.
          </EmbedText>
        </div>
      ) : null}
    </DropdownMenu.Content>
  );
  return (
    <DropdownMenu.Root onOpenChange={handleOpenChange} open={open ?? openInternal}>
      <DropdownMenu.Trigger asChild disabled={disabled}>
        {children}
      </DropdownMenu.Trigger>
      {portalRef ? (
        <DropdownMenu.Portal container={portalRef.current}>{content}</DropdownMenu.Portal>
      ) : (
        content
      )}
    </DropdownMenu.Root>
  );
};
