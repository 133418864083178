import { BaseCol } from '../columnTypes';
import {
  DEFAULT_DATE_RANGES,
  DEFAULT_DATE_RANGES_SET,
  RELATIVE_DATE_OPTIONS,
  RELATIVE_DATE_OPTIONS_SET,
} from '../dateRangeTypes';

export enum DATE_RELATIVE_OPTION {
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
}

export type ConditionalFilterConfig = {
  // If undefined or false then its constant
  isConditional?: boolean;
  // List of KPIs that if selected enable this filter
  chartsConditionalOn?: string[];
};

export interface FilterOperationInstructions {
  matchOnAll: boolean;
  filterClauses: FilterClause[];
}

export type FilterValueDateType = {
  startDate?: string;
  endDate?: string;
};

export type FilterValueRelativeDateType = {
  number?: number;
  relativeTimeType?: { id: DATE_RELATIVE_OPTION };
};

export type FilterValueNumberRangeType = {
  min?: number;
  max?: number;
};

export type FilterValueMultiSelectType = string[] | number[];

export type FilterValueType =
  | string
  | number
  | undefined
  | FilterValueMultiSelectType
  | FilterValueDateType
  | FilterValueRelativeDateType
  | FilterValueNumberRangeType
  | RELATIVE_DATE_OPTIONS
  | DEFAULT_DATE_RANGES;

export enum FilterValueSourceType {
  INPUT = 'INPUT',
  VARIABLE = 'VARIABLE',
}

export interface FilterClause {
  filterColumn?: BaseCol;
  filterOperation?: { id: FilterOperator };
  filterValue: FilterValueType;
  filterValueSource?: FilterValueSourceType;
  filterValueVariableId?: string;
  filterValueVariableProperty?: string;
  conditionalFilterConfig?: ConditionalFilterConfig;
}

export enum FilterOperator {
  STRING_IS = 'STRING_IS',
  STRING_IS_NOT = 'STRING_IS_NOT',
  STRING_IS_IN = 'STRING_IS_IN',
  STRING_IS_NOT_IN = 'STRING_IS_NOT_IN',
  STRING_CONTAINS = 'STRING_CONTAINS',
  STRING_DOES_NOT_CONTAIN = 'STRING_DOES_NOT_CONTAIN',
  NUMBER_EQ = 'NUMBER_EQ',
  NUMBER_NEQ = 'NUMBER_NEQ',
  NUMBER_LT = 'NUMBER_LT',
  NUMBER_GT = 'NUMBER_GT',
  NUMBER_LTE = 'NUMBER_LTE',
  NUMBER_GTE = 'NUMBER_GTE',
  NUMBER_IS_IN = 'NUMBER_IS_IN',
  NUMBER_IS_NOT_IN = 'NUMBER_IS_NOT_IN',
  NUMBER_IS_BETWEEN = 'NUMBER_IS_BETWEEN',
  DATE_IS = 'DATE_IS',
  DATE_IS_NOT = 'DATE_IS_NOT',
  DATE_IS_BETWEEN = 'DATE_IS_BETWEEN',
  DATE_LT = 'DATE_LT',
  DATE_LTE = 'DATE_LTE',
  DATE_GT = 'DATE_GT',
  DATE_GTE = 'DATE_GTE',
  DATE_PREVIOUS = 'DATE_PREVIOUS',
  DATE_NEXT = 'DATE_NEXT',
  DATE_TODAY = 'DATE_TODAY',
  BOOLEAN_IS_TRUE = 'BOOLEAN_IS_TRUE',
  BOOLEAN_IS_FALSE = 'BOOLEAN_IS_FALSE',
  BOOLEAN_IS = 'BOOLEAN_IS',
  IS_EMPTY = 'IS_EMPTY',
  IS_NOT_EMPTY = 'IS_NOT_EMPTY',
}

export const FILTER_OPS_NO_VALUE = new Set([
  FilterOperator.BOOLEAN_IS_TRUE,
  FilterOperator.BOOLEAN_IS_FALSE,
  FilterOperator.BOOLEAN_IS,
  FilterOperator.IS_EMPTY,
  FilterOperator.IS_NOT_EMPTY,
  FilterOperator.DATE_TODAY,
]);

export const FILTER_OPS_DATE_PICKER = new Set([
  FilterOperator.DATE_IS,
  FilterOperator.DATE_IS_NOT,
  FilterOperator.DATE_LT,
  FilterOperator.DATE_LTE,
  FilterOperator.DATE_GT,
  FilterOperator.DATE_GTE,
]);

export const FILTER_OPS_DATE_RANGE_PICKER = new Set([FilterOperator.DATE_IS_BETWEEN]);

export const FILTER_OPS_RELATIVE_PICKER = new Set([
  FilterOperator.DATE_PREVIOUS,
  FilterOperator.DATE_NEXT,
]);

export const FILTER_OPS_MULTISELECT = new Set([
  FilterOperator.STRING_IS_IN,
  FilterOperator.STRING_IS_NOT_IN,
  FilterOperator.NUMBER_IS_IN,
  FilterOperator.NUMBER_IS_NOT_IN,
]);

export const FILTER_OPS_STRING = new Set([
  FilterOperator.STRING_IS,
  FilterOperator.STRING_IS_NOT,
  FilterOperator.STRING_CONTAINS,
  FilterOperator.STRING_DOES_NOT_CONTAIN,
]);

export const FILTER_OPS_NUMBER = new Set([
  FilterOperator.NUMBER_EQ,
  FilterOperator.NUMBER_GT,
  FilterOperator.NUMBER_GTE,
  FilterOperator.NUMBER_LT,
  FilterOperator.NUMBER_LTE,
  FilterOperator.NUMBER_NEQ,
]);

export const FILTER_OPS_NUMBER_RANGE = new Set([FilterOperator.NUMBER_IS_BETWEEN]);

export const FILTER_OPS_EQ = new Set([
  FilterOperator.STRING_IS,
  FilterOperator.NUMBER_EQ,
  FilterOperator.DATE_IS,
  FilterOperator.NUMBER_NEQ,
  FilterOperator.STRING_IS_NOT,
  FilterOperator.DATE_IS_NOT,
  FilterOperator.DATE_TODAY,
]);

export const FILTER_OPS_BOOLEAN = new Set([
  FilterOperator.BOOLEAN_IS_FALSE,
  FilterOperator.BOOLEAN_IS_TRUE,
  FilterOperator.BOOLEAN_IS,
]);

export const FILTER_OPS_IN = new Set([
  FilterOperator.STRING_IS_IN,
  FilterOperator.STRING_IS_NOT_IN,
  FilterOperator.NUMBER_IS_IN,
  FilterOperator.NUMBER_IS_NOT_IN,
]);

export const FILTER_OPS_SINGLE_VALUE_RANGE = new Set([
  FilterOperator.DATE_LT,
  FilterOperator.DATE_LTE,
  FilterOperator.DATE_GT,
  FilterOperator.DATE_GTE,
  FilterOperator.NUMBER_GT,
  FilterOperator.NUMBER_GTE,
  FilterOperator.NUMBER_LT,
  FilterOperator.NUMBER_LTE,
  FilterOperator.DATE_NEXT,
  FilterOperator.DATE_PREVIOUS,
]);

export const FILTER_OPS_MULTI_VALUE_RANGE = new Set([
  FilterOperator.NUMBER_IS_BETWEEN,
  FilterOperator.DATE_IS_BETWEEN,
]);

export const FILTER_OPS_EMPTY = new Set([FilterOperator.IS_EMPTY, FilterOperator.IS_NOT_EMPTY]);

export const FILTER_OPS_CONTAINS = new Set([
  FilterOperator.STRING_CONTAINS,
  FilterOperator.STRING_DOES_NOT_CONTAIN,
]);

export const FILTER_OPS_NEGATED = new Set([
  FilterOperator.NUMBER_NEQ,
  FilterOperator.NUMBER_IS_NOT_IN,
  FilterOperator.STRING_DOES_NOT_CONTAIN,
  FilterOperator.STRING_IS_NOT_IN,
  FilterOperator.STRING_IS_NOT,
  FilterOperator.DATE_IS_NOT,
  FilterOperator.IS_NOT_EMPTY,
]);

export const isFilterClauseIncomplete = (clause: FilterClause) => {
  if (!clause.filterColumn || !clause.filterOperation) return true;

  // If no value is required for operation no need to check anything else
  if (FILTER_OPS_NO_VALUE.has(clause.filterOperation.id)) return false;

  // If the filter is a variable-based filter value, then it will be incomplete
  // if the variable is not yet specified
  if (clause.filterValueSource === FilterValueSourceType.VARIABLE) {
    return clause.filterValueVariableId === undefined;
  }

  if (Array.isArray(clause.filterValue)) return false;

  if (FILTER_OPS_MULTISELECT.has(clause.filterOperation.id)) {
    if (clause.filterValue === undefined) {
      return true;
    } else {
      // When filter values are passed in through inputs or web components, this is a string
      try {
        const filterValue = JSON.parse(clause.filterValue as string);
        return !(Array.isArray(filterValue) || filterValue.length > 0);
      } catch {
        return true;
      }
    }
  }

  if (
    clause.filterValue === undefined ||
    clause.filterValue === null ||
    clause.filterValue === ''
  ) {
    return true;
  }

  if (FILTER_OPS_DATE_RANGE_PICKER.has(clause.filterOperation.id)) {
    const dateValue = clause.filterValue as FilterValueDateType | DEFAULT_DATE_RANGES;
    if (typeof dateValue === 'string') {
      return !DEFAULT_DATE_RANGES_SET.has(dateValue);
    }
    const rangeValue = clause.filterValue as FilterValueDateType;
    return !rangeValue.startDate || !rangeValue.endDate;
  } else if (FILTER_OPS_DATE_PICKER.has(clause.filterOperation.id)) {
    const dateValue = clause.filterValue as FilterValueDateType | RELATIVE_DATE_OPTIONS;
    return typeof dateValue === 'string'
      ? !RELATIVE_DATE_OPTIONS_SET.has(dateValue)
      : !dateValue.startDate;
  } else if (FILTER_OPS_RELATIVE_PICKER.has(clause.filterOperation.id)) {
    const relativeDateValue = clause.filterValue as FilterValueRelativeDateType;
    return !relativeDateValue.number || !relativeDateValue.relativeTimeType;
  } else if (FILTER_OPS_NUMBER_RANGE.has(clause.filterOperation.id)) {
    const rangeValue = clause.filterValue as FilterValueNumberRangeType;
    return rangeValue.min == null || rangeValue.max == null || rangeValue.min > rangeValue.max;
  }

  return false;
};
