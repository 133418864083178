export const EMAIL_FREQUENCY = {
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
} as const;

export const EMAIL_FREQUENCY_SELECT_OPTIONS = Object.values(EMAIL_FREQUENCY).map((frequency) => ({
  value: frequency,
  label: frequency,
}));

export type EmailFrequency = (typeof EMAIL_FREQUENCY)[keyof typeof EMAIL_FREQUENCY];

export const WEEKS_OF_MONTH = {
  FIRST: 'First',
  SECOND: 'Second',
  THIRD: 'Third',
  FOURTH: 'Fourth',
  LAST: 'Last',
} as const;

export const WEEKS_OF_MONTH_SELECT_OPTIONS = Object.values(WEEKS_OF_MONTH).map((week) => ({
  value: week,
  label: week,
}));

export type WeekOfMonth = (typeof WEEKS_OF_MONTH)[keyof typeof WEEKS_OF_MONTH];

export const DAYS_OF_WEEK = {
  MON: 'Monday',
  TUES: 'Tuesday',
  WED: 'Wednesday',
  THUR: 'Thursday',
  FRI: 'Friday',
  SAT: 'Saturday',
  SUN: 'Sunday',
} as const;

export type DayOfWeek = (typeof DAYS_OF_WEEK)[keyof typeof DAYS_OF_WEEK];

export type EmailCadenceTime = {
  hour?: number;
  minute?: number;
  timezone?: string;
  isPm?: boolean;
};
