import produce from 'immer';
import { DateTime } from 'luxon';

import {
  CustomerReportFilter,
  DATE_TYPES,
  FilterOperationInstructions,
  Timezones,
} from '@explo/data';
import { INPUT_TYPE } from 'constants/types';
import { DASHBOARD_ELEMENT_TYPES, DashboardElement, SelectElemConfig } from 'types/dashboardTypes';
import { VariableNames } from './variableUtils';

export const areFilterValuesEqualWithoutOrder = (
  clause: CustomerReportFilter,
  newClause: CustomerReportFilter,
): boolean => {
  const clauseValues = new Set(clause.filterValue as string[]);
  const newClauseValues = new Set(newClause.filterValue as string[]);

  if (clauseValues.size !== newClauseValues.size) return false;

  for (const value of clauseValues) {
    if (!newClauseValues.has(value)) return false;
  }

  return true;
};

export const areFilterValuesComplete = (element: DashboardElement): boolean => {
  switch (element.element_type) {
    case DASHBOARD_ELEMENT_TYPES.TOGGLE:
    case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
    case DASHBOARD_ELEMENT_TYPES.MULTISELECT: {
      const config = element.config as SelectElemConfig;
      if (config.valuesConfig.valuesSource === INPUT_TYPE.QUERY) {
        return getSelectFilterDatasetId(config) !== null;
      } else {
        return !!config.valuesConfig.manualValues;
      }
    }
  }
  return true;
};

export const getSelectFilterDatasetId = ({ valuesConfig }: SelectElemConfig): string | null => {
  if (valuesConfig.valuesSource !== INPUT_TYPE.QUERY) {
    return null;
  }

  if (valuesConfig.queryTable !== undefined && valuesConfig.queryValueColumn !== undefined) {
    return valuesConfig.queryTable.id;
  } else if (valuesConfig.queryGlobalDatasetReference?.id) {
    return valuesConfig.queryGlobalDatasetReference.id;
  }
  return null;
};

/**
 * Adjusts filter date values in `filterInfo` to correct double timezone shifts
 * applied during SQL queries, particularly when handling non-UTC timezones.
 *
 * This is necessary for correcting datetime values when Embeddo applies additional timezone adjustments during drilldowns.
 *
 * @param {FilterOperationInstructions | undefined} filterInfo - The filter information containing the datetime to adjust.
 * @param {string | undefined} timezone - The user's local timezone.
 * @param {'local' | 'utc'} target - The target timezone ('local' for requests, 'utc' for responses).
 * @returns {FilterOperationInstructions | undefined} The adjusted filter information with corrected datetime values.
 */
export const shiftFilterInfoToTargetTimezoneForEmbeddo = (
  filterInfo: FilterOperationInstructions | undefined,
  timezone: string | undefined,
  target: 'local' | 'utc',
) => {
  if (!filterInfo || !filterInfo.filterClauses.length || !timezone || timezone === Timezones.UTC) {
    return filterInfo;
  }

  return produce(filterInfo, (draft) => {
    draft.filterClauses.forEach((clause) => {
      if (
        DATE_TYPES.has(clause.filterColumn?.type || '') &&
        typeof clause.filterValue === 'object'
      ) {
        if (VariableNames.START_DATE in clause.filterValue && clause.filterValue.startDate) {
          clause.filterValue.startDate = shiftByTimezone(
            clause.filterValue.startDate,
            timezone,
            target,
          );
        }
        if (VariableNames.END_DATE in clause.filterValue && clause.filterValue.endDate) {
          clause.filterValue.endDate = shiftByTimezone(
            clause.filterValue.endDate,
            timezone,
            target,
          );
        }
      }
    });
  });
};

/**
 * Adjusts a datetime string by shifting it to the specified timezone (local or UTC).
 *
 * @param {string} datetime - The ISO formatted datetime string to adjust. It assumes the datetime is in user-local time.
 * @param {string} timezone - The user's local timezone.
 * @param {'local' | 'utc'} target - The target timezone ('local' to adjust to the user's timezone, 'utc' to adjust to UTC).
 * @returns {string} The adjusted ISO formatted datetime string.
 */
const shiftByTimezone = (datetime: string, timezone: string, target: 'local' | 'utc'): string => {
  if (target === 'local') {
    return DateTime.fromISO(datetime, { zone: timezone }).toISO();
  }
  return DateTime.fromISO(datetime, { zone: timezone }).setZone(target).toISO();
};
