import { createSuccessAction, defineAPIPostAction } from 'actions/actionUtils';
import { ACTION } from 'actions/types';

import { FetchProfileData } from 'features/auth/types';
import { UserTeam } from 'types/team';

export const logInUserSuccess = createSuccessAction<FetchProfileData>(ACTION.LOGIN_USER);

export type UserPermissions = Record<string, string[]>;

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  has_team: boolean;
  team: UserTeam | null;
  logged_in: boolean;
  is_superuser: boolean;

  email_verified: boolean;
  is_sso_account: boolean;
  can_create_team: boolean;
  permissions: UserPermissions;
  last_seen_app_update_id?: number;
}

export interface TeamMember {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  // for now we only technically support one role per user, but leaving this
  //  as a list so that this doesn't have to be an api change in the future
  role_names: string[];
}

// Requests

type UpdateUserNameBody = {
  first_name: string;
  last_name: string;
  id: number;
};

type UpdateUserNameData = {
  first_name: string;
  last_name: string;
};

export const { actionFn: updateUserName, successAction: updateUserNameSuccess } =
  defineAPIPostAction<UpdateUserNameBody, UpdateUserNameData>(
    ACTION.CHANGE_USER_PROFILE_NAME,
    'settings',
    'update_user_name',
    'POST',
  );

type UpdateLastSeenAppUpdateIdBody = {
  last_seen_id: number;
};

export const {
  actionFn: updateLastSeenAppUpdateId,
  successAction: updateLastSeenAppUpdateIdSuccess,
} = defineAPIPostAction<UpdateLastSeenAppUpdateIdBody, {}>(
  ACTION.CHANGE_LAST_SEEN_APP_UPDATE_ID,
  'settings',
  'change_last_seen_app_update_id',
  'POST',
);
