import { User } from 'actions/userActions';
import { getAnonymousMessageBase, getMessageBaseWithUser } from 'utils/pingMessageUtils';

export const getChildDashboardCreatedMessage = (currentUser: User, childDashboardName: string) => {
  return `${getMessageBaseWithUser(currentUser)} \`created\` a child dashboard named "${childDashboardName}"`;
};

export const getChildDashboardDeletedMessage = (currentUser: User, childDashboardName: string) => {
  return `${getMessageBaseWithUser(currentUser)} \`deleted\` a child dashboard named "${childDashboardName}"`;
};

export const getDrilledDownIntoChildDashboardMessage = (
  teamName: string,
  sourceDashboardName: string,
  childDashboardName: string,
) => {
  return `${getAnonymousMessageBase(teamName)} \`drilled down\` into child dashboard "${childDashboardName}" from "${sourceDashboardName}"`;
};
