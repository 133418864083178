import { FC } from 'react';

import { Select, ToggleItem, sprinkles, Toggle } from 'components/ds';
import {
  EmailFrequency,
  EMAIL_FREQUENCY,
  WEEKS_OF_MONTH,
  DAYS_OF_WEEK,
} from 'constants/emailFrequency';

type Props = {
  frequency: EmailFrequency;
  dayOfWeek: number | undefined;
  weekOfMonth: number | undefined;
  onSetDayOfWeek: (dayOfWeek: number) => void;
  onSetWeekOfMonth: (weekOfMonth: number) => void;
};

const WEEKS_OF_MONTH_OPTIONS = Object.values(WEEKS_OF_MONTH).map((week, index) => ({
  value: String(index),
  label: week,
}));

export const EmailDaySelection: FC<Props> = ({
  frequency,
  dayOfWeek,
  weekOfMonth,
  onSetDayOfWeek,
  onSetWeekOfMonth,
}) => {
  return (
    <div className={sprinkles({ flexItems: 'alignCenterBetween', gap: 'sp1', marginTop: 'sp1' })}>
      {frequency === EMAIL_FREQUENCY.MONTHLY ? (
        <Select
          className={sprinkles({ flex: 1 })}
          onChange={(newWeek) => onSetWeekOfMonth(Number(newWeek))}
          selectedValue={weekOfMonth?.toString()}
          values={WEEKS_OF_MONTH_OPTIONS}
        />
      ) : null}
      <Toggle
        className={sprinkles({ flex: 5 })}
        onValueChange={(newDay) => onSetDayOfWeek(Number(newDay))}
        selectedValue={dayOfWeek?.toString()}>
        {Object.values(DAYS_OF_WEEK).map((weekday, index) => (
          <ToggleItem key={weekday} value={String(index)}>
            {weekday.substring(0, 3)}
          </ToggleItem>
        ))}
      </Toggle>
    </div>
  );
};
