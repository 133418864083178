import { FC, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { OPERATION_TYPES, STRING } from '@explo/data';

import { Button, Icon, sprinkles, Tooltip } from 'components/ds';

import { COLOR_CATEGORY_FILTER_SUFFIX } from 'constants/dashboardConstants';
import { GlobalDatasetQuerySection } from 'pages/dashboardPage/EditDashboardLeftPanel/DatasetsPanel/GlobalDatasetQuerySection';
import { TABLE_COLUMN_CLICKED_VARIABLE_SUFFIX } from 'pages/dashboardPage/EditDashboardLeftPanel/VariablesPanel/constants';
import { ReduxState } from 'reducers/rootReducer';
import {
  getCurrentDashboardAllElementsWithDrilldowns,
  getDashboardEditConfigWithDrilldowns,
} from 'reducers/selectors';
import { GlobalDatasetVariableNameMap } from 'components/DataLibrary/types';
import { isChartUsingMultipleColorCategories } from 'utils/colorColUtils';
import { getAllDataPanelsInVersion } from 'utils/dataPanelUtils';
import { CATEGORY_SUFFIX, COLOR_SUFFIX, filterDpsWithDrilldowns } from 'utils/drilldownUtils';
import { ReadAccessComputedView } from 'utils/fido/fidoShimmedTypes';
import { getSelectableKPIs } from 'utils/selectableKpiUtils';
import { getFilterElementVariableName } from 'utils/variableUtils';
import { VariableMappingListItem } from './VariableMappingListItem';
import { getValidDataTypesFromDashboardElement } from './conversionUtils';

export type VariableOption = {
  name: string;
  validTypes: string[] | undefined;
};

interface Props {
  selectedComputedView: ReadAccessComputedView;
  setVariableMappingsButtonText?: string;
  // A map of global dataset variable names to dashboard variable names.
  globalDatasetVariableMappings: GlobalDatasetVariableNameMap;

  onNewMappingSelected: (parameterName: string, variableName: string) => void;
  onSetVariableMappings?: (
    selectedDataset: ReadAccessComputedView,
    mappings: GlobalDatasetVariableNameMap,
  ) => void;
  onClose: () => void;
}

export const MapVariablesContent: FC<Props> = ({
  selectedComputedView,
  setVariableMappingsButtonText,
  globalDatasetVariableMappings,
  onNewMappingSelected,
  onSetVariableMappings,
  onClose,
}) => {
  const { elements, config, dataPanelData } = useSelector((state: ReduxState) => {
    return {
      elements: getCurrentDashboardAllElementsWithDrilldowns(state),
      config: getDashboardEditConfigWithDrilldowns(state),
      dataPanelData: state.dashboardData.dataPanelData,
    };
  }, shallowEqual);

  const customParams = useMemo(() => {
    return config?.params ?? {};
  }, [config]);

  const dataPanelMap = useMemo(() => {
    return config?.data_panels ?? {};
  }, [config]);

  const numUnmappedVars = useMemo(() => {
    return selectedComputedView.parameters.filter(
      (param) => !globalDatasetVariableMappings[param.name],
    ).length;
  }, [selectedComputedView.parameters, globalDatasetVariableMappings]);

  // TODO(tarastentz): Refactor this into utils that can be shared with the Variables Panel
  const allVars = useMemo(() => {
    const seenVarSet = new Set<string>();
    const vars: VariableOption[] = [];

    // Get embed variables
    Object.values(customParams).forEach((param) =>
      addVariableOption(param.name, [param.type], vars, seenVarSet),
    );

    // Get filter variables
    elements.forEach((element) => {
      const validEmbeddoTypes = getValidDataTypesFromDashboardElement(element.element_type);
      const varNames = getFilterElementVariableName(element);
      varNames.forEach((varName) =>
        addVariableOption(varName, validEmbeddoTypes, vars, seenVarSet),
      );
    });

    // Get KPI variables
    const selectableKPIs = getSelectableKPIs(dataPanelMap);
    selectableKPIs.forEach((kpi) => addVariableOption(kpi.provided_id, [STRING], vars, seenVarSet));

    const allDataPanels = config ? Object.values(getAllDataPanelsInVersion(config)) : [];

    // Get Color Category Variables
    const dpsWithColorCategories = allDataPanels.filter((dp) =>
      isChartUsingMultipleColorCategories(dp.visualize_op),
    );

    dpsWithColorCategories.forEach((dp) =>
      addVariableOption(dp.provided_id + COLOR_CATEGORY_FILTER_SUFFIX, [STRING], vars, seenVarSet),
    );

    // Get Drilldown Variables
    const dpsWithDrilldowns = filterDpsWithDrilldowns(allDataPanels);

    dpsWithDrilldowns.forEach((dp) => {
      const dpProvidedId = dp.provided_id;
      const instructions = dp.visualize_op.instructions;

      // Handle Table Case
      const isTable = dp.visualize_op.operation_type === OPERATION_TYPES.VISUALIZE_TABLE;
      if (isTable) {
        addVariableOption(
          dpProvidedId + '.' + TABLE_COLUMN_CLICKED_VARIABLE_SUFFIX,
          [STRING],
          vars,
          seenVarSet,
        );
        if (!instructions.VISUALIZE_TABLE.drilldownConfig?.allColumns) {
          // If not all columns are selected, just the data panel provided id is a valid variable
          addVariableOption(dpProvidedId, undefined, vars, seenVarSet);
        } else {
          // Otherwise, all columns are valid variables
          dataPanelData[dp.id]?.schema?.forEach((column) =>
            addVariableOption(dpProvidedId + '.' + column.name, [column.type], vars, seenVarSet),
          );
        }
      }

      // Handle 2D Chart Case
      const twoDimInstructions = instructions.V2_TWO_DIMENSION_CHART;
      if (twoDimInstructions?.colorColumnOptions?.length) {
        addVariableOption(dpProvidedId + COLOR_SUFFIX, undefined, vars, seenVarSet);
      }
      if (twoDimInstructions?.categoryColumn?.column.type) {
        addVariableOption(dpProvidedId + CATEGORY_SUFFIX, [STRING], vars, seenVarSet);
      }
    });
    return vars;
  }, [customParams, elements, dataPanelMap, config, dataPanelData]);

  const allVariableNameSet = useMemo(() => {
    const allVariableNames = new Set<string>();
    allVars.forEach((varOption) => {
      allVariableNames.add(varOption.name);
    });
    return allVariableNames;
  }, [allVars]);

  const invalidParameterNames = useMemo(() => {
    return new Set(
      selectedComputedView.parameters
        .filter((param) => {
          const mappedVarName = globalDatasetVariableMappings[param.name];
          // If the mapped variable name is not in the all variable name set, it's invalid.
          return mappedVarName && !allVariableNameSet.has(mappedVarName);
        })
        .map((param) => param.name),
    );
  }, [selectedComputedView.parameters, globalDatasetVariableMappings, allVariableNameSet]);

  const numInvalidMappings = invalidParameterNames.size;
  const computedViewHasVariables = selectedComputedView.parameters.length > 0;
  const pluralNumberOfUnmappedVars = numUnmappedVars > 1;
  const mappingWarningIconText = getMappingWarningIconText(numUnmappedVars, numInvalidMappings);

  return (
    <div className={sprinkles({ flexItems: 'column' })}>
      <div className={contentStyles}>
        {numUnmappedVars || numInvalidMappings ? (
          <div className={sprinkles({ paddingX: 'sp3', width: 'fill' })}>
            <div className={bannerStyles}>
              {numUnmappedVars ? (
                <div>
                  <Icon className={sprinkles({ marginRight: 'sp1' })} name="circle-exclamation" />
                  {numUnmappedVars} variable{pluralNumberOfUnmappedVars ? 's' : ''}
                  {pluralNumberOfUnmappedVars ? ' are' : ' is'} not linked
                </div>
              ) : null}
              {numInvalidMappings ? (
                <div className={numUnmappedVars ? sprinkles({ marginTop: 'sp1' }) : undefined}>
                  <Icon className={sprinkles({ marginRight: 'sp1' })} name="circle-exclamation" />
                  {getAllInvalidParameterNamesMessage(invalidParameterNames)}
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        <GlobalDatasetQuerySection query={selectedComputedView.query} />
        <div className={sprinkles({ flexItems: 'column', paddingX: 'sp3', gap: 'sp2' })}>
          {computedViewHasVariables ? (
            selectedComputedView.parameters.map((param, index) => (
              <VariableMappingListItem
                isFirstListItem={index === 0}
                isMappingInvalid={invalidParameterNames.has(param.name)}
                key={param.name}
                parameter={param}
                possibleVariables={allVars}
                selectedVariable={globalDatasetVariableMappings[param.name] ?? ''}
                setVariableMapping={onNewMappingSelected}
              />
            ))
          ) : (
            <div>
              Customer variables are automatically mapped. Dataset has no additional variables to
              map
            </div>
          )}
        </div>
      </div>
      {onSetVariableMappings && setVariableMappingsButtonText ? (
        <div className={sprinkles({ flexItems: 'alignCenter', justifyContent: 'flex-end' })}>
          <Button
            className={sprinkles({ marginRight: 'sp2', marginTop: 'sp2' })}
            onClick={() => {
              onSetVariableMappings(selectedComputedView, globalDatasetVariableMappings);
              onClose();
            }}>
            {setVariableMappingsButtonText}
            {mappingWarningIconText ? (
              <Tooltip text={mappingWarningIconText}>
                <Icon
                  className={sprinkles({ color: 'warning', marginX: 'sp1' })}
                  name="infoCircle"
                />
              </Tooltip>
            ) : null}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

const getMappingWarningIconText = (
  numUnmappedVars: number,
  numInvalidMappings: number,
): string | undefined => {
  const hasUnmappedVars = numUnmappedVars > 0;
  const hasInvalidMappings = numInvalidMappings > 0;
  if (hasUnmappedVars && hasInvalidMappings) {
    return 'There are unmapped dataset variables and invalid mappings';
  } else if (hasUnmappedVars) {
    return 'There are unmapped dataset variables';
  } else if (hasInvalidMappings) {
    return 'There are invalid mapped dataset variables';
  }
};

const getAllInvalidParameterNamesMessage = (invalidParameterNames: Set<string>): string => {
  if (invalidParameterNames.size === 0) {
    return '';
  }

  const invalidNamesArray = Array.from(invalidParameterNames);
  const invalidNamesList = invalidNamesArray.join(', ');
  const invalidNamesPluralityString = invalidNamesArray.length > 1 ? 'names are' : 'name is';
  return `The following mapped variable ${invalidNamesPluralityString} invalid: ${invalidNamesList}`;
};

const addVariableOption = (
  varName: string,
  validTypes: string[] | undefined,
  variableOptions: VariableOption[],
  seenVarSet: Set<string>,
) => {
  if (!seenVarSet.has(varName)) variableOptions.push({ name: varName, validTypes: validTypes });
  seenVarSet.add(varName);
};

const contentStyles = sprinkles({
  paddingX: 'sp3',
  flexItems: 'column',
  gap: 'sp3',
  marginTop: 'sp3',
});

const bannerStyles = sprinkles({
  paddingX: 'sp3',
  paddingY: 'sp2',
  backgroundColor: 'warningSubdued',
  color: 'warningBold',
  borderRadius: 8,
  gap: 'sp1',
});
