import { FC, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import * as styles from './dataLibraryPage.css';
import { ManageDatasetModalType } from './types';

import { Button, sprinkles } from 'components/ds';
import { ViewingAsCustomerSelector } from 'components/ViewingAsCustomerSelector';
import { DataLibraryBreadcrumbs } from 'pages/dataLibraryPage/DataLibraryBreadcrumbs';
import { ReduxState } from 'reducers/rootReducer';
import { ReadAccessComputedView } from 'utils/fido/fidoShimmedTypes';
import {
  getPathToCurrentSubFolderHelper,
  getSubFolderUniqueNames,
  getUpdatedFoldersForBranch,
} from './selectors';

import { addPendingResourceDeletion, ItemType } from 'reducers/dataLibraryReducer';
import { getOrDefault } from 'remotedata';
import { DeleteResourceConfirmationModal } from 'shared/ExploResource/DeleteResourceConfirmationModal';
import { COMPUTED_VIEW_TYPE } from './constants';
import { validateResourceName } from './dataLibraryUtil';
import { DatasetMetadataEditModal } from './DatasetMetadataEditModal';
import { navigateToPathThunk } from './navigationUtils';

type Props = {
  selectedView: ReadAccessComputedView;
};

export const DataLibraryTopBar: FC<Props> = ({ selectedView }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [openModalType, setOpenModalType] = useState<ManageDatasetModalType>(
    ManageDatasetModalType.NO_MODAL,
  );

  const { subFolderUniqueNames, currentItemPath, folders } = useSelector(
    (state: ReduxState) => ({
      currentItemPath: state.dataLibrary.currentItemPath,
      subFolderUniqueNames: getSubFolderUniqueNames(state),
      folderPath: state.dataLibrary.currentItemPath,
      folders: getUpdatedFoldersForBranch(state, state.dataLibrary.currentBranch),
    }),
    shallowEqual,
  );

  const itemName = selectedView.name;

  return (
    <>
      <div className={styles.dataLibraryTopBar}>
        {selectedView.path ? (
          <DataLibraryBreadcrumbs folders={folders} path={selectedView.path} />
        ) : null}
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1', height: 'fill' })}>
          <div className={sprinkles({ body: 'b2' })}>Viewing as</div>
          <ViewingAsCustomerSelector />
          <div
            className={sprinkles({ height: 'fill', backgroundColor: 'outline' })}
            style={{ width: 1 }}
          />
          <Button
            icon="pencil"
            onClick={() => setOpenModalType(ManageDatasetModalType.EDIT_NAME_AND_DESCRIPTION_MODAL)}
            tooltipProps={{
              text: 'Edit dataset name and description',
            }}
            variant="tertiary">
            Edit
          </Button>
          <Button
            icon="trash-can-reg"
            onClick={() => setOpenModalType(ManageDatasetModalType.DELETE_CONFIRMATION_MODAL)}
            variant="tertiary">
            Delete
          </Button>
        </div>
      </div>
      {openModalType === ManageDatasetModalType.DELETE_CONFIRMATION_MODAL ? (
        <DeleteResourceConfirmationModal
          onClose={() => setOpenModalType(ManageDatasetModalType.NO_MODAL)}
          onDelete={() => {
            dispatch(addPendingResourceDeletion(selectedView));
            if (!selectedView.path) return;

            const parentPath = getPathToCurrentSubFolderHelper(currentItemPath);
            dispatch(
              navigateToPathThunk(
                getOrDefault(folders.get(parentPath), null)?.id ?? '',
                ItemType.FOLDER,
                parentPath,
                history,
              ),
            );
          }}
          resourceName={itemName}
        />
      ) : null}
      {openModalType === ManageDatasetModalType.EDIT_NAME_AND_DESCRIPTION_MODAL ? (
        <DatasetMetadataEditModal
          computedView={selectedView}
          existingResourceNames={subFolderUniqueNames}
          onClose={() => setOpenModalType(ManageDatasetModalType.NO_MODAL)}
          validateNewNameFn={(newName) => validateResourceName(newName, COMPUTED_VIEW_TYPE)}
        />
      ) : null}
    </>
  );
};
