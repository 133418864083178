import { AnyAction, createAction, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import produce from 'immer';
import { Dispatch } from 'react';

import {
  BaseCol,
  FilterOperator,
  FilterValueSourceType,
  FilterValueType,
  OPERATION_TYPES,
} from '@explo/data';

import { ACTION } from 'actions/types';
import {
  ExportConfig,
  SunburstChartInstructions,
  V2BoxPlotInstructions,
  V2KPIChartInstructions,
  V2KPITrendInstructions,
  V2ScatterPlotInstructions,
  V2TwoDimensionChartInstructions,
  VisualizeCollapsibleListInstructions,
  VisualizeGeospatialChartInstructions,
  VisualizeOperationGeneralFormatOptions,
  VisualizeOperationInstructions,
  VisualizeTableInstructions,
} from 'constants/types';
import { updateVisualizeOperationColor } from 'reducers/dashboardStylesReducer';
import { ReduxState } from 'reducers/rootReducer';
import { cloneDeep } from 'utils/standard';
import { getDashboardEditConfigWithDrilldowns } from 'reducers/selectors';

// FILTER TAB

export const createFilterClause = createAction<BaseCol | undefined>(ACTION.CREATE_FILTER_CLAUSE);

export const deleteFilterClause = createAction<number>(ACTION.DELETE_FILTER_CLAUSE);

export const selectFilterColumn = createAction<{ index: number; column: BaseCol }>(
  ACTION.SELECT_FILTER_COLUMN,
);

export const selectFilterOperator = createAction<{
  index: number;
  operator: FilterOperator;
}>(ACTION.SELECT_FILTER_OPERATOR);

export const updateFilterValue = createAction<{ index: number; value: FilterValueType }>(
  ACTION.UPDATE_FILTER_VALUE,
);

export const updateFilterValueSource = createAction<{
  index: number;
  newSource: FilterValueSourceType;
}>(ACTION.UPDATE_FILTER_VALUE_SOURCE);

export const updateFilterValueVariable = createAction<{
  index: number;
  variableId: string;
  property?: string;
}>(ACTION.UPDATE_FILTER_VALUE_VARIABLE);

// VISUALIZE TAB

export const updateSelectedChart = createAction<{
  id: string;
  from: OPERATION_TYPES;
  to: OPERATION_TYPES;
}>(ACTION.UPDATE_SELECTED_CHART);

// Visualize Table Section

export type CombinedVisualizeInstructions =
  | VisualizeTableInstructions
  | V2TwoDimensionChartInstructions
  | V2KPIChartInstructions
  | V2BoxPlotInstructions
  | V2ScatterPlotInstructions
  | V2KPITrendInstructions
  | VisualizeCollapsibleListInstructions
  | VisualizeGeospatialChartInstructions
  | SunburstChartInstructions;

export type UpdateVisualizeOperationPayload = {
  visualizeInstructions: CombinedVisualizeInstructions;
  operationType: OPERATION_TYPES;
};

// Helper function to not have to rewrite all the places its used
export const updateVisualizeOperation = (
  visualizeInstructions: CombinedVisualizeInstructions,
  operationType: OPERATION_TYPES,
) => {
  return updateVisualizeOperationAction({ visualizeInstructions, operationType });
};

export const updateVisualizeOperationAction = createAction<{
  visualizeInstructions: CombinedVisualizeInstructions;
  operationType: OPERATION_TYPES;
}>(ACTION.UPDATE_VISUALIZE_OP);

export const updateGeneralFormatOptions = createAction<
  VisualizeOperationGeneralFormatOptions,
  ACTION.UPDATE_VISUALIZE_OPERATION_GENERAL_FORMAT_OPTIONS
>(ACTION.UPDATE_VISUALIZE_OPERATION_GENERAL_FORMAT_OPTIONS);

export function updateClonedVisualizationInstructions<T extends CombinedVisualizeInstructions>(
  oldInstructions: T,
  operationType: OPERATION_TYPES,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  modifyInstructions: (newInstructions: T) => void,
) {
  const newInstructions = cloneDeep(oldInstructions);
  modifyInstructions(newInstructions);
  dispatch(updateVisualizeOperation(newInstructions, operationType));
}

/**
 * @param visualizeInstructions
 * @param operationType
 * @param updateColors - Should be true when updating a table's category string column. If true, updates color category tracker
 */
export const updateVisualizeOperationThunk =
  (
    visualizeInstructions: CombinedVisualizeInstructions,
    operationType: OPERATION_TYPES,
    updateColors?: boolean,
  ): ThunkAction<void, ReduxState, unknown, AnyAction> =>
  (dispatch, getState) => {
    dispatch(updateVisualizeOperationAction({ visualizeInstructions, operationType }));

    if (!updateColors) return;

    const state = getState();
    const config = getDashboardEditConfigWithDrilldowns(state);
    const { editingDataPanelId } = state.dashboardEditConfig;
    if (!editingDataPanelId) return;

    const dataPanel = config?.data_panels[editingDataPanelId];
    const dataPanelData = state.dashboardData.dataPanelData[editingDataPanelId];
    if (!dataPanel || !dataPanelData) return;

    const isTable = operationType === OPERATION_TYPES.VISUALIZE_TABLE;
    const operationInstructions: VisualizeOperationInstructions = {
      VISUALIZE_TABLE: {
        changeSchemaList: [],
        ...(isTable ? (visualizeInstructions as VisualizeTableInstructions) : {}),
      },
      V2_TWO_DIMENSION_CHART: !isTable
        ? (visualizeInstructions as V2TwoDimensionChartInstructions)
        : undefined,
    };

    dispatch(
      updateVisualizeOperationColor({
        operationType,
        operationInstructions,
        previewData: dataPanelData.rows ?? [],
        schema: dataPanelData.schema ?? [],
        dataPanelId: dataPanel.id,
      }),
    );
  };

export const updateExportConfigThunk =
  (
    newExportConfig: Partial<ExportConfig>,
    generalFormatOptions: VisualizeOperationGeneralFormatOptions,
  ): ThunkAction<
    void,
    ReduxState,
    unknown,
    PayloadAction<
      VisualizeOperationGeneralFormatOptions,
      ACTION.UPDATE_VISUALIZE_OPERATION_GENERAL_FORMAT_OPTIONS
    >
  > =>
  (dispatch) => {
    const newOptions = produce(generalFormatOptions, (draft) => {
      draft.export = { ...generalFormatOptions.export, ...newExportConfig };
    });
    dispatch(updateGeneralFormatOptions(newOptions));
  };
