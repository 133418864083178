import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useImmer } from 'use-immer';

import { ColorPickerButton } from 'components/ColorPickerButton';
import { Button, IconButton, Input, Modal, sprinkles } from 'components/ds';
import { updateDashboardCategoryColors } from 'reducers/dashboardEditConfigReducer';
import { ReduxState } from 'reducers/rootReducer';
import { fetchDashboardDataThunk } from 'reducers/thunks/dashboardDataThunks/requestLogicThunks';
import { isEqual } from 'utils/standard';
import { getDashboardEditConfigWithDrilldowns } from 'reducers/selectors';

type Props = {
  onClose: () => void;
};

export const SyncColorsModal: FC<Props> = ({ onClose }) => {
  const dispatch = useDispatch();

  const categoryColors = useSelector(
    (state: ReduxState) => getDashboardEditConfigWithDrilldowns(state)?.category_colors,
  );

  const [currCategoryColors, setCurrCategoryColors] = useImmer(categoryColors ?? {});
  const [newCategoryColor, setNewCategoryColor] = useState<string>();

  const colorRow = (color: string, value?: string) => {
    const isNewCategory = value === undefined;
    return (
      <div
        className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1', marginBottom: 'sp2' })}
        key={value}>
        <ColorPickerButton
          color={color}
          colorPalette={[]}
          onColorChange={(color) => {
            if (isNewCategory) {
              setNewCategoryColor(color);
            } else {
              setCurrCategoryColors((draft) => {
                draft[value] = color;
              });
            }
          }}
          size={32}
          usePortal={false}
        />
        <Input
          fillWidth
          defaultValue={value}
          onSubmit={(newVal) => {
            if (newVal === value) return;
            if (isNewCategory) {
              setCurrCategoryColors((draft) => {
                draft[newVal] = color;
              });
              setNewCategoryColor(undefined);
            } else {
              setCurrCategoryColors((draft) => {
                const oldColor = draft[value];
                if (!oldColor) return;
                draft[newVal] = oldColor;
                delete draft[value];
              });
            }
          }}
        />
        <IconButton
          name="trash"
          onClick={() => {
            if (isNewCategory) setNewCategoryColor(undefined);
            else {
              setCurrCategoryColors((draft) => {
                delete draft[value];
              });
            }
          }}
        />
      </div>
    );
  };

  return (
    <Modal
      isOpen
      onClose={onClose}
      primaryButtonProps={{
        text: 'Done',
        onClick: () => {
          if (isEqual(currCategoryColors, categoryColors ?? {})) return;
          dispatch(updateDashboardCategoryColors(currCategoryColors));
          // Need to refetch data to correctly apply new colors to all panels
          dispatch(fetchDashboardDataThunk({}));
        },
      }}
      size="small"
      title="Syncing color categories">
      <div className={sprinkles({ paddingX: 'sp3' })}>
        <div className={sprinkles({ heading: 'h4' })}>Getting started</div>
        <ul className={listClass}>
          <li>Colors are applied to charts.</li>
          <li>Colors can be assigned to specific values and shared across datasets.</li>
          <li>
            For line charts and bar charts, colors can be also assigned to columns using its data
            label. Column data labels can be edited on the &quot;Configure&quot; tab for a chart.
          </li>
          <li>
            Setting a custom palette for a specific chart will override these dashboard-level
            colors.
          </li>
          <li>
            Changes to these colors will have to be published to be visible in embedded dashboard.
          </li>
        </ul>
        <div className={sprinkles({ heading: 'h3', marginBottom: 'sp2' })}>
          Defined values and columns
        </div>
        {Object.entries(currCategoryColors).map(([value, color]) => colorRow(color, value))}
        {newCategoryColor ? colorRow(newCategoryColor) : null}
        <Button
          disabled={!!newCategoryColor}
          icon="plus"
          onClick={() => setNewCategoryColor('#fff')}
          variant="secondary">
          Add a color category
        </Button>
      </div>
    </Modal>
  );
};

const listClass = sprinkles({
  body: 'b3',
  color: 'contentSecondary',
  flexItems: 'column',
  gap: 'sp1',
  marginBottom: 'sp3',
});
