import { Button, Input, Modal, sprinkles } from 'components/ds';
import { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { addPendingResourceUpdate, ItemType } from 'reducers/dataLibraryReducer';
import { getResourceNameErrorMessage } from 'utils/exploResourceUtils';
import { ReadAccessComputedView } from 'utils/fido/fidoShimmedTypes';
import { copyAndRenameItem } from './dataLibraryUtil';
import { navigateToPathThunk } from './navigationUtils';

interface Props {
  computedView: ReadAccessComputedView;
  existingResourceNames: Set<string>;

  validateNewNameFn: (newName: string) => string | undefined;
  onClose: () => void;
}

export const DatasetMetadataEditModal: FC<Props> = ({
  computedView,
  existingResourceNames,
  validateNewNameFn,
  onClose,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [name, setName] = useState(computedView.name);
  const [description, setDescription] = useState(computedView.description);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const isSaveButtonDisabled = useMemo(() => {
    return name === computedView.name && description === computedView.description;
  }, [name, description, computedView]);

  return (
    <Modal isOpen onClose={onClose} size="medium" title="Edit dataset">
      <Input
        className={sprinkles({ marginX: 'sp3', marginBottom: 'sp2' })}
        errorText={errorMessage}
        label="Name"
        onChange={(newName) => {
          const newNameError =
            validateNewNameFn(newName) ??
            getResourceNameErrorMessage(newName, existingResourceNames, computedView.name);
          if (newNameError) {
            setErrorMessage(newNameError);
          } else {
            setErrorMessage(undefined);
          }
          setName(newName);
        }}
        value={name}
      />
      <Input
        className={sprinkles({ marginX: 'sp3', marginBottom: 'sp2' })}
        label="Description"
        onChange={setDescription}
        value={description ?? ''}></Input>
      <div className={sprinkles({ flexItems: 'alignCenter', flexDirection: 'row-reverse' })}>
        <Button
          className={sprinkles({ marginX: 'sp3' })}
          disabled={isSaveButtonDisabled}
          onClick={() => {
            const hasNameChange = name !== computedView.name;
            const newResource = hasNameChange
              ? copyAndRenameItem(computedView, name)
              : computedView;
            newResource.description = description;
            dispatch(addPendingResourceUpdate(newResource));
            dispatch(
              navigateToPathThunk(
                newResource.id ?? '',
                ItemType.VIEW,
                newResource.path ?? '',
                history,
              ),
            );

            onClose();
          }}>
          Save
        </Button>
      </div>
    </Modal>
  );
};
