import { forwardRef } from 'react';
import { Icon, IconButton, sprinkles } from '../ds';
import { cross, input, openSearchBar } from './styles.css';

import cx from 'classnames';

export type SearchBarProps = {
  placeholderText: string;
  isExpanded: boolean;
  shouldKeepFocus?: boolean;

  onSetIsExpanded: (isExpanded: boolean) => void;
  onSubmit: (searchText: string) => void;
};

export const SearchBar = forwardRef<HTMLDivElement, SearchBarProps>(
  ({ placeholderText, isExpanded, shouldKeepFocus = false, onSetIsExpanded, onSubmit }, ref) => {
    if (isExpanded) {
      return (
        <div className={cx(openSearchBar)} ref={ref}>
          <Icon className={sprinkles({ color: 'active' })} name="search" />
          <input
            autoFocus
            className={input}
            onBlur={(event) => {
              if (!shouldKeepFocus) {
                return;
              }

              event.target.focus();
            }}
            onChange={(event) => {
              onSubmit(event.currentTarget.value);
            }}
            placeholder={placeholderText}
          />
          <IconButton
            className={cross}
            name="cross"
            onClick={() => {
              onSetIsExpanded(false);
              onSubmit('');
            }}
            variant="tertiary"
          />
        </div>
      );
    }
    return <IconButton name="search" onClick={() => onSetIsExpanded(true)} variant="primary" />;
  },
);

SearchBar.displayName = 'SearchBar';
