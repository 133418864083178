import { CommitResponseMetadata } from '@explo-tech/fido-api';

export const ROOT_FOLDER_PATH = '/';

export const COMPUTED_VIEW_TYPE = 'computed-view';

export const FOLDER_TYPE = 'folder';

export const MAIN_BRANCH_NAME = 'main';

export const MAX_RESOURCE_NAME_LENGTH = 150;

export const INVALID_RESOURCE_NAME_START_REGEXP = /^[/ _]/;

export const INVALID_RESOURCE_NAME_CONTENT_REGEXP = /\/+/;

export const INVALID_FOLDER_LENGTH_ERROR = 'Folder name must be less than 150 characters';

export const INVALID_DATASET_LENGTH_ERROR = 'Dataset name must be less than 150 characters';

export const INVALID_RESOURCE_NAME_START_ERROR_BASE =
  'name cannot start with a space, underscore or forward slash';

export const INVALID_FOLDER_NAME_START_ERROR = `Folder ${INVALID_RESOURCE_NAME_START_ERROR_BASE}`;

export const INVALID_DATASET_NAME_START_ERROR = `Dataset ${INVALID_RESOURCE_NAME_START_ERROR_BASE}`;

export const INVALID_RESOURCE_NAME_CONTENT_ERROR_BASE = 'name cannot contain a forward slash';

export const INVALID_FOLDER_NAME_CONTENT_ERROR = `Folder ${INVALID_RESOURCE_NAME_CONTENT_ERROR_BASE}`;

export const INVALID_DATASET_NAME_CONTENT_ERROR = `Dataset ${INVALID_RESOURCE_NAME_CONTENT_ERROR_BASE}`;

export const RESOURCE_NOT_FOUND_INDEX = -1;

export const NEWER_COMMIT_ERROR_MESSAGE = 'Cannot create commit off of non-head commit';

export const FOLDER_CONTENT_FETCH_ERROR_MESSAGE = 'Error fetching folder content';

export const VALID_BRANCH_NAME_REGEXP = /^[a-zA-Z0-9._-]+$/;

export enum ResourceDiffType {
  CREATE = 'create',
  DELETE = 'delete',
  UPDATE = 'update',
}

export const DEFAULT_COMMIT_CHANGE_METADATA: CommitResponseMetadata = {
  changes: [],
};
