import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icon, IconButton, sprinkles, Tabs, Tooltip } from 'components/ds';
import { DEFAULT_DELAY } from 'components/ds/Tooltip';
import { VIZ_TO_HELP_DOCS } from 'constants/dataConstants';
import {
  setEditableSectionModal,
  setSelectedDatasetId,
  setSelectedGlobalDatasetId,
} from 'reducers/dashboardEditConfigReducer';
import {
  setDeleteSelectedItemModalOpen,
  setEditDataPanelState,
} from 'reducers/dashboardInteractionsReducer';
import { ReduxState } from 'reducers/rootReducer';
import {
  getEditableDatasets,
  getReferencedGlobalDatasets,
  getRootEditConfigWithDrilldowns,
} from 'reducers/selectors';
import { onDataPanelUpdateThunk } from 'reducers/thunks/dashboardDataThunks/requestLogicThunks';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import {
  dataPanelToConfig,
  getDataPanelBackingDataset,
  shouldRecomputeDataForDataPanel,
  shouldRecomputeSecondaryDataForDataPanel,
} from 'utils/dataPanelConfigUtils';

import { getParentSchemasList } from 'reducers/parentSchemaReducer';
import { ActionsConfigTab } from './ActionsConfigTab';
import { ChangeDataPanelIdModal } from './ChangeDataPanelIdModal';
import { DataConfigTab } from './DataConfigTab';
import { DataPanelDuplicationOptionsMenu } from './DataPanelDuplicationOptionsMenu';
import { FormatConfigTab } from './FormatConfigTab';
import { duplicateToMainDashboardAction } from './dataPanelConfigActions';
import { DUPLICATE_BUTTON_TOOLTIP_TEXT } from './dataPanelConfigConstants';
import * as styles from './styles.css';

enum CONFIG_TABS {
  DATA = 'Data',
  FORMAT = 'Format',
  ACTIONS = 'Actions',
}

const TabNames = Object.values(CONFIG_TABS);

type Props = {
  dashboardId: number;
  dataPanel: DataPanelTemplate;
  isEditableSection?: boolean;
};

export const DataPanelConfig: FC<Props> = ({ dataPanel, dashboardId, isEditableSection }) => {
  const [selectedConfigTabId, setSelectedConfigTabId] = useState(CONFIG_TABS.DATA);
  const [disableDuplicating, setDisableDuplicating] = useState(false);
  const [isChangeDpIdModalOpen, setIsChangeDpIdModalOpen] = useState(false);

  const dispatch = useDispatch();

  const { datasets, referencedGlobalDatasets, parentSchemas } = useSelector((state: ReduxState) => {
    return {
      datasets: getEditableDatasets(state),
      referencedGlobalDatasets: getReferencedGlobalDatasets(state),
      parentSchemas: getParentSchemasList(state),
    };
  });
  const editableSectionConfig = useSelector(
    (state: ReduxState) => getRootEditConfigWithDrilldowns(state)?.editable_section,
  );

  const dataset = getDataPanelBackingDataset(
    dataPanel,
    datasets,
    referencedGlobalDatasets,
    parentSchemas,
  );
  const isBackedByGlobalDataset = !!dataPanel.globalDatasetReference;
  const prevConfigRef = useRef(dataPanel);

  const onDatasetTitleClicked = useCallback(() => {
    if (isBackedByGlobalDataset) {
      dispatch(setSelectedGlobalDatasetId(dataPanel.globalDatasetReference?.id ?? ''));
    } else {
      dispatch(setSelectedDatasetId(dataset.id));
    }
  }, [dataPanel, dataset, dispatch, isBackedByGlobalDataset]);

  useEffect(() => {
    // Only recompute if the config changes because the data panel changed
    if (prevConfigRef.current.id === dataPanel.id) {
      const prevConfig = dataPanelToConfig(prevConfigRef.current);
      const newConfig = dataPanelToConfig(dataPanel);

      const shouldRecompute = shouldRecomputeDataForDataPanel(prevConfig, newConfig);

      // If shouldRecompute is true then this value won't be used
      const shouldRecomputeSecondary = shouldRecompute
        ? false
        : shouldRecomputeSecondaryDataForDataPanel(prevConfig, newConfig);

      dispatch(onDataPanelUpdateThunk(dataPanel.id, shouldRecompute, shouldRecomputeSecondary));
    }

    prevConfigRef.current = dataPanel;
  }, [dataPanel, dispatch]);

  const renderDashboardTitle = () => {
    const helpDocLink = VIZ_TO_HELP_DOCS[dataPanel.visualize_op.operation_type];
    return (
      <div className={styles.configurationHeader}>
        <div className={sprinkles({ flex: 1, overflow: 'hidden' })}>
          <Tooltip delayDuration={DEFAULT_DELAY} text={dataPanel.provided_id}>
            <div className={styles.elementIdTag}>{dataPanel.provided_id}</div>
          </Tooltip>
          <Tooltip delayDuration={DEFAULT_DELAY} text={dataset.table_name}>
            <div className={sprinkles({ flexItems: 'alignCenter' })}>
              {isBackedByGlobalDataset ? (
                <Icon
                  className={sprinkles({ marginRight: 'sp1', color: 'contentSecondary' })}
                  name="globe"
                />
              ) : null}
              <div className={styles.dataTableName} onClick={onDatasetTitleClicked}>
                {dataset.table_name}
              </div>
            </div>
          </Tooltip>
        </div>
        <div className={sprinkles({ flexItems: 'alignCenter', marginLeft: 'sp1' })}>
          {helpDocLink ? (
            <IconButton
              name="file-reg"
              to={`https://docs.explo.co/creating-dashboards/visualizations/charts/${helpDocLink}`}
              tooltipProps={{ text: 'Help docs' }}
            />
          ) : null}
          <IconButton
            name="pencil"
            onClick={() =>
              isEditableSection
                ? dispatch(
                    setEditableSectionModal({ type: 'EditChartInfo', chartId: dataPanel.id }),
                  )
                : setIsChangeDpIdModalOpen(true)
            }
            tooltipProps={{ text: 'Edit chart ID' }}
          />
          <IconButton
            name="table"
            onClick={() => {
              dispatch(
                setEditDataPanelState({
                  selectedDatasetId: dataset.id,
                  opType: dataPanel.visualize_op.operation_type,
                }),
              );
            }}
            tooltipProps={{ text: 'Edit dataset' }}
          />
          {isEditableSection ? (
            <IconButton
              name="user"
              onClick={() =>
                dispatch(
                  setEditableSectionModal({ type: 'EditChartPermissions', chartId: dataPanel.id }),
                )
              }
              tooltipProps={{ text: 'Edit chart permissions' }}
            />
          ) : editableSectionConfig?.enabled ? (
            <DataPanelDuplicationOptionsMenu dashboardId={dashboardId} dataPanel={dataPanel} />
          ) : (
            <IconButton
              disabled={disableDuplicating}
              name={disableDuplicating ? 'tick' : 'copy'}
              onClick={() => {
                dispatch(
                  duplicateToMainDashboardAction(
                    dataPanel,
                    dashboardId,
                    () => setDisableDuplicating(true),
                    () => setDisableDuplicating(false),
                  ),
                );
              }}
              tooltipProps={{ text: DUPLICATE_BUTTON_TOOLTIP_TEXT }}
            />
          )}
          <IconButton
            name="trash"
            onClick={() =>
              dispatch(
                isEditableSection
                  ? setEditableSectionModal({ type: 'DeleteChart', chartId: dataPanel.id })
                  : setDeleteSelectedItemModalOpen(true),
              )
            }
            tooltipProps={{ text: 'Delete chart' }}
          />
        </div>
      </div>
    );
  };

  const renderTabConfigOptions = () => {
    switch (selectedConfigTabId) {
      case CONFIG_TABS.DATA:
        return (
          <DataConfigTab
            dataPanel={dataPanel}
            datasets={datasets}
            referencedGlobalDatasets={referencedGlobalDatasets}
            schema={dataset.schema ?? []}
          />
        );
      case CONFIG_TABS.FORMAT:
        return (
          <FormatConfigTab
            dataPanel={dataPanel}
            datasets={datasets}
            isEditableSection={isEditableSection}
          />
        );
      case CONFIG_TABS.ACTIONS:
        return <ActionsConfigTab dataPanel={dataPanel} />;
    }
  };

  return (
    <>
      {renderDashboardTitle()}
      <div className={styles.bodyContainer}>
        <Tabs
          onTabSelect={(tab) => setSelectedConfigTabId(tab as CONFIG_TABS)}
          selectedTabId={selectedConfigTabId}
          tabs={TabNames}
        />
        <div className={styles.configMenu}>{renderTabConfigOptions()}</div>
      </div>
      {isChangeDpIdModalOpen ? (
        <ChangeDataPanelIdModal
          dataPanel={dataPanel}
          onClose={() => setIsChangeDpIdModalOpen(false)}
        />
      ) : null}
    </>
  );
};
