import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Dashboard } from 'actions/dashboardActions';
import { fetchDashboardVersionsMetadata } from 'actions/dashboardV2Actions';
import { ReportBuilder } from 'actions/reportBuilderActions';
import { fetchReportBuilderVersionsMetadata } from 'actions/reportBuilderVersionActions';
import { AlertModal, Select, Spinner, sprinkles } from 'components/ds';
import { SelectItems } from 'components/ds/Select';
import { ReduxState } from 'reducers/rootReducer';
import { cloneResource } from 'reducers/thunks/versionManagementThunks';
import * as RD from 'remotedata';
import { getCloneResourceSuccessUrl } from 'utils/exploResourceUtils';

type DuplicateAlertModalProps = {
  isExploreProduct: boolean;
  isOpen: boolean;
  resource: Dashboard | ReportBuilder;
  // The root resource to initiate the duplicate action from.
  rootResource: Dashboard | ReportBuilder;
  onClose: () => void;
  setLoadingStateForResource?: (isLoading: boolean) => void;
};

export const DuplicateResourceModal: FC<DuplicateAlertModalProps> = ({
  isExploreProduct,
  isOpen,
  resource,
  rootResource,
  onClose,
  setLoadingStateForResource,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { versions, versionsRD } = useSelector((state: ReduxState) => {
    // We use `RD.getOrDefault` instead of `RD.isSuccess` to safely extract `versions`.
    // Needed because RD.isSuccess requires same type. Able to still get status checks with versionsRD.
    if (isExploreProduct) {
      return {
        versions: RD.getOrDefault(state.dashboardVersions.versionsMetadata, []),
        versionsRD: state.dashboardVersions.versionsMetadata,
      };
    }
    return {
      versions: RD.getOrDefault(state.reportBuilderEdit.versionsMetadata, []),
      versionsRD: state.reportBuilderEdit.versionsMetadata,
    };
  }, shallowEqual);

  useEffect(() => {
    if (!isOpen || !RD.isIdle(versionsRD)) return;

    if (isExploreProduct) {
      dispatch(fetchDashboardVersionsMetadata({ id: resource.id }));
    } else {
      dispatch(fetchReportBuilderVersionsMetadata({ id: resource.id }));
    }
  }, [isExploreProduct, resource.id, versionsRD, isOpen, dispatch]);

  const [selectedVersion, setSelectedVersion] = useState<string>();

  const versionOptions = useMemo(() => {
    const options: SelectItems<string> = [];

    versions.forEach(({ version_number, is_draft, change_comments }) => {
      const versionNumber = version_number.toString();
      options.push({
        value: versionNumber,
        label: is_draft ? 'In-progress draft' : `Version ${versionNumber}`,
        secondaryLabel: change_comments ?? undefined,
      });
    });

    return options;
  }, [versions]);

  useEffect(() => setSelectedVersion(versionOptions[0]?.value), [versionOptions]);

  const onDuplicate = useCallback(() => {
    if (selectedVersion === undefined) {
      throw Error('Duplication would be disabled if selectedVersion is undefined');
    }

    setLoadingStateForResource?.(true);
    onClose();

    dispatch(
      cloneResource(
        isExploreProduct,
        { id: rootResource.id, version_number: Number(selectedVersion) },
        (data) => {
          setLoadingStateForResource?.(false);
          history.push(getCloneResourceSuccessUrl(isExploreProduct, data.new_resource.id));
        },
      ),
    );
  }, [
    selectedVersion,
    setLoadingStateForResource,
    onClose,
    dispatch,
    isExploreProduct,
    rootResource.id,
    history,
  ]);

  const titleIconText = useMemo(() => {
    const resourceName = isExploreProduct ? 'dashboard' : 'report builder';
    return rootResource.id !== resource.id
      ? `Duplicating this ${resourceName} will duplicate the entire hierarchy`
      : undefined;
  }, [rootResource.id, resource.id, isExploreProduct]);

  return (
    <AlertModal
      actionButtonProps={{
        text: 'Duplicate',
        onClick: onDuplicate,
        variant: 'primary',
        disabled: RD.isLoading(versionsRD) || selectedVersion === undefined,
      }}
      isOpen={isOpen}
      onClose={onClose}
      title={`Duplicate: ${resource.name}?`}
      titleIconText={titleIconText}>
      <div
        className={sprinkles({ flexItems: 'centerColumn', parentContainer: 'fill' })}
        style={{ height: 64 }}>
        {RD.isLoading(versionsRD) ? (
          <Spinner fillContainer />
        ) : (
          <Select
            className={sprinkles({ paddingX: 'sp2', parentContainer: 'fill' })}
            filterProps={{
              placeholder: 'Search versions',
              allowSearchOnSecondaryLabel: true,
            }}
            label="Select version to duplicate"
            onChange={setSelectedVersion}
            selectedValue={selectedVersion}
            values={versionOptions}
          />
        )}
      </div>
    </AlertModal>
  );
};
