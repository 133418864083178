import cx from 'classnames';
import { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { BaseCol, DatasetSchema } from '@explo/data';

import {
  DatasetConfig,
  DrilldownColConfig,
  DrilldownColConfigOptions,
  DrilldownConfig,
} from 'actions/datasetActions';
import { InfoCard } from 'components/InfoCard';
import {
  SortableList,
  SortableListItem,
  SortableListItemDragHandle,
} from 'components/SortableList/SortableList';
import {
  APP_PORTAL_ID,
  Checkbox,
  IconButton,
  Input,
  Select,
  sprinkles,
  Switch,
  Tooltip,
} from 'components/ds';
import { COLUMN_FITS, ColumnFitOptions } from 'constants/types';
import { ReduxState } from 'reducers/rootReducer';
import { extendedDrilldownConfigToNormal, initConfig } from 'utils/drilldownDatasetUtils';
import { titleCase } from 'utils/graphUtils';
import { sortBy } from 'utils/standard';

import * as styles from './styles.css';

type Props = {
  datasetConfig: DatasetConfig;
  datasetSchema: DatasetSchema;
  selectedDrilldownColumn: BaseCol | undefined;

  onUpdateDrilldownConfig: (datasetId: string, updates: Partial<DrilldownConfig>) => void;
  onUpdateColumnConfig: (
    datasetId: string,
    columnName: string,
    newColumnConfigs: DrilldownColConfigOptions,
  ) => void;
  onUpdateColumnConfigs: (
    datasetId: string,
    newColumnConfigs: Record<string, DrilldownColConfig>,
  ) => void;
  onSelectDrilldownColumn: (columnInfo: BaseCol | undefined) => void;
};

export interface ExtendedDrilldownColConfig extends DrilldownColConfig {
  name: string;
  type: string;
}

export const DrilldownDatasetColumns: FC<Props> = ({
  datasetConfig,
  datasetSchema,
  selectedDrilldownColumn,
  onUpdateDrilldownConfig,
  onUpdateColumnConfig,
  onUpdateColumnConfigs,
  onSelectDrilldownColumn,
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  const enableNewGrid = useSelector(
    (state: ReduxState) => !!state.currentUser?.team?.feature_flags.enable_new_grid,
  );

  /** Schema is source of truth
   * 1. Map over schema to get name and type - schema is source of truth
   * 2. If drilldown config exists, which it won't for previously saved datasets
   *    For each column in the schema find the corresponding drilldown info
   *    If it exists, add the corresponding config info
   *    If it doesn't exist, assume isIncluded and isVisible, and index by:
   *    have a count starting at length of drilldown config or 0, and assign index as we go
   */
  const schemaMappedWithConfigs: ExtendedDrilldownColConfig[] = useMemo(() => {
    let countingIndex = Object.keys(datasetConfig.drilldownColumnConfigs || {})?.length;

    return datasetSchema.map((col) => {
      if (datasetConfig.drilldownColumnConfigs?.[col.name]) {
        return {
          name: col.name,
          type: col.type,
          ...datasetConfig.drilldownColumnConfigs[col.name],
        };
      } else {
        const initExtendedConfig = {
          name: col.name,
          type: col.type,
          ...initConfig(countingIndex, titleCase(col.name)),
        };
        countingIndex += 1;
        return initExtendedConfig;
      }
    });
  }, [datasetConfig, datasetSchema]);

  const sortableColumns = useMemo(
    () => sortBy(schemaMappedWithConfigs, 'index'),
    [schemaMappedWithConfigs],
  );

  const isSearching = searchQuery.trim() !== '';
  const filteredSortableColumns = useMemo(() => {
    if (!isSearching) return sortableColumns;

    const loweredSearch = searchQuery.toLowerCase();
    return sortableColumns.reduce((acc, col) => {
      if (col.name.toLowerCase().includes(loweredSearch)) {
        acc.push(col);
      }
      return acc;
    }, [] as ExtendedDrilldownColConfig[]);
  }, [searchQuery, sortableColumns, isSearching]);

  const numColsIncluded = useMemo(
    () => filteredSortableColumns.reduce((sum, c) => (c.isIncluded ? sum + 1 : sum), 0),
    [filteredSortableColumns],
  );
  const shouldSelectAll = numColsIncluded !== filteredSortableColumns.length;

  const updateIsIncluded = useCallback(
    (col: ExtendedDrilldownColConfig) => {
      onUpdateColumnConfig(datasetConfig.id, col.name, {
        isIncluded: !col.isIncluded,
        isVisible: !col.isIncluded,
      });
    },
    [datasetConfig, onUpdateColumnConfig],
  );

  const updateIsVisible = useCallback(
    (col: ExtendedDrilldownColConfig) => {
      onUpdateColumnConfig(datasetConfig.id, col.name, { isVisible: !col.isVisible });
    },
    [datasetConfig, onUpdateColumnConfig],
  );

  const selectAll = useCallback(
    (isSelected: boolean) => {
      filteredSortableColumns.forEach((col) =>
        onUpdateColumnConfig(datasetConfig.id, col.name, {
          isIncluded: isSelected,
          isVisible: isSelected,
        }),
      );
    },
    [filteredSortableColumns, datasetConfig, onUpdateColumnConfig],
  );

  return (
    <div
      className={styles.drilldownDatasetColumnsRoot}
      onClick={(e) => {
        onSelectDrilldownColumn(undefined);
        e.stopPropagation();
      }}>
      <div className={sprinkles({ flexItems: 'column', padding: 'sp1', gap: 'sp2' })}>
        <InfoCard
          noTopMargin
          text="This sets the dataset level formatting settings for the underlying data modal."
        />
        {enableNewGrid ? (
          <Select
            label={{ text: 'Column Fit', infoText: 'Automatically sizes columns.' }}
            onChange={(value) => {
              onUpdateDrilldownConfig(datasetConfig.id, {
                columnFit: value as COLUMN_FITS,
              });
            }}
            selectedValue={datasetConfig.drilldownConfig?.columnFit ?? COLUMN_FITS.FILL}
            values={ColumnFitOptions}
          />
        ) : null}
        <Switch
          label={{
            text: 'Ignore invalid dates',
            infoText: "Replace 'Invalid Date' with empty value",
          }}
          onChange={() => {
            onUpdateDrilldownConfig(datasetConfig.id, {
              ignoreInvalidDates: !datasetConfig.drilldownConfig?.ignoreInvalidDates,
            });
          }}
          switchOn={datasetConfig.drilldownConfig?.ignoreInvalidDates}
        />
      </div>
      <div
        className={sprinkles({ flexItems: 'column', padding: 'sp1', gap: 'sp1', heading: 'h4' })}>
        <div>Dataset Columns</div>
        <Input
          leftIcon="search"
          onChange={setSearchQuery}
          placeholder="Search columns"
          value={searchQuery}
        />
      </div>

      {isSearching && filteredSortableColumns.length === 0 ? (
        <div className={styles.noResultsText}>No results</div>
      ) : (
        <>
          <div
            className={sprinkles({ flexItems: 'alignCenter', marginLeft: 'sp4', paddingY: 'sp1' })}>
            <div className={sprinkles({ flexItems: 'alignCenter' })}>
              <Checkbox
                isChecked={!shouldSelectAll}
                isIndeterminate={!shouldSelectAll}
                onChange={() => selectAll(shouldSelectAll)}
              />
              <div className={styles.columnsSelectTextClass}>COLUMNS</div>
            </div>
          </div>
          <div className={sprinkles({ flexItems: 'column', overflowY: 'auto', flex: 1 })}>
            <SortableList
              getIdFromElem={(col) => col.name}
              onListUpdated={(newCols) => {
                const updatedIndicesConfig = newCols.map((col, index) => {
                  return { ...col, index };
                });
                const newConfigs = extendedDrilldownConfigToNormal(updatedIndicesConfig);
                onUpdateColumnConfigs(datasetConfig.id, newConfigs);
              }}
              sortableItems={sortableColumns}>
              {filteredSortableColumns.map((col) => (
                <SortableListItem
                  className={sprinkles({ cursor: 'pointer' })}
                  key={col.name}
                  sortId={col.name}>
                  <SortableColumn
                    col={col}
                    key={col.name}
                    onSelectDrilldownColumn={onSelectDrilldownColumn}
                    selectedDrilldownColumn={selectedDrilldownColumn}
                    updateIsIncluded={() => updateIsIncluded(col)}
                    updateIsVisible={() => updateIsVisible(col)}
                  />
                </SortableListItem>
              ))}
            </SortableList>
          </div>
        </>
      )}
    </div>
  );
};

type SortableColumnProps = {
  col: ExtendedDrilldownColConfig;
  selectedDrilldownColumn: BaseCol | undefined;
  updateIsIncluded: () => void;
  updateIsVisible: () => void;
  onSelectDrilldownColumn: (columnInfo: BaseCol | undefined) => void;
};

const SortableColumn: FC<SortableColumnProps> = ({
  col,
  selectedDrilldownColumn,
  updateIsIncluded,
  updateIsVisible,
  onSelectDrilldownColumn,
}) => {
  return (
    <div
      className={cx(
        styles.baseColumn,
        { [styles.hoverColumn]: selectedDrilldownColumn?.name !== col.name },
        { [styles.selectedColumn]: selectedDrilldownColumn?.name === col.name },
      )}>
      <div
        className={sprinkles({ flexItems: 'alignCenter' })}
        onClick={(e) => {
          const selectedColumnInfo = col.isIncluded
            ? { name: col.name, type: col.type }
            : undefined;
          onSelectDrilldownColumn(selectedColumnInfo);
          e.stopPropagation();
        }}>
        <SortableListItemDragHandle
          className={cx(
            sprinkles({ color: 'contentTertiary', marginRight: 'sp1' }),
            styles.dragHandle,
          )}
        />
        <Tooltip text={col.isIncluded ? 'Visible to customer' : 'Not visible to customer'}>
          <span onClick={(e) => e.stopPropagation()}>
            <Checkbox
              isChecked={col.isIncluded}
              onChange={() => {
                updateIsIncluded();
                const selectedColumnInfo = col.isIncluded
                  ? { name: col.name, type: col.type }
                  : undefined;
                onSelectDrilldownColumn(selectedColumnInfo);
              }}
            />
          </span>
        </Tooltip>
        <Tooltip text={col.name}>
          <div
            className={cx(
              styles.colNameClass,
              sprinkles({ color: col.isIncluded ? undefined : 'contentTertiary' }),
            )}>
            {col.name}
          </div>
        </Tooltip>
        <Tooltip
          portalContainerId={APP_PORTAL_ID}
          text={col.isVisible ? 'Start visible' : 'Do not start visible'}>
          <span className={sprinkles({ marginLeft: 'auto' })}>
            <IconButton
              disabled={!col.isIncluded}
              name={col.isVisible ? 'eye-open' : 'eye-closed'}
              onClick={(e) => {
                if (col.isIncluded) {
                  if (!col.isVisible) {
                    onSelectDrilldownColumn({ name: col.name, type: col.type });
                  }
                  updateIsVisible();
                }
                e.stopPropagation();
              }}
            />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};
