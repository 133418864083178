import { Branch } from '@explo-tech/fido-api';
import cx from 'classnames';
import {
  Button,
  Icon,
  IconButton,
  Menu,
  MenuActionItem,
  MenuSeparator,
  sprinkles,
} from 'components/ds';
import { IconName } from 'components/ds/Icon';
import { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ItemType, switchCurrentBranch, BranchOperationStatus } from 'reducers/dataLibraryReducer';
import { ReduxState } from 'reducers/rootReducer';
import { deleteBranchThunk, listBranchContentThunk } from 'reducers/thunks/fidoThunks/branchThunks';
import { isError, isLoading, isSuccess, ResponseData, Success } from 'remotedata';
import { branchMenuItemClassName } from './BranchMenu.css';
import { ROOT_FOLDER_PATH } from './constants';
import { isBranchMain } from './dataLibraryUtil';
import { navigateToPathThunk } from './navigationUtils';
import { getRootFolderForBranch } from './selectors';
import { RenameBranchModal } from './RenameBranchModal';
import { DeleteResourceConfirmationModal } from 'shared/ExploResource/DeleteResourceConfirmationModal';

interface Props {
  currentBranch: Branch;
  branches: Map<string, Branch>;
  branchOperationStatus: ResponseData<BranchOperationStatus>;

  onCreateNewBranchClicked: () => void;
}

enum BranchModification {
  RENAME = 'rename',
  DELETE = 'delete',
}

export const BranchMenu: FC<Props> = ({
  currentBranch,
  branches,
  branchOperationStatus,
  onCreateNewBranchClicked,
}) => {
  const [selectedBranchToModify, setSelectedBranchToModify] = useState<Branch | undefined>();
  const [branchModification, setBranchModification] = useState<BranchModification | undefined>();

  const history = useHistory();
  const dispatch = useDispatch();

  const { rootFolderData, baseFoldersForAllBranches } = useSelector((state: ReduxState) => {
    return {
      rootFolderData: getRootFolderForBranch(state, Success(currentBranch)),
      baseFoldersForAllBranches: state.dataLibrary.folders,
    };
  });
  const sortedBranches = useMemo(() => {
    return Array.from(branches.values()).sort((firstBranch, secondBranch) => {
      if (isBranchMain(firstBranch)) {
        return -1;
      } else if (isBranchMain(secondBranch)) {
        return 1;
      }

      return firstBranch.name.localeCompare(secondBranch.name);
    });
  }, [branches]);
  const buttonIconConfig: {
    name: IconName;
    class: string;
  } = useMemo(() => {
    if (isSuccess(branchOperationStatus)) {
      return {
        name: 'check',
        class: sprinkles({ color: 'success' }),
      };
    } else if (isLoading(branchOperationStatus)) {
      return {
        name: 'spinner',
        class: sprinkles({ color: 'active' }),
      };
    } else if (isError(branchOperationStatus)) {
      return {
        name: 'circle-exclamation-reg',
        class: sprinkles({ color: 'error' }),
      };
    }

    return {
      name: 'code-branch',
      class: '',
    };
  }, [branchOperationStatus]);

  if (!isSuccess(rootFolderData)) {
    return null;
  }

  return (
    <>
      {selectedBranchToModify && branchModification === BranchModification.RENAME && (
        <RenameBranchModal
          allBranches={sortedBranches}
          onClose={() => setSelectedBranchToModify(undefined)}
          selectedBranch={selectedBranchToModify}
        />
      )}
      {selectedBranchToModify && branchModification === BranchModification.DELETE && (
        <DeleteResourceConfirmationModal
          confirmationButtonText="Delete branch"
          onClose={() => setSelectedBranchToModify(undefined)}
          onDelete={() => {
            dispatch(deleteBranchThunk({ id: selectedBranchToModify.id ?? '' }));
            setSelectedBranchToModify(undefined);
          }}
          resourceName={selectedBranchToModify.name}
          titleText={`Type DELETE to permanently delete branch ${selectedBranchToModify.name}`}
        />
      )}
      <Menu
        trigger={
          <Button name="code-branch" variant="secondary">
            <Icon
              className={cx(buttonIconConfig.class, sprinkles({ marginRight: 'sp1' }))}
              name={buttonIconConfig.name}
            />
            <span className={sprinkles({ truncateText: 'ellipsis' })}>{currentBranch.name}</span>
          </Button>
        }>
        {sortedBranches.map((branch) => {
          return (
            <div className={branchMenuItemClassName} key={branch.id}>
              <MenuActionItem
                iconName="code-branch"
                onSelect={() => {
                  dispatch(switchCurrentBranch(branch));
                  const rootFolder = rootFolderData.data;
                  dispatch(
                    navigateToPathThunk(
                      rootFolder.id ?? '',
                      ItemType.FOLDER,
                      ROOT_FOLDER_PATH,
                      history,
                    ),
                  );

                  const baseFoldersForNewBranch = baseFoldersForAllBranches.get(branch.id ?? '');
                  if (!baseFoldersForNewBranch?.has(ROOT_FOLDER_PATH)) {
                    dispatch(
                      listBranchContentThunk({
                        id: branch.id ?? '',
                        path: ROOT_FOLDER_PATH,
                        resourceType: ItemType.FOLDER,
                      }),
                    );
                  }
                }}
                text={branch.name}
                textClassName={isBranchMain(branch) ? sprinkles({ fontWeight: 700 }) : ''}
              />
              <div
                className={sprinkles({
                  display: 'flex',
                  flexDirection: 'row',
                  flexGrow: 1,
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                })}>
                {currentBranch.id === branch.id ? (
                  <Icon
                    className={cx(
                      sprinkles({ color: 'active', marginLeft: 'sp1' }),
                      isBranchMain(branch) && sprinkles({ marginRight: 'sp1.5' }),
                    )}
                    name="check"
                  />
                ) : null}
                <div>
                  {!isBranchMain(branch) && (
                    <Menu trigger={<IconButton name="ellipsis-vertical" />}>
                      <MenuActionItem
                        iconName="pencil"
                        onSelect={() => {
                          setSelectedBranchToModify(branch);
                          setBranchModification(BranchModification.RENAME);
                        }}
                        text="Edit"
                      />
                      <MenuSeparator />
                      <MenuActionItem
                        isDestructive
                        iconName="trash"
                        onSelect={() => {
                          setSelectedBranchToModify(branch);
                          setBranchModification(BranchModification.DELETE);
                        }}
                        text="Delete"
                      />
                    </Menu>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        <MenuSeparator />
        <MenuActionItem
          disabled={!isBranchMain(currentBranch) || isLoading(branchOperationStatus)}
          iconName="plus"
          key="create-new-branch"
          onSelect={onCreateNewBranchClicked}
          text="Create new branch"
          tooltipProps={{
            text: !isBranchMain ? 'Cannot create new branch from non-main branch' : undefined,
          }}
        />
      </Menu>
    </>
  );
};
