import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Branch, VersionedViewRequest } from '@explo-tech/fido-api';
import { clearDashboardConfigReducer } from 'actions/dashboardV2Actions';
import { listTeamDataSources } from 'actions/dataSourceActions';
import { fetchAllSchemaTables, fetchUsedParentSchemas } from 'actions/parentSchemaActions';
import { FeatureFlags } from 'types/flags';
import { getSelectedCustomer } from 'reducers/customersReducer';
import { setInteractionsInfo } from 'reducers/dashboardInteractionsReducer';
import {
  clearComputedViews,
  clearReferencedGlobalDatasets,
  markGlobalDatasetsAsLoaded,
  markLatestGlobalDatasetsAsLoaded,
} from 'reducers/fidoReducer';
import { clearReportBuilderReducer } from 'reducers/reportBuilderEditReducer';
import { DashboardStates, ReduxState } from 'reducers/rootReducer';
import { initializeCustomerSelectorThunk } from 'reducers/thunks/customerThunks';
import { getComputedViews, getNamespaces } from 'reducers/thunks/fidoThunks';
import { listBranchesThunk } from 'reducers/thunks/fidoThunks/branchThunks';
import {
  batchGetLatestVersionedViews,
  batchGetVersionedViews,
} from 'reducers/thunks/fidoThunks/viewThunks';
import { Dispatch } from 'redux';
import { hasNotReturned, isIdle, isSuccess } from 'remotedata';
import { VIEW_MODE } from 'types/dashboardTypes';
import { VersionedComputedViewReference } from 'types/dashboardVersionConfig';
import { isEmpty } from 'utils/standard';

import { Location } from 'history';
import { useHistory } from 'react-router';
import { SaveStatus } from 'reducers/resourceSavingReducer';
import { areThereUnsavedChanges } from 'reducers/resourceSavingUtils';
import { listResourceEnvironmentTags } from 'reducers/thunks/resourceEnvironmentTagThunks';
import * as RD from 'remotedata';
import { ReadAccessComputedView } from 'utils/fido/fidoShimmedTypes';

// This custom hook loads all the basic data needed for editing experience,
// and returns the loading state of the metadata - no need to load customers in
export function useLoadEditMetadata(
  viewIds: string[] | undefined,
  globalDatasetReferences: Record<string, VersionedComputedViewReference> | undefined,
  referencedGlobalDatasets: RD.ResponseData<Record<string, ReadAccessComputedView>>,
  latestReferencedGlobalDatasets: RD.ResponseData<Record<string, ReadAccessComputedView>>,
  deletedLatestReferencedGlobalDatasetIds: Set<string>,
) {
  const dispatch = useDispatch();
  const {
    parentSchemas,
    schemaTablesMap,
    selectedCustomer,
    fetchCustomerStatus,
    dataSources,
    shouldUseFido,
    fido,
    envTags,
    currentUser,
    mainBranch,
  } = useSelector(
    (state: ReduxState) => ({
      parentSchemas: state.parentSchemas.usedParentSchemas,
      schemaTablesMap: state.parentSchemas.schemaTablesMap,
      selectedCustomer: getSelectedCustomer(state.customers),
      fetchCustomerStatus: state.customers.fetchCustomerStatus,
      dataSources: state.dataSource.dataSources,
      fido: state.fido,
      shouldUseFido: state.currentUser.team?.feature_flags.use_fido,
      envTags: state.environmentTags.tags,
      currentUser: state.currentUser,
      mainBranch: state.dataLibrary.mainBranch,
    }),
    shallowEqual,
  );

  const { computedViews, fidoDaos, embeddoDaos } = fido;

  useEffect(() => {
    if (isIdle(envTags)) dispatch(listResourceEnvironmentTags({}));
  }, [dispatch, envTags]);

  useEffect(() => {
    if (isIdle(parentSchemas)) dispatch(fetchUsedParentSchemas());
  }, [dispatch, parentSchemas]);

  useEffect(() => {
    if (isIdle(dataSources)) dispatch(listTeamDataSources());
  }, [dispatch, dataSources]);

  useEffect(() => {
    if (isIdle(schemaTablesMap)) dispatch(fetchAllSchemaTables());
  }, [dispatch, schemaTablesMap]);

  useEffect(() => {
    if (!selectedCustomer && isIdle(fetchCustomerStatus)) {
      dispatch(initializeCustomerSelectorThunk(true));
    }
  }, [dispatch, selectedCustomer, fetchCustomerStatus]);

  useEffect(() => {
    if (!shouldUseFido || !viewIds?.length) return;

    dispatch(getComputedViews(viewIds));
  }, [dispatch, viewIds, shouldUseFido]);

  useEffect(() => {
    if (!shouldUseFido || !isIdle(fidoDaos)) return;

    dispatch(getNamespaces());
  }, [dispatch, fidoDaos, shouldUseFido, embeddoDaos]);

  useEffect(() => {
    return function clearEdit() {
      dispatch(clearDashboardConfigReducer());
      dispatch(clearReportBuilderReducer());
      dispatch(clearComputedViews());
      dispatch(clearReferencedGlobalDatasets());
    };
  }, [dispatch]);

  const isFetchingGlobalDatasetInfo = useFetchGlobalDatasetInfo(
    mainBranch,
    currentUser.team?.feature_flags,
    globalDatasetReferences,
    referencedGlobalDatasets,
    latestReferencedGlobalDatasets,
    deletedLatestReferencedGlobalDatasetIds,
    dispatch,
  );

  const isFidoLoaded =
    !shouldUseFido || ((viewIds?.length === 0 || isSuccess(computedViews)) && isSuccess(fidoDaos));

  /**
   * For customer logic: in order to load a dashboard we need to know who our selected customer is first. This means we should be in the loading
   * state so long as we have not populated our selected customer and we have not finished trying to fetch our initial customer.
   */
  return (
    !isSuccess(schemaTablesMap) ||
    (!selectedCustomer && hasNotReturned(fetchCustomerStatus)) ||
    !isSuccess(parentSchemas) ||
    !isFidoLoaded ||
    isFetchingGlobalDatasetInfo
  );
}

export function useFidoDataSourceData() {
  const dispatch = useDispatch();
  const { parentSchemas, schemaTablesMap, dataSources, shouldUseFido, fido } = useSelector(
    (state: ReduxState) => ({
      parentSchemas: state.parentSchemas.usedParentSchemas,
      schemaTablesMap: state.parentSchemas.schemaTablesMap,
      dataSources: state.dataSource.dataSources,
      fido: state.fido,
      shouldUseFido: state.currentUser.team?.feature_flags.use_fido,
    }),
    shallowEqual,
  );

  const { fidoDaos, embeddoDaos } = fido;

  useEffect(() => {
    if (isIdle(parentSchemas)) dispatch(fetchUsedParentSchemas());
  }, [dispatch, parentSchemas]);

  useEffect(() => {
    if (isIdle(dataSources)) dispatch(listTeamDataSources());
  }, [dispatch, dataSources]);

  useEffect(() => {
    if (isIdle(schemaTablesMap)) dispatch(fetchAllSchemaTables());
  }, [dispatch, schemaTablesMap]);

  useEffect(() => {
    if (!shouldUseFido || !isIdle(fidoDaos)) return;

    dispatch(getNamespaces());
  }, [dispatch, fidoDaos, shouldUseFido, embeddoDaos]);

  const isFidoLoaded = !shouldUseFido || isSuccess(fidoDaos);

  return !isSuccess(schemaTablesMap) || !isSuccess(parentSchemas) || !isFidoLoaded;
}

/**
 * A simple React hook for differentiating single and double clicks on the same component.
 *
 * @param {number} [latency=300] The amount of time (in milliseconds) to wait before differentiating a single from a double click
 * @param {function} onSingleClick A callback function for single click events
 * @param {function} onDoubleClick A callback function for double click events
 */
export const useDoubleClick = ({
  latency = 300,
  onSingleClick,
  onDoubleClick,
}: {
  latency?: number;
  onSingleClick: () => void;
  onDoubleClick: () => void;
}) => {
  const [click, setClick] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (click === 1) onSingleClick();
      setClick(0);
    }, latency);

    if (click === 2) onDoubleClick();

    return () => {
      clearTimeout(timeout);
    };
  }, [click, latency, onSingleClick, onDoubleClick]);

  return { triggerClick: () => setClick((prev) => prev + 1) };
};

type InteractionsProps = {
  isEditing?: boolean;
  updateUrlParams?: boolean;
  disableStringifyOnStringUrlParams?: boolean;
  viewMode?: VIEW_MODE;
  disableFiltersWhileLoading?: boolean;
  disableInputs?: boolean;
  supportEmail?: string;
  disableEditingEditableSection?: boolean;
  hideEditableSectionEditControls?: boolean;
  shouldPersistCustomerState?: boolean;
};

// This hook makes sure that only the latest in interactions info state is being used
export const useDashboardInteractionsInfo = ({
  isEditing,
  viewMode,
  updateUrlParams,
  disableStringifyOnStringUrlParams,
  disableFiltersWhileLoading,
  supportEmail,
  disableInputs,
  disableEditingEditableSection,
  hideEditableSectionEditControls,
  shouldPersistCustomerState,
}: InteractionsProps) => {
  const dispatch = useDispatch();

  const latestInteractionsInfo = useSelector(
    (state: DashboardStates) => state.dashboardInteractions.interactionsInfo,
  );

  useEffect(() => {
    dispatch(
      setInteractionsInfo({
        isEditing: isEditing ?? false,
        viewMode: viewMode ?? VIEW_MODE.DEFAULT,
        updateUrlParams,
        disableStringifyOnStringUrlParams,
        disableFiltersWhileLoading,
        disableInputs,
        supportEmail,
        disableEditingEditableSection,
        hideEditableSectionEditControls,
        shouldPersistCustomerState,
      }),
    );
  }, [
    dispatch,
    isEditing,
    viewMode,
    updateUrlParams,
    disableStringifyOnStringUrlParams,
    disableFiltersWhileLoading,
    disableInputs,
    supportEmail,
    disableEditingEditableSection,
    shouldPersistCustomerState,
    hideEditableSectionEditControls,
  ]);

  return latestInteractionsInfo;
};

/**
 * @param globalDatasetReferences The referenced global datasets by the resource. Undefined means
 * that the resource configuration has not finished loading and we should not attempt to fetch the
 * backing global datasets.
 * @returns
 */
export const useFetchGlobalDatasetInfo = (
  currentBranch: RD.ResponseData<Branch>,
  featureFlags: FeatureFlags | undefined,
  globalDatasetReferences: Record<string, VersionedComputedViewReference> | undefined,
  referencedGlobalDatasets: RD.ResponseData<Record<string, ReadAccessComputedView>>,
  latestReferencedGlobalDatasets: RD.ResponseData<Record<string, ReadAccessComputedView>>,
  deletedLatestReferencedGlobalDatasetIds: Set<string>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
): boolean => {
  const isDataLibraryEnabled =
    (featureFlags?.enable_data_library && featureFlags?.use_fido) ?? false;
  useEffect(() => {
    if (!isDataLibraryEnabled) {
      return;
    }

    if (!RD.isIdle(currentBranch)) {
      return;
    }

    dispatch(listBranchesThunk());
  }, [dispatch, currentBranch, isDataLibraryEnabled]);

  const isFetchingGlobalDatasets = useFetchReferencedGlobalDatasets(
    globalDatasetReferences,
    referencedGlobalDatasets,
  );

  useEffect(() => {
    if (!isDataLibraryEnabled) {
      return;
    }

    if (
      !RD.isSuccess(currentBranch) ||
      globalDatasetReferences == undefined ||
      !RD.isIdle(latestReferencedGlobalDatasets)
    ) {
      return;
    }

    const datasetIdsToFetchLatestVersions = Object.values(globalDatasetReferences);
    if (datasetIdsToFetchLatestVersions.length > 0) {
      const versionedViewRequests = datasetIdsToFetchLatestVersions.map((datasetReference) => {
        return {
          branchId: currentBranch.data.id ?? '',
          viewId: datasetReference.id,
        };
      });
      dispatch(batchGetLatestVersionedViews(versionedViewRequests));
    } else {
      dispatch(markLatestGlobalDatasetsAsLoaded());
    }
  }, [
    dispatch,
    globalDatasetReferences,
    isDataLibraryEnabled,
    referencedGlobalDatasets,
    latestReferencedGlobalDatasets,
    currentBranch,
    deletedLatestReferencedGlobalDatasetIds,
  ]);

  return (
    (isFetchingGlobalDatasets ||
      !RD.isSuccess(currentBranch) ||
      (!RD.isSuccess(latestReferencedGlobalDatasets) && !isEmpty(globalDatasetReferences))) &&
    isDataLibraryEnabled
  );
};

export const useFetchReferencedGlobalDatasets = (
  globalDatasetReferences: Record<string, VersionedComputedViewReference> | undefined,
  referencedGlobalDatasets: RD.ResponseData<Record<string, ReadAccessComputedView>>,
): boolean => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Undefined globalDatasetReferences means that the resource configuration has not finished
    // loading.
    if (!RD.isIdle(referencedGlobalDatasets) || globalDatasetReferences == undefined) {
      return;
    }

    const globalDatasetReferencesToLoad = Object.values(globalDatasetReferences);
    if (globalDatasetReferencesToLoad.length > 0) {
      const versionedViewRequests: VersionedViewRequest[] = globalDatasetReferencesToLoad.map(
        (datasetReference) => {
          return {
            id: datasetReference.id,
            versionId: datasetReference.versionId,
          };
        },
      );
      dispatch(batchGetVersionedViews({ requests: versionedViewRequests }));
    } else {
      dispatch(markGlobalDatasetsAsLoaded());
    }
  }, [globalDatasetReferences, referencedGlobalDatasets, dispatch]);

  return !RD.isSuccess(referencedGlobalDatasets) && !isEmpty(globalDatasetReferences);
};

export const usePreventNavigationWithUnsavedChanges = (
  resourceSaveStatuses: Record<string, SaveStatus>,
  skipPreventionFn?: (location: Location<{}>) => boolean,
) => {
  const history = useHistory<{}>();

  useEffect(() => {
    const unblock = history.block((location: Location<{}>) => {
      if (skipPreventionFn?.(location)) {
        return undefined;
      }

      if (areThereUnsavedChanges(resourceSaveStatuses)) {
        return 'There are unsaved changes, are you sure you want to leave?';
      }

      return undefined;
    });

    return () => {
      unblock();
    };
  }, [resourceSaveStatuses, skipPreventionFn, history]);
};
