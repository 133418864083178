import cx from 'classnames';
import { FC } from 'react';

import { Colors, Icon, sprinkles } from 'components/ds';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { KPITrendDisplayFormat, KPIValueFormat } from 'constants/types';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { GlobalStyleConfig } from 'globalStyles/types';
import { formatValue } from 'pages/dashboardPage/charts/utils';

interface Props {
  globalStyleConfig: GlobalStyleConfig;
  /**
   * This should be how much the trend changed as either an absolute (e.g. 400,000) or percentage (e.g. 0.4) value
   */
  trendChangeVal?: number;
  /**
   * This is shown after the percent change text e.g. +40% <Comparison Period>
   */
  trendChangeValLabel: string;
  trendChangeValFormatId: string;
  displayFormat?: KPITrendDisplayFormat;
  valueFormat?: KPIValueFormat;
  noDataPrevPeriod: boolean;
  comparisonValue: number;
  currentValue: number;
}

export const TrendChange: FC<Props> = ({
  globalStyleConfig,
  trendChangeVal,
  trendChangeValFormatId,
  trendChangeValLabel,
  displayFormat,
  valueFormat,
  noDataPrevPeriod,
  comparisonValue,
  currentValue,
}) => {
  const { showTrendChangePeriodLabel, trendColorsReversed, useTrendTag, showPreviousValue } =
    displayFormat || {};

  if (trendChangeVal === undefined) return <></>;

  const isNegativeChange = trendChangeVal < 0 || (noDataPrevPeriod && currentValue < 0);
  const isPositiveChange = trendChangeVal > 0 || (noDataPrevPeriod && currentValue > 0);

  let backgroundColor: Colors = 'gray5';
  let trendColor: Colors = 'contentPrimary';
  if (isNegativeChange) {
    backgroundColor = trendColorsReversed ? 'green5' : 'red5';
    trendColor = trendColorsReversed ? 'green11' : 'red11';
  } else if (isPositiveChange) {
    backgroundColor = trendColorsReversed ? 'red5' : 'green5';
    trendColor = trendColorsReversed ? 'red11' : 'green11';
  }

  const sharedValueFormatConfigs = {
    hasCommas: true,
    customTimeFormat: valueFormat?.timeCustomerFormat,
    customDurationFormat: valueFormat?.customDurationFormat,
    timeFormatId: valueFormat?.timeFormat?.id,
    significantDigits: valueFormat?.significantDigits ?? 3,
    isSmartAbbreviate: valueFormat?.smartAbbreviate,
  };

  const isPercent = trendChangeValFormatId === V2_NUMBER_FORMATS.PERCENT.id;
  const defaultDecimalPlaces = isPercent ? 1 : 0;
  const formattedTrendChangeVal = formatValue({
    ...sharedValueFormatConfigs,
    value: Math.abs(trendChangeVal),
    decimalPlaces: displayFormat?.trendDecimalPlaces ?? defaultDecimalPlaces,
    formatId: trendChangeValFormatId,

    multiplier: isPercent ? 1 : valueFormat?.multiplyFactor,
  });

  const previousValue = noDataPrevPeriod
    ? undefined
    : formatValue({
        ...sharedValueFormatConfigs,
        value: comparisonValue,
        decimalPlaces: valueFormat?.decimalPlaces ?? 2,
        formatId: valueFormat?.numberFormat?.id || V2_NUMBER_FORMATS.NUMBER.id,
        multiplier: valueFormat?.multiplyFactor,
      });

  const displayValue = noDataPrevPeriod ? <Icon name="infinity" /> : formattedTrendChangeVal;

  const renderValueWithUnits = (value: string | JSX.Element, showUnits?: boolean) => {
    return (
      <span>
        {value}
        {showUnits ? (
          <span className={sprinkles({ marginLeft: valueFormat?.unitPadding ? 'sp.5' : 'sp0' })}>
            {valueFormat?.units}
          </span>
        ) : null}
      </span>
    );
  };
  return (
    <div
      className={cx(
        embedSprinkles({ body: 'primaryWithoutColor' }),
        sprinkles({
          flexItems: 'center',
          gap: 'sp1',
          color: 'contentTertiary',
        }),
      )}>
      <div
        className={cx(sprinkles({ color: trendColor, flexItems: 'center', gap: 'sp.5' }), {
          [sprinkles({
            padding: 'sp1',
            borderRadius: 2,
            backgroundColor,
          })]: useTrendTag,
        })}>
        <Icon
          name={isNegativeChange ? 'caret-down' : 'caret-up'}
          style={{
            width: globalStyleConfig.text.textSize,
            height: globalStyleConfig.text.textSize,
          }}
        />
        {renderValueWithUnits(displayValue, !!valueFormat?.units && !isPercent)}
        {showTrendChangePeriodLabel ? trendChangeValLabel : null}
      </div>

      {showPreviousValue && previousValue ? (
        <div className={sprinkles({ flexItems: 'center', gap: 'sp1' })}>
          <Icon name="circle" size="xs" />
          <div>vs. {renderValueWithUnits(previousValue, !!valueFormat?.units)}</div>
        </div>
      ) : null}
    </div>
  );
};
