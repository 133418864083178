import { FC, useState } from 'react';

import { Popover, Switch } from 'components/ds';
import { CustomerSelect } from './selects/CustomerSelect';
import { GroupTagSelect } from './selects/GroupTagSelect';
import { HierarchySelect } from './selects/HierarchySelect';

import { RecipientsDto } from '@explo/embeddo-api';
import { flattenRecipientFilter } from './recipientsFilterShims';
import * as styles from './styles.css';
import { FlattenedRecipients } from './types';

interface Props {
  recipients: RecipientsDto | undefined;
}

export const RecipientPopover: FC<Props> = ({ recipients }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<FlattenedRecipients | null>(
    flattenRecipientFilter(recipients?.recipientsFilter ?? null),
  );

  const handleSendToAllChange = (checked: boolean) => {
    setSelected(
      checked
        ? null
        : recipients?.recipientsFilter
          ? flattenRecipientFilter(recipients?.recipientsFilter)
          : { hierarchyIds: [], groupTagIds: [], customerIds: [] },
    );
  };

  const updateSelected = (recipients: FlattenedRecipients | null) => {
    setSelected(
      recipients == null
        ? null
        : {
            hierarchyIds: recipients.hierarchyIds,
            // Group tags selects are not available without selected hierarchy
            groupTagIds: recipients.hierarchyIds.length > 0 ? recipients.groupTagIds : [],
            customerIds: recipients.customerIds,
          },
    );
  };

  return (
    <Popover
      align="start"
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      primaryButtonProps={{
        text: 'Done',
        onClick: () => {
          // TODO: handle done
          setIsOpen(false);
        },
      }}
      sideOffset={8}
      trigger={
        <p className={styles.recipientPopoverTrigger}>
          {selected == null ? 'Send to All' : `${recipients?.totalCount} recipients`}
        </p>
      }
      width="large">
      <div className={styles.recipientPopoverContainer}>
        <div className={styles.sectionHeader}>Select Recipients</div>
        <div className={styles.recipientSelectContent}>
          <div className={styles.recipientPopoverSwitch}>
            <Switch onChange={handleSendToAllChange} switchOn={selected == null} />
            Send to All
          </div>

          {selected != null ? (
            <>
              <div className={styles.hierarchyGroupSelectContainer}>
                <HierarchySelect onUpdate={updateSelected} selected={selected} />
                <div className={styles.groupTagSelectContainer}>
                  <GroupTagSelect
                    disabled={selected.hierarchyIds.length === 0}
                    onUpdate={updateSelected}
                    selected={selected}
                  />
                </div>
              </div>
              <div className={styles.recipientPopoverDivider} />
              <CustomerSelect onUpdate={updateSelected} selected={selected} />
              <div className={styles.recipientPopoverDivider} />
            </>
          ) : null}
        </div>
        {/* TODO: fetch number of recipients based on selected options as you go */}
        <div className={styles.recipientCount}>
          Total Recipients: {recipients?.totalCount} emails
        </div>
      </div>
    </Popover>
  );
};
