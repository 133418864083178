import {
  DataSourcePropertyOption,
  SupportedDataSource,
  DataSourceConfiguration,
  DataSourceConfigurationSchema,
  DataSourcePropertyOptions,
} from 'actions/dataSourceActions';
import { User } from 'actions/userActions';
import { Intent } from 'components/ds';
import { Props as InputWithTagProps } from 'shared/InputWithTag/InputWithTag';
import { every } from 'utils/standard';

import { DATABASES } from './constants';

export const areCredentialsComplete = (
  properties: DataSourcePropertyOptions,
  configuration: DataSourceConfiguration,
  isSecurityPage: boolean,
): boolean => {
  return every(
    Object.keys(properties).map((propertyName: string) => {
      const property = properties[propertyName];
      const configValue = configuration[propertyName];

      if (!property) return false;

      if (property.security_page === !isSecurityPage) return true;

      if ((property.depends_on && !configuration[property.depends_on || '']) || property.optional) {
        return true;
      }

      // Dropdowns have sub-properties that need to be recursively checked
      if (property.type === 'dropdown') {
        const dropdownValue = configValue as string;
        if (!property.options || !property.options[dropdownValue]) return false;

        return areCredentialsComplete(
          (property.options ?? {})[dropdownValue],
          configuration,
          isSecurityPage,
        );
      }

      // A checkbox field is configured since if it is not present, it is false
      if (property.type === 'checkbox') {
        return true;
      }

      return (
        configValue !== null &&
        configValue !== undefined &&
        configValue !== '' &&
        (typeof configValue !== 'number' || !isNaN(configValue))
      );
    }),
  );
};

export const getPropertyNames = (
  configurationSchema: DataSourceConfigurationSchema,
  isSecurityPage: boolean,
) =>
  configurationSchema.order.filter(
    (propertyName) =>
      !!configurationSchema.properties[propertyName].security_page === isSecurityPage,
  );

export const getHelpText = (
  dataSource: SupportedDataSource | undefined,
  property: DataSourcePropertyOption,
) => {
  const snowflakeAccountHelpText = (
    <>
      To learn more about Snowflake account identifiers{' '}
      <a
        href="https://docs.snowflake.com/en/user-guide/admin-account-identifier.html#where-are-account-identifiers-used"
        rel="noreferrer"
        style={{ color: 'white' }}
        target="_blank">
        click here
      </a>
    </>
  );

  return dataSource?.name === DATABASES.SNOWFLAKE && property.label === 'Account'
    ? snowflakeAccountHelpText
    : property.help_tooltip;
};

export const getStatusInfo = (
  isEmpty: boolean,
  isDisplayValid: boolean,
  idError?: string,
): InputWithTagProps['statusInfo'] => {
  if (idError)
    return {
      statusIcon: 'cross',
      statusIntent: Intent.ERROR,
      statusText: idError,
    };

  return {
    statusIcon: !isEmpty ? (isDisplayValid ? 'check' : 'cross') : undefined,
    statusIntent: !isEmpty ? (isDisplayValid ? Intent.SUCCESS : Intent.ERROR) : Intent.NONE,
    statusText: !isEmpty ? (isDisplayValid ? '' : `Can't be blank`) : undefined,
  };
};

const errorRegexes = [
  {
    regx: /Code: 210/i,
    description: (
      <>
        Explo only supports using the Clickhouse Native Protocol, which is commonly port 9440. Go to{' '}
        <a>https://clickhouse.com/docs/en/guides/sre/network-ports</a> for more information.
      </>
    ),
  },
];

export const parseErrorMessage = (errorMessage: string | undefined) =>
  errorRegexes.find((er) => errorMessage?.match(er.regx))?.description ?? errorMessage;

const userPingInfo = (currentUser: User) => {
  const { first_name, last_name, email, team } = currentUser;
  return `${first_name} ${last_name} (${email}) on team "${team?.team_name}"`;
};

const dbPingInfo = (
  isError: boolean | undefined,
  useFido: boolean | undefined,
  name: string | undefined,
  type: string | undefined,
) => {
  const errorMsg = isError ? 'that ERRORED' : '';
  const usingFidoInfo = useFido ? 'using fido' : '';
  return `data source connection ${errorMsg} (name: "${name}" and type "${type}") ${usingFidoInfo}`;
};

export const pingDataSourceConnectionMsg = (
  currentUser: User,
  name: string | undefined,
  type: string | undefined,
  didError: boolean | undefined,
  usingFido: boolean | undefined,
  isRetesting?: boolean,
  isJobQue?: boolean,
) => {
  const userInfo = userPingInfo(currentUser);
  const dbInfo = dbPingInfo(didError, usingFido, name, type);

  if (isJobQue) {
    if (isRetesting) {
      return `${userInfo}" is trying to re-test a ${dbInfo} using the job queue`;
    }
    return `${userInfo}" is trying to test a ${dbInfo} using the job queue`;
  }

  if (isRetesting) {
    if (didError) {
      return `${userInfo} tried to re-test an updated ${dbInfo}`;
    }
    return `${userInfo} successfully re-tested an updated ${dbInfo}`;
  }

  if (didError) {
    return `${userInfo} tried to test a ${dbInfo}`;
  }

  return `${userInfo} successfully tested a ${dbInfo}`;
};

// matches the fido external id regex (must be a valid url-safe string)
const invalidProvidedIdRegex = /[^a-zA-Z0-9_.-]/;
const invalidProvidedIdReplacementRegex = /[^a-zA-Z0-9_.-]/g;

export const generateProvidedId = (name: string) =>
  name.toLowerCase().replaceAll(invalidProvidedIdReplacementRegex, '-');

export const isValidProvidedId = (
  value: string,
  originalValue: string | undefined,
  existingProvidedIds: Set<string | undefined>,
) =>
  !invalidProvidedIdRegex.test(value) ||
  (existingProvidedIds.has(value) && originalValue !== value);

export const getProvidedIdErrorTag = (
  value: string | undefined,
  originalValue: string | undefined,
  existingProvidedIds: Set<string | undefined>,
) => {
  if (value == undefined) return undefined;

  return invalidProvidedIdRegex.test(value)
    ? 'Must be URL-safe'
    : existingProvidedIds.has(value) && originalValue !== value
      ? 'ID not unique'
      : undefined;
};
