import { IconButton, Modal, sprinkles, Tabs, Tooltip } from 'components/ds';
import { MapVariablesContent } from 'pages/dashboardPage/dashboardDatasetEditor/modals/components/MapVariablesContent';
import { getEmbeddoSchemaIdFromView } from 'pages/dashboardPage/dashboardDatasetEditor/utils';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { updateVariableMappings } from 'reducers/dashboardEditConfigReducer';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getRootEditConfigWithDrilldowns } from 'reducers/selectors';
import { fetchGlobalDatasetPreviewThunk } from 'reducers/thunks/dashboardDataThunks/fetchDatasetPreviewThunks';
import { cloneAndRemapVariableNames } from 'utils/variableUtils';
import { GlobalDatasetPreviewSection } from './GlobalDatasetPreviewSection';
import { LOADING_DATASET_DATA } from './constants';
import { TabType } from 'components/DataLibrary/globalDatasetConfigurationTabType';

import { navigateToPathAndExpandFoldersThunk } from 'pages/dataLibraryPage/navigationUtils';
import { useHistory } from 'react-router';
import { ItemType } from 'reducers/dataLibraryReducer';
import { FetchOrigin } from 'reducers/thunks/dashboardDataThunks/types';
import { GlobalDatasetVariableNameMap } from 'components/DataLibrary/types';
import { ReadAccessComputedView } from 'utils/fido/fidoShimmedTypes';

interface Props {
  isOpen: boolean;
  globalDataset: ReadAccessComputedView;

  onClose: () => void;
}

export const ViewGlobalDatasetModal: FC<Props> = ({ isOpen, globalDataset, onClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedTab, setSelectedTab] = useState(TabType.PREVIEW);

  const { datasetData, parentSchemas, variables, variableMappings } = useSelector(
    (state: ReduxState) => {
      return {
        datasetData:
          state.dataLibrary.datasetData?.[globalDataset.id ?? '']?.[
            globalDataset.versionId ?? ''
          ] ?? LOADING_DATASET_DATA,
        parentSchemas: getParentSchemasList(state),
        variables: state.dashboardData.variables ?? {},
        variableMappings:
          getRootEditConfigWithDrilldowns(state)?.variable_mappings?.[globalDataset.id ?? ''] ?? {},
      };
    },
    shallowEqual,
  );

  const [currentVariableMappings, setCurrentVariableMappings] = useState(variableMappings);

  const globalDatasetWithIds = useMemo(() => {
    return {
      ...globalDataset,
      id: globalDataset.id ?? '',
      namespaceId: globalDataset.namespaceId ?? '',
    };
  }, [globalDataset]);

  const parentSchemaId = useMemo(
    () => getEmbeddoSchemaIdFromView(parentSchemas, globalDatasetWithIds),
    [parentSchemas, globalDatasetWithIds],
  );

  const numUnmappedVars = useMemo(() => {
    return globalDataset.parameters.filter((param) => !currentVariableMappings[param.name]).length;
  }, [globalDataset.parameters, currentVariableMappings]);

  const remappedVariableMap = useMemo(() => {
    return cloneAndRemapVariableNames(currentVariableMappings, variables);
  }, [currentVariableMappings, variables]);

  const onNewVariableMappingSelected = useCallback(
    (parameterName: string, variableName: string) => {
      const newGlobalToDashboardVariableMappings = {
        ...currentVariableMappings,
        [parameterName]: variableName,
      };
      setCurrentVariableMappings(newGlobalToDashboardVariableMappings);
    },
    [currentVariableMappings, setCurrentVariableMappings],
  );

  const onPreviewDataset = useCallback(
    (query: string, pageNumber?: number) => {
      if (numUnmappedVars > 0) {
        return;
      }

      dispatch(
        fetchGlobalDatasetPreviewThunk(
          {
            query,
            parentSchemaId,
            selectedView: globalDatasetWithIds,
            variables: remappedVariableMap,
          },
          FetchOrigin.DASHBOARD,
          pageNumber,
        ),
      );
    },
    [dispatch, parentSchemaId, globalDatasetWithIds, remappedVariableMap, numUnmappedVars],
  );

  useEffect(() => onPreviewDataset(globalDataset.query), [globalDataset.query, onPreviewDataset]);

  const onSetVariableMappings = useCallback(
    (selectedDataset: ReadAccessComputedView, variableMappings: GlobalDatasetVariableNameMap) => {
      dispatch(
        updateVariableMappings({
          datasetId: selectedDataset.id ?? '',
          globalDatasetVariableNameMap: variableMappings ?? {},
        }),
      );
    },
    [dispatch],
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="large"
      title={`Dataset ${globalDataset.name}`}
      titleComponent={
        <div className={sprinkles({ flexItems: 'alignCenter' })}>
          <div className={sprinkles({ marginRight: 'sp1' })}>{`Dataset ${globalDataset.name}`}</div>
          <Tooltip text="Open in data library">
            <IconButton
              name="arrow-up-right"
              onClick={() => {
                dispatch(
                  navigateToPathAndExpandFoldersThunk(
                    globalDatasetWithIds.id,
                    ItemType.VIEW,
                    globalDatasetWithIds.path ?? '',
                    history,
                  ),
                );
              }}
            />
          </Tooltip>
        </div>
      }>
      <div className={sprinkles({ flexItems: 'column' })}>
        <Tabs
          onTabSelect={(tabId) => setSelectedTab(tabId as TabType)}
          selectedTabId={selectedTab}
          tabs={Object.values(TabType)}
        />
        {selectedTab === TabType.PREVIEW ? (
          <GlobalDatasetPreviewSection
            className={sprinkles({ marginTop: 'sp3' })}
            datasetData={datasetData}
            globalDataset={globalDatasetWithIds}
            hasUnmappedVariables={numUnmappedVars > 0}
            onMapVariablesButtonClicked={() => setSelectedTab(TabType.VARIABLE_MAPPING)}
            onPreviewDataset={onPreviewDataset}
            parentSchemas={parentSchemas}
          />
        ) : null}
        {selectedTab === TabType.VARIABLE_MAPPING ? (
          <MapVariablesContent
            globalDatasetVariableMappings={currentVariableMappings}
            onClose={onClose}
            onNewMappingSelected={onNewVariableMappingSelected}
            onSetVariableMappings={onSetVariableMappings}
            selectedComputedView={globalDataset}
            setVariableMappingsButtonText="Update mapping"
          />
        ) : null}
      </div>
    </Modal>
  );
};
