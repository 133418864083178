import { FC, useMemo } from 'react';

import { ColumnConfigs, DatasetRow, DatasetSchema, NumberDisplayOptions } from '@explo/data';

import { sprinkles } from '@explo/design-system';
import { renderCell } from 'components/ds/DataGrid/columnUtils';
import { getFlexAlignments } from 'components/ds/DataGrid/utils';
import { TypeFooterRow } from '@trust-kaz/reactdatagrid-enterprise/types';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { Aggregation } from '@explo-tech/fido-api';

interface Props {
  columnName: string;
  schema: DatasetSchema;
  columnConfigs: ColumnConfigs;
  value?: string | number;
}

const ColumnTotalCell: FC<Props> = ({ columnName, schema, columnConfigs, value }) => {
  const datasetColumn = useMemo(
    () => schema.find((col) => col.name === columnName),
    [schema, columnName],
  );

  const config = useMemo(() => columnConfigs?.[columnName] || {}, [columnConfigs, columnName]);
  const renderedValue = useMemo(
    () =>
      (datasetColumn && config && value
        ? renderCell({ config, columnInfo: datasetColumn, value, isFooterCell: true })
        : value) ?? '—',
    [config, datasetColumn, value],
  );

  return (
    <div
      className={sprinkles({
        flexItems: 'alignCenter',
        gap: 'sp1',
        justifyContent: datasetColumn
          ? getFlexAlignments(config?.displayFormatting, datasetColumn.type)
          : undefined,
        width: 'fill',
      })}>
      {renderedValue !== '—' ? (
        <EmbedText
          className={sprinkles({ truncateText: 'ellipsis' })}
          color="contentTertiary"
          heading="subtitle">
          {(config.displayFormatting as NumberDisplayOptions)?.totalAggregation ?? Aggregation.SUM}
        </EmbedText>
      ) : null}
      <b className={sprinkles({ truncateText: 'ellipsis' })}>{renderedValue}</b>
    </div>
  );
};

export const useTotalFooter = ({
  columnTotals,
  schema,
  columnConfigs,
}: {
  columnTotals?: DatasetRow[];
  schema: DatasetSchema;
  columnConfigs: ColumnConfigs;
}) => {
  const footerRows = useMemo<TypeFooterRow[]>(
    () => [
      {
        render: ({ column }) => {
          return (
            <ColumnTotalCell
              columnConfigs={columnConfigs}
              columnName={column.name || ''}
              schema={schema}
              value={columnTotals?.[0]?.[column.name ?? '']}
            />
          );
        },
      },
    ],
    [columnConfigs, schema, columnTotals],
  );

  return footerRows;
};
