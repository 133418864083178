import { ReadAccessComputedView } from 'utils/fido/fidoShimmedTypes';
import { ParentSchema } from 'actions/dataSourceActions';
import { Dataset } from 'actions/datasetActions';
import { Dispatch } from 'react';

import { setSelectedDatasetId } from 'reducers/dashboardEditConfigReducer';
import { EVENTS, trackEvent } from 'telemetry/exploAnalytics';
import { DashboardElement } from 'types/dashboardTypes';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { getDataPanelsUsingDataset, getElementsUsingDataset } from 'utils/datasetUtils';
import { ComputedViewWithIds } from 'utils/fido/fidoShimmedTypes';
import { getEmbeddoSchemaFromFidoSchema } from 'utils/fido/fidoShims';

/*
 * Used to get the number of charts and dashboard elements each dataset is used in, and then
 * to convert to the descriptive string displayed with a dataset title
 */
export const getDataPanelUsageTextMap = (
  datasetConfigs: Record<string, Dataset>,
  dataPanels: DataPanelTemplate[],
  elements: DashboardElement[],
): Record<string, string> => {
  if (!datasetConfigs) return {};
  const usageTextMap: Record<string, string> = {};
  Object.keys(datasetConfigs).forEach((datasetConfigId) => {
    const dataPanelsInUse = getDataPanelsUsingDataset(dataPanels, datasetConfigId);
    const elementsInUse = getElementsUsingDataset(elements, datasetConfigId);
    const dataPanelCount = dataPanelsInUse.length;
    const elementCount = elementsInUse.length;
    usageTextMap[datasetConfigId] = getUsageText(elementCount, dataPanelCount);
  });
  return usageTextMap;
};

export const getUsageText = (elementCount: number, dataPanelCount: number): string => {
  let usageText = '';
  if (elementCount) {
    usageText += `${elementCount} filter${elementCount > 1 ? 's' : ''}`;
  }
  if (elementCount && dataPanelCount) {
    usageText += ', ';
  }
  if (dataPanelCount) {
    usageText += `${dataPanelCount} chart${dataPanelCount > 1 ? 's' : ''}`;
  }
  return usageText;
};

export const getEmbeddoSchemaIdFromView = (
  parentSchemas: ParentSchema[],
  view: ComputedViewWithIds,
): number => {
  return parentSchemas.find((s) => s.fido_id === view.namespaceId)?.id ?? -1;
};

export const getEmbeddoSchemaFromView = (
  parentSchemas: ParentSchema[],
  view: ReadAccessComputedView,
): ParentSchema | undefined => {
  return parentSchemas.find((s) => s.fido_id === view.namespaceId);
};

export const convertViewToDataset = (
  view: ComputedViewWithIds,
  parentSchemas: ParentSchema[],
): Dataset => {
  return {
    query: view.query,
    id: view.id,
    queryDraft: view.query,
    fido_id: view.id,
    namespace_id: view.namespaceId,
    table_name: view.name,
    parent_schema_id: getEmbeddoSchemaIdFromView(parentSchemas, view),
    schema: getEmbeddoSchemaFromFidoSchema(view.columnDefinitions ?? []),
  };
};

export const convertViewToDatasetSansParentSchema = (view: ComputedViewWithIds): Dataset => {
  return convertViewToDataset(view, []);
};

export const switchSelectedDataset = (
  datasetId: string | null,
  datasetName: string | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
) => {
  dispatch(setSelectedDatasetId(datasetId));

  if (!datasetName || !datasetId) return;

  trackEvent(EVENTS.SELECTED_DATASET, {
    dataset_id: datasetId,
    dataset_name: datasetName,
  });
};
