import { AggColOptional } from '../columnTypes';

// Adhoc sorting
export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

// Adhoc sorting
export interface SortInfo {
  column: {
    name: string;
    // this is somewhat of a bandaid, but a data table can have a hyperlink column with a separate display value column.
    // We have to sort on the display column, but setting that as the column.name messes with how react-data-grid knows
    // what column visually is being sorted on (for the little sort icon and whatnot)
    displayColumn?: string;
  };
  order: SortOrder;
}

// Sorting via instructions config
export enum SortAxis {
  NONE = 'None',
  MANUAL = 'MANUAL',
  AGG_AXIS = 'AGG_AXIS',
  CAT_AXIS = 'CAT_AXIS',
  COLUMN = 'COLUMN',
}

// Sorting via instructions config
export enum SortOption {
  ASC = 'Ascending',
  DESC = 'Descending',
  DEFAULT = 'Default', // No sorting?
}

export type AxisSort = {
  sortAxis?: SortAxis;
  // supporting a single column for now, but will make the data model support multiple
  sortColumns?: AggColOptional[];
  sortOption?: SortOption;
  maxCategories?: number;
};
