import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { sprinkles } from '@explo/design-system';
import { Dashboard, deleteDrilldownEntryPoint } from 'actions/dashboardActions';
import { IconButton, Input, Menu, Tooltip } from 'components/ds';
import { DataPanelTemplate, DrilldownEntryPointInfo } from 'types/dataPanelTemplate';

import {
  SortableList,
  SortableListItem,
  SortableListItemDragHandle,
} from 'components/SortableList/SortableList';
import {
  addDashboardDrilldownSourceColumn,
  removeDashboardDrilldownSourceColumn,
  setDashboardDrilldownSourceColumns,
  updateDashboardDrilldownCustomMenuText,
} from 'reducers/dashboardEditConfigReducer';
import { getDrilldownColumnInfosForSelectedDataPanel } from 'utils/dashboardDrilldownUtils';
import { Column } from '../../DataConfigTab/VisualizationConfigTableColumns/Column';

interface Props {
  drilldownEntryPoints: [string, DrilldownEntryPointInfo][];
  dashboardIdToNameMap: Record<number, string>;
  selectedDataPanel: DataPanelTemplate;
  currentDashboard: Dashboard | undefined;
}

export const DashboardDrilldownEntryDetailSection: FC<Props> = ({
  drilldownEntryPoints,
  dashboardIdToNameMap,
  selectedDataPanel,
  currentDashboard,
}) => {
  const dispatch = useDispatch();

  const eligibleColumnsToAdd = useMemo(() => {
    const allDrilldownColumns = Object.values(
      getDrilldownColumnInfosForSelectedDataPanel(selectedDataPanel),
    );
    const usedColumnNames = new Set(
      drilldownEntryPoints.flatMap(([, entryPoint]) =>
        entryPoint.sourceChartColumns.map((column) => column.name),
      ),
    );

    return allDrilldownColumns.filter((column) => !usedColumnNames.has(column.name));
  }, [drilldownEntryPoints, selectedDataPanel]);

  if (!currentDashboard) {
    return null;
  }

  return (
    <>
      {drilldownEntryPoints.map(([id, entryPoint], index) => {
        const dashboardName = dashboardIdToNameMap[entryPoint.destinationDashboardId];
        return (
          <div
            className={sprinkles({
              padding: 'sp1',
              borderBottom: 1,
              borderBottomColor:
                index === drilldownEntryPoints.length - 1 ? 'transparent' : 'outline',
            })}
            key={id}>
            <div
              className={sprinkles({
                display: 'flex',
                flexDirection: 'column',
                gap: 'sp1',
              })}>
              <div
                className={sprinkles({
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                })}>
                <div
                  className={sprinkles({
                    flexDirection: 'row',
                    flexItems: 'center',
                    body: 'b1',
                    gap: 'sp1',
                  })}>
                  <p className={sprinkles({ marginBottom: 'sp0' })}>Destination dashboard:</p>
                  <p
                    className={sprinkles({
                      marginBottom: 'sp0',
                      fontWeight: 600,
                    })}>
                    {dashboardName ? `${dashboardName}` : 'Not set'}
                  </p>
                </div>
                <IconButton
                  name="trash"
                  onClick={() => {
                    dispatch(
                      deleteDrilldownEntryPoint({
                        dashboardId: currentDashboard.id,
                        sourceDataPanelId: selectedDataPanel.id,
                        drilldownEntryPointId: id,
                      }),
                    );
                  }}
                />
              </div>
              <div className={sprinkles({ flexItems: 'alignCenterBetween' })}>
                <p className={sprinkles({ heading: 'h4' })}>Columns to drill into:</p>
                <Menu
                  trigger={<IconButton disabled={eligibleColumnsToAdd.length === 0} name="plus" />}>
                  {eligibleColumnsToAdd.map((column) => {
                    if (!column.name || !column.type) {
                      return null;
                    }

                    return (
                      <div
                        key={column.name}
                        onClick={() => {
                          dispatch(
                            addDashboardDrilldownSourceColumn({
                              columnToAdd: column,
                              dataPanelId: selectedDataPanel.id,
                              drilldownEntryPointId: id,
                            }),
                          );
                        }}>
                        <Column displayName={column.name} type={column.type} />
                      </div>
                    );
                  })}
                </Menu>
              </div>
              <div>
                <SortableList
                  getIdFromElem={(column) => column.name ?? ''}
                  onListUpdated={(newSourceColumns) => {
                    dispatch(
                      setDashboardDrilldownSourceColumns({
                        columns: newSourceColumns,
                        drilldownEntryPointId: id,
                        dataPanelId: selectedDataPanel.id,
                      }),
                    );
                  }}
                  sortableItems={entryPoint.sourceChartColumns}>
                  {
                    entryPoint.sourceChartColumns
                      .map((column) => {
                        if (!column.name || !column.type) {
                          return null;
                        }
                        return (
                          <SortableListItem
                            className={sprinkles({
                              backgroundColor: {
                                hover: 'lightBlue',
                              },
                              borderRadius: 3,
                            })}
                            key={column.name}
                            sortId={column.name}>
                            <div
                              className={sprinkles({ flexItems: 'alignCenterBetween' })}
                              key={column.name}>
                              <div className={sprinkles({ flexItems: 'alignCenter' })}>
                                <SortableListItemDragHandle />
                                <Column displayName={column.name} type={column.type} />
                              </div>
                              <Tooltip text="Remove source column">
                                <IconButton
                                  className={sprinkles({ color: 'error' })}
                                  name="trash"
                                  onClick={() => {
                                    dispatch(
                                      removeDashboardDrilldownSourceColumn({
                                        columnToRemove: column,
                                        dataPanelId: selectedDataPanel.id,
                                        drilldownEntryPointId: id,
                                      }),
                                    );
                                  }}
                                />
                              </Tooltip>
                            </div>
                          </SortableListItem>
                        );
                      })
                      .filter((columnElement) => columnElement != null) as JSX.Element[]
                  }
                </SortableList>
              </div>
            </div>

            <Input
              showInputButton
              className={sprinkles({ marginY: 'sp1' })}
              defaultValue={entryPoint.customDrilldownMenuText}
              label="Custom menu text"
              onSubmit={(newCustomMenuText) => {
                dispatch(
                  updateDashboardDrilldownCustomMenuText({
                    drilldownEntryPointId: id,
                    newCustomMenuText,
                  }),
                );
              }}
            />
          </div>
        );
      })}
    </>
  );
};
