import { ResourceEnvironmentTagDto } from '@explo/embeddo-api';
import { createSlice } from '@reduxjs/toolkit';

import {
  createResourceEnvironmentTag,
  deleteResourceEnvironmentTag,
  listResourceEnvironmentTags,
  updateResourceEnvironmentTag,
} from 'reducers/thunks/resourceEnvironmentTagThunks';
import * as RD from 'remotedata';

interface EnvironmentTagReducerState {
  tags: RD.ResponseData<ResourceEnvironmentTagDto[]>;
}

const initialState: EnvironmentTagReducerState = {
  tags: RD.Idle(),
};

const environmentTagSlice = createSlice({
  name: 'environmentTags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listResourceEnvironmentTags.pending, (state) => {
        state.tags = RD.Loading();
      })
      .addCase(listResourceEnvironmentTags.rejected, (state, { error }) => {
        state.tags = RD.Error(error.message ?? 'Error loading environment tags');
      })
      .addCase(listResourceEnvironmentTags.fulfilled, (state, { payload }) => {
        state.tags = RD.Success(payload.tags);
      })
      .addCase(updateResourceEnvironmentTag.fulfilled, (state, { payload }) => {
        if (!RD.isSuccess(state.tags)) return;
        const tagIndex = state.tags.data.findIndex(({ id }) => id === payload.tag.id);
        if (tagIndex !== -1) state.tags.data[tagIndex] = payload.tag;
      })
      .addCase(createResourceEnvironmentTag.fulfilled, (state, { payload }) => {
        if (!RD.isSuccess(state.tags)) return;
        state.tags.data.push(payload.tag);
      })
      .addCase(deleteResourceEnvironmentTag.fulfilled, (state, { meta }) => {
        if (!RD.isSuccess(state.tags)) return;
        state.tags.data = state.tags.data.filter(({ id }) => id !== meta.arg.id);
      });
  },
});

export const environmentTagsReducer = environmentTagSlice.reducer;
