import * as ProgressBar from '@radix-ui/react-progress';
import cx from 'classnames';
import { FC, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { logOutUser } from 'actions/authAction';
import { fetchUserTeam } from 'actions/teamActions';
import { APP_PORTAL_ID, Icon, Menu, MenuActionItem, sprinkles, Tooltip } from 'components/ds';
import { TRIAL_STATUS } from 'constants/paymentPlanConstants';
import { ROUTES } from 'constants/routes';
import { SETTINGS_SUB_SECTION, SUB_SECTION_ROUTE_MAP } from 'constants/settingsPageConstants';
import { setIsSidebarOpen } from 'reducers/dashboardInteractionsReducer';
import { ReduxState } from 'reducers/rootReducer';
import { numDaysLeftInPlan } from 'utils/paymentPlanUtils';

import { getEnvironment, isEnvironmentSecure } from 'utils/environmentUtils';
import { ChatWidget } from './ChatWidget';
import { NavItemInfo, SidebarItem } from './SidebarItem';
import {
  DOCS_TEXT,
  EXPANDED_STORAGE_KEY,
  NUM_DAYS_IN_TRIAL,
  TOOLTIP_SIDE,
  TOOLTIP_SIDE_OFFSET,
} from './constants';
import { EXPLO_HOMEPAGE_LOGO } from './icons';
import * as styles from './styles.css';
import {
  getOrderedDevItems,
  getOrderedProductItems,
  getOrderedSetupItems,
  getOrderedShareItems,
  getOrderedToolItems,
  getUserInitials,
} from './utils';

type Props = {
  activeItemId?: string;
};

export const Sidebar: FC<Props> = ({ activeItemId }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { currentUser, teamData } = useSelector(
    (state: ReduxState) => ({
      currentUser: state.currentUser,
      teamData: state.teamData.data,
    }),
    shallowEqual,
  );

  const [isExpanded, setIsExpanded] = useLocalStorage(EXPANDED_STORAGE_KEY, true);

  useEffect(() => {
    if (!teamData) dispatch(fetchUserTeam());
  }, [teamData, dispatch]);

  useEffect(() => {
    dispatch(setIsSidebarOpen(isExpanded));
  }, [dispatch, isExpanded]);

  const sidebarItemStyle = {
    [styles.sidebarItemOpen]: isExpanded,
    [styles.sidebarItemClosed]: !isExpanded,
  };

  const renderLogoSection = () => {
    return (
      <div className={styles.sidebarLogoContainer}>
        <div className={styles.sidebarLogoItems}>
          <div className={styles.sidebarLogo}>
            <Icon
              className={styles.hamburgerMenu}
              name="bars"
              onClick={() => setIsExpanded(!isExpanded)}
            />
            {isExpanded ? (
              <Link to="/home">
                <div className={sprinkles({ display: 'flex' })}>{EXPLO_HOMEPAGE_LOGO()}</div>
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const renderTrialTag = () => {
    const daysLeftInPlan = numDaysLeftInPlan(currentUser.team?.trial_end_date);
    if (currentUser.team?.trial_status !== TRIAL_STATUS.IN_PROGRESS || daysLeftInPlan <= 0)
      return null;

    const trialText = `${daysLeftInPlan} day${
      daysLeftInPlan > 1 ? 's' : ''
    } remaining on your trial`;

    return (
      <Link className={styles.tagLink} to={ROUTES.BILLING_PAGE}>
        <div className={styles.trialTag}>
          <p className={sprinkles({ margin: 'sp0', body: 'b2' })}>{trialText}</p>
          <ProgressBar.Root className={styles.progressBarRoot} value={daysLeftInPlan}>
            <ProgressBar.Indicator
              className={styles.progressBarIndicator}
              style={{ transform: `translateX(-${(daysLeftInPlan / NUM_DAYS_IN_TRIAL) * 100}%)` }}
            />
          </ProgressBar.Root>
        </div>
      </Link>
    );
  };

  const renderDocs = () => {
    return (
      <a
        className={cx(styles.hoverableItem, sidebarItemStyle)}
        href="https://docs.explo.co/"
        rel="noopener noreferrer"
        target="_blank">
        <div className={styles.sidebarItemIconName}>
          <Icon className={styles.leftIcon} name="file-reg" />
          {isExpanded ? <p className={styles.itemText}>{DOCS_TEXT}</p> : null}
        </div>
        {isExpanded ? (
          <div className={styles.rightIcon}>
            <Icon className={sprinkles({ color: 'blue7' })} name="arrow-up-right" />
          </div>
        ) : null}
      </a>
    );
  };

  const renderItemsSection = () => {
    const orderedSetupItems = getOrderedSetupItems(currentUser);
    const orderedProductItems = getOrderedProductItems(currentUser);
    const orderedShareItems = getOrderedShareItems(currentUser);
    const orderedToolItems = getOrderedToolItems(teamData);
    const orderedDevItems = getOrderedDevItems(currentUser);

    const renderItems = (sectionTitle: string, itemList: NavItemInfo[]) => {
      if (itemList.length === 0) return null;
      return (
        <>
          <p className={styles.sidebarHeading}>{sectionTitle}</p>
          {itemList.map((item) => (
            <SidebarItem
              isExpanded={isExpanded}
              isSelected={activeItemId === item.id}
              key={item.id}
              navItemInfo={item}
            />
          ))}
        </>
      );
    };

    return (
      <div className={styles.sidebarContainer} data-testid="sidebarSection">
        <div
          className={
            isExpanded ? styles.sidebarSectionContainerOpen : styles.sidebarSectionContainerClosed
          }>
          {renderItems('Setup', orderedSetupItems)}
          {renderItems('Products', orderedProductItems)}
          {renderItems('Share', orderedShareItems)}
          {renderItems('Tools', orderedToolItems)}
          {renderItems('Dev', orderedDevItems)}
        </div>
        <div className={styles.sidebarBottomContainer}>
          {isExpanded ? <div className={styles.tagContainer}>{renderTrialTag()}</div> : null}
          <div
            className={
              isExpanded ? styles.sidebarSectionContainerOpen : styles.sidebarSectionContainerClosed
            }>
            {getEnvironment() !== 'development' && !isEnvironmentSecure() ? (
              <ChatWidget className={sidebarItemStyle} isExpanded={isExpanded} />
            ) : null}
            {isExpanded ? (
              renderDocs()
            ) : (
              <Tooltip side={TOOLTIP_SIDE} sideOffset={TOOLTIP_SIDE_OFFSET} text={DOCS_TEXT}>
                {renderDocs()}
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderAccountSection = () => {
    const renderUserInfoContent = () => {
      return (
        <div className={styles.sidebarAccountContainer}>
          <div className={styles.sidebarAccountInfo}>
            <div className={styles.avatarStyle} data-testid="profileIcon">
              <div className={sprinkles({ color: 'white' })}>{getUserInitials(currentUser)}</div>
            </div>
            {isExpanded ? (
              <div className={styles.sidebarNameEmail}>
                <p className={styles.sidebarAccountName}>
                  {currentUser.first_name + ' ' + currentUser.last_name}
                </p>
                <p className={styles.sidebarAccountEmail}> {currentUser.email}</p>
              </div>
            ) : null}
          </div>
          {isExpanded ? (
            <Icon className={sprinkles({ color: 'blue7' })} name="chevron-right" />
          ) : null}
        </div>
      );
    };

    return (
      <Menu
        align="end"
        portalContainerId={APP_PORTAL_ID}
        side="right"
        trigger={<span>{renderUserInfoContent()}</span>}
        width="small">
        <MenuActionItem
          iconName="gear"
          onSelect={() => {
            history.push(`/settings/${SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.PROFILE]}`);
          }}
          text="Settings"
        />
        <MenuActionItem
          iconName="right-arrow-from-bracket"
          onSelect={() => dispatch(logOutUser())}
          text="Log Out"
        />
      </Menu>
    );
  };

  return (
    <div className={isExpanded ? styles.sidebarOpen : styles.sidebarClosed}>
      {renderLogoSection()}
      {renderItemsSection()}
      {renderAccountSection()}
    </div>
  );
};
