import { FC, useMemo } from 'react';

import { ConfigSection } from 'components/PanelComponents/ConfigSection';
import { DataPanelTemplate, DrilldownEntryPointInfo } from 'types/dataPanelTemplate';
import { areColumnsSubsets, getDataPanelBreakdownColumns } from 'utils/dashboardDrilldownUtils';

import { DashboardDrilldownEntryDetailSection } from './DashboardDrilldownEntryDetailSection';
import { Dashboard } from 'actions/dashboardActions';
import { ConfigSubSectionTitle } from 'components/PanelComponents/ConfigSubSection';

interface Props {
  dashboardIdToNameMap: Record<number, string>;
  drilldownEntryPointMap: Record<string, DrilldownEntryPointInfo>;
  selectedDataPanel: DataPanelTemplate;
  currentDashboard: Dashboard | undefined;
}

export const DashboardDrilldownsSection: FC<Props> = ({
  dashboardIdToNameMap,
  drilldownEntryPointMap,
  selectedDataPanel,
  currentDashboard,
}) => {
  const dashboardIds = useMemo(
    () => new Set(Object.keys(dashboardIdToNameMap)),
    [dashboardIdToNameMap],
  );

  const dataPanelBreakdownColumns = useMemo(
    () => getDataPanelBreakdownColumns(selectedDataPanel),
    [selectedDataPanel],
  );

  const validDrilldownEntryPoints = useMemo(() => {
    return Object.entries(drilldownEntryPointMap ?? {}).filter(
      ([, drilldownEntryPoint]) =>
        dashboardIds.has(String(drilldownEntryPoint.destinationDashboardId)) &&
        areColumnsSubsets(drilldownEntryPoint.sourceChartColumns, dataPanelBreakdownColumns),
    );
  }, [dashboardIds, drilldownEntryPointMap, dataPanelBreakdownColumns]);

  const validDrilldownEntryPointIds = useMemo(() => {
    const validDrilldownEntryPointIds = new Set<string>();
    validDrilldownEntryPoints.forEach(([drilldownEntryPointId]) =>
      validDrilldownEntryPointIds.add(drilldownEntryPointId),
    );
    return validDrilldownEntryPointIds;
  }, [validDrilldownEntryPoints]);

  const invalidDrilldownEntryPoints = useMemo(() => {
    return Object.entries(drilldownEntryPointMap ?? {}).filter(
      ([drilldownEntryPointId]) => !validDrilldownEntryPointIds.has(drilldownEntryPointId),
    );
  }, [drilldownEntryPointMap, validDrilldownEntryPointIds]);

  if (validDrilldownEntryPoints.length === 0 && invalidDrilldownEntryPoints.length === 0) {
    // Do not render this section if there are no drilldown entry points.
    return null;
  }

  const hasValidDrilldownEntryPoints = validDrilldownEntryPoints.length > 0;
  const hasInvalidDrilldownEntryPoints = invalidDrilldownEntryPoints.length > 0;

  return (
    <ConfigSection
      defaultOpen={hasValidDrilldownEntryPoints || hasInvalidDrilldownEntryPoints}
      icon="chart-bar"
      title="Dashboard drilldowns"
      variant="header1">
      {hasValidDrilldownEntryPoints ? (
        <>
          <ConfigSubSectionTitle title="Valid entry points" />
          <DashboardDrilldownEntryDetailSection
            currentDashboard={currentDashboard}
            dashboardIdToNameMap={dashboardIdToNameMap}
            drilldownEntryPoints={validDrilldownEntryPoints}
            selectedDataPanel={selectedDataPanel}
          />
        </>
      ) : null}
      {hasInvalidDrilldownEntryPoints ? (
        <>
          <ConfigSubSectionTitle title="Invalid entry points" />
          <DashboardDrilldownEntryDetailSection
            currentDashboard={currentDashboard}
            dashboardIdToNameMap={dashboardIdToNameMap}
            drilldownEntryPoints={invalidDrilldownEntryPoints}
            selectedDataPanel={selectedDataPanel}
          />
        </>
      ) : null}
    </ConfigSection>
  );
};
