import cx from 'classnames';
import { FC, forwardRef, ReactNode } from 'react';

import { Icon, Intent, IntentType, sprinkles, Tooltip } from 'components/ds';
import { IconName } from 'components/ds/Icon';

import * as styles from './index.css';

export type Props = {
  /**
   * Optional override for tag background color
   */
  backgroundColor?: string;
  /**
   * Text to display in tag
   */
  children?: ReactNode;
  /**
   * Optional class name for style overrides
   */
  className?: string;
  /**
   * Optional class to further style closeIcon
   */
  closeIconStyle?: string;
  /**
   * The intent defines the color of the tag
   */
  intent?: IntentType;
  /**
   * Inverts the colors of the text and background
   */
  inverted?: boolean;
  /**
   * Icon to show on left inside of tag
   */
  leftIcon?: IconName;

  /**
   * Tooltip text for the close icon.
   */
  closeIconTooltip?: string;

  /**
   * Function fired for closing tags with cross icon
   */
  onClose?: () => void;
};

export const Tag: FC<Props> = forwardRef<HTMLDivElement, Props>(
  (
    {
      backgroundColor,
      children,
      className,
      closeIconStyle,
      intent = Intent.NONE,
      inverted = false,
      leftIcon,
      closeIconTooltip,
      onClose,
      ...props
    },
    ref,
  ) => {
    const iconOnly = children === undefined;

    return (
      <div
        {...props}
        className={cx(styles.base({ intent, inverted, iconOnly }), className)}
        ref={ref}
        style={backgroundColor ? { backgroundColor } : undefined}>
        {leftIcon ? (
          <Icon
            className={
              iconOnly ? sprinkles({ marginRight: 'sp0' }) : sprinkles({ marginRight: 'sp.5' })
            }
            name={leftIcon}
            size="sm"
          />
        ) : null}
        {children}
        {onClose ? (
          closeIconTooltip ? (
            <CloseIconWithTooltip
              closeIconStyle={closeIconStyle}
              onClose={onClose}
              tooltipText={closeIconTooltip}
            />
          ) : (
            <CloseIcon closeIconStyle={closeIconStyle} onClose={onClose} />
          )
        ) : null}
      </div>
    );
  },
);

interface CloseIconProps {
  closeIconStyle?: string;
  onClose: () => void;
}

export const CloseIcon: FC<CloseIconProps> = ({ closeIconStyle, onClose }) => {
  return (
    <Icon
      className={cx(styles.closeIcon, closeIconStyle)}
      data-testid="tag-close"
      name="cross"
      onClick={onClose}
      size="sm"
    />
  );
};

interface CloseIconWithTooltipProps extends CloseIconProps {
  tooltipText: string;
}

export const CloseIconWithTooltip: FC<CloseIconWithTooltipProps> = ({
  closeIconStyle,
  tooltipText,
  onClose,
}) => {
  return (
    <Tooltip text={tooltipText}>
      <Icon
        className={cx(styles.closeIcon, closeIconStyle)}
        data-testid="tag-close"
        name="cross"
        onClick={onClose}
        size="sm"
      />
    </Tooltip>
  );
};

Tag.displayName = 'Tag';
