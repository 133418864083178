import { FC } from 'react';

import { Input } from 'components/ds';
import { createThrottleFn } from 'utils/general';

interface Props {
  searchQuery: string;
  placeholderText?: string;
  className: string;
  onInputChanged: (inputValue: string) => void;
}

const throttleFn = createThrottleFn(300);

// TODO(zifanxiang): Consider customizing this component more. E.g. allowing the caller to set the
// throttle duration, the placeholder, etc.
export const SearchInput: FC<Props> = ({
  searchQuery,
  className,
  placeholderText = DEFAULT_PLACEHOLDER_TEXT,
  onInputChanged,
}) => {
  return (
    <Input
      className={className}
      leftIcon="search"
      onChange={(value: string) => throttleFn(() => onInputChanged(value))}
      placeholder={placeholderText}
      value={searchQuery}
    />
  );
};

const DEFAULT_PLACEHOLDER_TEXT = 'Search...';
