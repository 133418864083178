import {
  Branch,
  Commit,
  CommitResponseMetadata,
  CreateResourceChange,
  Folder,
  ListViewsResponse,
  Parameter,
  Resource,
  UpdateResourceChange,
} from '@explo-tech/fido-api';
import { VersionedResourceUsageInfo } from '@explo/embeddo-api';
import { createSlice, Reducer, SerializedError } from '@reduxjs/toolkit';
import { VersionedDatasetDataMap } from 'actions/datasetActions';
import {
  COMPUTED_VIEW_TYPE,
  FOLDER_TYPE,
  MAIN_BRANCH_NAME,
  RESOURCE_NOT_FOUND_INDEX,
  ROOT_FOLDER_PATH,
} from 'pages/dataLibraryPage/constants';
import {
  getParentPath,
  getResourceById,
  updateResourceIdsInParentFolder,
} from 'pages/dataLibraryPage/dataLibraryUtil';
import { sanitizeFolder } from 'pages/dataLibraryPage/dataSanitizer';
import {
  BranchId,
  DeleteResourceChange,
  FolderPath,
  MoveResourceChange,
  ResourceId,
  SerializedPendingChanges,
} from 'pages/dataLibraryPage/types';
import * as RD from 'remotedata';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { ReadAccessComputedView } from 'utils/fido/fidoShimmedTypes';
import { getEmbeddoResponseFromFidoResponse } from 'utils/fido/fidoShims';
import { cloneDeep, isEqual } from 'utils/standard';
import { fetchGlobalDatasetFidoViewPreview } from './thunks/dashboardDataThunks/fetchFidoDataThunks';
import { FetchOrigin } from './thunks/dashboardDataThunks/types';
import { fetchDataLibraryViewUsages } from './thunks/dataLibraryThunks';
import {
  createBranchThunk,
  deleteBranchThunk,
  listBranchContentThunk,
  listBranchesThunk,
  updateBranchThunk,
} from './thunks/fidoThunks/branchThunks';
import {
  createCommitThunk,
  getCommitDetailsThunk,
  listCommitsThunk,
} from './thunks/fidoThunks/commitThunks';
import {
  getResourceOnBranchThunk,
  searchBranchContentThunk,
} from './thunks/fidoThunks/resourceThunks';
import { parseQueryThunk } from './thunks/fidoThunks/viewThunks';

export enum ItemType {
  FOLDER = 'folder',
  VIEW = 'view',
}

export type ItemPathInfo = {
  path: string;
  type: ItemType;
};

export enum BranchOperationType {
  UPDATE = 'update',
  CREATE = 'create',
  DELETE = 'delete',
}

export type BranchOperationStatus = {
  type: BranchOperationType;
  branch?: Branch;
};

export type ViewUsage = {
  viewId: string;
  viewVersionId: string;
  dashboards: VersionedResourceUsageInfo[];
  reportBuilders: VersionedResourceUsageInfo[];
};

export const createDefaultFoldersMap = () =>
  new Map<BranchId, Map<FolderPath, RD.ResponseData<Folder>>>();

export const createDefaultFoldersMapForBranch = () =>
  new Map<FolderPath, RD.ResponseData<Folder>>([[ROOT_FOLDER_PATH, RD.Idle()]]);

export const createDefaultFolderExpansionMap = () => {
  return new Map<string, boolean>([[ROOT_FOLDER_PATH, true]]);
};

export interface DataLibraryState {
  branches: RD.ResponseData<Map<string, Branch>>;
  mainBranch: RD.ResponseData<Branch>;
  // The branch that the user is currently switched onto.
  currentBranch: RD.ResponseData<Branch>;
  // A map of branch ids to folder maps. Each folder map is a map of folder paths to the full folder
  // resource (includes all children). The folder maps for this property represent the base state
  // that has been directly acknowledged by the server.
  folders: Map<BranchId, Map<FolderPath, RD.ResponseData<Folder>>>;
  // The same map as above but with local changes reflected on the stored folders.
  updatedFolders: Map<BranchId, Map<FolderPath, RD.ResponseData<Folder>>>;
  currentItemPath: ItemPathInfo;
  pendingResourceCreations: Map<ResourceId, CreateResourceChange>;
  pendingResourceDeletions: Map<ResourceId, DeleteResourceChange>;
  pendingResourceUpdates: Map<ResourceId, UpdateResourceChange>;
  unappliedPendingChanges: Map<
    ResourceId,
    CreateResourceChange | DeleteResourceChange | UpdateResourceChange
  >;
  // The initial set of unapplied pending creations that were stored locally.
  initialUnappliedPendingCreations: Map<ResourceId, CreateResourceChange>;
  datasetData: VersionedDatasetDataMap;
  pendingCommitStatus: RD.ResponseData<string>;
  pendingCommitError: SerializedError | null;
  detectedQueryParameters: RD.ResponseData<Array<Parameter>>;
  variables: DashboardVariableMap;
  branchCommits: RD.ResponseData<Array<Commit>>;
  commitDetails: Map<string, RD.ResponseData<CommitResponseMetadata>>;
  // A map of folder paths to whether or not they are expanded. No entry means the folder is
  // collapsed.
  folderExpansionState: Map<string, boolean>;
  directLoadedResource: RD.ResponseData<Resource>;
  inProgressDirectFetchPaths: Set<string>;
  // Whether all paths to the directly loaded resource have been fetched. Used to prevent multiple
  // fetches for the path of the original directly loaded resource.
  hasFinishedDirectPathsFetch: boolean;
  branchOperationStatus: RD.ResponseData<BranchOperationStatus>;
  computedViewUsage: RD.ResponseData<ViewUsage>;
  searchResults: RD.ResponseData<ListViewsResponse>;
}

export const INITIAL_STATE: DataLibraryState = {
  branches: RD.Idle(),
  mainBranch: RD.Idle(),
  currentBranch: RD.Idle(),
  folders: createDefaultFoldersMap(),
  updatedFolders: createDefaultFoldersMap(),
  currentItemPath: { path: ROOT_FOLDER_PATH, type: ItemType.FOLDER },
  pendingResourceCreations: new Map(),
  pendingResourceDeletions: new Map(),
  pendingResourceUpdates: new Map(),
  unappliedPendingChanges: new Map(),
  initialUnappliedPendingCreations: new Map(),
  datasetData: {},
  pendingCommitStatus: RD.Idle(),
  pendingCommitError: null,
  detectedQueryParameters: RD.Idle(),
  variables: {},
  branchCommits: RD.Idle(),
  commitDetails: new Map(),
  folderExpansionState: createDefaultFolderExpansionMap(),
  directLoadedResource: RD.Idle(),
  inProgressDirectFetchPaths: new Set(),
  hasFinishedDirectPathsFetch: false,
  branchOperationStatus: RD.Idle(),
  computedViewUsage: RD.Idle(),
  searchResults: RD.Idle(),
};

const dataLibrarySlice = createSlice({
  name: 'dataLibrary',
  initialState: INITIAL_STATE,
  reducers: {
    addPendingResourceCreation: (state, action: { payload: Resource }) => {
      if (!RD.isSuccess(state.currentBranch)) {
        return;
      }

      const resource = action.payload;
      state.pendingResourceCreations.set(action.payload.id ?? '', {
        resource,
        '@type': 'create',
      });
      const parentFolder = getHeadParentFolder(state, resource.path ?? '');
      if (!parentFolder) {
        return;
      }
      parentFolder.children ??= [];
      parentFolder.children?.push(resource);

      const isFolder = resource['@type'] === FOLDER_TYPE;
      if (isFolder) {
        const folder = resource as Folder;
        const updatedFoldersForBranch = state.updatedFolders.get(state.currentBranch.data.id ?? '');
        if (!updatedFoldersForBranch) {
          // TODO(zifanxiang): Add logic in the validation middleware to ensure that this state
          // is never reached.
          return;
        }
        updatedFoldersForBranch.set(resource.path ?? '', RD.Success(folder));
        state.folderExpansionState.set(folder.path ?? '', false);
      }

      resetPendingCommitState(state);
    },
    updateDatasetParameter: (
      state,
      action: { payload: { datasetId: string; parameter: Parameter } },
    ) => {
      if (!RD.isSuccess(state.currentBranch)) {
        return;
      }

      const { datasetId, parameter } = action.payload;
      const dataset = getResourceById(
        datasetId,
        state.updatedFolders.get(state.currentBranch.data.id ?? '') ?? new Map(),
      ) as ReadAccessComputedView;
      if (!dataset) {
        return;
      }
      const parameterIndex = dataset.parameters.findIndex((p) => p.name === parameter.name);
      if (parameterIndex !== -1) {
        dataset.parameters[parameterIndex] = parameter;
        handleAddPendingResourceUpdate(state, dataset);
      }
    },
    addPendingResourceDeletion: (state, action: { payload: Resource }) => {
      const resource = action.payload;
      markResourceForDeletion(state, resource);
      resetPendingCommitState(state);
    },
    addPendingResourceUpdate: (state, action: { payload: Resource }) => {
      handleAddPendingResourceUpdate(state, action.payload);
    },
    addPendingResourceMove: (state, action: { payload: MoveResourceChange }) => {
      const newResource = action.payload.resource;
      const previousPath = action.payload.previousPath;
      const previousParentFolder = getHeadParentFolder(state, previousPath);
      if (!previousParentFolder || !previousParentFolder.children) {
        return;
      }

      // TODO(zifanxiang): Note this reducer currently only works for moving computed views. This
      // needs to be updated for moving folders.
      const resourceIndex = previousParentFolder.children.findIndex(
        (child) => child.id === newResource.id,
      );
      if (resourceIndex === RESOURCE_NOT_FOUND_INDEX) {
        return;
      }
      previousParentFolder.children.splice(resourceIndex, 1);

      const newParentFolder = getHeadParentFolder(state, newResource.path ?? '');
      if (!newParentFolder) {
        return;
      }
      newParentFolder.children ??= [];
      newParentFolder.children.push(newResource);

      const baseResource = getBaseResourceById(state, newResource.id ?? '');

      if (baseResource && isEqual(baseResource, newResource)) {
        // Remove the pending update if the resource has been moved back to the original location.
        state.pendingResourceUpdates.delete(newResource.id ?? '');
        return;
      }

      // If the resource is still pending creation, we update the stored pending creation object,
      // otherwise update the stored pending update.
      appendUpdatedResourceToPendingChanges(state, newResource);

      resetPendingCommitState(state);
    },
    setCurrentItemInfo(state, action: { payload: ItemPathInfo }) {
      state.currentItemPath = { path: action.payload.path, type: action.payload.type };
    },
    updateVariables(state, action: { payload: DashboardVariableMap }) {
      state.variables ??= {};
      state.variables = { ...state.variables, ...action.payload };
    },
    clearQueryState(state) {
      state.variables = {};
      state.detectedQueryParameters = RD.Idle();
    },
    setUnappliedPendingChanges(state, action: { payload: SerializedPendingChanges }) {
      state.unappliedPendingChanges = new Map();
      action.payload.pendingResourceCreations.forEach((change) => {
        state.unappliedPendingChanges.set(change.resource.id ?? '', change);
        state.initialUnappliedPendingCreations.set(change.resource.id ?? '', change);
      });
      action.payload.pendingResourceDeletions.forEach((change) => {
        state.unappliedPendingChanges.set(change.id, change);
      });
      action.payload.pendingResourceUpdates.forEach((change) => {
        state.unappliedPendingChanges.set(change.resource.id ?? '', change);
      });
    },
    clearPendingChanges(state) {
      if (!RD.isSuccess(state.currentBranch)) {
        return;
      }

      resetPendingChanges(state);

      const currentBranchId = state.currentBranch.data.id ?? '';
      const foldersForBranch = state.folders.get(currentBranchId);
      if (!foldersForBranch) {
        return;
      }

      state.updatedFolders.set(currentBranchId, new Map());
      foldersForBranch.forEach((folderResponse: RD.ResponseData<Folder>, path: string) => {
        if (RD.isSuccess(folderResponse)) {
          state.updatedFolders
            .get(currentBranchId)
            ?.set(path, RD.Success(cloneDeep(folderResponse.data)));
        }
      });
    },
    revertComputedViewQuery(state, action: { payload: ReadAccessComputedView }) {
      if (!RD.isSuccess(state.currentBranch)) {
        return;
      }

      const updatedFoldersForBranch =
        state.updatedFolders.get(state.currentBranch.data.id ?? '') ?? new Map();
      const computedViewId = action.payload.id ?? '';
      const updatedResource = getResourceById(computedViewId, updatedFoldersForBranch);
      const baseResource = getBaseResourceById(state, computedViewId) ?? {
        ...updatedResource,
        query: '',
      };

      if (!baseResource || !updatedResource) {
        return;
      }

      const baseComputedView = baseResource as ReadAccessComputedView;
      const updatedComputedView = updatedResource as ReadAccessComputedView;
      const revertedComputedView = {
        ...updatedComputedView,
        query: baseComputedView.query,
      };

      if (isEqual(baseComputedView, revertedComputedView)) {
        if (state.pendingResourceCreations.has(computedViewId)) {
          // Reset the pending resource creation with the reverted query if the computed view is a
          // pending creation.
          state.pendingResourceCreations.set(computedViewId, {
            resource: revertedComputedView,
            '@type': 'create',
          });
        } else {
          // Remove the pending update if the computed view with the reverted query is the same as
          // the base computed view (if there are no other property updates such as the path within
          // a move).
          state.pendingResourceUpdates.delete(computedViewId);
        }
      } else {
        // Set the pending resource update's resource to be the reverted computed view.
        state.pendingResourceUpdates.set(computedViewId, {
          resource: revertedComputedView,
          '@type': 'update',
        });
      }

      const updatedComputedViewParentFolder = getHeadParentFolder(
        state,
        updatedComputedView.path ?? '',
      );
      if (!updatedComputedViewParentFolder?.children) {
        return;
      }

      const updatedComputedViewIndex =
        updatedComputedViewParentFolder.children?.findIndex(
          (child) => child.id === computedViewId,
        ) ?? RESOURCE_NOT_FOUND_INDEX;
      if (updatedComputedViewIndex === RESOURCE_NOT_FOUND_INDEX) {
        return;
      }

      // Reinsert the reverted computed view in the updated parent folder.
      updatedComputedViewParentFolder.children?.splice(
        updatedComputedViewIndex,
        1,
        revertedComputedView,
      );
    },
    clearAllDataLibraryContents(state) {
      if (!RD.isSuccess(state.currentBranch)) {
        return;
      }

      const rootFolder = state.updatedFolders
        .get(state.currentBranch.data.id ?? '')
        ?.get(ROOT_FOLDER_PATH);
      if (!RD.isSuccess(rootFolder)) {
        return;
      }

      rootFolder.data.children?.forEach((child) => {
        markResourceForDeletion(state, child);
      });

      resetPendingCommitState(state);
    },
    setFolderIsExpanded(state, action: { payload: { folderPath: string; isExpanded: boolean } }) {
      state.folderExpansionState.set(action.payload.folderPath, action.payload.isExpanded);
    },
    setFoldersExpansionState(state, action: { payload: Map<string, boolean> }) {
      action.payload.forEach((isExpanded, folderPath) => {
        state.folderExpansionState.set(folderPath, isExpanded);
      });
    },
    setInProgressDirectFetchPaths(state, action: { payload: Set<string> }) {
      state.inProgressDirectFetchPaths = action.payload;
    },
    revertPendingDeletion(state, action: { payload: DeleteResourceChange }) {
      if (!RD.isSuccess(state.currentBranch)) {
        return;
      }

      const { payload: deleteChange } = action;
      const deletedResourceId = deleteChange.id;
      state.pendingResourceDeletions.delete(deletedResourceId);

      const originalResource = getResourceById(
        deletedResourceId,
        state.folders.get(state.currentBranch.data.id ?? '') ?? new Map(),
      );
      if (!originalResource) {
        return;
      }

      // Get the parent folder through the original resource since the path stored by the delete
      // resource change can be an updated path if the resource was moved prior to being deleted.
      const parentFolder = getHeadParentFolder(state, originalResource.path ?? '');
      if (!parentFolder) {
        return;
      }
      parentFolder.children ??= [];
      parentFolder.children.push(originalResource);
    },
    setDirectLoadedResource(state, action: { payload: Resource }) {
      const resource = action.payload;
      state.directLoadedResource = RD.Success(resource);
      state.currentItemPath = {
        path: resource.path ?? '',
        type: resource['@type'] === FOLDER_TYPE ? ItemType.FOLDER : ItemType.VIEW,
      };
    },
    switchCurrentBranch(state, action: { payload: Branch }) {
      state.currentBranch = RD.Success(action.payload);
      state.folderExpansionState = createDefaultFolderExpansionMap();

      if (!state.folders.has(state.currentBranch.data.id ?? '')) {
        state.folders.set(state.currentBranch.data.id ?? '', createDefaultFoldersMapForBranch());
      }
      if (!state.updatedFolders.has(state.currentBranch.data.id ?? '')) {
        state.updatedFolders.set(
          state.currentBranch.data.id ?? '',
          createDefaultFoldersMapForBranch(),
        );
      }
      // TODO(zifanxiang): Read the serialized changes from the other branch.
      state.unappliedPendingChanges = new Map();

      resetPendingCommitState(state);
    },
    resetBranchOperationStatus(state) {
      state.branchOperationStatus = RD.Idle();
    },
    resetSearchState(state) {
      state.searchResults = RD.Idle();
    },
    resetHasFinishedDirectPathsFetch(state) {
      state.hasFinishedDirectPathsFetch = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listBranchesThunk.pending, (state) => {
        state.branches = RD.Loading();
        state.mainBranch = RD.Loading();
        state.currentBranch = RD.Loading();
      })
      .addCase(listBranchesThunk.fulfilled, (state, action) => {
        const branchesMap = new Map<string, Branch>();
        action.payload.branches.forEach((branch) => {
          branchesMap.set(branch.id ?? '', branch);
          if (branch.name === MAIN_BRANCH_NAME) {
            state.mainBranch = RD.Success(branch);
            state.currentBranch = RD.Success(branch);

            state.folders.set(branch.id ?? '', createDefaultFoldersMapForBranch());
            state.updatedFolders.set(branch.id ?? '', createDefaultFoldersMapForBranch());
          }
        });
        state.branches = RD.Success(branchesMap);
      })
      .addCase(listBranchesThunk.rejected, (state) => {
        state.branches = RD.Error('Error fetching branches');
        state.mainBranch = RD.Error('Error fetching main branch');
        state.currentBranch = RD.Error('Error fetching main branch');
      })
      .addCase(fetchGlobalDatasetFidoViewPreview.pending, (state, { meta }) => {
        const dataset = meta.arg.view;
        state.datasetData[dataset.id] ??= {};
        state.datasetData[dataset.id][dataset.versionId ?? ''] = { loading: true };
      })
      .addCase(fetchGlobalDatasetFidoViewPreview.fulfilled, (state, { payload, meta }) => {
        const dataset = meta.arg.view;
        const { schema, totalResults, rows, queryInformation } =
          getEmbeddoResponseFromFidoResponse(payload);

        state.datasetData[dataset.id][dataset.versionId ?? ''] = {
          schema,
          rows,
          totalRowCount: totalResults ?? undefined,
          loading: false,
          queryInformation,
          error: undefined,
          unsupportedOperations: undefined,
        };

        if (!RD.isSuccess(state.currentBranch)) {
          return;
        }

        if (meta.arg.origin !== FetchOrigin.DATA_LIBRARY) {
          // Do not attempt to update the schema of any dataset if the preview fetch did not
          // originate from the data library (previews are generated in the dashboard and report
          // builder as well).
          return;
        }

        const fetchedSchema = payload.meta.schema.propertySchema;
        const resourceFromFolders = getResourceById(
          dataset.id,
          state.updatedFolders.get(state.currentBranch.data.id ?? '') ?? new Map(),
        );
        if (
          !resourceFromFolders ||
          resourceFromFolders['@type'] !== COMPUTED_VIEW_TYPE ||
          resourceFromFolders.versionId !== dataset.versionId
        ) {
          return;
        }
        const updatedComputedView: ReadAccessComputedView = {
          ...(resourceFromFolders as ReadAccessComputedView),
          columnDefinitions: fetchedSchema,
        };
        handleAddPendingResourceUpdate(state, updatedComputedView);
      })
      .addCase(fetchGlobalDatasetFidoViewPreview.rejected, (state, { error, meta }) => {
        const dataset = meta.arg.view;
        state.datasetData[dataset.id][dataset.versionId ?? ''] = {
          loading: false,
          error: error.message ?? 'Something went wrong',
        };
      })
      .addCase(listBranchContentThunk.pending, (state, action) => {
        const {
          foldersForBranch: foldersForCurrentBranch,
          updatedFoldersForBranch: updatedFoldersForCurrentBranch,
        } = getFoldersAndUpdatedFoldersForBranch(state, state.currentBranch);

        if (!foldersForCurrentBranch || !updatedFoldersForCurrentBranch) {
          return;
        }

        if (action.meta.arg.resourceType === ItemType.FOLDER) {
          foldersForCurrentBranch.set(action.meta.arg.path, RD.Loading());
          updatedFoldersForCurrentBranch.set(action.meta.arg.path, RD.Loading());
        }
      })
      .addCase(listBranchContentThunk.fulfilled, (state, action) => {
        const {
          foldersForBranch: foldersForCurrentBranch,
          updatedFoldersForBranch: updatedFoldersForCurrentBranch,
        } = getFoldersAndUpdatedFoldersForBranch(state, state.currentBranch);

        if (!foldersForCurrentBranch || !updatedFoldersForCurrentBranch) {
          return;
        }

        const returnedResource = action.payload.content;
        if (returnedResource['@type'] !== 'folder') {
          return;
        }
        const sanitizedFolder = sanitizeFolder(returnedResource as Folder);
        foldersForCurrentBranch.set(returnedResource.path ?? '', RD.Success(sanitizedFolder));
        updatedFoldersForCurrentBranch.set(
          returnedResource.path ?? '',
          RD.Success(cloneDeep(sanitizedFolder)),
        );
        if (state.inProgressDirectFetchPaths.has(returnedResource.path ?? '')) {
          state.inProgressDirectFetchPaths.delete(returnedResource.path ?? '');
          state.hasFinishedDirectPathsFetch = state.inProgressDirectFetchPaths.size === 0;
        }
        maybeApplyUnappliedPendingChanges(state);
      })
      .addCase(listBranchContentThunk.rejected, (state, action) => {
        const {
          foldersForBranch: foldersForCurrentBranch,
          updatedFoldersForBranch: updatedFoldersForCurrentBranch,
        } = getFoldersAndUpdatedFoldersForBranch(state, state.currentBranch);

        if (!foldersForCurrentBranch || !updatedFoldersForCurrentBranch) {
          return;
        }

        if (action.meta.arg.resourceType === ItemType.FOLDER) {
          foldersForCurrentBranch.set(action.meta.arg.path, RD.Error('Error fetching folder'));
          updatedFoldersForCurrentBranch.set(
            action.meta.arg.path,
            RD.Error('Error fetching folder'),
          );
        }
      })
      .addCase(createCommitThunk.pending, (state) => {
        resetPendingCommitState(state);
      })
      .addCase(createCommitThunk.fulfilled, (state, { payload }) => {
        if (!RD.isSuccess(state.currentBranch)) {
          return;
        }

        state.currentBranch.data.headId = payload.commitId;
        state.pendingCommitStatus = RD.Success(payload.commitId);

        // Reset the stored branch commits to force a refetch.
        // TODO(zifanxiang): Consider instead just appending to the existing branch commits state.
        state.branchCommits = RD.Idle();

        const currentBranchId = state.currentBranch.data.id ?? '';
        const updatedFoldersForBranch: Map<
          FolderPath,
          RD.ResponseData<Folder>
        > = state.updatedFolders.get(currentBranchId) ?? new Map();
        // The server assigns ids to resources on creation and updates version ids on update, so we
        // need to update the locally stored resources that were created or updated as part of the
        // commit.
        const acknowledgedCreationsAndUpdates = payload.changes.filter(
          (change) => change['@type'] === 'create' || change['@type'] === 'update',
        );
        acknowledgedCreationsAndUpdates.forEach((change) => {
          if (!('resource' in change)) {
            return;
          }

          const resourcePath = change.resource.path;
          const resourceParentPath = getParentPath(resourcePath ?? '');

          const updatedParentFolderResponse = updatedFoldersForBranch.get(resourceParentPath);
          if (!RD.isSuccess(updatedParentFolderResponse)) {
            return;
          }
          const updateResult = updateResourceIdsInParentFolder(
            updatedParentFolderResponse.data,
            change.resource,
          );
          if (!updateResult) {
            return;
          }
          const originalResource = updateResult.originalResource;
          const updatedResource = updateResult.updatedResource;
          if (originalResource['@type'] === COMPUTED_VIEW_TYPE) {
            const previewData =
              state.datasetData[originalResource.id ?? '']?.[originalResource.versionId ?? ''];
            if (previewData) {
              state.datasetData[updatedResource.id ?? ''] ??= {};
              state.datasetData[updatedResource.id ?? ''][updatedResource.versionId ?? ''] =
                previewData;
              delete state.datasetData[originalResource.id ?? ''][originalResource.versionId ?? ''];
            }
          }

          // Update the folders stored as direct values within the updatedFolders map.
          if (change.resource['@type'] === FOLDER_TYPE) {
            const storedFolder = updatedFoldersForBranch.get(resourcePath ?? '');
            if (!RD.isSuccess(storedFolder)) {
              return;
            }
            // Only update the ids since the returned resources from the server might not have all
            // fields correctly set. E.g. for created nested folders, the parent folders do not
            // have their children field properly set.
            const folderWithUpdatedIds = {
              ...storedFolder.data,
              id: change.resource.id,
              versionId: change.resource.versionId,
            };
            updatedFoldersForBranch.set(resourcePath ?? '', RD.Success(folderWithUpdatedIds));
          }
        });

        // Update the folders to reflect the changes that were acknowledged by the server.
        state.folders.set(currentBranchId, new Map());
        updatedFoldersForBranch.forEach((folderResponse, path) => {
          state.folders.get(currentBranchId)?.set(path, cloneDeep(folderResponse));
        });
        resetPendingChanges(state);
      })
      .addCase(createCommitThunk.rejected, (state, action) => {
        state.pendingCommitStatus = RD.Error(action.error.message ?? 'Error committing changes');
        state.pendingCommitError = action.error;
      })
      .addCase(parseQueryThunk.pending, (state) => {
        state.detectedQueryParameters = RD.Loading();
      })
      .addCase(parseQueryThunk.fulfilled, (state, { payload, meta }) => {
        if (!RD.isSuccess(state.currentBranch)) {
          return;
        }

        const parameters = payload.parameters;
        state.detectedQueryParameters = RD.Success(parameters);

        const datasetId = meta.arg.datasetId;
        const dataset = getResourceById(
          datasetId,
          state.updatedFolders.get(state.currentBranch.data.id ?? '') ?? new Map(),
        ) as ReadAccessComputedView;

        const currentDatasetParameters = dataset.parameters;
        const currentDatasetNameToParameterMap = new Map();
        currentDatasetParameters.forEach((parameter) => {
          currentDatasetNameToParameterMap.set(parameter.name, parameter);
        });
        // If the detected parameter has the same name (identifier), keep the existing parameter in
        // order to preserve the set properties such as the type.
        const newParametersForDataset = parameters.map((parameter) => {
          const currentParameter = currentDatasetNameToParameterMap.get(parameter.name);
          return currentParameter ? currentParameter : parameter;
        });

        if (isEqual(newParametersForDataset, currentDatasetParameters)) {
          return;
        }

        dataset.parameters = [...newParametersForDataset];
        handleAddPendingResourceUpdate(state, dataset);
      })
      .addCase(parseQueryThunk.rejected, (state) => {
        state.detectedQueryParameters = RD.Error('Error parsing query parameters.');
      })
      .addCase(listCommitsThunk.pending, (state) => {
        state.branchCommits = RD.Loading();
      })
      .addCase(listCommitsThunk.fulfilled, (state, { payload }) => {
        state.branchCommits = RD.Success(payload.commits);
      })
      .addCase(getResourceOnBranchThunk.pending, (state) => {
        state.directLoadedResource = RD.Loading();
      })
      .addCase(getResourceOnBranchThunk.fulfilled, (state, { payload }) => {
        state.directLoadedResource = RD.Success(payload.resource);
        state.currentItemPath = {
          path: payload.resource.path ?? '',
          type: payload.resource['@type'] === FOLDER_TYPE ? ItemType.FOLDER : ItemType.VIEW,
        };
      })
      .addCase(getResourceOnBranchThunk.rejected, (state) => {
        state.directLoadedResource = RD.Error('Error fetching resource');
      })
      .addCase(createBranchThunk.pending, (state) => {
        state.branchOperationStatus = RD.Loading();
      })
      .addCase(createBranchThunk.fulfilled, (state, { payload }) => {
        if (!RD.isSuccess(state.branches) || !RD.isSuccess(state.mainBranch)) {
          return;
        }

        state.branchOperationStatus = RD.Success({
          type: BranchOperationType.CREATE,
          branch: payload.branch,
        });
        state.branches.data?.set(payload.branch.id ?? '', payload.branch);
        state.mainBranch.data = payload.branch;
      })
      .addCase(createBranchThunk.rejected, (state) => {
        state.branchOperationStatus = RD.Error('Error creating branch');
      })
      .addCase(updateBranchThunk.pending, (state) => {
        state.branchOperationStatus = RD.Loading();
      })
      .addCase(updateBranchThunk.fulfilled, (state, { payload }) => {
        if (RD.isSuccess(state.branches)) {
          state.branches.data?.set(payload.branch.id ?? '', payload.branch);
        }
        if (
          RD.isSuccess(state.currentBranch) &&
          state.currentBranch.data.id === payload.branch.id
        ) {
          state.currentBranch.data = payload.branch;
        }
        state.branchOperationStatus = RD.Success({
          type: BranchOperationType.UPDATE,
          branch: payload.branch,
        });
      })
      .addCase(deleteBranchThunk.pending, (state) => {
        state.branchOperationStatus = RD.Loading();
      })
      .addCase(deleteBranchThunk.fulfilled, (state, action) => {
        if (RD.isSuccess(state.branches) && action.meta.arg.id) {
          if (
            RD.isSuccess(state.currentBranch) &&
            state.currentBranch.data.id === action.meta.arg.id
          ) {
            state.currentBranch = state.mainBranch;
          }
          state.branches.data?.delete(action.meta.arg.id);
        }
        state.branchOperationStatus = RD.Success({
          type: BranchOperationType.DELETE,
        });
      })
      .addCase(getCommitDetailsThunk.pending, (state, { meta }) => {
        state.commitDetails.set(meta.arg.commitId, RD.Loading());
      })
      .addCase(getCommitDetailsThunk.fulfilled, (state, { payload, meta }) => {
        state.commitDetails.set(meta.arg.commitId, RD.Success(payload.meta ?? { changes: [] }));
      })
      .addCase(getCommitDetailsThunk.rejected, (state, { error, meta }) => {
        state.commitDetails.set(
          meta.arg.commitId,
          RD.Error(error.message ?? 'Error fetching commit details'),
        );
      })
      .addCase(fetchDataLibraryViewUsages.pending, (state) => {
        state.computedViewUsage = RD.Loading();
      })
      .addCase(fetchDataLibraryViewUsages.fulfilled, (state, { payload }) => {
        state.computedViewUsage = RD.Success({
          viewId: payload.viewId,
          viewVersionId: payload.viewVersionId,
          dashboards: payload.dashboards,
          reportBuilders: payload.reportBuilders,
        });
      })
      .addCase(searchBranchContentThunk.pending, (state) => {
        state.searchResults = RD.Loading();
      })
      .addCase(searchBranchContentThunk.fulfilled, (state, { payload }) => {
        state.searchResults = RD.Success(payload);
      })
      .addCase(searchBranchContentThunk.rejected, (state) => {
        state.searchResults = RD.Error('Error searching branch content');
      });
  },
});

const handleAddPendingResourceUpdate = (state: DataLibraryState, resource: Resource) => {
  const baseResource = getBaseResource(state, resource.path ?? '');
  // The resource has been restored to the base version, remove the pending update.
  if (baseResource && isEqual(baseResource, resource)) {
    state.pendingResourceUpdates.delete(resource.id ?? '');
  } else {
    appendUpdatedResourceToPendingChanges(state, resource);
  }

  const parentFolder = getHeadParentFolder(state, resource.path ?? '');
  if (!parentFolder || !parentFolder.children) {
    return;
  }
  const index = parentFolder.children.findIndex((child) => child.id === resource.id);
  parentFolder.children[index] = resource;

  resetPendingCommitState(state);
};

const maybeApplyUnappliedPendingChanges = (state: DataLibraryState) => {
  const appliedPendingChangeIds: Set<string> = new Set();
  state.unappliedPendingChanges.forEach((change) => {
    if (change['@type'] === 'create') {
      const wasApplied = maybeApplyResourceAddition(state, change.resource);
      if (wasApplied) {
        appliedPendingChangeIds.add(change.resource.id ?? '');
        state.pendingResourceCreations.set(change.resource.id ?? '', cloneDeep(change));
      }
    } else if (change['@type'] === 'update') {
      const wasApplied = maybeApplyResourceUpdate(state, change);
      if (wasApplied) {
        appliedPendingChangeIds.add(change.resource.id ?? '');
        state.pendingResourceUpdates.set(change.resource.id ?? '', cloneDeep(change));
      }
    } else if (change['@type'] === 'delete') {
      const wasApplied = maybeApplyResourceDeletion(state, change);
      if (wasApplied) {
        appliedPendingChangeIds.add(change.id);
        state.pendingResourceDeletions.set(change.id, cloneDeep(change));
      }
    }
  });

  appliedPendingChangeIds.forEach((id) => {
    state.unappliedPendingChanges.delete(id);
  });
};

const getFoldersAndUpdatedFoldersForBranch = (
  state: DataLibraryState,
  branch: RD.ResponseData<Branch>,
): {
  foldersForBranch: Map<FolderPath, RD.ResponseData<Folder>> | undefined;
  updatedFoldersForBranch: Map<FolderPath, RD.ResponseData<Folder>> | undefined;
} => {
  if (!RD.isSuccess(branch)) {
    return {
      foldersForBranch: undefined,
      updatedFoldersForBranch: undefined,
    };
  }

  const branchId = branch.data.id ?? '';
  const foldersForBranch = state.folders.get(branchId);
  const updatedFoldersForBranch = state.updatedFolders.get(branchId);

  return {
    foldersForBranch,
    updatedFoldersForBranch,
  };
};

const getFoldersForBranch = (
  state: DataLibraryState,
  branch: RD.ResponseData<Branch>,
  isBase: boolean,
): Map<FolderPath, RD.ResponseData<Folder>> => {
  if (!RD.isSuccess(branch)) {
    return new Map();
  }

  const foldersMap = isBase ? state.folders : state.updatedFolders;
  return foldersMap.get(branch.data.id ?? '') ?? new Map();
};

/**
 * @param foldersMap The map of branch ids to the folder map for the branch. The folder map for each
 *     branch is flat map of folder paths to folders
 * @param resourcePath The path of the resource to get the parent folder of
 * @param branch The branch that the resource is on
 * @returns The parent folder of the resource on the given branch
 */
const getParentFolder = (
  foldersMap: Map<BranchId, Map<FolderPath, RD.ResponseData<Folder>>>,
  resourcePath: string,
  branch: RD.ResponseData<Branch>,
): Folder | undefined => {
  if (!RD.isSuccess(branch)) {
    return undefined;
  }

  const foldersMapForBranch = foldersMap.get(branch.data.id ?? '');
  if (!foldersMapForBranch) {
    return undefined;
  }

  const parentPath = getParentPath(resourcePath);
  const parentFolderResponse = foldersMapForBranch.get(parentPath);
  if (!RD.isSuccess(parentFolderResponse)) {
    return undefined;
  }

  return parentFolderResponse.data;
};

/** @returns The head parent folder of the resource in the current branch */
const getHeadParentFolder = (state: DataLibraryState, resourcePath: string): Folder | undefined => {
  return getParentFolder(state.updatedFolders, resourcePath, state.currentBranch);
};

/** @returns The base parent folder of the resource in the current branch */
const getBaseParentFolder = (state: DataLibraryState, resourcePath: string): Folder | undefined => {
  return getParentFolder(state.folders, resourcePath, state.currentBranch);
};

const getBaseResource = (state: DataLibraryState, resourcePath: string): Resource | undefined => {
  const parentFolder = getBaseParentFolder(state, resourcePath);
  if (!parentFolder) {
    return undefined;
  }

  return parentFolder.children?.find((child) => child.path === resourcePath);
};

const getBaseResourceById = (state: DataLibraryState, resourceId: string): Resource | undefined => {
  const foldersForBranch = getFoldersForBranch(state, state.currentBranch, /* isBase= */ true);
  return getResourceById(resourceId, foldersForBranch);
};

const maybeApplyResourceAddition = (state: DataLibraryState, resource: Resource): boolean => {
  if (!RD.isSuccess(state.currentBranch)) {
    return false;
  }

  const parentFolder = getHeadParentFolder(state, resource.path ?? '');
  if (!parentFolder) {
    return false;
  }

  parentFolder.children ??= [];
  parentFolder.children.push(resource);

  if (resource['@type'] === FOLDER_TYPE) {
    const updatedFoldersForBranch = state.updatedFolders.get(state.currentBranch.data.id ?? '');
    updatedFoldersForBranch?.set(resource.path ?? '', RD.Success(resource as Folder));
  }

  return true;
};

const maybeApplyResourceUpdate = (
  state: DataLibraryState,
  pendingResourceUpdate: UpdateResourceChange,
): boolean => {
  if (!RD.isSuccess(state.currentBranch)) {
    return false;
  }

  const parentFolder = getHeadParentFolder(state, pendingResourceUpdate.resource.path ?? '');
  if (!parentFolder) {
    return false;
  }

  const updatedResource = pendingResourceUpdate.resource;
  const parentFolderChildren = parentFolder.children ?? [];
  const previousResourceIndex =
    parentFolderChildren.findIndex((child) => child.id === updatedResource.id) ??
    RESOURCE_NOT_FOUND_INDEX;
  if (previousResourceIndex === RESOURCE_NOT_FOUND_INDEX) {
    return false;
  }
  parentFolderChildren.splice(previousResourceIndex, 1, updatedResource);

  if (updatedResource['@type'] === FOLDER_TYPE) {
    const updatedFoldersForBranch = state.updatedFolders.get(state.currentBranch.data.id ?? '');
    updatedFoldersForBranch?.set(updatedResource.path ?? '', RD.Success(updatedResource as Folder));
  }

  return true;
};

const maybeApplyResourceDeletion = (
  state: DataLibraryState,
  pendingResourceDeletion: DeleteResourceChange,
): boolean => {
  const parentFolder = getHeadParentFolder(state, pendingResourceDeletion.path);
  if (!parentFolder) {
    return false;
  }

  const resourcePath = pendingResourceDeletion.path;
  const resourceIndex =
    parentFolder.children?.findIndex((child) => child.path === resourcePath) ??
    RESOURCE_NOT_FOUND_INDEX;
  if (resourceIndex === RESOURCE_NOT_FOUND_INDEX) {
    return false;
  }

  const deletedResource = parentFolder.children?.splice(resourceIndex, 1)[0];
  if (!deletedResource) {
    return false;
  }

  if (deletedResource['@type'] === FOLDER_TYPE && RD.isSuccess(state.currentBranch)) {
    state.updatedFolders.delete(resourcePath);
  }

  return true;
};

const resetPendingChanges = (state: DataLibraryState) => {
  state.pendingResourceCreations = new Map();
  state.pendingResourceDeletions = new Map();
  state.pendingResourceUpdates = new Map();
  state.unappliedPendingChanges = new Map();
};

const getAllChildFoldersFromFolder = (
  foldersMap: Map<string, RD.ResponseData<Folder>>,
  folder: Folder,
): Folder[] => {
  return getAllChildrenFromFolder(foldersMap, folder, FOLDER_TYPE) as Folder[];
};

const getAllChildrenFromFolder = (
  foldersMap: Map<string, RD.ResponseData<Folder>>,
  folder: Folder,
  resourceType: string,
): Resource[] => {
  const children: Resource[] = [];
  if (!folder.children) {
    return children;
  }

  folder.children.forEach((child) => {
    if (child['@type'] === FOLDER_TYPE) {
      const childFolder = foldersMap.get(child.path ?? '');
      if (RD.isSuccess(childFolder)) {
        children.push(...getAllChildrenFromFolder(foldersMap, childFolder.data, resourceType));
      }
    }
    if (child['@type'] === resourceType) {
      children.push(child);
    }
  });

  return children;
};

const deleteResourceFromPendingChangesAndMaybeAddDeletion = (
  resourceId: string,
  resourcePath: string,
  resourceType: string,
  state: DataLibraryState,
) => {
  // If the resource is pending creation, we will remove the creation from the pending creations
  // and not add a deletion.
  if (!state.pendingResourceCreations.has(resourceId)) {
    state.pendingResourceDeletions.set(resourceId, {
      id: resourceId,
      path: resourcePath,
      '@type': 'delete',
      resourceType,
    });
  }
  // A deletion removes any creations or updates to the resource.
  state.pendingResourceCreations.delete(resourceId);
  state.pendingResourceUpdates.delete(resourceId);
};

const deleteChildFromParentFolder = (child: Resource, state: DataLibraryState) => {
  const parentFolder = getHeadParentFolder(state, child.path ?? '');
  if (!parentFolder || !parentFolder.children) {
    return;
  }

  const childIndex = parentFolder.children.findIndex((folderChild) => folderChild.id === child.id);
  if (childIndex !== RESOURCE_NOT_FOUND_INDEX) {
    parentFolder.children.splice(childIndex, 1);
  }
};

const resetPendingCommitState = (state: DataLibraryState) => {
  state.pendingCommitStatus = RD.Idle();
  state.pendingCommitError = null;
};

const markResourceForDeletion = (state: DataLibraryState, resource: Resource) => {
  if (!RD.isSuccess(state.currentBranch)) {
    return;
  }

  const resourceType = resource['@type'];
  deleteResourceFromPendingChangesAndMaybeAddDeletion(
    resource.id ?? '',
    resource.path ?? '',
    resourceType,
    state,
  );
  deleteChildFromParentFolder(resource, state);

  if (resourceType !== FOLDER_TYPE) {
    return;
  }

  deleteAllChildFolders(state, state.currentBranch.data, resource as Folder);
};

const deleteAllChildFolders = (state: DataLibraryState, branch: Branch, folder: Folder) => {
  const updatedFoldersForBranch: Map<string, RD.ResponseData<Folder>> = state.updatedFolders.get(
    branch.id ?? '',
  ) ?? new Map();
  // Use the version of the folder from the top level of the folders map since that contains the
  // version of the folder that has all children.
  const fullFolder = updatedFoldersForBranch.get(folder.path ?? '');
  if (!RD.isSuccess(fullFolder)) {
    return;
  }
  const allChildFolders = getAllChildFoldersFromFolder(updatedFoldersForBranch, fullFolder.data);
  updatedFoldersForBranch.delete(folder.path ?? '');
  allChildFolders.forEach((childFolder) => {
    updatedFoldersForBranch.delete(childFolder.path ?? '');
  });
};

const appendUpdatedResourceToPendingChanges = (
  state: DataLibraryState,
  updatedResource: Resource,
) => {
  const updatedResourceId = updatedResource.id ?? '';
  if (state.pendingResourceCreations.has(updatedResourceId)) {
    state.pendingResourceCreations.set(updatedResourceId, {
      '@type': 'create',
      resource: updatedResource,
    });
  } else {
    state.pendingResourceUpdates.set(updatedResourceId, {
      '@type': 'update',
      resource: updatedResource,
    });
  }
};

export const dataLibraryReducer: Reducer<DataLibraryState> = dataLibrarySlice.reducer;

export const {
  addPendingResourceCreation,
  addPendingResourceDeletion,
  addPendingResourceUpdate,
  addPendingResourceMove,
  setCurrentItemInfo,
  updateVariables,
  clearQueryState,
  setUnappliedPendingChanges,
  clearPendingChanges,
  clearAllDataLibraryContents,
  setFolderIsExpanded,
  setFoldersExpansionState,
  setInProgressDirectFetchPaths,
  revertPendingDeletion,
  revertComputedViewQuery,
  setDirectLoadedResource,
  switchCurrentBranch,
  updateDatasetParameter,
  resetBranchOperationStatus,
  resetSearchState,
  resetHasFinishedDirectPathsFetch,
} = dataLibrarySlice.actions;

export const ALL_PENDING_CHANGES_ACTION_TYPES: Set<string> = new Set([
  addPendingResourceCreation.type,
  addPendingResourceDeletion.type,
  addPendingResourceUpdate.type,
  revertComputedViewQuery.type,
  revertPendingDeletion.type,
  updateDatasetParameter.type,
  parseQueryThunk.fulfilled.type,
  fetchGlobalDatasetFidoViewPreview.fulfilled.type,
]);

export const CLEARING_PENDING_CHANGES_ACTION_TYPES: Set<string> = new Set([
  createCommitThunk.fulfilled.type,
  clearPendingChanges.type,
]);
