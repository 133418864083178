import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomerReportGroupBy, PIVOT_AGG_TYPES, PivotAgg } from '@explo/data';

import { ReportBuilderColConfigs } from 'actions/reportBuilderConfigActions';
import { EmbeddedDropdownButton } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownButton';
import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';
import * as styles from 'pages/ReportBuilder/ReportView/DataPanel/ColumnSection.css';
import { DataPanelListItem } from 'pages/ReportBuilder/ReportView/DataPanel/DataPanelListItem';
import { COLS_SECTION_ID } from 'pages/ReportBuilder/ReportView/DataPanel/constants';
import { SCHEMA_TYPE_ICON_MAP } from 'pages/ReportBuilder/constants';
import {
  deleteGroupBy,
  deleteScatterPlotGrouping,
  updateGroupBy,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { getGroupByDisplay } from 'utils/V2ColUtils';
import {
  BucketsByCol,
  getGroupByUniqueId,
  getGroupByBucketOptions,
} from 'utils/customerReportUtils';
import { ReduxState } from 'reducers/rootReducer';
import { getReportBuilderUseFido } from 'reducers/selectors';

type Props = {
  isScatterPlotGrouping?: boolean;
  groupBy: CustomerReportGroupBy;
  bucketsByCol: BucketsByCol;
  columnConfigs?: ReportBuilderColConfigs;
  section: string;
  disabled?: boolean;
  sourceType: string | null;
};

export const GroupByListItem: FC<Props> = ({
  isScatterPlotGrouping,
  groupBy,
  bucketsByCol,
  columnConfigs,
  section,
  disabled,
  sourceType,
}) => {
  const dispatch = useDispatch();
  const isColumnGroupBy = section === COLS_SECTION_ID;

  const shouldUseFido = useSelector((state: ReduxState) => getReportBuilderUseFido(state));

  const id = getGroupByUniqueId(groupBy);
  const { column, bucket } = groupBy;
  const bucketOptions = getGroupByBucketOptions(groupBy, bucketsByCol, sourceType, shouldUseFido);
  const selectedBucket = bucket ? PIVOT_AGG_TYPES[bucket].name : '';

  const handleUpdateGroupBy = (newPivotAgg: string) =>
    dispatch(
      updateGroupBy({
        id,
        isColumnGroupBy,
        groupBy: { ...groupBy, bucket: newPivotAgg as PivotAgg },
      }),
    );

  return (
    <DataPanelListItem
      actionButton={{
        variant: 'tertiary',
        icon: 'trash',
        onClick: () =>
          dispatch(
            isScatterPlotGrouping
              ? deleteScatterPlotGrouping()
              : deleteGroupBy({ id, isColumnGroupBy }),
          ),
        className: styles.eyeIcon,
      }}
      column={column}
      disabled={disabled}
      icon={SCHEMA_TYPE_ICON_MAP[groupBy.column.type]}
      id={id}
      name={getGroupByDisplay(groupBy, columnConfigs)}
      section={section}>
      {bucketOptions.length > 0 ? (
        <EmbeddedDropdownMenu menuOptions={bucketOptions} onClick={handleUpdateGroupBy}>
          <EmbeddedDropdownButton selectedName={selectedBucket} />
        </EmbeddedDropdownMenu>
      ) : null}
    </DataPanelListItem>
  );
};
