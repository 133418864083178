import { sprinkles } from '@explo/design-system';
import { Spinner } from 'components/ds';
import { useExportsList } from 'features/dataShare/hooks';
import { FC, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { pageView } from 'telemetry/exploAnalytics';
import { EditExport } from '../features/dataShare/EditExport';

export const EmailExportPage: FC = () => {
  useEffect(() => {
    document.title = 'Explo | Email Export';
    pageView('Email Export');
  }, []);

  const { exportId } = useParams<{ exportId: string }>();
  const { isLoading, data: exports } = useExportsList();

  const exportItem = useMemo(() => {
    return exports?.find((e) => e.id === exportId);
  }, [exports, exportId]);

  return (
    <div
      className={sprinkles({
        flexItems: 'column',
        flex: 1,
        backgroundColor: 'white',
      })}>
      {isLoading && exportId ? <Spinner fillContainer /> : <EditExport exportItem={exportItem} />}
    </div>
  );
};
