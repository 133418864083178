import cx from 'classnames';
import { FC, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import {
  BaseCol,
  DatasetSchema,
  OldConfigurableColInfo,
  PERIOD_RANGE_OPTIONS,
  PeriodRangeTypes,
  getDateRangeExampleForDropdown,
  getKpiDateRanges,
} from '@explo/data';

import { sprinkles } from '@explo/design-system';
import { IconButton } from 'components/ds';
import { EmbeddedDropdownMenu } from 'components/resource/EmbeddedDropdownMenu/EmbeddedDropdownMenu';
import { ReduxState } from 'reducers/rootReducer';

import { ColumnOptionsPopover } from './ColumnOptionsPopover';
import * as styles from './styles.css';

type Props = {
  column: OldConfigurableColInfo;
  draggingClass: string;
  draggingPopoverClass: string | undefined;
  onColAdded: (newCol: BaseCol, oldColName?: string) => void;
  onColOptionChanged: (option: { id: string; name: string }) => void;
  onRemoveCol: () => void;
  disableEdits: boolean;
  periodRange: PeriodRangeTypes | undefined;
  schema: DatasetSchema;
};

export const DroppedPeriodColumn: FC<Props> = ({
  column,
  draggingClass,
  draggingPopoverClass,
  onColAdded,
  onColOptionChanged,
  onRemoveCol,
  disableEdits,
  periodRange,
  schema,
}) => {
  const { shouldUseFido, timezone } = useSelector(
    (state: ReduxState) => ({
      shouldUseFido: state.currentUser.team?.feature_flags.use_fido,
      timezone: state.dashboardLayout.requestInfo.timezone,
    }),
    shallowEqual,
  );

  const selectedOption = periodRange && PERIOD_RANGE_OPTIONS[periodRange];

  const containerStyles = cx(
    sprinkles({
      borderRadius: 3,
      display: 'flex',
      backgroundColor: 'lightBlue',
    }),
    draggingClass,
  );

  const periodRangeMenuOptions = useMemo(
    () =>
      Object.values(PERIOD_RANGE_OPTIONS).map((option) => {
        let description = '';

        if (
          shouldUseFido &&
          option.id !== PeriodRangeTypes.CUSTOM_RANGE &&
          option.id !== PeriodRangeTypes.TIME_PERIOD_DROPDOWN &&
          option.id !== PeriodRangeTypes.DATE_RANGE_INPUT &&
          option.id !== PeriodRangeTypes.CUSTOM_RANGE_VARIABLES
        ) {
          description = getDateRangeExampleForDropdown(
            getKpiDateRanges(
              {
                periodRange: option.id,
                column: {},
              },
              undefined,
              timezone,
            ).currentPeriod,
          );
        }

        return {
          value: option.id,
          name: option.name,
          description,
          onClick: () => !disableEdits && onColOptionChanged(option),
        };
      }),
    [shouldUseFido, disableEdits, onColOptionChanged, timezone],
  );

  return (
    <div className={containerStyles}>
      <div
        className={cx(
          sprinkles({ width: 'fill', flex: 3, overflow: 'hidden', display: 'flex' }),
          draggingPopoverClass,
        )}>
        <ColumnOptionsPopover
          rightDivider
          displayName={column.column.name}
          onColChange={(newCol) => onColAdded(newCol, column.column.name)}
          schema={schema}
        />
      </div>
      <div
        className={cx(
          sprinkles({ width: 'fill', flex: 2, display: 'flex', overflow: 'hidden' }),
          draggingPopoverClass,
        )}>
        <EmbeddedDropdownMenu disabled={disableEdits} menuOptions={periodRangeMenuOptions}>
          <div className={styles.popoverTargetContainer}>
            <div className={styles.colName}>{selectedOption?.name}</div>
            <IconButton className={styles.iconBtnWithDivider} name="caret-down" />
          </div>
        </EmbeddedDropdownMenu>
      </div>
      <IconButton
        className={styles.iconBtn}
        disabled={disableEdits}
        name="cross"
        onClick={() => !disableEdits && onRemoveCol()}
      />
    </div>
  );
};
