import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { verifyEmail as verifyEmailAction, logOutUser } from 'actions/authAction';
import { verifyEmail } from 'features/auth/api';
import { OnboardingFlowPage } from 'components/Onboarding/OnboardingFlowPage';
import { ROUTES } from 'constants/routes';
import { showSuccessToast } from 'shared/sharedToasts';
import { pageView } from 'telemetry/exploAnalytics';

export const VerifyEmailPage: FC = () => {
  const history = useHistory();
  const { verificationId } = useParams<{ verificationId: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    pageView('Verify Email');
  }, []);

  useEffect(() => {
    verifyEmail(
      verificationId,
      () => {
        dispatch(verifyEmailAction());
        showSuccessToast('Your email has been successfully verified! You can now log in.');

        history.push(ROUTES.LOGIN);
      },
      () => history.push(ROUTES.LOGIN),
    );
  }, [history, verificationId, dispatch]);

  return (
    <OnboardingFlowPage
      helpLinks={[
        {
          name: 'Log Out',
          onClick: () => {
            // we shouldn't actually be logged in on this page, but log out just for safety
            // and then make sure we navigate to the log in page
            dispatch(logOutUser());
            history.push(ROUTES.LOGIN);
          },
        },
        { name: 'Need Support?', url: 'https://docs.explo.co/' },
      ]}
      title={'Verifying... Sit tight!'}
    />
  );
};
