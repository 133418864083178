import cx from 'classnames';
import { FC, ReactNode } from 'react';
import { withSize, SizeMeProps } from 'react-sizeme';

import { Button, Icon, IconButton, Intent, IntentType, sprinkles } from 'components/ds';
import { ConnectStepByStep } from 'pages/ConnectDataSourceFlow/ConnectStepByStep';
import { ConnectDataSourceStep } from 'pages/ConnectDataSourceFlow/constants';

const FOOTER_HEIGHT = 56;

type Props = {
  // text to display as header of the container
  headerTitle: string;
  // text to display as subtitle of header
  headerSubtitle?: string;
  // configure the primary action button on the page
  primaryActionConfig: {
    // id to give primary action button
    id?: string;
    // text to on primary button in bottom right
    text: string;
    // set the primary action to be disabled
    disabled?: boolean;
    // handler when primary action is clicked
    onClick: () => void;
    // Indicates if the primary button is in a loading state
    loading?: boolean;
  };
  // handler when back button is clicked. If not provided, no back button appears
  backBtnOnClick?: () => void;
  // config of banner showing information on the bottom of the body above the footer
  bottomBannerConfig?: {
    //  text or JSX element to show in the banner
    text: string | JSX.Element;
    // the intent of the banner
    intent: IntentType;
  };
  currentStep: ConnectDataSourceStep;

  // content to be shown in the actual main container body
  bodyContent: ReactNode;
};

const LAYOUT_BREAKPOINT = 1440;

const ConnectDataSourceContainerBase: FC<Props & SizeMeProps> = ({
  headerTitle,
  headerSubtitle,
  primaryActionConfig,
  backBtnOnClick,
  bodyContent,
  bottomBannerConfig,
  size,
  currentStep,
}) => {
  const leftSideBarBottomContent = (
    <div className={leftSideBarBottomClass}>
      <a
        className={sprinkles({ color: 'contentPrimary' })}
        href="//docs.explo.co/data-sources/connecting-to-data-sources"
        rel="noopener noreferrer"
        target="_blank">
        Data Sources Docs
      </a>
      <a
        className={sprinkles({ color: 'contentPrimary' })}
        href="//docs.explo.co/security/security-faqs"
        rel="noopener noreferrer"
        target="_blank">
        Security FAQs
      </a>
    </div>
  );

  const renderBottomBanner = () => {
    if (!bottomBannerConfig) return null;

    const isSuccess = bottomBannerConfig.intent === Intent.SUCCESS;
    const isWarning = bottomBannerConfig.intent === Intent.WARNING;

    return (
      <div
        className={sprinkles({
          paddingY: 'sp2',
          paddingX: 'sp2.5',
          flexItems: 'alignCenter',
          backgroundColor: isSuccess ? 'green3' : isWarning ? 'yellow3' : 'red3',
        })}>
        <Icon
          className={sprinkles({
            marginRight: 'sp1',
            color: isSuccess ? 'green11' : isWarning ? 'yellow11' : 'red11',
          })}
          name={isSuccess ? 'circle-check' : 'circle-exclamation'}
        />
        <span>{bottomBannerConfig.text}</span>
      </div>
    );
  };

  return (
    <div className={sprinkles({ flexItems: 'center', height: 'fill', paddingY: 'sp7' })}>
      <div className={containerWrapperClass}>
        <div className={containerClass} style={{ width: 650 }}>
          <div className={sprinkles({ flex: 1, overflowY: 'auto', paddingBottom: 'sp3' })}>
            {headerTitle ? (
              <div className={headerClass}>
                <div className={sprinkles({ heading: 'h1' })}>{headerTitle}</div>
                {headerSubtitle ? (
                  <div className={sprinkles({ marginTop: 'sp1', body: 'b2' })}>
                    {headerSubtitle}
                  </div>
                ) : null}
              </div>
            ) : null}
            <div className={sprinkles({ paddingX: 'sp4' })}>{bodyContent}</div>
          </div>
          {renderBottomBanner()}
          <div className={footerClass} style={{ height: FOOTER_HEIGHT }}>
            {backBtnOnClick ? (
              <IconButton
                data-testid="full-page-content-container-back-button"
                name="arrow-left"
                onClick={backBtnOnClick}
                variant="tertiary"
              />
            ) : (
              <span></span>
            )}
            <Button
              data-testid="full-page-content-container-next-button"
              disabled={primaryActionConfig.disabled}
              id={primaryActionConfig.id}
              loading={primaryActionConfig.loading}
              onClick={primaryActionConfig.onClick}
              variant="primary">
              {primaryActionConfig.text}
            </Button>
          </div>
        </div>
        <div
          className={cx(leftSideBarClass, {
            [sprinkles({ position: 'initial', marginRight: 'sp5' })]:
              size.width && size.width < LAYOUT_BREAKPOINT,
          })}
          style={{ width: 150, left: -190 }}>
          <div className={sprinkles({ marginTop: 'sp4' })}>
            <ConnectStepByStep currentStep={currentStep} />
          </div>
          <div className={sprinkles({ marginBottom: 'sp1' })}>{leftSideBarBottomContent}</div>
        </div>
      </div>
    </div>
  );
};

const leftSideBarBottomClass = sprinkles({
  flexItems: 'column',
  alignItems: 'flex-end',
  textAlign: 'right',
  gap: 'sp.5',
});

const leftSideBarClass = sprinkles({
  positionAbsolute: 'topRight',
  height: 'fill',
  flexItems: 'column',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
});

const headerClass = sprinkles({
  padding: 'sp4',
  paddingBottom: 'sp0',
  marginBottom: 'sp4',
  color: 'contentPrimary',
});

const footerClass = sprinkles({
  borderTop: 1,
  borderColor: 'outline',
  padding: 'sp1.5',
  flexItems: 'alignCenterBetween',
});

const containerWrapperClass = sprinkles({
  height: 'fill',
  position: 'relative',
  flexItems: 'alignCenterBetween',
  flexDirection: 'row-reverse',
});

const containerClass = sprinkles({
  height: 'fill',
  backgroundColor: 'white',
  borderRadius: 8,
  border: 1,
  borderColor: 'outline',
  flexItems: 'column',
});

export const ConnectDataSourceContainer = withSize()(ConnectDataSourceContainerBase);
