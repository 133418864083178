import { FC, lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { saveDraftDatasetQuery } from 'actions/datasetActions';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { Button, Spinner, sprinkles } from 'components/ds';
import { SchemaViewer } from 'components/resource/SchemaViewer';
import { ReduxState } from 'reducers/rootReducer';
import { getSchemaTablesMap } from 'reducers/selectors';
import { formatQuery, getSchemaNameInfo } from 'utils/queryUtils';

import { ConfigSection } from '../ConfigSection';
import { QueryRunButtons } from '../QueryRunButtons';
import * as sharedStyles from '../index.css';

import {
  EDIT_IN_DATA_LIBRARY_BUTTON_TEXT,
  EDIT_IN_DATA_LIBRARY_BUTTON_TOOLTIP_TEXT,
  EDIT_IN_DATA_LIBRARY_DATASET_DELETED_TOOLTIP_TEXT,
} from 'components/DataLibrary/constants';
import { ReadAccessComputedView } from 'utils/fido/fidoShimmedTypes';
import * as styles from './index.css';

const SqlEditor = lazy(() => import(/* webpackChunkName: "SqlEditor" */ 'components/SqlEditor'));

type Props = {
  dataset: ReportBuilderDataset;
  backingGlobalDataset?: ReadAccessComputedView;
  latestBackingGlobalDataset?: ReadAccessComputedView;

  onSelectSchema?: (schemaId: number) => void;
  getPreview: () => void;
  saveQuery: () => void;
  onEditInDataLibrary?: () => void;
};

export const QuerySection: FC<Props> = ({
  dataset,
  backingGlobalDataset,
  latestBackingGlobalDataset,
  onSelectSchema,
  getPreview,
  saveQuery,
  onEditInDataLibrary,
}) => {
  const dispatch = useDispatch();

  const { schemaTablesMap, shouldUseFido } = useSelector(
    (state: ReduxState) => ({
      schemaTablesMap: getSchemaTablesMap(state),
      shouldUseFido: state.currentUser.team?.feature_flags.use_fido,
    }),
    shallowEqual,
  );

  const [currentQuery, setCurrentQuery] = useState(dataset.queryDraft ?? dataset.query);
  const [currDatasetId, setCurrDatasetId] = useState(dataset.id);
  const [backingGlobalDatasetVersionId, setBackingGlobalDatasetVersionId] = useState(
    backingGlobalDataset?.id,
  );

  useEffect(() => {
    // Update currentQuery when dataset is switched
    if (dataset.id === currDatasetId) return;
    setCurrDatasetId(dataset.id);
    setCurrentQuery(dataset.queryDraft ?? dataset.query);
  }, [dataset, currDatasetId]);

  useEffect(() => {
    // Update the currentQuery when the version of the backing global dataset changes if there is a
    // backing global dataset.
    if (!backingGlobalDataset) {
      return;
    }

    if (backingGlobalDataset.versionId === backingGlobalDatasetVersionId) {
      return;
    }

    setBackingGlobalDatasetVersionId(backingGlobalDataset.versionId);
    setCurrentQuery(backingGlobalDataset.query);
  }, [backingGlobalDataset, backingGlobalDatasetVersionId]);

  const parentSchemaId = dataset.parent_schema_id;

  const { tableNames, columnNames } = useMemo(
    () => getSchemaNameInfo(schemaTablesMap, parentSchemaId.toString()),
    [schemaTablesMap, parentSchemaId],
  );

  const globalDatasetId = backingGlobalDataset?.id;
  const sectionTitle = useMemo(
    () => (!globalDatasetId ? 'Write your query' : 'Query'),
    [globalDatasetId],
  );

  const formatSqlQuery = () => {
    const newQuery = formatQuery(currentQuery);
    setCurrentQuery(newQuery);
    dispatch(saveDraftDatasetQuery({ queryDraft: newQuery, dataset_id: dataset.id }));
  };

  const onRevertDraft = () => {
    dispatch(saveDraftDatasetQuery({ queryDraft: undefined, dataset_id: dataset.id }));
    setCurrentQuery(dataset.query);
  };

  const hasChanges = currentQuery !== dataset.query || dataset.schema?.length === 0;

  return (
    <ConfigSection defaultIsOpen title={sectionTitle}>
      <div className={sharedStyles.configSection}>
        <div className={styles.editorWrapper}>
          <Suspense fallback={<Spinner fillContainer />}>
            <SqlEditor
              columnNames={columnNames}
              disabled={!!globalDatasetId}
              onChange={setCurrentQuery}
              onChangeDraft={(newQuery) => {
                dispatch(saveDraftDatasetQuery({ queryDraft: newQuery, dataset_id: dataset.id }));
              }}
              query={currentQuery}
              readonly={!!globalDatasetId}
              tableNames={tableNames}
            />
          </Suspense>
        </div>
        <div className={sprinkles({ paddingX: 'sp1.5', paddingY: 'sp1' })}>
          {!globalDatasetId ? (
            <QueryRunButtons
              disableAI //TODO: remove this after SHIBA-5828 builds out AI dataset support
              onFormat={formatSqlQuery}
              onPreview={currentQuery.trim() ? getPreview : undefined}
              onRevertDraft={onRevertDraft}
              onSave={hasChanges ? saveQuery : getPreview}
              saveText={hasChanges ? 'Save & Run' : 'Run'}
              saveTooltipProps={{
                text: hasChanges ? 'Preview and save query' : 'Preview results without saving',
              }}
            />
          ) : (
            <div className={sprinkles({ flexItems: 'alignCenter' })}>
              <Button onClick={getPreview}>Preview</Button>
              <Button
                className={sprinkles({ marginX: 'sp1' })}
                disabled={!latestBackingGlobalDataset}
                onClick={onEditInDataLibrary}
                tooltipProps={{
                  text: !latestBackingGlobalDataset
                    ? EDIT_IN_DATA_LIBRARY_DATASET_DELETED_TOOLTIP_TEXT
                    : EDIT_IN_DATA_LIBRARY_BUTTON_TOOLTIP_TEXT,
                }}
                variant="secondary">
                {EDIT_IN_DATA_LIBRARY_BUTTON_TEXT}
              </Button>
            </div>
          )}
        </div>

        {!backingGlobalDataset ? (
          <SchemaViewer
            isReportBuilder
            onSelectSchema={onSelectSchema}
            selectedDatasetSchemaId={
              shouldUseFido
                ? (dataset.namespace_id ?? dataset.parent_schema_id)
                : dataset.parent_schema_id
            }
          />
        ) : null}
      </div>
    </ConfigSection>
  );
};
