import {
  BranchRequest,
  BranchResponse,
  ListBranchContentResponse,
  ListBranchResponse,
  MergeBranchRequest,
  UUID,
} from '@explo-tech/fido-api';
import { BranchResourceService } from '@explo-tech/fido-api/services/BranchResourceService';
import { BranchContentResourceService } from '@explo-tech/fido-api';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ACTION } from 'actions/types';
import { ReduxState } from 'reducers/rootReducer';
import { makeFidoThunkRequest } from 'utils/thunkUtils';
import { ItemType } from 'reducers/dataLibraryReducer';

export const listBranchesThunk = createAsyncThunk<ListBranchResponse, void, { state: ReduxState }>(
  ACTION.FIDO_LIST_BRANCHES,
  async (_, { getState }) => {
    const fido = getState().fido;

    return makeFidoThunkRequest(
      () => BranchResourceService.listBranches(),
      fido.fidoToken ?? '',
      'Error fetching branches',
    );
  },
);

export const listBranchContentThunk = createAsyncThunk<
  ListBranchContentResponse,
  { id: UUID; path: string; resourceType: ItemType },
  { state: ReduxState }
>(ACTION.LIST_BRANCH_CONTENT, async ({ id, path }, { getState }) => {
  const fido = getState().fido;

  return makeFidoThunkRequest(
    () => BranchContentResourceService.listBranchContent(id, path, 0, 1000),
    fido.fidoToken ?? '',
    'Error fetching branch content',
  );
});

// TODO: Create branch probably shouldn't create a commit, should just accept a view ID?
export const createBranchThunk = createAsyncThunk<
  BranchResponse,
  BranchRequest,
  { state: ReduxState }
>(ACTION.FIDO_CREATE_BRANCH, async (requestBody, { getState }) => {
  const fido = getState().fido;

  return makeFidoThunkRequest(
    () => BranchResourceService.createBranch(requestBody),
    fido.fidoToken ?? '',
    'Error creating branch',
  );
});

export const getBranchThunk = createAsyncThunk<
  BranchResponse,
  { id: UUID; includeMeta?: boolean },
  { state: ReduxState }
>(ACTION.FIDO_GET_BRANCH, async ({ id, includeMeta = false }, { getState }) => {
  const fido = getState().fido;

  return makeFidoThunkRequest(
    () => BranchResourceService.getBranch(id, includeMeta),
    fido.fidoToken ?? '',
    'Error fetching branch',
  );
});

export const updateBranchThunk = createAsyncThunk<
  BranchResponse,
  { id: UUID; requestBody: BranchRequest },
  { state: ReduxState }
>(ACTION.FIDO_UPDATE_BRANCH, async ({ id, requestBody }, { getState }) => {
  const fido = getState().fido;

  return makeFidoThunkRequest(
    () => BranchResourceService.updateBranch(id, requestBody),
    fido.fidoToken ?? '',
    'Error updating branch',
  );
});

export const deleteBranchThunk = createAsyncThunk<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<string, any>,
  { id: UUID; includeMeta?: boolean },
  { state: ReduxState }
>(ACTION.FIDO_DELETE_BRANCH, async ({ id }, { getState }) => {
  const fido = getState().fido;

  return makeFidoThunkRequest(
    () => BranchResourceService.deleteBranch(id),
    fido.fidoToken ?? '',
    'Error deleting branch',
  );
});

export const mergeBranchThunk = createAsyncThunk<
  BranchResponse,
  { id: UUID; requestBody: MergeBranchRequest; onSuccess: () => void },
  { state: ReduxState }
>(ACTION.FIDO_MERGE_BRANCH, async ({ id, requestBody, onSuccess }, { getState }) => {
  const fido = getState().fido;

  return makeFidoThunkRequest(
    () => BranchResourceService.mergeBranch(id, /* isForce= */ false, requestBody),
    fido.fidoToken ?? '',
    'Error merging branch',
    onSuccess,
  );
});
