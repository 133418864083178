import React, { FC, useState } from 'react';

import { Icon, IconButton, sprinkles, Tooltip } from 'components/ds';
import { Props as IconButtonProps } from 'components/ds/IconButton';
import { IconName } from 'components/ds/Icon';
import { DEFAULT_DELAY } from 'components/ds/Tooltip';

import * as styles from './styles.css';

type AlertProps = {
  tooltipText: string;
  icon: IconName;
  iconStyle?: string;
  onClick?: (event: React.MouseEvent) => void;
};

type Props = {
  name: string;
  secondaryText?: string;
  leftIcon: IconName;
  alert?: AlertProps;
  onEdit?: () => void;
  onDuplicate?: () => void;
  onDelete?: () => void;
  isSelected?: boolean;
  isHighlighted?: boolean; // controls whether the card should be highlighted based on an external condition
  onClick?: () => void;
  onMouseEntered?: () => void;
  onMouseLeave?: () => void;
  additionalIconButtons?: IconButtonProps[];
};

export const CardItem: FC<Props> = ({
  leftIcon,
  name,
  onEdit,
  onDuplicate,
  onDelete,
  secondaryText,
  onClick,
  isSelected,
  alert,
  isHighlighted,
  onMouseEntered,
  onMouseLeave,
  additionalIconButtons,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleClick = (onClickFn: () => void, e: React.MouseEvent) => {
    // Prevent dataset editor from being opened when button is clicked
    e.stopPropagation();
    onClickFn();
  };

  const alertClassName = alert?.iconStyle ?? sprinkles({ color: 'warningBold' });
  return (
    <div
      className={
        isSelected
          ? styles.selectedCardItemContainer
          : isHighlighted
            ? styles.highlightedCardItemContainer
            : styles.cardItemContainer
      }
      onClick={onClick}
      onMouseEnter={() => {
        setIsHovered(true);
        onMouseEntered?.();
      }}
      onMouseLeave={() => {
        setIsHovered(false);
        onMouseLeave?.();
      }}
      style={{ height: secondaryText ? 72 : 52 }}>
      <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp2', overflow: 'hidden' })}>
        <Icon className={sprinkles({ color: 'contentSecondary' })} name={leftIcon} />
        <div className={sprinkles({ flexItems: 'column', gap: 'sp.5', overflow: 'hidden' })}>
          <Tooltip delayDuration={DEFAULT_DELAY} text={name}>
            <div className={styles.cardHeaderText} title={name}>
              {name}
            </div>
          </Tooltip>
          {secondaryText ? (
            <Tooltip delayDuration={DEFAULT_DELAY} text={secondaryText}>
              <div className={styles.cardBodyText} title={secondaryText}>
                {secondaryText}
              </div>
            </Tooltip>
          ) : null}
        </div>
      </div>
      <div className={sprinkles({ flexItems: 'alignCenter' })}>
        {isHovered ? (
          <>
            {onEdit ? (
              <IconButton
                className={sprinkles({ color: 'contentSecondary' })}
                name="pencil"
                onClick={(e) => handleClick(onEdit, e)}
                tooltipProps={{ text: 'Edit', delayDuration: DEFAULT_DELAY }}
              />
            ) : null}
            {onDuplicate ? (
              <IconButton
                className={sprinkles({ color: 'contentSecondary' })}
                name="copy"
                onClick={(e) => handleClick(onDuplicate, e)}
                tooltipProps={{ text: 'Duplicate', delayDuration: DEFAULT_DELAY }}
              />
            ) : null}
            {onDelete ? (
              <IconButton
                className={styles.deleteIcon}
                name="trash-can"
                onClick={(e) => handleClick(onDelete, e)}
                tooltipProps={{ text: 'Delete', delayDuration: DEFAULT_DELAY }}
              />
            ) : null}
            {additionalIconButtons?.map((buttonProps) => (
              <IconButton key={`card-item-${name}-${buttonProps.name}`} {...buttonProps} />
            ))}
          </>
        ) : null}
        {alert ? (
          <Tooltip delayDuration={DEFAULT_DELAY} text={alert.tooltipText}>
            <div className={sprinkles({ flexItems: 'alignCenter', paddingX: 'sp1' })}>
              {alert.onClick ? (
                <IconButton className={alertClassName} name={alert.icon} onClick={alert.onClick} />
              ) : (
                <Icon className={alertClassName} name={alert.icon} />
              )}
            </div>
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
};
