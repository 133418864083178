import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { ReduxState } from 'reducers/rootReducer';

import { useDataLibrarySetup } from './dataFetchUtils';
import { getRootFolderForBranch } from './selectors';

import { ExploLoadingSpinner } from 'components/ExploLoadingSpinner';
import { ItemType, setCurrentItemInfo } from 'reducers/dataLibraryReducer';
import * as RD from 'remotedata';
import { ROOT_FOLDER_PATH } from './constants';

export const RootDataLibraryPage: FC = () => {
  const dispatch = useDispatch();

  const {
    rootFolder,
    dataSources,
    parentSchemaTablesMap,
    parentSchemas,
    useFido,
    currentBranch,
    currentItemPath,
  } = useSelector((state: ReduxState) => {
    return {
      rootFolder: getRootFolderForBranch(state, state.dataLibrary.currentBranch),
      currentItemPath: state.dataLibrary.currentItemPath,
      dataSources: state.dataSource.dataSources,
      parentSchemas: state.parentSchemas.usedParentSchemas,
      parentSchemaTablesMap: state.parentSchemas.schemaTablesMap,
      useFido: state.currentUser.team?.feature_flags.use_fido,
      currentBranch: state.dataLibrary.currentBranch,
    };
  });

  // Custom hook to load all the data library state
  useDataLibrarySetup(
    dispatch,
    dataSources,
    parentSchemas,
    parentSchemaTablesMap,
    useFido,
    currentBranch,
    rootFolder,
    currentItemPath,
  );

  if (RD.isIdle(currentBranch) || RD.isLoading(currentBranch) || !RD.isSuccess(rootFolder)) {
    return <ExploLoadingSpinner />;
  }

  if (currentItemPath.path !== ROOT_FOLDER_PATH) {
    dispatch(setCurrentItemInfo({ type: ItemType.FOLDER, path: ROOT_FOLDER_PATH }));
  }
  return <Redirect to={{ pathname: `/data-library/folder/${rootFolder.data.id ?? ''}` }} />;
};
