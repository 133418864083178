import {
  ChartAggregation,
  DatabaseUnsupportedOperations,
  DatasetRow,
  DatasetSchema,
  DATE_RELATIVE_OPTION,
  PivotAgg,
  SortOrder,
} from '@explo/data';

import { CustomerReportAgg, CustomerReportConfig } from 'actions/customerReportActions';
import { ReportBuilderColConfigs, ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { ViewRequestParams } from 'reportBuilderContent/thunks/utils';
import { DashboardVariableMap } from 'types/dashboardTypes';

export type CustomerReportRowCountBody = {
  resource_embed_id: string | undefined;
  version_number: number;
  dataset_id: string;
  variables: DashboardVariableMap;
} & ViewRequestParams;

export type CustomerReportDataBody = {
  columns: string[]; // If empty, selects all columns
  page: number;
  limit?: number;
} & ViewRequestParams;

export type FetchEmbeddedDataBody = {
  resource_embed_id: string | undefined;
  version_number: number;
  dataset_id: string;
  variables?: DashboardVariableMap;
} & CustomerReportDataBody;

export type FetchReportBuilderDatasetData = {
  rows: DatasetRow[];
  schema: DatasetSchema;
  source_type: string | null;
  unsupported_operations: DatabaseUnsupportedOperations[];
};

export type FetchAppDataBody = {
  query: string;
  parent_schema_id: number;
  customer_id: number;
  page: number; // Starts at 1
  column_configs: ReportBuilderColConfigs | undefined; // column_configs should only be undefined when fetching dataset preview data
  timezone: string;
  custom_aggregations: CustomerReportAgg[] | undefined;
  // Used if we need to copy dataset fields to view fields
  dataset?: ReportBuilderDataset;
} & Partial<ViewRequestParams>;

export type FetchReportBuilderPreviewReportRowCountSuccess = {
  row_count: number;
};

export const DATA_PREVIEW_TYPE = {
  FULL_PREVIEW: 'FULL_PREVIEW',
  CUSTOM_AGGREGATION: 'CUSTOM_AGGREGATION',
};
export type DataPreviewType = (typeof DATA_PREVIEW_TYPE)[keyof typeof DATA_PREVIEW_TYPE];

export type FetchReportBuilderDatasetBody = {
  datasetId: string;
  save?: boolean;
  switchedToDataset?: boolean;
  page?: number;
  viewParams?: Partial<ViewRequestParams>;
  dataPreviewType: DataPreviewType;
};

export type AIMessage = {
  role: 'system' | 'assistant' | 'user';
  content: string;
};

export type ChatReportBuilderViewAIJobBody = {
  messages: AIMessage[];
  schema: DatasetSchema;
};

export interface ChatReportBuilderViewAIJobResponse {
  content: string;
  chart_type?: string;
}

export type GenerateReportBuilderViewAIJobBody = {
  schema: DatasetSchema;
  message: string;
  chart_type: string;
};

export interface GenerateReportBuilderViewAIJobResponse {
  chart_title: string;
  chart_type: AIChartType;
  chart_schema: AIView;
}

export type GenerateReportBuilderViewSuggestionsAIJobBody = {
  schema: DatasetSchema;
  datasetId: string; // Only used in Reducer
};

export type GenerateReportBuilderViewSuggestionsAIJobResponse = {
  ideas: string[];
};

export type AIChartType = 'table' | 'bar' | 'line' | 'pie' | 'number';

export interface ColumnFilter {
  filterColumn: string;
  COLUMN_TYPE_IS_STRING?: StringTypeOperations;
  COLUMN_TYPE_IS_NUMERIC?: NumericTypeOperations;
  COLUMN_TYPE_IS_DATE_OR_TIME?: DateOrTimeTypeOperations;
  COLUMN_TYPE_IS_BOOLEAN?: BooleanTypeOperations;
  ANY_COLUMN_TYPE?: AnyColumnTypeOperations;
}

interface StringTypeOperations {
  EQUALS?: string;
  NOT_EQUALS?: string;
  IS_IN_LIST?: string[];
  IS_NOT_IN_LIST?: string[];
  CONTAINS_SUBSTR?: string;
  NOT_CONTAINS_SUBSTR?: string;
}

interface NumericTypeOperations {
  EQUALS?: number;
  NOT_EQUALS?: number;
  IS_IN_LIST?: number[];
  IS_NOT_IN_LIST?: number[];
  LESS_THAN?: number;
  GREATER_THAN?: number;
  LESS_THAN_OR_EQUAL?: number;
  GREATER_THAN_OR_EQUAL?: number;
  IS_BETWEEN?: {
    min: number;
    max: number;
  };
}

interface DateOrTimeTypeOperations {
  EQUALS_UTC?: string;
  NOT_EQUALS_UTC?: string;
  LESS_THAN_UTC?: string;
  GREATER_THAN_UTC?: string;
  LESS_THAN_OR_EQUAL_UTC?: string;
  GREATER_THAN_OR_EQUAL_UTC?: string;
  IS_BETWEEN_UTC?: { min: string; max: string };
  DATE_PREVIOUS?: DateUnitOperation;
  DATE_NEXT?: DateUnitOperation;
}

interface BooleanTypeOperations {
  EQUALS?: boolean;
}

interface AnyColumnTypeOperations {
  IS_NULL?: string;
  IS_NOT_NULL?: string;
}

interface DateUnitOperation {
  unit: DATE_RELATIVE_OPTION;
  number: number;
}

type AIAggregation = { aggregateColumn: string; aggregateFunction: ChartAggregation };

export interface AIView {
  groupBys?: { groupByColumn: string; dateGrouping: PivotAgg }[];
  aggregations?: AIAggregation | AIAggregation[];
  filters?: ColumnFilter[];
  sort?: { sortColumn: string; sortOrder: SortOrder }[];
  columns?: string[];
}

export interface CustomerReportBuilderMetadata {
  id: string;
  customer: string;
  favorite_built_ins: string[];
  created: string;
  modified: string;
}

export interface ToggleFavoriteCustomerReportBuilderMetadataBody {
  resource_embed_id: string | undefined;
  built_in_id: string;
}

export interface ToggleFavoriteCustomerReportBuilderMetadataResponse {
  favorite_built_ins: string[];
}

export type ReportUpdate = { report_id: number };

export type SaveCustomerReportBody = ReportUpdate & { config: CustomerReportConfig };
