import { FC } from 'react';

import { OPERATION_TYPES, BAR_CHART_TYPES, HORIZONTAL_OPERATION_TYPES } from '@explo/data';

import { RangeInput } from 'components/ChartConfigs/RangeInput';
import { BooleanToggle, Input, Switch } from 'components/ds';
import { YAxisFormat } from 'constants/types';
import { ValueFormatConfig } from 'pages/dashboardPage/DataPanelConfig/FormatConfigTab/formatSections/ValueFormatConfig';
import { getValidRange } from 'utils/numberRangeUtils';

type Props = {
  configInputClass: string;
  isNormalized: boolean;
  yAxisFormat: YAxisFormat | undefined;

  updateAxisFormat: (yAxisFormat: YAxisFormat) => void;
  visualizationType: OPERATION_TYPES;
};

export const SharedYAxisConfigs: FC<Props> = ({
  configInputClass,
  isNormalized,
  updateAxisFormat,
  yAxisFormat,
  visualizationType,
}) => {
  const isHeatMap = visualizationType === OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2;
  const isBarFunnel = visualizationType === OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_FUNNEL_V2;

  const renderValueFormatSection = () => {
    if (isHeatMap) return null;

    return (
      <ValueFormatConfig
        instructions={yAxisFormat}
        updateInstructions={updateAxisFormat}
        valueFormatLabelHelpText={
          isNormalized ? 'Formatting will only apply to the data tooltip' : undefined
        }
      />
    );
  };

  const renderAxisPlacement = () => {
    if (
      !BAR_CHART_TYPES.has(visualizationType) &&
      visualizationType !== OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2
    )
      return;
    const isHorizontal = HORIZONTAL_OPERATION_TYPES.has(visualizationType);
    return (
      <BooleanToggle
        className={configInputClass}
        falseText={isHorizontal ? 'Bottom' : 'Left'}
        label="Axis placement"
        onValueChange={(oppositeAligned) => updateAxisFormat({ oppositeAligned })}
        selectedValue={!!yAxisFormat?.oppositeAligned}
        trueText={isHorizontal ? 'Top' : 'Right'}
      />
    );
  };

  const renderAxisFormat = () => {
    if (isHeatMap || isBarFunnel) return null;

    return (
      <>
        <Switch
          className={configInputClass}
          label="Axis Labels"
          onChange={() => updateAxisFormat({ hideAxisLabels: !yAxisFormat?.hideAxisLabels })}
          switchOn={!yAxisFormat?.hideAxisLabels}
        />
        <Switch
          className={configInputClass}
          label="Grid Lines"
          onChange={() => updateAxisFormat({ hideGridLines: !yAxisFormat?.hideGridLines })}
          switchOn={!yAxisFormat?.hideGridLines}
        />
      </>
    );
  };

  const renderNumberScaleSection = () => {
    if (isHeatMap || isBarFunnel) return null;

    return (
      <>
        <Switch
          className={configInputClass}
          label="Use Logarithmic Scale"
          onChange={() => {
            const useLogScale = !yAxisFormat?.useLogScale;
            const { min } = getValidRange(yAxisFormat?.min, yAxisFormat?.max, useLogScale);
            updateAxisFormat({ useLogScale, min });
          }}
          switchOn={yAxisFormat?.useLogScale}
        />
        <RangeInput
          className={configInputClass}
          endVal={String(yAxisFormat?.max ?? '')}
          onNewRange={(newStart?: string, newEnd?: string) => {
            const min = parseFloat(newStart ?? '');
            const max = parseFloat(newEnd ?? '');
            updateAxisFormat(getValidRange(min, max, yAxisFormat?.useLogScale));
          }}
          startVal={String(yAxisFormat?.min ?? '')}
        />
      </>
    );
  };

  if (visualizationType === OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP)
    return renderValueFormatSection();

  return (
    <>
      {isBarFunnel ? (
        <Switch
          className={configInputClass}
          label="Axis Title"
          onChange={() => updateAxisFormat({ showTitle: !yAxisFormat?.showTitle })}
          switchOn={yAxisFormat?.showTitle}
        />
      ) : null}
      {yAxisFormat?.showTitle ? (
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={yAxisFormat?.title}
          onSubmit={(newValue) => updateAxisFormat({ title: newValue })}
        />
      ) : null}
      {renderValueFormatSection()}
      {renderNumberScaleSection()}
      {renderAxisPlacement()}
      {renderAxisFormat()}
      {!isBarFunnel ? (
        <Switch
          className={configInputClass}
          label="Reverse Axis"
          onChange={() => updateAxisFormat({ reverseAxis: !yAxisFormat?.reverseAxis })}
          switchOn={yAxisFormat?.reverseAxis}
        />
      ) : null}
    </>
  );
};
