import { User } from 'actions/userActions';

export const getMessageBase = (userName: string, teamName: string) => {
  return `"${userName}" (on team "${teamName}")`;
};

export const getAnonymousMessageBase = (teamName: string) => {
  return `"User" (on team "${teamName}")`;
};

export const getMessageBaseWithUser = (user: User) => {
  const userName = user.first_name + ' ' + user.last_name;
  return getMessageBase(userName, user.team?.team_name ?? '');
};
