import { FC, useMemo, useState } from 'react';

import { RenameModal } from 'components/RenameModal';
import { IconButton, Menu, MenuActionItem, MenuSeparator } from 'components/ds';
import { DeleteResourceConfirmationModal } from 'shared/ExploResource/DeleteResourceConfirmationModal';
import * as styles from 'shared/ExploResource/styles.css';
import { ResourceType } from 'types/exploResource';

import { Branch, ComputedView, Folder } from '@explo-tech/fido-api';
import { DataLibraryFolderActionModal } from 'pages/dataLibraryPage/DataLibraryFolderActionModal';
import { ResponseData } from 'remotedata';
import { COMPUTED_VIEW_TYPE, FOLDER_TYPE } from './constants';
import { getItemNameFromPath, validateResourceName } from './dataLibraryUtil';
import { ManageDatasetModalType } from './types';

interface Props {
  item: Folder | ComputedView;
  allItemNames: Set<string>;
  currentBranch: ResponseData<Branch>;

  onItemDelete: () => void;
  onItemRename?: (newName: string) => void;
  onItemDuplicate?: () => void;
  onItemMove?: (newParentFolder: Folder) => void;
}

export const DataLibraryOverflowMenu: FC<Props> = ({
  item,
  allItemNames,
  currentBranch,
  onItemDelete,
  onItemRename,
  onItemDuplicate,
  onItemMove,
}) => {
  const [openModalType, setOpenModalType] = useState<ManageDatasetModalType>(
    ManageDatasetModalType.NO_MODAL,
  );

  const itemName = useMemo(() => getItemNameFromPath(item.path ?? ''), [item.path]);

  const isItemFolder = item['@type'] === ResourceType.FOLDER;
  return (
    <div className={styles.dotsMenuContainer} onClick={(event) => event.stopPropagation()}>
      <Menu
        className={styles.resourceConfigMenu}
        trigger={<IconButton className={styles.dotsMenuIcon} name="ellipsis-vertical" />}>
        {onItemRename ? (
          <MenuActionItem
            iconName="edit"
            onSelect={() => setOpenModalType(ManageDatasetModalType.RENAME_MODAL)}
            text="Rename"
          />
        ) : null}
        {onItemDuplicate ? (
          <MenuActionItem iconName="copy" onSelect={onItemDuplicate} text="Duplicate" />
        ) : null}
        {onItemMove ? (
          <MenuActionItem
            iconName="arrow-right"
            onSelect={() => setOpenModalType(ManageDatasetModalType.MOVE_MODAL)}
            text="Move"
          />
        ) : null}
        {onItemRename || onItemDuplicate ? <MenuSeparator /> : null}
        <MenuActionItem
          isDestructive
          iconName="trash"
          onSelect={() => setOpenModalType(ManageDatasetModalType.DELETE_CONFIRMATION_MODAL)}
          text="Delete"
        />
      </Menu>
      {openModalType === ManageDatasetModalType.DELETE_CONFIRMATION_MODAL ? (
        <DeleteResourceConfirmationModal
          onClose={() => setOpenModalType(ManageDatasetModalType.NO_MODAL)}
          onDelete={onItemDelete}
          resourceName={itemName}
        />
      ) : null}
      {openModalType === ManageDatasetModalType.RENAME_MODAL ? (
        <RenameModal
          isOpen
          isLoadingUniqueNames={false}
          onClose={() => setOpenModalType(ManageDatasetModalType.NO_MODAL)}
          onSubmit={(newName) => {
            if (onItemRename) {
              onItemRename(newName);
            }
          }}
          resourceName={itemName}
          resourceType={isItemFolder ? ResourceType.FOLDER : ResourceType.DATASET}
          uniqueNames={allItemNames}
          validateNewNameFn={(newName) => {
            const resourceNameError = validateResourceName(
              newName ?? '',
              isItemFolder ? FOLDER_TYPE : COMPUTED_VIEW_TYPE,
            );

            return resourceNameError ?? '';
          }}
        />
      ) : null}
      {openModalType === ManageDatasetModalType.MOVE_MODAL && onItemMove ? (
        <DataLibraryFolderActionModal
          actionButtonText="Move"
          currentBranch={currentBranch}
          isButtonDisabledFn={(allChildResourceNames) => allChildResourceNames.has(itemName)}
          onActionButtonClicked={onItemMove}
          onClose={() => setOpenModalType(ManageDatasetModalType.NO_MODAL)}
        />
      ) : null}
    </div>
  );
};
